const { isEmpty } = require('lodash');

const { entries } = Object;
const displayTypes = {
  tip: {
    label: '画面右上に小さく',
  },
  alert: {
    label: '画面上部',
  },
};

module.exports = {
  fields: () => {
    return {
      displayType: {
        label: '表示',
        type: 'select',
        options: entries(displayTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: v => v != null,
        },
        initialValue: 'tip',
      },
      body: {
        label: '内容(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
    };
  },
};
