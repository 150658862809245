import React from 'react';
import { Input, Button, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import TenantLink from './TenantLink';
import useTenant from './hooks/useTenant';

const tabs = [
  { name: 'schedule', label: 'シミュレーション', path: '/schedule' },
  { name: 'purchaseOrders', label: 'PO', path: '/purchaseOrders' },
  { name: 'containers', label: 'コンテナ', path: '/containers' },
];

export default function InventoriesHeader (props) {
  const { activeTab, } = props;
  const tenant = useTenant();
  const url = `${window.location.origin}/${tenant?.id}/inventories`;

  return (
    <div>
      <div className="d-flex justify-content-center mb-3">
        <h4>在庫管理</h4>
      </div>
      <Nav tabs>
        {
          tabs.map(({ name, label, path }) => {
            return (
              <NavItem key={name}>
                <NavLink
                  tag={TenantLink}
                  className={classnames({ active: activeTab === name })}
                  to={`/admin/inventories${path}`}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })
        }
      </Nav>
    </div>
  );
};

