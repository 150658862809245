import {
  getConverter,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  limit as _limit,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
  CollectionReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import { useCollectionsDataOnce } from '../components/hooks/v9/useCollectionsDataOnce';
import type { TroubleInquiryCommentDocumentData, TroubleInquiryComment } from '../types';
import type { PartiallyPartial } from 'types';

export const troubleInquiryCommentConverter = getConverter<TroubleInquiryCommentDocumentData>();

export const troubleInquiryCommentsRef = (parent: DocumentReference) =>
  collection(parent, 'troubleInquiryComments').withConverter(troubleInquiryCommentConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<TroubleInquiryComment> : null;
export const troubleInquiryCommentRef = <Id extends string | undefined>(
  parent: DocumentReference,
  troubleInquiryCommentId: Id
) =>
  (troubleInquiryCommentId ? doc(troubleInquiryCommentsRef(parent), troubleInquiryCommentId) : null) as RefOrNull<Id>;

export const newTroubleInquiryCommentRef = (parent: DocumentReference) => doc(troubleInquiryCommentsRef(parent));

export const defaultQueryConstraints = [orderBy('createdAt', 'desc')];

export const limitToLastQueryConstraints = (limit: number) => [orderBy('createdAt', 'desc'), _limit(limit)];

export const troubleInquiryCommentsQuery = (parent: DocumentReference, ...queryConstraints: QueryConstraint[]) =>
  query(
    troubleInquiryCommentsRef(parent),
    ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints)
  );

export const tenantTroubleInquiryCommentsQuery = (parent: DocumentReference, tenantId: string | undefined) =>
  tenantId
    ? troubleInquiryCommentsQuery(parent, ...tenantQueryConstraints(tenantId), ...defaultQueryConstraints)
    : null;

type QueryOrNull<Id extends string | undefined> = Id extends string ? CollectionReference<TroubleInquiryComment> : null;
export const tenantLimitToLastTroubleInquiryCommentsQuery = <Id extends string | undefined>(
  parent: DocumentReference,
  tenantId: Id,
  limit: number
) =>
  (tenantId
    ? troubleInquiryCommentsQuery(parent, ...tenantQueryConstraints(tenantId), ...limitToLastQueryConstraints(limit))
    : null) as QueryOrNull<Id>;

export const addTroubleInquiryComment = async (
  parent: DocumentReference,
  data: PartiallyPartial<TroubleInquiryComment, 'id' | 'createdAt'>
) => addDoc(troubleInquiryCommentsRef(parent), { createdAt: serverTimestamp(), ...data });

export const updateTroubleInquiryComment = async (
  parent: DocumentReference,
  troubleInquiryCommentId: string,
  data: UpdateData<TroubleInquiryComment>
) => updateDoc(troubleInquiryCommentRef(parent, troubleInquiryCommentId), data);

export const deleteTroubleInquiryComment = async (parent: DocumentReference, troubleInquiryCommentId: string) =>
  deleteDoc(troubleInquiryCommentRef(parent, troubleInquiryCommentId));

export const useTroubleInquiryCommentCollection = useCollectionData;

export const useTroubleInquiryCommentCollectionOnce = useCollectionDataOnce;

export const useTroubleInquiryCommentCollectionsOnce = useCollectionsDataOnce;

export const useTroubleInquiryCommentDocument = useDocumentData;

export const useTroubleInquiryCommentDocumentOnce = useDocumentDataOnce;
