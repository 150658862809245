const { omit, pick, chunk, uniq, keyBy, isEmpty, pickBy, sortBy } = require('lodash');
const { format: formatDate, differenceInYears, addDays, addHours, startOfDay, endOfDay, } = require('date-fns');
const retry = require('async-retry');

const { batch, getDocumentData, getCollectionData, } = require('../firebase');
const { vehicleExperiences, } = require('./user');
const { smsOrderTypes: orderTypes, smsEntryTypes: entryTypes, } = require('../config');
const { computeEnvelopeTargets, generateOrderId, } = require('../util');
const { systemAuditObject } = require('../auditLogs');
const { fields: orderFields, destinationFields, } = require('./order');

const { entries, keys } = Object;

const scheduleTypes = {
  ship: {
    label: '発送から',
    candidates: async (db, { tenantId, daysAfter }) => {
      const orders = await getCollectionData(db.collection('orders').where('tenantId', '==', tenantId).where('shippedDate', '==', formatDate(addDays(addHours(new Date(), 9), -daysAfter), 'yyyy/MM/dd')));
      return uniq(orders.map(_ => _.createdBy?.uid).filter(_ => _));
    },
  },
  entry: {
    label: 'イベント参加から',
    candidates: async (db, { tenantId, daysAfter }) => {
      const lectures = await getCollectionData(db.collectionGroup('lectures').where('tenantId', '==', tenantId).where('date', '>=', addHours(startOfDay(addHours(addDays(new Date(), -daysAfter), 9)), -9)).where('date', '<=', addHours(endOfDay(addHours(addDays(new Date(), -daysAfter), 9)), -9)));
      const entries = (await Promise.all(chunk(lectures, 10).map((lectures) => {
        return getCollectionData(db.collectionGroup('entries').where('lectureIds', 'array-contains-any', lectures.map(_ => _.id)));
      }))).flat();
      return uniq(entries.map(_ => _.createdBy?.uid).filter(_ => _));
    },
  },
};

async function createEnvelopeOrders(db, firestore, tenantId, candidates, envelopeOptions, envelopeScheduleId, _targets = null) {
  const { ref } = envelopeOptions;
  const targets = _targets || await computeEnvelopeTargets(db, tenantId, candidates, { envelopeScheduleId, ...envelopeOptions, });
  await targets.reduce(async (x, target) => {
    await x;

    await retry(async () => {
      const batch = db.batch();
      const orderId = await generateOrderId();
      batch.set(db.collection('orders').doc(orderId), {
        ...pick(target.lastOrder, keys(orderFields())),
        ...keys(destinationFields()).reduce((x, field) => {
          return {
            ...x,
            [field]: target.lastOrder[field.replace('destination', 'contactor')],
          };
        }, {}),
        productIds: [],
        createdBy: systemAuditObject,
        isWholesale: false,
        wholesaleAgentId: null,
        wholesaleAgentShopId: null,
        amount: 0,
        deliveryDate: null,
        deliveryTime: 'anytime',
        orderItems: (envelopeOptions.envelopeProductIds || []).map(_ => ({ productId: _, quantity: 1 })),
        charge: null,
        referrerKey: null,
        createdAt: new Date(),
        cammacsStatus: 'initial',
        addedBy: systemAuditObject,
        packageNote: '',
        deliveryCode: '0100',
        isEnvelopeOrder: true,
      });
      envelopeScheduleId && batch.update(target.ref, { receivedEnvelopeScheduleIds: firestore.FieldValue.arrayUnion(envelopeScheduleId) });
      await batch.commit();
    }, { retries: 2 });
  }, Promise.resolve());
}

module.exports = {
  fields: ({ products = [], envelopeProducts = [], productTypes = [], events = [], userTags = [], } = {}) => {
    return {
      name: {
        label: '名称',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      scheduleType: {
        label: 'スケジュール種別',
        type: 'select',
        options: entries(scheduleTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      daysAfter: {
        label: 'スケジュール(n日後)',
        type: 'integer',
        validations: {
          required: v => v != null,
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      orderType: {
        label: '注文種別',
        type: 'select',
        options: entries(pick(orderTypes, 'ordered')).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
        initialValue: 'ordered',
      },
      conditionProductTypeIds: {
        label: '注文商品種別',
        type: 'multiSelect',
        options: productTypes.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      conditionProductIds: {
        label: '注文商品',
        type: 'multiSelect',
        options: products.map(_ => ({ label: _.label || _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      isBodyOnly: {
        label: '本体のみ',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.orderType !== 'ordered',
      },
      entryType: {
        label: 'イベント参加種別',
        type: 'select',
        options: entries(entryTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      conditionEventIds: {
        label: '参加イベント',
        type: 'multiSelect',
        options: events.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.entryType !== 'entried',
      },
      isIncludedCancellOrAbort: {
        label: 'キャンセル・中止を含む',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.entryType !== 'entried',
      },
      userTagIds: {
        label: 'ユーザータグ',
        type: 'multiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
        hint: 'ORで判定します',
      },
      userChildAgeMin: {
        label: 'お子様の年齢下限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildAgeMax: {
        label: 'お子様の年齢上限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildVehicleExperiences: {
        label: 'お子様乗り物経験',
        type: 'multiSelect',
        options: ['未選択', ...vehicleExperiences].map(_ => ({ label: _, value: _, })),
      },
      envelopeProductIds: {
        label: '郵送物',
        type: 'multiSelect',
        options: envelopeProducts.map(_ => ({ label: _.label || _.name, value: _.id })),
      },
    };
  },
  orderTypes,
  entryTypes,
  scheduleTypes,
  createEnvelopeOrders,
};
