import React, { useState } from 'react';
import qs from 'qs';
import { omit } from 'lodash';
import Select from 'react-select';

import firebase from '../../firebase';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { fields } from '../../shared/models/searchParameter';
import { fullPathWithParams } from '../../util';
import AddInTenantButton from '../AddInTenantButton';
import ModelFormModal from '../modals/ModelFormModal';
import DeleteButton from '../DeleteButton';

const db = firebase.firestore();

export default function SavedSearchParameterForm (props) {
  const { user, location, history, type } = props;
  const searchParameters = useCollectionSubscriptionInTenant(db.collection('searchParameters').where('type', '==', type).where('addedBy.uid', '==', user.uid));
  const searchParameterOptions = searchParameters.map(_ => ({ label: _.memo, value: _.id }));
  const [selectedSearchValue, setSelectedSearchValue] = useState('');

  return (
    <div className="d-flex ml-auto align-items-end flex-wrap gap-2">
    <div style={{ width: 200 }}>
      <div className="text-muted small">保存された検索条件</div>
      <Select
        options={searchParameterOptions}
        value={searchParameterOptions.find(_ => _.value === selectedSearchValue)}
        className='form-select'
        onChange={(event) => {
          setSelectedSearchValue(event?.value);
          if (!event) return;
          const searchParameter = searchParameters.find(_ => _.id === event.value);
          if (!searchParameter) return;
          const path = fullPathWithParams(searchParameter.params, location, ['startOn', 'endOn', 'field', 'text']);
          history.replace(encodeURI(path));
        }}
        isClearable
      />
    </div>
    <AddInTenantButton
      label="保存"
      processValues={(_) => ({ ..._, params: omit(qs.parse(decodeURI(location.search.slice(1))), ['startOn', 'endOn', 'field', 'text']), type })}
      itemRef={db.collection('searchParameters').doc()}
      FormModal={ModelFormModal}
      formProps={{
        title: '検索条件を保存する',
        fields: fields(),
        hasSpin: true,
      }}
    />
    <DeleteButton
      label="削除"
      itemRef={selectedSearchValue && db.collection('searchParameters').doc(selectedSearchValue)}
      disabled={!selectedSearchValue}
    />
  </div>
  );
}
