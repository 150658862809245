import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { chunk, isEmpty, omit, keyBy, get, omitBy, isUndefined, pick } from 'lodash';

import { errorMessages as userErrorMessages } from '../shared/models/user';
import { roles, agentMemberFields, } from '../shared/models/agent';
import { canUpdateAgentMember, } from '../shared/abilities';
import { getCollectionData, } from '../shared/firebase';
import firebase, { functions } from '../firebase';
import useFirebaseUser from './hooks/useFirebaseUser';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useDocumentsFetch from './hooks/useDocumentsFetch';
import useQueryParams from './hooks/useQueryParams';
import ModelFormModal from './modals/ModelFormModal';
import EditButton from './EditButton';
import ModalButton from './ModalButton';
import ProgressButton from './ProgressButton';
import ExportButton from './ExportButton';
import ImportButton from './ImportButton';
import AdminAgentHeader from './AdminAgentHeader';
import { agentUserFields } from '../shared/models/user';

const { keys } = Object;
const createAgentUser = functions.httpsCallable('createAgentUser');
const getUserByEmail = functions.httpsCallable('getUserByEmail');
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const usersRef = db.collection('users');

export default function AgentMembersPageContent(props) {
  const { agent, user, canEditMembers, } = props;
  const queryParams = useQueryParams();
  const [showsFormModal, toggleFormModal] = useToggle(queryParams.creates === '1');
  const { firebaseUser } = useFirebaseUser();
  const agentShops = useCollectionSubscription(agent?.ref.collection('agentShops').orderBy('createdAt'), [agent]);
  const agentShopsById = keyBy(agentShops, 'id');
  const agentShopsByName = keyBy(agentShops, 'name');
  const members = useDocumentsFetch(agent && keys(agent.members || {}).map((_) => usersRef.doc(_)), [agent]);
  const addNewUser = async (values) => {
    const { email, displayName, role, shopIds = [], } = values;
    const {
      data: { uid },
    } = await createAgentUser({ agentId: agent.id, email, displayName, });
    await usersRef
      .doc(uid)
      .set({
        uid,
        email,
        displayName,
        addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        createdAt: new Date(),
      });
    await agent.ref.set({
      members: {
        [uid]: {
          role,
          shopIds,
          addedAt: new Date()
        },
      },
      updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
    }, { merge: true });
  };
  const onSubmitForm = async (values) => {
    try {
      await addNewUser(values);
      toast.success('追加しました');
      toggleFormModal(false);
    } catch (e) {
      console.error(e);
      const code = get(e, 'details.code') || e.code;
      const message = userErrorMessages[code] || '登録に失敗しました';
      toast.error(message, { autoClose: false });
    }
  };
  const onSubmitAddExistingUser = async (values, { onClickClose }) => {
    const { email, role, shopIds = [], } = values;

    try {
      const { data: targetUser } = await getUserByEmail({ email });
      if(targetUser != null) {
        await agent.ref.update({
          members: {
            ...agent.members,
            [targetUser.id]: {
              role,
              shopIds,
              addedAt: new Date(),
            },
          },
          updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        });
        toast.success('追加しました');
        onClickClose();
      } else {
        toast.error('見つかりませんでした');
      }
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const rowsForExport = () => {
    return (members || []).map((member) => {
      const { role, shopIds } = agent.members[member.id] || {};
      return {
        ...pick(member, [
          'id',
          'email',
          'displayName',
        ]),
        role,
        shopNames: shopIds?.map(_ => agentShopsById[_]?.name).join(','),
      };
    });
  };
  const processRows = async (rows) => {
    await chunk(rows.filter(_ => _.email), 10).reduce(async (x, rows) => {
      await x;

      await Promise.all(rows.map((row) => addNewUser({
        ...pick(row, ['email', 'displayName', 'role']),
        shopIds: row.shopNames?.split(',').map(_ => agentShopsByName[_]?.id).filter(_ => _),
      })));
    }, Promise.resolve());
  };

  return (
    agent != null && (
      <div>
        <div className='bg-white p-4'>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-end mb-3 gap-1'>
                <ImportButton label="インポート(追加のみ)" processRows={processRows} />
                <ExportButton fileName={`${agent.name}_ビジネスアカウント.csv`} rows={rowsForExport} />
                <Button color='primary' onClick={toggleFormModal} disabled={!canEditMembers}>
                  <span className='fas fa-plus mr-1' />
                  追加
                </Button>
                <ModalButton color='primary' Modal={ModelFormModal} modalProps={{ title: '既存アカウントを追加', fields: pick(agentUserFields({ agentShops, }), ['email', 'role', 'shopIds']), onSubmit: onSubmitAddExistingUser, }} disabled={!canEditMembers}>
                  <span className='fas fa-plus mr-1' />
                  既存アカウントを追加
                </ModalButton>
              </div>
              {members.length > 0 ? (
                <table className='table'>
                  <thead className='thead-light text-center'>
                    <tr>
                      <th>ビジネスアカウント名</th>
                      <th>メールアドレス</th>
                      <th>権限</th>
                      <th>所属店舗</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(members || []).map((member) => {
                      const { id, displayName, email, } = member;
                      const { role, shopIds } = agent.members[id] || {};
                      const onClickRemove = async () => {
                        if(!window.confirm('除外します。よろしいですか？')) return;

                        await agent.ref.set({ members: { [id]: firebase.firestore.FieldValue.delete(), } }, { merge: true, });
                      };
                      const beforeSubmit = (values) => {
                        return {
                          members: {
                            ...agent.members,
                            [id]: {
                              ...agent.members[id],
                              ...values,
                            },
                          },
                        };
                      };

                      return (
                        <tr key={id}>
                          <td>{displayName}</td>
                          <td>{email}</td>
                          <td>{roles[role]?.label}</td>
                          <td>{shopIds?.map(_ => agentShopsById[_]?.name).join(', ')}</td>
                          <td className='text-right'>
                            <EditButton itemRef={agent.ref} FormModal={ModelFormModal} disabled={!canEditMembers} formProps={{ title: '編集', fields: agentMemberFields({ agentShops }), }} beforeSubmit={beforeSubmit} beforeEdit={_ => _.members[id]} />
                            <ProgressButton className="ml-1" process={onClickRemove} color="danger" outline disabled={!canEditMembers}>
                              除外
                            </ProgressButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>ビジネスアカウントは未登録です</div>
              )}
            </div>
          </div>
        </div>
        {showsFormModal && (
          <ModelFormModal
            title='ビジネスアカウント追加'
            fields={omit(agentUserFields({ agentShops, }), ['password'])}
            isOpen
            onClickClose={toggleFormModal.bind(null, false)}
            onSubmit={onSubmitForm}
            values={{ email: queryParams.email }}
          />
        )}
      </div>
    )
  );
};
