import React, { useEffect } from 'react';
import ellipsis from 'text-ellipsis';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { orderBy, } from 'lodash';
import { format as formatDate, } from 'date-fns';

import firebase from '../../firebase';
import { batch } from '../../shared/firebase';
import { canUpdateProductType } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/textTemplate';

const db = firebase.firestore();
const { entries, } = Object;

export default function TextTemplatesModal(props) {
  const { isOpen, type, textTemplateKey, onClickClose, onApply, } = props;
  const textTemplates = useCollectionSubscriptionInTenant(db.collection('textTemplates').where('key', '==', textTemplateKey), [textTemplateKey]);
  const { firebaseUser } = useFirebaseUser();
  const sortedTextTemplates = orderBy(textTemplates, _ => _.createdAt.toDate(), 'desc');
  const replaceKeywords = (body) => {
    return body
      .replace('{{LOGIN_USER_NAME}}', firebaseUser.displayName)
  };

  return (
    <Modal isOpen={isOpen}>
      <div className='modal-header'>
        <div className='w-100 d-flex justify-content-between'>
          <h5 className='modal-title'>
            文例
          </h5>
        </div>
      </div>
      <ModalBody>
        <div>
          <div className='d-flex justify-content-end mb-3'>
            <AddInTenantButton
              itemRef={db.collection('textTemplates').doc()}
              processValues={(_) => ({ ..._, key: textTemplateKey, })}
              FormModal={ModelFormModal}
              formProps={{ title: '文例追加', fields: fields({ type }), }}
            />
          </div>
          <table className='table'>
            <thead className='thead-light text-nowrap text-center'>
              <tr>
                <th style={{ width: 50 }}>タイトル</th>
                <th style={{ width: 200 }}>内容</th>
                <th style={{ width: 50 }}></th>
              </tr>
            </thead>
            <tbody>
              {textTemplates.map((textTemplate) => {
                const { id, ref, subject, body, createdAt, } = textTemplate;
                const onClickApply = () => {
                  if(!window.confirm('本当に適用しますか？')) return;

                  onApply(replaceKeywords(body));
                  onClickClose();
                };

                return (
                  <tr>
                    <td>
                      {subject}
                      <div className="small text-muted">{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</div>
                    </td>
                    <td>{ellipsis(body, 100)}</td>
                    <td>
                      <div className="d-flex text-nowrap flex-column gap-1">
                        <Button size="sm" onClick={onClickApply}>
                          適用
                        </Button>
                        <EditButton
                          size="sm"
                          itemRef={ref}
                          FormModal={ModelFormModal}
                          formProps={{ title: '文例編集', fields: fields({ type }), }}
                        />
                        <DeleteButton
                          size="sm"
                          itemRef={ref}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='cancel' color='secondary' onClick={onClickClose}>閉じる</Button>
      </ModalFooter>
    </Modal>
  );
};
