import { omitBy, isUndefined, pick } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';

import useFirebaseUser from '../hooks/useFirebaseUser';
import { DocumentReference, setDoc, serverTimestamp } from '../../firebase';

export const AddButton = ({
  label = '追加',
  successMessage = '追加しました',
  initialValues = {},
  validateOnSubmit = async (_) => true,
  itemRef,
  FormModal,
  formProps,
  processValues = (_) => _,
  icon = <span className='fas fa-plus mr-1' />,
  onFinish = (_) => _,
  ...extraProps
}: {
  label?: string;
  successMessage?: string;
  initialValues?: Object;
  validateOnSubmit?: (values: Object) => Promise<boolean>;
  itemRef: DocumentReference;
  FormModal: any;
  formProps: any;
  processValues?: any;
  icon?: any;
  onFinish?: any;
  disabled?: boolean;
  className?: string;
}) => {
  const { firebaseUser } = useFirebaseUser();
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    const ref = itemRef;
    const { id } = itemRef;
    if (!(await validateOnSubmit({ id, ...values }))) return;

    try {
      const processedValues = await processValues({ id, ref, ...values });
      await setDoc(ref, {
        ...processedValues,
        addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        createdAt: serverTimestamp(),
      });
      await onFinish(processedValues);
      toast.success(successMessage);
      toggleModal(false);
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color='primary' aria-label='add-button' onClick={(_) => toggleModal(true)} {...extraProps}>
      {icon}
      {label}
      {showsModal && (
        <FormModal
          isOpen
          values={initialValues}
          onClickClose={(_) => toggleModal(false)}
          onSubmit={onSubmit}
          {...formProps}
        />
      )}
    </Button>
  );
};
