import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { QrUrlDocumentData, QrUrl } from '../types';
import type { PartiallyPartial } from 'types';

export const qrUrlConverter = getConverter<QrUrlDocumentData>();

export const qrUrlsRef = () => collection(getFirestore(), 'qrUrls').withConverter(qrUrlConverter);

type RefOrNull<Id extends string | undefined | null> = Id extends string ? DocumentReference<QrUrl> : null;
export const qrUrlRef = <Id extends string | undefined | null>(qrUrlId: Id) =>
  (qrUrlId ? doc(qrUrlsRef(), qrUrlId) : null) as RefOrNull<Id>;

export const newQrUrlRef = () => doc(qrUrlsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const qrUrlsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(qrUrlsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantQrUrlsQuery = (tenantId: string | undefined) =>
  tenantId ? qrUrlsQuery(...tenantQueryConstraints(tenantId)) : null;

export const addQrUrl = async (data: PartiallyPartial<QrUrl, 'id' | 'createdAt'>) =>
  addDoc(qrUrlsRef(), { createdAt: serverTimestamp(), ...data });

export const updateQrUrl = async (qrUrlId: string, data: UpdateData<QrUrl>) => updateDoc(qrUrlRef(qrUrlId), data);

export const deleteQrUrl = async (qrUrlId: string) => deleteDoc(qrUrlRef(qrUrlId));

export const useQrUrlCollection = useCollectionData;

export const useQrUrlCollectionOnce = useCollectionDataOnce;

export const useQrUrlDocument = useDocumentData;

export const useQrUrlDocumentOnce = useDocumentDataOnce;
