import React, { useState } from 'react';
import { sortBy, keyBy, isEmpty, get } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { toast } from 'react-toastify';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import { arrayMoveImmutable } from 'array-move';
import classnames from 'classnames';

import { activateRichTextHtml } from '../../util';
import firebase from '../../firebase';
import { canUpdateSurveys } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import SurveyFormModal from '../modals/SurveyFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/survey';
import { defaultGroups } from '../../shared/models/surveyGroup';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import RichTextContent from '../RichTextContent';
import useQueryParams from '../hooks/useQueryParams';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const questionsRef = db.collection('questions');
const surveysRef = db.collection('surveys');
const surveyGroupsRef = db.collection('surveyGroups');

export default AdminPage(function AdminSurveys(props) {
  const { user } = props;
  const questions = sortBy(useCollectionSubscriptionInTenant(questionsRef), (_) => _.createdAt.toDate());
  const { groups: groupsForFilter, showsHidden, } = useQueryParams();
  const questionsById = keyBy(questions, 'id');
  const surveys = sortBy(useCollectionSubscriptionInTenant(surveysRef), (_) => _.createdAt.toDate());
  const _surveyGroups = useCollectionSubscriptionInTenant(surveyGroupsRef);
  const staffs = useCollectionSubscriptionInTenant(db.collection('users').where('role', 'in', ['admin', 'staff']));
  const surveyGroups = [...defaultGroups, ..._surveyGroups];
  const groupOptions = surveyGroups.map((_) => ({ label: _.name, value: _.id }));

  let filteredSurveys = surveys;
  if (!isEmpty(groupsForFilter)) {
    filteredSurveys = filteredSurveys.filter((_) => groupsForFilter.includes(get(_, 'surveyGroupId')));
  }
  if (showsHidden !== '1') {
    filteredSurveys = filteredSurveys.filter(_ => !_.isHidden);
  }

  return (
    <div>
      <div className="admin-question-sets container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アンケートページ一覧</h4>
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QuerySelector
            paramName='groups'
            className='ml-0'
            width={400}
            isMulti
            options={groupOptions}
            label='グループで絞込み'
          />
          <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton
            itemRef={surveysRef.doc()}
            FormModal={SurveyFormModal}
            disabled={!canUpdateSurveys(user)}
            formProps={{ staffs, questions, surveyGroups, }}
          />
        </div>
        <div>
          {
            filteredSurveys.length > 0 ? (
              <table className="table table-responsive-sm">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ minWidth: 150 }}>グループ</th>
                    <th style={{ minWidth: 150 }}>名称</th>
                    <th style={{ minWidth: 150 }}>タイトル</th>
                    <th style={{ minWidth: 200 }}>説明文</th>
                    <th style={{ minWidth: 300 }}>アンケート項目</th>
                    <th style={{ minWidth: 200 }}>メモ</th>
                    <th style={{ minWidth: 200 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredSurveys.map((survey) => {
                      return (
                        <Row key={survey.id} survey={survey} {...{ staffs, questionsById, user, questions, surveyGroups }} />
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                アンケートページは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

const Row = (props) => {
  const { staffs, survey, questionsById, user, questions, surveyGroups, } = props;
  const { id, ref, name, title, description, steps = [{}], questionRows, surveyGroupId, isHidden, note, } = survey;
  const beforeDelete = async () => {
    if ((await db.collection('surveyAnswers').where('tenantId', '==', survey.tenantId).where('surveyId', '==', id).limit(1).get()).docs.length > 0) {
      toast.error('使用されているため削除できません');
      return false;
    }
  };

  return (
    <tr id={id} style={{ background: isHidden ? 'lightgray' : '' }}>
      <td>{surveyGroups.find((_) => _.id === surveyGroupId)?.name || ''}</td>
      <td>{name}</td>
      <td>{title}</td>
      <td>
        <RichTextContent html={description} />
      </td>
      <td>
        <div className="d-flex flex-column gap-1">
          {
            steps.map((step, stepIndex) => {
              const stepRows = (questionRows ?? []).filter(_ => (_.stepIndex ?? 0) === stepIndex);
              return (
                <div key={stepIndex} className="card p-1">
                  <h6>
                    {step.title || '(No Title)'}
                  </h6>
                  <div className="m-0">
                    {stepRows.map(({ questionId, visibilityConditionEnabled, }) => {
                      const question = questionsById[questionId];
                      return (
                        question != null && (
                          <div key={questionId} className="overflow-auto">
                            {question.name}
                            {visibilityConditionEnabled && <span className="badge badge-info">表示条件あり</span>}
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              );
            })
          }
        </div>
      </td>
      <td style={{ whiteSpace: 'pre-line' }}>
        {note}
      </td>
      <td className="text-nowrap text-right">
        <Button
          tag={TenantLink}
          to={`/surveys/${id}/surveyAnswers/thanks`}
          target="_blank"
        >
          サンクス確認
          <span className="fas fa-external-link-alt ml-1" />
        </Button>
        <ModalButton
          className="ml-2"
          title="回答画面URL"
          content={(_) => <SurveyAnswerURLDisplay survey={survey} />}
          modalProps={{ style: { minWidth: 800 } }}
        >
          回答画面URL
        </ModalButton>
        <EditButton
          itemRef={ref}
          className="ml-2"
          FormModal={SurveyFormModal}
          disabled={!canUpdateSurveys(user)}
          formProps={{ staffs, questions, surveyGroups, }}
        />
        <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateSurveys(user)} beforeDelete={beforeDelete} />
      </td>
    </tr>
  );
};

function SurveyAnswerURLDisplay(props) {
  const { survey } = props;
  const [tag, setTag] = useState('');
  const url =
    `${window.location.origin}/${survey.tenantId}/surveys/${survey.id}/surveyAnswers/new` +
    (tag ? `?${qs.stringify({ tag })}` : '');
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };

  return (
    <div>
      <FormGroup>
        <Label>タグ</Label>
        <Input value={tag} onChange={(_) => setTag(_.target.value)} />
      </FormGroup>
      <div className="d-flex">
        <Input className="flex-grow-1 mr-2" readOnly value={url} />
        <Button color="primary" onClick={onClickCopy}>
          <span className="fas fa-copy" />
        </Button>
      </div>
    </div>
  );
}
