const { groupBy, partition, omit, isEmpty, sumBy, flatten, sortBy } = require('lodash');

const { max } = Math;

const getProductsWithInventory = (tenant, products, receivings, shippings, preOrders, receivingPlans, initialTmpOrders = []) => {
  const receivingItemsGroupedByProductId = groupBy(receivings.flatMap(_ => _.items), 'productId');
  const shippingItemsGroupedByProductId = groupBy(shippings.filter(_ => _.status !== 'cancelled').flatMap(_ => _.items), 'productId');
  return products.map((product) => {
    const normalInventory = tenant.useWms ? (
      sumBy(receivingItemsGroupedByProductId[product.id], 'quantity') - sumBy(shippingItemsGroupedByProductId[product.id], 'quantity')
    ) : (product.normalInventory || 0);
    const preOrderItems = flatten(preOrders.map(_ => _.orderItems)).filter(_ => _.productId === product.id);
    const preOrderQuantity = sumBy(preOrderItems, 'quantity');
    const [preTmpOrderQuantity, tmpOrderQuantity] = partition(initialTmpOrders, 'isPreOrder').map(_ => sumBy(flatten(_.map(_ => _.orderItems)).filter(_ => _.productId === product.id), 'quantity'));
    const { receivingPlanItems } = flatten(
      receivingPlans.map(r => r.items.map(_ => ({ ..._, receivingPlan: r })))
    )
      .filter(_ => _.productId === product.id)
      .reduce((x, y) => {
        const leftQuantity = max(y.quantity - x.leftOrderQuantity, 0);
        const leftOrderQuantity = max(x.leftOrderQuantity - y.quantity, 0);
        return {
          receivingPlanItems: [
            ...x.receivingPlanItems,
            { ...y, leftQuantity }
          ],
          leftOrderQuantity,
        };
      }, { receivingPlanItems: [], leftOrderQuantity: preOrderQuantity, });
    const receivingQuantity = sumBy(receivingPlanItems, 'quantity');
    return {
      ...product,
      normalInventory,
      tmpOrderQuantity,
      normalOrderableQuantity: normalInventory - tmpOrderQuantity,
      preTmpOrderQuantity,
      receivingPlanItems,
      receivingQuantity,
      preOrderQuantity,
      preOrderableQuantity: receivingQuantity - preOrderQuantity - preTmpOrderQuantity,
    };
  });
};

const getProductId = (tenantId, code) => tenantId === 'vi' ? code : tenantId + '__' + code;

module.exports = {
  fields: ({ tenant, surveys = [], productTags = [], productTypes = [], products = [] } = {}) => {
    return {
      code: {
        label: '商品コード',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      name: {
        label: '商品名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      productTypeIds: {
        label: '商品種別',
        type: 'multiSelect',
        options: productTypes.map(_ => ({ ..._, label: _.name, value: _.id })),
      },
      isPart: {
        label: 'パーツ',
        type: 'boolean',
        initialValue: false,
      },
      isBody: {
        label: '本体',
        type: 'boolean',
        initialValue: false,
      },
      isOption: {
        label: 'オプション',
        type: 'boolean',
        initialValue: false,
      },
      approvalRequired: {
        label: '要承認',
        type: 'boolean',
        initialValue: false,
      },
      productTagIds: {
        label: '商品タグ',
        type: 'multiSelect',
        options: productTags.map(_ => ({ ..._, label: _.name, value: _.id })),
      },
      image: {
        label: '画像',
        type: 'file',
        inputProps: {
          accept: 'image/*',
        },
      },
      price: {
        label: '税込価格',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
      referralFeeRate: {
        label: '紹介料率(%)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        initialValue: 0,
        showsTextInput: true,
      },
      wholesalePriceRate: {
        label: '下代率(%)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        initialValue: 0,
        showsTextInput: true,
      },
      normalInventory: {
        label: '在庫',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
        hidden: _ => tenant?.useWms,
      },
      alertInventory: {
        label: 'アラート在庫数(下回るとバッジ表示)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        initialValue: 0,
        showsTextInput: true,
      },
      abroadInventoryLeadDays: {
        label: '海外在庫リードタイム(日)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        initialValue: 0,
        showsTextInput: true,
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: '連絡事項(カートに表示して注意喚起用）',
        type: 'text',
      },
      description: {
        label: '商品説明(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      thanksContent: {
        label: 'サンクス表示(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      recommendedProductIds: {
        label: 'おすすめ商品',
        type: 'multiSelect',
        options: products.map(_ => ({ ..._, label: _.label || _.name, value: _.id})),
      },
      troubleInquirySurveyId: {
        label: '不具合問合せ時のアンケートページ',
        type: 'select',
        options: sortBy(surveys.filter((_) => _.surveyGroupId === 'troubleInquiry'), _ => !!_.isHidden)
          .map((_) => ({ label: (_.isHidden ? '[非表示] ' : '') + _.title, value: _.id })),
      },
      troubleReinquirySurveyId: {
        label: '不具合再問合せ時のアンケートページ',
        type: 'select',
        options: sortBy(surveys.filter((_) => _.surveyGroupId === 'troubleReinquiry'), _ => !!_.isHidden)
          .map((_) => ({ label: (_.isHidden ? '[非表示] ' : '') + _.title, value: _.id })),
      },
      methodInquirySurveyId: {
        label: '乗り方・使い方問合せ時のアンケートページ',
        type: 'select',
        options: sortBy(surveys.filter((_) => _.surveyGroupId === 'methodInquiry'), _ => !!_.isHidden)
          .map((_) => ({ label: (_.isHidden ? '[非表示] ' : '') + _.title, value: _.id })),
      },
      methodReinquirySurveyId: {
        label: '乗り方・使い方再問合せ時のアンケートページ',
        type: 'select',
        options: sortBy(surveys.filter((_) => _.surveyGroupId === 'methodReinquiry'), _ => !!_.isHidden)
          .map((_) => ({ label: (_.isHidden ? '[非表示] ' : '') + _.title, value: _.id })),
      },
      packageNote: {
        label: '梱包指示備考追加テキスト',
        type: 'string',
        initialValue: '',
      },
      shippingName: {
        label: '品名 (配送用)',
        type: 'string',
      },
      orderTag: {
        label: '購入時タグ',
        type: 'text',
        initialValue: '',
        rows: 5,
      },
      memo: {
        label: 'メモ',
        type: 'text',
        initialValue: '',
      },
    };
  },
  getProductsWithInventory,
  prospectFields: ({ products }) => {
    return {
      benchmarkProductIds: {
        label: 'ベンチマーク商品',
        type: 'multiSelect',
        options: products.map(_ => ({ ..._, label: `[${_.code}] ${_.name}`, value: _.id })),
      },
    };
  },
  getProductId,
};
