import React, { useEffect, } from 'react';
import { pick, } from 'lodash';
import { toast } from 'react-toastify';
import qs from 'qs';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import UserPage from '../hocs/UserPage';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useLocale from '../hooks/useLocale';

const db = firebase.firestore();
const migrateOrderOwner = functions.httpsCallable('migrateOrderOwner');

function OrderOwnerMigrate(props) {
  const { user, location, history, } = props;
  const queryParams = useQueryParams();
  const { token, } = queryParams;
  useEffect(() => {
    (async () => {
      const { data: { status, errorMessage } } = await retry(_ => migrateOrderOwner({ token, }), { retries: 2 });
      if(status === 'ok') {
        await new Promise(_ => setTimeout(_, 100));
        history.push('/mypage/orders');
      } else if (status === 'failed') {
        toast.error(errorMessage);
      }
    })();
  }, []);

  return (
    <div className="order-owner-migrate h-100 container">
      <div className="py-5 d-flex justify-content-center">
        <div className="py-3">
          <span className="fas fa-spin fa-spinner mr-1" />
          処理中...
        </div>
      </div>
    </div>
  );
};

OrderOwnerMigrate.defaultSignUp = true;
OrderOwnerMigrate.signsUpWithFullFields = true;
export default UserPage(OrderOwnerMigrate);
