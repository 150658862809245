import firebase from 'firebase/app';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { mapValues } from 'lodash';
import { toast } from 'react-toastify';
import Field from '../Field';
import { auditData } from '../../shared/models/user';
import useFormState from '../hooks/useFormState';

export default function ExportApprovalFormModal({
  exportRequest,
  user,
  isOpen,
  fields,
  onClickClose,
  onSubmit,
  onCancel,
}) {
  const { ref } = exportRequest;
  const statedFields = useFormState(exportRequest, fields(), isOpen);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_?.isValid);
  const handleSubmit = async () => {
    const { approvalOrRejectionComment, downloadStartAt } = mapValues(statedFields, 'value');
    if (!window.confirm('本当に承認しますか？')) return;

    try {
      const updatedData = {
        approvalStatus: 'approved',
        approvalOrRejectionComment,
        downloadStartAt,
        approvedBy: auditData(user),
        approvedAt: new Date(),
        updatedBy: auditData(user),
      };
      await ref.update(updatedData);

      onSubmit && (await onSubmit({ ...exportRequest, ...updatedData }));
      toast.success('承認しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const handleCancel = async () => {
    const { approvalOrRejectionComment } = mapValues(statedFields, 'value');
    if (!window.confirm('本当に否認しますか？')) return;

    try {
      const updatedData = {
        approvalStatus: 'rejected',
        approvalOrRejectionComment,
        rejectedAt: new Date(),
        updatedBy: auditData(user),
      };
      await ref.update(updatedData);
      onCancel && (await onCancel({ ...exportRequest, ...updatedData }));
      toast.success('否認しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>承認</ModalHeader>
        <ModalBody>
          {Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
            return <Field key={fieldName} name={fieldName} documentName='exportRequest' {...(fieldSetting)} />;
          })}
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleSubmit} disabled={isUnsubmittable}>
            承認する
          </Button>
          <Button color='primary' outline onClick={handleCancel} disabled={isUnsubmittable}>
            否認する
          </Button>
          <Button onClick={onClickClose}>閉じる</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
