const { addDays, getDate, getYear, getMonth, } = require('date-fns');
const statuses = {
  initial: { label: '作業前' },
  received: { label: '入荷済' },
};

const displayDate = (date) => {
  const bufferedDate = addDays(date, 10);
  const days = getDate(bufferedDate);
  const suffix = (() => {
    switch(true) {
      case 1 <= days && days <= 10: return '上旬';
      case 11 <= days && days <= 20: return '中旬';
      case 21 <= days && days <= 27: return '下旬';
      case 28 <= days: return '末';
    }
  })();
  return `${getYear(bufferedDate)}年${getMonth(bufferedDate) + 1}月${suffix}`;
};

module.exports = {
  statuses,
  displayDate,
};
