import React, { useEffect, useState, useMemo, } from 'react';
import { Label, Button, Form, FormGroup, Input, Tooltip } from 'reactstrap';
import { sumBy, omitBy, upperFirst, uniqWith, isEqual, get, omit, camelCase, pick, isEmpty, mapValues, keyBy, uniq, } from 'lodash';
import { set } from 'lodash/fp';
import { useToggle, useAsync } from 'react-use';
import classnames from 'classnames';
import numeral from 'numeral';

import { toHankaku } from '../../shared/util';
import { fields, basicFields, ordererFields, } from '../../shared/models/rentalOrder';
import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import ChildForm from '../forms/ChildForm';
import ListForm from '../ListForm';
import Field from '../Field';
import SettingText from '../SettingText';
import AppButton from '../AppButton';
import RichTextContent from '../RichTextContent';
import { childFields } from '../../shared/models/user';
import { getAddressByPostalCode } from '../../util';

const { entries, keys, } = Object;
const checkEmailExistence = functions.httpsCallable('checkEmailExistence');

export default function RentalOrderContentProductsForm (props) {
  const { user, statedFields, contentProducts, contentProductIds, setContentProductIds, } = props;
  const contentProductsById = keyBy(contentProducts, 'id');
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const totalAmount = sumBy(contentProductIds, _ => contentProductsById[_]?.price);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    statedFields.setStarts(keys(statedFields));

    await props.onSubmit();
  };

  return (
    <Form onSubmit={onSubmit}>
      <div style={{ marginBottom: 80 }}>
        <div className="mt-4">
          {contentProducts.map((contentProduct) => {
            const {
              id,
              name,
              image,
              price,
              description,
            } = contentProduct;

            return (
              <div key={id} className="border-top py-3">
                <div className="mt-2 d-flex">
                  <div>
                    <img src={image?.url} style={{ maxHeight: 100 }} />
                  </div>
                  <RichTextContent className="ml-2 small flex-fill" html={description || ''} />
                  <div className="ml-2 d-flex flex-column justify-content-end">
                    <div className="mb-2 text-center">&yen;{numeral(price).format('0,0')}</div>
                      <Button
                        className="text-nowrap"
                        color="secondary"
                        size="sm"
                        onClick={(_) => setContentProductIds(uniq([...contentProductIds, id]))}
                        disabled={contentProductIds.includes(id)}
                      >
                        <span className="fas fa-plus mr-1" />
                        選択
                      </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-3 text-muted small">※ 価格表示は税込です。</div>
      </div>
      <div className="position-sticky" style={{ bottom: 10, left: 0, right: 0 }}>
        <div className="card py-3 d-flex">
          <div className="d-flex flex-column gap-3">
            {
              !isEmpty(contentProductIds) && (
                <div className="d-flex flex-column overflow-auto px-3" style={{ maxHeight: '250px' }}>
                  <div className="flex-fill">
                    {contentProductIds.map((contentProductId) => {
                      const contentProduct = contentProductsById[contentProductId];

                      return (
                        <div key={contentProductId} className="d-flex align-items-center justify-content-between mb-1 small">
                          <div>
                            <span
                              className="fas fa-times text-danger cursor-pointer"
                              onClick={(_) => setContentProductIds(contentProductIds.filter(_ => _ !== contentProductId))}
                            />
                            <span className="ml-2">{contentProduct?.name}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="text-nowrap flex-fill">合計金額: &yen;{numeral(totalAmount).format('0,0')}</div>
                </div>
              )
            }
            <div className="d-flex justify-content-between align-items-center px-3">
              <AppButton className="cancel text-nowrap flex-fill" size="sm" color="secondary" onClick={props.onClickBack}>
                <span className="fas fa-arrow-left mr-1" />
                戻る
              </AppButton>
              {
                isEmpty(contentProductIds) ? (
                  <AppButton
                    className="text-nowrap save ml-4 flex-fill text-muted"
                    size="sm"
                    type="submit"
                    color="link"
                    onClick={onSubmit}
                  >
                    <span className="fas fa-arrow-right mr-1" />
                    スキップして次へ
                  </AppButton>
                ) : (
                  <AppButton
                    className="text-nowrap save ml-4 flex-fill"
                    size="sm"
                    type="submit"
                    color="primary"
                    onClick={onSubmit}
                  >
                    <span className="fas fa-arrow-right mr-1" />
                    次へ
                  </AppButton>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
