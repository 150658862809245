import React from 'react';
import classnames from 'classnames';

import { lectureLevels } from '../shared/i18n';

export default function LectureLevelDisplay(props) {
  const { event, lectureLevel, className, } = props;

  return lectureLevel === 'none' ? null : (
    <div className={classnames('bg-secondary rounded py-2 text-center', className)}>
      <div className="small font-weight-bold">レベル</div>
      <div className="mt-1 large font-weight-bold">
        {lectureLevels[event?.lang || 'ja'][lectureLevel]}
      </div>
    </div>
  );
};
