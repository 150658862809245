import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { omit, keyBy } from 'lodash';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import { fields, } from '../../shared/models/locale';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';

const db = firebase.firestore();
const localesRef = db.collection('locales');

export default AdminPage(function AdminLocales (props) {
  const { user, tenant, } = props;
  const locales = useCollectionSubscriptionInTenant(localesRef, [], { initialItems: null });
  useEffect(() => {
    if(locales?.length === 0) ['ja', 'en'].map(_ => db.collection('locales').doc([tenant.id, _].join('__')).set({ tenantId: tenant.id, name: '', content: '' }));
  }, [locales]);

  return (
    <div>
      <div className="admin-locales container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>言語</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={_ => db.collection('locales').doc([tenant.id, _.code].join('__'))} FormModal={ModelFormModal} processValues={_ => omit(_, ['code'])} formProps={{ title: '言語追加', fields, }} />
        </div>
        <div>
          <table className="table">
            <thead className="thead-light text-center">
              <tr>
                <th style={{ minWidth: 100 }}>言語名</th>
                <th></th>
              </tr>
            </thead>
              <tbody>
                {
                  locales?.map(({ ref, id, name, content, }) => {
                    return (
                      <tr key={id} id={id}>
                        <td>
                          {name}
                        </td>
                        <td>
                          <div className="text-nowrap d-flex justify-content-end gap-1">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '言語 編集', fields: omit(fields(), ['code']), style: { minWidth: 900 }, }} />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

