import React from 'react';
import { groupBy, sortBy, get, isEmpty, omit, omitBy, isUndefined, pick } from 'lodash';
import { keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import dedent from 'dedent';
import { Button } from 'reactstrap';

import firebase, { functions } from '../../firebase';
import { canUpdateMagazine } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { userConditionText, } from '../../shared/util';
import { deliveryMethods, emailSignature } from '../../shared/config';
import { fields, orderTypes, entryTypes, scheduleTypes, } from '../../shared/models/magazine';
import TenantLink from '../TenantLink';
import QuerySelector from '../QuerySelector';
import useQueryParams from '../hooks/useQueryParams';
import { fields as groupFields } from '../../shared/models/magazineGroup';

const db = firebase.firestore();
const sendSms = functions.httpsCallable('sendSms');
const sendEmail = functions.httpsCallable('sendEmail');
const sendMobile = functions.httpsCallable('sendMobile');

const magazineGroupsRef = db.collection('magazineGroups');

export default AdminPage(function AdminMagazines (props) {
  const { tenant, user } = props;
  const { groups: groupsForFilter } = useQueryParams();
  const magazines = useCollectionSubscriptionInTenant(db.collection('magazines').orderBy('createdAt'));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts
    .filter((_) => _.isBody || _.isOption)
    .map((_) => ({
      ..._,
      label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`,
    }));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), _ => _.createdAt.toDate());
  const productTypesById = keyBy(productTypes, 'id');
  const lectures = useCollectionSubscriptionInTenant(db.collectionGroup('lectures').orderBy('date'));
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));
  const userTagsById = keyBy(userTags, 'id');
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const sortedEvents = sortBy(events, (event) => {
    const date = get(lecturesGroupedByEventId, `${event.id}.0.date`);
    return date && date.toDate();
  });
  const magazineGroups = sortBy(useCollectionSubscriptionInTenant(magazineGroupsRef), _ => _.createdAt.toDate());
  const magazineGroupsById = keyBy(magazineGroups, 'id');
  const groupOptions = magazineGroups.map((_) => ({ label: _.name, value: _.id }));

  const onCreateMagazineGroup = async (value) => {
    const ref = await magazineGroupsRef.add({ tenantId: user.tenantId, name: value, createdAt: new Date() });
    return ref.id;
  }

  let filteredMagazines = magazines;
  if (!isEmpty(groupsForFilter)) {
    filteredMagazines = filteredMagazines.filter((_) => groupsForFilter.some(group => (get(_, 'magazineGroupIds') || []).includes(group)));
  }

  return (
    <div>
      <div className="admin-inquiry-types container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>ステップSMS配信管理</h4>
              </div>
              <div className='d-flex justify-content-between flex-wrap gap-2 mb-3'>
                <QuerySelector
                  paramName='groups'
                  className='ml-0'
                  width={400}
                  isMulti
                  options={groupOptions}
                  label='グループで絞込み'
                />
                <div className="d-flex justify-content-end align-items-end gap-2">
                  <ModalButton
                    title="グループ管理"
                    content={(_) => <MagazineGroups user={user} magazineGroups={magazineGroups} />}
                    modalProps={{ style: { minWidth: 1300 } }}
                  >
                    グループ管理
                  </ModalButton>
                  <ModalButton Modal={ModelFormModal} modalProps={{
                    title: 'グループによる一括配信停止',
                    submitLabel: '配信停止',
                    fields: {
                      magazineGroupId: {
                        label: 'グループ',
                        type: 'select',
                        options: magazineGroups.map(_ => ({ label: _.name, value: _.id })),
                        validations: {
                          required: _ => !isEmpty(_),
                        },
                      },
                    },
                    onSubmit: async ({ magazineGroupId }) => {
                      try {
                        await Promise.all(magazines.filter(({ magazineGroupIds }) => (magazineGroupIds || []).includes(magazineGroupId)).map(({ ref }) => ref.update({ isSuspended: true })));
                        toast.success('一括配信停止しました');
                      } catch(e) {
                        console.error(e);
                        toast.error('失敗しました');
                      }
                    },
                  }}>
                    一括配信停止
                  </ModalButton>
                  <AddInTenantButton
                    itemRef={db.collection('magazines').doc()}
                    FormModal={ModelFormModal}
                    formProps={{ title: 'はじめナビSMS追加', fields: fields({ products: selectableProducts, productTypes, events: sortedEvents, userTags, magazineGroups, onCreateMagazineGroup, useSms: tenant.useSms, useMobile: tenant.useMobile }), }}
                    disabled={!canUpdateMagazine(user)}
                  />
                </div>
              </div>
              <div className="overflow-auto">
                {
                  filteredMagazines.length > 0 ? (
                    <table className="table">
                      <thead className="thead-light text-center text-nowrap">
                        <tr>
                          <th>グループ</th>
                          <th>名称</th>
                          <th>注文条件</th>
                          <th>イベント参加条件</th>
                          <th>アカウント条件</th>
                          <th>スケジュール</th>
                          <th>本文</th>
                          <th>配信方法</th>
                          <th>配信停止</th>
                          <th>メモ</th>
                          <th style={{ minWidth: 120 }}></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          filteredMagazines.map((magazine) => {
                            const {
                              id,
                              ref,
                              name,
                              orderType = 'any',
                              conditionProductTypeIds,
                              conditionProductIds,
                              entryType = 'any',
                              conditionEventIds,
                              scheduleType,
                              daysAfter,
                              body,
                              isSuspended,
                              deliveryMethod,
                              emailSubject,
                              magazineGroupIds = [],
                              note,
                              mobileSubject,
                              mobileMessage,
                              mobileNotificationType,
                              mobileNotificationContentType,
                              mobileUrl,
                              mobileHtml,
                              mobileTypeDisplayName,
                              fixUntil
                            } = magazine;
                            const content = { text: mobileMessage, url: mobileUrl, html: mobileHtml }[mobileNotificationContentType] || '';
                            const onClickSendSms = async ({ displayName, phone, email }) => {
                              try {
                                if (deliveryMethod === 'email') {
                                  await sendEmail({
                                    from_name: tenant.name,
                                    to: [
                                      {
                                        email,
                                        name: displayName,
                                        type: 'to',
                                      },
                                    ],
                                    subject: `${emailSubject}`,
                                    text: dedent`
                                      ${body.replace(/\{\{name\}\}/g, displayName)}
                                    `,
                                  })
                                } else if (deliveryMethod === 'sms') {
                                  await sendSms({ targets: [{ displayName, phone }], text: body, });
                                } else {
                                  await sendMobile({ targets: [{ email, displayName }], subject: mobileSubject, contentType: mobileNotificationContentType, content, type: mobileNotificationType, typeDisplayName: mobileTypeDisplayName, fixUntil: fixUntil?.toDate().toISOString() });
                                }
                                toast.success('送信しました');
                              } catch(e) {
                                console.error(e);
                                toast.error('失敗しました');
                              }
                            };
                            const handleClickCopy = async () => {
                              if (!window.confirm('ステップSMSをコピーしますか？')) return;
                              try {
                                await db.collection('magazines').add({
                                  ...omit(magazine, ['id', 'ref', 'createdAt', 'addedBy']),
                                  name: `${magazine.name} - コピー`,
                                  status: 'copied',
                                  createdAt: new Date(),
                                  addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
                                });
                                toast.success('コピーしました。');
                              } catch (e) {
                                console.error(e);
                                toast.error('失敗しました');
                              }
                            };

                            return (
                              <tr key={id} id={id}>
                                <td>
                                  <div className="d-flex flex-wrap gap-1">
                                    {magazineGroupIds.map(_ => <div className="badge badge-secondary">{magazineGroupsById[_]?.name}</div>)}
                                  </div>
                                </td>
                                <td>
                                  <TenantLink to={`/admin/magazines/${id}`}>{name}</TenantLink>                                  
                                </td>
                                <td style={{ whiteSpace: 'pre-line' }}>
                                  {orderTypes[orderType]?.label}
                                  {
                                    orderType === 'ordered' && (
                                      <div style={{ maxWidth: 300, maxHeight: 200, overflow: 'hidden' }}>
                                        <div className="small text-muted">
                                          {conditionProductTypeIds?.map(_ => productTypesById[_]?.name).join(',')}
                                        </div>
                                        <div className="small text-muted">
                                          {conditionProductIds?.map(_ => productsById[_]).map(_ => `[${_?.code}] ${_?.name}`).join(',')}
                                        </div>
                                      </div>
                                    )
                                  }
                                </td>
                                <td>
                                  {entryTypes[entryType]?.label}
                                  {
                                    entryType === 'entried' && (
                                      <div className="small text-muted">
                                        {conditionEventIds?.map(_ => eventsById[_]?.name).join(',')}
                                      </div>
                                    )
                                  }
                                </td>
                                <td className="text-muted small">
                                  {userConditionText(magazine, { userTagsById, })}
                                </td>
                                <td>
                                  <span>{scheduleTypes[scheduleType]?.label}</span>
                                  <span>{daysAfter}日後</span>
                                </td>
                                <td style={{ whiteSpace: 'pre-line' }}>
                                  <div style={{ maxWidth: 300, maxHeight: 200, overflow: 'hidden' }}>{body || content}</div>
                                </td>
                                <td>
                                  {deliveryMethod && deliveryMethods[deliveryMethod].label}
                                </td>
                                <td>{isSuspended && '配信停止'}</td>
                                <td style={{ whiteSpace: 'pre-line' }}>
                                  {note}
                                </td>
                                <td>
                                  <TenantLink to={`/admin/magazines/${magazine.id}/deliveryHistories`} target="_blank">配信履歴</TenantLink>
                                </td>
                                <td className="text-nowrap text-right">
                                  <ModalButton Modal={ModelFormModal} modalProps={{
                                    title: 'テスト送信',
                                    submitLabel: '送信',
                                    fields: {
                                      displayName: { type: 'string', label: '名前', },
                                      ...(['email', 'mobile'].includes(magazine.deliveryMethod) ? { email: { type: 'string', label: 'メールアドレス' } } : { phone: { type: 'string', label: '電話番号' } })
                                    },
                                    onSubmit: onClickSendSms,
                                  }}>
                                    <span className="fas fa-paper-plane mr-1" />
                                    テスト送信
                                  </ModalButton>
                                  <Button className="ml-2" onClick={handleClickCopy} disabled={!canUpdateMagazine(user)}>
                                    <span className="fas fa-copy mr-1" />
                                    コピー
                                  </Button>
                                  <EditButton itemRef={ref} className="ml-2" FormModal={ModelFormModal} formProps={{ title: 'はじめナビSMS編集', fields: fields({ products: selectableProducts, productTypes, events: sortedEvents, userTags, magazineGroups, onCreateMagazineGroup, useSms: tenant.useSms, useMobile: tenant.useMobile }), }} disabled={!canUpdateMagazine(user)} />
                                  <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateMagazine(user)} />
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      はじめナビSMSは未登録です
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function MagazineGroups(props) {
  const { user, magazineGroups, } = props;

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <AddInTenantButton
          itemRef={db.collection('magazineGroups').doc()}
          FormModal={ModelFormModal}
          formProps={{ title: 'グループ追加', fields: groupFields() }}
          disabled={!canUpdateMagazine(user)}
        />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>グループ名</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {magazineGroups.map((magazineGroup) => {
            const { id, ref, name } = magazineGroup;
            return (
              <tr key={id}>
                <td>{name}</td>
                <td className="text-nowrap text-right">
                  <EditButton
                    itemRef={ref}
                    className="ml-1"
                    FormModal={ModelFormModal}
                    formProps={{ documentName: 'magazineGroup', title: 'グループ編集', fields: groupFields() }}
                  />
                  <DeleteButton itemRef={ref} className="ml-1" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
