const { omit, keyBy, range, isEmpty, camelCase } = require('lodash');
const { getYear } = require('date-fns');
const { format: formatDate, addMinutes } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');
const numeral = require('numeral');
const dedent = require('dedent');
const qs = require('qs');

const { ordererFields, destinationFields } = require('./order');
const { fieldDisplayValue } = require('../util');
const { supportMeans } = require('../config');

const { keys, entries } = Object;

const statuses = {
  initial: { label: '未対応', color: 'warning' },
  supporting: { label: '対応中', color: 'info' },
  awaiting: { label: '待ち', color: 'success' },
  supported: { label: '対応完了', color: 'secondary' },
};

const itemFields = ({ products = [] } = {}) => {
  const productsById = keyBy(products, 'id');
  return {
    productId: {
      label: '商品',
      type: 'select',
      options: products.map((_) => ({ label: _.label || _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    quantity: {
      label: '数量',
      type: 'integer',
      validations: {
        greaterThan0: (_) => _ > 0,
      },
    },
  };
};

const fields = (...args) => {
  const [{ inquiryTypes = [], inquiryTypeChildren = [] }] = args;
  return {
    inquiryTypeId: {
      label: 'お問合せカテゴリ',
      type: 'select',
      options: inquiryTypes.filter((_) => !_.isHidden).map((_) => ({ value: _.id, label: _.subject })),
      validations: {
        required: (v) => v != null,
      },
      inputProps: {
        isSearchable: false,
      },
    },
    inquiryTypeChildId: {
      label: 'お問合せ子カテゴリ',
      type: 'select',
      options: (s) =>
        inquiryTypeChildren
          .filter((_) => !_.isHidden)
          .filter((_) => _.parentId === s?.inquiryTypeId)
          .map((_) => ({ value: _.id, label: _.subject })),
      validations: {
        required: (v) => v != null,
      },
      inputProps: {
        isSearchable: false,
      },
    },
    description: {
      label: 'お問合せ内容',
      type: 'text',
      rows: 5,
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    files: {
      label: '画像や動画（複数選択可）',
      type: 'files',
      inputProps: {
        accept: 'image/*,video/*',
      },
      topHint: '商品の組立、使い方、不具合の場合、画像や動画を添付してください。',
    },
    callableTime: {
      label: '電話可能時間',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    ...omit(ordererFields(...args), ['route']),
  };
};

const shipmentFields = () => {
  return {
    shipmentMessage: {
      type: 'text',
      label: '発送メールでのユーザーへのメッセージ',
      rows: 6,
    },
    shipmentPackageNote: {
      type: 'text',
      label: '発送時の梱包指示備考',
      hint: '最大111文字までで指定してください',
      validations: {
        maxLength: v => (v || '').length <= 111,
      },
    },
  };
};

const commentFields = () => {
  return {
    body: {
      type: 'text',
      label: 'コメント内容',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const reimbursementRequestFields = () => {
  return {
    amount: {
      label: '立替金額',
      type: 'integer',
      validations: {
        required: (_) => _,
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
    },
    receiptFiles: {
      label: '領収書',
      type: 'files',
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        accept: 'image/*',
      },
      hint: '領収書の画像をアップロードして下さい。複数アップロード可\n',
    },
    description: {
      label: '連絡事項',
      type: 'text',
    },
  };
};

const reimbursementResultFields = () => {
  return {
    date: {
      label: '立替工賃 - 支払日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
    },
    amount: {
      label: '立替工賃 - 支払金額',
      type: 'integer',
      validations: {
        required: (_) => _,
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
      showsTextInput: true,
    },
    note: {
      label: '立替工賃 - 備考',
      type: 'text',
    },
  };
};

const approvalRequestFields = () => {
  return {
    approvalOrRejectionComment: {
      label: '承認/否認コメント',
      type: 'text',
      readOnly: () => true,
    },
    approvalRequestComment: {
      label: '承認申請コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const approvalFields = () => {
  return {
    approvalRequestComment: {
      label: '承認申請コメント',
      type: 'text',
      readOnly: () => true,
    },
    approvalOrRejectionComment: {
      label: '承認/否認コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const supportContentsFields = ({ surveys = [] } = {}) => {
  return {
    supportMeans: {
      label: '対応方法',
      type: 'multiSelect',
      options: entries(supportMeans).map(([key, value]) => ({ label: value, value: key })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    supportSummary: {
      label: '対応内容',
      type: 'text',
      validations: {
        required: (_) => _,
      },
    },
    supportSurvey: {
      label: '対応完了後アンケート',
      type: 'select',
      options: surveys.map(_ => ({ label: _.title, value: _.id })),
      initialValue: '',
    },
    supportSurveySentDate: {
      label: '送信日時',
      type: 'datetime',
      validations: {
        laterThanNow: v => v > new Date()
      },
      hidden: _ => !_.supportSurvey,
    }
  };
};

const activityTypes = {
  startSupportOfInquiry: {
    text: (_) => '対応開始',
  },
  editInquiry: {
    text: (_) => 'パーツ編集',
  },
  finishSupportOfInquiry: {
    text: (_) => '対応完了',
  },
  unfinishSupportOfInquiry: {
    text: (_) => '対応再開',
  },
  createOrderOfInquiry: {
    text: (_) => '発送',
  },
  updatePicOfInquiry: {
    text: (_) => '担当変更',
  },
  startReimbursementOfInquiry: {
    text: (_) => '立替受付開始',
  },
  finishReimbursementOfInquiry: {
    text: (_) => '立替完了',
  },
  cancelReimbursementOfInquiry: {
    text: (_) => '立替受付停止',
  },
  requestedApprovalOfInquiry: {
    text: (_) => '承認申請',
  },
  approvedInquiry: {
    text: (_) => '承認',
  },
  rejectedInquiry: {
    text: (_) => '否認',
  },
  editDestinationOfInquiry: {
    text: (_) => '送り先変更',
  },
  sendInquiryEmail: {
    text: (_) => 'メール送信',
  }
};

const confirmMailBody = (inquiry, inquiryTypes, inquiryTypeChildren) => {
  const { id, createdAt } = inquiry;
  return dedent`
    下記のお問合せを受付しました。

    [問合せ番号] ${id}
    [日時] ${formatDate(utcToZonedTime(createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}

    ---------- お問合せ内容 ----------

    ${entries(omit(fields({ inquiryTypes, inquiryTypeChildren }), ['files']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(inquiry[fieldName], { ...fieldSettings, values: inquiry })}`;
      })
      .join('\n')}

  `;
};

const shipmentMailBody = (inquiry, products, productTypes) => {
  const { id, items, createdAt, shipmentMessage = '', orderId = '' } = inquiry;
  const productsById = keyBy(products, 'id');
  const productOrPartText = items.some(({ productId }) => productsById[productId]?.isBody) ? '商品' : 'パーツ';

  return dedent`
    ${inquiry.name} 様

    新しい${productOrPartText}をお送りさせて頂きます。

    ${shipmentMessage}

    [問合せ番号] ${id}

    ---------- 発送${productOrPartText} ----------

    [注文番号] ${orderId}

    [${productOrPartText}]
    ${items
      .map(({ productId, quantity }) => {
        const { name: productName } = productsById[productId];
        return `${productName} ${quantity}個`;
      })
      .join('\n')}

    ---------- お届け先情報 ----------

    ${entries(destinationFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(shippingDestination(inquiry)[fieldName], fieldSettings)}`;
      })
      .join('\n')}
    
    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(inquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}
  `;
};

const reimbursementRequestMailBody = (inquiry) => {
  const {
    id,
    name,
    reimbursementRequest: { amount, description, requestedAt, requestedBy },
  } = inquiry;

  return dedent`
    ${name} 様

    立替工賃の申請を受付ました。

    [問合せ番号] ${id}

    ---------- 申請内容 ----------

    [申請金額] ${numeral(amount).format('0,0')}円
    [申請日時] ${formatDate(utcToZonedTime(requestedAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}
    [申請者] ${requestedBy.displayName} (${requestedBy.email})
    [連絡事項]
    ${description}

    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(inquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}
  `;
};

const shippingDestination = (inquiry) => {
  return keys(destinationFields()).reduce((x, y) => {
    return {
      ...x,
      [y]: inquiry[y] || inquiry[camelCase(y.replace(/^destination/, ''))] || null,
    };
  }, {});
};

const supportSurveyMailBody = (inquiry, url) => {
  const { name, createdBy } = inquiry;
  return dedent`
    ${createdBy?.displayName || name} 様

    この度はお問合せありがとうございました。

    宜しければ以下のURLからアンケートへのご回答をお願いします。

    ${url}
  `;
}

const emailFields = () => {
  return {
    body: {
      label: 'メール本文',
      type: 'text',
    },
    files: {
      label: '添付ファイル',
      type: 'files',
    },
  };
};

const replyEmailBody = (inquiry, body, inquiryTypes, inquiryTypeChildren, host) => {
  const { id, name, items, createdAt, createdBy } = inquiry;
  return dedent`
    ${createdBy?.displayName || name} 様

    お問合せへのメッセージが届いています。
    以下のURLからご確認ください。

    ${host || '{host}'}/mypage/inquiries?messagesInquiryId=${inquiry.id}



    [問合せ番号] ${id}

    ---------- お問い合わせ内容 ----------

    ${entries(omit(fields({ inquiryTypes, inquiryTypeChildren }), ['files']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(inquiry[fieldName], { ...fieldSettings, values: inquiry })}`;
      })
      .join('\n')}
  `;
}

module.exports = {
  fields,
  itemFields,
  shipmentFields,
  commentFields,
  reimbursementRequestFields,
  reimbursementResultFields,
  approvalRequestFields,
  approvalFields,
  activityTypes,
  confirmMailBody,
  shipmentMailBody,
  reimbursementRequestMailBody,
  statuses,
  shippingDestination,
  supportContentsFields,
  supportSurveyMailBody,
  emailFields,
  replyEmailBody,
};
