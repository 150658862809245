import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { get, omitBy, isUndefined, pick, } from 'lodash';
import Toggle from 'react-toggle'
import { format as formatDate } from 'date-fns'
import { canUpdateAgent, } from '../../shared/abilities';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { commentFields, } from '../../shared/models/agent';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { agentUserFields } from '../../shared/models/user';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const usersRef = db.collection('users');

export default AdminPage(function AdminAgentComments (props) {
  const { user, match: { params: { agentId } }, addBreadNavValues } = props;
  const [showsFormModal, toggleFormModal] = useToggle(false);
  const agentRef = agentsRef.doc(agentId);
  const { firebaseUser } = useFirebaseUser();
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const comments = useCollectionSubscription(agent && agent.ref.collection('agentComments').orderBy('createdAt'), [agent]);
  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent-qr-urls container py-5 position-relative">
        <AdminAgentHeader activeTab="comments" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div>
                <div className="mt-4 d-flex justify-content-end mb-3">
                  <AddInTenantButton label="新規コメント" itemRef={agent.ref.collection('agentComments').doc()} processValues={_ => ({ ..._, createdBy: pick(user, ['uid', 'email', 'displayName']), })} FormModal={ModelFormModal} formProps={{ title: '新規コメント', fields: commentFields, }} />
                </div>
                <div>
                  {
                    comments.length > 0 ? (
                      <div>
                        {
                          comments.map(({ id, body, createdAt, createdBy, }) => {
                            return (
                              <div key={id} className="card p-3 mb-3">
                                <div className="small text-muted mb-1">
                                  {createdBy.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                                </div>
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  {body}
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    ) : (
                      <div>
                        コメントはまだありません
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
