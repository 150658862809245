const { pick, chunk, uniq, keyBy, isEmpty, pickBy, sortBy } = require('lodash');
const { format: formatDate, differenceInYears, addDays, addHours, startOfDay, endOfDay, } = require('date-fns');

const { getDocumentData, getCollectionData, } = require('../firebase');
const { vehicleExperiences, } = require('./user');
const { userFiltering: { dateTypes }, smsOrderTypes: orderTypes, smsEntryTypes: entryTypes, } = require('../config');

const { entries } = Object;

const statuses = {
  create: { label: '作成前', color: 'secondary', },
  creating: { label: '作成中', color: 'secondary', },
  preview: { label: '配信待ち', color: 'secondary', },
  initial: { label: '処理待ち', color: 'warning', },
  processing: { label: '処理中', color: 'info', },
  completed: { label: '処理完了', color: 'success', },
  failed: { label: '処理失敗', color: 'danger', },
  error: { label: 'エラー', color: 'danger', },
};

module.exports = {
  fields: ({ products = [], envelopeProducts = [], productTypes = [], events = [], userTags = [], } = {}) => {
    return {
      dateType: {
        label: '日付範囲種別',
        type: 'select',
        options: entries(dateTypes).map(([k, v]) => ({ label: v.label, value: k })),
        initialValue: '',
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      startDate: {
        label: '開始日',
        type: 'date',
      },
      endDate: {
        label: '終了日',
        type: 'date',
      },
      orderType: {
        label: '注文種別',
        type: 'select',
        options: entries(pick(orderTypes, 'ordered')).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
        initialValue: 'ordered',
      },
      conditionProductTypeIds: {
        label: '注文商品種別',
        type: 'multiSelect',
        options: productTypes.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      conditionProductIds: {
        label: '注文商品',
        type: 'multiSelect',
        options: products.map(_ => ({ label: _.label || _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      isBodyOnly: {
        label: '本体のみ',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.orderType !== 'ordered',
      },
      entryType: {
        label: 'イベント参加種別',
        type: 'select',
        options: entries(entryTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      conditionEventIds: {
        label: '参加イベント',
        type: 'multiSelect',
        options: events.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.entryType !== 'entried',
      },
      isIncludedCancellOrAbort: {
        label: 'キャンセル・中止を含む',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.entryType !== 'entried',
      },
      userTagIds: {
        label: 'ユーザータグ',
        type: 'multiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
        hint: 'ORで判定します',
      },
      userChildAgeMin: {
        label: 'お子様の年齢下限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildAgeMax: {
        label: 'お子様の年齢上限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildVehicleExperiences: {
        label: 'お子様乗り物経験',
        type: 'multiSelect',
        options: ['未選択', ...vehicleExperiences].map(_ => ({ label: _, value: _, })),
      },
      envelopeProductIds: {
        label: '郵送物',
        type: 'multiSelect',
        options: envelopeProducts.map(_ => ({ label: _.label || _.name, value: _.id })),
      },
    };
  },
  orderTypes,
  entryTypes,
  dateTypes,
  statuses,
};
