import React from 'react';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import firebase, { functions } from '../firebase';
import { getDocumentData } from 'src/shared/firebase';
const auth = firebase.auth();

const db = firebase.firestore();
const sendEmailVerification = functions.httpsCallable('sendEmailVerification');

export default function PleaseConfirmEmail(props) {
  const { firebaseUser } = props;
  const location = useLocation();
  const handleClick = async () => {
    try {
      const user = await getDocumentData(db.collection('users').doc(firebaseUser.uid));
      await sendEmailVerification({
        ...pick(user, ['uid', 'email', 'displayName', 'phone', 'prefecture', 'route']),
        pathname: location.pathname,
      });
      toast.success('メールを再送しました。');
    } catch (e) {
      console.error(e);
      toast.error('メール再送に失敗しました。');
    }
  };
  return (
    <div>
      <p>
        ご登録いただいたメールアドレスにメールをお送りしました。
        <br />
        そちらのメール本文内のリンクをクリックして、登録を完了してください。
      </p>
      <p className="text-muted small">
        ※ メールが届かない場合は
        <span onClick={handleClick} className="text-info cursor-pointer">
          メールを再送
        </span>
        できます。また、最初からやり直す場合は、一度
        <span onClick={(_) => auth.signOut()} className="text-primary cursor-pointer">
          ログアウト
        </span>
        してください。
      </p>
    </div>
  );
}
