const { mapValues, groupBy, sumBy, isEmpty, } = require('lodash');
const { format: formatDate, } = require('date-fns');

const { entries } = Object;

const types = {
  receive: {
    label: '入荷',
  },
  changeCode: {
    label: '品番変更',
  },
};

module.exports = {
  types,
  fields: () => {
    return {
      type: {
        label: '種別',
        type: 'select',
        options: entries(types).map(([k, v]) => ({ value: k, label: v.label, })),
        initialValue: 'ship',
      },
      receivedOn: {
        label: '入荷日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
      note: {
        label: 'メモ',
        type: 'text',
        rows: 5,
      },
    };
  },
  itemFields: ({ products, }) => {
    return {
      productId: {
        label: '商品',
        type: 'select',
        options: products.map(_ => ({ label: _.code, value: _.id })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
    };
  },
};
