import React from 'react';
import { sortBy, get, pick } from 'lodash';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import randomstring from 'randomstring';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { fieldDisplayValue, } from '../../shared/util';
import { canUpdateCouponTemplate } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields, } from '../../shared/models/couponTemplate';

const db = firebase.firestore();
const productsRef = db.collection('products');
const couponTemplatesRef = db.collection('couponTemplates');

export default AdminPage(function AdminCouponTemplates (props) {
  const { user } = props;
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const couponTemplates = sortBy(useCollectionSubscriptionInTenant(couponTemplatesRef), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="admin-coupon-templates container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>優待テンプレート一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={couponTemplatesRef.doc()} FormModal={ModelFormModal} formProps={{ title: '優待テンプレート追加', fields: fields({ products }), }} disabled={!canUpdateCouponTemplate(user)} />
        </div>
        <div>
          {
            couponTemplates.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>テンプレート名</th>
                    <th>クーポン種別</th>
                    <th>商品</th>
                    <th>割引内容</th>
                    <th>送料無料</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    couponTemplates.map((couponTemplate) => {
                      const { id, ref, name, type, productIds, valueType, discountAmount, discountRate, isShipmentFree, } = couponTemplate;
                      const discountDisplay = ({
                        amount: `${numeral(discountAmount).format()} 円`,
                        rate: `${numeral(discountRate).format()} %`,
                      })[valueType];

                      return (
                        <tr key={id}>
                          <td>
                            {name}
                          </td>
                          <td>
                            {fieldDisplayValue(type, fields().type)}
                          </td>
                          <td>
                            {fieldDisplayValue(productIds, fields({ products }).productIds).map(_ => <div>{_}</div>)}
                          </td>
                          <td>
                            {discountDisplay}
                          </td>
                          <td>
                            {isShipmentFree && '送料無料'}
                          </td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '優待テンプレート編集', fields: fields({ products }), }} disabled={!canUpdateCouponTemplate(user)} />
                            <DeleteButton className="ml-2" itemRef={ref} disabled={!canUpdateCouponTemplate(user)} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                優待テンプレートは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
