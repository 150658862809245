import { format as formatDate } from 'date-fns';
import { keyBy } from 'lodash';
import numeral from 'numeral';
import UserPage from '../hocs/UserPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import LoadingScreen from '../LoadingScreen';
import Chat from '../Chat';
import InquiryReimbursementRequestButton from '../InquiryReimbursementRequestButton';
import { TroubleInquiry } from '../../types/troubleInquiry';
import { Product } from '../../types/product';
import firebase from '../../firebase';
import { reimbursementRequestFields } from '../../shared/models/troubleInquiry';

const db = firebase.firestore();
const troubleInquiriesRef = db.collection('troubleInquiries');
const productsRef = db.collection('products');

export default UserPage(function MypageTroubleInquiry(props: any) {
  const {
    user,
    match: {
      params: { troubleInquiryId },
    },
  } = props;
  const troubleInquiryRef = troubleInquiriesRef.doc(troubleInquiryId);
  const troubleInquiry = useDocumentSubscription(troubleInquiryRef) as TroubleInquiry | undefined;
  const productRef = troubleInquiry && productsRef.doc(troubleInquiry.productId);
  const product = useDocumentSubscription(productRef, [troubleInquiry]) as Product | undefined;
  const messagesRef = troubleInquiryRef.collection('chatMessages');
  const partProductRefs =
    troubleInquiry?.items && troubleInquiry.items.map(({ productId }) => productsRef.doc(productId));
  const partProducts = useDocumentsFetch(partProductRefs, [troubleInquiry]);
  const partProductsById = keyBy(partProducts, 'id');

  return troubleInquiry ? (
    <div className='container pt-3 pt-md-5'>
      <div className='card px-3 pt-3'>
        <div className='table table-borderless table-sm'>
          <tbody>
            <tr>
              <th className='small text-muted align-middle text-nowrap'>お問合せ番号</th>
              <td>{troubleInquiry.id}</td>
            </tr>
            <tr>
              <th className='small text-muted align-middle text-nowrap'>お問合せ日時</th>
              <td>{formatDate(troubleInquiry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
            </tr>
            <tr>
              <th className='small text-muted align-middle text-nowrap'>商品</th>
              <td>{product?.name || ''}</td>
            </tr>
            <tr>
              <th className='small text-muted align-middle text-nowrap'>故障箇所</th>
              <td>{troubleInquiry.troublePoints.join(', ')}</td>
            </tr>
            <tr>
              <th className='small text-muted align-middle'>症状</th>
              <td>{troubleInquiry.troubleSituations.join(', ')}</td>
            </tr>
            <tr>
              <th className='small text-muted align-middle'>パーツ</th>
            </tr>
          </tbody>
          <div>
            {troubleInquiry.items.map((item) => {
              const { productId, quantity } = item;
              const product = partProductsById[productId];

              return (
                product != null && (
                  <div>
                    <div className='d-flex'>
                      <div>
                        <img src={product.image} style={{ maxHeight: 50 }} />
                      </div>
                      <div className='flex-fill ml-2'>
                        <div className='d-flex'>
                          <div className='flex-fill mx-2'>{product.name}</div>
                          <div className='text-nowrap'>{numeral(quantity).format('0,0')}個</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
        {troubleInquiry.hasReimbursement && (
          <div className='d-flex justify-content-end pb-2 gap-2'>
            <InquiryReimbursementRequestButton
              inquiry={troubleInquiry}
              formFields={reimbursementRequestFields()}
              buttonProps={{
                color: 'secondary',
                className: 'rounded-pill px-4',
                size: 'sm',
                disabled: !!troubleInquiry.reimbursementRequest?.requestedAt,
              }}
              user={user}
            />
          </div>
        )}
      </div>
      <div className='pt-3 pb-2 small text-muted'>こちらからサポートとチャットでやり取りができます</div>
      <div className='card p-3'>
        <Chat
          messagesRef={messagesRef}
          user={user}
          ownerAlias='サポート'
          formProps={{
            style: {
              position: 'sticky',
              bottom: 10,
              left: 0,
              right: 0,
            },
          }}
        />
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
});
