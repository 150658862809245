const { isEmpty, } = require('lodash');

module.exports = {
  fields: () => {
    return {
      name: {
        label: 'グループ名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
    };
  },
};
