const { isEmpty } = require('lodash');

const referralFeeTypes = {
  rate: {
    label: '紹介料(率)',
  },
  amount: {
    label: '紹介料(税込金額)',
  },
};

module.exports = {
  fields: () => {
    return {
      isHiddenForReferral: {
        label: '紹介カートで非表示',
        type: 'boolean',
        initialValue: false,
      },
      isShownForReferral: {
        label: '紹介カートで強制表示',
        type: 'boolean',
        initialValue: false,
        hint: '最も優先されます',
      },
      isHiddenForWholesale: {
        label: '卸カートで非表示',
        type: 'boolean',
        initialValue: false,
      },
      isShownForWholesale: {
        label: '卸カートで強制表示',
        type: 'boolean',
        initialValue: false,
        hint: '最も優先されます',
      },
      price: {
        label: '代理店上代',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        showsTextInput: true,
        hint: '空なら商品マスタの価格',
      },
      description: {
        label: '商品説明(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      wholesalePriceRate: {
        label: '代理店下代率',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        showsTextInput: true,
        hint: '空なら商品マスタの下代率',
      },
      referralFeeType: {
        label: '紹介料種別',
        type: 'select',
        options: Object.entries(referralFeeTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
        initialValue: 'rate',
      },
      referralFeeAmount: {
        label: '紹介料額',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        showsTextInput: true,
        hidden: _ => _.referralFeeType !== 'amount',
        hint: '空なら商品マスタの紹介料額',
      },
      referralFeeRate: {
        label: '紹介料率',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        showsTextInput: true,
        hidden: _ => _.referralFeeType !== 'rate',
        hint: '空なら商品マスタの紹介料率',
      },
    };
  },
};
