import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { InquiryTypeDocumentData, InquiryType } from '../types';
import type { PartiallyPartial } from 'types';

export const inquiryTypeConverter = getConverter<InquiryTypeDocumentData>();

export const inquiryTypesRef = () => collection(getFirestore(), 'inquiryTypes').withConverter(inquiryTypeConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<InquiryType> : null;
export const inquiryTypeRef = <Id extends string | undefined>(inquiryTypeId: Id) =>
  (inquiryTypeId ? doc(inquiryTypesRef(), inquiryTypeId) : null) as RefOrNull<Id>;

export const newInquiryTypeRef = () => doc(inquiryTypesRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const inquiryTypesQuery = (...queryConstraints: QueryConstraint[]) =>
  query(inquiryTypesRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantInquiryTypesQuery = (tenantId: string | undefined) =>
  tenantId ? inquiryTypesQuery(...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

export const addInquiryType = async (data: PartiallyPartial<InquiryType, 'id' | 'createdAt'>) =>
  addDoc(inquiryTypesRef(), { createdAt: serverTimestamp(), ...data });

export const updateInquiryType = async (inquiryTypeId: string, data: UpdateData<InquiryType>) =>
  updateDoc(inquiryTypeRef(inquiryTypeId), data);

export const deleteInquiryType = async (inquiryTypeId: string) => deleteDoc(inquiryTypeRef(inquiryTypeId));

export const useInquiryTypeCollection = useCollectionData;

export const useInquiryTypeCollectionOnce = useCollectionDataOnce;

export const useInquiryTypeDocument = useDocumentData;

export const useInquiryTypeDocumentOnce = useDocumentDataOnce;
