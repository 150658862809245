const { format: formatDate, addHours, startOfDay, endOfDay, } = require('date-fns');
const { uniq, chunk } = require('lodash');
const dedent = require('dedent');

const { getCollectionData, } = require('./firebase');

const userFiltering = {
  dateTypes: {
    ship: {
      label: '発送日',
      candidates: async (db, { tenantId, startDate, endDate, }, offsetHours = 9) => {
        let ordersRef = db.collection('orders').where('tenantId', '==', tenantId);
        if(startDate) ordersRef = ordersRef.where('shippedDate', '>=', formatDate(addHours(startDate.toDate?.() || startDate, offsetHours), 'yyyy/MM/dd'));
        if(endDate) ordersRef = ordersRef.where('shippedDate', '<=', formatDate(addHours(endDate.toDate?.() || endDate, offsetHours), 'yyyy/MM/dd'));
        const orders = await getCollectionData(ordersRef);
        return uniq(orders.filter(_ => _.cancelledAt == null && !_.viaInquiry && !_.viaTroubleInquiry).map(_ => _.createdBy?.uid).filter(_ => _));
      },
      text: ({ data }) => {
        const { startDate, endDate } = data;
        if(startDate == null && endDate == null) return '';

        return `発送日 ${startDate ? formatDate(startDate.toDate(), 'yyyy/MM/dd') : ''} 〜 ${endDate ? formatDate(endDate.toDate(), 'yyyy/MM/dd') : ''}`;
      },
    },
    entry: {
      label: 'イベント参加日',
      candidates: async (db, { tenantId, startDate, endDate, }, offsetHours = 9) => {
        let lecturesRef = db.collectionGroup('lectures').where('tenantId', '==', tenantId);
        if(startDate) lecturesRef = lecturesRef.where('date', '>=', addHours(startOfDay(addHours(startDate.toDate?.() || startDate, offsetHours)), -offsetHours))
        if(endDate) lecturesRef = lecturesRef.where('date', '<=', addHours(endOfDay(addHours(endDate.toDate?.() || endDate, offsetHours)), -offsetHours))
        const lectures = await getCollectionData(lecturesRef);
        const entries = (await Promise.all(chunk(lectures, 10).map((lectures) => {
          return getCollectionData(db.collectionGroup('entries').where('lectureIds', 'array-contains-any', lectures.map(_ => _.id)));
        }))).flat();
        return uniq(entries.map(_ => _.createdBy?.uid).filter(_ => _));
      },
      text: ({ data }) => {
        const { startDate, endDate } = data;
        if(startDate == null && endDate == null) return '';

        return `イベント参加日 ${startDate ? formatDate(startDate.toDate(), 'yyyy/MM/dd') : ''} 〜 ${endDate ? formatDate(endDate.toDate(), 'yyyy/MM/dd') : ''}`;
      },
    },
    order: {
      label: '注文日',
      candidates: async (db, { tenantId, startDate, endDate }, offsetHours = 9) => {
        let ordersRef = db.collection('orders').where('tenantId', '==', tenantId);
        if(startDate) ordersRef = ordersRef.where('createdAt', '>=', addHours(startOfDay(addHours(startDate.toDate?.() || startDate, offsetHours)), -offsetHours));
        if(endDate) ordersRef = ordersRef.where('createdAt', '<=', addHours(endOfDay(addHours(endDate.toDate?.() || endDate, offsetHours)), -offsetHours));
        console.log(ordersRef._queryOptions);
        const orders = await getCollectionData(ordersRef);
        return uniq(orders.filter(_ => _.cancelledAt == null && !_.viaInquiry && !_.viaTroubleInquiry).map(_ => _.createdBy?.uid).filter(_ => _));
      },
      text: ({ data }) => {
        const { startDate, endDate } = data;
        if(startDate == null && endDate == null) return '';

        return `注文日 ${startDate ? formatDate(startDate.toDate(), 'yyyy/MM/dd') : ''} 〜 ${endDate ? formatDate(endDate.toDate(), 'yyyy/MM/dd') : ''}`;
      }
    }
  },
};

module.exports = {
  smsOrderTypes: {
    ordered: { label: '注文あり', },
    notOrdered: { label: '注文なし', },
    any: { label: 'どちらでも', },
  },
  smsEntryTypes: {
    entried: { label: '申込あり', },
    notEntried: { label: '申込なし', },
    any: { label: 'どちらでも', },
  },
  userFiltering,
  lectureLevels: {
    beginner: '初級',
    intermediate: '中級',
    advanced: '上級',
    none: '制限なし',
  },
  lectureAges: {
    '2-2': '2歳',
    '2-3': '2歳から3歳',
    '4-5': '4歳から5歳',
    '4-100': '4歳以上',
    '6-8': '6歳から8歳',
    '2-6': '2歳から6歳',
    '2-8': '2歳から8歳',
    '3-6': '3歳から6歳',
    '3-7': '3歳から7歳',
    '9-100': '9歳以上',
    '0-999': '制限なし',
  },
  enLectureLevels: {
    beginner: 'beginner',
    intermediate: 'intermediate',
    advanced: 'advanced',
    none: 'free',
  },
  enLectureAges: {
    '2-2': '2 years old',
    '2-3': 'from 2 to 2 years old',
    '4-5': 'from 4 to 5 years old',
    '4-100': 'from 4 years old',
    '6-8': 'from 4 to 8 years old',
    '2-6': 'from 2 to 6 years old',
    '2-8': 'from 2 to 8 years old',
    '3-6': 'from 3 to 6 years old',
    '3-7': 'from 3 to 7 years old',
    '9-100': 'from 9 years old',
    '0-999': 'free',
  },
  prefectures: {
    1: '北海道', 2: '青森県', 3: '岩手県', 4: '宮城県', 5: '秋田県',
    6: '山形県', 7: '福島県', 8: '茨城県', 9: '栃木県', 10: '群馬県',
    11: '埼玉県', 12: '千葉県', 13: '東京都', 14: '神奈川県', 15: '新潟県',
    16: '富山県', 17: '石川県', 18: '福井県', 19: '山梨県', 20: '長野県',
    21: '岐阜県', 22: '静岡県', 23: '愛知県', 24: '三重県', 25: '滋賀県',
    26: '京都府', 27: '大阪府', 28: '兵庫県', 29: '奈良県', 30: '和歌山県',
    31: '鳥取県', 32: '島根県', 33: '岡山県', 34: '広島県', 35: '山口県',
    36: '徳島県', 37: '香川県', 38: '愛媛県', 39: '高知県', 40: '福岡県',
    41: '佐賀県', 42: '長崎県', 43: '熊本県', 44: '大分県', 45: '宮崎県',
    46: '鹿児島県', 47: '沖縄県',
  },
  roles: {
    admin: '管理者',
    staff: 'スタッフ',
    user: '一般ユーザー',
  },
  questionTypes: {
    text: 'テキスト',
    radio: 'ラジオボタン',
    select: 'セレクトボックス',
    checkbox: 'チェックボックス',
    imageFile: '画像・動画ファイル',
  },
  deliveryTimes: {
    anytime: {
      label: '指定なし',
      cammacs: '0',
      wms: '0',
      sagawa: '',
      yamato: '',
    },
    am: {
      label: '午前中',
      cammacs: '1',
      wms: '0912',
      sagawa: '01',
      yamato: '0812',
    },
    at14: {
      label: '14:00-16:00',
      cammacs: '1416',
      wms: '1416',
      sagawa: '14',
      yamato: '1416',
    },
    at16: {
      label: '16:00-18:00',
      cammacs: '1618',
      wms: '1618',
      sagawa: '16',
      yamato: '1618',
    },
    at18: {
      label: '18:00-20:00',
      cammacs: '1820',
      wms: '1820',
      sagawa: '18',
      yamato: '1820',
    },
    at19: {
      label: '19:00-21:00',
      cammacs: '1921',
      wms: '1921',
      sagawa: '19',
      yamato: '1921',
    },
  },
  colors: [
    '#8dd3c7',
    '#bebada',
    '#fb8072',
    '#80b1d3',
    '#fdb462',
    '#b3de69',
    '#fccde5',
    '#d9d9d9',
    '#bc80bd',
    '#ccebc5',
    '#ffed6f',
  ],
  defaultShipmentFee: 770,
  shipmentFeeThreshold: 4400,
  oldReferralFeeRate: 0.2,
  taxRate: 0.1,
  routes: [
    'WEB',
    'アプリ',
    'TV',
    '新聞・雑誌',
    '店舗',
    '電車広告・駅看板',
    'イベント・教室',
    'フリーペーパー',
    'Facebook',
    'インスタ',
    'X/ツイッター',
    'YouTube',
    'TikTok',
    'その他SNS',
    '友達の紹介',
    '家族の紹介',
    '先生や園の紹介',
    '公園で見た',
    'その他',
  ],
  childGenders: {
    girl: '女の子',
    boy: '男の子',
    other: 'ひみつ',
  },
  vehicleExperiences: [
    'なし',
    'キックバイク',
    'キックバイク（ブレーキ付）',
    '三輪車',
    '三輪車（ペダル付）',
    '自転車１２インチ',
    '自転車１４インチ',
    '自転車１６インチ',
    'わからない'
  ],
  supportMeans: {
    systemOnly: 'VSMのみ',
    mail: 'メール',
    phone: '電話',
    videoCall: 'テレビ通話',
    movie: '動画',
    unnecessary: '対応不要',
    shop: '店舗',
    visit: '訪問',
    other: 'その他',
  },
  deliveryMethods: {
    email: { label: 'Eメール' },
    sms: { label: 'SMS' },
    mobile: { label: 'アプリ通知' },
    emailAndMobile: { label: 'Eメールとアプリ' }
  },
  emailSignature: dedent`

    VSM
  `,
  staffRoles: {
    main: { label: 'メイン' },
    sub: { label: 'サブ' },
    other: { label: 'その他' },
  },
  langs: {
    ja: {
      label: '日本語',
    },
    en: {
      label: '英語',
    },
  },
  userNotificationTypes: {
    newEvent: { label: '新しいイベント' },
    newContent: { label: '新しいコンテンツ' },
    newSurvey: { label: '新しいアンケート' },
  },
  mobileNotificationContentTypes: {
    text: { label: 'テキスト' },
    url: { label: 'URL' },
    html: { label: 'HTML' },
  },
  reinquiryContacts: {
    inquiry: {
      label: 'お問合せ',
      path: 'inquiries/new'
    },
    troubleInquiry: {
      label: '不具合問合せ',
      path: 'troubleInquiry'
    },
    methodInquiry: {
      label: '乗り方・使い方問合せ',
      path: 'methodInquiry'
    }
  }
};
