import React, { useEffect } from 'react';

import AgentPage from '../hocs/AgentPage';
import AgentHeader from '../AgentHeader';
import AgentMembersPageContent from '../AgentMembersPageContent';

export default AgentPage(function AgentMembers(props) {
  const { agent, setBreadNavValues, canEditMembers, } = props;
  useEffect(() => {
    setBreadNavValues({ agent });
  }, [agent]);

  return canEditMembers && (
    <div>
      <div className="agent-orders container-fluid py-5 position-relative">
        <AgentHeader activeTab="members" agent={agent} canEditMembers={canEditMembers} />
        <AgentMembersPageContent {...{ ...props, agent }} canEditMembers={canEditMembers} />
      </div>
    </div>
  );
});

