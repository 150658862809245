import React, { useEffect, useRef } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import { isString, sortBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { Input, Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync, } from 'react-use';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { beforeDelete, } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentShopHeader from '../AgentShopHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/rentalPrice';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export default AgentPage(function AgentShopRentalPrices (props) {
  const { tenant, user, agent, match: { params: { agentShopId } }, setBreadNavValues, canReadAllShops, memberData, } = props;
  const queryParams = useQueryParams();
  const agentShop = useDocumentSubscription((canReadAllShops || memberData.shopIds.includes(agentShopId)) && agent.ref.collection('agentShops').doc(agentShopId), [agent, agentShopId]);
  const rentalItems = sortBy(useCollectionSubscription(agent.ref.collection('rentalItems'), [agent]), 'name');
  const rentalPrices = sortBy(useCollectionSubscription(agent.ref.collection('rentalPrices').where('agentShopId', '==', agentShopId), [agent, agentShopId]), 'hours');

  let filteredRentalPrices = rentalPrices;
  if (queryParams.showsHidden !== '1') {
    filteredRentalPrices = filteredRentalPrices.filter(_ => !_.isHidden);
  }

  useEffect(() => {
    setBreadNavValues({ agent, agentShop, });
  }, [agent, agentShop]);

  return agentShop != null && (
    <div>
      <div className="agent-shop-rental-prices container-fluid py-5 position-relative">
        <AgentShopHeader activeTab="rentalPrices" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
            <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
          </div>
          <div className="mb-2 d-flex align-items-end justify-content-end gap-1">
            <AddInTenantButton itemRef={agent.ref.collection('rentalPrices').doc()} processValues={_ => ({ ..._, agentShopId, })} FormModal={ModelFormModal} formProps={{ title: 'レンタル料金追加', fields: fields({ rentalItems }), }} disabled={!canUpdateAgent(user)} />
          </div>
          {
            rentalPrices.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th></th>
                    <th>名称</th>
                    <th>時間</th>
                    {
                      rentalItems.map((rentalItem) => {
                        const { id, name, } = rentalItem;
                        return (
                          <th key={id}>
                            {name}
                          </th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    (filteredRentalPrices || []).map((rentalPrice) => {
                      const { id, ref, name, hours, isHidden = false, } = rentalPrice;
                      const beforeDelete = async () => {
                        if(
                          (await agent.ref.collection('rentalOrders').where('rentalPriceId', '==', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                          <td>
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'レンタル料金編集', fields: fields({ rentalItems }), }} disabled={!canUpdateAgent(user)} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateAgent(user)} beforeDelete={beforeDelete} />
                          </td>
                          <td>
                            {name}
                          </td>
                          <td className="text-right">
                            {hours}
                          </td>
                          {
                            rentalItems.map((rentalItem) => {
                              const { id, name, } = rentalItem;
                              return (
                                <th key={id} className="text-right">
                                  {numeral(rentalPrice[`price__${id}`]).format()}
                                </th>
                              );
                            })
                          }
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                レンタル料金は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
