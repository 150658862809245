import React, { useEffect, useMemo, Fragment, } from 'react';
import { Button, } from 'reactstrap';
import { sumBy, groupBy, trim, pick, omit, isEmpty, orderBy, sortBy, keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, startOfDay, endOfDay, } from 'date-fns';
import numeral from 'numeral';
import moji from 'moji';

import firebase from '../../firebase';
import { colors, prefectures, deliveryTimes, } from '../../shared/config';
import { fieldDisplayValue, postalCodeWithHyphen, } from '../../shared/util';
import { canUpdateShipping } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import TenantLink from '../TenantLink';
import ModelFormModal from '../modals/ModelFormModal';
import ShippingFormModal from '../modals/ShippingFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import { fields, types, statuses, } from '../../shared/models/shipping';
import QuerySelector from '../QuerySelector';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import DeliverySlip from '../DeliverySlip';

const { keys, entries } = Object;
const db = firebase.firestore();
const typeOptions = entries(types).map(([k, v]) => ({ label: v.label, value: k }));
const statusOptions = entries(statuses).map(([k, v]) => ({ label: v.label, value: k }));
const replaceSpaceAndChar = _ => (_ || '').replace(/[ 　]([a-zA-Z])/g, 'ー$1');
const trimAndReplaceDashAndEtc = _ => trim(_ || '').replace(/[-ー―−‐—]/g, '-').replace(/\t/, '');

export default AdminPage(function AdminShippings (props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes').orderBy('index'));
  const productTypesById = keyBy(productTypes, 'id');
  const productTypeOptions = productTypes.map(_ => ({ value: _.id, label: _.name }));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productOptions = products.map(_ => ({ value: _.id, label: _.code }));
  const productsById = keyBy(products, 'id');
  const _shippings = useCollectionSubscriptionInTenant(db.collection('shippings').where('status', '==', 'initial'));
  const shippings = useMemo(_ => orderBy(_shippings, _ => _.createdAt?.toDate(), 'desc'), [_shippings]);
  const shippingItems = useMemo(_ => shippings.flatMap(s => s.items.map(_ => ({ ..._, shipping: s, }))), [shippings]);
  const shippingItemsGroupedByProductId = useMemo(_ => groupBy(shippingItems, 'productId'), [shippingItems]);
  const productItems = products.map((product) => {
    const shippingItems = shippingItemsGroupedByProductId[product.id];
    return {
      ...product,
      shippingItems,
    };
  });

  return (
    <div>
      <div className="d-flex justify-content-center" style={{ pageBreakAfter: 'always', }}>
        <table className="table table-bordered" style={{ width: 800, }}>
          <thead className="thead-light text-center">
            <tr className="text-nowrap">
              <th style={{ minWidth: 150 }}>商品コード</th>
              <th style={{ minWidth: 150 }}>内訳</th>
              <th style={{ minWidth: 100 }}>合計数量</th>
            </tr>
          </thead>
          <tbody>
            {
              productItems.map((productItem) => {
                const { id, ref, shippingItems = [], } = productItem;

                return (
                  <Fragment key={id}>
                    {
                      shippingItems.map((shippingItem, i) => {
                        const { shipping, quantity, } = shippingItem;
                        return (
                          <tr key={i}>
                            {
                              i === 0 && (
                                <Fragment>
                                  <td rowSpan={shippingItems.length}>
                                    {id}
                                  </td>
                                </Fragment>
                              )
                            }
                            <td>
                              <div className="d-flex justify-content-between">
                                <div>
                                  {shipping.id}
                                </div>
                                <div>
                                  {numeral(quantity).format()}
                                </div>
                              </div>
                            </td>
                            {
                              i === 0 && (
                                <Fragment>
                                  <td rowSpan={shippingItems.length} className="text-right">
                                    {numeral(sumBy(shippingItems, 'quantity')).format()}
                                  </td>
                                </Fragment>
                              )
                            }
                          </tr>
                        );
                      })
                    }
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center" style={{ pageBreakAfter: 'always', }}>
        <table className="table table-bordered" style={{ width: 1200, }}>
          <thead className="thead-light text-center">
            <tr className="text-nowrap">
              <th style={{ minWidth: 150 }}>出荷ID</th>
              <th style={{ minWidth: 150 }}>商品コード</th>
              <th style={{ minWidth: 100 }}>出荷数量</th>
              <th style={{ minWidth: 150 }}>品名</th>
            </tr>
          </thead>
          <tbody>
            {
              shippings.map((shipping) => {
                const { id, ref, type, shippedOn, items, sourceOrderId, cancelledAt, } = shipping;

                return (
                  <Fragment key={id}>
                    {
                      items.map((item, i) => {
                        const { productId, quantity, } = item;
                        const product = productsById[productId];
                        return (
                          <tr key={i}>
                            {
                              i === 0 && (
                                <Fragment>
                                  <td rowSpan={items.length}>
                                    {id}
                                  </td>
                                </Fragment>
                              )
                            }
                            <td>
                              {product?.code}
                            </td>
                            <td className="text-right">
                              {numeral(quantity).format()}
                            </td>
                            <td>
                              {product?.shippingName}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
      {
        shippings.map((shipping, i) => {
          return (
            <div key={shipping.id} className="d-flex justify-content-center" style={{ pageBreakAfter: i !== (shippings.length - 1) && 'always', }}>
              <div style={{ width: 1200, }}>
                <DeliverySlip {...{ tenant, shipping, productsById }} />
              </div>
            </div>
          );
        })
      }
    </div>
  );
});
