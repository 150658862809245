const { omit, pick, inRange, isEmpty } = require('lodash');

const fields = ({ isEditing, } = {}) => ({
  name: {
    type: 'string',
    label: '名称',
    validations: {
      required: v => !isEmpty(v),
    },
    ...(isEditing && { hint: '名前のみ変更可。条件変更は、新テンプレート作成（不要テンプレートは削除可）', }),
  },
});

module.exports = {
  fields,
};
