import React, { useEffect } from 'react';
import { pick, sortBy, keyBy, isEmpty } from 'lodash';
import { useAsync, useLocalStorage } from 'react-use';
import qs from 'qs';
import numeral from 'numeral';
import Slider from 'react-slick';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../../util';
import firebase, { functions } from '../../firebase';
import { getDocumentData, getCollectionData, } from '../../shared/firebase';
import PublicPage from '../hocs/PublicPage';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AppButton from '../AppButton';
import AppCard from '../AppCard';
import CartInformation from '../CartInformation';
import useAvailableReferrerKey from '../hooks/useAvailableReferrerKey';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const agentsRef = db.collection('agents');
const couponsRef = db.collection('coupons');
const getProductsWithInventory = functions.httpsCallable('getProductsWithInventory');
const getAgentProductPublicSettings = functions.httpsCallable('getAgentProductPublicSettings');

function Products(props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const { types = [], wholesale, agentId, couponId } = queryParams;
  const availableReferrerKey = useAvailableReferrerKey();
  const isWholesale = wholesale === '1';
  const isForAgent = queryParams.aps != null || isWholesale || !isEmpty(availableReferrerKey);
  const isWish = queryParams.wish === '1';
  const cartSetting = useDocumentSubscription(db.collection('settings').doc([tenant.id, 'cart'].join('__')));
  const { value: agent, loading: isLoadingAgent, } = useAsync(async () => {
    return agentId != null ? await getDocumentData(db.collection('agents').doc(agentId))
      : !isEmpty(availableReferrerKey) ? await (async () => {
        const [referrer] = await getCollectionData(db.collectionGroup('referrers').where('key', '==', availableReferrerKey).limit(1));
        return await getDocumentData(referrer.ref.parent.parent.parent.parent);
      })() : null;
  }, [isForAgent, agentId, availableReferrerKey]);
  const agentProductsPageHeaderHtml = queryParams.agentProductsPageHeaderHtml || agent?.productsPageHeaderHtml;
  const { value: agentProductPublicSettings = [], loading: isLoadingAgentProductPublicSettings = false, } = useAsync(async () => {
    if(isForAgent) {
      if(queryParams.aps != null) return queryParams.aps.split('\n').map(_ => _.split(',')).map(([id, isHiddenForReferral, isShownForReferral]) => ({ id, isHiddenForReferral: isHiddenForReferral === '1', isShownForReferral: isShownForReferral === '1' }));

      const { data } = await getAgentProductPublicSettings({ agentId, referrerKey: availableReferrerKey, });
      return data;
    }
  }, [isForAgent, agentId, availableReferrerKey]);
  const agentProductPublicSettingsById = keyBy(agentProductPublicSettings, 'id');
  const isAgentUser = !!agent?.members?.[user?.id];
  const coupon = useDocumentSubscription(couponsRef.doc(couponId), [couponId]);

  // NOTE: ref filter
  let productsRef = db.collection('products');
  if (!isEmpty(types)) {
    productsRef = productsRef.where('productTypeIds', 'array-contains-any', types);
  }

  const products = useCollectionSubscriptionInTenant(productsRef);
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes'));
  const filteredProducts = products
    .filter((product) => {
      const agentProductPublicSetting = agentProductPublicSettingsById[product.id];
      return agentProductPublicSetting?.[`isShownFor${isWholesale ? 'Wholesale' : 'Referral'}`] || (!product.isHidden && !agentProductPublicSetting?.[`isHiddenFor${isWholesale ? 'Wholesale' : 'Referral'}`]);
    })
    .filter((product) => {
      if (!coupon) return true;
      return coupon.productIds.includes(product.id);
    })
    .filter((_) => (_.isOption || !_.isPart))
    .filter((_) => _.code)
    .map((_) => {
      const productType = productTypes.find(({ id }) => _.productTypeIds.includes(id))
      return { ..._, index: productType?.index ?? Number.MAX_VALUE }
    })
  const sortedProducts = sortBy(filteredProducts, ['isOption', 'index', 'code']);
  const { loading: isLoadingInventories = false, value: productsWithInfoById = {} } = useAsync(async () => {
    const { data: products } = await getProductsWithInventory({ tenantId: tenant.id, });
    return keyBy(
      products.map((_) => ({
        ..._,
        receivingPlanItems: _.receivingPlanItems.map((_) => ({ ..._, date: new Date(JSON.parse(_.date)) })),
      })),
      'id'
    );
  });

  return (
    <div className='products container py-5 position-relative'>
      <div className='row mb-5'>
        <div className='col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
          {
            isForAgent ? (
              !isLoadingAgent && (
                !isEmpty(agentProductsPageHeaderHtml) ? (
                  <div className='bg-light-grey rounded-3 p-3'>
                    <RichTextContent html={agentProductsPageHeaderHtml} />
                  </div>
                ) : <CartInformation />
              )
            ) : <CartInformation />
          } 
          {
            isWish && cartSetting?.wishHeader && (
              <div className='text-break border border-info rounded p-3 mt-3'>
                <RichTextContent html={cartSetting.wishHeader} />
              </div>
            )
          }
        </div>
      </div>
      {
        (isForAgent && isLoadingAgentProductPublicSettings) ? (
          <div className='d-flex justify-content-center'>
            <div>
              <span className='fas fa-spin fa-spinner mr-1' />
              読込中...
            </div>
          </div>
        ) : (
          <div className='position-relative d-flex flex-wrap justify-content-around gap-4'>
            {sortedProducts.map((product) => {
              const { id, name, image, subImages = [], description } = product;
              const agentProductPublicSetting = agentProductPublicSettingsById[id];
              console.log(id, agentProductPublicSetting);
              const { normalOrderableQuantity = 0, preOrderableQuantity = 0, } = productsWithInfoById[id] || {};
              const imageUrls = [image, ...subImages.map((_) => _.downloadUrl)].filter((_) => _);

              return (
                <AppCard
                  key={id}
                  className='p-3 d-flex flex-column align-items-center justify-content-between'
                  style={{ width: 300 }}
                >
                  <div className='w-100'>
                    <Slider dots infinite arrows={false} className='mb-4'>
                      {imageUrls.map((imageUrl) => (
                        <div key={imageUrl} className='d-flex justify-content-center'>
                          <img src={imageUrl} style={{ maxHeight: 200, maxWidth: '100%' }} />
                        </div>
                      ))}
                    </Slider>
                    <div className='small text-center'>{name}</div>
                  </div>
                  <div className='d-flex flex-column align-items-center'>
                    {(agentProductPublicSetting?.description || description) && (
                      <RichTextContent className='small mt-4' html={agentProductPublicSetting?.description || description} />
                    )}
                    <div>&yen; {numeral(agentProductPublicSetting?.price ?? product.price).format('0,0')} (税込)</div>
                    {isWholesale && isAgentUser && <div className="text-muted"><span className="small mr-1">卸価格</span>&yen; {numeral(Math.floor((agentProductPublicSetting?.price ?? product.price) * (agentProductPublicSetting?.wholesalePriceRate ?? product.wholesalePriceRate) / 100)).format('0,0')} (税込)</div>}
                    <div className='mt-3'>
                      {normalOrderableQuantity > 0 ? (
                        <AppButton
                          color='primary'
                          tag={TenantLink}
                          to={`/orders/new?${qs.stringify({ productId: id, ...pick(queryParams, ['coupon', 'n', 'wholesale', 'agentId', 'agentShopId', 'couponId', 'wish']), })}`}
                        >
                          <span className='fas fa-arrow-right mr-1' />
                          {isWish ? 'おねだり注文に進む' : '注文に進む'}
                        </AppButton>
                      ) : isLoadingInventories ? (
                        <span className='fas fa-spin fa-spinner' />
                      ) : (preOrderableQuantity > 0 && !isWish) ? (
                        <AppButton
                          color='primary'
                          tag={TenantLink}
                          to={`/orders/new?${qs.stringify({ productId: id, ...pick(queryParams, ['coupon', 'wholesale', 'agentId', 'agentShopId', 'couponId']), })}`}
                        >
                          <span className='fas fa-arrow-right mr-1' />
                          予約注文に進む
                        </AppButton>
                      ) : (
                        <AppButton color='secondary' disabled className="text-danger">
                          SOLD OUT
                        </AppButton>
                      )}
                    </div>
                  </div>
                </AppCard>
              );
            })}
          </div>
        )
      }
    </div>
  );
}

Products.preview = true;

export default PublicPage(Products);
