const { partition, omit, isEmpty, sumBy, flatten, sortBy } = require('lodash');

const { max } = Math;

module.exports = {
  fields: ({ rentalItemGroups }) => {
    return {
      rentalItemGroupId: {
        label: 'グループ',
        type: 'select',
        options: rentalItemGroups.map(_ => ({ label: _.name, value: _.id })),
        validations: {
          required:  v => !isEmpty(v),
        },
      },
      name: {
        label: '商品名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      image: {
        label: '画像',
        type: 'file',
        inputProps: {
          accept: 'image/*',
        },
      },
      description: {
        label: '商品説明',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: 'メモ',
        type: 'text',
        initialValue: '',
      },
    };
  },
};
