import React, { useEffect } from 'react';
import { Input, } from 'reactstrap';
import { useList } from 'react-use';
import numeral from 'numeral';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { isEqual, sortBy, isEmpty, invoke, keyBy, flatten, uniq, orderBy } from 'lodash';

import firebase from '../../firebase';
import { batch, } from '../../shared/firebase';
import { prefectures } from '../../shared/config';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import AdminEventHeader from '../AdminEventHeader';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const questionsRef = db.collection('questions');
const statuses = {
  cancelled: { label: 'キャンセル済み', filter: _ => _.cancelledAt != null, },
  aborted: { label: '中止済み', filter: _ => _.abortedAt != null, },
  none: { label: 'キャンセル・中止なし', filter: _ => [_.cancelledAt, _.abortedAt].every(_ => _ == null), },
};
const statusOptions = Object.entries(statuses).map(([k, v]) => ({ label: v.label, value: k }));

export default AdminPage(function AdminEventEntries(props) {
  const {
    user,
    match: {
      params: { eventId },
    },
    addBreadNavValues,
  } = props;
  const queryParams = useQueryParams();
  const [selectedIds, { push: selectId, removeAt: removeSelectedIdAt, set: setSelectedId, }] = useList([]);
  const unselectId = _ => removeSelectedIdAt(selectedIds.indexOf(_));
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const lectures = useCollectionSubscription(eventRef.collection('lectures').orderBy('date', 'desc'), [eventId]);
  const lecturesById = keyBy(lectures, 'id');
  const lectureIdOptions = lectures.map(_ => ({ label: formatDate(_.date.toDate(), 'yyyy/MM/dd'), value: _.id }));
  const entries = useCollectionSubscription(eventsRef.doc(eventId).collection('entries').orderBy('createdAt', 'desc'), [
    eventId,
  ]);

  let filteredEntries = entries;
  if(!isEmpty(queryParams.lectureIds)) {
    filteredEntries = filteredEntries.filter(_ => _.frames.some(_ => queryParams.lectureIds.includes(_.lectureId)));
  }
  if(!isEmpty(queryParams.statuses)) {
    filteredEntries = filteredEntries.filter(e => queryParams.statuses.some(_ => statuses[_].filter(e)));
  }

  const entryQuestionIds = uniq(
    flatten(entries.filter(({ answers }) => answers).map(({ answers }) => Object.keys(answers)))
  );
  const questions = useDocumentsFetch(
    entryQuestionIds.map((_) => questionsRef.doc(_)),
    [entries]
  );
  const sortedQuestions = orderBy(questions, 'createdAt');
  const entriesForExport = filteredEntries
    .map((entry) => {
      const { answers = {} } = entry;

      return entry.frames.map((frame) => {
        return {
          id: entry.id,
          isCancelled: entry.cancelledAt != null,
          hasRefunded: entry.refund != null,
          userName: entry.createdBy.displayName,
          userEmail: entry.createdBy.email,
          userPostalCode: entry.createdBy.postalCode,
          userPrefecture: prefectures[entry.createdBy.prefecture],
          userCity: entry.createdBy.city,
          createdAt: formatDate(entry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
          frameDateTime:
            formatDate(invoke(lecturesById, [frame.lectureId, 'date', 'toDate']), 'yyyy-MM-dd') +
            ' ' +
            formatDate(frame.lectureFrame.startAt.toDate(), 'HH:mm'),
          frameName: frame.name,
          frameBirthday: frame.birthday && formatDate(frame.birthday.toDate(), 'yyyy/MM/dd'),
          chargeAmount: entry.charge && entry.charge.amount,
          refundedAt: entry.refundedAt && formatDate(entry.refundedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
          ...sortedQuestions.reduce((acc, { id, type, name }) => {
            const answer = answers[id] || '';
            const formattedAnswer =
              type === 'checkbox'
                ? Object.entries(answer)
                    .filter(([, value]) => value)
                    .map(([key]) => key)
                    .join(',')
                : answer;

            return { ...acc, [name]: formattedAnswer };
          }, {}),
        };
      });
    })
    .flat();
  const isAllSelected = isEqual(...[selectedIds, filteredEntries.map(_ => _.id)].map(_ => sortBy(_, _ => _)));
  const onClickRefund = async () => {
    if(!window.confirm('本当に返金しますか？')) return;

    try {
      await batch(db, selectedIds, (batch, id) => batch.update(event.ref.collection('entries').doc(id), { refundRequestedAt: new Date() }));
      toast.success('返金処理しました');
      setSelectedId([]);
    } catch(e){
      console.error(e);
      toast.error('失敗しました');
    }
  };
  useEffect(() => {
    addBreadNavValues({ event });
  }, [event]);

  return (
    event != null && (
      <div>
        <div className='admin-event-entries container-fluid py-5 position-relative'>
          <AdminEventHeader activeTab='entries' event={event} user={user} />
          <div className='bg-white p-4'>
            <div className='row'>
              <div className='col-12'>
                <h5 className='text-center'>申込み一覧</h5>
                <div className='d-flex justify-content-between align-items-end mb-3'>
                  <div className="d-flex align-items-end gap-1">
                    <QuerySelector paramName="lectureIds" width={250} isMulti options={lectureIdOptions} label="レクチャーで絞込み" disabled={selectedIds.length > 0} />
                    <QuerySelector paramName="statuses" width={250} isMulti options={statusOptions} label="ステータスで絞込み" disabled={selectedIds.length > 0} />
                  </div>
                  <ExportButton fileName={`${event.name}_申込み.csv`} rows={entriesForExport} />
                </div>
                {
                  selectedIds.length > 0 && (
                    <div className="d-flex mb-2 alert alert-info justify-content-between align-items-center">
                      <div>{selectedIds.length}件を選択中</div>
                      <ProgressButton process={onClickRefund} color="danger">
                        返金する
                      </ProgressButton>
                    </div>
                  )
                }
                {entries.length > 0 ? (
                  <table className='table'>
                    <thead className='thead-light text-center text-nowrap'>
                      <tr>
                        <th>
                          <Input
                            type='checkbox'
                            className='position-relative m-0'
                            checked={isAllSelected}
                            onChange={_ => isAllSelected ? setSelectedId([]) : setSelectedId(filteredEntries.map(_ => _.id))}
                          />
                        </th>
                        <th></th>
                        <th>アカウント名</th>
                        <th>メールアドレス</th>
                        <th>郵便番号</th>
                        <th>都道府県</th>
                        <th style={{ minWidth: 200 }}>市区町村</th>
                        <th>申込日時</th>
                        <th style={{ minWidth: 250 }}>参加枠</th>
                        <th>決済金額(税込)</th>
                        <th>返金日時</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEntries.map((entry) => {
                        const {
                          id,
                          frames = [],
                          charge,
                          refund,
                          createdAt,
                          createdBy = {},
                          abortedAt,
                          cancelledAt,
                          checkedInAt,
                          refundedAt,
                        } = entry;
                        const isCancelled = cancelledAt != null;
                        const isCheckedIn = checkedInAt != null;
                        return (
                          <tr key={id} className={classnames({ 'table-secondary': isCancelled || abortedAt != null })}>
                            <td>
                              <Input
                                type='checkbox'
                                className='position-relative m-0'
                                checked={selectedIds.includes(id)}
                                onChange={(_) => (selectedIds.includes(id) ? unselectId(id) : selectId(id))}
                              />
                            </td>
                            <td>
                              <div className='d-flex flex-column gap-1'>
                                {isCheckedIn && <span className='badge badge-info'>チェックイン済み</span>}
                                {abortedAt != null && <span className='badge badge-secondary'>中止済み</span>}
                                {isCancelled && <span className='badge badge-secondary'>キャンセル</span>}
                                {refund != null && <span className='badge badge-success'>返金済み</span>}
                              </div>
                            </td>
                            <td>
                              <TenantLink to={`/admin/events/${eventId}/entries/${id}`}>{createdBy.displayName}</TenantLink>
                            </td>
                            <td>{createdBy.email}</td>
                            <td>{createdBy.postalCode}</td>
                            <td>{prefectures[createdBy.prefecture]}</td>
                            <td>{createdBy.city}</td>
                            <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                            <td>
                              <table className='table table-sm table-borderless'>
                                <tbody>
                                  {frames.map((frame, i) => {
                                    const { name, lectureFrame, lectureId } = frame;
                                    const lecture = lecturesById[lectureId];
                                    return (
                                      <tr key={i}>
                                        <td>
                                          {lecture && formatDate(lecture.date.toDate(), 'yyyy/MM/dd')}{' '}
                                          {formatDate(lectureFrame.startAt.toDate(), 'HH:mm')}
                                        </td>
                                        <td>{name}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                            <td className='text-right'>{numeral(charge && charge.amount).format('0,0')}</td>
                            <td>{refundedAt && formatDate(refundedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>申込みはまだありません</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
