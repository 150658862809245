import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import env from '../env';

const config = {
  apiKey: env('FIREBASE_API_KEY'),
  authDomain: env('FIREBASE_AUTH_DOMAIN'),
  databaseURL: env('FIREBASE_DATABASE_URL'),
  projectId: env('FIREBASE_PROJECT_ID'),
  storageBucket: env('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID'),
};

firebase.initializeApp(config);

export let functions = firebase.app().functions('asia-northeast1');

if (env('EMULATORS') === 'true' || env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  functions.useEmulator('localhost', Number(env('CLOUD_FUNCTIONS_PORT')) || 5001);
}

if (env('EMULATORS') === 'true') {
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.firestore().settings({ experimentalAutoDetectLongPolling: true, merge: true });
  firebase.auth().useEmulator('http://localhost:9099');
}

export type DocumentReferenceV8 = firebase.firestore.DocumentReference;
export { firebase };
