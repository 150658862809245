import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { AgentDocumentData, Agent } from '../types';
import type { PartiallyPartial } from 'types';

export const agentConverter = getConverter<AgentDocumentData>();

export const agentsRef = () => collection(getFirestore(), 'agents').withConverter(agentConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Agent> : null;
export const agentRef = <Id extends string | undefined>(agentId: Id) =>
  (agentId ? doc(agentsRef(), agentId) : null) as RefOrNull<Id>;

export const newAgentRef = () => doc(agentsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const agentsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(agentsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantAgentsQuery = (tenantId: string | undefined) =>
  tenantId ? agentsQuery(...tenantQueryConstraints(tenantId)) : null;

export const addAgent = async (data: PartiallyPartial<Agent, 'id' | 'createdAt'>) =>
  addDoc(agentsRef(), { createdAt: serverTimestamp(), ...data });

export const updateAgent = async (agentId: string, data: UpdateData<Agent>) => updateDoc(agentRef(agentId), data);

export const deleteAgent = async (agentId: string) => deleteDoc(agentRef(agentId));

export const useAgentCollection = useCollectionData;

export const useAgentCollectionOnce = useCollectionDataOnce;

export const useAgentDocument = useDocumentData;

export const useAgentDocumentOnce = useDocumentDataOnce;
