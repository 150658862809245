import { useToggle } from 'react-use';
import { Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import dedent from 'dedent';

import AppButton from '../AppButton';
import LectureTypeDisplay from '../LectureTypeDisplay';
import LectureLevelDisplay from '../LectureLevelDisplay';
import LectureAgeDisplay from '../LectureAgeDisplay';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { functions } from '../../firebase';

const checkInEvent = functions.httpsCallable('checkInEvent');

export default function EventCheckInModal({ event, lecture, entry, isOpen, onClickClose, onCheckedIn }) {
  const [isCheckingIn, toggleIsCheckingIn] = useToggle(false);
  const [frame] = entry.frames;
  const entries = useCollectionSubscription(
    event.ref.collection('entries').where('lectureIds', 'array-contains', lecture.id).orderBy('createdAt'),
    [event]
  );
  const entryIndex = entries.findIndex((_) => _.id === entry?.id) + 1;
  const { startAt, endAt } = lecture.frames[frame.frameIndex];
  const handleClickSubmit = async () => {
    toggleIsCheckingIn(true);
    try {
      const { error } = await checkInEvent({
        eventId: event.id,
        entryId: entry.id,
      });
      if (error) throw error;

      onCheckedIn && (await onCheckedIn());
      toast.success(
        dedent`
        チェックインが完了しました。
        番号は${event?.entryIndexPrefix || ''}${entryIndex}です。
      `,
        { autoClose: false }
      );
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('チェックインに失敗しました。申し訳ありませんが再度チェックインを行ってください。');
    }
    toggleIsCheckingIn(false);
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>こちらのお申込みで間違いありませんか？</ModalHeader>
      <ModalBody>
        {lecture && (
          <div>
            <div className="d-flex align-items-center">
              <LectureTypeDisplay lectureType={lecture.lectureType} />
              <div className="ml-3 text-grey">{numeral(lecture.price).format('0,0')} 円(税込)</div>
            </div>
            <div className="mt-3 d-flex">
              <LectureLevelDisplay className="flex-fill" lectureLevel={lecture.lectureLevel} />
              <LectureAgeDisplay className="ml-3 flex-fill" lectureAge={lecture.lectureAge} />
            </div>
            <div className="mt-2">
              {lecture.date && formatDate(lecture.date.toDate(), 'yyyy/MM/dd')} {formatDate(startAt.toDate(), 'HH:mm')}{' '}
              - {formatDate(endAt.toDate(), 'HH:mm')}
            </div>
            <div className="mt-2">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td>アカウント名</td>
                    <td className="font-weight-bold">{entry.createdBy?.displayName}</td>
                  </tr>
                  <tr>
                    <td>参加者名</td>
                    <td className="font-weight-bold">{frame.name}</td>
                  </tr>
                  <tr>
                    <td>生年月日</td>
                    <td className="font-weight-bold">{formatDate(frame.birthday.toDate(), 'yyyy / MM / dd')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <AppButton color="primary" outline onClick={handleClickSubmit} disabled={isCheckingIn}>
          {isCheckingIn ? (
            <div className="d-flex align-items-center gap-1">
              <Spinner color="primary" size="sm" />
              チェックイン中
            </div>
          ) : (
            'チェックインする'
          )}
        </AppButton>
        <AppButton outline onClick={onClickClose}>
          閉じる
        </AppButton>
      </ModalFooter>
    </Modal>
  );
}
