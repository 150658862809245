import { useState } from 'react';
import firebase from '../firebase';
import { Input, Button } from 'reactstrap';
import { auditData } from '../shared/models/user';
import { User } from '../types/user';
import { ChatMessage as ChatMessageType } from '../types/chatMessage';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import ChatMessage from './ChatMessage';

export default function Chat({
  messagesRef,
  user,
  ownerAlias,
  formProps,
}: {
  messagesRef: firebase.firestore.CollectionReference;
  user: User;
  ownerAlias?: string;
  formProps?: any;
}) {
  const messages = useCollectionSubscriptionInTenant(messagesRef.orderBy('createdAt')) as ChatMessageType[];
  const [newMessage, setNewMessage] = useState<string>('');
  const onChangeInput = (e) => {
    setNewMessage(e.target.value);
  };
  const onClickButton = async () => {
    await messagesRef.add({ body: newMessage, createdBy: auditData(user), createdAt: new Date() });
    setNewMessage('');
  };

  return (
    <div className='d-flex flex-column gap-2'>
      <div className='d-flex flex-column gap-2'>
        {messages.map(({ id, body, createdBy, createdAt }) => (
          <ChatMessage
            key={id}
            message={body}
            owner={createdBy}
            createdAt={createdAt.toDate()}
            isOwnMessageView={user.uid === createdBy.uid}
            ownerAlias={ownerAlias}
          />
        ))}
      </div>
      <div className='d-flex align-items-end' {...formProps}>
        <Input type='textarea' value={newMessage} onChange={onChangeInput} />
        <Button color='info' className='text-nowrap ml-2' onClick={onClickButton} disabled={!newMessage}>
          送信
        </Button>
      </div>
    </div>
  );
}
