import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { sortBy, get, omit, omitBy, pick, isUndefined } from 'lodash';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import LectureForm from '../forms/LectureForm';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';

const db = firebase.firestore();
const usersRef = db.collection('users');
const eventsRef = db.collection('events');
const questionsRef = db.collection('questions');
const laterQuestionsRef = db.collection('laterQuestions');
const surveysRef = db.collection('surveys');

export default AdminPage(function AdminLectureForm(props) {
  const { firebaseUser } = useFirebaseUser();
  const {
    history,
    match: {
      params: { eventId, lectureId },
    },
    addBreadNavValues,
  } = props;
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const entries = useCollectionSubscription(eventRef.collection('entries'), [eventId]);
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const hasLectureEntries = entries.some((_) => get(_, 'frames', []).some((_) => _.lectureId === lectureId));
  const lecture = useDocumentSubscription(lectureId && eventRef.collection('lectures').doc(lectureId), [
    eventId,
    lectureId,
  ]);
  const questions = sortBy(useCollectionSubscriptionInTenant(questionsRef, []), (_) => _.createdAt.toDate());
  const laterQuestions = sortBy(useCollectionSubscriptionInTenant(laterQuestionsRef, []), (_) => _.createdAt.toDate());
  const surveys = sortBy(useCollectionSubscriptionInTenant(surveysRef), (_) => _.createdAt.toDate());
  const staffs = useCollectionsFetchInTenant(['admin', 'staff'].map(_ => usersRef.where('role', '==', _)));
  const onSubmit = async (values) => {
    const isNew = lectureId == null;
    const hasChangedDate = lecture && lecture.date.toDate().toDateString() !== values.date.toDateString();
    if (hasLectureEntries && hasChangedDate && !window.confirm('既に申込みがあります。本当に開催日を変更しますか？'))
      return;

    try {
      await eventsRef
        .doc(eventId)
        .collection('lectures')
        .doc(...(isNew ? [] : [lectureId]))
        [isNew ? 'set' : 'update']({
          ...omit(values, 'id'),
          ...(isNew && { tenantId: event.tenantId, createdAt: new Date() }),
          [isNew ? 'addedBy' : 'updatedBy']: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        });
      toast.success('保存しました');
      history.push(`/${event.tenantId}/admin/events/${eventId}`);
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  useEffect(() => {
    addBreadNavValues({ event, lecture });
  }, [event, lecture]);

  return (
    <div>
      <div className="admin-event container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>{lectureId ? 'レクチャー編集' : '新規レクチャー'}</h4>
        </div>
        <div>
          {(!lectureId || lecture) && (
            <LectureForm
              eventId={eventId}
              values={lecture || {}}
              questions={questions}
              laterQuestions={laterQuestions}
              onSubmit={onSubmit}
              undeletableFrame={hasLectureEntries}
              surveys={surveys}
              eventProductTypes={eventProductTypes}
              lectureTypes={lectureTypes}
              staffs={staffs}
            />
          )}
        </div>
      </div>
    </div>
  );
});
