import { format as formatDate } from 'date-fns';
import { keyBy, orderBy, pick } from 'lodash';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import { fields, reimbursementRequestFields } from '../../shared/models/inquiry';
import { fieldDisplayValue } from '../../shared/util';
import UserPage from '../hocs/UserPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import AppCard from '../AppCard';
import MypageMessagesButton from '../MypageMessagesButton';
import InquiryReimbursementRequestButton from '../InquiryReimbursementRequestButton';

const { entries } = Object;
const db = firebase.firestore();
const inquiriesRef = db.collection('inquiries');
const inquiryTypesRef = db.collection('inquiryTypes');

export default UserPage(function MypageInquiries(props) {
  const { user = {} } = props;
  const tenants = useCollectionSubscription(db.collection('tenants'));
  const tenantsById = keyBy(tenants, 'id');
  const inquiries = useCollectionSubscription(user.uid && inquiriesRef.where('createdBy.uid', '==', user.uid), [
    user.uid,
  ]);
  const inquiryTypes = useCollectionSubscription(inquiryTypesRef);
  const filteredinquiries = inquiries.filter((_) => !_.isAdminCreated);
  const sortedinquiries = orderBy(filteredinquiries, (_) => _.createdAt.toDate(), 'desc');

  return (
    <div className="mypage-inquiries">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">お問合せ履歴</h5>
      </section>
      <section className="container mt-4">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="d-flex justify-content-center">
              <div>
                お買上げの製品についての問合せは、
                <Link to="/mypage/orders">注文履歴</Link>
                からどうぞ
                <br />
                その他のお問合せは、
                <a href="https://www.vitamin-i.jp/contact">こちら</a>
              </div>
            </div>
            <div className="mt-4">
              {sortedinquiries.map((inquiry) => {
                const tenant = tenantsById[inquiry.tenantId];
                return (
                  <InquiryItem
                    key={inquiry.id}
                    inquiry={inquiry}
                    inquiryTypes={inquiryTypes}
                    tenant={tenant}
                    user={user}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

function InquiryItem({ inquiry, inquiryTypes, tenant, user }) {
  const { createdAt, cancelledAt, hasReimbursement, reimbursementRequest } = inquiry;
  const isCancelled = cancelledAt != null;

  return (
    <div className="mb-4">
      <div className="position-relative" style={{ textDecoration: 'none' }}>
        <AppCard className="card text-dark p-3 p-sm-4" style={{ opacity: isCancelled && 0.5 }}>
          <div className="flex-grow-1">
            <div className="mb-3">
              <div className="small text-muted">お問合せ先</div>
              {tenant?.name}
            </div>
            <div className="mb-3">
              <div className="small text-muted">お問合せ日時</div>
              {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
            </div>
            {entries(pick(fields({ inquiryTypes }), ['inquiryTypeId', 'description'])).map(
              ([fieldName, fieldSetting]) => {
                return (
                  <div key={fieldName} className="mb-3 text-truncate">
                    <div className="small text-muted">{fieldSetting.label}</div>
                    {fieldDisplayValue(inquiry[fieldName], fieldSetting)}
                  </div>
                );
              }
            )}
          </div>
          <div className="d-flex justify-content-end pt-3">
            {hasReimbursement && (
              <InquiryReimbursementRequestButton
                inquiry={inquiry}
                formFields={reimbursementRequestFields()}
                buttonProps={{
                  color: 'secondary',
                  className: 'rounded-pill px-4',
                  size: 'sm',
                  disabled: !!reimbursementRequest?.requestedAt,
                }}
                user={user}
              />
            )}
            {inquiry.messages?.length > 0 && <MypageMessagesButton color="secondary" className="rounded-pill px-4" size="sm" type="inquiry" subject={inquiry} reinquiryPath="inquiries/new" />}
          </div>
        </AppCard>
      </div>
    </div>
  );
}
