import React, { useEffect, } from 'react';
import { pick, sortBy } from 'lodash';
import ellipsis from 'text-ellipsis';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import { canUpdateTenantSettings } from '../../shared/abilities';
import { fieldDisplayValue } from '../../shared/util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import FilesUploadButton from '../FilesUploadButton';
import EditButton from '../EditButton';
import { generalSettingsFields, } from '../../shared/models/tenant';

const db = firebase.firestore();
const { entries, keys, } = Object;

export default AdminPage(function AdminSettings(props) {
  const { user, tenant } = props;
  const validateFiles = _ => /^image/.test(_[0]?.type) || toast.error('画像を選択してください');
  const storagePath = `tenants/${tenant.id}/settings/logo`;
  const onUploaded = async ([{ path, file, fileRef, downloadUrl }]) => {
    await tenant.ref.update({
      logo: {
        uploadedAt: new Date(),
        downloadUrl,
        storagePath: path,
        ...pick(file, ['name', 'type']),
      },
    });
  };

  return (
    <div className="admin-settings container py-5 position-relative">
      <div className="d-flex justify-content-center mb-3">
        <h4>設定</h4>
      </div>
      <div>
        <h5>ロゴ</h5>
        <div className="d-flex gap-2 align-items-start">
          <FilesUploadButton
            multiple={false}
            path={_ => storagePath + '/' + _.name}
            onUploaded={onUploaded}
            accept='image/*'
            validate={validateFiles}
          />
          <img src={tenant.logo?.downloadUrl} style={{ maxWidth: 300 }} />
        </div>
      </div>
      <hr className="my-5" />
      <div className="d-flex justify-content-end mb-3">
        <EditButton color="primary" itemRef={tenant.ref} FormModal={ModelFormModal} formProps={{ title: '設定', fields: generalSettingsFields(tenant) }} disabled={!canUpdateTenantSettings(user)} />
      </div>
      <div>
        <table className="table table-bordered">
          <tbody className="thead-light">
            {
              entries(generalSettingsFields()).map(([fieldName, fieldSetting]) => {
                const { label, hidden = _ => false, } = fieldSetting;
                return !hidden(tenant) && (
                  <tr key={fieldName}>
                    <th style={{ width: 300 }}>{label}</th>
                    <td>{fieldDisplayValue(tenant[fieldName], fieldSetting)}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
