import React from 'react';
import { format as formatDate } from 'date-fns';

import firebase, { getFirestore, collection } from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import UserListSection from '../UserListSection';
import TenantLink from '../TenantLink';

const db = firebase.firestore();

export default AdminPage(function AdminEnvelopeScheduleDeliveryHistories(props) {
  const {
    user,
    match: {
      params: { envelopeScheduleId },
    },
  } = props;
  const collectionPath = `envelopeSchedules/${envelopeScheduleId}/deliveryHistories`;
  const usersRef = db.collection(collectionPath);
  const prefixColumns = [
    {
      name: 'createdAt',
      label: (_) => '受注作成日時',
      body: ({ orderId, createdAt }) => {
        const Component = orderId != null ? TenantLink : 'div';
        return (
          <Component to={`/admin/orders/${orderId}`}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</Component>
        );
      },
    },
  ];

  return (
    <UserListSection
      user={user}
      usersRef={usersRef}
      title="受注作成履歴"
      prefixColumns={prefixColumns}
      usersRefV9={collection(getFirestore(), collectionPath)}
    />
  );
});
