import React from 'react';
import numeral from 'numeral';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { keyBy, uniq } from 'lodash';
import { format as formatDate } from 'date-fns';

import { ageDisplay } from '../../shared/util';
import firebase from '../../firebase';
import LectureTitle from '../LectureTitle';
import TenantLink from '../TenantLink';
import AdminUserLink from '../AdminUserLink';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useTenant from '../hooks/useTenant';
import EntryFrameNoteModal from './EntryFrameNoteModal';
import EditButton from '../EditButton';
import useDocumentSubscription from '../hooks/useDocumentSubscription';

const db = firebase.firestore();
const questionsRef = db.collection('questions');
const laterQuestionsRef = db.collection('laterQuestions');
const surveysRef = db.collection('surveys');

export default function LectureDetailModal(props) {
  const {
    user,
    isOpen,
    event,
    entry,
    lecture,
    onClickClose,
    entryIds,
    history,
    eventProductTypesById,
    lectureTypesById,
  } = props;
  const tenant = useTenant();
  const questions = useCollectionSubscription(questionsRef, [event.id]);
  const questionsById = keyBy(questions, 'id');
  const survey = useDocumentSubscription(lecture.surveyId && surveysRef.doc(lecture.surveyId), [lecture.id]);
  // NOTE: lectureにあるアンケート（lecture内の順番通り） -> lecutreにないアンケートの順に表示する
  const entryQuestionIds = entry
    ? uniq([...(lecture.questions || []), ...(survey?.questionRows?.map((_) => _.questionId) || [])])
    : [];
  const entryQuestions = entryQuestionIds.map((_) => {
    const question = questionsById[_];
    const answer = entry.answers && entry.answers[_];

    return { question, answer };
  });
  const laterQuestions = useCollectionSubscription(laterQuestionsRef, [event.id]);
  const laterQuestionsById = keyBy(laterQuestions, 'id');

  const currentIndex = entryIds.indexOf(entry.id);
  const prevEntryId = entryIds[(currentIndex + entryIds.length - 1) % entryIds.length];
  const nextEntryId = entryIds[(currentIndex + 1) % entryIds.length];

  return (
    <Modal isOpen={isOpen} modalClassName="w-100">
      <ModalHeader>イベント申込み詳細</ModalHeader>
      <ModalBody>
        <div>
          <div className="d-flex justify-content-between mb-1">
            <Button
              className="mr-1 w-100"
              color="secondary"
              onClick={() =>
                history.push(`/${tenant.id}/admin/events/${event.id}/lectures/${lecture.id}?entryId=${prevEntryId}`)
              }
            >
              <span className="fas fa-arrow-left mr-1" />
              前へ
            </Button>
            <Button
              className="ml-1 w-100"
              color="secondary"
              onClick={() =>
                history.push(`/${tenant.id}/admin/events/${event.id}/lectures/${lecture.id}?entryId=${nextEntryId}`)
              }
            >
              <span className="fas fa-arrow-right mr-1" />
              次へ
            </Button>
          </div>
          {entry && (
            <div className="card p-3">
              {entry.cancelledAt != null && <div className="alert alert-warning">この申込みはキャンセル済みです</div>}
              {entry.abortedAt != null && <div className="alert alert-warning">この申込みは中止済みです</div>}
              <div className="mb-3">
                <div className="text-muted small">アカウント名</div>
                <AdminUserLink operator={user} user={entry.createdBy} target="_blank" />
              </div>
              <div className="mb-3">
                <div className="text-muted small">メールアドレス</div>
                {entry.createdBy.email}
              </div>
              <div className="mb-3">
                <div className="text-muted small">申込日時</div>
                {formatDate(entry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
              </div>
              <div className="mb-3">
                <div className="text-muted small">決済金額(税込)</div>
                {numeral(entry.charge && entry.charge.amount).format('0,0')} 円
              </div>
              <div className="mb-3">
                <div className="text-muted small">市区町村</div>
                {entry.createdBy.city}
              </div>
              <div className="mb-3">
                <h5>参加枠</h5>
                {entry.frames.map(
                  ({ lectureId, frameIndex, name, birthday, laterQuestionInstructionMailSentAt, laterAnswers }, i) => {
                    const frame = lecture.frames[frameIndex];
                    return (
                      <div key={i} className="card p-3 mb-2">
                        <div>
                          <LectureTitle {...lecture} {...{ eventProductTypesById, lectureTypesById }} />
                        </div>
                        <div>
                          {formatDate(frame.startAt.toDate(), 'HH:mm')} 〜 {formatDate(frame.endAt.toDate(), 'HH:mm')}
                        </div>
                        <div>
                          <span>{name}</span>
                          {birthday && (
                            <>
                              <span className="ml-2">{formatDate(birthday.toDate(), 'yyyy/MM/dd')}</span>
                              <span className="ml-2">({ageDisplay(birthday.toDate(), lecture.date.toDate())})</span>
                            </>
                          )}
                        </div>
                        {laterAnswers != null && (
                          <div className="mt-4">
                            <div className="h6">[事後アンケート回答]</div>
                            {Object.entries(laterAnswers || {}).map(([laterQuestionId, _value]) => {
                              const laterQuestion = laterQuestionsById[laterQuestionId];
                              if (!laterQuestion) return null;
                              const { type, name } = laterQuestion;
                              const value = {
                                text: _value,
                                radio: _value,
                                select: _value,
                                checkbox: Object.entries(_value || {})
                                  .filter((_) => _[1])
                                  .map((_) => _[0])
                                  .join(', '),
                              }[type];
                              return (
                                <div key={laterQuestionId} className="mb-3">
                                  <div className="text-muted small">{name}</div>
                                  {value}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
              <div className="mb-3">
                <h5>備考</h5>
                {entry.frames.map(({ note, noteUpdatedAt, noteUpdatedBy, lectureId }, i) => {
                  return (
                    <div key={i} className="card p-3 mb-2">
                      <div className="d-flex justify-content-end">
                        <EditButton
                          itemRef={entry.ref}
                          FormModal={EntryFrameNoteModal}
                          label=""
                          icon={<span className="fas fa-edit" />}
                          formProps={{ user, entry, lectureId }}
                        />
                      </div>
                      <div>{note}</div>
                      {noteUpdatedAt && noteUpdatedBy && (
                        <div className="text-muted small">
                          最終更新: {noteUpdatedAt.toDate().toLocaleString()} {noteUpdatedBy.displayName}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="mt-4">
                <h5>申込時アンケート</h5>
                {entryQuestions.map(({ question, answer }) => {
                  if (!question || !answer) return null;

                  const { id, type, name } = question;
                  const value = {
                    text: answer,
                    radio: answer,
                    select: answer,
                    checkbox: Object.entries(answer || {})
                      .filter((_) => _[1])
                      .map((_) => _[0])
                      .join(', '),
                  }[type];

                  return (
                    <div key={id} className="mb-3">
                      <div className="text-muted small">{name}</div>
                      {value}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClickClose}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  );
}
