import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  limit as _limit,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
  getCollectionData,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { EventDocumentData, Event } from '../types';
import type { PartiallyPartial } from 'types';

export const eventConverter = getConverter<EventDocumentData>();

export const eventsRef = () => collection(getFirestore(), 'events').withConverter(eventConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Event> : null;
export const eventRef = <Id extends string | undefined>(eventId: Id) =>
  (eventId ? doc(eventsRef(), eventId) : null) as RefOrNull<Id>;

export const newEventRef = () => doc(eventsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const eventsByPlaceIdConstraints = (placeId: string) => [where('placeId', '==', placeId)];

export const eventsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(eventsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const eventsByPlaceIdQuery = (placeId: string, limit?: number) =>
  eventsQuery(...eventsByPlaceIdConstraints(placeId), ...(limit ? [_limit(limit)] : []));

export const addEvent = async (data: PartiallyPartial<Event, 'id' | 'createdAt'>) =>
  addDoc(eventsRef(), { createdAt: serverTimestamp(), ...data });

export const updateEvent = async (eventId: string, data: UpdateData<Event>) => updateDoc(eventRef(eventId), data);

export const deleteEvent = async (eventId: string) => deleteDoc(eventRef(eventId));

export const useEventCollection = useCollectionData;

export const useEventCollectionOnce = useCollectionDataOnce;

export const useEventDocument = useDocumentData;

export const useEventDocumentOnce = useDocumentDataOnce;

export const getEventCollection = getCollectionData;
