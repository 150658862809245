const { isHiragana } = require('wanakana');
const { isEmpty } = require('lodash');
const { isEmail, } = require('validator');

const { prefectures, } = require('../config');

const { entries } = Object;

module.exports = {
  fields: ({ forAgent = false } = {}) => {
    return {
      name: {
        label: '店舗名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
        hint: forAgent && '団体名、紹介者名としてお客様に表示されます',
      },
      nameKana: {
        label: 'ふりがな',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
          hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
        },
      },
      customerCode: {
        label: '得意先CD',
        type: 'string',
      },
      email: {
        type: 'string',
        label: 'メールアドレス',
        validations: {
          required: v => !isEmpty(v),
          format: v => !v || isEmail(v),
        },
      },
      phone: {
        type: 'string',
        inputType: 'tel',
        label: '電話番号',
        validations: {
          required: v => !isEmpty(v),
          format: v => !v || v.match(/^(0{1}\d{9,10})$/),
        },
      },
      hidesShopDestination: {
        label: '「店舗に送る」を非表示',
        type: 'boolean',
        initialValue: false,
      },
      postalCode: {
        type: 'string',
        label: '郵便番号',
        validations: {
          required: v => !isEmpty(v),
          format: v => (v || '').match(/^[0-9]{7}$/g),
        },
        inputProps: {
          type: 'tel',
        },
      },
      prefecture: {
        label: '都道府県',
        type: 'select',
        options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
        validations: {
          required:  v => !isEmpty(v),
        },
      },
      city: {
        type: 'string',
        label: '市区町村',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      address: {
        type: 'string',
        label: '番地・建物名',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
    };
  },
};
