const statuses = {
  initial: { label: '未対応', color: 'warning', },
  approved: { label: '承認済み', color: 'success', },
  rejected: { label: 'キャンセル済み', color: 'secondary', },
};

const fieldsToApprove = () => {
  return {
    orderId: {
      label: '発注番号',
      type: 'string',
      hint: '指定しない場合は仮注文の注文番号が使用されます',
    },
  };
};

module.exports = {
  statuses,
  fieldsToApprove,
};
