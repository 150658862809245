import React from 'react';

import UserPage from '../hocs/UserPage';
import Receipt from '../Receipt';

export default UserPage(function MypageOrderReceipt (props) {
  const { match: { params: { orderId } } } = props;

  return (
    <div>
      <div className="mypage-order-receipt container py-5 position-relative d-flex justify-content-center">
        <Receipt orderId={orderId} readOnly />
      </div>
    </div>
  );
});
