import { Button, Form, Input } from 'reactstrap';
import classnames from 'classnames';
import { isEmpty, keyBy, get } from 'lodash';
import { useList } from 'react-use';
import numeral from 'numeral';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../../util';
import { colors } from '../../shared/config';
import { Product } from '../../types/product';
import { ProductTag } from '../../types/productTag';
import RichTextContent from '../RichTextContent';
import AppButton from '../AppButton';

const { entries, values } = Object;

type StatedFieldsProductTypeId = {
  value: any;
};

type StatedFieldsFiles = {
  setValue: any;
};

type StatedFields = {
  productTypeId: StatedFieldsProductTypeId;
  files?: StatedFieldsFiles;
};

type Props = {
  products: Product[];
  productTags: ProductTag[];
  selectedItems: { [key: string]: number };
  setSelectedItems: (key: string, value: number) => void;
  removeSelectedItems: (key: string) => void;
  statedFields?: StatedFields;
  onSubmit?: () => void;
  onCancel?: () => void;
  showsPrice?: boolean;
  showsWholesalePrice?: boolean;
  isWholesale?: boolean;
  isOptinal?: boolean;
  noInventoryLabel?: string;
};

export default function PartsSelectorForm(props: Props) {
  const {
    products,
    productTags,
    selectedItems,
    setSelectedItems,
    removeSelectedItems,
    statedFields,
    onSubmit,
    onCancel,
    showsPrice = false,
    showsWholesalePrice = false,
    isWholesale = false,
    noInventoryLabel,
    isOptinal = false,
  } = props;
  const productsFiletedByType = statedFields
    ? products.filter((_) => (_.productTypeIds || []).includes(statedFields.productTypeId.value))
    : [];
  const productsById = keyBy(products, 'id');
  const productTagsById = keyBy(productTags, 'id');
  const selectedAllTag: ProductTag = {
    id: 'all-products-tag',
    name: 'すべて',
    color: 3,
  };
  const selectableTags = [selectedAllTag, ...productTags];
  const [selectedTags, { push: selectTag, removeAt: removeSelectedTagAt, clear: clearSelectedTags }] =
    useList<ProductTag>([]);
  const selectedTagIds = selectedTags.map((_) => _.id);
  const unselectTag = (_) => removeSelectedTagAt(selectedTags.indexOf(_));
  const filteredProducts =
    selectedTags.length === 0
      ? productsFiletedByType
      : productsFiletedByType.filter((product) =>
          selectedTagIds.some((_) => (product.productTagIds || []).includes(_))
        );
  const handleCancel = () => {
    statedFields?.files?.setValue(null);
    onCancel && onCancel();
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    onSubmit && (await onSubmit());
  };
  const isUnsubmittable = (!isOptinal && isEmpty(selectedItems)) || values(selectedItems).some((_) => !(_ > 0));
  const totalAmount = entries(selectedItems).reduce((total, [productId, count]) => {
    const product = productsById[productId];
    return total + product?.price * count;
  }, 0);
  const totalWholesaleAmount = entries(selectedItems).reduce((total, [productId, count]) => {
    const product = productsById[productId];
    return total + Math.floor((product?.price * product?.wholesalePriceRate) / 100) * count;
  }, 0);

  return (
    <Form onSubmit={handleSubmit}>
      <div style={{ marginBottom: 80 }}>
        <div>
          {selectableTags.map((productTag) => {
            const { id, name, color } = productTag;
            const isAllProductsTag = id === selectedAllTag.id;
            const isSelected = isAllProductsTag ? selectedTags.length === 0 : selectedTags.includes(productTag);
            const handleClickTag = () => {
              isAllProductsTag ? clearSelectedTags() : isSelected ? unselectTag(productTag) : selectTag(productTag);
            };

            return (
              <span
                key={id}
                className={`badge mr-1 cursor-pointer ${isAllProductsTag && 'px-3 text-white'}`}
                style={{ backgroundColor: colors[color], opacity: isSelected ? 1 : 0.5 }}
                onClick={handleClickTag}
              >
                {name}
              </span>
            );
          })}
        </div>
        <div className="mt-4">
          {filteredProducts.map((product) => {
            const {
              id,
              name,
              productTagIds = [],
              image,
              price,
              normalInventory,
              description,
              wholesalePriceRate,
            } = product;
            return (
              <div key={id} className="border-top py-3">
                <div>
                  <div className="small">
                    <span className="mr-2">{name}</span>
                    {productTagIds?.map((productTagId) => {
                      const productTag = productTagsById[productTagId];
                      return (
                        productTag != null && (
                          <span
                            key={productTagId}
                            className="badge mr-1"
                            style={{ backgroundColor: colors[productTag.color] }}
                          >
                            {productTag.name}
                          </span>
                        )
                      );
                    })}
                  </div>
                </div>
                <div className="mt-2 d-flex">
                  <div>
                    <img src={image} style={{ maxHeight: 100 }} />
                  </div>
                  <RichTextContent className="ml-2 small flex-fill" html={description || ''} />
                  <div className="ml-2 d-flex flex-column justify-content-end">
                    {showsPrice && <div className="mb-2 text-center">&yen;{numeral(price).format('0,0')}</div>}
                    {isWholesale && showsWholesalePrice && (
                      <div className="mb-2 text-center text-muted">
                        (<span className="small mr-1">卸価格</span>&yen;
                        {numeral(Math.floor(price * (wholesalePriceRate / 100))).format('0,0')})
                      </div>
                    )}
                    {noInventoryLabel && !normalInventory ? (
                      <div className="text-right text-danger" style={{ minWidth: 65 }}>
                        {noInventoryLabel}
                      </div>
                    ) : (
                      <Button
                        className="text-nowrap"
                        color="secondary"
                        size="sm"
                        onClick={(_) => setSelectedItems(product.id, (get(selectedItems, product.id) || 0) + 1)}
                      >
                        <span className="fas fa-plus mr-1" />
                        選択
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {showsPrice && <div className="mt-3 text-muted small">※ 価格表示は税込です。</div>}
      </div>
      <div className="position-sticky" style={{ bottom: 10, left: 0, right: 0 }}>
        <div className="card py-3 d-flex">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between align-items-center px-3">
              {showsPrice && (
                <div className="text-nowrap flex-fill">合計金額: &yen;{numeral(totalAmount).format('0,0')}</div>
              )}
              {isWholesale && showsWholesalePrice && (
                <div className="text-nowrap flex-fill text-muted">
                  (<span className="small mr-1">卸価格</span>&yen;{numeral(totalWholesaleAmount).format('0,0')})
                </div>
              )}
              {onCancel && (
                <AppButton className="cancel text-nowrap flex-fill" size="sm" color="secondary" onClick={handleCancel}>
                  <span className="fas fa-arrow-left mr-1" />
                  戻る
                </AppButton>
              )}
              <AppButton
                className="text-nowrap save ml-4 flex-fill"
                size="sm"
                type="submit"
                color="primary"
                onClick={onSubmit}
                disabled={isUnsubmittable}
              >
                <span className="fas fa-arrow-right mr-1" />
                次へ
              </AppButton>
            </div>
            {isEmpty(selectedItems) ? (
              !isOptinal && (
                <div className="text-center">選択してください</div>
              )
            ) : (
              <div className="d-flex flex-column overflow-auto px-3" style={{ maxHeight: '250px' }}>
                <div className="flex-fill">
                  {entries(selectedItems).map(([productId, count]) => {
                    const product = productsById[productId];

                    return (
                      <div key={productId} className="d-flex align-items-center justify-content-between mb-1 small">
                        <div>
                          <span
                            className="fas fa-times text-danger cursor-pointer"
                            onClick={(_) => removeSelectedItems(productId)}
                          />
                          <span className="ml-2">{product?.name}</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <Input
                            type="number"
                            bsSize="sm"
                            value={count}
                            className={classnames('text-right mr-1', { 'is-invalid': !(count > 0) })}
                            style={{ width: 70 }}
                            onChange={(_) => setSelectedItems(productId, parseInt(_.target.value, 10))}
                            min={0}
                            step={1}
                          />
                          個
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
