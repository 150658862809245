import React, { useEffect, useState } from 'react';
import { Label, Button, Form, FormGroup, Input } from 'reactstrap';
import { omitBy, upperFirst, uniqWith, isEqual, get, omit, camelCase, pick, isEmpty, mapValues, keyBy } from 'lodash';
import { set } from 'lodash/fp';
import { useList } from 'react-use';
import { useToggle, useAsync } from 'react-use';
import classnames from 'classnames';
import dedent from 'dedent';
import sanitizeHtml from 'sanitize-html';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { stripHtml } from 'string-strip-html';
import Rte, { getTextAlignClassName } from 'react-rte';

import { activateRichTextHtml } from '../../util';
import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import Field from '../Field';
import { prefectures } from '../../shared/config';
import { fields } from '../../shared/models/inquiry';
import AppButton from '../AppButton';
import SettingText from '../SettingText';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const { entries, keys } = Object;
const prefecturesByPref = mapValues(keyBy(entries(prefectures), '1'), '0');
const checkEmailExistence = functions.httpsCallable('checkEmailExistence');

export default function NewInquiryForm(props) {
  const { inquiryTypes, inquiryTypeChildren, user, values, onClickLogin, sourceInquiry } = props;
  const userValues = user != null ? { name: user.displayName, ...user } : {};
  const inquiryTypesById = keyBy(inquiryTypes, 'id');
  const inquiryTypeChildrenById = keyBy(inquiryTypeChildren, 'id');
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [registersAccount, toggleRegistersAccount] = useToggle(true);
  const [password, setPassword] = useState('');
  const statedFields = useFormState(values, fields({ inquiryTypes, inquiryTypeChildren }), values);
  const validationErrorMessages = [
    ...Object.values(statedFields)
      .filter((_) => !_.isValid)
      .map(({ label, validationErrors }) => {
        return `${label}にエラーがあります`;
      }),
    user == null && registersAccount && password.length < 6 && 'パスワードにエラーがあります',
  ].filter((_) => _);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    statedFields.startAll();
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    toggleSubmitting(true);
    await props.onSubmit({
      ...mapValues(statedFields, 'value'),
      password,
      registersAccount,
    });
  };
  const onClickSearchAddress = async (postalCode, { fieldNames = ['prefecture', 'city'] } = {}) => {
    try {
      const [, postalCode1, postalCode2] = postalCode.match(/^(\d{3})(\d{4})$/) || [];
      if (!postalCode1 || !postalCode2) return;

      const { results } = await (
        await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`)
      ).json();
      if (isEmpty(results)) return;

      const [
        { address1, address2, address3 },
      ] = results;
      statedFields.setValues({
        [fieldNames[0]]: prefecturesByPref[address1],
        [fieldNames[1]]: address2 + address3,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const { value: isExistingEmail } = useAsync(async () => {
    if (!statedFields.email.isValid) return;

    const { data } = await checkEmailExistence({ email: statedFields.email.value });
    return data.exists;
  }, [statedFields.email.value]);
  useEffect(() => {
    if (user != null) {
      statedFields.setValues({
        name: user.displayName,
        ...omitBy(pick(user, ['nameKana', 'phone', 'email', 'postalCode', 'prefecture', 'city', 'address']), isEmpty),
      });
    }
    if (sourceInquiry != null) {
      statedFields.setValues({
        ...pick(sourceInquiry, ["inquiryTypeId", "inquiryTypeChildId", "callableTime"]),
      });
    }
  }, [user, sourceInquiry]);

  const onClickCopy = (body) => {
    const sanitizedHtml = sanitizeHtml(activateRichTextHtml(body || ''), {
      allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img', 'h2'],
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        img: [...sanitizeHtml.defaults.allowedAttributes.img, 'style'],
      },
    });
    const stripedHtml = stripHtml(sanitizedHtml, {
      dumpLinkHrefsNearby: { enabled: true, wrapHeads: '[', wrapTails: ']' },
    }).result;
    copy(stripedHtml);
    toast.success('コピーしました');
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <h4 className="h5 text-center font-weight-bold">お問合せ</h4>
            <SettingText
              name="troubleInquiryPageHeaderHtml"
              renderContainer={(html, children) =>
                !isEmpty(html) && <div className="border border-info rounded p-3 my-3">{children}</div>
              }
            />
            {user == null && (
              <div className="mt-4 card p-3">
                <div className="mb-2">すでにアカウントをお持ちの方はログインしてください</div>
                <Button color="primary" size="lg" block onClick={onClickLogin}>
                  ログインする
                </Button>
              </div>
            )}
            <div className="mt-5">
              <Field name="inquiryTypeId" documentName="inquiry" {...statedFields.inquiryTypeId} />
              <Field
                name="inquiryTypeChildId"
                documentName="inquiry"
                {...statedFields.inquiryTypeChildId}
                values={mapValues(statedFields, 'value')}
                label={false}
              />
              <div className="mb-3">
                <RichTextContent html={inquiryTypeChildrenById[statedFields.inquiryTypeChildId.value]?.body || ''} />
                {inquiryTypeChildrenById[statedFields.inquiryTypeChildId.value]?.copyableContent &&
                  <>
                    <Rte value={Rte.createValueFromString(inquiryTypeChildrenById[statedFields.inquiryTypeChildId.value]?.copyableContent || '', 'html')} blockStyleFn={getTextAlignClassName} readOnly disabled />
                    <div className="d-flex justify-content-end mt-1">
                      <Button color="primary" size='sm' onClick={() => onClickCopy(inquiryTypeChildrenById[statedFields.inquiryTypeChildId.value]?.copyableContent)}>
                        <span className="fas fa-copy mr-1" />テキストをコピー
                      </Button>
                    </div>
                  </>
                }
              </div>
              {keys(omit(fields({ inquiryTypes, inquiryTypeChildren }), ['inquiryTypeId', 'inquiryTypeChildId'])).map(
                (fieldName) => {
                  const fieldSetting = statedFields[fieldName];
                  const fieldContent = (
                    <Field
                      key={fieldName}
                      name={fieldName}
                      documentName="inquiry"
                      {...fieldSetting}
                      readOnly={!isEmpty(userValues[fieldName]) ? (_) => true : fieldSetting.readOnly}
                    />
                  );
                  if (fieldName === 'postalCode') {
                    return (
                      <div key={fieldName} className="d-flex align-items-start">
                        <div className="flex-fill">{fieldContent}</div>
                        <div className="ml-2 mt-3 pt-3">
                          <Button onClick={onClickSearchAddress.bind(null, fieldSetting.value)}>
                            <span className="fas fa-search mr-1" />
                            郵便番号で住所検索
                          </Button>
                        </div>
                      </div>
                    );
                  } else {
                    return fieldContent;
                  }
                }
              )}
            </div>
            {user == null &&
              (isExistingEmail ? (
                <div className="mt-4 card p-3">
                  <div className="alert alert-warning mb-3">
                    お客様のメールアドレスはすでにアカウント登録されています。
                    <br />
                    ログインしてください。
                  </div>
                  <Button color="primary" size="lg" block onClick={onClickLogin}>
                    ログインする
                  </Button>
                </div>
              ) : (
                <div className="mt-5">
                  <h4>アカウント登録</h4>
                  <div className="alert alert-info">
                    30分で乗れる自転車教室の予約やへんしんバイクを購入後の乗り方サポート、メンテナンスサポートを受けられます。
                  </div>
                  <div>
                    <FormGroup check className="d-none">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={registersAccount}
                          onChange={(_) => toggleRegistersAccount(_.target.checked)}
                        />
                        アカウント登録する
                      </Label>
                    </FormGroup>
                    {registersAccount && (
                      <FormGroup className="mt-2">
                        <Label>
                          パスワード
                          <span className="text-danger small">【必須】</span>
                        </Label>
                        <Input
                          type="password"
                          value={password}
                          onChange={(_) => setPassword(_.target.value)}
                          minLength={6}
                        />
                        <small className="form-text text-muted">6文字以上で指定してください</small>
                      </FormGroup>
                    )}
                  </div>
                </div>
              ))}
            {!(user == null && isExistingEmail) && (
              <div className="d-flex mt-5">
                <AppButton
                  size="lg"
                  color="primary"
                  className="save flex-fill ml-2"
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={isSubmitting}
                >
                  <span
                    className={classnames('fas mr-1', {
                      'fa-arrow-right': !isSubmitting,
                      'fa-spin fa-spinner': isSubmitting,
                    })}
                  />
                  確認画面
                </AppButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </Form>
  );
}
