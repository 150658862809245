import React from 'react';
import { Button, Form, } from 'reactstrap';
import { mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields } from '../../shared/models/place';

const { entries } = Object;

export default function PlaceForm(props) {
  const statedFields = useFormState(null, fields);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value'), });
  };

  return (
    <Form onSubmit={onSubmit} className="d-flex align-items-end">
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => (
          <div className="mr-2 flex-grow-1" style={{ minWidth: 150 }}>
            <Field
              key={fieldName}
              name={fieldName}
              {...fieldSetting}
            />
          </div>
        ))
      }
      <div className="text-nowrap" style={{ marginBottom: '1rem' }}>
        <Button type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
      </div>
    </Form>
  );
};
