const { partition, omit, isEmpty, sumBy, flatten, sortBy } = require('lodash');

const { max } = Math;

module.exports = {
  fields: ({ rentalItems }) => {
    return {
      rentalItemId: {
        label: 'レンタル商品',
        type: 'select',
        options: rentalItems.map(_ => ({ label: _.name, value: _.id })),
        validations: {
          required:  v => !isEmpty(v),
        },
      },
      code: {
        label: '識別コード',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: 'メモ',
        type: 'text',
        initialValue: '',
      },
    };
  },
};

