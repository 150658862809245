import React from 'react';
import { Badge } from 'reactstrap';
import { keyBy, isEmpty, sortBy, uniq, uniqBy, orderBy } from 'lodash';
import { format as formatDate } from 'date-fns';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import { roles, prefectures } from '../../shared/config';
import { userConditionText } from '../../shared/util';
import ExportButton from '../ExportButton';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import { statuses, adminFields, orderTypes, entryTypes, dateTypes, supportFields } from '../../shared/models/contact';
import TenantLink from '../TenantLink';
import { getCollectionData } from '../../shared/firebase';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import { auditData } from '../../shared/models/user';
import useTenant from '../hooks/useTenant';
import { useSurveyCollection, tenantSurveysQuery } from '../../models/survey';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import { areaFromPostalCode } from '../../shared/models/setting';

const db = firebase.firestore();

export default AdminPage(function AdminContactContactTargets(props) {
  const {
    user,
    match: {
      params: { contactId },
    },
  } = props;
  const tenant = useTenant();
  const contactRef = db.collection('contacts').doc(contactId);
  const contact = useDocumentSubscription(contactRef);
  const usersRef = db.collection(`contacts/${contactId}/contactTargets`);
  const params = useQueryParams();
  const { field: [field] = ['email'], text, showsAllUsers: _showsAllUsers = '0', areaGroups: areaGroupsFilter, areaStaffs: areaStaffsFilter } = params;
  const showsAllUsers = _showsAllUsers === '1';
  const usersQuery = text
    ? usersRef.orderBy(field).startAt(text).endAt(`${text}\uf8ff`)
    : showsAllUsers
    ? usersRef
    : usersRef.orderBy('createdAt', 'desc').limit(100);
  const users = useCollectionSubscription(usersQuery, [params]);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウント名', value: 'displayName' },
    { label: '電話番号', value: 'phone' },
  ];
  const { data: surveys = [] } = useSurveyCollection(tenantSurveysQuery(tenant?.id));
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map(_ => _.group));
  const areaGroupOptions = areaGroups.map(_ => ({ label: _, value: _ }));
  const areaStaffs = uniqBy(Object.values(areaSetting?.data || {}).map(_ => _.user), 'id');
  const areaStaffOptions = areaStaffs.map(_ => ({ label: _.displayName, value: _.id }));

  let filteredRows = orderBy(users, (user) => {
    if (user.conditions?.order?.shippedDate) return new Date(user.conditions.order?.shippedDate);
    if (user.conditions?.order) return user.conditions.order.createdAt.toDate();
    if (user.conditions?.entry) return user.conditions.entry.createdAt.toDate();
    return user.createdAt.toDate();
  }, 'desc');
  if (!text) {
    if (!isEmpty(areaGroupsFilter)) {
      filteredRows = filteredRows.filter((user) => {
        const setting = areaFromPostalCode(user.postalCode, areaSetting);
        return setting && areaGroupsFilter.includes(setting.group);
      });
    }
    if (!isEmpty(areaStaffsFilter)) {
      filteredRows = filteredRows.filter((user) => {
        const setting = areaFromPostalCode(user.postalCode, areaSetting);
        return setting && areaStaffsFilter.includes(setting.user.id);
      });
    }
  }

  return (
    <div>
      <div className="admin-users container-fluid py-5 position-relative d-flex flex-column gap-3">
        <div className="d-flex justify-content-center">
          <h4>コンタクトリスト詳細</h4>
        </div>
        <ContactTable contact={contact} />
        <div className="d-flex justify-content-center">
          <h4>コンタクトリスト</h4>
        </div>
        <div className="d-flex flex-wrap gap-2 align-items-end">
          <QuerySelector
            paramName="field"
            options={fieldOptions}
            label="検索フィールド"
            defaultValue={[field]}
            isClearable={false}
          />
          <QueryText paramName="text" label="検索テキスト" />
          <QueryBoolean paramName="showsAllUsers" label="全アカウント表示" defaultValue={'0'} />
        </div>
        <div className="mt-2 d-flex align-items-end flex-wrap gap-2">
          <QuerySelector
            paramName="areaGroups"
            width={250}
            max={10}
            isMulti
            options={areaGroupOptions}
            label="エリアグループで絞込み"
          />
          <QuerySelector
            paramName="areaStaffs"
            width={250}
            max={10}
            isMulti
            options={areaStaffOptions}
            label="担当者で絞込み"
          />
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-end">
          <ExportButton
            fileName="users.csv"
            rows={filteredRows.map((u) => ({
              ...['id', 'email', 'displayName', 'role', 'ngDm', 'note', 'subUserIds', 'mainUserId'].reduce(
                (x, k) => ({ ...x, [k]: u[k] }),
                {}
              ),
              conditionOrderId: u.conditions?.order ? u.conditions.order.id : '',
              conditionOrderUrl: u.conditions?.order
                ? `${window.location.origin}/admin/orders/${u.conditions.order.id}`
                : '',
              conditionEntryPath: u.conditions?.entry ? u.conditions.entry.ref.path : '',
              conditionEntryUrl: u.conditions?.entry
                ? `${window.location.origin}/admin/${u.conditions.entry.ref.path}`
                : '',
              conditionChildren: u.conditions?.children ? u.conditions.children.map((_) => _.name).join(' ') : '',
              conditionAccountUrl: u.conditions?.children ? `${window.location.origin}/admin/users/${u.id}` : '',
              status: u.disabledAt ? '無効' : '',
            }))}
            hasPersonalInfo
            user={user}
          />
          <ExportButton
            label="全件エクスポート"
            fileName="all_users.csv"
            rows={async () => {
              const allusers = await getCollectionData(usersRef);
              return allusers.map((u) => ({
                ...['id', 'email', 'displayName', 'role', 'ngDm', 'note', 'subUserIds', 'mainUserId'].reduce(
                  (x, k) => ({ ...x, [k]: u[k] }),
                  {}
                ),
                conditionOrderId: u.conditions?.order ? u.conditions.order.id : '',
                conditionOrderUrl: u.conditions?.order
                  ? `${window.location.origin}/admin/orders/${u.conditions.order.id}`
                  : '',
                conditionEntryPath: u.conditions?.entry ? u.conditions.entry.ref.path : '',
                conditionEntryUrl: u.conditions?.entry
                  ? `${window.location.origin}/admin/${u.conditions.entry.ref.path}`
                  : '',
                conditionChildren: u.conditions?.children ? u.conditions.children.map((_) => _.name).join(' ') : '',
                conditionAccountUrl: u.conditions?.children ? `${window.location.origin}/admin/users/${u.id}` : '',
                status: u.disabledAt ? '無効' : '',
              }));
            }}
            hasPersonalInfo
            user={user}
          />
        </div>
        <div className="overflow-auto">
          {filteredRows.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 150 }}>判定条件</th>
                  <th style={{ minWidth: 150 }}>アカウント名</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 100 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 100 }}>権限</th>
                  <th style={{ minWidth: 100 }}>DM発送不可</th>
                  <th style={{ minWidth: 150 }}>ユーザータグ</th>
                  <th style={{ minWidth: 200 }}>メモ</th>
                  <th style={{ minWidth: 200 }}>備考</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(filteredRows || []).map((user) => {
                  return <ContactRow key={user.id} user={user} userTagsById={userTagsById} surveys={surveys} />;
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});

const ContactRow = (props) => {
  const { user, userTagsById, surveys } = props;
  const {
    id,
    ref,
    email,
    displayName,
    prefecture,
    phone,
    role = 'user',
    ngDm = false,
    note,
    city,
    userTagIds,
    mainUserId,
    subUserIds,
    disabledAt,
    conditions,
    adminNote,
    adminNoteUpdatedAt,
    adminNoteUpdatedBy,
    status,
  } = user;

  const validateOnDone = async () => {
    if (!window.confirm('本当に対応完了しますか？')) return false;
    return true;
  };
  const onDoneFinished = async (values) => {
    const { supportSurvey } = values;
    if (supportSurvey) {
      await ref.update({ status: 'sendSurvey' });
    } else {
      await ref.update({ status: 'completed' });
    }
  };

  return (
    <tr key={id}>
      <td>
        {conditions?.order && (
          <div>
            <Badge className="mr-1">注文条件</Badge>
            <TenantLink to={`/admin/orders/${conditions.order.id}`}>{conditions.order.id}</TenantLink>
          </div>
        )}
        {conditions?.entry && (
          <div>
            <Badge className="mr-1">イベント参加条件</Badge>
            <TenantLink to={`/admin/${conditions.entry.ref.path}`}>{conditions.entry.id}</TenantLink>
          </div>
        )}
        {conditions?.children && (
          <div>
            <Badge className="mr-1">年齢・乗り物経験</Badge>
            <TenantLink to={`/admin/users/${id}`}>{conditions.children[0].name}</TenantLink>
          </div>
        )}
      </td>
      <td>
        <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
        <div>
          {!isEmpty(mainUserId) && <div className="badge badge-warning">サブアカウント</div>}
          {!isEmpty(subUserIds) && <div className="badge badge-info">サブアカウントあり {subUserIds.length}件</div>}
        </div>
        {disabledAt && <Badge color="danger">無効</Badge>}
      </td>
      <td>{email}</td>
      <td>{prefecture && prefectures[prefecture]}</td>
      <td>{city}</td>
      <td>{phone}</td>
      <td>{roles[role]}</td>
      <td>{ngDm && '発送不可'}</td>
      <td>
        <div className="d-flex flex-wrap gap-1">
          {(userTagIds || []).map((_) => (
            <div key={_.id} className="badge badge-secondary">
              {userTagsById[_]?.name}
            </div>
          ))}
        </div>
      </td>
      <td style={{ whiteSpace: 'pre-line' }}>{note}</td>
      <td>
        <div>
          <EditButton
            label={false}
            color="link"
            size="sm"
            itemRef={ref}
            FormModal={ModelFormModal}
            formProps={{ title: '情報編集', fields: adminFields }}
            beforeSubmit={(values) => ({
              ...values,
              adminNoteUpdatedAt: new Date(),
              adminNoteUpdatedBy: auditData(user),
            })}
          />
          {adminNote}
          <br />
          {adminNoteUpdatedAt && adminNoteUpdatedBy && (
            <small className="text-muted">
              {adminNoteUpdatedAt.toDate().toLocaleString()} {adminNoteUpdatedBy.displayName}
            </small>
          )}
        </div>
      </td>
      <td>
        <EditButton
          label={
            {
              initial: '対応完了',
              sendSurvey: '送信中',
              completed: '対応済',
            }[status]
          }
          icon={null}
          color={
            {
              initial: 'primary',
              sendSurvey: 'secondary',
              completed: 'secondary',
            }[status]
          }
          itemRef={ref}
          FormModal={ModelFormModal}
          formProps={{
            title: 'サポート内容を登録する',
            fields: supportFields({ surveys }),
            submitLabel: '登録する',
          }}
          disabled={status !== 'initial'}
          validateOnSubmit={validateOnDone}
          onFinish={onDoneFinished}
        />
      </td>
    </tr>
  );
};

const ContactTable = (props) => {
  const { contact } = props;
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const productTypesById = keyBy(productTypes, 'id');
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  if (!contact) return <></>;
  const {
    id,
    status,
    completedAt,
    orderType = 'any',
    conditionProductTypeIds,
    conditionProductIds,
    entryType = 'any',
    conditionEventIds,
    dateType,
    createdAt,
  } = contact;
  const { label: statusLabel, color } = statuses[status] || {};
  return (
    <table className="table">
      <thead className="thead-light text-center text-nowrap">
        <tr>
          <th>配信日時</th>
          <th>ステータス</th>
          <th>注文条件</th>
          <th>イベント参加条件</th>
          <th>アカウント条件</th>
          <th>エリアグループ</th>
          <th>日付範囲</th>
          <th>作成日時</th>
        </tr>
      </thead>
      <tbody>
        <tr key={id}>
          <td style={{ whiteSpace: 'pre-line' }}>
            {completedAt ? formatDate(completedAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : '未配信'}
          </td>
          <td>
            <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
          </td>
          <td>
            {orderTypes[orderType]?.label}
            {orderType === 'ordered' && (
              <div>
                <div className="small text-muted">
                  {conditionProductTypeIds?.map((_) => productTypesById[_]?.name).join(',')}
                </div>
                <div className="small text-muted">
                  {conditionProductIds
                    ?.map((_) => productsById[_])
                    .map((_) => `[${_?.code}] ${_?.name}`)
                    .join(',')}
                </div>
              </div>
            )}
          </td>
          <td>
            {entryTypes[entryType]?.label}
            {entryType === 'entried' && (
              <div className="small text-muted">{conditionEventIds?.map((_) => eventsById[_]?.name).join(',')}</div>
            )}
          </td>
          <td className="text-muted small">{userConditionText(contact)}</td>
          <td>{contact.areaGroups?.join(',')}</td>
          <td>
            <span>{dateTypes[dateType]?.text({ data: contact })}</span>
          </td>
          <td style={{ whiteSpace: 'pre-line' }}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
        </tr>
      </tbody>
    </table>
  );
};
