import React from 'react';
import TenantUserPage from '../hocs/TenantUserPage';

function InquiryThanks(props) {
  return (
    <div className="inquiry-thanks container py-5 position-relative">
      <div className="row mb-5">
        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="bg-light-grey rounded-3 p-3">
            <h5 className="font-weight-normal text-center">お問合せを受付けました。</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

InquiryThanks.preview = true;

export default TenantUserPage(InquiryThanks);
