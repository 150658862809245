import { useState } from 'react';
import { Calendar, dateFnsLocalizer, Views, Event as CalendarEvent, stringOrDate } from 'react-big-calendar';
import { useWindowSize } from 'react-use';
import { format, parse, startOfWeek, getDay, startOfDay, endOfDay } from 'date-fns';
import { ja } from 'date-fns/locale';
import { sortBy, keyBy, } from 'lodash';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { Event } from '../../types/event';
import { Lecture } from '../../types/lecture';
import { Entry } from '../../types/entry';

const db = firebase.firestore();
const lecturesRef = db.collectionGroup('lectures');
const entriesRef = db.collectionGroup('entries');
const eventsRef = db.collection('events');

const locales = {
  ja: ja,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const formatOfYear = 'yyyy年';
const formatOfMonth = 'MM月';
const formatOfDay = 'dd日';
const formatOfDate = `${formatOfYear}${formatOfMonth}${formatOfDay}`;

export default AdminPage(function AdminEventCalendar(props) {
  const { tenant } = props;
  const [range, setRange] = useState({ startAt: startOfDay(new Date()), endAt: endOfDay(new Date()) });
  const lectures = useCollectionSubscriptionInTenant(lecturesRef.orderBy('date').startAt(range.startAt).endAt(range.endAt), [
    range,
  ]) as Lecture[];
  const entries = useCollectionSubscriptionInTenant(entriesRef) as Entry[];
  const events = useCollectionSubscriptionInTenant(eventsRef) as Event[];
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const calendarResources = events
    .filter(({ id }) => lectures.map((_) => _.ref?.parent?.parent?.id).includes(id))
    .map((_) => ({ id: _.id, title: _.name }));
  const calendarEvents = lectures.reduce((events: any[], lecture) => {
    const { frames, date, ref } = lecture;
    const resourceId = ref?.parent?.parent?.id;

    return [
      ...events,
      ...frames.map((frame, index) => {
        const frameEntries = entries
          .filter(({ cancelledAt }) => !cancelledAt)
          .filter((entry) =>
            entry.frames.some(({ lectureId, frameIndex }) => lectureId === lecture.id && frameIndex === index)
          );
        const isAborted = frameEntries.some(_ => _.abortedAt != null);
        return {
          resourceId,
          start: new Date(`${date?.toDate().toDateString()} ${frame.startAt.toDate().toTimeString()}`),
          end: new Date(`${date?.toDate().toDateString()} ${frame.endAt.toDate().toTimeString()}`),
          title: (
            <div>
              {frame.lectureType && <span className="d-none small mr-1">[{lectureTypesById[frame.lectureType]?.name}]</span>}
              {isAborted && <span className="badge badge-secondary mr-1">中止済み</span>}
              <span>{frameEntries.map(({ createdBy }) => createdBy?.displayName).join(', ')}</span>
            </div>
          ),
          frameEntries,
          isAborted,
          lectureId: lecture.id,
        };
      }),
    ];
  }, []);
  const { height } = useWindowSize();
  const handleSelectEvent = (event, e) => {
    const { resourceId, lectureId } = event;

    window.open(`/${tenant.id}/admin/events/${resourceId}/lectures/${lectureId}`);
  };
  // drilldownイベントの時にdrilldown前のrangeが指定されてくるので
  // drilldownでday表示にしたときにrangeが絞り込めずレクチャーがないイベントが表示される場合がある（react-big-calendarのバグっぽい）
  const handleRangeChange = (_range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
    if (Array.isArray(_range)) {
      const [start] = _range;
      const [end] = _range.slice(-1);
      setRange({ startAt: startOfDay(start as Date), endAt: endOfDay(end as Date) });
    } else {
      const { start, end } = _range;
      setRange({ startAt: startOfDay(start as Date), endAt: endOfDay(end as Date) });
    }
  };
  const resourceTitleAccessor = ({ title }) => {
    return (
      <div className='text-wrap' style={{ minHeight: '84px' }}>
        {title}
      </div>
    );
  };
  const eventStyleGetter = (event) => {
    const { isAborted, } = event;
    return {
      style: {
        opacity: isAborted && 0.3,
      },
    };
  };

  return (
    <div className='admin-events container py-5 position-relative'>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-center mb-3'>
          <h4>イベントカレンダー</h4>
        </div>
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          resources={calendarResources}
          defaultView={Views.DAY}
          style={{ height: height * 0.6 }}
          step={15}
          timeslots={1}
          culture='ja'
          formats={{
            monthHeaderFormat: `${formatOfYear}${formatOfMonth}`,
            dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
              localizer?.format(start, formatOfDate, culture || 'ja') +
              ' - ' +
              localizer?.format(end, formatOfDate, culture || 'ja'),
            dayHeaderFormat: `${formatOfDate}(E)`,
            agendaDateFormat: `${formatOfDate}(E)`,
          }}
          scrollToTime={new Date()}
          onSelectEvent={handleSelectEvent}
          onRangeChange={handleRangeChange}
          resourceTitleAccessor={resourceTitleAccessor}
          eventPropGetter={eventStyleGetter}
        />
      </div>
    </div>
  );
});
