const { omit, pick, inRange, isEmpty } = require('lodash');

const fields = ({ isEditing, } = {}) => ({
  name: {
    type: 'string',
    label: '名称',
    validations: {
      required: v => !isEmpty(v),
    },
  },
  text: {
    type: 'text',
    label: '本文',
    validations: {
      required: v => !isEmpty(v),
    },
    rows: 20,
  },
});

module.exports = {
  fields,
};
