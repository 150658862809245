import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { keyBy, omit, mapValues, } from 'lodash';
import { set, } from 'lodash/fp';
import { useAsync, useList, } from 'react-use';

import firebase from '../../firebase';
import { getCollectionData, } from '../../shared/firebase';
import { qrUrlFields, } from '../../shared/models/agent';
import useFormState from '../hooks/useFormState';
import useTenant from '../hooks/useTenant';
import Field from '../Field';
import ListForm from '../ListForm';
import RichTextContent from '../RichTextContent';

const { entries, } = Object;
const db = firebase.firestore();

export default function AgentQrUrlsFormModal(props) {
  const { isOpen, values, qrUrls, coupons, onClickClose, } = props;
  const couponsById = keyBy(coupons, 'id');
  const tenant = useTenant();
  const itemFields = set('couponIds.hint', ({ couponIds, }) => {
    return couponIds?.map((couponId) => {
      const coupon = couponsById[couponId];
      return (
        <div className="mb-1 card p-2 text-body" key={couponId}>
          <h6>メモ</h6>
          <div style={{ whiteSpace: 'pre-line' }}>{coupon.note}</div>
          <h6 className="mt-2">説明</h6>
          <RichTextContent html={coupon.description} />
        </div>
      );
    });
  }, qrUrlFields({ qrUrls, coupons }));
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.showingQrUrls || []);
  const isUnsubmittable = items.some(_ => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ showingQrUrls: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            代理店 紹介QRコード遷移先設定
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div>
            <ListForm
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <RowForm
                      fields={itemFields}
                      index={itemIndex}
                      values={item}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={0}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function RowForm (props) {
  const { fields, values, onChange, } = props;
  const statedFields = useFormState(values, fields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
    statedFields.startAll();
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex gap-1">
        <div style={{ minWidth: 300 }}>
          <Field
            name="qrUrlId"
            values={mapValues(statedFields, 'value')}
            {...statedFields.qrUrlId}
          />
        </div>
        <div style={{ minWidth: 300 }}>
          <Field
            name="couponIds"
            values={mapValues(statedFields, 'value')}
            {...statedFields.couponIds}
          />
        </div>
      </div>
      <div className="d-flex gap-1">
        <Field
          name="emailRequestScreenDescription"
          values={mapValues(statedFields, 'value')}
          {...statedFields.emailRequestScreenDescription}
        />
      </div>
    </div>
  );
}

