module.exports = {
  validations: {
    general: {
      required: '必須項目です',
      greaterThanOrEqualTo0: '0以上で指定してください。',
      greaterThan0: '1以上で指定してください。',
      samePassword: 'パスワードと一致しません',
      format: '形式が正しくありません',
      age: '対象年齢ではありません',
      notMultibyte: '半角で入力してください',
      hiragana: 'ひらがなで入力してください',
      katakana: 'カタカナで入力してください',
      emailFormat: 'メールアドレスの形式が正しくありません',
      lessThanOrEqualToInventory: '在庫数を超えています',
      lessThanOrEqualTo: '許容数を超えています',
      uniq: '他に存在します',
      existingPostalCode: '存在しない郵便番号です',
      laterThanNow: '未来の日時を指定してください。',
      enough: '不足しています',
      maxLength: '指定の文字数を超えています',
      maxRows: '指定の行数を超えています',
    },
    event: {
    },
    lecture: {
      frames: {
        fields: {
          priorCapacity: {
            lessThanOrEqualToCapacity: '定員を超えないようにしてください。',
          },
        },
      },
    },
    user: {
      phone: {
      },
      password: {
        minLength: '6文字以上で指定してください',
      },
    },
    order: {
      postalCode: {
        format: 'ハイフンなし数字のみの7文字で入力してください',
      },
    },
  },
  warnings: {
    general: {
      cellPhone: 'SMSサポートをお受けいただけますので携帯電話の登録をおすすめします',
    },
  },
  stripe: {
    errorMessage: {
      card_declined: 'そのカードは現在ご利用できませんでした',
      expired_card: '期限切れのカードです',
      incorrect_cvc: 'CVCコードが不正です',
    },
  },
};
