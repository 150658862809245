import React from 'react';

export default function OrderContract (props) {
  return (
    <div className="order-contract">
      特商法
    </div>
  );
};

