import React from 'react';
import { orderBy, groupBy, sortBy, get, keyBy, uniq } from 'lodash';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { canCreateContact } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useTenant from '../hooks/useTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { userConditionText } from '../../shared/util';
import { fields, orderTypes, entryTypes, dateTypes } from '../../shared/models/contact';
import TenantLink from '../TenantLink';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import { useSurveyCollection, tenantSurveysQuery } from '../../models/survey';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';

const db = firebase.firestore();

export default AdminPage(function AdminContacts(props) {
  const { user } = props;
  const tenant = useTenant();
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts
    .filter((_) => _.isBody || _.isOption)
    .map((_) => ({
      ..._,
      label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`,
    }));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const productTypesById = keyBy(productTypes, 'id');
  const lectures = useCollectionSubscriptionInTenant(db.collectionGroup('lectures').orderBy('date'));
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const sortedEvents = sortBy(events, (event) => {
    const date = get(lecturesGroupedByEventId, `${event.id}.0.date`);
    return date && date.toDate();
  });
  const { data: surveys = [] } = useSurveyCollection(tenantSurveysQuery(tenant?.id));
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map(_ => _.group));
  const contacts = orderBy(
    useCollectionSubscriptionInTenant(db.collection('contacts')),
    (_) => _.createdAt.toDate(),
    'desc'
  );

  return (
    <div>
      <div className="admin-sms-deliveries container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>コンタクトリスト一覧</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton
                  label="コンタクトリストを作成する"
                  processValues={(_) => ({ ..._, status: 'create' })}
                  itemRef={db.collection('contacts').doc()}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: 'コンタクトリスト作成',
                    fields: fields({ products: selectableProducts, productTypes, events: sortedEvents, userTags, surveys, areaGroups }),
                    hasSpin: true,
                  }}
                  disabled={!canCreateContact(user)}
                />
              </div>
              <hr className="my-5" />
              <h5>配信履歴</h5>
              <div>
                {contacts.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center text-nowrap">
                      <tr>
                        <th>作成日時</th>
                        <th>注文条件</th>
                        <th>イベント参加条件</th>
                        <th>アカウント条件</th>
                        <th>エリアグループ</th>
                        <th>日付範囲</th>
                        <th>対象件数</th>
                        <th style={{ minWidth: 300 }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map((contact) =>
                        <ContactRow
                          key={contact.id}
                          user={user}
                          productTypesById={productTypesById}
                          productsById={productsById}
                          eventsById={eventsById}
                          selectableProducts={selectableProducts}
                          productTypes={productTypes}
                          sortedEvents={sortedEvents}
                          userTags={userTags}
                          surveys={surveys}
                          areaGroups={areaGroups}
                          contact={contact}
                        />)}
                    </tbody>
                  </table>
                ) : (
                  <div>No Data</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const ContactRow = (props) => {
  const { user, productTypesById, productsById, eventsById, selectableProducts, productTypes, sortedEvents, userTags, surveys, areaGroups, contact } = props;
  const {
    id,
    ref,
    name,
    orderType = 'any',
    conditionProductTypeIds,
    conditionProductIds,
    entryType = 'any',
    conditionEventIds,
    dateType,
    status,
    createdAt,
    completedAt,
  } = contact;
  const contactCandidates = useCollectionSubscriptionInTenant(db.collection(`contacts/${id}/contactCandidates`));

  return (
    <tr key={id}>
      <td style={{ whiteSpace: 'pre-line' }}>
        <TenantLink to={`/admin/contacts/${id}`}>
          {completedAt ? formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : '作成中'}
        </TenantLink>
      </td>
      <td>
        {orderTypes[orderType]?.label}
        {orderType === 'ordered' && (
          <div>
            <div className="small text-muted">
              {conditionProductTypeIds?.map((_) => productTypesById[_]?.name).join(',')}
            </div>
            <div className="small text-muted">
              {conditionProductIds
                ?.map((_) => productsById[_])
                .map((_) => `[${_?.code}] ${_?.name}`)
                .join(',')}
            </div>
          </div>
        )}
      </td>
      <td>
        {entryTypes[entryType]?.label}
        {entryType === 'entried' && (
          <div className="small text-muted">
            {conditionEventIds?.map((_) => eventsById[_]?.name).join(',')}
          </div>
        )}
      </td>
      <td className="text-muted small">{userConditionText(contact)}</td>
      <td>{contact.areaGroups?.join(',')}</td>
      <td>
        <span>{dateTypes[dateType]?.text({ data: contact })}</span>
      </td>
      <td style={{ whiteSpace: 'pre-line' }} className="text-right">
        {numeral(contactCandidates.reduce((x, y) => x + y.deliveryCount, 0)).format('0,0')}
      </td>
      <td className="text-nowrap text-right">
        <EditButton
          itemRef={ref}
          className="ml-2"
          FormModal={ModelFormModal}
          formProps={{
            title: 'コンタクトリスト',
            fields: fields({
              products: selectableProducts,
              productTypes,
              events: sortedEvents,
              userTags,
              surveys,
              areaGroups,
            }),
            hasSpin: true,
          }}
          disabled={!canCreateContact(user)}
        />
        <div>
          <TenantLink to={`/admin/contacts/${id}/contactTargets`} target="_blank">
            コンタクトリスト
          </TenantLink>
        </div>
      </td>
    </tr>
  );
};
