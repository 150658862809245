import React, { useEffect, Fragment, } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { keyBy, omit, mapValues, } from 'lodash';
import { set, } from 'lodash/fp';
import { useAsync, useList, } from 'react-use';

import firebase from '../../firebase';
import { getCollectionData, } from '../../shared/firebase';
import { fields, itemFields, } from '../../shared/models/receiving';
import useFormState from '../hooks/useFormState';
import useTenant from '../hooks/useTenant';
import Field from '../Field';
import ListForm from '../ListForm';
import RichTextContent from '../RichTextContent';

const { entries, } = Object;
const db = firebase.firestore();

export default function ReceivingFormModal(props) {
  const { isOpen, values, products, onClickClose, } = props;
  const productsById = keyBy(products, 'id');
  const tenant = useTenant();
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.items || [{}]);
  const statedFields = useFormState(values, fields(), isOpen);
  const isUnsubmittable = items.some(_ => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ ...mapValues(statedFields, 'value'), items: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            入荷
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div>
            {
              entries(statedFields).map(([fieldName, fieldSetting]) => {
                return (
                  <Fragment>
                    <Field
                      key={fieldName}
                      name={fieldName}
                      documentName="receiving"
                      {...fieldSetting}
                    />
                  </Fragment>
                );
              })
            }
          </div>
          <div>
            <ListForm
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ItemForm
                      fields={itemFields({ products, })}
                      index={itemIndex}
                      values={item}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={0}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

function ItemForm (props) {
  const { fields, values, onChange, } = props;
  const statedFields = useFormState(values, fields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
    statedFields.startAll();
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex gap-1">
        <div style={{ minWidth: 300 }}>
          <Field
            name="productId"
            values={mapValues(statedFields, 'value')}
            {...statedFields.productId}
          />
        </div>
        <div style={{ minWidth: 300 }}>
          <Field
            name="quantity"
            values={mapValues(statedFields, 'value')}
            {...statedFields.quantity}
          />
        </div>
      </div>
    </div>
  );
}

