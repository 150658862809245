import React, { useEffect } from 'react';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { sortBy, keyBy, omitBy, pick, isUndefined, flatten, uniq } from 'lodash';
import { format as formatDate, differenceInYears } from 'date-fns';

import { canUpdateEntry } from '../../shared/abilities';
import { ageDisplay } from '../../shared/util';
import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import LectureTitle from '../LectureTitle';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import TenantLink from '../TenantLink';
import EntryFrameNoteModal from '../modals/EntryFrameNoteModal';
import EditButton from '../EditButton';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const questionsRef = db.collection('questions');
const surveysRef = db.collection('surveys');

export default AdminPage(function AdminEntry(props) {
  const {
    user,
    match: {
      params: { eventId, entryId },
    },
    addBreadNavValues,
  } = props;
  const { firebaseUser } = useFirebaseUser();
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const entry = useDocumentSubscription(eventRef.collection('entries').doc(entryId), [eventId, entryId]);
  const lectures = useCollectionSubscription(eventsRef.doc(eventId).collection('lectures'), [eventId]);
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const lecturesById = keyBy(lectures, 'id');
  const questions = useCollectionSubscriptionInTenant(questionsRef, [eventId]);
  const questionsById = keyBy(questions, 'id');
  const surveys = useCollectionSubscription(surveysRef.where('surveyGroupId', '==', 'eventEarlierQuestion'), []);
  // NOTE: lectureにあるアンケート（lecture内の順番通り） -> lecutreにないアンケートの順に表示する
  const entryQuestionIds = entry
    ? uniq([
        ...flatten(entry.frames.map(({ lectureId }) => lecturesById[lectureId]?.questions || [])),
      ...flatten(entry.frames.map(({ lectureId }) => surveys.find(_ => _.id === lecturesById[lectureId]?.surveyId)?.questionRows?.map(_ => _.questionId) || [])),
      ])
    : [];
  const entryQuestions = entryQuestionIds.map((_) => {
    const question = questionsById[_];
    const answer = entry.answers && entry.answers[_];

    return { question, answer };
  });
  useEffect(() => {
    addBreadNavValues({ event, entry });
  }, [event, entry]);
  const onClickCancel = async () => {
    if (!window.confirm('本当にキャンセルしますか？')) return;
    try {
      await eventRef
        .collection('entries')
        .doc(entryId)
        .update({
          updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
          cancelledAt: new Date(),
        });
      toast.success('キャンセルしました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div>
      <div className="admin-event container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>申込み詳細</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <Button
            color="danger"
            onClick={onClickCancel}
            disabled={entry?.abortedAt != null || entry?.cancelledAt != null || !canUpdateEntry(user, event)}
          >
            キャンセルする
          </Button>
        </div>
        {entry && (
          <div className="card p-3">
            {entry.cancelledAt != null && <div className="alert alert-warning">この申込みはキャンセル済みです</div>}
            {entry.abortedAt != null && <div className="alert alert-warning">この申込みは中止済みです</div>}
            <div className="mb-3">
              <div className="text-muted small">アカウント名</div>
              <TenantLink to={`/admin/users/${entry.createdBy.uid}`} target="_blank">
                {entry.createdBy.displayName}
              </TenantLink>
            </div>
            <div className="mb-3">
              <div className="text-muted small">メールアドレス</div>
              {entry.createdBy.email}
            </div>
            <div className="mb-3">
              <div className="text-muted small">申込日時</div>
              {formatDate(entry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
            </div>
            <div className="mb-3">
              <div className="text-muted small">決済金額(税込)</div>
              {numeral(entry.charge && entry.charge.amount).format('0,0')} 円
            </div>
            <div className="mb-3">
              <div className="text-muted small">市区町村</div>
              {entry.createdBy.city}
            </div>
            <div className="mb-3">
              <h5>参加枠</h5>
              {entry.frames.map(
                ({ lectureId, frameIndex, name, birthday, laterQuestionInstructionMailSentAt, laterAnswers }, i) => {
                  const lecture = lecturesById[lectureId];
                  if (!lecture) return null;
                  const frame = lecture.frames[frameIndex];
                  return (
                    <div key={i} className="card p-3 mb-2">
                      <div>
                        <LectureTitle {...lecture} {...{ eventProductTypesById, lectureTypesById, }} />
                      </div>
                      <div>
                        {formatDate(frame.startAt.toDate(), 'HH:mm')} 〜 {formatDate(frame.endAt.toDate(), 'HH:mm')}
                      </div>
                      <div>
                        <span>{name}</span>
                        {birthday && (
                          <>
                            <span className="ml-2">{formatDate(birthday.toDate(), 'yyyy/MM/dd')}</span>
                            <span className="ml-2">({ageDisplay(birthday.toDate(), lecture.date.toDate())})</span>
                          </>
                        )}
                      </div>
                      {laterAnswers != null && (
                        <div className="mt-4">
                          <div className="h6">[事後アンケート回答]</div>
                          {Object.entries(laterAnswers || {}).map(([laterQuestionId, _value]) => {
                            const laterQuestion = questionsById[laterQuestionId];
                            if (!laterQuestion) return null;
                            const { type, name } = laterQuestion;
                            const value = {
                              text: _value,
                              radio: _value,
                              select: _value,
                              checkbox: Object.entries(_value || {})
                                .filter((_) => _[1])
                                .map((_) => _[0])
                                .join(', '),
                            }[type];
                            return (
                              <div key={laterQuestionId} className="mb-3">
                                <div className="text-muted small">{name}</div>
                                {value}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
            <div className='mb-3'>
              <h5>備考</h5>
              {entry.frames.map(
                ({ note, noteUpdatedAt, noteUpdatedBy, lectureId }, i) => {
                  const lecture = lecturesById[lectureId];
                  return (
                    <div key={i} className='card p-3 mb-2'>
                      <div className="d-flex justify-content-end">
                        <EditButton itemRef={entry.ref} FormModal={EntryFrameNoteModal} label='' icon={<span className='fas fa-edit' />} formProps={{ user, entry, lectureId: lecture?.id }} />
                      </div>
                      <div>
                        {note}
                      </div>
                      {
                        noteUpdatedAt && noteUpdatedBy && <div className='text-muted small'>最終更新: {noteUpdatedAt.toDate().toLocaleString()} {noteUpdatedBy.displayName}</div>
                      }
                    </div>
                  );
                }
              )}
            </div>
            <div className='mt-4'>
              <h5>申込時アンケート</h5>
              {entryQuestions.map(({ question, answer }) => {
                if (!question || !answer) return null;

                const { id, type, name } = question;
                const value = {
                  text: answer,
                  radio: answer,
                  select: answer,
                  checkbox: Object.entries(answer || {})
                    .filter((_) => _[1])
                    .map((_) => _[0])
                    .join(', '),
                }[type];

                return (
                  <div key={id} className="mb-3">
                    <div className="text-muted small">{name}</div>
                    {value}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
