const { isEmpty, } = require('lodash');

module.exports = {
  fields: ({ parents = [] } = {}) => {
    return {
      parentId: {
        label: '親種別',
        type: 'select',
        options: parents.map((parent) => ({ value: parent.id, label: parent.subject, })),
        validations: {
          required: v => !isEmpty(v),
        },
        inputProps: {
          isSearchable: false,
        },
      },
      subject: {
        label: 'タイトル',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      body: {
        label: '内容',
        type: 'richText',
        rows: 10,
      },
      copyableContent: {
        label: '内容（コピー可能）',
        type: 'richText',
        rows: 10,
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
    };
  },
};
