import { orderBy, keyBy, uniqBy, isUndefined } from 'lodash';
import { format as formatDate } from 'date-fns';

import AdminPage from '../hocs/AdminPage';
import firebase from '../../firebase';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useQueryParams from '../hooks/useQueryParams';
import { Event } from '../../types/event';
import { Lecture } from '../../types/lecture';
import { Entry } from '../../types/entry';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const entriesRef = db.collectionGroup('entries');

export default AdminPage(function AdminEntries() {
  const params = useQueryParams();
  const { field: [field] = ['createdBy.email'], text } = params as { field?: string[]; text?: string };
  const fieldOptions = [
    { label: 'メールアドレス', value: 'createdBy.email' },
    { label: 'アカウント名', value: 'createdBy.displayName' },
    { label: 'アカウントID', value: 'createdBy.uid' },
  ];
  const entriesQuery = isUndefined(text) ? null : entriesRef.orderBy(field).startAt(text).endAt(`${text}\uf8ff`);
  const entries = useCollectionSubscriptionInTenant(entriesQuery, [params]) as Entry[];
  const events = useDocumentsFetch(
    entries.map((_) => _.ref!.parent.parent),
    [entries]
  ) as Event[];
  const eventsById = keyBy(events, 'id');
  const fetchedLectures = useCollectionsFetchInTenant(
    events.map((_) => _.ref!.collection('lectures')),
    [events]
  ) as Lecture[];
  const lectures = uniqBy(
    (fetchedLectures || []).map((_) => ({ eventId: _.ref!.parent!.parent!.id, ..._ })),
    'id'
  );
  const lecturesById = keyBy(lectures, 'id');
  const rows = orderBy(
    entries.map((entry) => {
      const {
        frames: [frame],
      } = entry;
      const { lectureId, name: participantName } = frame;
      const lecture = lecturesById[lectureId];
      const { eventId } = lecture || {};
      const event = lecture && eventsById[eventId];
      const { name: eventName } = event || {};
      const lectureStartAt =
        lecture &&
        new Date(`${lecture.date!.toDate().toDateString()} ${frame.lectureFrame.startAt.toDate().toTimeString()}`);

      return {
        ...entry,
        participantName,
        lectureId,
        eventId,
        eventName,
        lectureStartAt,
      };
    }),
    ({ createdAt }) => createdAt.toDate(),
    'desc'
  );

  return (
    <div className='admin-entries container py-5 position-relative'>
      <div className='d-flex flex-column gap-3'>
        <div className='d-flex justify-content-center'>
          <h4>イベント申込み一覧</h4>
        </div>
        <div className='d-flex flex-wrap gap-2'>
          <QuerySelector
            paramName='field'
            options={fieldOptions}
            label='検索フィールド'
            defaultValue={[field]}
            isClearable={false}
          />
          <QueryText paramName='text' label='検索テキスト' />
        </div>
        <div className='overflow-auto'>
          {rows.length > 0 ? (
            <table className='table'>
              <thead className='thead-light text-nowrap'>
                <tr>
                  <th></th>
                  <th style={{ minWidth: 200 }}>イベント</th>
                  <th style={{ minWidth: 160 }}>参加枠</th>
                  <th style={{ minWidth: 150 }}>参加者名</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 150 }}>アカウント名</th>
                </tr>
              </thead>
              <tbody>
                {rows?.map(
                  ({
                    id,
                    createdBy,
                    refund,
                    abortedAt,
                    cancelledAt,
                    participantName,
                    lectureId,
                    eventId,
                    eventName,
                    lectureStartAt,
                  }) => (
                    <tr key={id}>
                      <td>
                        <div className='d-flex flex-column gap-1'>
                          {abortedAt && <div className='badge badge-secondary'>中止済み</div>}
                          {cancelledAt && <div className='badge badge-secondary'>キャンセル</div>}
                          {refund != null && <div className='badge badge-success'>返金済み</div>}
                        </div>
                      </td>
                      <td>{eventName}</td>
                      <td>
                        {lectureStartAt && (
                          <TenantLink to={`/admin/events/${eventId}/lectures/${lectureId}`}>
                            {formatDate(lectureStartAt, 'yyyy/MM/dd hh:mm')}
                          </TenantLink>
                        )}
                      </td>
                      <td>{participantName}</td>
                      <td>{createdBy?.email}</td>
                      <td>{createdBy && <TenantLink to={`/admin/users/${createdBy.uid}`}>{createdBy.displayName}</TenantLink>}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});
