import React, { useState, Fragment, useEffect, } from 'react';
import { toast } from 'react-toastify';
import { format as formatDate, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, } from 'date-fns';
import { Button, Input } from 'reactstrap';
import { orderBy, mapValues, sortBy, omitBy, isUndefined, pick, groupBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import numeral from 'numeral';
import qs from 'qs';
import copy from 'copy-to-clipboard';
import Select from 'react-select';
import ellipsis from 'text-ellipsis';
import { useList, } from 'react-use';

import { fullPathWithParams, } from '../../util';
import firebase from '../../firebase';
import { canUpdateAgent, canUpdateAgentContractPlan } from '../../shared/abilities';
import { conclusiveReferralFee } from '../../shared/models/order';
import { fields as agentTempleteFields } from '../../shared/models/agentTemplate';
import AdminPage from '../hocs/AdminPage';
import AgentRegisterForm from '../forms/AgentRegisterForm';
import AgentRegisterConfirmForm from '../forms/AgentRegisterConfirmForm';
import useFormState from '../hooks/useFormState';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import ProgressButton from '../ProgressButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import ModalButton from '../ModalButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import { registerFields } from '../../shared/models/agent';
import { fields as agentContractPlanFields } from '../../shared/models/agentContractPlan';
import TenantLink from '../TenantLink';

const db = firebase.firestore();

export default AdminPage(function AdminAgentRegister (props) {
  const { tenant, user, history, location, } = props;
  const [currentScreen, setScreen] = useState('form');
  const [productRows, { set: setProductRows, updateAt: updateProductRowAt }] = useList([]);
  const [showingQrUrlRows, { set: setShowingQrUrlRows, updateAt: updateShowingQrUrlRowAt }] = useList([]);
  const [agentRegistrationId, setAgentRegistrationId] = useState(null);
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productsById = keyBy(products, 'id');
  const nonPartProducts = orderBy(products.filter(_ => !_.isPart && _.createdAt != null), 'isHidden');
  const qrUrls = sortBy(useCollectionSubscriptionInTenant(db.collection('qrUrls')), _ => _.createdAt.toDate());
  const coupons = useCollectionSubscriptionInTenant(db.collection('coupons').orderBy('createdAt'));
  const agentContractPlans = sortBy(useCollectionSubscriptionInTenant(db.collection('agentContractPlans'), []), _ => _.createdAt.toDate());
  const statedFields = useFormState(null, registerFields({ products, agentContractPlans, }));
  const onSubmit = async () => {
    if(!window.confirm('この内容で確定し、サインアップフォームURLを表示します。よろしいですか？')) return;

    const ref = db.collection('agentRegistrations').doc();
    await ref.set({
      ...mapValues(statedFields, 'value'),
      productRows: productRows.map(_ => omit(_, ['isValid'])),
      showingQrUrlRows: showingQrUrlRows.map(_ => omit(_, ['isValid'])),
      tenantId: tenant.id,
      createdAt: new Date(),
      addedBy:  omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
    });
    setAgentRegistrationId(ref.id);
    setScreen('signUpUrl');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentScreen]);

  return (
    <div>
      <div className="admin-agents container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>代理店登録ウィザード</h4>
        </div>
        {
          currentScreen === 'form' && (
            <div className="mt-5 d-flex justify-content-end align-items-end gap-1">
              <ModalButton title="代理店テンプレート選択" content={_ => <AgentTemplates {...{ ..._, statedFields, qrUrls, coupons, setProductRows, setShowingQrUrlRows, productsById, }} />} modalProps={{ style: { minWidth: 1300 } }}>
                代理店テンプレート選択
              </ModalButton>
            </div>
          )
        }
        <div>
          {
            ({
              form: () => <AgentRegisterForm onSubmit={_ => setScreen('confirm')} {...{ statedFields, productRows, setProductRows, updateProductRowAt, showingQrUrlRows, setShowingQrUrlRows, updateShowingQrUrlRowAt, nonPartProducts, qrUrls, coupons, }} />,
              confirm: () => <AgentRegisterConfirmForm onSubmit={onSubmit} onCancel={_ => setScreen('form')} {...{ statedFields, productRows, setProductRows, updateProductRowAt, showingQrUrlRows, setShowingQrUrlRows, updateShowingQrUrlRowAt, nonPartProducts, qrUrls, coupons, }} />,
              signUpUrl: () => <SignUpUrlContent {...{ tenant, agentRegistrationId, statedFields, productRows, showingQrUrlRows, }} />,
            })[currentScreen]()
          }
        </div>
      </div>
    </div>
  );
});

function SignUpUrlContent (props) {
  const { productRows, showingQrUrlRows, statedFields, tenant, agentRegistrationId, } = props;
  const url = `${window.location.origin}/${tenant.id}/agents/new?agentRegistrationId=${agentRegistrationId}`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };
  const beforeAdd = (values) => {
    return {
      ...values,
      productRows,
      showingQrUrlRows,
      ...mapValues(statedFields, 'value'),
    };
  };

  return (
    <div>
      <div className="card mb-1">
        <div className="card-body">
          <div>
            <p>設定された内容でのサインアップフォームが作成されました。</p>
            <p>以下のURLを共有してください。</p>
          </div>
          <div className="d-flex gap-1 align-items-end mt-4">
            <Input style={{ width: 300 }} className="flex-grow-1 mr-1" readOnly defaultValue={url} />
            <Button color="primary" onClick={onClickCopy}>
              <span className="fas fa-copy" />
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <AddInTenantButton
          label="代理店テンプレートとして保存する"
          processValues={beforeAdd}
          itemRef={_ => db.collection('agentTemplates').doc()}
          FormModal={ModelFormModal}
          formProps={{ title: '代理店テンプレート作成', fields: agentTempleteFields, }}
        />
      </div>
    </div>
  );
}

function AgentTemplates (props) {
  const { productsById, toggleModal, qrUrls, coupons, statedFields, setProductRows, setShowingQrUrlRows, } = props;
  const qrUrlsById = keyBy(qrUrls, 'id');
  const couponsById = keyBy(coupons, 'id');
  const agentTemplates = sortBy(useCollectionSubscriptionInTenant(db.collection('agentTemplates'), []), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th style={{ minWidth: 250 }}>商品</th>
            <th style={{ minWidth: 250 }}>紹介QRコード遷移先</th>
            <th style={{ minWidth: 250 }}>条件</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            agentTemplates.map((agentTemplate) => {
              const { id, ref, name, conditionNote, productRows, showingQrUrlRows, qrUrlIds = [], } = agentTemplate;
              const onClickApply = async () => {
                if(!window.confirm('この代理店テンプレートを適用します。よろしいですか？')) return;

                setProductRows(agentTemplate.productRows);
                setShowingQrUrlRows(agentTemplate.showingQrUrlRows || []);
                statedFields.setValues(pick(agentTemplate, Object.keys(registerFields())));
                statedFields.productsPageHeaderHtml.setRteValue(agentTemplate.productsPageHeaderHtml);
                toast.success('適用しました');
                toggleModal(false);
              };

              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td>
                    {
                      productRows.map((productRow, i) => {
                        const { productId, } = productRow;
                        const product = productsById[productId];
                        const usesProductMaster = ['referralFeeAmount', 'referralFeeRate'].every(_ => productRow[_] == null);
                        return (
                          <div key={i} className="d-flex gap-1 align-items-center">
                            <div>
                              {product?.name}
                            </div>
                            <div>
                              ({
                                usesProductMaster ? (
                                  product.referralFeeRate != null ? (numeral(product.referralFeeRate).format() + ' %') : ''
                                ) : {
                                  amount: productRow.referralFeeAmount != null ? (numeral(productRow.referralFeeAmount).format() + ' 円') : '',
                                  rate: productRow.referralFeeRate != null ? (numeral(productRow.referralFeeRate).format() + ' %') : '',
                                }[productRow.referralFeeType]
                              })
                            </div>
                          </div>
                        );
                      })
                    }
                  </td>
                  <td>
                    {
                      (showingQrUrlRows || []).map((showingQrUrlRow, i) => {
                        const { qrUrlId, couponIds, } = showingQrUrlRow;
                        const qrUrl = qrUrlsById[qrUrlId];
                        return (
                          <div key={i} className="d-flex flex-column gap-1 border rounded p-2">
                            <div>
                              {qrUrl?.name}
                            </div>
                            <div>
                              {
                                couponIds?.map((couponId) => {
                                  return (
                                    <div key={couponId} className="card p-1 mb-1">
                                      {couponsById[couponId]?.name}
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        );
                      })
                    }
                  </td>
                  <td title={conditionNote}>
                    {ellipsis(conditionNote || '', 150)}
                  </td>
                  <td className="text-nowrap text-right">
                    <ProgressButton size="sm" process={onClickApply}>
                      適用する
                    </ProgressButton>
                    <EditButton size="sm" label={false} itemRef={ref} className="ml-1" FormModal={ModelFormModal} formProps={{ documentName: 'agentTemplate', title: '代理店テンプレート編集', fields: agentTempleteFields({ isEditing: true }), }} />
                    <DeleteButton size="sm" label={false} itemRef={ref} className="ml-1" />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
