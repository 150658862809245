const { isEmpty } = require('lodash');

const { entries } = Object;
const columnWidths = {
  full: { label: '全画面', container: 'container-fluid', },
  width12: { label: '広め', col: 'col-12', },
  width8: { label: '標準', col: 'col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8', },
  width6: { label: '狭め', col: 'col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6', },
};

const fields = ({ contentGroups = [] }) => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: { required: (v) => !isEmpty(v) },
    },
    contentGroupId: {
      label: 'コンテンツグループ',
      type: 'select',
      options: contentGroups.map(({ id, name }) => ({ value: id, label: name })),
      initialValue: '',
    },
    title: {
      label: 'タイトル',
      type: 'string',
      validations: { required: (v) => !isEmpty(v) },
    },
    columnWidth: {
      label: '横幅',
      type: 'select',
      options: entries(columnWidths).map(([k, v]) => ({ label: v.label, value: k, })),
      validations: { required: (v) => !isEmpty(v) },
    },
    description: {
      label: '説明文(HTML)',
      type: 'richText',
      rows: 10,
      initialValue: '',
    },
    uploadFile: {
      label: 'コンテンツ',
      type: 'file',
      inputProps: {
        accept: 'image/*,video/mp4,audio/*,application/pdf',
      },
      hint: 'MOV形式はアップロード不可',
    },
    isPublic: {
      label: '公開する',
      type: 'boolean',
      initialValue: false,
      hint: 'ONの場合、未ログインでも参照できます。'
    },
    isNoindex: {
      label: '検索エンジンのインデックスに登録しない',
      type: 'boolean',
      initialValue: false,
    },
    isHidden: {
      label: '非表示',
      type: 'boolean',
      initialValue: false,
    },
    note: {
      label: 'メモ',
      type: 'text',
      rows: 5,
    },
  };
};

module.exports = {
  fields,
  columnWidths,
};
