import React from 'react';
import { format as formatDate } from 'date-fns';
import { keyBy } from 'lodash';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import numeral from 'numeral';

import firebase from '../../firebase';
import { i18nField } from '../../shared/i18n';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import LectureTypeDisplay from '../LectureTypeDisplay';
import LectureLevelDisplay from '../LectureLevelDisplay';
import LectureAgeDisplay from '../LectureAgeDisplay';
import TenantLink from '../TenantLink';

const db = firebase.firestore();

export default function EntryDetailModal(props) {
  const { translate = _ => _, event, isOpen, tenant, entry, lectures, onClickClose, } = props;
  const lecturesById = keyBy(lectures, 'id');
  const { cancelledAt, abortedAt, createdAt, frames = [], checkInCode } = entry;
  const frameLectureTypes = useDocumentsFetch(frames.map(_ => _.lectureFrame.lectureType).filter(_ => _).map((_) => db.collection('lectureTypes').doc(_)), [entry]);
  const lectureTypesById = keyBy(frameLectureTypes, 'id');
  const isCancelled = !!cancelledAt;
  const isOverCancelLimitTime = frames.some((frame) => {
    const { lectureId } = frame;
    const lecture = lecturesById[lectureId];
    if (lecture == null) return true;
    const startDateString = lecture.date.toDate().toDateString();
    const startTimeString = frame.lectureFrame.startAt.toDate().toTimeString();
    return new Date(`${startDateString} ${startTimeString}`) < new Date();
  });
  const onClickCancel = async () => {
    if (!window.confirm(translate('本当にキャンセルしますか？'))) return;
    try {
      await entry.ref.update({ cancelledAt: new Date() });
      toast.success(translate('キャンセルしました'));
    } catch (e) {
      console.error(e);
      toast.error(translate('失敗しました'));
    }
  };

  return translate(
    <Modal isOpen={isOpen}>
      <ModalHeader>イベント申込み詳細</ModalHeader>
      <ModalBody>
        {isCancelled && <div className="alert alert-warning">この申込みはキャンセル済みです</div>}
        <div className="mt-3">
          {frames.map((frame, index) => {
            const { name, birthday, frameIndex, lectureId } = frame;
            const lecture = lecturesById[lectureId];
            if (!lecture) return null;
            const { startAt, endAt, lectureType } = lecture.frames[frameIndex];

            return (
              <div key={index} className="mt-4">
                {tenant != null && (
                  <div className="mb-1 text-muted" style={{ fontSize: 10 }}>
                    {tenant[i18nField(event?.lang, 'name')]}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <LectureTypeDisplay event={event} lectureType={lecture.lectureType} />
                  <div className="ml-3 text-grey">{numeral(lecture.price).format('0,0')} 円(税込)</div>
                </div>
                <div className="mt-3 d-flex">
                  <LectureLevelDisplay event={event} className="flex-fill" {...lecture} />
                  <LectureAgeDisplay event={event} className="ml-3 flex-fill" {...lecture} />
                </div>
                <div className="mt-2">
                  {formatDate(lecture.date.toDate(), 'yyyy/MM/dd')} {formatDate(startAt.toDate(), 'HH:mm')}{' '}
                  {lectureTypesById[lectureType]?.[i18nField(event?.lang || 'ja', 'name')]}
                </div>
                <div className="mt-2">
                  <table className="table table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td>参加者名</td>
                        <td className="font-weight-bold">{name}</td>
                      </tr>
                      <tr>
                        <td>生年月日</td>
                        <td className="font-weight-bold">{formatDate(birthday.toDate(), 'yyyy / MM / dd')}</td>
                      </tr>
                      {checkInCode && (
                        <tr>
                          <td>チェックインコード</td>
                          <td className="font-weight-bold">{checkInCode}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-5 d-flex justify-content-end text-grey">
          申込日時: {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
        </div>
        {!isOverCancelLimitTime && !isCancelled && abortedAt == null && (
          <div className="mt-3 card d-block border-secondary text-secondary p-3">
            変更希望の場合はキャンセルの上、
            <TenantLink to={`/events/${entry.ref.parent.parent.id}/entries/new`}>こちら</TenantLink>
            から再予約してください。
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={onClickCancel}
          disabled={isCancelled || abortedAt != null || isOverCancelLimitTime}
        >
          キャンセルする
        </Button>
        <Button color="secondary" onClick={onClickClose}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  );
}
