import React from 'react';
import { sortBy } from 'lodash';
import ellipsis from 'text-ellipsis';

import firebase from '../../firebase';
import { canUpdateInitialTags } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/initialTag';

const db = firebase.firestore();

export default AdminPage(function AdminInitialTags(props) {
  const { user } = props;
  const initialTags = sortBy(useCollectionSubscriptionInTenant(db.collection('initialTags')), (_) =>
    _.createdAt.toDate()
  );

  return (
    <div>
      <div className="admin-question-sets container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>初期化タグ一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton
            itemRef={db.collection('initialTags').doc()}
            FormModal={ModelFormModal}
            disabled={!canUpdateInitialTags(user)}
            formProps={{ documentName: 'initialTag', title: '初期化タグ追加', fields: fields() }}
          />
        </div>
        <div>
          {initialTags.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th>タイトル</th>
                  <th>タグ内容</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {initialTags.map((initialTag) => {
                  const { id, ref, subject, body } = initialTag;
                  return (
                    <tr key={id}>
                      <td>{subject}</td>
                      <td style={{ whiteSpace: 'pre-line' }}>{ellipsis(body, 300)}</td>
                      <td className="text-nowrap text-right">
                        <EditButton
                          itemRef={ref}
                          className="ml-2"
                          FormModal={ModelFormModal}
                          disabled={!canUpdateInitialTags(user)}
                          formProps={{ documentName: 'initialTag', title: '初期化タグ編集', fields: fields() }}
                        />
                        <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateInitialTags(user)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>初期化タグは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});
