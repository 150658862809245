const { isEmpty } = require('lodash');
const { questionTypes } = require('../config');

const { entries } = Object;

module.exports = {
  fields: ({ surveyGroups = [] }) => {
    return {
      surveyGroupId: {
        label: 'アンケートグループ',
        type: 'select',
        options: surveyGroups.map(({ id, name }) => ({ value: id, label: name })),
        validations: { required: (v) => !isEmpty(v) },
        initialValue: '',
      },
      name: {
        label: '名称',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
      description: {
        label: '質問文',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
      hint: {
        label: 'ヒント(HTML)',
        type: 'richText',
        rows: 10,
      },
      type: {
        label: '入力タイプ',
        type: 'select',
        options: entries(questionTypes).map(([k, v]) => ({ value: k, label: v })),
        validations: { required: (v) => !isEmpty(v) },
        initialValue: 'text',
      },
      optionsString: {
        label: '選択肢',
        type: 'text',
        validations: { required: (v) => !isEmpty(v) },
        hidden: (_) => !['radio', 'select', 'checkbox'].includes(_.type),
        placeholder: 'カンマ区切り',
      },
      isOptional: {
        label: '任意',
        type: 'boolean',
        initialValue: false,
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
    };
  },
};
