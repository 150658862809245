const { isEmpty, } = require('lodash');
const dedent = require('dedent');

const reasons = [
  { label: '色やサイズを変更したい', },
  { label: '送り先や数量を間違えた', },
  { label: '重複注文', },
  { label: '間違えて注文した', },
  { label: '店舗で購入', },
  { label: '都合により必要がなくなった', },
  { label: '配送日時変更したい', instruction: '発送完了メールをご確認のうえ、ヤマトにご連絡ください。', endsForm: true, },
  {
    label: '追加注文したい',
    instruction: dedent`キャンセルには、数日かかります。
      お急ぎの場合、別途ご注文お願いします。

      キャンセル処理を希望する場合、内容を記入して送信して下さい。
    `,
  },
  { label: 'その他', },
];

module.exports = {
  fields: () => {
    return {
      reason: {
        label: '理由',
        type: 'select',
        options: reasons.map(_ => ({ label: _.label, value: _.label, })),
        validations: {
          required:  v => !isEmpty(v),
        },
      },
      note: {
        label: '希望する内容を具体的に入力してください',
        type: 'text',
        validations: {
          required:  v => !isEmpty(v),
        },
      },
    };
  },
  reasons,
};
