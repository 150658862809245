import React from 'react';
import { set } from 'lodash/fp';
import { sortBy, } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdateProductTag } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields as _fields, } from '../../shared/models/productTag';

const db = firebase.firestore();
const productsRef = db.collection('products');
const productTagsRef = db.collection('productTags');

export default AdminPage(function AdminProductTags (props) {
  const { user } = props;
  const productTags = sortBy(useCollectionSubscriptionInTenant(productTagsRef), _ => _.createdAt.toDate());
  const fields = () => {
    return set('color.options', _fields().color.options.map(_ => ({
      ..._,
      label: <div><span className="fas fa-square" style={{ color: _.color }} /></div>
    })), _fields());
  };

  return (
    <div>
      <div className="admin-productTags container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>商品タグ一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={productTagsRef.doc()} FormModal={ModelFormModal} formProps={{ title: '商品タグ追加', fields, }} disabled={!canUpdateProductTag(user)} />
        </div>
        <div>
          {
            productTags.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>名称</th>
                    <th>色</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    productTags.map((productTag) => {
                      const { id, name, color, ref, } = productTag;
                      const beforeDelete = async () => {
                        if(
                          (await productsRef.where('productTagIds', 'array-contains', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id}>
                          <td>
                            {name}
                          </td>
                          <td>
                            <span className="fas fa-square" style={{ color: colors[color] }} />
                          </td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '商品タグ編集', fields }} disabled={!canUpdateProductTag(user)} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateProductTag(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                商品タグは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

