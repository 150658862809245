const { isEmpty } = require('lodash');

module.exports = {
  fields: () => {
    return {
      subject: {
        label: 'タイトル',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
      body: {
        label: 'タグ内容',
        type: 'text',
        initialValue: '',
        rows: 5,
        validations: { required: (v) => !isEmpty(v) },
      },
    };
  },
};

