import React, { useEffect, useRef } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import { isString, sortBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { Input, Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync, } from 'react-use';
import { v4 as uuid } from 'uuid';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { beforeDelete, } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentShopHeader from '../AgentShopHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/rentalItemEntity';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';
import TenantLink from '../TenantLink';
import { downloadCanvasImage } from '../../util';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export default AgentPage(function AgentShopRentalItemEntities (props) {
  const { tenant, user, agent, match: { params: { agentShopId } }, setBreadNavValues, canReadAllShops, memberData, } = props;
  const queryParams = useQueryParams();
  const agentShop = useDocumentSubscription((canReadAllShops || memberData.shopIds.includes(agentShopId)) && agent.ref.collection('agentShops').doc(agentShopId), [agent, agentShopId]);
  const rentalItems = sortBy(useCollectionSubscription(agent.ref.collection('rentalItems'), [agent]), 'name');
  const rentalItemsById = keyBy(rentalItems, 'id');
  const rentalItemGroups = sortBy(useCollectionSubscriptionInTenant(agent.ref.collection('rentalItemGroups'), [agent]), _ => _.createdAt.toDate());
  const rentalItemGroupsById = keyBy(rentalItemGroups, 'id');
  const rentalItemEntities = sortBy(useCollectionSubscription(agent.ref.collection('rentalItemEntities').where('agentShopId', '==', agentShopId), [agent, agentShopId]), 'code');
  const rentalItemOptions = rentalItems.map((_) => ({ label: _.name, value: _.id }));
  const groupOptions = rentalItemGroups.map((_) => ({ label: _.name, value: _.id }));

  let filteredRentalItemEntities = rentalItemEntities;
  if (!isEmpty(queryParams.rentalItemIds)) {
    filteredRentalItemEntities = filteredRentalItemEntities.filter((_) => queryParams.rentalItemIds.includes(_.rentalItemId));
  }
  if (!isEmpty(queryParams.groupIds)) {
    filteredRentalItemEntities = filteredRentalItemEntities.filter((_) => queryParams.groupIds.includes(rentalItemsById[_.rentalItemId]?.rentalItemGroupId));
  }
  if (queryParams.showsHidden !== '1') {
    filteredRentalItemEntities = filteredRentalItemEntities.filter(_ => !_.isHidden);
  }

  useEffect(() => {
    setBreadNavValues({ agent, agentShop, });
  }, [agent, agentShop]);

  return agentShop != null && (
    <div>
      <div className="agent-shop container-fluid py-5 position-relative">
        <AgentShopHeader activeTab="rentalItemEntities" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
            <QuerySelector
              paramName='groupIds'
              className='ml-0'
              width={300}
              isMulti
              options={groupOptions}
              label='グループで絞込み'
            />
            <QuerySelector
              paramName='rentalItemIds'
              className='ml-0'
              width={300}
              isMulti
              options={rentalItemOptions}
              label='レンタル商品で絞込み'
            />
            <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
          </div>
          <div className="mb-2 d-flex align-items-end justify-content-end gap-1">
            <AddInTenantButton itemRef={agent.ref.collection('rentalItemEntities').doc()} processValues={_ => ({ ..._, agentShopId })} FormModal={ModelFormModal} formProps={{ title: 'レンタル商品在庫追加', fields: fields({ rentalItems }), }} disabled={!canUpdateAgent(user)} />
          </div>
          {
            rentalItemEntities.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>グループ</th>
                    <th>レンタル商品</th>
                    <th>識別コード</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (filteredRentalItemEntities || []).map((rentalItemEntity) => {
                      const { id, ref, rentalItemId, code, isHidden = false, } = rentalItemEntity;
                      const rentalItem = rentalItemsById[rentalItemId];
                      const rentalItemGroup = rentalItemGroupsById[rentalItem?.rentalItemGroupId];
                      const beforeDelete = async () => {
                        if(
                          (await agent.ref.collection('rentalOrders').where('rentalItemEntityId', '==', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                          <td>
                            {rentalItemGroup?.name}
                          </td>
                          <td>
                            {rentalItem?.name}
                          </td>
                          <td>
                            {code}
                          </td>
                          <td className="text-right">
                            <ModalButton content={_ => <Qr text={code} />}>
                              QR
                            </ModalButton>
                            <EditButton itemRef={ref} className="ml-1" FormModal={ModelFormModal} formProps={{ title: 'レンタル商品在庫編集', fields: fields({ rentalItems }), }} disabled={!canUpdateAgent(user)} />
                            <DeleteButton itemRef={ref} className="ml-1" disabled={!canUpdateAgent(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                レンタル商品在庫は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

function Qr (props) {
  const { text } = props;
  const canvasEl = useRef(null);
  useEffect(() => {
    if(canvasEl.current) {
      const size = 350;
      const fontSize = 20;
      qrcode.toCanvas(canvasEl.current, text, { width: size, height: size });
      if (!text) return;

      const ctx = canvasEl.current.getContext('2d');
      ctx.font = `${fontSize}px sans-serif`;
      const { width: textWidth } = ctx.measureText(text);
      const paddingSize = 5;
      const rectWidth = textWidth + paddingSize * 2;
      const textHeight = fontSize;
      const rectHeight = textHeight + paddingSize * 2
      ctx.fillStyle = 'white';
      ctx.fillRect(size / 2 - rectWidth / 2, size / 2 - rectHeight / 2, rectWidth, rectHeight);
      ctx.fillStyle = 'black';
      ctx.fillText(text, size / 2 - textWidth / 2, size / 2 + textHeight / 2 - 3);
    }
  }, [canvasEl, text]);

  const handleQrImageDownload = () => {
    downloadCanvasImage(canvasEl.current, "qrcode.jpg");
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="d-flex justify-content-center" title={text}>
          <canvas ref={canvasEl} />
        </div>
        <div className="d-flex w-100 justify-content-end mb-1">
          <Button color="secondary" onClick={handleQrImageDownload}><span className="fas fa-download mr-1" />画像ダウンロード</Button>
        </div>
      </div>
    </div>
  );
}
