import React from 'react';

import AdminPage from '../hocs/AdminPage';
import Receipt from '../Receipt';

export default AdminPage(function AdminOrderReceipt (props) {
  const { match: { params: { orderId } } } = props;

  return (
    <div>
      <div className="admin-order-receipt container py-5 position-relative d-flex justify-content-center">
        <Receipt orderId={orderId} />
      </div>
    </div>
  );
});
