const { keyBy, orderBy, pick, isEmpty } = require('lodash');
const { isHiragana, isKatakana, } = require('wanakana');
const { isMultibyte, isEmail, } = require('validator');
const dedent = require('dedent');

const { prefectures, } = require('../config');
const { fields: agentProductSettingFields, } = require('./agentProductSetting');

const { keys, entries } = Object;

const corporationOrIndividuals = {
  corporation: { label: '法人', },
  individual: { label: '個人', },
};
const bankAccountTypes = {
  saving: { label: '普通', },
  checking: { label: '当座', },
};
const roles = {
  admin: { label: '管理者', },
  member: { label: 'メンバー', },
};

const productsPageHeaderHtmlField = {
  label: '商品ページ冒頭説明文',
  type: 'richText',
  rows: 10,
};

const commentFields = () => {
  return {
    body: {
      type: 'text',
      label: 'コメント内容',
      validations: {
        required: _ => !isEmpty(_),
      },
    },
  };
};

const newFields = () => {
  return {
    ...acceptFields(),
    ...newAgentFields(),
    ...newUserFields(),
    ...bankFields(),
  };
};

const acceptFields = () => {
  return {
    accept: {
      type: 'boolean',
      label: '規約に同意します',
      validations: {
        required: v => v === true,
      },
    },
  };
};

const newAgentFields = () => {
  return {
    ...pick(fields(), ['name', 'corporationOrIndividual']),
  };
};
const newUserFields = () => {
  return {
    displayName: {
      label: '担当者名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    nameKana: {
      type: 'string',
      label: '担当者名ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
    },
    phone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || v.match(/^(0{1}\d{9,10})$/),
      },
      warnings: {
        cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
      },
      placeholder: '08012345678 （ハイフンなし）'
    },
    email: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      hint: `
        携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
        詳しくは各携帯電話会社にご確認下さい。
      `,
    },
    postalCode: {
      type: 'string',
      label: '郵便番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => (v || '').match(/^[0-9]{7}$/g),
      },
      inputProps: {
        type: 'tel',
      },
    },
    prefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      validations: {
        required:  v => !isEmpty(v),
      },
    },
    city: {
      type: 'string',
      label: '市区町村',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    address: {
      type: 'string',
      label: '番地・建物名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
        minLength: v => (v || '').length >= 6,
      },
      placeholder: '6文字以上'
    },
    passwordConfirmation: {
      type: 'password',
      label: 'パスワード確認',
      validations: {
        required: (v, { password }) => !isEmpty(v),
        samePassword: (v, { password }) => v === password,
      },
    },
  };
};
const bankFields = () => {
  return {
    accountantName: {
      label: '経理担当者名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => _.corporationOrIndividual !== 'corporation',
    },
    accountantNameKana: {
      type: 'string',
      label: '経理担当者名ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
      hidden: _ => _.corporationOrIndividual !== 'corporation',
    },
    accountantPhone: {
      type: 'string',
      inputType: 'tel',
      label: '経理担当電話番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || v.match(/^(0{1}\d{9,10})$/),
      },
      warnings: {
        cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
      },
      placeholder: '08012345678 （ハイフンなし）',
      hidden: _ => _.corporationOrIndividual !== 'corporation',
    },
    accountantEmail: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      hint: `
        携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
        詳しくは各携帯電話会社にご確認下さい。
      `,
      hidden: _ => _.corporationOrIndividual !== 'corporation',
    },
    bankName: {
      label: '銀行名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    bankShopName: {
      label: '支店名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    bankAccountType: {
      label: '普通・当座',
      type: 'select',
      options: entries(bankAccountTypes).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    bankAccountNumber: {
      label: '口座番号',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    bankAccountName: {
      label: '口座名義',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    bankAccountNameKana: {
      label: '口座名義カナ',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
        katakana: v => isKatakana((v || '').replace(/[ 　]/g, '')),
      },
    },
  };
};
const contractPlanFields = () => {
  return {
    agentContractPlanText: {
      type: 'text',
      label: '本文',
      validations: {
        required: v => !isEmpty(v),
      },
      rows: 20,
    },
  };
};

const fields = () => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: { required:  v => !isEmpty(v), },
    },
    corporationOrIndividual: {
      label: '法人・個人',
      type: 'select',
      options: entries(corporationOrIndividuals).map(([k, v]) => ({ label: v.label, value: k })),
      validations: { required:  v => !isEmpty(v), },
    },
    usesReferral: {
      label: '紹介機能を使う',
      type: 'boolean',
      initialValue: false,
    },
    usesWholesale: {
      label: '卸機能を使う',
      type: 'boolean',
      initialValue: false,
    },
    usesPartsWholesale: {
      label: 'パーツ卸機能を使う',
      type: 'boolean',
      initialValue: false,
    },
    usesRental: {
      label: 'レンタル機能を使う',
      type: 'boolean',
      initialValue: true,
    },
    hidesAmountInSlip: {
      label: '卸にて納品書金額なし',
      type: 'boolean',
      initialValue: false,
    },
    enabledLastReferrerWin: {
      label: 'リファラ後勝ち',
      type: 'boolean',
      initialValue: false,
    },
    maxReferrersCount: {
      label: '店舗ごとの最大リファラ数',
      type: 'integer',
      initialValue: 5,
      validations: {
        greaterThan0: v => v != null && v > 0,
      },
    },
    isHidden: {
      label: '非表示',
      type: 'boolean',
      initialValue: false,
    },
  };
};
const agentMemberFields = ({ agentShops = [] }) => {
  return {
    role: {
      label: '権限',
      type: 'select',
      options: entries(roles).map(([k, v]) => ({ value: k, label: v.label })),
      validations: {
        required: _ => !isEmpty(_),
      },
    },
    shopIds: {
      label: '所属店舗',
      type: 'multiSelect',
      options: agentShops.map(_ => ({ value: _.id, label: _.name })),
      hint: '空白の場合、全ての店舗に所属しているとみなされます',
      hidden: _ => _.role !== 'member',
    },
  };
};

const registerFields = ({ products = [], qrUrls = [], agentContractPlans = [], } = {}) => {
  return {
    conditionNote: {
      label: '取扱条件',
      type: 'text',
      rows: 5,
    },
    agentContractPlanId: {
      label: '規約',
      type: 'select',
      options: agentContractPlans.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: _ => !isEmpty(_),
      },
    },
    productsPageHeaderHtml: productsPageHeaderHtmlField,
  };
};

const productRowFields = ({ nonPartProducts, } = {}) => {
  return {
    productId: {
      label: '取扱商品',
      type: 'select',
      options: nonPartProducts.map(_ => ({ label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`, value: _.id })),
      validations: {
        required: _ => !isEmpty(_),
      },
    },
    ...pick(agentProductSettingFields(), ['referralFeeType', 'referralFeeAmount', 'referralFeeRate']),
  };
};

const qrUrlFields = ({ qrUrls, coupons, }) => {
  const qrUrlsById = keyBy(qrUrls, 'id');
  return {
    qrUrlId: {
      label: '紹介QRコード遷移先',
      type: 'select',
      options: qrUrls.map(_ => ({ value: _.id, label: _.name })),
      validations: {
        required: _ => !isEmpty(_),
      },
    },
    couponIds: {
      label: '優待',
      type: 'multiSelect',
      options: coupons.map(_ => ({ value: _.id, label: _.name })),
      hint: '',
    },
    emailRequestScreenDescription: {
      label: 'メールアドレス入力画面説明文',
      type: 'richText',
      hidden: _ => !qrUrlsById[_.qrUrlId]?.requestsEmail,
    },
  }
};


module.exports = {
  fields,
  agentSettingsFields: ({ agentContractPlans, } = {}) => {
    return {
      ...fields(),
      ...bankFields(),
      productsPageHeaderHtml: productsPageHeaderHtmlField,
    };
  },
  commentFields,
  newFields,
  acceptFields,
  newAgentFields,
  newUserFields,
  bankFields,
  contractPlanFields,
  agentMemberFields,
  roles,
  registerFields,
  productRowFields,
  qrUrlFields,
};
