import { useList } from 'react-use';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';

import { writeBatch } from '../../firebase';
import { canUpdateProductType } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import { AddInTenantButton } from '../v9/AddInTenantButton';
import { EditButton } from '../v9/EditButton';
import { DeleteButton } from '../v9/DeleteButton';
import { fields } from '../../shared/models/productType';
import useTenant from '../hooks/useTenant';
import {
  useProductTypeCollection,
  tenantProductTypesQuery,
  newProductTypeRef,
  productTypeRef,
} from '../../models/productType';
import { getProductCollection, tenantProductsByProductTypeIdQuery } from 'src/models/product';

export default AdminPage(function AdminProductTypes(props) {
  const { user } = props;
  const tenant = useTenant();
  const [isDragging, toggleDragging] = useToggle(false);
  const [withCoupon, toggleWithCoupon] = useToggle(false);
  const [isWish, toggleWish] = useToggle(false);
  const [selectedIds, { push: selectId, removeAt: removeSelectedIdAt }] = useList([]);
  const unselectId = (_) => removeSelectedIdAt(selectedIds.indexOf(_));
  const isSelecting = selectedIds.length > 0;
  const productsUrl = `${window.location.origin}/${tenant?.id}/products?${qs.stringify({
    types: selectedIds,
    ...(withCoupon && { coupon: 1 }),
    ...(isWish && { wish: 1 }),
  })}`;
  const onClickCopy = () => {
    copy(productsUrl);
    toast.success('クリップボードにコピーしました');
  };
  const { data: productTypes = [] } = useProductTypeCollection(tenantProductTypesQuery(tenant?.id));
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const batch = writeBatch();
    arrayMoveImmutable(productTypes, removedIndex, addedIndex).map(({ id }, index) =>
      batch.update(productTypeRef(id), { index })
    );
    await batch.commit();
  };

  return (
    <div>
      <div className="admin-productTypes container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>商品種別一覧</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton
                  itemRef={newProductTypeRef()}
                  processValues={(_) => ({ ..._, index: productTypes.length })}
                  FormModal={ModelFormModal}
                  formProps={{ title: '商品種別追加', fields }}
                  disabled={!canUpdateProductType(user)}
                />
              </div>
              {isSelecting && (
                <div className="mb-3">
                  <div>{selectedIds.length} 件を選択中</div>
                  <div className="mt-1">
                    <label className="small text-muted mb-1">商品ページURL</label>
                    <div className="d-flex align-items-center">
                      <Input className="flex-fill mr-2" readOnly value={productsUrl} />
                      <FormGroup check className="text-nowrap mr-2">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={withCoupon}
                            onChange={(_) => toggleWithCoupon(_.target.checked)}
                          />
                          優待あり
                        </Label>
                      </FormGroup>
                      <FormGroup check className="text-nowrap mr-2">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={isWish}
                            onChange={(_) => toggleWish(_.target.checked)}
                          />
                          おねだり
                        </Label>
                      </FormGroup>
                      <Button color="primary" onClick={onClickCopy}>
                        <span className="fas fa-copy" />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div>
                {productTypes.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>名称</th>
                        <th></th>
                      </tr>
                    </thead>
                    <Container
                      dragHandleSelector=".drag-handle"
                      onDrop={onDrop}
                      onDragStart={(_) => toggleDragging(true)}
                      onDragEnd={(_) => toggleDragging(false)}
                      // NOTE: 型エラー出るけどstyleはあたっているので一旦anyで回避
                      dropPlaceholder={{
                        style: { background: 'eee' },
                      }}
                      render={(ref) => (
                        <tbody ref={ref}>
                          {productTypes.map((productType) => {
                            const { id, name } = productType;
                            const beforeDelete = async () => {
                              const products = await getProductCollection(
                                tenantProductsByProductTypeIdQuery(tenant.id, id, 1)
                              );
                              if (products.length > 0) {
                                toast.error('使用されているため削除できません');
                                return false;
                              }
                              return true;
                            };

                            return (
                              <Draggable
                                key={id}
                                render={() => (
                                  <tr
                                    {...(!isDragging && {
                                      style: { display: 'table-row' },
                                    })}
                                  >
                                    <td style={{ width: 30 }}>
                                      <div className="drag-handle text-muted cursor-pointer">
                                        <span className="fas fa-grip-vertical" />
                                      </div>
                                    </td>
                                    <td>
                                      <Input
                                        type="checkbox"
                                        className="position-relative m-0"
                                        checked={selectedIds.includes(id)}
                                        onChange={(_) => (selectedIds.includes(id) ? unselectId(id) : selectId(id))}
                                      />
                                    </td>
                                    <td>{id}</td>
                                    <td>{name}</td>
                                    <td className="text-nowrap text-right">
                                      <EditButton
                                        itemRef={productTypeRef(id)}
                                        FormModal={ModelFormModal}
                                        formProps={{
                                          title: '商品種別編集',
                                          fields,
                                        }}
                                        disabled={!canUpdateProductType(user)}
                                      />
                                      <DeleteButton
                                        itemRef={productTypeRef(id)}
                                        className="ml-2"
                                        disabled={!canUpdateProductType(user)}
                                        beforeDelete={beforeDelete}
                                      />
                                    </td>
                                  </tr>
                                )}
                              />
                            );
                          })}
                        </tbody>
                      )}
                    />
                  </table>
                ) : (
                  <div>商品種別は未登録です</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
