import React from 'react';
import { toast } from 'react-toastify';
import { mapValues, isEmpty, keys } from 'lodash';
import { useToggle } from 'react-use';
import { Tooltip } from 'reactstrap';

import { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import AppButton from '../AppButton';

const getOrderItems = functions.httpsCallable('getOrderItems');
const fields = {
  orderId: {
    label: '注文番号',
    type: 'patternString',
    validations: {
      required: (_) => !isEmpty(_),
      format: (v) => !v || v.match(/^\d{5}-\d{5}-\d{5}-\d{5}$/),
    },
    pattern: '#####-#####-#####-#####',
  },
};

export default function PublicTroubleInquiryInputOrderForm(props) {
  const { onSubmit } = props;
  const statedFields = useFormState({}, fields);
  const [isSubmitting, toggleSubmitting] = useToggle();
  const [showTooltip, toggleTooltip] = useToggle(false);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);

  const handleClick = async () => {
    if (isUnsubmittable || isSubmitting) return;
    toggleSubmitting(true);

    const values = mapValues(statedFields, 'value');
    try {
      const { data } = await getOrderItems({ orderId: values.orderId });
      if (data) {
        const { orderId, createdAt, productIds } = data;
        onSubmit({ orderId, createdAt, productIds });
      } else {
        toast.error('存在しない注文番号です');
      }
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    } finally {
      toggleSubmitting(false);
    }
  };

  return (
    <>
      <div className="mb-5 text-muted">
        お買上の製品の初期不良やお使いになっての故障、お困りのことがありましたら下記に記入して、お問合せください。
      </div>
      {keys(fields).map((fieldName) => {
        const fieldSetting = statedFields[fieldName];
        return (
          <Field
            key={fieldName}
            name={fieldName}
            documentName="troubleInquiry"
            {...fieldSetting}
            options={fieldSetting.options}
            hint={fieldSetting.hint}
          />
        );
      })}
      <p className="mb-5">
        ※ 旧システムの注文番号
        <span id="oldOrderNumber" className="far fa-question-circle text-muted" />
        をお持ちのお客様は<a href="/inquiries/new">こちら</a>よりお問合せください。
      </p>
      <Tooltip placement="top" isOpen={showTooltip} target="oldOrderNumber" toggle={toggleTooltip} style={{ maxWidth: '220px' }}>
        <div>日付(年月日)-6桁の数字 <br />例）20000101-000001</div>
        <div>P + 18桁の数字<br />例）P123456789123456789</div>
      </Tooltip>
      <div className="d-flex mt-5">
        <AppButton
          size="lg"
          className="save flex-fill ml-2"
          color="primary"
          onClick={handleClick}
          disabled={isUnsubmittable || isSubmitting}
        >
          <span className="fas fa-arrow-right mr-1" />
          次へ
        </AppButton>
      </div>
    </>
  );
}
