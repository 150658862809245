import { useEffect, useMemo, } from 'react';
import { format as formatDate, addDays, } from 'date-fns';
import { useAsync, } from 'react-use';
import { keyBy, orderBy, } from 'lodash';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { getCollectionData, } from '../../shared/firebase';
import PublicPage from '../hocs/PublicPage';
import TenantUserPage from '../hocs/TenantUserPage';
import RichTextContent from '../RichTextContent';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { prefectures } from '../../shared/config';
import useAppTitle from '../hooks/useAppTitle';

const { entries, keys, } = Object;
const db = firebase.firestore();
const fetchUserVoiceData = functions.httpsCallable('fetchUserVoiceData');

function UserVoiceContent(props) {
  const { history, user, tenant, userVoice, match: { params: { userVoiceId }, }, } = props;
  const questions = useCollectionSubscriptionInTenant(db.collection('questions'));
  const questionsById = keyBy(questions, 'id');
  useAppTitle(userVoice?.title);
  const { value: surveyAnswers, loading: isLoadingSurveyAnswers = false } = useAsync(async () => {
    const { data } = await fetchUserVoiceData({
      userVoiceId,
    });
    return data?.surveyAnswers.map((surveyAnswer) => {
      return {
        ...surveyAnswer,
        createdAt: { toDate: _ => new Date(surveyAnswer.createdAt._seconds * 1000), },
      };
    }) ?? await getCollectionData(db.collection('surveyAnswers').where('userVoiceIds', 'array-contains', userVoiceId));
  }, [userVoiceId]);
  const sortedSurveyAnswers = useMemo(_ => orderBy(surveyAnswers, _ => _.createdAt.toDate(), 'desc'), [surveyAnswers]);
  useEffect(() => {
    if(userVoice?.isNoindex && ![...(document.head.childNodes || [])].some(_ => _.getAttribute?.('name') === 'robots')) {
      const metaElm = document.createElement('meta');
      metaElm.setAttribute('name', 'robots');
      metaElm.setAttribute('content', 'noindex');
      document.head.appendChild(metaElm);
    }
  }, [userVoice?.isNoindex]);
  useEffect(() => {
    if(user != null && !userVoice?.isPublic && !['admin', 'staff'].includes(user.role)) {
      history.push(`/${tenant.id}`);
      toast.error('非公開です');
      return null;
    }
  }, [user?.role, userVoice]);


  return (
    <div className={`container p-3 p-md-5`}>
      <div className="row">
        <div className={`col col-12 d-flex flex-column gap-5`}>
          <h3 className="text-center">{userVoice?.title}</h3>
          <RichTextContent className="text-left" html={userVoice?.description} />
          <div className="d-flex flex-column gap-2">
            {
              sortedSurveyAnswers.map((surveyAnswer) => {
                return (
                  <SurveyAnswerContent key={surveyAnswer.id} surveyAnswer={surveyAnswer} questionsById={questionsById} />
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

function SurveyAnswerContent (props) {
  const { surveyAnswer, questionsById, } = props;
  const rows = entries(surveyAnswer.answers || {}).filter(_ => surveyAnswer.publicQuestionIds?.includes(_[0])).map(([questionId, answer]) => {
    const question = questionsById[questionId];
    return {
      questionId,
      question,
      answer,
    };
  });

  return (
    <div className="border rounded p-3">
      {
        surveyAnswer.userVoiceHeader && (
          <div className="p-2 rounded bg-light-grey mb-3" style={{ whiteSpace: 'pre-line' }}>
            {surveyAnswer.userVoiceHeader}
          </div>
        )
      }
      <div className="d-flex flex-column gap-3">
        {
          rows.map(({ questionId, question, answer }) => {
            const displayValue = {
              text: answer,
              checkbox: keys(answer || {}).join(', '),
              radio: answer,
              select: answer,
              imageFile: [answer].flat().map((answer, i) => (
                <div key={i}>
                  <img src={answer?.url} style={{ maxWidth: '100%' }}></img>
                </div>
              )),
            }[question?.type || ''];
            return (
              <div key={questionId}>
                <div className="small text-muted">{question?.description}</div>
                <div>
                  {displayValue}
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="mt-4 d-flex justify-content-end gap-1 small">
        <div>
          {formatDate(surveyAnswer.createdAt.toDate(), 'yyyy/MM/dd')}
        </div>
        <div>
          {prefectures[surveyAnswer.createdBy.prefecture]}
        </div>
        <div>
          {surveyAnswer.createdBy.displayName.slice(0, 2)}{Array(surveyAnswer.createdBy.displayName.length - 2).fill().map(_ => '*').join('')}様
        </div>
      </div>
    </div>
  );
}

export default function UserVoice(props) {
  const { match: { params: { tenantPath, userVoiceId }, }, } = props;
  const userVoice = useDocumentSubscription(db.collection('userVoices').doc(userVoiceId), [userVoiceId]);
  const Content = userVoice?.isPublic ? PublicPage(UserVoiceContent) : TenantUserPage(UserVoiceContent);
  return <Content {...props} userVoice={userVoice} />;
}
