import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { pickBy, isEmpty, keyBy, sortBy, get, pick, omit, mapValues, } from 'lodash';
import { useMap, } from 'react-use';
import { getYear, getMonth, } from 'date-fns';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import { fields, } from '../../shared/models/troubleInquiry';
import { computeVisibleQuestionIds, computeVisibleAnswers, } from '../../shared/models/survey';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { generateTroubleInquiryId, } from '../../shared/util';
import useFormState from '../hooks/useFormState';
import TroubleInquiryBasicForm from '../forms/TroubleInquiryBasicForm';
import TroubleInquiryPartsSelectorForm from '../forms/TroubleInquiryPartsSelectorForm';
import TroubleInquiryDeliveryForm from '../forms/TroubleInquiryDeliveryForm';
import TroubleInquiryConfirmForm from '../forms/TroubleInquiryConfirmForm';
import TenantUserPage from '../hocs/TenantUserPage';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import TroubleInquiryFlowSection from '../TroubleInquiryFlowSection';
import { getCollectionData } from '../../shared/firebase';

const storageRef = firebase.storage().ref();
const { entries, keys, } = Object;
const auth = firebase.auth();
const db = firebase.firestore();
const ordersRef = db.collection('orders');
const productTypesRef = db.collection('productTypes');
const productsRef = db.collection('products');
const productTagsRef = db.collection('productTags');
const troubleInquiriesRef = db.collection('troubleInquiries');
const surveysRef = db.collection('surveys');
const questionsRef = db.collection('questions');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');
const getOrderItems = functions.httpsCallable('getOrderItems');
const createPendingSubUser = functions.httpsCallable('createPendingSubUser');

function Page(props) {
  const { tenant, user, myAccount, history, toggleLoginForm } = props;
  const { sourceTroubleInquiryId, sourceSurveyAnswerId, orderId, productId, productTypeId, wholesale, agentId: wholesaleAgentId, agentShopId: wholesaleAgentShopId, createdAt } = useQueryParams();
  const isWholesale = wholesale === '1';
  const sourceTroubleInquiry = useDocumentSubscription(sourceTroubleInquiryId && db.collection('troubleInquiries').doc(sourceTroubleInquiryId), [sourceTroubleInquiryId]);
  const wholesaleAgent = useDocumentSubscription(isWholesale && db.collection('agents').doc(wholesaleAgentId), [isWholesale, wholesaleAgentId]);
  const wholesaleAgentShop = useDocumentSubscription(wholesaleAgent?.ref.collection('agentShops').doc(wholesaleAgentShopId), [wholesaleAgent, wholesaleAgentShopId]);
  const order = useDocumentSubscription(user != null && orderId && ordersRef.doc(orderId), [user, orderId]);
  const orders = useCollectionSubscriptionInTenant(user != null && !orderId && ordersRef.where("createdBy.uid", "==", user.uid).orderBy('createdAt', 'desc'), [user, orderId]);
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const productTags = sortBy(useCollectionSubscriptionInTenant(productTagsRef), _ => _.createdAt.toDate());
  const partProducts = products
    .filter(_ => !_.isHidden)
    .filter(_ => _.isPart);
  const statedFields = useFormState({ productId, productTypeId, }, fields({ products, productTypes }));
  const currentProduct = productsById[statedFields.productId.value];
  const survey = useDocumentSubscription(currentProduct?.troubleInquirySurveyId != null && surveysRef.doc(currentProduct[sourceTroubleInquiryId ? 'troubleReinquirySurveyId' : 'troubleInquirySurveyId']), [currentProduct?.troubleInquirySurveyId]);
  const questions = useDocumentsFetch((survey?.questionRows?.map(_ => _.questionId) || []).map(_ => questionsRef.doc(_)), [survey]);
  const questionsById = keyBy(questions, 'id');
  const initialAnswers = questions.reduce(
    (x, { id, type, isOptional = false }) => ({
      ...x,
      [id]: {
        isValid: isOptional,
        value: { text: '', radio: null, select: null, checkbox: {}, imageFile: null, }[type],
      },
    }),
    {}
  );
  const [answers, { setAll: setAnswers, }] = useMap(initialAnswers);
  const visibleQuestionIds = computeVisibleQuestionIds(survey, mapValues(answers, 'value'));
  const visibleQuestions = visibleQuestionIds.map(_ => questionsById[_] || {});
  const [selectedItems, { set: setSelectedItems, remove: removeSelectedItems, }] = useMap({});
  const [currentScreen, setScreen] = useState('basic');

  const createOrUpdateUser = async (_values, createdBy) => {
    if (user != null) {
      // NOTE: 更新
      await user.ref.update({ ...pick(_values, ['nameKana', 'postalCode', 'city', 'address', 'route']) });
      return user;
    } else {
      // NOTE: アカウント登録
      try {
        const { email, password, name: displayName } = _values;
        const userValues = {
          displayName,
          ...pick(_values, [
            'email',
            'phone',
            'prefecture',
            'route',
            'nameKana',
            'postalCode',
            'city',
            'address',
            'children',
          ]),
          children: [],
          pendingMainUserId: createdBy
        };
        const {
          data: { uid },
        } = await retry(
          (_) =>
            createUserAndSendEmailVerification({
              userValues,
              password,
              pathname: encodeURIComponent('/mypage/orders'),
              skipsEmailVerification: true,
            }),
          { maxTimeout: 1000 }
        );
        try {
          await auth.signInWithEmailAndPassword(email, password);
          await createPendingSubUser({ mainUserId: createdBy });
        } catch (e) {
          // NOTE: 最悪ログインは失敗しても問題ないのでスルー
          console.error(e);
        }
        return { uid, ...userValues };
      } catch (e) {
        console.error(e);
        const code = get(e, 'details.code') || e.code;
        const message = userErrorMessages[code] || '登録に失敗しました';
        toast.error(message);
        throw e;
      }
    }
  };

  const onSubmit = async () => {
    const id = await generateTroubleInquiryId();
    const ref = troubleInquiriesRef.doc(id);
    const processedAnswers = await entries(answers).reduce(async (x, [questionId, { value }]) => {
      const prevs = await x;

      const question = questionsById[questionId];
      return {
        ...prevs,
        [questionId]:
          question?.type === 'imageFile' && !isEmpty(value)
            ? await Promise.all(
                value.map(async (file) => {
                  const fileRef = storageRef.child(`troubleInquiries/${ref.id}/${questionId}/${new Date().toISOString()}/${file.name}`);
                  await fileRef.put(file, { contentType: file.type });
                  return {
                    ...pick(file, ['name', 'type']),
                    url: await fileRef.getDownloadURL(),
                  };
                })
              )
            : value,
      };
    }, Promise.resolve({}));

    const values = {
      ...mapValues(statedFields, 'value'),
      answers: processedAnswers,
    };
    // orderIdが無い時、productIdから探す
    const [_order] = !orderId && values.productId
      ? await getCollectionData(ordersRef.where('tenantId', '==', tenant.id).where('createdBy.uid', '==', user.uid).where('productIds', 'array-contains', values.productId))
      : [];
    try {
      const { data } = await getOrderItems({ orderId: orderId || _order?.id });
      const order = data ? { ...data, id: data.orderId, createdAt: new Date(data.createdAt) } : null;

      // NOTE: アカウント更新 or 登録
      const _user = await createOrUpdateUser(values, order?.createdBy);

      // NOTE: 問い合わせ登録
      await ref.set({
        tenantId: tenant.id,
        ...omit(values, 'files'),
        sourceOrder: order != null ? pick(order, ['id', 'orderItems', 'createdAt']) : null,
        sourceTroubleInquiryId: sourceTroubleInquiryId || null,
        sourceSurveyAnswerId: sourceSurveyAnswerId || null,
        items: entries(selectedItems).map(([k, v]) => ({ productId: k, quantity: v })),
        createdAt: new Date(),
        createdBy: _user,
        myAccount: myAccount || null,
        isWholesale,
        wholesaleAgentId: wholesaleAgentId || null,
        wholesaleAgentShopId: wholesaleAgentShopId || null,
        status: 'initial',
      });

      // NOTE: 遷移、トースト
      history.push('/mypage/troubleInquiries');
      toast.success('お問合せ完了しました。');
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  useEffect(() => {
    if (user != null) {
      statedFields.setValues({
        name: user.displayName,
        ...pick(user, ['nameKana', 'phone', 'email', 'postalCode', 'prefecture', 'city', 'address', 'route']),
      });
    }
  }, [user != null]);
  useEffect(() => {
    if (order != null) {
      statedFields.setValues({
        purchaseYear: getYear(order.createdAt.toDate()),
        purchaseMonth: getMonth(order.createdAt.toDate()) + 1,
      });
    }
    if (createdAt) {
      statedFields.setValues({
        purchaseYear: getYear(new Date(createdAt)),
        purchaseMonth: getMonth(new Date(createdAt)) + 1,
      });
    }
  }, [order != null, products.length, productTypes.length, createdAt]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentScreen]);
  useEffect(() => {
    setAnswers(initialAnswers);
  }, [questions]);
  useEffect(() => {
    if (sourceTroubleInquiry != null) {
      statedFields.setValues({
        ...pick(sourceTroubleInquiry, keys(statedFields)),
      });
    }
  }, [sourceTroubleInquiry != null]);
  useEffect(() => {
    const [lastOrder] = orders.filter(({ productIds }) => productIds && productIds.some(productId => productsById[productId]?.isBody));
    // orderIdが無い時、直近のorderから設定する
    if (!orderId && lastOrder) {
      const [productId] = lastOrder.productIds.filter(productId => productsById[productId]?.isBody);
      const product = productsById[productId];
      statedFields.setValues({
        productId,
        productTypeId: product.productTypeIds?.[0]
      });
    }
  }, [orderId, orders?.length, products?.length]);
  const screens = ['basic', 'partsSelector', 'delivery', 'confirm'];

  return (
    <div className="trouble-inquiry position-relative">
      <TroubleInquiryFlowSection activeIndex={screens.indexOf(currentScreen)} />
      <section className="container mt-5 py-3">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            {
              ({
                basic: () => <TroubleInquiryBasicForm onSubmit={_ => setScreen('partsSelector')}  statedFields={statedFields} {...{ products, isWholesale, wholesaleAgent, wholesaleAgentShop, questions: visibleQuestions, answers, onChange: (id, _) => setAnswers(computeVisibleAnswers(survey, { ...answers, [id]: _, })), }} />,
                partsSelector: () => <TroubleInquiryPartsSelectorForm onSubmit={_ => setScreen('delivery')} onCancel={_ => setScreen('basic')} {...{ statedFields, products: partProducts, productTags, selectedItems, setSelectedItems, removeSelectedItems, isWholesale, wholesaleAgent, wholesaleAgentShop, }} isOptinal={sourceTroubleInquiry != null} />,
                delivery: () => <TroubleInquiryDeliveryForm onSubmit={_ => setScreen('confirm')} onCancel={_ => setScreen('partsSelector')} statedFields={statedFields} user={user} {...{ isWholesale, wholesaleAgent, wholesaleAgentShop, toggleLoginForm }} />,
                confirm: () => <TroubleInquiryConfirmForm onSubmit={onSubmit} onCancel={_ => setScreen('delivery')} {...{ statedFields, selectedItems, products: partProducts, isWholesale, wholesaleAgent, wholesaleAgentShop, questions: visibleQuestions, answers, }} />,
              })[currentScreen]()
            }
          </div>
        </div>
      </section>
    </div>
  );
};

export default function TroubleInquiry(props) {
  const { user } = props;
  const queryParams = useQueryParams();
  Page.preview = !user && queryParams.sourceTroubleInquiryId == null && queryParams.sourceSurveyAnswerId == null;
  const Component = TenantUserPage(Page)
  return <Component {...props} />;
};
