import React from 'react';

import UserPage from '../hocs/UserPage';
import Warranty from '../Warranty';

export default UserPage(function MypageOrderWarranty (props) {
  const { match: { params: { orderId } } } = props;

  return (
    <div>
      <div className="mypage-order-warranty container py-5 position-relative d-flex justify-content-center">
        <Warranty orderId={orderId} />
      </div>
    </div>
  );
});
