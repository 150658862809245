import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { ProductTypeDocumentData, ProductType } from '../types';
import type { PartiallyPartial } from 'types';

export const productTypeConverter = getConverter<ProductTypeDocumentData>();

export const productTypesRef = () => collection(getFirestore(), 'productTypes').withConverter(productTypeConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<ProductType> : null;
export const productTypeRef = <Id extends string | undefined>(productTypeId: Id) =>
  (productTypeId ? doc(productTypesRef(), productTypeId) : null) as RefOrNull<Id>;

export const newProductTypeRef = () => doc(productTypesRef());

export const defaultQueryConstraints = [orderBy('index', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const productTypesQuery = (...queryConstraints: QueryConstraint[]) =>
  query(productTypesRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantProductTypesQuery = (tenantId: string | undefined) =>
  tenantId ? productTypesQuery(...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

export const addProductType = async (data: PartiallyPartial<ProductType, 'id' | 'createdAt'>) =>
  addDoc(productTypesRef(), { createdAt: serverTimestamp(), ...data });

export const updateProductType = async (productTypeId: string, data: UpdateData<ProductType>) =>
  updateDoc(productTypeRef(productTypeId), data);

export const deleteProductType = async (productTypeId: string) => deleteDoc(productTypeRef(productTypeId));

export const useProductTypeCollection = useCollectionData;

export const useProductTypeCollectionOnce = useCollectionDataOnce;

export const useProductTypeDocument = useDocumentData;

export const useProductTypeDocumentOnce = useDocumentDataOnce;
