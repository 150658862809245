const statuses = {
  initial: { label: '未配信', color: 'secondary', },
  requested: { label: '配信済み', color: 'info', },
  delivered: { label: '配信完了(既読)', color: 'success', },
  failed: { label: '配信失敗', color: 'danger', },
};

module.exports = {
  statuses,
};
