import React, { useState, Fragment, } from 'react';
import { toast } from 'react-toastify';
import { format as formatDate, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, } from 'date-fns';
import { Button, Input } from 'reactstrap';
import { sortBy, omitBy, isUndefined, pick, groupBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import numeral from 'numeral';
import qs from 'qs';
import copy from 'copy-to-clipboard';
import Select from 'react-select';

import { fullPathWithParams, } from '../../util';
import firebase from '../../firebase';
import { canUpdateAgent, canUpdateAgentContractPlan, canUpdateQrBottomDisplay, } from '../../shared/abilities';
import { conclusiveReferralFee } from '../../shared/models/order';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import ModalButton from '../ModalButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import { fields } from '../../shared/models/agent';
import { fields as agentContractPlanFields } from '../../shared/models/agentContractPlan';
import { fields as qrBottomDisplayFields } from '../../shared/models/qrBottomDisplay';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const productsRef = db.collection('products');
const agentsRef = db.collection('agents');
const referrersRef = db.collectionGroup('referrers');
const ordersRef = db.collection('orders');

export default AdminPage(function AdminAgents (props) {
  const { tenant, user, history, location, } = props;
  const now = new Date();
  const {
    startOn: startOnString = formatDate(addDays(new Date(), -7), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(new Date(), 'yyyy-MM-dd'),
    showsHidden,
  } = useQueryParams();
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const [selectedAgentContractPlanId, setSelectedAgentContractPlanId] = useState(null);
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const agents = useCollectionSubscriptionInTenant(agentsRef.orderBy('createdAt'));
  const agentsById = keyBy(agents, 'id');
  const orders = useCollectionSubscriptionInTenant(ordersRef.where('shippedDate', '>=', startOnString.replace(/-/g, '/')).where('shippedDate', '<=', endOnString.replace(/-/g, '/')), [startOnString, endOnString]);
  const referrers = useCollectionsFetchInTenant(orders.filter(_ => _.referrerKey).map(_ => referrersRef.where('key', '==', _.referrerKey)), [orders.length]);
  const referrersById = keyBy(referrers, 'id');
  const agentShops = useDocumentsFetch(referrers.map(_ => _.ref.parent.parent), [referrers.length]);
  const agentShopsById = keyBy(agentShops, 'id');
  const agentContractPlans = sortBy(useCollectionSubscriptionInTenant(db.collection('agentContractPlans'), []), _ => _.createdAt.toDate());
  const agentContractPlanOptions = agentContractPlans.map(_ => ({ value: _.id, label: _.name }));
  const computedOrders = orders.map((order) => {
    const { referrerKey, amount, discountAmount = 0, } = order;
    const referrer = referrersById[referrerKey];
    const referralAgentId = referrer?.ref.parent.parent.parent.parent.id;
    const discountedAmount = amount - discountAmount;
    return { ...order, referralAgentId, discountedAmount, };
  });
  const referralOrdersGroupedByAgentId = groupBy(computedOrders, 'referralAgentId');
  const wholesaleOrdersGroupedByAgentId = groupBy(computedOrders, 'wholesaleAgentId');


  let filteredAgents = agents;
  if (showsHidden !== '1') {
    filteredAgents = filteredAgents.filter(_ => !_.isHidden);
  }

  const rowsForExport = () => {
    return filteredAgents.map((agent) => {
      const { id, name, productsPageHeaderHtml, } = agent;
      return {
        id,
        name,
        productsPageHeaderHtml,
      };
    });
  };
  const processRow = (batch, item, i) => {
    const agent = agentsById[item.id];
    if(agent == null) return;

    batch.update(agent.ref, {
      ...pick(item, ['productsPageHeaderHtml']),
      updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
    });
  };
  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams({ startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd'), }, location);
    history.replace(encodeURI(path));
  };
  const url = `${window.location.origin}/${tenant.id}/agents/new?planId=${selectedAgentContractPlanId}`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };

  return (
    <div>
      <div className="admin-agents container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>代理店一覧</h4>
        </div>
        <div className="d-flex justify-content-start align-items-end mb-3 mt-1 gap-1">
          <div className="d-flex align-items-end">
            <Button className="ml-0" onClick={onClickDateButton.bind(null, [startOfMonth(now), endOfMonth(now)])}>今月</Button>
            <Button className="ml-2" onClick={onClickDateButton.bind(null, [startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}>先月</Button>
            <QueryDateSelector className="ml-2" paramName="startOn" label="開始日" history={history} location={location} defaultValue={addDays(new Date(), -7)} />
            <QueryDateSelector className="ml-2" paramName="endOn" label="終了日" history={history} location={location} defaultValue={new Date()} invalid={startOn > endOn} />
          </div>
          <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
        </div>
        <div className="d-flex justify-content-end align-items-end mb-3 mt-1 gap-1">
          <div className="d-flex gap-1">
            <Button tag={TenantLink} to={`/admin/agents/register`}>
              紹介代理店登録開始
            </Button>
            <ModalButton title="規約" content={_ => <AgentContractPlans user={user} tenant={tenant} />} modalProps={{ style: { minWidth: 1000 } }}>
              規約
            </ModalButton>
            <ModalButton title="QR下部表記" content={_ => <QrBottomDisplays user={user} tenant={tenant} />} modalProps={{ style: { minWidth: 1000 } }}>
              QR下部表記
            </ModalButton>
            <ImportButton processRow={processRow} />
            <ExportButton fileName={`代理店.csv`} rows={rowsForExport} />
            <AddInTenantButton itemRef={agentsRef.doc()} FormModal={ModelFormModal} formProps={{ documentName: 'agent', title: '代理店追加', fields }} disabled={!canUpdateAgent(user)} />
          </div>
        </div>
        <div>
          {
            agents.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>代理店名</th>
                    <th>紹介機能を使う</th>
                    <th>紹介注文合計</th>
                    <th>紹介料合計</th>
                    <th>卸機能を使う</th>
                    <th>パーツ卸機能を使う</th>
                    <th>卸注文合計</th>
                    <th>レンタル機能を使う</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredAgents.map((agent) => {
                      const { id, ref, name, usesReferral = true, usesWholesale = false, usesPartsWholesale = false, usesRental = false, hidesAmountInSlip = false, isHidden = false, } = agent;
                      const uncancelledReferralOrders = (referralOrdersGroupedByAgentId[id] || []).filter(_ => isEmpty(_.cancelledAt));
                      const uncancelledWholesaleOrders = (wholesaleOrdersGroupedByAgentId[id] || []).filter(_ => isEmpty(_.cancelledAt));
                      const beforeDelete = async () => {
                        if(
                          (await ref.collection('agentShops').limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                          <td>
                            <div>
                              <TenantLink to={`/admin/agents/${id}/agentShops`}>
                                {name}
                              </TenantLink>
                            </div>
                            {hidesAmountInSlip && <span className="badge badge-secondary">納品書金額なし</span>}
                          </td>
                          <td className="text-right">
                            {usesReferral && 'はい'}
                          </td>
                          <td className="text-right">
                            <TenantLink to={`/agents/${id}/referralOrders?${qs.stringify({ startOn: startOnString, endOn: endOnString })}`} target="_blank">
                              {numeral(sumBy(uncancelledReferralOrders, 'discountedAmount')).format()}
                            </TenantLink>
                          </td>
                          <td className="text-right">
                            {numeral(sumBy(uncancelledReferralOrders, conclusiveReferralFee.bind(null, productsById))).format()}
                          </td>
                          <td className="text-right">
                            {usesWholesale && 'はい'}
                          </td>
                          <td className="text-right">
                            {usesPartsWholesale && 'はい'}
                          </td>
                          <td className="text-right">
                            <TenantLink to={`/agents/${id}/wholesaleOrders?${qs.stringify({ startOn: startOnString, endOn: endOnString })}`} target="_blank">
                              {numeral(sumBy(uncancelledWholesaleOrders, _ => (_.wholesaleAmount || 0) + (_.shipmentFee || 0))).format()}
                            </TenantLink>
                          </td>
                          <td className="text-right">
                            {usesRental && 'はい'}
                          </td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} disabled={!canUpdateAgent(user)} formProps={{ documentName: 'agent', title: '代理店編集', fields, }} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateAgent(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                代理店は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

function AgentContractPlans (props) {
  const { user, tenant } = props;
  const agentContractPlans = sortBy(useCollectionSubscriptionInTenant(db.collection('agentContractPlans'), []), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <AddInTenantButton itemRef={db.collection('agentContractPlans').doc()} FormModal={ModelFormModal} formProps={{ documentName: 'agentContractPlan', title: '規約追加', fields: agentContractPlanFields }} disabled={!canUpdateAgentContractPlan(user)} />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th style={{ minWidth: 300 }}>本文</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            agentContractPlans.map((agentContractPlan) => {
              const { id, ref, name, text, } = agentContractPlan;
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td>
                    <div className="overflow-auto" style={{ maxHeight: 100, whiteSpace: 'pre-line', }}>
                      {text}
                    </div>
                  </td>
                  <td className="text-nowrap text-right">
                    <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'agentContractPlan', title: '代理店テンプレート編集', fields: agentContractPlanFields(), }} disabled={!canUpdateAgentContractPlan(user)} />
                    <DeleteButton itemRef={ref} className="ml-1" disabled={!canUpdateAgentContractPlan(user)} />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

function QrBottomDisplays (props) {
  const { user, tenant } = props;
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(db.collection('qrBottomDisplays'), []), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <AddInTenantButton itemRef={db.collection('qrBottomDisplays').doc()} FormModal={ModelFormModal} formProps={{ documentName: 'qrBottomDisplay', title: 'QR下部表記追加', fields: qrBottomDisplayFields() }} disabled={!canUpdateQrBottomDisplay(user)} />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th style={{ minWidth: 300 }}>表記</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            qrBottomDisplays.map((qrBottomDisplay) => {
              const { id, ref, name, display, } = qrBottomDisplay;
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td>
                    <div className="overflow-auto" style={{ maxHeight: 100, whiteSpace: 'pre-line', }}>
                      {display}
                    </div>
                  </td>
                  <td className="text-nowrap text-right">
                    <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'qrBottomDisplay', title: '代理店テンプレート編集', fields: qrBottomDisplayFields(), }} disabled={!canUpdateQrBottomDisplay(user)} />
                    <DeleteButton itemRef={ref} className="ml-1" disabled={!canUpdateQrBottomDisplay(user)} />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
