const { upperFirst, } = require('lodash');

const { lectureLevels, enLectureLevels, lectureAges, enLectureAges, } = require('./config');

const i18nField = (lang = 'ja', fieldName) => {
  return lang === 'ja' ? fieldName : lang + upperFirst(fieldName);
};

module.exports = {
  lectureLevels: {
    ja: lectureLevels,
    en: enLectureLevels,
  },
  lectureAges: {
    ja: lectureAges,
    en: enLectureAges,
  },
  i18nField,
};
