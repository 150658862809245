import React from 'react';
import { sortBy, isEmpty, uniq, omit, keyBy, maxBy, orderBy } from 'lodash';
import { format as formatDate, startOfDay, endOfDay, addMonths, addDays, } from 'date-fns';
import numeral from 'numeral';
import ellipsis from 'text-ellipsis';

import { statuses, fields as _fields, } from '../../shared/models/methodInquiry';
import { answerDisplayValue, } from '../../util';
import firebase from '../../firebase';
import { areaFromPostalCode } from '../../shared/models/setting';
import { fieldDisplayValue } from '../../shared/util';
import AdminPage from '../hocs/AdminPage';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import ExportButton from '../ExportButton';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { keys, entries } = Object;
const db = firebase.firestore();
const ordersRef = db.collection('orders');

export default AdminPage(function AdminMethodInquiries(props) {
  const tenant = useTenant();
  const { user } = props;
  const queryParams = useQueryParams();
  const {
    text = '',
    field: [field] = ['email'],
    picNames: picNamesForFilter,
    statuses: statusesForFilter,
    dateRange,
  } = queryParams;
  const startOn = queryParams.dateRange?.[0] ? new Date(queryParams.dateRange[0]) : addDays(addMonths(new Date(), -6), 1);
  const endOn = queryParams.dateRange?.[1] ? new Date(queryParams.dateRange[1]) : new Date();
  const areaSetting = useDocumentSubscription(db.collection('settings').doc([tenant.id, 'area'].join('__')), [tenant.id]);
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) => _.createdAt.toDate());
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productsById = keyBy(products, 'id');
  const fields = {
    ...omit(_fields({ productTypes, products }), ['destinationType']),
  };
  const methodInquiriesRef = text ? (
    db.collection('methodInquiries')
      .where(field, '>=', text)
      .where(field, '<=', text + '\uf8ff')
      .orderBy(field, 'asc')
  ) : (
    db.collection('methodInquiries').where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)).orderBy('createdAt', 'desc')
  );
  const methodInquiries = useCollectionSubscriptionInTenant(methodInquiriesRef, [text, ...[startOn, endOn].map(_ => formatDate(_, 'yyyy/MM/dd'))]);
  const questions = sortBy(useCollectionSubscriptionInTenant(db.collection('questions')), (_) => _.createdAt.toDate());
  const surveys = useCollectionSubscriptionInTenant(db.collection('surveys').where('surveyGroupId', 'in', ['methodInquiry', 'methodReinquiry']));
  const surveysById = keyBy(surveys, 'id');
  const answeredSurveys = uniq(methodInquiries.flatMap((methodInquiry) => {
    const product = productsById[methodInquiry.productId];
    return [product?.methodInquirySurveyId, product?.methodReinquirySurveyId].filter(_ => _);
  })).map(_ => surveysById[_]);
  const answeredQuestionIds = methodInquiries.map(_ => Object.keys(_.answers || {})).flat()
  const relatedQuestionIds = uniq([...answeredSurveys.flatMap(_ => _?.questionRows?.map(_ => _.questionId) || []), ...answeredQuestionIds].flat());
  const relatedQuestions = questions.filter(_ => relatedQuestionIds.includes(_.id));
  const lastComments = useCollectionsFetch(methodInquiries.map(_ => _.ref.collection('methodInquiryComments').orderBy('createdAt', 'desc').limit(1)), [methodInquiries]);
  const lastCommentsByMethodInquiryId = keyBy(lastComments, ({ ref }) => ref.parent.parent.id);
  const rows = methodInquiries.filter(_ => _.name).map((methodInquiry) => {
    const { postalCode, respondedBy, } = methodInquiry;
    const area = areaFromPostalCode(postalCode, areaSetting);
    const picName = respondedBy?.displayName || area?.user.displayName;
    const { body: lastComment = '' } = lastCommentsByMethodInquiryId[methodInquiry.id] || {};
    return {
      methodInquiry,
      area,
      picName,
    };
  });
  const picNameOptions = uniq(rows.map((_) => _.picName))
    .filter((_) => _)
    .map((_) => ({ label: _, value: _ }));
  const statusOptions = entries(statuses).map(([value, { label }]) => ({
    label,
    value,
  }));
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウントID', value: 'createdBy.uid' },
  ];

  // NOTE: filter
  let filteredRows = rows;
  if (!isEmpty(picNamesForFilter)) {
    filteredRows = filteredRows.filter((_) => picNamesForFilter?.includes(_.picName));
  }
  if (!isEmpty(statusesForFilter)) {
    filteredRows = filteredRows.filter((_) => statusesForFilter?.includes(_.methodInquiry.status));
  }

  const rowsForExport = () => {
    return filteredRows.map(({ methodInquiry, area, lastComment }) => {
      const {
        id,
        status,
        sourceOrder,
        createdAt,
        respondedBy,
      } = methodInquiry;
      return {
        id,
        status,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        sourceOrderedAt: sourceOrder != null && formatDate(sourceOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        areaStaff: area?.user.displayName,
        respondedBy: respondedBy?.displayName,
        ...(
          relatedQuestions.reduce((x, question) => {
            const { id, name, type, } = question;
            const answer = methodInquiry.answers?.[id];
            const value = ({
              checkbox: keys(answer || {}),
              imageFile: [answer].flat().map(_ => _?.url).join('\n'),
            })[type] || answer;
            return {
              ...x,
              [name]: value,
            };
          }, {})
        ),
        ...entries(fields).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            [fieldName]: fieldDisplayValue(methodInquiry[fieldName], fieldSettings),
          };
        }, {}),
      };
    });
  };

  return (
    <div>
      <div className="admin-method-inquiries container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>乗り方・使い方問合せ一覧</h4>
        </div>
        <div className="d-flex flex-wrap gap-3 mb-2">
          <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} disabled={!!text} />
          {
            /*
            <div className="d-flex flex-wrap align-items-end gap-1">
              <QuerySelector
                paramName="field"
                options={fieldOptions}
                label="検索フィールド"
                defaultValue={[field]}
                isClearable={false}
              />
              <QueryText paramName="text" label="検索テキスト" />
            </div>
            */
          }
        </div>
        <div className="d-flex flex-wrap gap-1 mb-3 align-items-end">
          <QuerySelector paramName="picNames" width={250} isMulti options={picNameOptions} label="担当で絞込み" />
          <QuerySelector paramName="statuses" width={250} isMulti options={statusOptions} label="状態で絞込み" />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <ExportButton
            className="ml-2"
            fileName="乗り方・使い方問合せ.csv"
            rows={rowsForExport}
            hasPersonalInfo
            user={user}
          />
        </div>
        <div className="overflow-auto">
          {rows.length > 0 ? (
            <table className="table table-bordered">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 150 }}>ID</th>
                  <th style={{ minWidth: 80 }}>状態</th>
                  <th style={{ minWidth: 150 }}>お問合せ日時</th>
                  <th style={{ minWidth: 150 }}>元受注日時</th>
                  <th style={{ minWidth: 150 }}>担当</th>
                  <th style={{ minWidth: 150 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>お名前</th>
                  <th style={{ minWidth: 150 }}>商品種別</th>
                  <th style={{ minWidth: 150 }}>商品</th>
                  <th style={{ minWidth: 150 }}>購入時期(年)</th>
                  <th style={{ minWidth: 150 }}>購入時期(月)</th>
                  {relatedQuestions.map((question) => {
                    const { id, name } = question;
                    return (
                      <th key={id} style={{ minWidth: 200, maxWidth: 300 }}>
                        {name}
                      </th>
                    );
                  })}
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 150 }}>郵便番号</th>
                  <th style={{ minWidth: 150 }}>番地・建物名</th>
                  <th style={{ minWidth: 400 }}>対応コメント</th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((_) => <MethodInquiryRow key={_.methodInquiry.id} {..._} fields={fields} relatedQuestions={relatedQuestions} />)}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
});

const MethodInquiryRow = ({ methodInquiry, picName, agent, agentShop, lastComment, fields, relatedQuestions }) => {
  const {
    id,
    createdAt,
    sourceOrder,
    status,
    createdBy,
    approvalStatus,
    approvedAt,
    approvalRequestComment,
    approvalOrRejectionComment,
    discountRequest,
    orderId,
  } = methodInquiry;
  const { label: statusLabel, color } = statuses[status] || {};
  const shipmentOrder = useDocumentSubscription(orderId && ordersRef.doc(orderId), [orderId]);
  return (
    <tr key={id}>
      <td>
        <TenantLink to={`/admin/methodInquiries/${id}`}>{id}</TenantLink>
      </td>
      <td>
        <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
      </td>
      <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
      <td>
        {sourceOrder != null && formatDate(sourceOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
      </td>
      <td>{picName}</td>
      {['prefecture', 'city', 'name', 'productTypeId', 'productId'].map((fieldName) => {
        const displayValue = fieldDisplayValue(methodInquiry[fieldName], fields[fieldName]);
        return (
          <td key={fieldName}>
            {fieldName === 'name' ? (
              <TenantLink to={`/admin/users/${createdBy.uid}`}>{displayValue}</TenantLink>
            ) : (
              ellipsis(displayValue, 80)
            )}
          </td>
        );
      })}
      {[
        'purchaseYear',
        'purchaseMonth',
      ].map((fieldName) => {
        return (
          <td key={fieldName}>
            {ellipsis(fieldDisplayValue(methodInquiry[fieldName], fields[fieldName]), 80)}
          </td>
        );
      })}
      {relatedQuestions.map((question) => {
        const { id, type } = question;
        const answer = methodInquiry.answers?.[id];
        return <td key={id}>{answerDisplayValue(answer)}</td>;
      })}
      {[
        'phone',
        'email',
        'postalCode',
        'address',
      ].map((fieldName) => {
        return (
          <td key={fieldName}>
            {ellipsis(fieldDisplayValue(methodInquiry[fieldName], fields[fieldName]), 80)}
          </td>
        );
      })}
      <td>{lastComment}</td>
    </tr>
  );
}
