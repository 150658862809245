const { isEmpty, omit, keyBy } = require('lodash');
const dedent = require('dedent');
const { supportMeans, reinquiryContacts } = require('../config');
const { fields } = require('./user');
const { fieldDisplayValue } = require('../util');
const { entries } = Object;
const { destinationFields } = require('./order');
const qs = require('qs');

const activityTypes = {
  startSupportOfSurveyAnswer: {
    text: (_) => '対応開始',
  },
  finishSupportOfSurveyAnswer: {
    text: (_) => '対応完了',
  },
  unfinishSupportOfSurveyAnswer: {
    text: (_) => '対応再開',
  },
  updatePicOfSurveyAnswer: {
    text: (_) => '担当変更',
  },
  updateSurveyAnswer: {
    text: (_) => '回答編集',
  },
  editSurveyAnswer: {
    text: (_) => 'パーツ編集',
  },
  createOrderOfSurveyAnswer: {
    text: (_) => '発送',
  },
  editDestinationOfSurveyAnswer: {
    text: (_) => '送り先変更',
  },
  sendSurveyAnswerSupportEmail: {
    text: (_) => 'メール送信',
  },
};

const statuses = {
  initial: { label: '未対応', color: 'warning' },
  supportRequired: { label: '要対応', color: 'danger' },
  supporting: { label: '対応中', color: 'info' },
  awaiting: { label: '待ち', color: 'success' },
  supported: { label: '対応完了', color: 'secondary' },
  expired: { label: '---', color: 'secondary' },
};

const supportSurveyMailBody = (surveyAnswer, url) => {
  const { name, createdBy } = surveyAnswer;
  return dedent`
    ${createdBy?.displayName || name} 様

    宜しければ以下のURLからアンケートへのご回答をお願いします。

    ${url}
  `;
}

const emailFields = () => {
  return {
    subject: {
      label: 'メール件名',
      type: 'string',
    },
    body: {
      label: 'メール本文',
      type: 'text',
    },
    files: {
      label: '添付ファイル',
      type: 'files',
    },
    reinquiryContact: {
      label: '再問合せ先',
      type: 'select',
      options: entries(reinquiryContacts).map(([k, v]) => ({ value: k, label: v.label })),
      validations: { required:  v => !isEmpty(v), },
      initialValue: 'inquiry',
    }
  };
};

const replyEmailBody = (surveyAnswer, body, host) => {
  const { createdBy, tenantId } = surveyAnswer;
  return dedent`
    ${createdBy?.displayName} 様

    お問合せへのメッセージが届いています。
    以下のURLからご確認ください。

    ${host || '{host}'}/mypage/surveyAnswers?messagesSurveyAnswerId=${surveyAnswer.id}



    ---------- お客様情報 ----------

    ${entries(omit(fields(), ['password', 'currentPassword', 'passwordConfirmation']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(createdBy[fieldName], fieldSettings)}`;
      })
      .join('\n')}
  `;
}

const itemFields = ({ products = [] } = {}) => {
  return {
    productId: {
      label: '商品',
      type: 'select',
      options: products.map((_) => ({ label: _.label || _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    quantity: {
      label: '数量',
      type: 'integer',
      validations: {
        greaterThan0: (_) => _ > 0,
      },
    },
  };
};

const shipmentFields = () => {
  return {
    shipmentSubject: {
      type: 'string',
      label: '発送メールの件名',
    },
    shipmentMessage: {
      type: 'text',
      label: '発送メールでのユーザーへのメッセージ',
      rows: 6,
    },
    shipmentPackageNote: {
      type: 'text',
      label: '発送時の梱包指示備考',
      hint: '最大111文字までで指定してください',
      validations: {
        maxLength: v => (v || '').length <= 111,
      },
    },
  };
};

const shipmentMailBody = (surveyAnswer, products, host) => {
  const {
    id,
    items,
    createdBy,
    shipmentMessage = '',
    orderId = '',
  } = surveyAnswer;
  const productsById = keyBy(products, 'id');
  const productOrPartText = items.some(({ productId }) => productsById[productId]?.isBody) ? '商品' : 'パーツ';

  return dedent`
    ${createdBy.displayName} 様

    ${shipmentMessage}

    ---------- 発送${productOrPartText} ----------

    [注文番号] ${orderId}

    [${productOrPartText}]
    ${items
      .map(({ productId, quantity }) => {
        const { name: productName } = productsById[productId];
        return `${productName} ${quantity}個`;
      })
      .join('\n')}

    ---------- お届け先情報 ----------

    ${entries(destinationFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(surveyAnswer[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    ---------- お客様情報 ----------

    ${entries(omit(fields(), ['password', 'currentPassword', 'passwordConfirmation']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(createdBy[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

module.exports = {
  adminFields: ({ userVoices = [], } = {}) => {
    const userVoiceOptions = userVoices.map(_ => ({ label: _.name, value: _.id }));
    return {
      adminNote: {
        label: 'メモ',
        type: 'text',
      },
      userVoiceIds: {
        label: 'ユーザーズボイス',
        type: 'multiSelect',
        options: userVoiceOptions,
      },
      userVoiceHeader: {
        label: 'ユーザーズボイス見出し',
        type: 'text',
      },
    };
  },
  commentFields: () => {
    return {
      body: {
        type: 'text',
        label: 'コメント内容',
        validations: {
          required: (_) => !isEmpty(_),
        },
      },
    };
  },
  supportContentsFields: ({ surveys = [] } = {}) => {
    return {
      supportMeans: {
        label: '対応方法',
        type: 'multiSelect',
        options: entries(supportMeans).map(([key, value]) => ({ label: value, value: key })),
        validations: {
          required: (_) => !isEmpty(_),
        },
      },
      supportSummary: {
        label: '対応内容',
        type: 'text',
        validations: {
          required: (_) => _,
        },
      },
      supportSurvey: {
        label: '対応完了後アンケート',
        type: 'select',
        options: surveys.map(_ => ({ label: _.title, value: _.id })),
        initialValue: '',
      },
      supportSurveySentDate: {
        label: '送信日時',
        type: 'datetime',
        validations: {
          laterThanNow: v => v > new Date()
        },
        hidden: _ => !_.supportSurvey,
      }
    };
  },
  activityTypes,
  statuses,
  supportSurveyMailBody,
  emailFields,
  replyEmailBody,
  itemFields,
  shipmentFields,
  shipmentMailBody
};
