import React, { useEffect, useState, useMemo, } from 'react';
import { Label, Button, Form, FormGroup, Input, Tooltip } from 'reactstrap';
import {
  omitBy,
  upperFirst,
  uniqWith,
  isEqual,
  get,
  omit,
  camelCase,
  pick,
  isEmpty,
  mapValues,
  keyBy,
  uniq,
} from 'lodash';
import { set } from 'lodash/fp';
import { useToggle, useAsync } from 'react-use';
import classnames from 'classnames';

import { toHankaku } from '../../shared/util';
import { fields, basicFields, ordererFields, } from '../../shared/models/rentalOrder';
import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import ChildForm from '../forms/ChildForm';
import ListForm from '../ListForm';
import Field from '../Field';
import SettingText from '../SettingText';
import AppButton from '../AppButton';
import poNumberImageSrc from '../../images/po_number.png';
import QuestionForm from './QuestionForm';
import { childFields } from '../../shared/models/user';
import { getAddressByPostalCode } from '../../util';

const { entries, keys, } = Object;
const checkEmailExistence = functions.httpsCallable('checkEmailExistence');

export default function RentalOrderBasicForm (props) {
  const { user, statedFields, rentalItems, rentalPrices, children, password, setChildren, updateChildAt, setPassword, onClickLogin, } = props;
  const _basicFields = set(
    'rentalItemId.options',
    basicFields({ rentalItems, rentalPrices, }).rentalItemId.options.map((_) => ({
      ..._,
      label: (
        <div className='d-flex small align-items-center gap-1'>
          <div>
            <img src={_.rentalItem.image} style={{ maxWidth: 100, maxHeight: 60 }} />
          </div>
          <div className='d-flex flex-column'>
            <div>{_.rentalItem.name}</div>
          </div>
        </div>
      ),
    })),
    basicFields({ rentalItems, rentalPrices, })
  );
  const userValues = user != null ? { name: user.displayName, ...user } : {};
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [extraValidationErrors, setExtraValidationErrors] = useState({});
  const validationErrorMessages = uniq([
    ...Object.values(statedFields)
      .filter((_) => !_.isValid)
      .map(({ label, validationErrors }) => {
        return `${label}にエラーがあります`;
      }),
    isEmpty(get(user, 'children', [])) && children.some((_) => !_.isValid) && 'お子様情報にエラーがあります',
    user == null && password.length < 6 && 'パスワードにエラーがあります',
    ...Object.keys(extraValidationErrors).map((_) => `${fields({ rentalItems, rentalPrices })[_].label}にエラーがあります`),
  ].filter((_) => _));
  const { value: isExistingEmail } = useAsync(async () => {
    if (!statedFields.email.isValid) return;

    const { data } = await checkEmailExistence({ email: statedFields.email.value });
    return data.exists;
  }, [statedFields.email.value]);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    statedFields.setStarts(keys(statedFields));
    if(validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    await props.onSubmit();
  };
  const handleChangePostalCode = async ({ fieldNames }, { target: { name, value } }) => {
    if (value?.length !== 7) return;

    try {
      const { prefecture, city, address1 } = await getAddressByPostalCode(value);
      setExtraValidationErrors(omit(extraValidationErrors, [name]));
      statedFields.setValues({
        [name]: value,
        [fieldNames.prefecture]: prefecture,
        [fieldNames.city]: city + address1,
      });
    } catch (e) {
      console.error(e);
      setExtraValidationErrors({ ...extraValidationErrors, [name]: ['existingPostalCode'] });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <SettingText name="rentalOrderPageHeaderHtml" renderContainer={(html, children) => !isEmpty(html) && <div className="border border-info rounded p-3 mb-3">{children}</div>} />
      <div>
        {user == null && (
          <div className='mt-4 card p-3'>
            <div className='mb-2'>すでにアカウントをお持ちの方はログインしてください</div>
            <Button color='primary' size='lg' block onClick={onClickLogin}>
              ログインする
            </Button>
          </div>
        )}
        <div className='mt-5'>
          <div className='mt-5'>
            <h4>レンタル注文内容</h4>
            <Field
              name='rentalItemId'
              {...statedFields.rentalItemId}
              {..._basicFields.rentalItemId}
              inputProps={{
                styles: {
                  control: (provided) => {
                    return { ...provided, height: '80px' };
                  },
                  valueContainer: (provided) => {
                    return { ...provided, height: '80px' };
                  },
                },
              }}
            />
            <Field name='rentalPriceId' {...statedFields.rentalPriceId} />
          </div>
          <div className='mt-5'>
            <h4>注文者情報</h4>
            {keys(ordererFields()).map((fieldName) => {
              const fieldSetting = statedFields[fieldName];
              if (fieldName === 'postalCode') {
                return (
                  <Field
                    key={fieldName}
                    name={fieldName}
                    documentName='rentalOrder'
                    {...fieldSetting}
                    {...(extraValidationErrors[fieldName] && {
                      validationErrors: [...fieldSetting.validationErrors, ...extraValidationErrors[fieldName]],
                    })}
                    readOnly={!isEmpty(userValues[fieldName]) ? (_) => true : fieldSetting.readOnly}
                    onChange={handleChangePostalCode.bind(null, {
                      fieldNames: { prefecture: 'prefecture', city: 'city' },
                    })}
                  />
                );
              } else {
                return (
                  <Field
                    key={fieldName}
                    name={fieldName}
                    documentName='rentalOrder'
                    {...fieldSetting}
                    {...['city', 'address'].includes(fieldName) && { inputProps: { onBlur: _ => fieldSetting.setValue(toHankaku(fieldSetting.value)), } }}
                    readOnly={!isEmpty(userValues[fieldName]) ? (_) => true : fieldSetting.readOnly}
                  />
                );
              }
            })}
          </div>
          {isEmpty(get(user, 'children', [])) && (
            <div className='mt-5'>
              <h4>お子様情報</h4>
              <div className='mb-2 card border-info text-info p-3 text-center font-weight-bold'>
                お子様の成長に合わせた
                <br className='d-sm-none' />
                サポートが受けられます。
              </div>
              <div>
                <ListForm
                  addButtonLabel='お子様を追加する'
                  items={children}
                  renderItem={(item, itemIndex) => {
                    return (
                      <div className='card p-3'>
                        <ChildForm
                          fields={childFields()}
                          index={itemIndex}
                          values={item}
                          onChange={(_) => updateChildAt(itemIndex, { ...item, ..._ })}
                          shouldStarts={hasSubmitted}
                        />
                      </div>
                    );
                  }}
                  onChange={setChildren}
                  minItems={0}
                />
              </div>
            </div>
          )}
        </div>
        {user == null &&
          (isExistingEmail ? (
            <div className='mt-4 card p-3'>
              <div className='alert alert-warning mb-3'>
                お客様のメールアドレスはすでにアカウント登録されています。
                <br />
                ログインしてください。
              </div>
              <Button color='primary' size='lg' block onClick={onClickLogin}>
                ログインする
              </Button>
            </div>
          ) : (
            <div className='mt-5'>
              <h4>アカウント登録</h4>
              <div className='alert alert-info'>
                30分で乗れる自転車教室の予約やへんしんバイクを購入後の乗り方サポート、メンテナンスサポートを受けられます。
              </div>
              <div>
                <FormGroup>
                  <Label>
                    パスワード
                    <span className='text-danger small'>【必須】</span>
                  </Label>
                  <Input
                    type='password'
                    value={password}
                    onChange={(_) => setPassword(_.target.value)}
                    minLength={6}
                  />
                  <small className='form-text text-muted'>6文字以上で指定してください</small>
                </FormGroup>
              </div>
            </div>
          ))}
        {!(user == null && isExistingEmail) && (
          <div className='d-flex mt-5'>
            <AppButton
              size='lg'
              color='primary'
              className='save flex-fill ml-2'
              type='submit'
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              <span
                className={classnames('fas mr-1', {
                  'fa-arrow-right': !isSubmitting,
                  'fa-spin fa-spinner': isSubmitting,
                })}
              />
              次へ
            </AppButton>
          </div>
        )}
      </div>
    </Form>
  );
}
