import React, { useState } from 'react';
import { format as formatDate } from 'date-fns';
import { Input } from 'reactstrap';
import numeral from 'numeral';

import firebase from '../firebase';
import AdminPage from './hocs/AdminPage';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useDependableState from './hooks/useDependableState';
import stamp from '../images/stamp.jpg';
import { totalPaymentAmount } from '../shared/models/order';

const db = firebase.firestore();
const ordersRef = db.collection('orders');

export default function Receipt (props) {
  const { orderId, readOnly = false, } = props;
  const order = useDocumentSubscription(ordersRef.doc(orderId), [orderId]);
  const [dateString = '', setDateString] = useDependableState(order && formatDate(order.createdAt.toDate(), 'yyyy/MM/dd'), [order]);
  const [name = '', setName] = useDependableState(order && order.name, [order]);
  const [note, setNote] = useState('但し、商品購入代金として (クレジットカード利用分）');

  return order != null && (
    <div>
      <div className="admin-order-receipt container py-5 position-relative d-flex justify-content-center">
        <div className="small card p-4" style={{ width: 720 }}>
          <div className="d-flex justify-content-end">
            {readOnly ? dateString : <Input value={dateString} onChange={_ => setDateString(_.target.value)} className="text-right" style={{ width: 200, fontSize: '12.8px' }} />}
          </div>
          <div className="mt-3 text-center large" style={{ letterSpacing: '2rem', }}>
            領収書
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <div className="d-flex justify-content-center align-items-end border-bottom pb-1" style={{ width: 400 }}>
              <div className="flex-fill text-center">
                {readOnly ? name : <Input value={name} onChange={_ => setName(_.target.value)} className="text-center" style={{ fontSize: '12.8px' }} />}
              </div>
              <div className="ml-2">
                様
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <div className="d-flex justify-content-center p-2" style={{ backgroundColor: '#eee', width: 400 }}>
              領収金額（税込）: &yen; {numeral(totalPaymentAmount(order)).format('0,0')} -
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-start">
            <div className="border-bottom pb-1" style={{ width: 640 }}>
              {readOnly ? note : <Input type="textarea" value={note} onChange={_ => setNote(_.target.value)} rows={1} style={{ fontSize: '12.8px' }} />}
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-start">
            上記金額を正に領収いたしました。
          </div>
          <div className="d-flex mt-5">
            <div className="flex-fill d-flex justify-content-center">
              <div>
                <div>
                〒154-0013
                </div>
                <div>
                  東京都世田谷区駒沢公園１－１　Tote駒沢公園２階
                </div>
                <div className="font-weight-bold large">
                  株式会社ビタミンアイファクトリー
                </div>
                <div>
                  登録番号：T7011101040217
                </div>
              </div>
            </div>
            <div>
              <img src={stamp} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
