import { DocumentReference, updateDoc, serverTimestamp } from '../../firebase';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { mapValues } from 'lodash';
import { toast } from 'react-toastify';
import Field from '../Field';
import { auditData } from '../../shared/models/user';
import { TroubleInquiry } from '../../types/troubleInquiry';
import { Inquiry } from 'src/types/inquiry';
import { User } from '../../types/user';
import { Fields } from 'types/field';
import useFormState from '../hooks/useFormState';

export const InquiryApprovalFormModal = ({
  inquiryRef,
  inquiry,
  user,
  isOpen,
  fields,
  onClickClose,
  onSubmit,
  onCancel,
}: {
  inquiryRef: DocumentReference<TroubleInquiry | Inquiry>;
  inquiry: TroubleInquiry | Inquiry;
  user: User;
  isOpen: boolean;
  fields: () => Fields;
  onClickClose: () => void;
  onSubmit?: (values: Partial<TroubleInquiry | Inquiry>) => Promise<void>;
  onCancel?: (values: Partial<TroubleInquiry | Inquiry>) => Promise<void>;
}) => {
  const statedFields = useFormState(inquiry, fields(), isOpen);
  const isUnsubmittable = Object.values(statedFields).some((_: any) => !_?.isValid);
  const handleSubmit = async () => {
    const { approvalOrRejectionComment } = mapValues(statedFields, 'value');
    if (!window.confirm('本当に承認しますか？')) return;

    try {
      const updatedData = {
        approvalStatus: 'approved',
        approvalOrRejectionComment,
        approvedBy: auditData(user),
        approvedAt: serverTimestamp(),
        updatedBy: auditData(user),
      };
      await updateDoc(inquiryRef, updatedData);

      onSubmit && (await onSubmit({ ...inquiry, ...updatedData }));
      toast.success('承認しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const handleCancel = async () => {
    const { approvalOrRejectionComment } = mapValues(statedFields, 'value');
    if (!window.confirm('本当に否認しますか？')) return;

    try {
      const updatedData = {
        approvalStatus: 'rejected',
        approvalOrRejectionComment,
        rejectedAt: serverTimestamp(),
        updatedBy: auditData(user),
      };
      await updateDoc(inquiryRef, updatedData);
      onCancel && (await onCancel({ ...inquiry, ...updatedData }));
      toast.success('否認しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>承認</ModalHeader>
        <ModalBody>
          {Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
            return <Field key={fieldName} name={fieldName} documentName="inquiry" {...(fieldSetting as Object)} />;
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={isUnsubmittable}>
            承認する
          </Button>
          <Button color="primary" outline onClick={handleCancel} disabled={isUnsubmittable}>
            否認する
          </Button>
          <Button onClick={onClickClose}>閉じる</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
