const { chunk, pick } = require('lodash');

const docToData = (doc, { withoutRef = false } = {}) => {
  return { ...pick(doc, ['id', 'exists', !withoutRef && 'ref']), ...doc.data() };
};

const getDocumentData = async (ref, { withoutRef = false, } = {}) => {
  return docToData(await ref.get(), { withoutRef });
};

const getCollectionData = async (ref, { withoutRef = false, } = {}) => {
  return (await ref.get()).docs.map(_ => docToData(_, { withoutRef }));
};

const batch = async (db, data, f, size = 500) => {
  return await chunk(data, size).reduce(async (x, data, i) => {
    const prevs = await x;

    const batch = db.batch();
    const refs = data.map((_, i2) => f(batch, _, i * size + i2));
    await batch.commit();
    return [...prevs, ...refs];
  }, Promise.resolve([]));
};

const getAllCollectionDataByChunk = async (_ref, limit = 10000, lastDoc = null, prevs = []) => {
  const ref = lastDoc != null ? _ref.startAfter(lastDoc) : _ref;
  const { docs } = await ref.limit(limit).get();
  const [nextLastDoc] = docs.slice(-1);
  const all = [...prevs, ...docs.map(docToData)];
  return nextLastDoc == null ? all : await getAllCollectionDataByChunk(ref, limit, nextLastDoc, all);
};

const processDataByChunk = async (_ref, f, limit = 10000, lastDoc = null) => {
  const ref = lastDoc != null ? _ref.startAfter(lastDoc) : _ref;
  const { docs } = await ref.limit(limit).get();
  const [nextLastDoc] = docs.slice(-1);
  await f(docs.map(docToData));
  if(nextLastDoc != null) {
    await processDataByChunk(_ref, f, limit, nextLastDoc);
  }
};

module.exports = {
  docToData,
  getDocumentData,
  getCollectionData,
  getAllCollectionDataByChunk,
  processDataByChunk,
  batch,
};
