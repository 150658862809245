import { useEffect, useRef } from 'react';
import qrcode from 'qrcode';
import { v4 as uuid } from 'uuid';

import firebase from '../firebase';
import useTenant from './hooks/useTenant';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default function EventQrcode({ event, forTheDay = true, }) {
  const canvasEl = useRef(null);
  const tenant = useTenant();
  const url = event && (
    forTheDay ? (event.theDayKey && `${window.location.origin}/${tenant?.id}/events/${event.id}?key=${event.theDayKey}`)
    : `${window.location.origin}/${tenant.id}/events/${event.id}`
  );

  useEffect(() => {
    if (canvasEl.current && event && (!forTheDay || event.theDayKey)) {
      const size = 350;
      const fontSize = 20;
      qrcode.toCanvas(canvasEl.current, url, { width: size, height: size, errorCorrectionLevel: 'H' });
      if (!event.textForQr) return;

      const text = event.textForQr;
      const ctx = canvasEl.current.getContext('2d');
      ctx.font = `${fontSize}px sans-serif`;
      const { width: textWidth } = ctx.measureText(text);
      const paddingSize = 5;
      const rectWidth = textWidth + paddingSize * 2;
      const textHeight = fontSize;
      const rectHeight = textHeight + paddingSize * 2
      ctx.fillStyle = 'white';
      ctx.fillRect(size / 2 - rectWidth / 2, size / 2 - rectHeight / 2, rectWidth, rectHeight);
      ctx.fillStyle = 'black';
      ctx.fillText(text, size / 2 - textWidth / 2, size / 2 + textHeight / 2 - 3);
    }
  }, [canvasEl, url, forTheDay]);

  useEffect(() => {
    if (event && !event.theDayKey) eventsRef.doc(event.id).update({ theDayKey: uuid() });
  }, [event]);

  return <canvas title={url} ref={canvasEl} />;
}
