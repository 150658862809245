import React from 'react';
import { format as formatDate } from 'date-fns';
import { orderBy } from 'lodash';
import ja from 'date-fns/locale/ja';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router';
import PriceDisplay from './PriceDisplay';
import { lectureLevels, lectureAges } from '../shared/config';

export default function LectureSelector({ lectureId, eventProductTypesById, lectureTypesById, lectures }) {
  const history = useHistory();
  const location = useLocation();
  const options = orderBy(lectures, ({ date }) => date.toDate(), 'desc').map(
    ({ id, productType, lectureType, lectureLevel, lectureAge, date, price, isUserFree = false }) => {
      return {
        label: (
          <span>
            {date && formatDate(date.toDate(), 'yyyy/MM/dd (iii)', { locale: ja })}{' '}
            {eventProductTypesById[productType]?.name} {lectureTypesById[lectureType]?.name}{' '}
            {lectureLevel === 'none' ? '' : lectureLevels[lectureLevel]}{' '}
            {lectureAge === '0-999' ? '' : lectureAges[lectureAge]}{' '}
            <PriceDisplay price={price} isUserFree={isUserFree} />
          </span>
        ),
        value: id,
      };
    }
  );
  const onSelect = (selected) => {
    history.push([...location.pathname.split('/').slice(0, -1), selected.value].join('/'));
  };
  return (
    <div style={{ width: 800 }}>
      <Select
        value={options.filter((_) => _.value === lectureId)}
        options={options}
        onChange={onSelect}
        className="w-100"
      />
    </div>
  );
}
