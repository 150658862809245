import React, { useEffect } from 'react';
import { Button, Form, Input, FormGroup, Label, } from 'reactstrap';
import { upperFirst, camelCase, mapValues, pick, isEmpty, keyBy, } from 'lodash';
import { useAsync } from 'react-use';

import { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { ordererFields, destinationFields, passwordFields } from '../../shared/models/troubleInquiry';
import Field from '../Field';
import AppButton from '../AppButton';

const { keys, entries, } = Object;
const prefecturesByPref = mapValues(keyBy(entries(prefectures), '1'), '0');
const checkEmailExistence = functions.httpsCallable('checkEmailExistence');

export default function TroubleInquiryDeliveryForm (props) {
  const { user, statedFields, onCancel, wholesaleAgent, wholesaleAgentShop, toggleLoginForm } = props;
  const userValues = user != null ? { name: user.displayName, ...user } : {};
  const validationErrorMessages = Object.values(pick(statedFields, keys({ ...ordererFields(), ...(statedFields.destinationType.value === 'other' && destinationFields()), ...(!user && passwordFields()), })))
    .filter(_ => !_.isValid)
    .map(({ label, validationErrors }) => {
      return `${label}にエラーがあります`;
    });
  const { value: isExistingEmail } = useAsync(async () => {
    if (!statedFields.email.isValid) return;
  
    const { data } = await checkEmailExistence({ email: statedFields.email.value });
    return data.exists;
  }, [statedFields.email.value]);
  const onSubmit = async (event) => {
    event.preventDefault();
    statedFields.startAll();
    if(validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    if(statedFields.destinationType.value === 'same') {
      statedFields.setValues(
        keys(destinationFields()).reduce((x, y) => {
          return {
            ...x,
            [y]: statedFields[camelCase(y.replace(/^destination/, ''))].value,
          };
        }, {})
      );
    }
    await props.onSubmit();
  };
  const onClickSearchAddress = async (postalCode, { fieldNames = ['prefecture', 'city'] } = {}) => {
    const { results } = await (await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`)).json();
    if(isEmpty(results)) return;

    const [{ address1, address2, address3 }] = results;
    statedFields.setValues({
      [fieldNames[0]]: prefecturesByPref[address1],
      [fieldNames[1]]: address2 + address3,
    });
  };
  useEffect(() => {
    if (statedFields.destinationType.value === 'shop') {
      statedFields.setValues(
        ['name', 'nameKana', 'phone', 'postalCode', 'prefecture', 'city', 'address'].reduce((x, k) => {
          return {
            ...x,
            [`destination${upperFirst(k)}`]: wholesaleAgentShop[k],
          };
        }, {})
      );
    }
  }, [statedFields.destinationType.value]);

  return (
    <Form onSubmit={onSubmit}>
      <div>
        {user == null && (
          <div className='mt-4 card p-3 mb-3'>
            <div className='mb-2'>すでにアカウントをお持ちの方はログインしてください</div>
            <Button color='primary' size='lg' block onClick={toggleLoginForm}>
              ログインする
            </Button>
          </div>
        )}
        <h4>お客様情報</h4>
        {
          keys(ordererFields()).map((fieldName) => {
            const fieldSetting = statedFields[fieldName];
            if(fieldName === 'postalCode') {
              return (
                <div className="d-flex align-items-start">
                  <div className="flex-fill">
                    <Field
                      key={fieldName}
                      name={fieldName}
                      documentName="order"
                      {...fieldSetting}
                      readOnly={!isEmpty(userValues[fieldName]) ? _ => true : fieldSetting.readOnly}
                    />
                  </div>
                  <div className="ml-2 mt-3 pt-3">
                    <Button onClick={onClickSearchAddress.bind(null, fieldSetting.value)}>
                      <span className="fas fa-search mr-1" />
                      郵便番号で住所検索
                    </Button>
                  </div>
                </div>
              );
            } else {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  documentName="order"
                  {...fieldSetting}
                  readOnly={!isEmpty(userValues[fieldName]) ? _ => true : fieldSetting.readOnly}
                />
              );
            }
          })
        }
      </div>
      <div className="mt-5">
        <h4>お届け先情報</h4>
        <div className="mb-3 text-muted">
          代替品、パーツのお届け先を記入してください。
        </div>
        <div>
          <FormGroup tag="fieldset">
            <FormGroup check>
              <Label check>
                <Input checked={statedFields.destinationType.value === 'same'} type="radio" onChange={_ => statedFields.destinationType.setValue('same')} /> お客様の住所にお届けする
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input checked={statedFields.destinationType.value === 'other'} type="radio" onChange={_ => statedFields.destinationType.setValue('other')} /> 新しい届け先を入力する
              </Label>
            </FormGroup>
            {wholesaleAgentShop != null && (
              <FormGroup check>
                <Label check>
                  <Input
                    checked={statedFields.destinationType.value === 'shop'} type='radio' onChange={(_) => statedFields.destinationType.setValue('shop')} /> 店舗に送る
                </Label>
              </FormGroup>
            )}
          </FormGroup>
        </div>
        {
          ['other', 'shop'].includes(statedFields.destinationType.value) && keys(destinationFields()).map((fieldName) => {
            const fieldSetting = statedFields[fieldName];
            if(fieldName === 'destinationPostalCode') {
              return (
                <div className="d-flex align-items-start">
                  <div className="flex-fill">
                    <Field
                      key={fieldName}
                      name={fieldName}
                      documentName="order"
                      {...fieldSetting}
                    />
                  </div>
                  <div className="ml-2 mt-3 pt-3">
                    <Button onClick={onClickSearchAddress.bind(null, fieldSetting.value, { fieldNames: ['destinationPrefecture', 'destinationCity'] })}>
                      <span className="fas fa-search mr-1" />
                      郵便番号で住所検索
                    </Button>
                  </div>
                </div>
              );
            } else {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  documentName="order"
                  {...fieldSetting}
                />
              );
            }
          })
        }
      </div>
      {user == null &&
        (isExistingEmail ? (
          <div className='mt-4 card p-3'>
            <div className='alert alert-warning mb-3'>
              お客様のメールアドレスはすでにアカウント登録されています。
              <br />
              ログインしてください。
            </div>
            <Button color='primary' size='lg' block onClick={toggleLoginForm}>
              ログインする
            </Button>
          </div>
        ) : (
          <div className='mt-5'>
            <h4>アカウント登録</h4>
            <div className='alert alert-info'>
              30分で乗れる自転車教室の予約やへんしんバイクを購入後の乗り方サポート、メンテナンスサポートを受けられます。
            </div>
            {
              keys(passwordFields()).map((fieldName) => {
                const fieldSetting = statedFields[fieldName];
                return (
                  <Field
                    key={fieldName}
                    name={fieldName}
                    documentName="order"
                    {...fieldSetting}
                  />
                )
              })
            }      
          </div>
        ))}
      <div className="d-flex mt-5">
        <AppButton size="lg" className="cancel flex-fill" color="secondary" onClick={onCancel}>
          <span className="fas fa-arrow-left mr-1" />
          戻る
        </AppButton>
        <AppButton size="lg" className="save flex-fill ml-2" type="submit" color="primary" onClick={onSubmit} disabled={user == null && isExistingEmail}>
          <span className="fas fa-arrow-right mr-1" />
          次へ
        </AppButton>
      </div>
    </Form>
  );
};
