import React from 'react';
import { Input, Button, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import TenantLink from './TenantLink';

export default function AgentHeader (props) {
  const { activeTab, agent, canEditMembers, } = props;
  const url = `${window.location.origin}/agents/${agent.id}`;
  const tabs = [
    { name: 'shops', label: '店舗', path: '/shops' },
    ...(agent.usesRental ? [{ name: 'rentalItems', label: 'レンタル商品', path: '/rentalItems', }] : []),
    ...(agent.usesRental ? [{ name: 'rentalOrders', label: 'レンタル注文', path: '/rentalOrders', }] : []),
    ...(agent.usesReferral !== false ? [{ name: 'referralOrders', label: 'リファラ注文', path: '/referralOrders' }] : []),
    ...((agent.usesWholesale || agent.usesPartsWholesale) ? [{ name: 'tmpOrders', label: '卸仮注文', path: '/tmpOrders' }] : []),
    ...((agent.usesWholesale || agent.usesPartsWholesale) ? [{ name: 'wholesaleOrders', label: '卸注文', path: '/wholesaleOrders' }] : []),
    ...(canEditMembers ? [{ name: 'members', label: 'メンバー', path: '/members' }] : []),
  ];
  return (
    <div>
      <div className="d-flex justify-content-center mb-3">
        <h4>{agent.name}</h4>
      </div>
      <Nav tabs>
        {
          tabs.map(({ name, label, path }) => {
            return (
              <NavItem key={name}>
                <NavLink
                  tag={TenantLink}
                  className={classnames({ active: activeTab === name })}
                  to={`/agents/${agent.id}${path}`}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })
        }
      </Nav>
    </div>
  );
};
