import { useTitle } from 'react-use';

import useTenant from './useTenant';

export default function useAppTitle(pageTitle = '', prefix) {
  const tenant = useTenant();
  const title = (pageTitle ? `${pageTitle} - ` : '') + (prefix ?? 'VSM');

  return useTitle(process.env.NODE_ENV === 'production' ? title : `[DEVELOPMENT] ${title}`, {
    restoreOnUnmount: false,
  });
}
