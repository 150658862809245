import React, { useEffect, useRef, } from 'react';
import qrcode from 'qrcode';
import { Button } from 'reactstrap';
import { downloadCanvasImage } from '../util';

import useTenant from './hooks/useTenant';
import { drawQr } from '../qr';

export default function QrCode(props) {
  const { url, topText, centerText, bottomText, } = props;
  const canvasEl = useRef(null);
  useEffect(() => {
    if(canvasEl.current) {
      drawQr(canvasEl.current, url, topText, centerText, bottomText);
    }
  }, [canvasEl, url, topText, centerText, bottomText]);

  const handleQrImageDownload = () => {
    downloadCanvasImage(canvasEl.current, `${centerText || 'qrcode'}.jpg`);
  }

  return <div>
    <canvas ref={canvasEl} />
    <div className="d-flex w-100 justify-content-end">
      <Button color="secondary" onClick={handleQrImageDownload}><span className="fas fa-download mr-1" />画像ダウンロード</Button>
    </div>
  </div>;
}
