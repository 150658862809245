const { isEmpty } = require('lodash');

module.exports = {
  fields: () => {
    return {
      name: {
        label: 'グループ名称',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
    };
  },
};
