import React from 'react';
import { Button } from 'reactstrap';
import { isString, sortBy, keyBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import numeral from 'numeral';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdateContentProduct } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import TenantLink from '../TenantLink';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';
import { fields } from '../../shared/models/contentProduct';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();
const contentProductsRef = db.collection('contentProducts');

export default AdminPage(function AdminContentProducts (props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const contentProducts = useCollectionSubscriptionInTenant(contentProductsRef);

  let filteredContentProducts = contentProducts;
  if (queryParams.showsHidden !== '1') {
    filteredContentProducts = filteredContentProducts.filter(_ => !_.isHidden);
  }

  const sortedContentProducts = sortBy(filteredContentProducts, 'code');
  const processValues = async (values) => {
    const { id, } = values;

    const [image, file] = await Promise.all([['image', values.image], ['file', values.file]].map(async ([key, file]) => {;
      if(file == null) return null;
      if(file.url) return file;

      const path = `contentProducts/${id}/${key}`;
      const fileRef = storageRef.child(path);
      await fileRef.put(file, { cacheControl: 'public,max-age=10800', contentType: file.type });
      return { type: file.type, path, url: await fileRef.getDownloadURL() };
    }));
    return { ...values, image, file, };
  };

  return (
    <div>
      <div className="admin-contentProducts container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>コンテンツ商品一覧</h4>
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton
            itemRef={(_) => contentProductsRef.doc(_.code)}
            FormModal={ModelFormModal}
            formProps={{ title: 'コンテンツ商品追加', fields, }}
            disabled={!canUpdateContentProduct(user)}
            processValues={processValues}
          />
        </div>
        <div>
          {
            sortedContentProducts.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>商品コード</th>
                    <th>商品名</th>
                    <th>画像</th>
                    <th>価格</th>
                    <th>期限</th>
                    <th>メモ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sortedContentProducts.map((contentProduct) => {
                      const { id, ref, code, name, image, price, expirationDays, note, isHidden = false, } = contentProduct;
                      const beforeDelete = async () => {
                      };

                      return (
                        <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                          <td>
                            {code}
                          </td>
                          <td>
                            {name}
                          </td>
                          <td>
                            <img src={image?.url} style={{ maxHeight: 50 }} />
                          </td>
                          <td className="text-right">
                            {numeral(price).format()}
                          </td>
                          <td className="text-right">
                            {numeral(expirationDays).format()}
                          </td>
                          <td style={{ whiteSpace: 'pre-line' }}>
                            {note}
                          </td>
                          <td className="text-nowrap text-right">
                            <Button target="_blank" tag={TenantLink} to={`/contentProducts/${id}`}>プレビュー <span className="ml-1 fas fa-external-link-alt" /></Button>
                            <EditButton itemRef={ref} className="ml-1" FormModal={ModelFormModal} formProps={{ title: 'コンテンツ商品編集', fields: omit(fields(), ['code']), }} disabled={!canUpdateContentProduct(user)} beforeSubmit={processValues} />
                            <DeleteButton itemRef={ref} className="ml-1" disabled={!canUpdateContentProduct(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                コンテンツ商品は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
