import React, { Fragment, useEffect, } from 'react';
import { Button, Form, } from 'reactstrap';
import { keyBy, mapValues, groupBy } from 'lodash';
import { set, } from 'lodash/fp';
import { useToggle, useMap, } from 'react-use';

import { productRowFields, qrUrlFields, } from '../../shared/models/agent';
import Field from '../Field';
import SettingText from '../SettingText';
import AppButton from '../AppButton';
import poNumberImageSrc from '../../images/po_number.png';
import useFormState from '../hooks/useFormState';
import ListForm from '../ListForm';
import RichTextContent from '../RichTextContent';

const { entries, keys, } = Object;

export default function AgentRegisterForm (props) {
  const { nonPartProducts, qrUrls, coupons, productRows, setProductRows, updateProductRowAt, showingQrUrlRows, setShowingQrUrlRows, updateShowingQrUrlRowAt, statedFields, } = props;
  const couponsById = keyBy(coupons, 'id');
  const validationErrorMessages = [
    ...Object.values(statedFields)
      .filter(_ => !_.isValid)
      .map(({ label, validationErrors }) => {
        return `${label}にエラーがあります`;
      }),
    productRows.some(_ => !_.isValid) && '取扱商品にエラーがあります',
    showingQrUrlRows.some(_ => !_.isValid) && '紹介QRコード遷移先にエラーがあります',
    Object.values(groupBy(productRows, 'productId')).some(_ => _.length >= 2) && '選択した商品が重複しています',
    Object.values(groupBy(showingQrUrlRows, 'qrUrlId')).some(_ => _.length >= 2) && '選択した紹介QRコード遷移先が重複しています',
  ].filter(_ => _);
  const onSubmit = async (event) => {
    event.preventDefault();
    statedFields.setStarts(keys(statedFields));
    if(validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    await props.onSubmit();
  };
  const showingQrUrlRowFields = set('couponIds.hint', ({ couponIds, }) => {
    return couponIds?.map((couponId) => {
      const coupon = couponsById[couponId];
      return (
        <div className="mb-1 card p-2 text-body" key={couponId}>
          <h6>メモ</h6>
          <div style={{ whiteSpace: 'pre-line' }}>{coupon.note}</div>
          <h6 className="mt-2">説明</h6>
          <RichTextContent html={coupon.description} />
        </div>
      );
    });
  }, qrUrlFields({ qrUrls, coupons }));

  return (
    <Form onSubmit={onSubmit}>
      <div className="mt-3">
        <div className="my-3">
          <div className="card p-3">
            <h5>取扱商品</h5>
            <div className="text-muted small mb-4">
              <div>ここで選択されたもののみが表示されるように調整されます。</div>
              <div>例) 商品マスタにて非表示のものを選択する -> 強制表示</div>
              <div>例) 商品マスタにて表示のものを選択せず -> 強制非表示</div>
            </div>
            <ListForm
              items={productRows}
              renderItem={(item, itemIndex) => {
                return (
                  <div>
                    <div className="card p-3">
                      <ProductRowForm
                        rows={productRows}
                        index={itemIndex}
                        values={item}
                        nonPartProducts={nonPartProducts}
                        onChange={(_) => updateProductRowAt(itemIndex, { ...item, ..._ })}
                      />
                    </div>
                  </div>
                );
              }}
              onChange={(_) => setProductRows(_)}
              minItems={0}
            />
          </div>
          <div className="mt-3 card p-3">
            <h5>紹介QRコード遷移先</h5>
            <ListForm
              items={showingQrUrlRows}
              renderItem={(showingQrUrlRow, showingQrUrlRowIndex) => {
                return (
                  <div className="card p-3">
                    <ShowingQrUrlRowForm
                      fields={showingQrUrlRowFields}
                      index={showingQrUrlRowIndex}
                      values={showingQrUrlRow}
                      onChange={_ => updateShowingQrUrlRowAt(showingQrUrlRowIndex, { ...showingQrUrlRow, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setShowingQrUrlRows}
              minItems={0}
            />
          </div>
        </div>
        <div>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => {

              return (
                <Fragment>
                  <Field
                    key={fieldName}
                    name={fieldName}
                    documentName="agentRegister"
                    {...fieldSetting}
                  />
                </Fragment>
              );
            })
          }
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5">
        <Button className="save" type="submit" color="primary" onClick={onSubmit}>
          <span className="fas fa-arrow-right mr-1" />
          次へ
        </Button>
      </div>
    </Form>
  );
};

function ProductRowForm(props) {
  const { nonPartProducts, ruleType, values, onChange } = props;
  const statedFields = useFormState(values, productRowFields({ nonPartProducts, }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every((_) => _.isValid),
    });
  }, [...Object.values(statedFields).map((_) => _.value)]);

  return (
    <div className="d-flex">
      <div style={{ width: 300 }}>
        <Field
          name="productId"
          {...statedFields.productId}
          inputProps={{ components: { IndicatorsContainer: (_) => null } }}
        />
      </div>
      <div className="ml-2" style={{ width: 250 }}>
        <Field
          name="referralFeeType"
          {...statedFields.referralFeeType}
        />
      </div>
      <div className="ml-2" style={{ width: 200 }}>
        <Field
          name="referralFeeAmount"
          {...statedFields.referralFeeAmount}
        />
        <Field
          name="referralFeeRate"
          {...statedFields.referralFeeRate}
        />
      </div>
    </div>
  );
}

function ShowingQrUrlRowForm(props) {
  const { fields, values, onChange, } = props;
  const statedFields = useFormState(values, fields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
    statedFields.startAll();
  }, [...Object.values(statedFields).map(_ => _.value)]);

  return (
    <div>
      <div className="d-flex gap-1">
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div style={{ minWidth: 300 }}>
              <Field
                key={fieldName}
                name={fieldName}
                values={mapValues(statedFields, 'value')}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
