import qrcode from 'qrcode';

export const drawQr = (canvas, url, topText, centerText, bottomText, size = 350) => {
  const yPadding = 25;
  const fontSize = 20;
  qrcode.toCanvas(canvas, url, { margin: 6, width: size, height: size });
  const ctx = canvas.getContext('2d');
  ctx.font = `${fontSize}px sans-serif`;
  const { width: centerTextWidth } = ctx.measureText(centerText);
  const paddingSize = 5;
  const rectWidth = centerTextWidth + paddingSize * 2;
  const textHeight = fontSize;
  const rectHeight = textHeight + paddingSize * 2
  ctx.fillStyle = 'white';
  centerText && ctx.fillRect(size / 2 - rectWidth / 2, size / 2 - rectHeight / 2, rectWidth, rectHeight);
  ctx.fillStyle = 'black';
  centerText && ctx.fillText(centerText, size / 2 - centerTextWidth / 2, size / 2 + textHeight / 2 - 3);
  topText && ctx.fillText(topText, 32, 20);
  const { width: bottomTextWidth } = ctx.measureText(bottomText);
  bottomText && ctx.fillText(bottomText, size / 2 - bottomTextWidth / 2, size - 6);
};
