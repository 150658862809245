import React, { useEffect, useRef } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import { sortBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { Input, Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync, } from 'react-use';
import { v4 as uuid } from 'uuid';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { beforeDelete, } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentShopHeader from '../AgentShopHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/agentShop';
import QueryDateSelector from '../QueryDateSelector';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';
import TenantLink from '../TenantLink';

const { entries } = Object;
const db = firebase.firestore();
const ordersRef = db.collection('orders');
const productsRef = db.collection('products');
const checkReferrerOrderExistence = functions.httpsCallable('checkReferrerOrderExistence');
const qrBottomDisplaysRef = db.collection('qrBottomDisplays');

export default AgentPage(function AgentShop (props) {
  const { tenant, user, agent, match: { params: { agentShopId } }, setBreadNavValues, canReadAllShops, memberData, } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const agentShop = useDocumentSubscription((canReadAllShops || memberData.shopIds.includes(agentShopId)) && agent.ref.collection('agentShops').doc(agentShopId), [agent, agentShopId]);

  useEffect(() => {
    setBreadNavValues({ agent, agentShop, });
  }, [agent, agentShop]);

  return agentShop != null && (
    <div>
      <div className="agent-shop container-fluid py-5 position-relative">
        <AgentShopHeader activeTab="general" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end mb-3">
                <EditButton color="primary" itemRef={agentShop.ref} FormModal={ModelFormModal} formProps={{ documentName: 'agentShop', title: '編集', fields, }} disabled={!canUpdateAgent(user)} />
              </div>
              <table className="table table-bordered align-middle thead-light">
                <tbody className="thead-light">
                  {
                    entries(fields()).map(([fieldName, fieldSettings]) => {
                      const { label, type } = fieldSettings;
                      const value = fieldDisplayValue(agentShop[fieldName], fieldSettings);
                      return (
                        <tr>
                          <th style={{ width: 250 }}>
                            {label}
                          </th>
                          <td>
                            {value}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
