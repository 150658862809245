import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { omit, isEmpty, sumBy, get, keyBy } from 'lodash';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';

import { ordererFields, } from '../../shared/models/rentalOrder';
import { computeDiscountAmount, isShipmentFree } from '../../shared/models/coupon';
import { fieldDisplayValue } from '../../shared/util';
import RentalOrderFlowSection from '../RentalOrderFlowSection';
import AppButton from '../AppButton';
import SettingText from '../SettingText';
import AppCardElement from '../AppCardElement';
import env from '../../env';

const { entries } = Object;

export default function NewRentalOrderConfirmFormApp(props) {
  return (
    <StripeProvider apiKey={env('STRIPE_API_KEY')}>
      <FormContainer {...props} />
    </StripeProvider>
  );
}

function FormContainer(props) {
  return (
    <Elements>
      <InjectedNewRentalOrderForm {...props} />
    </Elements>
  );
}

function NewRentalOrderConfirmForm(props) {
  const {
    rentalItems = [],
    rentalPrices = [],
    contentProductIds,
    contentProducts,
    statedFields,
    children,
    stripe,
    onClickBack,
    user,
  } = props;
  const rentalItemsById = keyBy(rentalItems, 'id');
  const rentalPricesById = keyBy(rentalPrices, 'id');
  const contentProductsById = keyBy(contentProducts, 'id');
  const rentalItem = rentalItemsById[statedFields.rentalItemId.value];
  const rentalPrice = rentalPricesById[statedFields.rentalPriceId.value];
  const rows = [{ rentalItem, rentalPrice }];
  const amount = sumBy(rows, _ => _.rentalPrice?.[`price__${_.rentalItem.id}`]);
  const contentAmount = sumBy(contentProductIds, _ => contentProductsById[_]?.price);
  const [isSubmitting, toggleSubmitting] = useToggle();
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    toggleSubmitting(true);
    try {
      const { token } = await stripe.createToken({ type: 'card' });
      await props.onSubmit({
        stripeToken: token,
        amount,
        contentAmount,
      });
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div>
          <div>
            <div className="card border-danger text-danger p-3 text-center font-weight-bold">
              注文はまだ完了していません
            </div>
            <h4 className="mt-4 h5 text-center font-weight-bold">注文内容をご確認ください</h4>
          </div>
          <div className="mt-5">
            <h4>レンタル注文の内容</h4>
            <div className="text-muted small">
              ※ レンタル開始時に決済されます。
            </div>
            <table className="table table-bordered">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th>商品</th>
                  <th>時間</th>
                  <th>金額(税込)</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => {
                  const { rentalItem, rentalPrice, } = row;

                  return (
                    <tr>
                      <td>
                        {rentalItem.name}
                      </td>
                      <td className="text-right">
                        {numeral(rentalPrice?.hours).format('0,0')}
                      </td>
                      <td className="text-right">
                        {numeral(rentalPrice?.[`price__${rentalItem?.id}`]).format('0,0')}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2} className="text-right">
                    合計
                  </td>
                  <td className="text-right">{numeral(amount).format('0,0')}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {
            !isEmpty(contentProductIds) && (
              <div className="mt-5">
                <h4>コンテンツ注文の内容</h4>
                <div className="text-muted small">
                  ※ 注文を確定すると決済されます。
                </div>
                <table className="table table-bordered">
                  <thead className="thead-light text-center text-nowrap">
                    <tr>
                      <th>商品</th>
                      <th>金額(税込)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contentProductIds.map((contentProductId) => {
                      const contentProduct = contentProductsById[contentProductId];

                      return (
                        <tr>
                          <td>
                            <div className="d-flex gap-2">
                              <div>
                                <img src={contentProduct?.image?.url} style={{ maxHeight: 40 }} />
                              </div>
                              {contentProduct?.name}
                            </div>
                          </td>
                          <td className="text-right">
                            {numeral(contentProduct?.price).format()}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="text-right">
                        合計
                      </td>
                      <td className="text-right">{numeral(contentAmount).format('0,0')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
          <div className="mt-5">
            <h4>注文者情報</h4>
            <table className="table table-bordered">
              <tbody>
                {entries(ordererFields()).map(([fieldName, fieldSettings]) => {
                  const { label } = fieldSettings;
                  const value = fieldDisplayValue(statedFields[fieldName].value, fieldSettings);
                  return (
                    <tr>
                      <th>{label}</th>
                      <td>{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isEmpty(get(user, 'children', [])) && (
            <div className="mt-5">
              <h4>お子様情報</h4>
              {children.length > 0
                ? children.map((child, i) => {
                    const { name, birthday, vehicleExperiences = [] } = child;
                    return (
                      <div className="mb-3 bg-grey rounded p-3 flex-fill" key={i}>
                        <div className="large font-weight-bold mb-3">{name}</div>
                        <div>{formatDate(birthday, 'yyyy年MM月dd日生まれ')}</div>
                        <div>
                          {vehicleExperiences.map((_) => (
                            <div className="badge bg-white mr-1">{_}</div>
                          ))}
                        </div>
                      </div>
                    );
                  })
                : 'なし'}
            </div>
          )}
          <div className="mt-4">
            <div className="mt-3 text-center">お支払い情報を入力してください</div>
            <FormGroup className="mt-3">
              <Label>
                クレジットカード
                <span className="text-danger small">【必須】</span>
              </Label>
              <AppCardElement />
              <small className="form-text text-muted">
                CVCはセキュリティコードです。署名欄の３桁など、カード会社所定のコードを入力して下さい。
              </small>
            </FormGroup>
          </div>
        </div>
        <div className="d-flex mt-5">
          <AppButton
            size="lg"
            className="cancel flex-fill"
            color="secondary"
            onClick={onClickBack}
            disabled={isSubmitting}
          >
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton
            size="lg"
            color="primary"
            className="save flex-fill ml-2"
            type="submit"
            color="primary"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            <span
              className={classnames('fas mr-1', {
                'fa-arrow-right': !isSubmitting,
                'fa-spin fa-spinner': isSubmitting,
              })}
            />
            注文を確定する
          </AppButton>
        </div>
      </section>
    </Form>
  );
}

const InjectedNewRentalOrderForm = injectStripe(NewRentalOrderConfirmForm);
