const { keyBy, isEmpty, } = require('lodash');
const numeral = require('numeral');

module.exports = {
  fields: () => {
    return {
      number: {
        label: 'No.',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      departureDate: {
        label: '工場出荷予定日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
      arrivalDate: {
        label: '倉庫到着予定日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
      note: {
        label: 'メモ',
        type: 'text',
        rows: 5,
      },
    };
  },
  itemFields: ({ productId, purchaseOrderId, productsById, purchaseOrders, products, }) => {
    const product = productsById[productId];
    const computedItems = purchaseOrders.flatMap(po => po.computedItems?.map(_ => ({ ..._, po, }))).filter(_ => _.productId === productId);
    const selectedComputedItem = computedItems.find(_ => _.po.id === purchaseOrderId);
    return {
      productId: {
        label: '商品',
        type: 'select',
        options: products.map(_ => ({ label: _?.code, value: _?.id })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      purchaseOrderId: {
        label: 'PO',
        type: 'select',
        options: (state) => {
          return computedItems.map(_ => ({ label: `${_.po.poNumber} (${numeral(_.leftQuantity).format()})`, value: _.po.id }));
        },
        validations: {
          required: v => !isEmpty(v),
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
          lessThanOrEqualTo: v => v == null || v <= selectedComputedItem.leftQuantity,
        },
        showsTextInput: true,
      },
    };
  },
};
