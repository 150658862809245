import React from 'react';
import { format as formatDate } from 'date-fns';
import { isObject, orderBy, keyBy, pick } from 'lodash';
import numeral from 'numeral';

import firebase from '../../firebase';
import { answerDisplayValue, } from '../../util';
import { basicFields, reimbursementRequestFields } from '../../shared/models/methodInquiry';
import { fieldDisplayValue } from '../../shared/util';
import UserPage from '../hocs/UserPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AppCard from '../AppCard';
import MypageMessagesButton from '../MypageMessagesButton';
import InquiryReimbursementRequestButton from '../InquiryReimbursementRequestButton';

const { entries, keys, } = Object;
const db = firebase.firestore();
const methodInquiriesRef = db.collection('methodInquiries');
const productsRef = db.collection('products');

export default UserPage(function MypageMethodInquiries(props) {
  const { user = {} } = props;
  const methodInquiries = useCollectionSubscription(
    user.uid && methodInquiriesRef.where('createdBy.uid', '==', user.uid),
    [user.uid]
  );
  const products = useCollectionSubscription(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const questions = useDocumentsFetch(methodInquiries.flatMap(_ => keys(_.answers)).map(_ => db.collection('questions').doc(_)), [methodInquiries]);
  const questionsById = keyBy(questions, 'id');
  const filteredMethodInquiries = methodInquiries;
  const sortedMethodInquiries = orderBy(filteredMethodInquiries, (_) => _.createdAt.toDate(), 'desc');

  return (
    <div className="mypage-methodInquiries">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">乗り方・使い方お問合せ履歴</h5>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="mt-4">
              {sortedMethodInquiries.map((methodInquiry) => {
                return (
                  <MethodInquiryItem
                    key={methodInquiry.id}
                    methodInquiry={methodInquiry}
                    products={products}
                    productsById={productsById}
                    user={user}
                    questionsById={questionsById}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

function MethodInquiryItem(props) {
  const { methodInquiry, products, productsById, questionsById, user } = props;
  const { createdAt, items = [], answers } = methodInquiry;

  return (
    <div className="mb-4">
      <div className="position-relative" style={{ textDecoration: 'none' }}>
        <AppCard className="card text-dark p-3 p-sm-4">
          <div className="flex-grow-1">
            <div className="mb-3">
              <div className="small text-muted">お問合せ日時</div>
              {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
            </div>
            {entries(pick(basicFields({ products }), ['productId', 'description'])).map(([fieldName, fieldSetting]) => {
              return (
                <div key={fieldName} className="mb-3 text-truncate">
                  <div className="small text-muted">{fieldSetting.label}</div>
                  {fieldDisplayValue(methodInquiry[fieldName], fieldSetting)}
                </div>
              );
            })}
          </div>
          <div className="d-flex flex-column gap-2">
            {entries(answers || {}).map(([questionId, answer]) => {
              const question = questionsById[questionId]
              const { type, description } = question || {};
              return (
                <div key={questionId}>
                  <div className="small text-muted">
                    {description}
                  </div>
                  <div>{answerDisplayValue(answer)}</div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-end pt-3">
            {methodInquiry.messages?.length > 0 && <MypageMessagesButton color="secondary" className="rounded-pill px-4" size="sm" type="methodInquiry" subject={methodInquiry} reinquiryPath="methodInquiry" />}
          </div>
        </AppCard>
      </div>
    </div>
  );
}
