import { useState, useEffect, useMemo, useRef } from 'react';
import { getCollectionData, queryEqual, Query } from '../../../firebase';

const queriesEqual = <T>(prevQueries: Query<T>[], queries: Query<T>[]) => {
  if (prevQueries.length !== queries.length) return false;
  const sortedPrevQueries = prevQueries.slice().sort();
  const sortedQueries = queries.slice().sort();

  return sortedPrevQueries.every((prev, i) => queryEqual(prev, sortedQueries[i]));
};

export const useCollectionsDataOnce = <T>(queries: Query<T>[]) => {
  const [data, setData] = useState<T[]>([]);
  const prevQueries = useRef<Query<T>[] | undefined>();

  useEffect(() => {
    if (prevQueries.current != null && queriesEqual(prevQueries.current, queries)) return;

    prevQueries.current = queries;
    (async () => {
      setData(
        (await Promise.all(queries.map((query) => getCollectionData<T>(query)))).reduce(
          (prev, current) => [...prev, ...current],
          []
        )
      );
    })();
  }, [queries]);

  return useMemo(() => ({ data }), [data]);
};
