import React, { useEffect, useMemo, Fragment, } from 'react';
import { isEmpty, orderBy, sortBy, keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, startOfDay, endOfDay, } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdateReceiving } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import ReceivingFormModal from '../modals/ReceivingFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields, types, } from '../../shared/models/receiving';
import QuerySelector from '../QuerySelector';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { keys, entries } = Object;
const db = firebase.firestore();
const typeOptions = entries(types).map(([k, v]) => ({ label: v.label, value: k }));

export default AdminPage(function AdminInventoriesSchedule (props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const receivedOnStartOn = useMemo(_ => queryParams.receivedOnRange?.[0] ? startOfDay(new Date(queryParams.receivedOnRange[0])) : null, [queryParams.receivedOnRange]);
  const receivedOnEndOn = useMemo(_ => queryParams.receivedOnRange?.[1] ? endOfDay(new Date(queryParams.receivedOnRange[1])) : null, [queryParams.receivedOnRange]);
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes').orderBy('index'));
  const productTypesById = keyBy(productTypes, 'id');
  const productTypeOptions = productTypes.map(_ => ({ value: _.id, label: _.name }));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productOptions = products.map(_ => ({ value: _.id, label: _.code }));
  const productsById = keyBy(products, 'id');
  const _receivings = useCollectionSubscriptionInTenant(db.collection('receivings'));
  const receivings = useMemo(_ => orderBy(_receivings, _ => _.receivedOn.toDate()), [_receivings]);

  let filteredRowGroups = receivings;
  if(receivedOnStartOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.receivedOn.toDate() >= receivedOnStartOn);
  }
  if(receivedOnEndOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.receivedOn.toDate() <= receivedOnEndOn);
  }
  if(!isEmpty(queryParams.types)) {
    filteredRowGroups = filteredRowGroups.filter(_ => queryParams.types.includes(_.type));
  }
  filteredRowGroups = filteredRowGroups.map((rowGroup) => {
    let filteredItems = rowGroup.items;
    if(!isEmpty(queryParams.productTypeIds)) {
      filteredItems = filteredItems.filter(_ => productsById[_.productId]?.productTypeIds?.some(_ => queryParams.productTypeIds.includes(_)));
    }
    if(!isEmpty(queryParams.productIds)) {
      filteredItems = filteredItems.filter(_ => queryParams.productIds.includes(_.productId));
    }
    return {
      ...rowGroup,
      filteredItems,
    };
  }).filter(_ => _.filteredItems.length > 0);

  return (
    <div>
      <div className="admin-receivings container-fluid py-5 position-relative bg-white">
        <div className="d-flex justify-content-center mb-3">
          <h4>入荷管理</h4>
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QueryDateRangeSelector label="入荷日" defaultValue={[receivedOnStartOn, receivedOnEndOn]} paramName="receivedOnRange" pickerProps={{ isClearable: true, showYearDropdown: true, dropdownMode: 'select' }} />
          <QuerySelector paramName="types" width={200} isMulti options={typeOptions} label="種別で絞込み" />
          <QuerySelector paramName="productTypeIds" width={400} isMulti options={productTypeOptions} label="商品種別で絞込み" />
          <QuerySelector paramName="productIds" width={300} isMulti options={productOptions} label="商品で絞込み" />
        </div>
        <div className="mt-4 d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={db.collection('receivings').doc()} processValues={_ => ({ ..._, status: 'initial', })} FormModal={ReceivingFormModal} formProps={{ products, }} disabled={!canUpdateReceiving(user)} />
        </div>
        <div>
          {
            receivings.length > 0 ? (
              <table className="table table-bordered table-sm">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ width: 200 }}></th>
                    <th>入荷日</th>
                    <th>種別</th>
                    <th>商品コード</th>
                    <th>数量</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRowGroups.map((receiving) => {
                      const { id, ref, receivedOn, type, items, } = receiving;

                      return (
                        <Fragment key={id}>
                          {
                            items.map((item, i) => {
                              const { productId, quantity, } = item;
                              const product = productsById[productId];
                              return (
                                <tr key={id}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={items.length} className="text-nowrap">
                                          <EditButton itemRef={ref} FormModal={ReceivingFormModal} formProps={{ products, }} disabled={!canUpdateReceiving(user)} />
                                          <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateReceiving(user)} />
                                        </td>
                                        <td rowSpan={items.length}>
                                          {formatDate(receivedOn.toDate(), 'yyyy/MM/dd')}
                                        </td>
                                        <td rowSpan={items.length}>
                                          {types[type]?.label}
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                  <td>
                                    {product?.code}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format()}
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                入荷は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
