import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { sortBy, isEmpty, keyBy, get, omitBy, isUndefined, pick, } from 'lodash';
import numeral from 'numeral';

import { canUpdateAgent, } from '../../shared/abilities';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { fields as agentContentProductSettingFields, } from '../../shared/models/agentContentProductSetting';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import { agentUserFields } from '../../shared/models/user';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const db = firebase.firestore();

export default AdminPage(function AdminAgentContentProducts (props) {
  const { user, match: { params: { agentId } }, addBreadNavValues } = props;
  const agent = useDocumentSubscription(db.collection('agents').doc(agentId), [agentId]);
  const contentProducts = sortBy(useCollectionSubscriptionInTenant(db.collection('contentProducts')), 'code');
  const agentContentProductSettings = useCollectionSubscription(agent?.ref.collection('agentContentProductSettings'), [agent]);
  const agentContentProductSettingsById = keyBy(agentContentProductSettings, 'id');
  const nonPartContentProducts = contentProducts.filter(_ => !_.isPart && _.createdAt);
  const rowsForExport = () => {
    return nonPartContentProducts.map((contentProduct) => {
      const { id, } = contentProduct;
      const setting = agentContentProductSettingsById[id];
      return {
        id,
        isShownForRental: setting?.isShownForRental || false,
        price: setting?.price,
      };
    });
  };
  const processRow = (batch, item, i) => {
    const existsContentProduct = contentProducts.some(_ => _.id === item.id);
    if(!existsContentProduct) return;

    const exists = agentContentProductSettings.some(_ => _.id === item.id);
    batch.set(agent.ref.collection('agentContentProductSettings').doc(item.id), {
      isShownForRental: item.isShownForRental?.toUpperCase() === 'TRUE',
      price: isEmpty(item.price) ? null : parseInt(item.price, 10),
      ...(
        !exists && {
          createdAt: new Date(),
        }
      ),
      [exists ? 'updatedBy' : 'addedBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
    }, { merge: true });
  };
  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent-contentProducts container-fluid py-5 position-relative">
        <AdminAgentHeader activeTab="contentProducts" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center mb-3">
                コンテンツ商品
              </h5>
              <div className="d-flex justify-content-end mb-3 gap-1">
                <ImportButton processRow={processRow} />
                <ExportButton fileName={`${agent.name}_商品.csv`} rows={rowsForExport} />
              </div>
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th>コンテンツ商品</th>
                    <th>レンタルカートで表示</th>
                    <th>価格</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    nonPartContentProducts.map((contentProduct) => {
                      const { id, ref, name, code, } = contentProduct;
                      const setting = agentContentProductSettingsById[id];
                      const ButtonComponent = setting != null ? EditButton : AddInTenantButton;
                      const { isShownForRental = false, price, } = setting || {};
                      return (
                        <tr key={id}>
                          <td>
                            [{code}] {name}
                          </td>
                          <td>{isShownForRental && '表示'}</td>
                          <td className='text-right'>
                            {
                              price != null ? (
                                <span>{numeral(price).format()}</span>
                              ) : ''
                            }
                          </td>
                          <td className="text-right">
                            <ButtonComponent icon={<span className="fas fa-cog mr-1" />} color="secondary" label="設定" itemRef={agent.ref.collection('agentContentProductSettings').doc(id)} FormModal={ModelFormModal} formProps={{ documentName: 'agentContentProductSetting', title: '設定', fields: agentContentProductSettingFields(), }} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
