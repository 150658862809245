const { isEmpty } = require('lodash');
const dedent = require('dedent')
const { isMultibyte, isEmail, isFullWidth } = require('validator');

const { vehicleExperiences, } = require('./user');
const { userFiltering: { dateTypes }, smsOrderTypes: orderTypes, smsEntryTypes: entryTypes, deliveryMethods, userNotificationTypes, mobileNotificationContentTypes } = require('../config');

const { entries } = Object;

const statuses = {
  create: { label: '作成前', color: 'secondary', },
  creating: { label: '作成中', color: 'secondary', },
  preview: { label: '配信待ち', color: 'secondary', },
  initial: { label: '処理待ち', color: 'warning', },
  processing: { label: '処理中', color: 'info', },
  completed: { label: '配信完了', color: 'success', },
  failed: { label: '配信失敗', color: 'danger', },
  error: { label: 'エラー', color: 'danger', },
  copied: { label: 'コピー', color: 'secondary' },
  createRetry: { label: 'リトライ', color: 'secondary' },
};

module.exports = {
  fields: ({ products = [], productTypes = [], events = [], userTags = [], areaGroups = [], useSms = false, useMobile = false } = {}) => {
    return {
      dateType: {
        label: '日付範囲種別',
        type: 'select',
        options: entries(dateTypes).map(([k, v]) => ({ label: v.label, value: k })),
        initialValue: '',
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      startDate: {
        label: '開始日',
        type: 'date',
      },
      endDate: {
        label: '終了日',
        type: 'date',
      },
      orderType: {
        label: '注文種別',
        type: 'select',
        options: entries(orderTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      conditionProductTypeIds: {
        label: '注文商品種別',
        type: 'multiSelect',
        options: productTypes.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      conditionProductIds: {
        label: '注文商品',
        type: 'multiSelect',
        options: products.map(_ => ({ label: _.label || _.name, value: _.id })),
        hidden: _ => _.orderType !== 'ordered',
      },
      isBodyOnly: {
        label: '本体のみ',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.orderType !== 'ordered',
      },
      entryType: {
        label: 'イベント申込種別',
        type: 'select',
        options: entries(entryTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      conditionEventIds: {
        label: '申込イベント',
        type: 'multiSelect',
        options: events.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.entryType !== 'entried',
      },
      isIncludedCancellOrAbort: {
        label: 'キャンセル・中止を含む',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.entryType !== 'entried',
      },
      isCancellOrAbortOnly: {
        label: 'キャンセル・中止のみ',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.entryType !== 'entried',
      },
      userTagIds: {
        label: 'ユーザータグ',
        type: 'multiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
        hint: 'ORで判定します',
      },
      userChildAgeMin: {
        label: 'お子様の年齢下限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildAgeMax: {
        label: 'お子様の年齢上限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildVehicleExperiences: {
        label: 'お子様乗り物経験',
        type: 'multiSelect',
        options: ['未選択', ...vehicleExperiences].map(_ => ({ label: _, value: _, })),
      },
      userChildBlank: {
        label: 'お子様情報の未記入',
        type: 'select',
        options: ['含む', '含まない'].map(_ => ({ label: _, value: _, })),
      },
      areaGroups: {
        label: 'エリアグループ',
        type: 'multiSelect',
        options: areaGroups.map((_) => ({ label: _, value: _ })),
        hint: 'ORで判定します'
      },
      deliveryMethod: {
        label: '配信方法',
        type: 'select',
        options: entries(deliveryMethods)
          .filter(([k, v]) => useSms || k !== 'sms')
          .filter(([k, v]) => useMobile || k !== 'mobile')
          .map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
      },
      emailSubject: {
        label: 'Eメール件名',
        type: 'text',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.deliveryMethod !== 'email',
      },
      emailBody: {
        label: '本文',
        type: 'richText',
        rows: 10,
        validations: {
          required: v => !isEmpty(v),
        },
        showsTextLength: true,
        hint: dedent`埋め込みキーワード
        {{name}} : contactorName もしくは displayName が表示される。
        `,
        hidden: _ => _.deliveryMethod !== 'email',
      },
      smsBody: {
        label: '本文',
        type: 'text',
        rows: 5,
        validations: {
          required: v => !isEmpty(v),
        },
        showsTextLength: true,
        hint: dedent`埋め込みキーワード
        {{name}} : contactorName もしくは displayName が表示される。
        `,
        hidden: _ => _.deliveryMethod !== 'sms',
      },
      mobileSubject: {
        label: '通知文',
        type: 'text',
        rows: 2,
        hint: '全角18文字、2行までで指定してください',
        validations: {
          required: v => !isEmpty(v),
          maxRows: v => (v || '').split('\n').length <= 2,
          maxLength: v => (v || '').split('\n').every(text => {
            return text.split('').map(_ => isFullWidth(_) ? 2 : 1).reduce((x, y) => x + y, 0) <= 36
          }),
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      mobileNotificationContentType: {
        label: '本文タイプ',
        type: 'select',
        options: entries(mobileNotificationContentTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      mobileMessage: {
        label: '本文（テキスト）',
        type: 'text',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationContentType !== 'text',
      },
      mobileUrl: {
        label: '本文（URL）',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationContentType !== 'url',
      },
      mobileHtml: {
        label: '本文（HTML）',
        type: 'richText',
        rows: 10,
        validations: {
          required: v => !isEmpty(v),
        },
        showsTextLength: true,
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationContentType !== 'html',
      },
      mobileTypeDisplayName: {
        label: '通知グループ',
        type: 'text',
        rows: 1,
        hint: '最大16文字までで指定してください',
        validations: {
          maxLength: v => (v || '').length <= 16,
          maxRows: v => (v || '').split('\n').length <= 1,
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      mobileNotificationType: {
        label: '通知タイプ',
        type: 'select',
        options: entries(userNotificationTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: _ => !isEmpty(_),
        },
        hidden: _ => _.deliveryMethod !== 'mobile',
      },
      fixUntil: {
        label: 'イベント最終日',
        type: 'date',
        hidden: _ => _.deliveryMethod !== 'mobile' || _.mobileNotificationType !== 'newEvent',
      },
      extraEmail: {
        label: '追加配信メールアドレス',
        type: 'string',
        inputType: 'email',
        validations: {
          format: v => !v || isEmail(v),
          notMultibyte: v => !v || !isMultibyte(v),
        },
        hidden: _ => _.deliveryMethod !== 'email',
      },
      note: {
        label: 'メモ',
        type: 'text',
      },
    };
  },
  orderTypes,
  entryTypes,
  dateTypes,
  statuses,
};
