const { isEmpty } = require('lodash');
const { prefectures } = require('../config');
const { entries } = Object;

module.exports = {
  fields: {
    name: {
      label: '場所名',
      type: 'string',
      validations: {
        required:  v => !isEmpty(v),
      },
    },
    prefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      validations: {
        required:  v => !isEmpty(v),
      },
    },
  },
};
