import { useState, useEffect } from 'react';
import { useToggle } from 'react-use';

const paths = [];
export default function useCollectionSubscription(ref, dependencies = [], { initialItems = [], detail = false } = {}) {
  const [items, setItems] = useState(initialItems);
  const [isLoading, toggleLoading] = useToggle(false);

  useEffect(() => {
    setItems(initialItems);
    if (!ref) return;

    toggleLoading(true);
    const unsubscribe = ref.onSnapshot(({ docs }) => {
      setItems(docs.map((_) => ({ id: _.id, ref: _.ref, ..._.data() })));
      toggleLoading(false);
    }, _ => console.log(111, _, ref));
    return unsubscribe;
  }, dependencies);
  return detail ? { items, isLoading } : items;
}
