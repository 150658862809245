import React from 'react';
import { sortBy, keyBy, } from 'lodash';
import { useList } from 'react-use';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';

import firebase from '../../firebase';
import { batch } from '../../shared/firebase';
import { canUpdateInquiryType } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/inquiryType';
import { fields as inquiryTypeChildFields } from '../../shared/models/inquiryTypeChild';

const db = firebase.firestore();
const inquiriesRef = db.collection('inquiries');
const inquiryTypesRef = db.collection('inquiryTypes');
const inquiryTypeChildrenRef = db.collection('inquiryTypeChildren');

export default AdminPage(function AdminInquiryTypes (props) {
  const { tenant, user } = props;
  const [isDragging, toggleDragging] = useToggle(false);
  const inquiryTypes = sortBy(useCollectionSubscriptionInTenant(inquiryTypesRef), 'index');
  const inquiryTypeChildren = sortBy(useCollectionSubscriptionInTenant(inquiryTypeChildrenRef), 'index');
  const inquiryTypesById = keyBy(inquiryTypes, 'id');
  const onDrop = async ({ addedIndex, removedIndex }) => {
    await batch(db, arrayMoveImmutable(inquiryTypes, removedIndex, addedIndex), (batch, inquiryType, index) => {
      batch.update(inquiryType.ref, { index });
    });
  };
  const onDropChild = async ({ addedIndex, removedIndex }) => {
    await batch(db, arrayMoveImmutable(inquiryTypeChildren, removedIndex, addedIndex), (batch, inquiryTypeChild, index) => {
      batch.update(inquiryTypeChild.ref, { index });
    });
  };

  return (
    <div>
      <div className="admin-inquiry-types container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>問合せ種別一覧</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton itemRef={inquiryTypesRef.doc()} processValues={_ => ({ ..._, index: inquiryTypes.length, })} FormModal={ModelFormModal} formProps={{ documentName: 'inquiryType', title: '問合せ種別追加', fields, }} disabled={!canUpdateInquiryType(user)} />
              </div>
              <div>
                {
                  inquiryTypes.length > 0 ? (
                    <table className="table">
                      <thead className="thead-light text-center">
                        <tr>
                          <th></th>
                          <th>タイトル</th>
                          <th>非表示</th>
                          <th></th>
                        </tr>
                      </thead>
                      <Container
                        dragHandleSelector=".drag-handle"
                        onDrop={onDrop}
                        onDragStart={_ => toggleDragging(true)}
                        onDragEnd={_ => toggleDragging(false)}
                        dropPlaceholder={{ style: { background: 'eee', } }}
                        render={(ref) => (
                          <tbody ref={ref}>
                            {
                              inquiryTypes.map((inquiryType) => {
                                const { id, ref, subject, isHidden = false, } = inquiryType;
                                const beforeDelete = async () => {
                                  if(
                                    (await inquiryTypeChildrenRef.where('tenantId', '==', tenant.id).where('parentId', '==', id).limit(1).get()).docs.length > 0
                                  ) {
                                    toast.error('使用されているため削除できません');
                                    return false;
                                  }
                                };

                                return (
                                  <Draggable
                                    key={id}
                                    render={() => (
                                      <tr style={{ display: !isDragging && 'table-row' }}>
                                        <td style={{ width: 30 }}>
                                          <div className="drag-handle text-muted cursor-pointer">
                                            <span className="fas fa-grip-vertical" />
                                          </div>
                                        </td>
                                        <td>
                                          {subject}
                                        </td>
                                        <td>
                                          {isHidden && '非表示'}
                                        </td>
                                        <td className="text-nowrap text-right">
                                          <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'inquiryType', title: '問合せ種別編集', fields, }} disabled={!canUpdateInquiryType(user)} />
                                          <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateInquiryType(user)} beforeDelete={beforeDelete} />
                                        </td>
                                      </tr>
                                    )}
                                  />
                                );
                              })
                            }
                          </tbody>
                        )}
                      />
                    </table>
                  ) : (
                    <div>
                      問合せ種別は未登録です
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>問合せ子種別一覧</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton itemRef={inquiryTypeChildrenRef.doc()} processValues={_ => ({ ..._, index: inquiryTypeChildren.length, })} FormModal={ModelFormModal} formProps={{ documentName: 'inquiryTypeChild', title: '問合せ子種別追加', fields: inquiryTypeChildFields({ parents: inquiryTypes }), }} disabled={!canUpdateInquiryType(user)} />
              </div>
              <div>
                {
                  inquiryTypeChildren.length > 0 ? (
                    <table className="table">
                      <thead className="thead-light text-center">
                        <tr>
                          <th></th>
                          <th>親種別</th>
                          <th>タイトル</th>
                          <th>非表示</th>
                          <th></th>
                        </tr>
                      </thead>
                      <Container
                        dragHandleSelector=".drag-handle"
                        onDrop={onDropChild}
                        onDragStart={_ => toggleDragging(true)}
                        onDragEnd={_ => toggleDragging(false)}
                        dropPlaceholder={{ style: { background: 'eee', } }}
                        render={(ref) => (
                          <tbody ref={ref}>
                            {
                              inquiryTypeChildren.map((inquiryTypeChild) => {
                                const { id, ref, parentId, subject, isHidden = false, } = inquiryTypeChild;
                                const beforeDelete = async () => {
                                  if(
                                    (await inquiriesRef.where('tenantId', '==', tenant.id).where('inquiryTypeChildId', '==', id).limit(1).get()).docs.length > 0
                                  ) {
                                    toast.error('使用されているため削除できません');
                                    return false;
                                  }
                                };

                                return (
                                  <Draggable
                                    key={id}
                                    render={() => (
                                      <tr style={{ display: !isDragging && 'table-row' }}>
                                        <td style={{ width: 30 }}>
                                          <div className="drag-handle text-muted cursor-pointer">
                                            <span className="fas fa-grip-vertical" />
                                          </div>
                                        </td>
                                        <td>
                                          {inquiryTypesById[parentId]?.subject}
                                        </td>
                                        <td>
                                          {subject}
                                        </td>
                                        <td>
                                          {isHidden && '非表示'}
                                        </td>
                                        <td className="text-nowrap text-right">
                                          <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'inquiryTypeChild', title: '問合せ子種別編集', fields: inquiryTypeChildFields({ parents: inquiryTypes }), }} disabled={!canUpdateInquiryType(user)} />
                                          <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateInquiryType(user)} beforeDelete={beforeDelete} />
                                        </td>
                                      </tr>
                                    )}
                                  />
                                );
                              })
                            }
                          </tbody>
                        )}
                      />
                    </table>
                  ) : (
                    <div>
                      問合せ子種別は未登録です
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

