import React from 'react';
import { groupBy, sortBy, get, keyBy, } from 'lodash';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { canCreateEnvelopeDelivery } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { userConditionText, } from '../../shared/util';
import { statuses, fields, orderTypes, entryTypes, dateTypes, } from '../../shared/models/envelopeDelivery';
import TenantLink from '../TenantLink';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';

const db = firebase.firestore();

export default AdminPage(function AdminEnvelopeDeliveries (props) {
  const { user } = props;
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts').orderBy('code'));
  const envelopeProductsById = keyBy(envelopeProducts, 'id');
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts
    .filter((_) => _.isBody || _.isOption)
    .map((_) => ({
      ..._,
      label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`,
    }));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), _ => _.createdAt.toDate());
  const productTypesById = keyBy(productTypes, 'id');
  const lectures = useCollectionSubscriptionInTenant(db.collectionGroup('lectures').orderBy('date'));
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const sortedEvents = sortBy(events, (event) => {
    const date = get(lecturesGroupedByEventId, `${event.id}.0.date`);
    return date && date.toDate();
  });
  const envelopeDeliveries = useCollectionSubscriptionInTenant(db.collection('envelopeDeliveries').orderBy('createdAt', 'desc'));

  return (
    <div>
      <div className="admin-envelope-deliveries container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>一括郵便発送</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton
                  label="郵便発送する"
                  processValues={_ => ({ ..._, status: 'create' })}
                  itemRef={db.collection('envelopeDeliveries').doc()}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '郵便発送',
                    fields: fields({ products: selectableProducts, envelopeProducts, productTypes, events: sortedEvents, userTags, }),
                    hasSpin: true,
                  }}
                  disabled={!canCreateEnvelopeDelivery(user)}
                />
              </div>
              <hr className="my-5" />
              <h5>履歴</h5>
              <div>
                {
                  envelopeDeliveries.length > 0 ? (
                    <table className="table">
                      <thead className="thead-light text-center text-nowrap">
                        <tr>
                          <th>処理日時</th>
                          <th>ステータス</th>
                          <th>注文条件</th>
                          <th>イベント参加条件</th>
                          <th>アカウント条件</th>
                          <th>日付範囲</th>
                          <th>対象件数</th>
                          <th>郵送物</th>
                          <th style={{ minWidth: 120 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          envelopeDeliveries.map((envelopeDelivery) =>
                            <EnvelopeDeliveryRow
                              key={envelopeDelivery.id}
                              user={user}
                              productTypesById={productTypesById}
                              productsById={productsById}
                              eventsById={eventsById}
                              envelopeProductsById={envelopeProductsById} 
                              selectableProducts={selectableProducts}
                              envelopeProducts={envelopeProducts}
                              productTypes={productTypes}
                              sortedEvents={sortedEvents}
                              userTags={userTags}
                              envelopeDelivery={envelopeDelivery}
                            />)
                        }
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      No Data
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const EnvelopeDeliveryRow = (props) => {
  const { user, productTypesById, productsById, eventsById, envelopeProductsById, selectableProducts, envelopeProducts, productTypes, sortedEvents, userTags, envelopeDelivery } = props;
  const { id, ref, name, orderType = 'any', conditionProductTypeIds, conditionProductIds, entryType = 'any', conditionEventIds, dateType, envelopeProductIds, status, completedAt, } = envelopeDelivery;
  const deliveryCandidates = useCollectionSubscriptionInTenant(db.collection(`envelopeDeliveries/${id}/deliveryCandidates`));
  const { label: statusLabel, color } = statuses[status] || {} 
  return (
    <tr key={id}>
      <td style={{ whiteSpace: 'pre-line' }}>
        <TenantLink to={`/admin/envelopeDeliveries/${id}`}>{completedAt ? formatDate(completedAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : '未処理'}</TenantLink>
      </td>
      <td>
        <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
      </td>
      <td>
        {orderTypes[orderType]?.label}
        {
          orderType === 'ordered' && (
            <div>
              <div className="small text-muted">
                {conditionProductTypeIds?.map(_ => productTypesById[_]?.name).join(',')}
              </div>
              <div className="small text-muted">
                {conditionProductIds?.map(_ => productsById[_]).map(_ => `[${_?.code}] ${_?.name}`).join(',')}
              </div>
            </div>
          )
        }
      </td>
      <td>
        {entryTypes[entryType]?.label}
        {
          entryType === 'entried' && (
            <div className="small text-muted">
              {conditionEventIds?.map(_ => eventsById[_]?.name).join(',')}
            </div>
          )
        }
      </td>
      <td className="text-muted small">
        {userConditionText(envelopeDelivery)}
      </td>
      <td>
        <span>{dateTypes[dateType]?.text({ data: envelopeDelivery })}</span>
      </td>
      <td className='text-right'>{numeral(deliveryCandidates.reduce((x, y) => x + y.deliveryCount, 0)).format('0,0')}</td>
      <td>
        {
          (envelopeProductIds || []).map((envelopeProductId) => {
            const envelopeProduct = envelopeProductsById[envelopeProductId];
            return (
              <div key={envelopeProductId}>
                [{envelopeProduct?.code}] {envelopeProduct?.name}
              </div>
            );
          })
        }
      </td>
      <td className="text-nowrap text-right">
        <EditButton
          itemRef={ref}
          className="ml-2"
          FormModal={ModelFormModal}
          formProps={{
            title: '郵便発送',
            fields: fields({ products: selectableProducts, envelopeProducts, productTypes, events: sortedEvents, userTags, }),
            hasSpin: true,
          }}
          disabled={!canCreateEnvelopeDelivery(user) || status !== 'preview'}
        />
        <div>
          <TenantLink to={`/admin/envelopeDeliveries/${envelopeDelivery.id}/deliveryTargets`} target="_blank">処理対象</TenantLink>
        </div>
      </td>
    </tr>
  );
}
