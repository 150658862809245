import React from 'react';
import { range } from 'lodash';
import Select from 'react-select';
import { getDaysInMonth, setYear, setMonth, setDate, getYear, getMonth, getDate } from 'date-fns';
import classnames from 'classnames';

export default function DateSelector (props) {
  const { value, onChange, className, readOnly, yearRange: [startYear, endYear] } = props;
  const year = getYear(value);
  const month = getMonth(value);
  const date = getDate(value);
  const onChangeYear = _ => onChange(setYear(value, _.value));
  const onChangeMonth = _ => onChange(setMonth(value, _.value));
  const onChangeDate = _ => onChange(setDate(value, _.value));
  const yearOptions = range(startYear, endYear + 1).map(_ => ({ label: `${_}年`, value: _ }));
  const monthOptions = range(0, 12).map(_ => ({ label: `${_ + 1}月`, value: _ }));
  const dateOptions = range(1, getDaysInMonth(value) + 1).map(_ => ({ label: `${_}日`, value: _ }));
  return (
    <div className="d-flex">
      <div style={{ width: 80 }}>
        <Select
          value={yearOptions.find(_ => _.value === year) || null}
          onChange={onChangeYear}
          className={classnames('form-select', className)}
          options={yearOptions}
          isDisabled={readOnly}
          components={{ DropdownIndicator: _ => null, IndicatorSeparator: _ => null }}
        />
      </div>
      <div style={{ width: 70 }} className="ml-1">
        <Select
          value={monthOptions.find(_ => _.value === month) || null}
          onChange={onChangeMonth}
          className={classnames('form-select', className)}
          options={monthOptions}
          isDisabled={readOnly}
          components={{ DropdownIndicator: _ => null, IndicatorSeparator: _ => null }}
        />
      </div>
      <div style={{ width: 70 }} className="ml-1">
        <Select
          value={dateOptions.find(_ => _.value === date) || null}
          onChange={onChangeDate}
          className={classnames('form-select', className)}
          options={dateOptions}
          isDisabled={readOnly}
          components={{ DropdownIndicator: _ => null, IndicatorSeparator: _ => null }}
        />
      </div>
    </div>
  );
};
