import { toast } from 'react-toastify';
import { omit, uniqBy } from 'lodash';
import ModalButton from './ModalButton';
import ModelFormModal from './modals/ModelFormModal';
import useTenant from './hooks/useTenant';
import { useSettingDocument, tenantAreaSettingRef } from '../models/setting';
import { updateDoc, DocumentReference } from '../firebase';
import type { Inquiry } from '../types/inquiry';
import type { TroubleInquiry } from '../types/troubleInquiry';

export const InquiryUpdatePicButton = ({
  inquiryRef,
  inquiry,
  onSubmit,
}: {
  inquiryRef: DocumentReference<TroubleInquiry | Inquiry>;
  inquiry: Inquiry | TroubleInquiry;
  onSubmit?: () => Promise<void>;
}) => {
  const tenant = useTenant();
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  // areaSettingのuserはidじゃなくてuid持つようにしたほうがよさそう（userドキュメントとの整合性考えると）
  const picOptions = uniqBy(
    Object.values(areaSetting?.data || {}).map(({ user }) => user),
    ({ id }) => id
  ).map((_) => ({ label: _.displayName, value: { ...omit(_, 'id'), uid: _.id } }));

  return (
    <ModalButton
      Modal={ModelFormModal}
      modalProps={({ toggleModal }) => {
        const handleSubmit = async ({ respondedBy }) => {
          if (!window.confirm('本当に担当を変更しますか？')) return;

          try {
            await updateDoc(inquiry.ref, { respondedBy });
            onSubmit && (await onSubmit());
            toast.success('担当を変更しました');
            toggleModal(false);
          } catch (e) {
            console.error(e);
            toast.error('失敗しました');
          }
        };

        return {
          title: '担当変更',
          submitLabel: '変更',
          fields: { respondedBy: { type: 'select', label: '担当', options: picOptions } },
          onSubmit: handleSubmit,
        };
      }}
    >
      担当変更
    </ModalButton>
  );
};
