import { useState, useEffect } from 'react';
import { isEmpty, get, isFunction } from 'lodash';

const { keys, entries } = Object;

export default function useFormState(values, fields, shouldReset) {
  const initialState = entries(fields).reduce((x, [fieldName, fieldSetting]) => {
    const { type, initialValue } = fieldSetting;
    const _value = (values || {})[fieldName];
    const toDateInSomeCases = (v) => (v && v.toDate ? v.toDate() : v);
    const value = (
      {
        datetime: () => toDateInSomeCases(_value),
        date: () => toDateInSomeCases(_value),
        time: () => toDateInSomeCases(_value),
      }[type] || ((_) => _value)
    )();
    return {
      ...x,
      [fieldName]: value != null ? value : initialValue != null ? initialValue : null,
    };
  }, {});
  const [valuesState, setValuesState] = useState(initialState);
  const [startsState, setStartsState] = useState({});
  const [richTextEditorRefs, setRichTextEditorRefs] = useState({});

  useEffect(() => {
    if (shouldReset) setValuesState(initialState);
  }, [isEmpty(values), shouldReset]);

  const statedFields = entries(fields).reduce((x, [fieldName, fieldSetting]) => {
    const { type, validations = {}, warnings = {}, hidden = (_) => false, options = [] } = fieldSetting;
    const shouldHide = hidden(valuesState);
    const value = shouldHide ? null : valuesState[fieldName];
    const validationErrors = shouldHide
      ? []
      : entries(validations)
          .filter(([k, v]) => !v(value, valuesState, fieldName))
          .map(([k]) => k);
    const validationWarnings = shouldHide
      ? []
      : entries(warnings)
          .filter(([k, v]) => !v(value, valuesState, fieldName))
          .map(([k]) => k);
    const _options = isFunction(options) ? options(valuesState) : options;
    return {
      ...x,
      [fieldName]: {
        ...fieldSetting,
        value,
        setValue: (v) => setValuesState({ ...valuesState, [fieldName]: v }),
        start: setStartsState.bind(null, { ...startsState, [fieldName]: true }),
        hasStarted: get(startsState, fieldName, false),
        validationErrors,
        validationWarnings,
        isValid: validationErrors.length === 0,
        shouldHide,
        options: _options,
        ...(
          type === 'richText' && {
            setRteValue: _ => richTextEditorRefs[fieldName]?.current?.setRteValue(_),
            setRichTextEditorRef: _ => setRichTextEditorRefs({ ...richTextEditorRefs, [fieldName]: _ }),
          }
        ),
      },
    };
  }, {});

  Object.defineProperty(statedFields, 'setValues', {
    value: (_) => setValuesState({ ...valuesState, ..._ }),
    enumerable: false,
  });
  Object.defineProperty(statedFields, 'setStarts', {
    value: (_) => setStartsState({ ...startsState, ..._.reduce((x, y) => ({ ...x, [y]: true }), {}) }),
    enumerable: false,
  });
  Object.defineProperty(statedFields, 'startAll', {
    value: (_) => setStartsState(keys(fields).reduce((x, y) => ({ ...x, [y]: true }), {})),
    enumerable: false,
  });

  return statedFields;
}
