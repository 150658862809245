import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  limit as _limit,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
  getCollectionData,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import { useDocumentsDataOnce } from '../components/hooks/v9/useDocumentsDataOnce';
import type { ProductDocumentData, Product } from '../types';
import type { PartiallyPartial } from 'types';

export const productConverter = getConverter<ProductDocumentData>();

export const productsRef = () => collection(getFirestore(), 'products').withConverter(productConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Product> : null;
export const productRef = <Id extends string | undefined>(productId: Id) =>
  (productId ? doc(productsRef(), productId) : null) as RefOrNull<Id>;

export const newProductRef = () => doc(productsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const byProductTypeIdQueryConstraints = (productTypeId: string) => [
  where('productTypeIds', 'array-contains', productTypeId),
];

export const productsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(productsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantProductsQuery = (tenantId: string | undefined) =>
  tenantId ? productsQuery(...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

export const tenantProductsByProductTypeIdQuery = (tenantId: string, productTypeId: string, limit: number) =>
  productsQuery(...tenantQueryConstraints(tenantId), ...byProductTypeIdQueryConstraints(productTypeId), _limit(limit));

export const addProduct = async (data: PartiallyPartial<Product, 'id' | 'createdAt'>) =>
  addDoc(productsRef(), { createdAt: serverTimestamp(), ...data });

export const updateProduct = async (productId: string, data: UpdateData<Product>) =>
  updateDoc(productRef(productId), data);

export const deleteProduct = async (productId: string) => deleteDoc(productRef(productId));

export const getProductCollection = getCollectionData;

export const useProductCollection = useCollectionData;

export const useProductCollectionOnce = useCollectionDataOnce;

export const useProductDocument = useDocumentData;

export const useProductDocumentOnce = useDocumentDataOnce;

export const useProductDocumentsOnce = useDocumentsDataOnce;
