const { omit, pick, inRange, isEmpty } = require('lodash');

const fields = () => ({
  name: {
    type: 'string',
    label: '名称',
    validations: {
      required: v => !isEmpty(v),
    },
  },
  display: {
    type: 'string',
    label: '表示',
    validations: {
      required: v => !isEmpty(v),
    },
  },
});

module.exports = {
  fields,
};
