import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { Button } from 'reactstrap';

export default function ListForm (props) {
  const {
    items,
    onChange,
    initialItem = {},
    renderItem,
    itemClassName,
    deleteDisabled = false,
    deleteItemDisabled = (_) => false,
    minItems = 0,
    addButtonLabel = '追加',
    hideAddButton = false,
    showsInsert = false,
  } = props;
  const onClickAdd = () => {
    onChange([...items, { ...initialItem, }], items);
  };

  return (
    <Fragment>
      {items.map((item, i) => {
        const onClickInsert = () => {
          onChange([...items.slice(0, i), { ...initialItem }, ...items.slice(i)], items);
        };
        const onClickDelete = () => {
          onChange([...items.slice(0, i), ...items.slice(i + 1)], items);
          props.onDelete?.(i);
        };
        return (
          <div key={i} className={classnames('d-flex mb-2 align-items-start', itemClassName)}>
            <div className='mr-1 flex-grow-1'>{renderItem(item, i)}</div>
            <div className="d-flex flex-column gap-1">
              {
                showsInsert && (
                  <Button color='secondary' onClick={onClickInsert}>
                    <span className='fas fa-arrow-up mr-1' />
                    <span className='fas fa-plus' />
                  </Button>
                )
              }
              {items.length > minItems && (
                <Button
                  color='danger'
                  onClick={onClickDelete}
                  disabled={deleteDisabled || deleteItemDisabled(item)}
                >
                  <span className='fas fa-trash' />
                </Button>
              )}
            </div>
          </div>
        );
      })}
      {hideAddButton || (
        <div>
          <Button color='secondary' onClick={onClickAdd}>
            <span className='fas fa-plus mr-1' />
            {addButtonLabel}
          </Button>
        </div>
      )}
    </Fragment>
  );
};
