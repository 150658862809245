import { useWindowSize } from 'react-use';
import { addDays, } from 'date-fns';

import TenantUserPage from '../hocs/TenantUserPage';
import RichTextContent from '../RichTextContent';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import firebase from '../../firebase';
import { columnWidths } from '../../shared/models/content';
import useAppTitle from '../hooks/useAppTitle';

const db = firebase.firestore();
const contentsRef = db.collection('contents');

export default TenantUserPage(function ContentProduct(props) {
  const { user, tenant, match: { params: { contentProductId }, }, } = props;
  const isTenantStaff = ['admin', 'staff'].includes(user.role) && user.tenantId === tenant.id;
  const { width, height } = useWindowSize();
  const contentOrders = useCollectionSubscription(db.collection('contentOrders').where('createdBy.uid', '==', user.id).where('contentProductIds', 'array-contains', contentProductId), [user, contentProductId]);
  const hasActiveContentOrder = contentOrders.filter(_ => _.startedAt != null && _.cancelledAt == null).some(c => c.contentProductValues.some(_ => _.id === contentProductId && new Date() <= addDays(c.createdAt.toDate(), _.expirationDays)));
  const contentProduct = useDocumentSubscription((isTenantStaff || hasActiveContentOrder) && db.collection('contentProducts').doc(contentProductId), [isTenantStaff, hasActiveContentOrder, contentProductId]);
  const { container = 'container', col = '', } = columnWidths[contentProduct?.columnWidth || 'full'] || {};
  const mainContent = (contentProduct) => {
    const {
      name,
      file: { type, url, } = {},
    } = contentProduct || {};

    if (!url) return;
    // TODO: iframeのレスポンシブ悩ましい（PDFコンテンツ実際使うことになったら必要に応じて考える）
    return [
      {
        regexp: /^video\/.*$/,
        contentProduct: <video title={name} src={url} width="100%" controls controlsList="nodownload" />,
      },
      {
        regexp: /^audio\/.*$/,
        contentProduct: <audio title={name} src={url} controls controlsList="nodownload" />,
      },
      {
        regexp: /^image\/.*$/,
        contentProduct: <img title={name} src={url} width="100%" />,
      },
      {
        regexp: /^application\/pdf$/,
        contentProduct: <iframe title={name} src={`/pdfjs-4.0.269-dist/web/viewer.html?file=${encodeURIComponent(url)}`} width="100%" height={height * 0.6} />,
      },
    ].find(({ regexp }) => type?.match(regexp))?.contentProduct;
  };
  useAppTitle(contentProduct?.title);

  return (
    <div className={`${container} p-3 p-md-5`}>
      <div className="row">
        <div className={`col ${col} d-flex flex-column gap-5 text-center`}>
          <h4>{contentProduct?.name}</h4>
          <RichTextContent html={contentProduct?.description} />
          <div>{mainContent(contentProduct)}</div>
        </div>
      </div>
      {
        !isTenantStaff && contentOrders.length > 0 && !hasActiveContentOrder && (
          <div className="alert alert-danger">
            視聴期限を過ぎています。
          </div>
        )
      }
    </div>
  );
});
