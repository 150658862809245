import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
  FieldPath,
} from '../firebase';
import { tenantQueryConstraints, forwardMatchingQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { TroubleInquiryDocumentData, TroubleInquiry } from '../types';
import type { PartiallyPartial } from 'types';

export const troubleInquiryConverter = getConverter<TroubleInquiryDocumentData>();

export const troubleInquiriesRef = () =>
  collection(getFirestore(), 'troubleInquiries').withConverter(troubleInquiryConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<TroubleInquiry> : null;
export const troubleInquiryRef = <Id extends string | undefined>(troubleInquiryId: Id) =>
  (troubleInquiryId ? doc(troubleInquiriesRef(), troubleInquiryId) : null) as RefOrNull<Id>;

export const newTroubleInquiryRef = () => doc(troubleInquiriesRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'desc')];

export const troubleInquiriesQuery = (...queryConstraints: QueryConstraint[]) =>
  query(troubleInquiriesRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantTroubleInquiriesQuery = (tenantId: string | undefined) =>
  tenantId ? troubleInquiriesQuery(...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

export const forwardMatchingTenantTroubleInquiriesQuery = (
  tenantId: string | undefined,
  fieldPath: string | FieldPath,
  text: string
) =>
  tenantId
    ? troubleInquiriesQuery(...tenantQueryConstraints(tenantId), ...forwardMatchingQueryConstraints(fieldPath, text))
    : null;

export const addTroubleInquiry = async (data: PartiallyPartial<TroubleInquiry, 'id' | 'createdAt'>) =>
  addDoc(troubleInquiriesRef(), { createdAt: serverTimestamp(), ...data });

export const updateTroubleInquiry = async (troubleInquiryId: string, data: UpdateData<TroubleInquiry>) =>
  updateDoc(troubleInquiryRef(troubleInquiryId), data);

export const deleteTroubleInquiry = async (troubleInquiryId: string) => deleteDoc(troubleInquiryRef(troubleInquiryId));

export const useTroubleInquiryCollection = useCollectionData;

export const useTroubleInquiryCollectionOnce = useCollectionDataOnce;

export const useTroubleInquiryDocument = useDocumentData;

export const useTroubleInquiryDocumentOnce = useDocumentDataOnce;
