import {
  getConverter,
  getFirestore,
  collection,
  doc,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getBytes,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';

export const contentConverter = getConverter();

export const contentsRef = () => collection(getFirestore(), 'contents').withConverter(contentConverter);

export const contentRef = (contentId) => (contentId ? doc(contentsRef(), contentId) : null);

export const newContentRef = () => doc(contentsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const contentsQuery = (...queryConstraints) =>
  query(contentsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantContentsQuery = (tenantId, ...queryConstraints) =>
  tenantId ? contentsQuery(where('tenantId', '==', tenantId), ...queryConstraints) : null;

export const addContent = async (data) => addDoc(contentsRef(), { createdAt: serverTimestamp(), ...data });

export const updateContent = async (contentId, data) => updateDoc(contentRef(contentId), data);

export const deleteContent = async (contentId) => deleteDoc(contentRef(contentId));

export const uploadContentFile = async (contentId, file, fileName = 'file') => {
  const path = `contents/${contentId}/${fileName}`;
  const { type, name, size, } = file;
  console.log(file);
  const ref = storageRef(path);
  // NOTE: キャッシュ期限3h
  const metadata = { cacheControl: 'public,max-age=10800', contentType: type };
  await uploadBytes(ref, file, metadata);
  const url = await getDownloadURL(ref);

  return {
    path,
    url,
    type,
    name,
    size,
    uploadedAt: new Date(),
  };
};

export const copyContentFile = async (dstContentId, { name, size, uploadedAt, path, type }) => {
  const srcRef = storageRef(path);
  const data = await getBytes(srcRef);

  const [fileName] = path.split('/').reverse();
  const dstPath = `contents/${dstContentId}/${fileName}`;
  const metadata = { cacheControl: 'public,max-age=10800', contentType: type };
  const dstRef = storageRef(dstPath);
  await uploadBytes(dstRef, data, metadata);
  const url = await getDownloadURL(dstRef);

  return {
    path: dstPath,
    url,
    type,
    name,
    size,
    uploadedAt,
  };
};

export const deleteContentFile = async (path) => deleteObject(storageRef(path));

export const useContentCollection = useCollectionData;

export const useContentCollectionOnce = useCollectionDataOnce;

export const useContentDocument = useDocumentData;

export const useContentDocumentOnce = useDocumentDataOnce;
