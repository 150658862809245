import React, { useRef, useEffect, } from 'react';
import qrcode from 'qrcode';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { Button, Input, } from 'reactstrap';
import { orderBy, isEmpty, get } from 'lodash';

import firebase from '../../firebase';
import { canUpdateShortUrls } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModalButton from '../ModalButton';
import { fields } from '../../shared/models/shortUrl';
import { fields as groupFields } from '../../shared/models/shortUrlGroup';
import QueryBoolean from '../QueryBoolean';
import QuerySelector from '../QuerySelector';
import useQueryParams from '../hooks/useQueryParams';
import { downloadCanvasImage } from '../../util';

const db = firebase.firestore();
const shortUrlsRef = db.collection('shortUrls');
const shortUrlGroupsRef = db.collection('shortUrlGroups');

export default AdminPage(function AdminShortUrls (props) {
  const { user } = props;
  const {
    showsAllShortUrls: _showsAllShortUrls = '0',
    groups: groupsForFilter,
  } = useQueryParams();
  const showsAllShortUrls = _showsAllShortUrls === '1';
  const shortUrls = useCollectionSubscriptionInTenant(shortUrlsRef.orderBy('createdAt'));
  const shortUrlGroups = useCollectionSubscriptionInTenant(shortUrlGroupsRef.orderBy('createdAt', 'desc'));
  const groupOptions = shortUrlGroups.map((_) => ({ label: _.name, value: _.id }));

  let filteredShortUrls = orderBy(shortUrls.map(_ => ({ ..._, groupName: shortUrlGroups.find(group => group.id === _.shortUrlGroupId)?.name })), 'groupName');
  if (!showsAllShortUrls) {
    filteredShortUrls = filteredShortUrls.filter(({ isHidden }) => !isHidden);
  }
  if (!isEmpty(groupsForFilter)) {
    filteredShortUrls = filteredShortUrls.filter((_) => groupsForFilter.includes(get(_, 'shortUrlGroupId')));
  }

  return (
    <div>
      <div className="admin-question-sets container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>短縮URL一覧</h4>
        </div>
        <div className='d-flex justify-content-between flex-wrap gap-2 mb-3'>
          <QuerySelector
            paramName='groups'
            className='ml-0'
            width={400}
            isMulti
            options={groupOptions}
            label='グループで絞込み'
          />
          <div className="d-flex justify-content-end gap-2 align-items-end flex-wrap">
            <QueryBoolean paramName='showsAllShortUrls' label='非表示も表示' defaultValue={'0'} />
            <ModalButton title="グループ管理" content={(_) => <ShortUrlGroups user={user} />} modalProps={{ style: { minWidth: 1300 } }}>
              グループ管理
            </ModalButton>
            <AddInTenantButton itemRef={shortUrlsRef.doc()} FormModal={ModelFormModal} disabled={!canUpdateShortUrls(user)} formProps={{ title: '短縮URL追加', fields: fields({ otherShortUrls: shortUrls, shortUrlGroups }), }} />
          </div>
        </div>
        <div>
          {
            filteredShortUrls.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>グループ</th>
                    <th>名称</th>
                    <th>キー</th>
                    <th>QR内テキスト</th>
                    <th>短縮URL</th>
                    <th>転送先URL</th>
                    <th>非表示</th>
                    <th>用途メモ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredShortUrls.map((shortUrl) => {
                      const { id, ref, name, key, textForQr, destination, isHidden, shortUrlGroupId, memo } = shortUrl;
                      const url = window.location.origin + '/s/' + key;
                      const group = shortUrlGroups.find(_ => _.id === shortUrlGroupId);

                      return (
                        <tr key={id} style={{ background: isHidden ? "lightgray" : "" }}>
                          <td>{group?.name}</td>
                          <td>{name}</td>
                          <td>{key}</td>
                          <td>{textForQr}</td>
                          <td style={{ maxWidth: 250 }}>
                            <a href={url} target="_blank">
                              {url}
                            </a>
                          </td>
                          <td className="text-truncate" style={{ maxWidth: 300 }}>{destination}</td>
                          <td>{isHidden && '非表示'}</td>
                          <td>{memo}</td>
                          <td className="text-nowrap text-right">
                            <ModalButton title={name} content={_ => <Qr url={url} qrKey={key} text={textForQr} />}>
                              QR
                            </ModalButton>
                            <EditButton itemRef={ref} className="ml-2" FormModal={ModelFormModal} disabled={!canUpdateShortUrls(user)} formProps={{ title: '短縮URL編集', fields: fields({ otherShortUrls: shortUrls.filter(_ => _.id !== id), shortUrlGroups }), }} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateShortUrls(user)} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                短縮URLは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

function Qr (props) {
  const { url, text, qrKey } = props;
  const canvasEl = useRef(null);
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };
  useEffect(() => {
    if(canvasEl.current) {
      const size = 350;
      const fontSize = 20;
      qrcode.toCanvas(canvasEl.current, url, { width: size, height: size });
      if (!text) return;

      const ctx = canvasEl.current.getContext('2d');
      ctx.font = `${fontSize}px sans-serif`;
      const { width: textWidth } = ctx.measureText(text);
      const paddingSize = 5;
      const rectWidth = textWidth + paddingSize * 2;
      const textHeight = fontSize;
      const rectHeight = textHeight + paddingSize * 2
      ctx.fillStyle = 'white';
      ctx.fillRect(size / 2 - rectWidth / 2, size / 2 - rectHeight / 2, rectWidth, rectHeight);
      ctx.fillStyle = 'black';
      ctx.fillText(text, size / 2 - textWidth / 2, size / 2 + textHeight / 2 - 3);
    }
  }, [canvasEl, url]);

  const handleQrImageDownload = () => {
    downloadCanvasImage(canvasEl.current, `QR-${text || qrKey}.jpg`);
  }

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="d-flex justify-content-center" title={url}>
          <canvas ref={canvasEl} />
        </div>
        <div className="d-flex w-100 justify-content-end mb-1">
          <Button color="secondary" onClick={handleQrImageDownload}><span className="fas fa-download mr-1" />画像ダウンロード</Button>
        </div>
        <div className="mt-4">
          <div className="d-flex">
            <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
            <Button color="primary" onClick={onClickCopy}>
              <span className="fas fa-copy" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ShortUrlGroups (props) {
  const { user } = props;
  const shortUrlGroups = useCollectionSubscriptionInTenant(shortUrlGroupsRef.orderBy('createdAt', 'desc'), []);

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <AddInTenantButton
          itemRef={shortUrlGroupsRef.doc()}
          FormModal={ModelFormModal}
          formProps={{ title: '短縮URLグループ追加', fields: groupFields() }}
          disabled={!canUpdateShortUrls(user)}
        />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>グループ名</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            orderBy(shortUrlGroups, 'name').map((shortUrlGroup) => {
              const { id, ref, name } = shortUrlGroup;
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td className="text-nowrap text-right">
                    <EditButton size="sm" label={false} itemRef={ref} className="ml-1" FormModal={ModelFormModal} formProps={{ documentName: 'shortUrlGroup', title: '短縮URLグループ編集', fields: groupFields(), }} />
                    <DeleteButton size="sm" label={false} itemRef={ref} className="ml-1" />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
