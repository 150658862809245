const { isEmpty, } = require('lodash');

module.exports = {
  fields: () => {
    return {
      code: {
        label: '商品コード',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      name: {
        label: '商品名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      alertInventory: {
        label: 'アラート在庫数(下回るとバッジ表示)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        initialValue: 0,
        showsTextInput: true,
      },
    };
  },
};
