import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { CouponDocumentData, Coupon } from '../types';
import type { PartiallyPartial } from 'types';

export const couponConverter = getConverter<CouponDocumentData>();

export const couponsRef = () => collection(getFirestore(), 'coupons').withConverter(couponConverter);

type RefOrNull<Id extends string | undefined | null> = Id extends string ? DocumentReference<Coupon> : null;
export const couponRef = <Id extends string | undefined | null>(couponId: Id) =>
  (couponId ? doc(couponsRef(), couponId) : null) as RefOrNull<Id>;

export const newCouponRef = () => doc(couponsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const couponsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(couponsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantCouponsQuery = (tenantId: string | undefined) =>
  tenantId ? couponsQuery(...tenantQueryConstraints(tenantId)) : null;

export const addCoupon = async (data: PartiallyPartial<Coupon, 'id' | 'createdAt'>) =>
  addDoc(couponsRef(), { createdAt: serverTimestamp(), ...data });

export const updateCoupon = async (couponId: string, data: UpdateData<Coupon>) => updateDoc(couponRef(couponId), data);

export const deleteCoupon = async (couponId: string) => deleteDoc(couponRef(couponId));

export const useCouponCollection = useCollectionData;

export const useCouponCollectionOnce = useCollectionDataOnce;

export const useCouponDocument = useDocumentData;

export const useCouponDocumentOnce = useDocumentDataOnce;
