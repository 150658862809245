import React from 'react';
import { toast } from 'react-toastify';
import { sortBy, keyBy, get } from 'lodash';
import { set, } from 'lodash/fp';
import dedent from 'dedent';

import firebase from '../../firebase';
import { canUpdateQrUrl } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import QueryBoolean from '../QueryBoolean';
import DeleteButton from '../DeleteButton';
import RichTextContent from '../RichTextContent';
import { fields as _fields } from '../../shared/models/qrUrl';

const db = firebase.firestore();
const qrUrlsRef = db.collection('qrUrls');
const couponsRef = db.collection('coupons');
const ordersRef = db.collection('orders');
const embededKeywordDescription = dedent`
  代理店名: {{AGENT_NAME}}
  店舗名: {{AGENT_SHOP_NAME}}
  リファラ名: {{REFERRER_NAME}}
`;

export default AdminPage(function AdminQrUrls (props) {
  const { user } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const coupons = useCollectionSubscriptionInTenant(couponsRef.orderBy('createdAt'));
  const couponsById = keyBy(coupons, 'id');
  const fields = set('couponIds.hint', ({ couponIds }) => {
    return couponIds?.map((couponId) => {
      const coupon = couponsById[couponId];
      return (
        <div className="mb-1 card p-2 text-body" key={couponId}>
          <h6>メモ</h6>
          <div style={{ whiteSpace: 'pre-line' }}>{coupon.note}</div>
          <h6 className="mt-2">説明</h6>
          <RichTextContent html={coupon.description} />
        </div>
      );
    });
  }, _fields({ coupons }));
  const qrUrls = sortBy(useCollectionSubscriptionInTenant(qrUrlsRef), _ => _.createdAt.toDate());

  let filteredQrUrls = qrUrls;
  if (showsHidden !== '1') {
    filteredQrUrls = filteredQrUrls.filter(_ => !_.isHidden);
  }

  const renderFormHeader = _ => {
    return (
      <div className="alert alert-info small" style={{ whiteSpace: 'pre-line' }}>
        {embededKeywordDescription}
      </div>
    );
  };

  return (
    <div>
      <div className="admin-qrUrls container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>紹介QRコード遷移先一覧</h4>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-3">
          <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
          <AddInTenantButton itemRef={qrUrlsRef.doc()} FormModal={ModelFormModal} formProps={{ documentName: 'qrUrl', title: '紹介QRコード遷移先 追加', fields, renderFormHeader, }} disabled={!canUpdateQrUrl(user)} />
        </div>
        <div>
          {
            qrUrls.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center text-nowrap">
                  <tr>
                    <th>名称</th>
                    <th>URL</th>
                    <th>優待</th>
                    <th>QRコード表示テキスト</th>
                    <th>メールアドレス入力</th>
                    <th>非表示</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredQrUrls.map((qrUrl) => {
                      const { id, ref, name, url, couponIds = [], textForQr, requestsEmail, emailSkipEnabled = false, isHidden = false, addedBy, updatedBy } = qrUrl;
                      const beforeDelete = async () => {
                        if(
                          (await ordersRef.where('qrUrlId', '==', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                          <td>
                            {name}
                            {
                              updatedBy ? <div className="small text-muted">updated by {get(updatedBy, 'displayName', '')}</div> : <div className="small text-muted">created by {get(addedBy, 'displayName', '')}</div>
                            }
                          </td>
                          <td>
                            <a href={url} target="_blank" className="text-break">
                              {url}
                              <span className="ml-1 fas fa-external-link-alt" />
                            </a>
                          </td>
                          <td>
                            {
                              couponIds?.map((couponId) => {
                                return (
                                  <div key={couponId} className="card p-1 mb-1">
                                    {couponsById[couponId]?.name}
                                  </div>
                                );
                              })
                            }
                          </td>
                          <td>
                            {textForQr}
                          </td>
                          <td>
                            {requestsEmail && '求める'}
                            {emailSkipEnabled && '(スキップ可)'}
                          </td>
                          <td>
                            {isHidden && '非表示'}
                          </td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} disabled={!canUpdateQrUrl(user)} formProps={{ documentName: 'qrUrl', title: '紹介QRコード遷移先 編集', fields, renderFormHeader, }} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateQrUrl(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                紹介QRコード遷移先は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
