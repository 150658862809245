import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Label, } from 'reactstrap';
import { mapValues, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';

import { computeDiscountAmount, isShipmentFree, } from '../../shared/models/coupon';
import { fieldDisplayValue, } from '../../shared/util';
import { newFields as fields, acceptFields, newAgentFields, newUserFields, bankFields, } from '../../shared/models/agent';
import { fields as agentShopFields } from '../../shared/models/agentShop';
import { fields as referrerFields } from '../../shared/models/referrer';
import AppButton from '../AppButton';
import SettingText from '../SettingText';
import AppCardElement from '../AppCardElement';
import env from '../../env';

const { entries, keys } = Object;

export default function NewAgentConfirmForm (props) {
  const { agentRegistration, productsById, onClickBack, user, statedFields, agentShopStatedFields, referrerStatedFields, } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isSubmitting) return;

    toggleSubmitting(true);
    try {
      await props.onSubmit({
      });
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div>
          <div>
            <div className="card border-danger text-danger p-3 text-center font-weight-bold">
              お申込みはまだ完了していません
            </div>
            <h4 className="mt-4 h5 text-center font-weight-bold">
              お申込み内容をご確認ください
            </h4>
          </div>
          <div className="mt-5 d-flex flex-column gap-5">
            <div>
              <h5 className="font-weight-bold">取扱商品</h5>
              <div className="card p-2">
                <div className="d-flex flex-column gap-2">
                  {
                    agentRegistration.productRows.map((productRow, i) => {
                      const { productId, } = productRow;
                      const product = productsById[productId];
                      const usesProductMaster = ['referralFeeAmount', 'referralFeeRate'].every(_ => productRow[_] == null);
                      return (
                        <div key={i} className="d-flex gap-1 align-items-center gap-5">
                          <div>
                            {product?.name}
                          </div>
                          <div className="d-flex justify-content-between" style={{ width: 130 }}>
                            <div>紹介料: </div>
                            <div>
                              {
                                usesProductMaster ? (
                                  product.referralFeeRate != null ? (numeral(product.referralFeeRate).format() + ' %') : ''
                                ) : {
                                  amount: productRow.referralFeeAmount != null ? (numeral(productRow.referralFeeAmount).format() + ' 円') : '',
                                  rate: productRow.referralFeeRate != null ? (numeral(productRow.referralFeeRate).format() + ' %') : '',
                                }[productRow.referralFeeType]
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div>
              <h5 className="font-weight-bold">取扱条件</h5>
              <div className="card p-2" style={{ whiteSpace: 'pre-line', }}>
                {agentRegistration.conditionNote}
              </div>
            </div>
            {
              [
                ['代理店情報', keys(newAgentFields()), statedFields],
                ['アカウント情報', keys(omit(newUserFields(), ['password', 'passwordConfirmation'])), statedFields],
                ['経理担当・振込口座情報', keys(bankFields()), statedFields],
                ['店舗情報', keys(omit(agentShopFields({ forAgent: true }), ['customerCode', 'hidesShopDestination'])), agentShopStatedFields],
                ['リファラ', keys(omit(referrerFields({ forAgent: true }), ['textForQr'])), referrerStatedFields],
              ].flatMap(([title, fieldNames, statedFields]) => {
                return (
                  <div key={title}>
                    <h5 className="font-weight-bold">{title}</h5>
                    <table className="table table-bordered">
                      <tbody>
                        {
                          fieldNames.map((fieldName) => {
                            const fieldSetting = statedFields[fieldName];
                            const { label, } = fieldSetting;
                            const value = fieldDisplayValue(fieldSetting.value, { ...fieldSetting, values: mapValues(statedFields, 'value'), });
                            return (
                              <tr key={fieldName}>
                                <th style={{ width: '40%' }}>{label}</th>
                                <td>{value}</td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="d-flex mt-5">
          <AppButton size="lg" className="cancel flex-fill" color="secondary" onClick={onClickBack} disabled={isSubmitting}>
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton size="lg" color="primary" className="save flex-fill ml-2" type="submit" color="primary" onClick={onSubmit} disabled={isSubmitting}>
            <span className={classnames('fas mr-1', { 'fa-arrow-right': !isSubmitting, 'fa-spin fa-spinner': isSubmitting, })} />
            申し込む
          </AppButton>
        </div>
      </section>
    </Form>
  );
};
