const { omit, sumBy, isEmpty, } = require('lodash');

const { types, valueTypes, } = require('./coupon');

const { entries } = Object;

module.exports = {
  fields: ({ products = [], } = {}) => {
    return {
      type: {
        label: 'クーポン種別',
        type: 'select',
        options: entries(types).map(([k, v]) => ({ label: v.label, value: k })),
        initialValue: 'once',
      },
      name: {
        label: 'テンプレート名',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      productIds: {
        label: '商品',
        type: 'multiSelect',
        options: products.map(_ => ({ ..._, label: _.name, value: _.id })),
      },
      valueType: {
        label: '特典種別',
        type: 'select',
        options: entries(omit(valueTypes, 'amount')).map(([k, v]) => ({ label: v.label, value: k })),
        initialValue: 'amount',
      },
      discountAmount: {
        label: '割引額',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        hidden: _ => _.valueType !== 'amount',
      },
      discountRate: {
        label: '割引率(%)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        hidden: _ => _.valueType !== 'rate',
      },
      giftProductIds: {
        label: 'プレゼント商品',
        type: 'multiSelect',
        options: products.map(_ => ({ ..._, label: _.name, value: _.id })),
        initialValue: [],
        hidden: _ => _.valueType !== 'gift',
      },
      isShipmentFree: {
        label: '送料無料',
        type: 'boolean',
        initialValue: false,
      },
    };
  },
  valueTypes,
};
