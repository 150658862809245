import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Input } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { pick, omitBy, isUndefined } from 'lodash';

import firebase from '../../firebase';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const { origin } = window.location;

export default function EventInvitationModal (props) {
  const { isOpen, eventId, user, onClickClose } = props;
  const [token, setToken] = useState(null);

  useEffect(() => {
    if(isOpen) {
      setToken(null);
    }
  }, [isOpen]);

  const generateInvitationUrl = async () => {
    const token = uuid();
    const { exists, ref } = await eventsRef.doc(eventId).collection('invitations').doc(token).get();
    if(exists) return generateInvitationUrl();
    await ref.set({ tenantId: user.tenantId, token, createdAt: new Date(), addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined), });
    setToken(token);
  };

  const invitationUrl = `${origin}/events/${eventId}/detail?eventInvitationToken=${token}`;

  const onClickCopy = () => {
    copy(invitationUrl);
    toast.success('コピーしました');
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        招待
      </ModalHeader>
      <ModalBody>
        {
          token ? (
            <div>
              <div className="d-flex">
                <Input className="flex-grow-1 mr-2" readOnly defaultValue={invitationUrl} />
                <Button color="primary" onClick={onClickCopy}>
                  <span className="fas fa-copy" />
                </Button>
              </div>
              <div className="small text-muted mt-2 text-center">※一度しか表示されません</div>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <Button color="primary" onClick={generateInvitationUrl}>
                招待URLを取得する
              </Button>
            </div>
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClickClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};
