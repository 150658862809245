import React from 'react';
import { Badge, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { keyBy, isEmpty, sortBy } from 'lodash';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { useAsync } from 'react-use';

import AdminPage from '../hocs/AdminPage';
import firebase, { getFirestore, collection, query, where, getCountFromServer } from '../../firebase';
import { roles, prefectures, deliveryMethods } from '../../shared/config';
import { userConditionText } from '../../shared/util';
import ExportButton from './../ExportButton';
import QueryText from './../QueryText';
import QuerySelector from './../QuerySelector';
import QueryBoolean from './../QueryBoolean';
import useCollectionSubscriptionInTenant from './../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from './../hooks/useCollectionSubscription';
import useQueryParams from './../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import { statuses, fields, orderTypes, entryTypes, dateTypes } from '../../shared/models/smsDelivery';
import { statuses as deliveryTargetStatuses } from '../../shared/models/deliveryTarget';
import TenantLink from '../TenantLink';
import { getCollectionData } from '../../shared/firebase';

const db = firebase.firestore();

export default AdminPage(function AdminSmsDeliveryDeliveryTargets(props) {
  const {
    user,
    match: {
      params: { smsDeliveryId },
    },
  } = props;
  const smsDeliveryRef = db.collection('smsDeliveries').doc(smsDeliveryId);
  const smsDelivery = useDocumentSubscription(smsDeliveryRef);
  const usersRef = db.collection(`smsDeliveries/${smsDeliveryId}/deliveryTargets`);
  const params = useQueryParams();
  const { field: [field] = ['email'], text, showsAllUsers: _showsAllUsers = '0' } = params;
  const showsAllUsers = _showsAllUsers === '1';
  const usersQuery = text
    ? usersRef.orderBy(field).startAt(text).endAt(`${text}\uf8ff`)
    : showsAllUsers
    ? usersRef
    : usersRef.orderBy('createdAt', 'desc').limit(100);
  const users = useCollectionSubscription(usersQuery, [params]);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウント名', value: 'displayName' },
    { label: '電話番号', value: 'phone' },
  ];

  const updateNotDelivery = async (id, value) => {
    await usersRef.doc(id).update({ notDelivery: value });
  };

  const onClickDelivery = async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `smsDeliveries/${smsDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', false),
        where('status', 'in', ['initial', 'failed'])
      )
    );
    if (!window.confirm(`${snapshot.data().count}件が配信対象です。配信しますか？`)) return;

    await smsDeliveryRef.update({ status: 'initial' });
  };

  const deliveryStatus = {
    create: { label: '配信条件保存中', color: 'secondary', disabled: true },
    creating: { label: '配信条件保存中', color: 'secondary', disabled: true },
    preview: { label: '配信実行', color: 'primary', disabled: false },
    initial: { label: '配信中', color: 'secondary', disabled: true },
    processing: { label: '配信中', color: 'secondary', disabled: true },
    completed: { label: '配信完了', color: 'secondary', disabled: true },
    failed: { label: '再配信実行', color: 'primary', disabled: false },
    error: { label: '配信不可', color: 'secondary', disabled: true },
    copied: { label: '配信不可', color: 'secondary', disabled: true },
  }[smsDelivery?.status];

  return (
    <div>
      <div className="admin-users container-fluid py-5 position-relative d-flex flex-column gap-3">
        <div className="d-flex justify-content-center">
          <h4>SMS配信詳細</h4>
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-end">
          <Button
            color={deliveryStatus?.color}
            onClick={onClickDelivery}
            disabled={deliveryStatus?.disabled}
          >
            {deliveryStatus?.label === '配信中' && <span className="fas fa-spin fa-spinner mr-1" />}
            {deliveryStatus?.label}
          </Button>
        </div>
        <SmsDeliveryTable smsDeliveryId={smsDeliveryId} smsDelivery={smsDelivery} />
        <SmsDeliveryReport smsDeliveryId={smsDeliveryId} smsDelivery={smsDelivery} />
        <div className="d-flex justify-content-center">
          <h4>配信対象</h4>
        </div>
        <div className="d-flex flex-wrap gap-2 align-items-end">
          <QuerySelector
            paramName="field"
            options={fieldOptions}
            label="検索フィールド"
            defaultValue={[field]}
            isClearable={false}
          />
          <QueryText paramName="text" label="検索テキスト" />
          <QueryBoolean paramName="showsAllUsers" label="全アカウント表示" defaultValue={'0'} />
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-end">
          <ExportButton
            fileName="users.csv"
            rows={users.map((u) => ({
              ...[
                'id',
                'email',
                'displayName',
                'role',
                'ngDm',
                'note',
                'subUserIds',
                'mainUserId',
                'notDelivery',
              ].reduce((x, k) => ({ ...x, [k]: u[k] }), {}),
              conditionOrderId: u.conditions?.order ? u.conditions.order.id : '',
              conditionOrderUrl: u.conditions?.order
                ? `${window.location.origin}/admin/orders/${u.conditions.order.id}`
                : '',
              conditionEntryPath: u.conditions?.entry ? u.conditions.entry.ref.path : '',
              conditionEntryUrl: u.conditions?.entry
                ? `${window.location.origin}/admin/${u.conditions.entry.ref.path}`
                : '',
              conditionChildren: u.conditions?.children ? u.conditions.children.map((_) => _.name).join(' ') : '',
              conditionAccountUrl: u.conditions?.children ? `${window.location.origin}/admin/users/${u.id}` : '',
              status: u.disabledAt ? '無効' : '',
            }))}
            hasPersonalInfo
            user={user}
          />
          <ExportButton
            label="全件エクスポート"
            fileName="all_users.csv"
            rows={async () => {
              const allusers = await getCollectionData(usersRef);
              return allusers.map((u) => ({
                ...[
                  'id',
                  'email',
                  'displayName',
                  'role',
                  'ngDm',
                  'note',
                  'subUserIds',
                  'mainUserId',
                  'notDelivery',
                ].reduce((x, k) => ({ ...x, [k]: u[k] }), {}),
                conditionOrderId: u.conditions?.order ? u.conditions.order.id : '',
                conditionOrderUrl: u.conditions?.order
                  ? `${window.location.origin}/admin/orders/${u.conditions.order.id}`
                  : '',
                conditionEntryPath: u.conditions?.entry ? u.conditions.entry.ref.path : '',
                conditionEntryUrl: u.conditions?.entry
                  ? `${window.location.origin}/admin/${u.conditions.entry.ref.path}`
                  : '',
                conditionChildren: u.conditions?.children ? u.conditions.children.map((_) => _.name).join(' ') : '',
                conditionAccountUrl: u.conditions?.children ? `${window.location.origin}/admin/users/${u.id}` : '',
                status: u.disabledAt ? '無効' : '',
              }));
            }}
            hasPersonalInfo
            user={user}
          />
        </div>
        <div className="overflow-auto">
          {users.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 50 }}>ステータス</th>
                  <th style={{ minWidth: 150 }}>判定条件</th>
                  <th style={{ minWidth: 150 }}>アカウント名</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 100 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 100 }}>権限</th>
                  <th style={{ minWidth: 100 }}>DM発送不可</th>
                  <th style={{ minWidth: 150 }}>ユーザータグ</th>
                  <th style={{ minWidth: 200 }}>メモ</th>
                  <th>配信対象</th>
                </tr>
              </thead>
              <tbody>
                {(users || []).map((user) => {
                  const {
                    id,
                    email,
                    displayName,
                    prefecture,
                    phone,
                    role = 'user',
                    ngDm = false,
                    note,
                    city,
                    userTagIds,
                    mainUserId,
                    subUserIds,
                    disabledAt,
                    conditions,
                    notDelivery,
                    status,
                  } = user;
                  const { label: statusLabel, color } = deliveryTargetStatuses[status] || {};
                  return (
                    <tr key={id} style={{ background: notDelivery ? 'lightgray' : '' }}>
                      <td>
                        <Badge color={color}>{statusLabel}</Badge>
                      </td>
                      <td>
                        {conditions?.order && (
                          <div>
                            <Badge className="mr-1">注文条件</Badge>
                            <TenantLink to={`/admin/orders/${conditions.order.id}`}>{conditions.order.id}</TenantLink>
                          </div>
                        )}
                        {conditions?.entry && (
                          <div>
                            <Badge className="mr-1">イベント参加条件</Badge>
                            <TenantLink to={`/admin/${conditions.entry.ref.path}`}>{conditions.entry.id}</TenantLink>
                          </div>
                        )}
                        {conditions?.children && (
                          <div>
                            <Badge className="mr-1">年齢・乗り物経験</Badge>
                            <TenantLink to={`/admin/users/${id}`}>{conditions.children[0].name}</TenantLink>
                          </div>
                        )}
                      </td>
                      <td>
                        <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
                        <div>
                          {!isEmpty(mainUserId) && <div className="badge badge-warning">サブアカウント</div>}
                          {!isEmpty(subUserIds) && (
                            <div className="badge badge-info">サブアカウントあり {subUserIds.length}件</div>
                          )}
                        </div>
                        {disabledAt && <Badge color="danger">無効</Badge>}
                      </td>
                      <td>{email}</td>
                      <td>{prefecture && prefectures[prefecture]}</td>
                      <td>{city}</td>
                      <td>{phone}</td>
                      <td>{roles[role]}</td>
                      <td>{ngDm && '発送不可'}</td>
                      <td>
                        <div className="d-flex flex-wrap gap-1">
                          {(userTagIds || []).map((_) => (
                            <div key={_} className="badge badge-secondary">
                              {userTagsById[_]?.name}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>{note}</td>
                      <td>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={notDelivery}
                              onChange={(_) => updateNotDelivery(id, _.target.checked)}
                            />
                            除外する
                          </Label>
                        </FormGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});

const SmsDeliveryTable = (props) => {
  const { smsDeliveryId, smsDelivery } = props;
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const productTypesById = keyBy(productTypes, 'id');
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const deliveryCandidates = useCollectionSubscriptionInTenant(
    db.collection(`smsDeliveries/${smsDeliveryId}/deliveryCandidates`)
  );
  if (!smsDelivery) return <></>;
  const {
    id,
    status,
    completedAt,
    orderType = 'any',
    conditionProductTypeIds,
    conditionProductIds,
    entryType = 'any',
    conditionEventIds,
    dateType,
    emailSubject,
    emailBody,
    smsBody,
    deliveryMethod,
    createdAt,
  } = smsDelivery;
  const { label: statusLabel, color } = statuses[status] || {};
  return (
    <table className="table">
      <thead className="thead-light text-center text-nowrap">
        <tr>
          <th>配信日時</th>
          <th>ステータス</th>
          <th>配信方式</th>
          <th>注文条件</th>
          <th>イベント参加条件</th>
          <th>アカウント条件</th>
          <th>日付範囲</th>
          <th>対象件数</th>
          <th>Eメール件名</th>
          <th>本文</th>
          <th>作成日時</th>
        </tr>
      </thead>
      <tbody>
        <tr key={id}>
          <td style={{ whiteSpace: 'pre-line' }}>
            {completedAt ? formatDate(completedAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : '未配信'}
          </td>
          <td>
            <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
          </td>
          <td>{deliveryMethod && deliveryMethods[deliveryMethod]?.label}</td>
          <td>
            {orderTypes[orderType]?.label}
            {orderType === 'ordered' && (
              <div>
                <div className="small text-muted">
                  {conditionProductTypeIds?.map((_) => productTypesById[_]?.name).join(',')}
                </div>
                <div className="small text-muted">
                  {conditionProductIds
                    ?.map((_) => productsById[_])
                    .map((_) => `[${_?.code}] ${_?.name}`)
                    .join(',')}
                </div>
              </div>
            )}
          </td>
          <td>
            {entryTypes[entryType]?.label}
            {entryType === 'entried' && (
              <div className="small text-muted">{conditionEventIds?.map((_) => eventsById[_]?.name).join(',')}</div>
            )}
          </td>
          <td className="text-muted small">{userConditionText(smsDelivery)}</td>
          <td>
            <span>{dateTypes[dateType]?.text({ data: smsDelivery })}</span>
          </td>
          <td style={{ whiteSpace: 'pre-line' }} className="text-right">
            {numeral(deliveryCandidates.reduce((x, y) => x + y.deliveryCount, 0)).format('0,0')}
          </td>
          <td style={{ whiteSpace: 'pre-line' }}>{emailSubject}</td>
          <td style={{ whiteSpace: 'pre-line' }}>{emailBody || smsBody}</td>
          <td style={{ whiteSpace: 'pre-line' }}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
        </tr>
      </tbody>
    </table>
  );
};

const SmsDeliveryReport = (props) => {
  const { smsDeliveryId, smsDelivery } = props;
  const { value: notDeliveryCount } = useAsync(async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `smsDeliveries/${smsDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', true)
      )
    );
    return snapshot.data().count;
  }, [smsDeliveryId]);
  const { value: initialCount } = useAsync(async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `smsDeliveries/${smsDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', false),
        where('status', '==', 'initial')
      )
    );
    return snapshot.data().count;
  }, [smsDeliveryId]);
  const { value: requestedCount } = useAsync(async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `smsDeliveries/${smsDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', false),
        where('status', '==', 'requested')
      )
    );
    return snapshot.data().count;
  }, [smsDeliveryId]);
  const { value: deliveredCount } = useAsync(async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `smsDeliveries/${smsDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', false),
        where('status', '==', 'delivered')
      )
    );
    return snapshot.data().count;
  }, [smsDeliveryId]);
  const { value: failedCount } = useAsync(async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `smsDeliveries/${smsDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', false),
        where('status', '==', 'failed')
      )
    );
    return snapshot.data().count;
  }, [smsDeliveryId]);

  if (!smsDelivery) return <></>;
  return (
    ['completed', 'failed'].includes(smsDelivery.status) && (
      <Alert>
        <div>
          <b>配信結果</b>
        </div>
        <div>
          配信対象外：{notDeliveryCount} 未配信：{initialCount} 配信済み：{requestedCount} 配信完了(既読)：{deliveredCount} 配信失敗：{failedCount}
        </div>
      </Alert>
    )
  );
};
