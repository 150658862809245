import React, { Fragment, } from 'react';
import { sumBy, groupBy, chunk, mapValues, orderBy, get, pick, omit, uniqBy, keyBy, } from 'lodash';
import { format as formatDate, } from 'date-fns';
import numeral from 'numeral';
import ellipsis from 'text-ellipsis';

import { deliveryTimes, prefectures, } from '../shared/config';
import firebase from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import SettingText from './SettingText';

const db = firebase.firestore();

export default function DeliverySlip (props) {
  const { tenant, shipping, productsById, } = props;
  const sourceOrder = useDocumentSubscription(shipping.sourceOrderId && db.collection('orders').doc(shipping.sourceOrderId), [shipping.sourceOrderId]);
  console.log(sourceOrder);

  return (
    <div className="delivery-slip d-flex justify-content-center" style={{ fontSize: 15, }}>
      <div className="d-flex flex-column gap-4 p-5" style={{ width: 1000 }}>
        <div className="d-flex justify-content-end">
          <div>
            <img src={tenant?.logo?.downloadUrl} style={{ maxHeight: 30, maxWidth: 300, }} />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <h1 className="font-weight-bold" style={{ fontSize: 35 }}>納品書</h1>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <SettingText name="deliverySlipHeaderText" />&nbsp;
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div className="d-flex border-bottom p-2 justify-content-between" style={{ width: 300 }}>
            <span>{shipping.destinationName}</span>
            <span>様</span>
          </div>
          <table className="table table-bordered m-0 w-auto table-sm">
            <tbody>
              <tr>
                <td className="text-center">No.</td>
                <td className="text-center" style={{ width: 300 }}>{shipping.id}</td>
              </tr>
              <tr>
                <td className="text-center">発行日</td>
                <td className="text-center">{formatDate(new Date(), 'yyyy/MM/dd')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <div>
              {shipping.destinationPostalCode.slice(0, 3) + '-' + shipping.destinationPostalCode.slice(3)}
            </div>
            <div>
              {[prefectures[shipping.destinationPrefecture], shipping.destinationCity, shipping.destinationAddress].join('')}
            </div>
            <div>
              {shipping.destinationPhone}
            </div>
          </div>
          <div>
            <div>{tenant.name}</div>
            <div>
              {tenant.postalCode.slice(0, 3) + '-' + tenant.postalCode.slice(3)}
            </div>
            <div>
              {[prefectures[tenant.prefecture], tenant.city, tenant.address1].join('')}
            </div>
            <div>
              {tenant.address2}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <table className="table table-bordered table-sm m-0">
            <thead className="thead-light text-center">
              <tr>
                <th style={{ }}>商品番号／商品名</th>
                <th style={{ width: 120 }}>単価(税込)</th>
                <th style={{ width: 80 }}>数量</th>
                <th style={{ width: 120 }}>金額(税込)</th>
              </tr>
            </thead>
            <tbody>
              {
                shipping.items.map((item, i) => {
                  const { productId, quantity, } = item;
                  const product = productsById[productId];
                  const orderItem = sourceOrder?.orderItems.find(_ => _.productId === productId);
                  return (
                    <tr key={i} style={{ height: 58, }}>
                      <td className="align-middle">
                        <div>{productId}</div>
                        <div>{ellipsis(product?.name || '', 55)}</div>
                      </td>
                      <td className="text-right align-middle">
                        {numeral(orderItem?.price).format()}
                      </td>
                      <td className="text-right align-middle">
                        {numeral(quantity).format()}
                      </td>
                      <td className="text-right align-middle">
                        {numeral(orderItem?.price).format()}
                      </td>
                    </tr>
                  )
                })
              }
              {
                Array(Math.max(12 - shipping.items.length, 0)).fill().map((i) => {
                  return (
                    <tr key={i} className="text-center" style={{ height: 58, }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <table className="table table-bordered table-sm m-0">
            <tbody>
              <tr>
                <td rowSpan={4}>
                  備考欄
                </td>
                <td style={{ width: 160, }}>
                  合計(税込)
                </td>
                <td className="text-right align-middle" style={{ width: 320, }}>
                  {numeral(sourceOrder?.amount).format()}
                </td>
              </tr>
              <tr>
                <td>
                  割引金額
                </td>
                <td className="text-right align-middle">
                  {numeral(sourceOrder?.discountAmount).format()}
                </td>
              </tr>
              <tr>
                <td>
                  送料(税込)
                </td>
                <td className="text-right align-middle">
                  {numeral(sourceOrder?.shipmentFee).format()}
                </td>
              </tr>
              <tr>
                <td>
                  お支払総額(税込)
                </td>
                <td className="text-right align-middle">
                  {numeral(sourceOrder?.amount - sourceOrder?.discountAmount + sourceOrder?.shipmentFee).format()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ width: 500, }}>
            <SettingText name="deliverySlipBottomLeft" />
          </div>
          <div style={{ width: 500, }}>
            <SettingText name="deliverySlipBottomRight" />
          </div>
        </div>
      </div>
    </div>
  );
}
