const { omit, sumBy, isEmpty, } = require('lodash');
const { startOfDay, } = require('date-fns');

const { entries } = Object;

const types = {
  once: {
    label: '1回限り',
  },
  unlimited: {
    label: '制限なし',
  },
};
const valueTypes = {
  amount: {
    label: '割引(金額)',
    compute: (amount, _) => Math.min(amount, _.discountAmount),
  },
  rate: {
    label: '割引(率)',
    compute: (amount, _) => Math.round(amount * _.discountRate * 0.01),
  },
  gift: {
    label: 'プレゼント',
    compute: (amount) => amount,
  },
};
const statuses = {
  initial: { label: '利用可', color: 'info', },
  used: { label: '利用済み', color: 'success', },
  disabled: { label: '無効', color: 'secondary', },
};

const computeDiscountAmount = (items, coupon) => {
  const targetItems = coupon.valueType === 'gift' ? items.filter(_ => _.isGift) : items.filter(_ => coupon.productIds.includes(_.productId));
  const amount = sumBy(targetItems, _ => _.product.price * _.quantity);
  return valueTypes[coupon.valueType].compute(amount, coupon);
};

const isShipmentFree = (items, coupon) => {
  const targetItems = items.filter(_ => coupon.productIds.includes(_.productId));
  return targetItems.length > 0 && coupon.isShipmentFree;
};

module.exports = {
  fields: ({ products = [], } = {}) => {
    return {
      type: {
        label: 'クーポン種別',
        type: 'select',
        options: entries(types).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
        initialValue: 'once',
      },
      name: {
        label: 'クーポン名',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      productIds: {
        label: '商品',
        type: 'multiSelect',
        options: products.map(_ => ({ ..._, label: _.label || _.name, value: _.id })),
      },
      valueType: {
        label: '割引種別',
        type: 'select',
        options: entries(omit(valueTypes, 'amount')).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
        initialValue: 'amount',
      },
      discountAmount: {
        label: '割引額',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        hidden: _ => _.valueType !== 'amount',
      },
      discountRate: {
        label: '割引率(%)',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        hidden: _ => _.valueType !== 'rate',
      },
      giftProductIds: {
        label: 'プレゼント商品',
        type: 'multiSelect',
        options: products.map(_ => ({ ..._, label: _.label || _.name, value: _.id })),
        initialValue: [],
        hidden: _ => _.valueType !== 'gift',
      },
      isShipmentFree: {
        label: '送料無料',
        type: 'boolean',
        initialValue: false,
      },
      startDate: {
        label: '開始日',
        type: 'date',
        initialValue: null,
      },
      deadlineDate: {
        label: '終了日',
        type: 'date',
        initialValue: null,
      },
      status: {
        label: '状態',
        type: 'select',
        options: _ => entries(_.type === 'unlimited' ? omit(statuses, 'used') : statuses).map(([k, v]) => ({ label: v.label, value: k })),
        initialValue: 'initial',
      },
      disabilityMessage: {
        label: '無効メッセージ',
        type: 'text',
        hidden: _ => _.status !== 'disabled',
      },
      description: {
        label: '説明',
        type: 'richText',
      },
      note: {
        label: 'メモ',
        type: 'text',
      },
    };
  },
  computeDiscountAmount,
  isShipmentFree,
  valueTypes,
  statuses,
  types,
};
