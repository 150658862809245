import { toast } from 'react-toastify';
import { omitBy, isUndefined, sortBy, keyBy, pick } from 'lodash';

import { serverTimestamp } from '../../firebase';
import { canUpdatePlaces } from '../../shared/abilities';
import { prefectures } from '../../shared/config';
import { checkPlaceUniqueness } from '../../util';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import { AddInTenantButton } from '../v9/AddInTenantButton';
import { EditButton } from '../v9/EditButton';
import { DeleteButton } from '../v9/DeleteButton';
import { ImportButton } from '../v9/ImportButton';
import ExportButton from '../ExportButton';
import { fields } from '../../shared/models/place';
import useTenant from '../hooks/useTenant';
import { usePlaceCollection, tenantPlacesQuery, placeRef, newPlaceRef } from '../../models/place';
import { getEventCollection, eventsByPlaceIdQuery } from 'src/models/event';

const { entries } = Object;
const prefecturesByName = entries(prefectures).reduce((x, [k, v]) => ({ ...x, [v]: k }), {});

export default AdminPage(function AdminPlaces(props) {
  const { user } = props;
  const tenant = useTenant();
  const { data: places = [] } = usePlaceCollection(tenantPlacesQuery(tenant?.id));
  const sortedPlaces = sortBy(places, (_) => parseInt(_.prefecture, 10));
  const placesById = keyBy(places, 'id');
  const validateOnSubmit = async (values) => {
    const isUnique = await checkPlaceUniqueness(values);
    if (isUnique) {
      return true;
    } else {
      toast.error('その開催場所名は該当都道府県にすでに存在します');
      return false;
    }
  };
  const processRow = (batch, { id, name, prefecture }) => {
    const exists = placesById[id] != null;
    if (exists) {
      batch.update(placeRef(id), {
        name,
        prefecture: prefecturesByName[prefecture],
        updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      });
    } else {
      // NOTE: なぜかdataにidが必須と怒られるので一旦anyで逃げる
      batch.set(newPlaceRef(), {
        name,
        prefecture: prefecturesByName[prefecture],
        addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
        createdAt: serverTimestamp(),
        tenantId: tenant.id,
      });
    }
  };
  const placesForExport = places.map((_) => ({
    ...pick(_, ['id', 'name']),
    prefecture: prefectures[_.prefecture],
  }));

  return (
    <div>
      <div className="admin-places container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>開催場所一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <ImportButton processRow={processRow} disabled={!canUpdatePlaces(user)} />
          <ExportButton className="ml-2" fileName="開催場所.csv" rows={placesForExport} />
          <AddInTenantButton
            className="ml-2"
            itemRef={newPlaceRef()}
            FormModal={ModelFormModal}
            validateOnSubmit={validateOnSubmit}
            formProps={{ title: '開催場所追加', fields }}
            disabled={!canUpdatePlaces(user)}
          />
        </div>
        <div>
          {places.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th>場所名</th>
                  <th>都道府県</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortedPlaces.map((place) => {
                  const { id, name, prefecture } = place;
                  const beforeDelete = async () => {
                    const events = await getEventCollection(eventsByPlaceIdQuery(id, 1));
                    if (events.length > 0) {
                      toast.error('使用されているため削除できません');
                      return false;
                    }
                    return true;
                  };

                  return (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{prefectures[prefecture]}</td>
                      <td className="text-right">
                        <EditButton
                          itemRef={placeRef(id)}
                          FormModal={ModelFormModal}
                          validateOnSubmit={validateOnSubmit}
                          formProps={{ title: '開催場所編集', fields }}
                          disabled={!canUpdatePlaces(user)}
                        />
                        <DeleteButton
                          itemRef={placeRef(id)}
                          className="ml-2"
                          disabled={!canUpdatePlaces(user)}
                          beforeDelete={beforeDelete}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>開催場所は未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});
