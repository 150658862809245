import { useState, useEffect } from 'react';

export default function useCollectionFetch (ref, dependencies = [], { initialItems = [] } = {}) {
  const [items, setItems] = useState(initialItems);
  useEffect(() => {
    if(!ref) return;

    (async () => {
      try {
        await ref
          .get()
          .then(({ docs }) => {
            setItems(docs.map(_ => ({ id: _.id, ref: _.ref, ..._.data() })));
          });
      } catch(e) {
        console.log(e, ref);
      }
    })();
  }, dependencies);
  return items;
};
