import React from 'react';
import { useToggle } from 'react-use';
import { format as formatDate } from 'date-fns';
import { Input } from 'reactstrap';
import { isEmpty, get } from 'lodash';
import { decycle } from 'json-cyclic';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import ModalButton from '../ModalButton';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import QuerySelector from '../QuerySelector';
import QueryInput from '../QueryInput';

const { entries } = Object;
const db = firebase.firestore();
const auditLogsRef = db.collection('auditLogs');
const collections = {
  products: { label: '商品', },
  productTypes: { label: '商品種別', },
  agents: { label: '代理店', },
  events: { label: 'イベント', },
  surveys: { label: 'アンケートページ', },
  inquiries: { label: '問合せ', },
  troubleInquiries: { label: '不具合・組立問合せ', },
  users: { label: 'アカウント', },
  coupons: { label: '優待', },
  magazines: { label: 'ステップSMS', },
  contents: { label: 'コンテンツ', },
  initialTags: { label: '初期化タグ', },
};
const collectionIdOptions = entries(collections).map(([k, v]) => ({ label: v.label, value: k, }));

export default AdminPage(function AdminAuditLogs(props) {
  const {
    collectionIds: [collectionId] = [],
    docId,
  } = useQueryParams();
  const [showsSystemAuditLogs, toggleSystemAuditLogs] = useToggle(false);
  // NOTE: とりあえず3000件。ページネーションを別途。
  let ref = auditLogsRef.orderBy('createdAt', 'desc').limit(3000)
  if(collectionId) {
    ref = ref.where('path.collectionId', '==', collectionId);
  }
  if(docId) {
    ref = ref.where('path.docId', '==', docId);
  }
  const { items: auditLogs, isLoading } = useCollectionSubscriptionInTenant(ref, [collectionId, docId], { detail: true });
  const shownAuditLogs = showsSystemAuditLogs
    ? auditLogs
    : auditLogs.filter((_) => _.operatedBy?.displayName !== 'System');

  return (
    <div>
      <div className='admin-agents container py-5 position-relative'>
        <div className='d-flex justify-content-center mb-3'>
          <h4>操作ログ</h4>
        </div>
        <div className='d-flex justify-content-between align-items-end mb-2'>
          <div className='d-flex gap-1 align-items-end'>
            <QuerySelector
              paramName='collectionIds'
              width={250}
              options={collectionIdOptions}
              label='対象種別で絞込み'
            />
            {!isEmpty(collectionId) && <QueryInput paramName="docId" label="ID" />}
          </div>
          <div>
            <Input type='checkbox' checked={showsSystemAuditLogs} onChange={toggleSystemAuditLogs} />{' '}
            Systemの操作ログを表示する
          </div>
        </div>
        <div>
          {
            isLoading ? (
              <div>
                <span className="fas fa-spin fa-spinner" />
              </div>
            ) : (
              <table className='table'>
                <thead className='thead-light text-center'>
                  <tr>
                    <th>日時</th>
                    <th>操作者</th>
                    <th>種別</th>
                    <th style={{ width: '30%' }}>対象</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {shownAuditLogs.map((auditLog) => {
                    const { id, operatedBy, createdAt, operationType, path, before, after } = auditLog;
                    const displayPath = ['collectionId', 'docId', 'collectionId2', 'docId2', 'collectionId3', 'docId3']
                      .map((_) => path[_])
                      .filter((_) => _)
                      .join('/');

                    return (
                      <tr key={id}>
                        <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                        <td>{get(operatedBy, 'displayName')}</td>
                        <td>{operationType}</td>
                        <td className='text-break'>{displayPath}</td>
                        <td className='text-nowrap text-right'>
                          <ModalButton
                            label='詳細'
                            modalProps={{ style: { minWidth: '80vw' } }}
                            content={({ toggleModal }) => {
                              return (
                                <div>
                                  <div>
                                    {operationType} {displayPath}
                                  </div>
                                  <div className='mt-2 d-flex gap-1'>
                                    <div className='card p-3' style={{ flexBasis: '50%' }}>
                                      <h5>変更前</h5>
                                      <pre>{before && JSON.stringify(decycle(before), null, 2)}</pre>
                                    </div>
                                    <div className='card p-3' style={{ flexBasis: '50%' }}>
                                      <h5>変更後</h5>
                                      <pre>{after && JSON.stringify(decycle(after), null, 2)}</pre>
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )
          }
        </div>
      </div>
    </div>
  );
});
