import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { isEmpty, sortBy } from 'lodash';

import firebase from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { fields } from '../../shared/models/magazine';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const magazinesRef = db.collection('magazines');
const magazineGroupsRef = db.collection('magazineGroups');
const { entries } = Object;

export default AdminPage(function AdminMagazine(props) {
  const {
    match: {
      params: { magazineId },
    },
  } = props;
  const magazine = useDocumentSubscription(magazinesRef.doc(magazineId), [magazineId]);
  const deliveryHistoriesRef = db.collection(`magazines/${magazineId}/deliveryHistories`);
  const deliveryHistories = useCollectionSubscriptionInTenant(deliveryHistoriesRef.limit(1));
  const magazineGroups = useCollectionSubscriptionInTenant(magazineGroupsRef);
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), _ => _.createdAt.toDate());
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [magazine]);

  return (
    magazine != null && (
      <div className="admin-user container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>SMS配信詳細</h4>
          </div>
          <div className="d-flex justify-content-end mb-3 gap-2 flex-wrap text-nowrap">
            {isEmpty(deliveryHistories) || (
              <Button tag={TenantLink} to={`/admin/magazines/${magazine.id}/deliveryHistories`} target="_blank">
                配信履歴
              </Button>
            )}
          </div>
          <div>
            <table className="table table-bordered">
              <tbody className="thead-light">
                {entries({
                  ...fields({ products, productTypes, events, userTags, magazineGroups }),
                }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  const displayValue = fieldDisplayValue(magazine[fieldName], fieldSetting)
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td style={{ whiteSpace: 'pre-line' }}>{Array.isArray(displayValue) ? displayValue.join(', ') : displayValue}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
});
