const { isHiragana } = require('wanakana');
const { omit, pick, inRange, isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');
const { differenceInYears, } = require('date-fns');
const dedent = require('dedent');

const { prefectures, roles, routes, childGenders, vehicleExperiences, deliveryMethods } = require('../config');
const { agentMemberFields, } = require('./agent');

const { entries } = Object;
const currentYear = (new Date()).getFullYear();

const fields = ({ role, magazineGroups = [], abroad = false, } = {}) => ({
  email: {
    type: 'string',
    label: 'メールアドレス',
    validations: {
      required: v => !isEmpty(v),
      format: v => !v || isEmail(v),
      notMultibyte: v => !v || !isMultibyte(v),
    },
    hint: dedent`
      携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
      詳しくは各携帯電話会社にご確認下さい。
    `,
  },
  displayName: {
    type: 'string',
    label: 'お名前',
    validations: {
      required: v => !isEmpty(v),
    },
  },
  nameKana: {
    type: 'string',
    label: 'お名前ふりがな',
    validations: {
      hiragana: v => isEmpty(v) || isHiragana((v || '').replace(/[ 　]/g, '')),
    },
  },
  phone: {
    type: 'string',
    label: '電話番号',
    validations: {
      required: v => !isEmpty(v),
      ...(!abroad && { format: v => !v || v.match(/^(0{1}\d{9,10})$/) }),
    },
    ...(
      !abroad && {
        warnings: {
          cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
        },
        placeholder: '08012345678 （ハイフンなし）'
      }
    ),
  },
  postalCode: {
    type: 'string',
    label: '郵便番号',
    validations: {
      required: v => !isEmpty(v),
      format: v => (v || '').match(/^[0-9]{7}$/g),
    },
    inputProps: {
      type: 'tel',
    },
  },
  prefecture: {
    type: 'select',
    label: '都道府県',
    options: entries(prefectures).map(([k, v]) =>  ({ label: v, value: k })),
    validations: {
      required: v => !isEmpty(v),
    },
  },
  city: {
    type: 'string',
    label: '市区町村',
    validations: {
      required: v => !isEmpty(v),
    },
  },
  address: {
    type: 'string',
    label: '番地・建物名',
    validations: {
      required: v => !isEmpty(v),
    },
  },
  route: {
    type: 'select',
    label: 'どちらでお知りになりましたか？',
    options: routes.map(_ => ({ value: _, label: _ })),
    validations: {
      required: v => !isEmpty(v),
    },
  },
  password: {
    type: 'password',
    label: 'パスワード',
    validations: {
      required: v => !isEmpty(v),
      minLength: v => (v || '').length >= 6,
    },
    placeholder: '6文字以上'
  },
  currentPassword: {
    type: 'password',
    label: '現在のパスワード',
    validations: {
      required: v => !isEmpty(v),
    },
  },
  passwordConfirmation: {
    type: 'password',
    label: 'パスワード確認',
    validations: {
      required: (v, { password }) => !isEmpty(v),
      samePassword: (v, { password }) => v === password,
    },
  },
  ...(
    ['admin', 'staff'].includes(role) && {
      trs110User: {
        type: 'string',
        label: '[スタッフ向け] TRS110 USER',
      },
      trs110Password: {
        type: 'password',
        label: '[スタッフ向け] TRS110 PASSWORD',
      },
    }
  ),
});

const blockSmsFields = ({ tenants, magazineGroupsGroupedByTenantId }) => {
  return {
    ...tenants.reduce((x, tenant) => {
      const magazineGroups = magazineGroupsGroupedByTenantId[tenant.id] || [];
      return {
        ...x,
        [`deliveryMethod__${tenant.id}`]: {
          type: 'select',
          label: '通知方法を選択する',
          initialValue: 'email',
          options: Object.entries(deliveryMethods)
            .filter(([k, v]) => tenant.useSms || k !== 'sms')
            .filter(([k, v]) => tenant.useMobile || k !== 'mobile')
            .filter(([key]) => key !== 'emailAndMobile')
            .map(([key, { label }]) => ({ label, value: key })),
        },
        [`blocksSms__${tenant.id}`]: {
          type: 'boolean',
          label: `SMS/メール配信（お客様サポート）を停止する`,
          initialValue: false,
        },
        [`blocksMagazineGroupIds__${tenant.id}`]: {
          type: 'multiSelect',
          label: `種類毎にSMS/メール配信を停止する`,
          options: magazineGroups.map(_ => ({ label: _.name, value: _.id })),
          initialValue: []
        }
      };
    }, {}),
  };
};

const agentUserFields = ({ agentShops }) => {
  return {
    email: omit(fields().email, 'hint'),
    ...pick(fields(), ['displayName', 'password']),
    ...agentMemberFields({ agentShops }),
  };
};

const errorMessages = {
  'auth/invalid-email': 'メールアドレスの形式が正しくありません',
  'auth/user-not-found': 'アカウントが存在しません',
  'auth/email-already-exists': 'すでにそのメールアドレスは登録されています。',
};

const commentFields = () => {
  return {
    body: {
      type: 'text',
      label: 'コメント内容',
      validations: {
        required: _ => !isEmpty(_),
      },
    },
  };
};

const auditData = (user) => pick(user, ['uid', 'email', 'displayName']);

module.exports = {
  fields,
  agentUserFields,
  fieldsToSignIn: {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
      },
    }
  },
  childFields: () => {
    return {
      name: {
        type: 'string',
        label: 'お名前',
        validations: {
          required: v => !isEmpty(v),
          hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
        },
        placeholder: 'たろう',
      },
      birthday: {
        label: '生年月日',
        type: 'date',
        selector: true,
        yearRange: [currentYear - 30, currentYear],
        validations: {
          required: v => !!v,
        },
        initialValue: new Date(),
      },
      gender: {
        label: '性別',
        type: 'select',
        options: entries(childGenders).map(([k, v]) => ({ label: v, value: k})),
        validations: {
          required: v => !!v,
        },
      },
      vehicleExperiences: {
        label: '乗り物経験',
        type: 'multiSelect',
        options: vehicleExperiences.map(_ => ({ value: _, label: _, })),
        initialValue: [],
        validations: {
          required: v => !isEmpty(v),
        },
      },
    };
  },
  adminFields: ({ userTags = [], onCreateUserTag = () => null, } = {}) => {
    return {
      role: {
        type: 'select',
        label: '権限',
        options: entries(roles).map(([k, v]) =>  ({ label: v, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      ngDm: {
        type: 'boolean',
        label: 'DM発送不可',
        initialValue: false,
      },
      note: {
        type: 'text',
        label: 'メモ',
      },
      userTagIds: {
        label: 'ユーザータグ',
        type: 'creatableMultiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
        onCreateMultiSelectValue: onCreateUserTag
      },
    };
  },
  errorMessages,
  commentFields,
  vehicleExperiences,
  auditData,
  blockSmsFields,
};
