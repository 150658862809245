import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import { keyBy, omit, mapValues, } from 'lodash';
import { useList, } from 'react-use';

import { childFields, } from '../../shared/models/user';
import ChildForm from '../forms/ChildForm';
import ListForm from '../ListForm';

export default function ChildrenFormModal(props) {
  const { isOpen, values, onClickClose, } = props;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.children || []);
  const isUnsubmittable = items.some(_ => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    props.onSubmit({ children: items.map(_ => omit(_, 'isValid')), });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">
            お子様情報
          </h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div>
            <ListForm
              addButtonLabel="お子様を追加する"
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <ChildForm
                      fields={childFields()}
                      index={itemIndex}
                      values={item}
                      onChange={_ => updateItemAt(itemIndex, { ...item, ..._, })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={0}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
