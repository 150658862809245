import React, { Fragment, } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Label } from 'reactstrap';
import { omitBy, pick, isUndefined, mapValues } from 'lodash';
import classnames from 'classnames';
import { useToggle } from 'react-use';
import { toast } from 'react-toastify';
import dedent from 'dedent';

import firebase from '../../firebase';
import { checkPlaceUniqueness } from '../../util';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import PlaceForm from '../forms/PlaceForm';
import { fields } from '../../shared/models/event';

const db = firebase.firestore();
const placesRef = db.collection('places');
const { entries } = Object;
const reminderMailBodyDescription = dedent`
イベント名: {{EVENT_NAME}}
開催日: {{LECTURE_DATE}}
商品種別: {{LECTURE_PRODUCT_TYPE}}
レクチャー種別: {{LECTURE_TYPE}}
対象レベル: {{LECTURE_LEVEL}}
対象年齢: {{LECTURE_AGE}}
価格: {{LECTURE_PRICE}}
`;

export default function EventFormModal(props) {
  const { places, staffs, user, isOpen, values, onClickClose } = props;
  const [showsNewPlaceForm, toggleNewPlaceForm] = useToggle();
  const isNew = !values;
  const statedFields = useFormState(values, fields({ places, staffs, user }), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value'), });
  };
  const onSubmitNewPlaceForm = async (values) => {
    try {
      const isUnique = await checkPlaceUniqueness(values);
      if(!isUnique) return toast.error('その開催場所名は該当都道府県にすでに存在します');
      const { id } = await placesRef.add({ ...values, tenantId: user.tenantId, createdAt: new Date(), addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined), });
      toast.success('開催場所を追加しました');
      statedFields.placeId.setValue(id);
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
    toggleNewPlaceForm();
  };

  return (
    <Modal isOpen={isOpen} style={{ width: '80vw', maxWidth: '80vw', }}>
      <ModalHeader>
        イベント{isNew ? '追加' : '編集'}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => {
              if(fieldName === 'reminderMailBody') {
                return (
                  <div>
                    <Label>{fieldSetting.label}</Label>
                    <div className="alert alert-info small" style={{ whiteSpace: 'pre-line' }}>
                      {reminderMailBodyDescription}
                    </div>
                    <Field
                      documentName="event"
                      key={fieldName}
                      name={fieldName}
                      {...fieldSetting}
                      label={null}
                    />
                  </div>
                );
              }

              return (
                <Fragment>
                  {
                    fieldName === 'placeId' && (
                      <div>
                        <Label>開催場所</Label>
                        <Button size="sm" className="ml-2 py-0 px-2" onClick={toggleNewPlaceForm}>
                          <span className={classnames('fas', { 'fa-plus': !showsNewPlaceForm, 'fa-times': showsNewPlaceForm })} />
                        </Button>
                        {
                          showsNewPlaceForm && (
                            <div className="card p-3 mb-2">
                              <PlaceForm onSubmit={onSubmitNewPlaceForm} />
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  <Field
                    key={fieldName}
                    name={fieldName}
                    documentName="event"
                    {...fieldSetting}
                    {...(fieldName === 'placeId' && { label: null })}
                  />
                </Fragment>
              );
            })
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>保存</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
