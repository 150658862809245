import React, { Fragment, } from 'react';
import { format as formatDate } from 'date-fns';
import { isObject, orderBy, keyBy, pick } from 'lodash';
import numeral from 'numeral';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import UserPage from '../hocs/UserPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AppCard from '../AppCard';
import AppButton from '../AppButton';
import ProgressButton from '../ProgressButton';
import TenantContext from '../contexts/tenant';
import TenantLink from '../TenantLink';

const { entries, keys, } = Object;
const db = firebase.firestore();
const cancelContentOrder = functions.httpsCallable('cancelContentOrder');
const contentOrdersRef = db.collection('contentOrders');
const productsRef = db.collection('products');

export default UserPage(function MypageContentOrders(props) {
  const { user = {} } = props;
  const tenants = useCollectionSubscription(db.collection('tenants'));
  const tenantsById = keyBy(tenants, 'id');
  const contentOrders = useCollectionSubscription(
    user.uid && contentOrdersRef.where('createdBy.uid', '==', user.uid),
    [user.uid]
  );
  const contentProducts = useCollectionSubscription(db.collection('contentProducts').orderBy('code'));
  const contentProductsById = keyBy(contentProducts, 'id');
  const filteredContentOrders = contentOrders;
  const sortedContentOrders = orderBy(filteredContentOrders, (_) => _.createdAt.toDate(), 'desc');

  return (
    <div className="mypage-content-orders">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">コンテンツ注文履歴</h5>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="mt-4">
              {sortedContentOrders.map((contentOrder) => {
                const tenant = tenantsById[contentOrder.tenantId];
                return (
                  <ContentOrderItem
                    key={contentOrder.id}
                    contentOrder={contentOrder}
                    contentProductsById={contentProductsById}
                    tenant={tenant}
                    user={user}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

function ContentOrderItem(props) {
  const { contentOrder, contentProductsById, tenant, user } = props;
  const { id, ref, amount, createdAt, contentProductIds, startedAt, cancelledAt, } = contentOrder;
  const items = contentProductIds.map((contentProductId) => {
    const contentProduct = contentProductsById[contentProductId];
    const expiredAt = addDays(contentOrder.createdAt.toDate(), contentOrder.contentProductValues.find(_ => _.id === contentProductId)?.expirationDays);
    const isExpired = new Date() > expiredAt;
    return {
      contentProductId,
      contentProduct,
      expiredAt,
      isExpired,
    };
  });
  const isAnyExpired = items.some(_ => _.isExpired);
  const isCancelled = cancelledAt != null;
  const onClickStart = async () => {
    if(!window.confirm('視聴を開始します。よろしいですか？')) return;

    await ref.update({ startedAt: new Date() });
  };
  const onClickCancel = async () => {
    if(!window.confirm('キャンセルします。よろしいですか？')) return;

    try {
      await cancelContentOrder({ contentOrderId: contentOrder.id, });
      toast.success('キャンセルしました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <TenantContext.Provider value={tenant}>
      <div className="mb-4">
        <div className="position-relative" style={{ textDecoration: 'none' }}>
          <AppCard className="text-dark p-3 p-sm-4" style={{ opacity: isCancelled && 0.5 }}>
            <div className="card-title small font-weight-bold">注文番号 {id}</div>
            <div className="flex-grow-1">
              <div className="mb-3">
                <div className="small text-muted">注文日時</div>
                {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
              </div>
            </div>
            <div className="mt-3">
              <div className="small text-muted">商品</div>
              {
                items.map(({ contentProductId, contentProduct, isExpired }) => {
                  return (
                    contentProduct != null && (
                      <div key={contentProductId}>
                        <div className="d-flex">
                          <div style={{ minWidth: 80 }}>
                            <img src={contentProduct.image.url} style={{ maxHeight: 50 }} />
                          </div>
                          <div className="flex-fill ml-2">
                            <div className="d-flex">
                              <div className="flex-fill mx-2">{contentProduct.name}</div>
                            </div>
                            <div className="mt-1 d-flex flex-wrap text-nowarp gap-2">
                              {
                                (startedAt != null || isAnyExpired) && !isCancelled && (
                                  isExpired ? (
                                    <div className="text-danger small">視聴期限を過ぎました</div>
                                  ) : (
                                    <AppButton tag={TenantLink} target="_blank" color="primary" outline size="sm" to={`/contentProducts/${contentProductId}`}>視聴する</AppButton>
                                  )
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })
              }
            </div>
            <div className="mt-3">
              <div className="d-flex small gap-2">
                <div className="text-muted">お支払い合計</div>
              </div>
              {numeral(amount).format('0,0')}円(税込)
            </div>
            <div className="mt-3 d-flex justify-content-end">
              <div className="d-flex flex-column flex-md-row text-right gap-1">
                {
                  startedAt == null && !isAnyExpired && !isCancelled && (
                    <Fragment>
                      <ProgressButton
                        className="rounded-pill px-4"
                        size="sm"
                        color="primary"
                        icon={false}
                        process={onClickStart}
                      >
                        視聴を開始する
                      </ProgressButton>
                      <ProgressButton
                        className="rounded-pill px-4"
                        size="sm"
                        color="secondary"
                        outline
                        icon={false}
                        process={onClickCancel}
                        style={{ color: '#777', borderColor: '#777' }}
                      >
                        キャンセルする
                      </ProgressButton>
                    </Fragment>
                  )
                }
              </div>
            </div>
          </AppCard>
          {
            isCancelled && (
              <div className="position-absolute fit d-flex justify-content-center align-items-center">
                <div className="text-danger border border-danger font-weight-bold px-2 py-1 bg-white">キャンセル済み</div>
              </div>
            )
          }
        </div>
      </div>
    </TenantContext.Provider>
  );
}
