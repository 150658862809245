import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { get, without } from 'lodash';

import { activateRichTextHtml } from '../util';
import firebase from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useTenant from './hooks/useTenant';
import RichTextContent from './RichTextContent';

const db = firebase.firestore();

export default function SettingText (props) {
  const { initialText = '', name, renderContainer = (x, y) => y } = props;
  const tenant = useTenant();
  const texts = useDocumentSubscription(db.collection('settings').doc([tenant?.id, 'text'].join('__')));
  const html = sanitizeHtml(activateRichTextHtml(get(texts, name, initialText)), {
    allowedTags: [...without(sanitizeHtml.defaults.allowedTags), 'u', 'img', 'h2'],
    allowedAttributes: false,
  });
  const content = <RichTextContent html={html} />;

  return renderContainer(html, content);
};
