import React from 'react';
import PartsSelectorForm from './PartsSelectorForm';

export default function TroubleInquiryPartsSelectorForm(props) {
  return (
    <>
      <div className='mb-5 text-muted'>
        必要と思われるパーツを選択して、次へボタンを押してください。
        <br />
        わからない場合は、「不明」をお選びください。
      </div>
      <PartsSelectorForm {...props} />
    </>
  );
}
