import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { sortBy, isEmpty, keyBy, get, omitBy, isUndefined, pick, } from 'lodash';
import numeral from 'numeral';

import { canUpdateAgent, } from '../../shared/abilities';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { fields as agentProductSettingFields, } from '../../shared/models/agentProductSetting';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import { agentUserFields } from '../../shared/models/user';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const db = firebase.firestore();

export default AdminPage(function AdminAgentQrUrls (props) {
  const { user, match: { params: { agentId } }, addBreadNavValues } = props;
  const agent = useDocumentSubscription(db.collection('agents').doc(agentId), [agentId]);
  const products = sortBy(useCollectionSubscriptionInTenant(db.collection('products')), 'code');
  const agentProductSettings = useCollectionSubscription(agent?.ref.collection('agentProductSettings'), [agent]);
  const agentProductSettingsById = keyBy(agentProductSettings, 'id');
  const nonPartProducts = products.filter(_ => !_.isPart && _.createdAt);
  const rowsForExport = () => {
    return nonPartProducts.map((product) => {
      const { id, } = product;
      const setting = agentProductSettingsById[id];
      return {
        id,
        isHiddenForReferral: setting?.isHiddenForReferral || false,
        isShownForReferral: setting?.isShownForReferral || false,
        isHiddenForWholesale: setting?.isHiddenForWholesale || false,
        isShownForWholesale: setting?.isShownForWholesale || false,
        price: setting?.price,
        wholesalePriceRate: setting?.wholesalePriceRate,
        referralFeeType: setting?.referralFeeType,
        referralFeeAmount: setting?.referralFeeAmount,
        referralFeeRate: setting?.referralFeeRate,
        description: setting?.description,
      };
    });
  };
  const processRow = (batch, item, i) => {
    const existsProduct = products.some(_ => _.id === item.id);
    if(!existsProduct) return;

    const exists = agentProductSettings.some(_ => _.id === item.id);
    batch.set(agent.ref.collection('agentProductSettings').doc(item.id), {
      isHiddenForReferral: item.isHiddenForReferral?.toUpperCase() === 'TRUE',
      isShownForReferral: item.isShownForReferral?.toUpperCase() === 'TRUE',
      isHiddenForWholesale: item.isHiddenForWholesale?.toUpperCase() === 'TRUE',
      isShownForWholesale: item.isShownForWholesale?.toUpperCase() === 'TRUE',
      price: isEmpty(item.price) ? null : parseInt(item.price, 10),
      wholesalePriceRate: isEmpty(item.wholesalePriceRate) ? null : parseInt(item.wholesalePriceRate, 10),
      referralFeeType: isEmpty(item.referralFeeType) ? null : item.referralFeeType,
      referralFeeAmount: isEmpty(item.referralFeeAmount) ? null : parseInt(item.referralFeeAmount),
      referralFeeRate: isEmpty(item.referralFeeRate) ? null : parseFloat(item.referralFeeRate),
      description: item.description ?? '',
      ...(
        !exists && {
          createdAt: new Date(),
        }
      ),
      [exists ? 'updatedBy' : 'addedBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
    }, { merge: true });
  };
  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  const referralFee = (referralFeeType, referralFeeAmount, referralFeeRate) => {
    if (referralFeeType === 'amount') {
      return referralFeeAmount != null ? <span>{numeral(referralFeeAmount).format('0,0')}</span> : '';
    } else {
      return referralFeeRate != null ? <span>{referralFeeRate} %</span> : '';
    }
  };

  return agent != null && (
    <div>
      <div className="admin-agent-products container-fluid py-5 position-relative">
        <AdminAgentHeader activeTab="products" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center mb-3">
                商品
              </h5>
              <div className="d-flex justify-content-end mb-3 gap-1">
                <ImportButton processRow={processRow} />
                <ExportButton fileName={`${agent.name}_商品.csv`} rows={rowsForExport} />
              </div>
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th>商品</th>
                    <th>紹介カートで非表示</th>
                    <th>紹介カートで強制表示</th>
                    <th>卸カートで非表示</th>
                    <th>卸カートで強制表示</th>
                    <th>代理店上代</th>
                    <th>代理店下代率</th>
                    <th>代理店紹介料</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    nonPartProducts.map((product) => {
                      const { id, ref, name, code, } = product;
                      const setting = agentProductSettingsById[id];
                      const ButtonComponent = setting != null ? EditButton : AddInTenantButton;
                      const { isHiddenForReferral = false, isShownForReferral = false, isHiddenForWholesale = false, isShownForWholesale = false, price, wholesalePriceRate, referralFeeType, referralFeeAmount, referralFeeRate } = setting || {};
                      return (
                        <tr key={id}>
                          <td>
                            [{code}] {name}
                          </td>
                          <td>{isHiddenForReferral && '非表示'}</td>
                          <td>{isShownForReferral && '強制表示'}</td>
                          <td>{isHiddenForWholesale && '非表示'}</td>
                          <td>{isShownForWholesale && '強制表示'}</td>
                          <td className='text-right'>
                            {
                              price != null ? (
                                <span>{numeral(price).format()}</span>
                              ) : ''
                            }
                          </td>
                          <td className='text-right'>
                            {
                              wholesalePriceRate != null ? (
                                <span>{numeral(wholesalePriceRate).format('0,0')}%</span>
                              ) : ''
                            }
                          </td>
                          <td className='text-right'>
                            {referralFee(referralFeeType, referralFeeAmount, referralFeeRate)}
                          </td>
                          <td className="text-right">
                            <ButtonComponent icon={<span className="fas fa-cog mr-1" />} color="secondary" label="設定" itemRef={agent.ref.collection('agentProductSettings').doc(id)} FormModal={ModelFormModal} formProps={{ documentName: 'agentProductSetting', title: '設定', fields: agentProductSettingFields(), }} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
