import React, { Component, useState, } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { isEmpty, } from 'lodash';

import useDocumentSubscription from '../hooks/useDocumentSubscription';
import firebase from '../../firebase';
import HeaderNav from '../HeaderNav';
import AdminHelp from '../AdminHelp';
import BreadNav from '../BreadNav';
import env from '../../env';
import TenantUserPage from './TenantUserPage';

const auth = firebase.auth();
const db = firebase.firestore();
const agentsRef = db.collection('agents');

export default function AgentPageHOC(WrappedComponent) {
  return TenantUserPage(function AgentPage (props) {
    const { user, match: { path, params, params: { agentId } } } = props;
    const [breadNavValues, setBreadNavValues] = useState({});
    const agent = useDocumentSubscription(agentsRef.doc(agentId), [agentId]);
    const memberData = agent?.members?.[user.id];
    const canEditMembers = ['admin', 'staff'].includes(user.role) || (memberData?.role === 'admin');
    const canReadAllShops = ['admin', 'staff'].includes(user.role) || (memberData != null && isEmpty(memberData.shopIds));
    const helpKey = [params.tenantPath, encodeURIComponent(path)].join('__');

    return agent != null && (
      <div className="agent-page h-100">
        <BreadNav currentPath={path} currentParams={params} values={{ ...breadNavValues }} prefixContent={_ => <AdminHelp helpKey={helpKey} user={user} />} />
        <WrappedComponent {...props} {...{ agent, setBreadNavValues, canReadAllShops, canEditMembers, memberData, }} />
      </div>
    );
  });
};
