import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Progress } from 'reactstrap';
import { uniq } from 'lodash';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { statuses, fields } from '../../shared/models/smsDelivery';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from './../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from './../hooks/useCollectionSubscriptionInTenant';
import DeleteButton from '../DeleteButton';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';

const db = firebase.firestore();
const smsDeliveriesRef = db.collection('smsDeliveries');
const { entries } = Object;

export default AdminPage(function AdminSmsDelivery(props) {
  const {
    match: {
      params: { smsDeliveryId },
    },
  } = props;
  const tenant = useTenant();
  const smsDelivery = useDocumentSubscription(smsDeliveriesRef.doc(smsDeliveryId), [smsDeliveryId]);
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes'));
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const deliveryCandidates = useCollectionSubscriptionInTenant(db.collection(`smsDeliveries/${smsDeliveryId}/deliveryCandidates`));
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map(_ => _.group));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [smsDelivery]);

  const { label: statusLabel, color } = statuses[smsDelivery?.status] || {};
  const pregressValue = deliveryCandidates.length ? deliveryCandidates.filter(_ => _.status === 'completed').length / deliveryCandidates.length * 100 : 0;

  const handleClickRetry = async () => {
    if (!window.confirm('配信対象リストを再作成しますか？')) return;
    await smsDelivery.ref.update({ status: 'createRetry' });
  }

  return (
    smsDelivery != null && (
      <div className="admin-user container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>SMS配信詳細</h4>
          </div>
          <div className="d-flex justify-content-end mb-3 gap-2 flex-wrap text-nowrap">
            {smsDelivery.status === 'error' && (
              <Button onClick={handleClickRetry}>
                リスト再作成
              </Button>
            )}
            {['create', 'creating', 'copied'].includes(smsDelivery.status) || (
              <Button tag={TenantLink} to={`/admin/smsDeliveries/${smsDelivery.id}/deliveryTargets`} target="_blank">
                配信対象
              </Button>
            )}
            <DeleteButton itemRef={smsDelivery.ref} disabled={!['preview', 'error', 'failed', 'copied'].includes(smsDelivery.status)} />
          </div>
          <div>
            <table className="table table-bordered">
              <tbody className="thead-light">
                <tr>
                  <th style={{ width: 200 }}>ステータス</th>
                  <td style={{ whiteSpace: 'pre-line' }}>
                    <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                    {smsDelivery.status === 'creating' && (
                      <div className="w-100">
                        <Progress color="success" value={Math.min(pregressValue, 100)} />
                        <div className="text-center">{Math.min(Math.ceil(pregressValue), 100)}%</div>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>配信日時</th>
                  <td style={{ whiteSpace: 'pre-line' }}>
                    {smsDelivery.completedAt && formatDate(smsDelivery.completedAt.toDate(), 'yyyy/MM/dd HH:mm')}
                  </td>
                </tr>
                {entries({
                  ...fields({ products, productTypes, events, userTags, areaGroups }),
                }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {fieldDisplayValue(smsDelivery[fieldName], fieldSetting)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
});
