import React from 'react';
import { set } from 'lodash/fp';
import { sortBy, } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdateUserVoices } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import TenantLink from '../TenantLink';
import { fields, } from '../../shared/models/userVoice';
import { beforeDelete, } from '../../util';

const db = firebase.firestore();
const productsRef = db.collection('products');
const userVoicesRef = db.collection('userVoices');

export default AdminPage(function AdminUserVoices (props) {
  const { user, tenant, } = props;
  const userVoices = sortBy(useCollectionSubscriptionInTenant(userVoicesRef), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="admin-userVoices container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>ユーザーズボイス一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={userVoicesRef.doc()} FormModal={ModelFormModal} formProps={{ title: 'ユーザーズボイス追加', fields, }} disabled={!canUpdateUserVoices(user)} />
        </div>
        <div>
          {
            userVoices.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>名称</th>
                    <th>タイトル</th>
                    <th>メモ</th>
                    <th>公開状態</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    userVoices.map((userVoice) => {
                      const { id, name, title, note, ref, isPublic = false, } = userVoice;

                      return (
                        <tr key={id}>
                          <td>
                            <TenantLink to={`/userVoices/${id}`} target="_blank">
                              {name}
                              <span className="fas fa-external-link-alt ml-1" />
                            </TenantLink>
                          </td>
                          <td>
                            {title}
                          </td>
                          <td style={{ whiteSpace: 'pre-line' }}>
                            {note}
                          </td>
                          <td style={{ whiteSpace: 'pre-line' }}>
                            {isPublic && <span className="badge badge-info">公開</span>}
                          </td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'ユーザーズボイス編集', fields }} disabled={!canUpdateUserVoices(user)} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateUserVoices(user)} beforeDelete={beforeDelete([db.collection('surveyAnswers').where('tenantId', '==', tenant.id).where('userVoiceIds', 'array-contains', id)])} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                ユーザーズボイスは未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

