import {
  getFirestore,
  getConverter,
  collection,
  collectionGroup,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { AgentShopDocumentData, AgentShop } from '../types';
import type { PartiallyPartial } from 'types';

export const agentShopConverter = getConverter<AgentShopDocumentData>();

export const agentShopsGroupRef = () => collectionGroup(getFirestore(), 'agentShops').withConverter(agentShopConverter);

export const agentShopsRef = (parent: DocumentReference) =>
  collection(parent, 'agentShops').withConverter(agentShopConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<AgentShop> : null;
export const agentShopRef = <Id extends string | undefined>(parent: DocumentReference, agentShopId: Id) =>
  (agentShopId ? doc(agentShopsRef(parent), agentShopId) : null) as RefOrNull<Id>;

export const newAgentShopRef = (parent: DocumentReference) => doc(agentShopsRef(parent));

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const agentShopsQuery = (parent: DocumentReference, ...queryConstraints: QueryConstraint[]) =>
  query(agentShopsRef(parent), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const agentShopsGroupQuery = (...queryConstraints: QueryConstraint[]) =>
  query(agentShopsGroupRef(), ...queryConstraints);

export const tenantAgentShopsGroupQuery = (tenantId: string | undefined) =>
  tenantId ? agentShopsGroupQuery(...tenantQueryConstraints(tenantId)) : null;

export const addAgentShop = async (parent: DocumentReference, data: PartiallyPartial<AgentShop, 'id' | 'createdAt'>) =>
  addDoc(agentShopsRef(parent), { createdAt: serverTimestamp(), ...data });

export const updateAgentShop = async (parent: DocumentReference, agentShopId: string, data: UpdateData<AgentShop>) =>
  updateDoc(agentShopRef(parent, agentShopId), data);

export const deleteAgentShop = async (parent: DocumentReference, agentShopId: string) =>
  deleteDoc(agentShopRef(parent, agentShopId));

export const useAgentShopCollection = useCollectionData;

export const useAgentShopCollectionOnce = useCollectionDataOnce;

export const useAgentShopDocument = useDocumentData;

export const useAgentShopDocumentOnce = useDocumentDataOnce;
