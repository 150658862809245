const { mapValues, isString, pickBy, isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');

const { langs, reinquiryContacts } = require('../config');

const { entries } = Object;

const computeVisibleQuestionIds = (survey, answers) => {
  return (survey?.questionRows || [])
    .filter(_ => computeVisibility(_, answers))
    .map(_ => _.questionId);
};

const computeVisibility = ({ visibilityConditionEnabled, visibilityConditionQuestionId, visibilityConditionAnswers, }, answers) => {
  return !visibilityConditionEnabled || (_ => isString(_) ? [_] : Object.keys(pickBy(_, v => v)))(answers[visibilityConditionQuestionId]).some(v => visibilityConditionAnswers?.includes(v));
};

const computeVisibleAnswers = (survey, answers) => {
  const visibleAnswers = survey.questionRows.reduce((reducedAnswers, questionRow) => {
    const { questionId, } = questionRow;
    const isVisible = computeVisibility(questionRow, mapValues(reducedAnswers, 'value'));
    return {
      ...reducedAnswers,
      ...(isVisible && { [questionId]: answers[questionId], }),
    };
  }, {});
  return pickBy(visibleAnswers, _ => _ !== undefined);
};

module.exports = {
  fields: ({ staffs, surveyGroups = [] }) => {
    return {
      surveyGroupId: {
        label: 'アンケートグループ',
        type: 'select',
        options: surveyGroups.map(({ id, name }) => ({ value: id, label: name })),
        validations: { required: (v) => !isEmpty(v) },
        initialValue: '',
      },
      lang: {
        label: '言語',
        type: 'select',
        options: entries(langs).map(([k, v]) => ({ value: k, label: v.label })),
        validations: { required:  v => !isEmpty(v), },
        initialValue: 'ja',
      },
      name: {
        label: '名称',
        type: 'string',
      },
      title: {
        label: 'タイトル',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
      description: {
        label: '説明文(HTML)',
        type: 'richText',
        initialValue: '',
        rows: 10,
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      isAnswerEditable: {
        label: '回答編集可',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: 'メモ',
        type: 'text',
      },
      reinquiryContact: {
        label: '再問合せ先',
        type: 'select',
        options: entries(reinquiryContacts).map(([k, v]) => ({ value: k, label: v.label })),
        validations: { required:  v => !isEmpty(v), },
        initialValue: 'inquiry',
      },
      defaultRespondedBy: {
        label: 'デフォルトの担当',
        type: 'select',
        options: staffs.map(_ => ({ label: _.displayName, value: _.id })),
        initialValue: 'inquiry',
      },
      notificationTo: {
        label: '回答時の通知先メールアドレス',
        type: 'string',
        validations: {
          format: v => !v || isEmail(v),
          notMultibyte: v => !v || !isMultibyte(v),
        },
      },
      thanksContent: {
        label: 'サンクステキスト(HTML)',
        type: 'richText',
        initialValue: '',
        rows: 10,
      },
    };
  },
  questionRowFields: ({ otherRows, questions = [], prevRowQuestionIds, }) => {
    return {
      questionId: {
        label: 'アンケート項目',
        type: 'select',
        options: questions.filter(_ => !_.isHidden).map((_) => ({ label: `${_.name} (${_.description})`, value: _.id })),
        validations: {
          required: _ => _ != null,
          uniq: _ => !otherRows.map(_ => _.questionId).includes(_),
        },
      },
      visibilityConditionEnabled: {
        label: '表示条件を付ける',
        type: 'boolean',
        initialValue: false,
      },
      visibilityConditionQuestionId: {
        label: '表示条件アンケート項目',
        type: 'select',
        options: prevRowQuestionIds
          .map(_ => questions.find(q => q.id === _))
          .filter(_ => ['radio', 'select', 'checkbox'].includes(_?.type))
          .map((_) => ({ label: `${_.name} (${_.description})`, value: _.id })),
        validations: { required: (v) => !isEmpty(v) },
        hidden: _ => !_.visibilityConditionEnabled,
        hint: 'ラジオ、チェックボックスの項目のみ選択可',
      },
      visibilityConditionAnswers: {
        label: '表示条件回答',
        type: 'multiSelect',
        options: _ => (questions.find(q => q.id === _.visibilityConditionQuestionId)?.optionsString.split(',') || []).map(_ => ({ label: _, value: _ })),
        validations: { required: (v) => !isEmpty(v) },
        hidden: _ => !_.visibilityConditionEnabled,
        hint: '複数選択した場合は、どれかが一致したら表示',
      },
      supportRequiredAnswer: {
        label: '要対応になる回答',
        type: 'string',
        hint: '設定した文言を含む回答をした場合、「要対応」になります。複数指定する場合は「,」で区切る'
      },
    };
  },
  stepFields: () => {
    return {
      title: {
        label: 'ステップタイトル',
        type: 'string',
      },
    };
  },
  computeVisibleQuestionIds,
  computeVisibility,
  computeVisibleAnswers,
};
