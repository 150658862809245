import React, { useImperativeHandle, forwardRef, useState, useEffect, useMemo, useRef, } from 'react';
import Rte, { getTextAlignClassName, getTextAlignStyles, getTextAlignBlockMetadata, } from 'react-rte';
import { isEmpty, } from 'lodash';
import { EditorState, Modifier, convertToRaw, } from 'draft-js';
import { Button, Input, } from 'reactstrap';
import { ENTITY_TYPE } from 'draft-js-utils';
import { useToggle } from 'react-use';
import moviePlayImage from '../images/movie_play.png';

import { storageRef, uploadBytes, getDownloadURL } from '../firebase';
import { serial, } from '../shared/util';
import { isURL } from 'validator';
import ModelFormModal from './modals/ModelFormModal';
import ModalButton from './ModalButton';

export default forwardRef(function RichTextEditor(props, ref) {
  const { value, onChange, ...extraProps } = props;
  const rte = useRef();
  const [rteValue, setRteValue] = useState(Rte.createValueFromString(value || '', 'html', { customBlockFn: getTextAlignBlockMetadata, }));
  const emptyString = useMemo(() => Rte.createEmptyValue().toString('html'), []);
  useImperativeHandle(ref, () => ({
    setRteValue: (value) => setRteValue(Rte.createValueFromString(value || '', 'html', { customBlockFn: getTextAlignBlockMetadata, })),
  }));
  useEffect(() => {
    const htmlString = rteValue.toString('html', { blockStyleFn: getTextAlignStyles, });

    onChange(htmlString === emptyString ? '' : htmlString);
  }, [rteValue, emptyString]);

  return (
    <div className="app-rte">
      <Rte
        className="vsm-rte"
        ref={rte}
        value={rteValue}
        onChange={setRteValue}
        toolbarConfig={{
          display: ['INLINE_STYLE_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
          INLINE_STYLE_BUTTONS: [{ label: 'Bold', style: 'BOLD' }, { label: 'Italic', style: 'ITALIC' }, { label: 'Strikethrough', style: 'STRIKETHROUGH' }, { label: 'Monospace', style: 'CODE' }, { label: 'Underline', style: 'UNDERLINE' }],
          BLOCK_ALIGNMENT_BUTTONS: [{ label: 'Align Left', style: 'ALIGN_LEFT' }, { label: 'Align Center', style: 'ALIGN_CENTER' }, { label: 'Align Right', style: 'ALIGN_RIGHT' }, { label: 'Align Justify', style: 'ALIGN_JUSTIFY' }],
          BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' },
            { label: 'Code Block', style: 'code-block' },
            { label: '装飾1', style: 'header-four' },
            { label: '装飾2', style: 'header-five' },
          ],
          BLOCK_TYPE_BUTTONS: [{ label: 'UL', style: 'unordered-list-item' }, { label: 'OL', style: 'ordered-list-item' }, { label: 'Blockquote', style: 'blockquote' }],
        }}
        customControls={[
          ...(
            [
              { type: 'image', label: '画像', },
              { type: 'video', label: '動画', },
            ].map(({ type, label }) => {
              return (setState, getState, editorState) => {
                const onSubmit = async (values, { onClickClose }) => {
                  const { url, file, poster, } = values;
                  const [fileUrl, posterUrl] = await serial([file, poster], async (file) => {
                    if(file == null) return;

                    const path = ['rte', (new Date()).getTime().toString(), file.name].join('/');
                    const fileRef = storageRef(path);
                    await uploadBytes(fileRef, file, { contentType: file.type });
                    const downloadUrl = await getDownloadURL(fileRef);
                    return downloadUrl;
                  });
                  let contentState = editorState.getCurrentContent();
                  const selection = editorState.getSelection();
                  contentState = contentState.createEntity(ENTITY_TYPE.IMAGE, 'IMMUTABLE', ({
                    image: {
                      src: fileUrl,
                      alt: url,
                    },
                    video: {
                      src: posterUrl || moviePlayImage,
                      alt: `[video] ${fileUrl}`,
                    },
                  })[type]);
                  const entityKey = contentState.getLastCreatedEntityKey();
                  const newContentState = Modifier.insertText(contentState, selection, ' ', null, entityKey);
                  rte.current._onChange(EditorState.push(editorState, newContentState));
                  await new Promise(_ => setTimeout(_, 10));
                  setTimeout(_ => rte.current.editor.focus(), 50);
                  onClickClose();
                };

                return (
                  <ModalButton
                    size="sm"
                    className="ml-1"
                    Modal={ModelFormModal}
                    modalProps={{
                      title: label,
                      fields: {
                        file: {
                          label: `${label}ファイル`,
                          type: 'file',
                          inputProps: {
                            accept: `${type}/*`,
                          },
                        },
                        ...(
                          type === 'image' && {
                            url: {
                              label: 'リンク先URL',
                              type: 'string',
                              validations: {
                                format: v => isEmpty(v) || v.includes('localhost') || isURL(v),
                              },
                            }
                          }
                        ),
                        ...(
                          type === 'video' && {
                            poster: {
                              label: `サムネイル画像`,
                              type: 'file',
                              inputProps: {
                                accept: `image/*`,
                              },
                            }
                          }
                        ),
                      },
                      onSubmit,
                      submitLabel: '挿入する',
                    }}
                  >
                    <span className={`fas fa-file-${type}`} />
                  </ModalButton>
                );
              };
            })
          ),
        ]}
        blockStyleFn={getTextAlignClassName}
        {...extraProps}
      />
    </div>
  );
});
