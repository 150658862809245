import React, { useEffect, Fragment } from 'react';
import { Button as _Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import usePrevious from '../hooks/usePrevious';
import Field from '../Field';

const { entries } = Object;

export default function ModelFormModal(props) {
  const { documentName, fields, title, isOpen, values, onClickClose, onChange = _ => _, submitLabel = '保存', renderFormHeader = _ => null, renderFooterContent = _ => _, Button = _Button, hasSpin = false, confirms = false, confirmBody = _ => null, ...extraProps } = props;
  const [isConfirming, toggleConfirming] = useToggle(false);
  const statedFields = useFormState(values, isFunction(fields) ? fields() : fields, isOpen);
  const prevStatedFields = usePrevious(statedFields);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    if(confirms && !isConfirming) return toggleConfirming(true);

    toggleSubmitting(true);
    await props.onSubmit({ ...mapValues(statedFields, 'value'), }, { onClickClose });
    toggleSubmitting(false);
  };
  useEffect(() => {
    onChange(prevStatedFields, statedFields);
  }, [Object.values(statedFields).map(_ => _.value)]);
  const closeButton = onClickClose && (
    <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
  );
  const confirmButton = (
    <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
      確認する
    </Button>
  );
  const unconfirmButton = (
    <Button className="cancel" color="secondary" onClick={_ => toggleConfirming(false)}>
      戻る
    </Button>
  );
  const submitButton = (
    <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
      {hasSpin && isSubmitting && <span className="fas mr-1 fa-spin fa-spinner" />}
      {submitLabel}
    </Button>
  );

  return (
    <Modal isOpen={isOpen} {...extraProps}>
      <ModalHeader>
        {title}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            confirms && isConfirming ? (
              confirmBody({ values: mapValues(statedFields, 'value'), })
            ) : (
              <Fragment>
                {renderFormHeader(statedFields)}
                {
                  entries(statedFields).map(([fieldName, fieldSetting]) => (
                    <Field
                      key={fieldName}
                      name={fieldName}
                      values={mapValues(statedFields, 'value')}
                      documentName={documentName}
                      {...fieldSetting}
                    />
                  ))
                }
              </Fragment>
            )
          }
        </ModalBody>
        <ModalFooter>
          {
            renderFooterContent((
              confirms ? (
                isConfirming ? (
                  <Fragment>
                    {unconfirmButton}
                    {submitButton}
                  </Fragment>
                ) : (
                  <Fragment>
                    {closeButton}
                    {confirmButton}
                  </Fragment>
                )
              ) : (
                <Fragment>
                  {closeButton}
                  {submitButton}
                </Fragment>
              )
            ), { onSubmit, onClickClose, toggleSubmitting, statedFields, isUnsubmittable, isSubmitting, })
          }
        </ModalFooter>
      </Form>
    </Modal>
  );
};
