import React, { useEffect, useMemo, } from 'react';
import qs from 'qs';
import { sortBy, orderBy, groupBy, uniq, maxBy, omit, isEmpty, sum, sumBy, get, keyBy, omitBy, pick, isUndefined, max } from 'lodash';
import { format as formatDate, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { Button, Badge, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ellipsis from 'text-ellipsis';

import { fullPathWithParams } from '../../util';
import { prefectures, routes, colors, } from '../../shared/config';
import { canUpdateOrder } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { ordererFields, cancelFields, } from '../../shared/models/rentalOrder';
import { areaFromPostalCode } from '../../shared/models/setting';
import { fields as userFields, adminFields as adminUserFields } from '../../shared/models/user';
import { fieldDisplayValue } from '../../shared/util';
import AdminPage from '../hocs/AdminPage';
import useFirebaseUser from '../hooks/useFirebaseUser';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import TenantLink from '../TenantLink';
import AgentHeader from '../AgentHeader';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import EditButton from '../EditButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import QueryText from '../QueryText';
import QueryInput from '../QueryInput';
import OrderExportButton from '../OrderExportButton';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import ContentOrdersTable from '../ContentOrdersTable';

const db = firebase.firestore();
const cancelRentalOrder = functions.httpsCallable('cancelRentalOrder');
const { entries } = Object;

export default AdminPage(function AdminContentOrders (props) {
  const { tenant, user, } = props;
  const queryParams = useQueryParams();
  const {
    agentIds,
    agentShopIds,
    contentProductIds,
    dateRange,
  } = queryParams;
  const startOn = useMemo(_ => queryParams.dateRange?.[0] ? new Date(queryParams.dateRange[0]) : addDays(addMonths(new Date(), -1), 1), [queryParams.dateRange]);
  const endOn = useMemo(_ => queryParams.dateRange?.[1] ? new Date(queryParams.dateRange[1]) : new Date(), [queryParams.dateRange]);
  const agents = useCollectionSubscriptionInTenant(db.collection('agents'));
  const agentsById = keyBy(agents, 'id');
  const agentOptions = agents.map((_) => ({ label: _.name, value: _.id }));
  const agentShops = useCollectionSubscriptionInTenant(db.collectionGroup('agentShops'));
  const agentShopsById = keyBy(agentShops, 'id');
  const agentShopOptions = agentShops.map(_ => ({ label: _.name, value: _.id, }));
  const contentProducts = useCollectionSubscriptionInTenant(db.collection('contentProducts').orderBy('code'));
  const contentProductsById = keyBy(contentProducts, 'id');
  const contentProductOptions = contentProducts.map((_) => ({ label: `[${_.code}] ${_.name}`, value: _.id }));

  return (
    <div>
      <div className="admin-content-orders container-fluid py-5 position-relative">
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className='position-relative mb-2 d-flex align-items-end justify-content-start gap-2' style={{ zIndex: 11 }}>
            <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} />
            <QuerySelector paramName="agentIds" width={250} isMulti options={agentOptions} label="代理店で絞込み" />
            <QuerySelector paramName="agentShopIds" width={250} isMulti options={agentShopOptions} label="代理店店舗で絞込み" />
            <QuerySelector paramName="contentProductIds" width={250} isMulti options={contentProductOptions} label="コンテンツ商品で絞込み" />
          </div>
          <div className='overflow-auto mt-2'>
            <ContentOrdersTable
              {...{
                agentsById,
                agentShopsById,
                contentProductsById,
                startOn,
                endOn,
                agentIds,
                agentShopIds,
                contentProductIds,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
