const { camelCase, omit, keyBy, range, isEmpty } = require('lodash');
const { getYear } = require('date-fns');
const { format: formatDate, addMinutes } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');
const numeral = require('numeral');
const dedent = require('dedent');
const qs = require('qs');

const { ordererFields, destinationFields } = require('./order');
const { fieldDisplayValue } = require('../util');
const { supportMeans } = require('../config');

const { keys, entries } = Object;

const statuses = {
  initial: { label: '未対応', color: 'warning' },
  supporting: { label: '対応中', color: 'info' },
  awaiting: { label: '待ち', color: 'success' },
  supported: { label: '対応完了', color: 'secondary' },
};
const basicFields = ({ productTypes = [], products = [] } = {}) => {
  return {
    productTypeId: {
      label: '商品種別',
      type: 'select',
      options: productTypes.map((_) => ({ label: _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        isSearchable: false,
      },
    },
    productId: {
      label: '商品',
      type: 'select',
      options: products.filter((_) => !_.isPart).map((_) => ({ label: _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        isSearchable: false,
      },
    },
    purchaseYear: {
      label: '購入時期(年)',
      type: 'select',
      options: range(getYear(new Date()), 1999).map((_) => ({ label: `${_}年`, value: _ })),
      validations: {
        required: (_) => _ != null,
      },
      inputProps: {
        isSearchable: false,
      },
    },
    purchaseMonth: {
      label: '購入時期(月)',
      type: 'select',
      options: range(1, 13).map((_) => ({ label: `${_}月頃`, value: _ })),
      validations: {
        required: (_) => _ != null,
      },
      inputProps: {
        isSearchable: false,
      },
    },
  };
};

const activityTypes = {
  startSupportOfMethodInquiry: {
    text: (_) => '対応開始',
  },
  editMethodInquiry: {
    text: (_) => 'パーツ編集',
  },
  finishSupportOfMethodInquiry: {
    text: (_) => '対応完了',
  },
  unfinishSupportOfMethodInquiry: {
    text: (_) => '対応再開',
  },
  createOrderOfMethodInquiry: {
    text: (_) => '発送',
  },
  updatePicOfMethodInquiry: {
    text: (_) => '担当変更',
  },
  requestedApprovalOfMethodInquiry: {
    text: (_) => '承認申請',
  },
  editDestinationOfMethodInquiry: {
    text: (_) => '送り先変更',
  },
  sendMethodInquiryEmail: {
    text: (_) => 'メール送信',
  },
};

const itemFields = ({ products = [] } = {}) => {
  const productsById = keyBy(products, 'id');
  return {
    productId: {
      label: '商品',
      type: 'select',
      options: products.map((_) => ({ label: _.label || _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    quantity: {
      label: '数量',
      type: 'integer',
      validations: {
        greaterThan0: (_) => _ > 0,
      },
    },
  };
};

const approvalRequestFields = () => {
  return {
    approvalOrRejectionComment: {
      label: '承認/否認コメント',
      type: 'text',
      readOnly: () => true,
    },
    approvalRequestComment: {
      label: '承認申請コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const approvalFields = () => {
  return {
    approvalRequestComment: {
      label: '承認申請コメント',
      type: 'text',
      readOnly: () => true,
    },
    approvalOrRejectionComment: {
      label: '承認/否認コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const fields = (...args) => {
  return {
    ...basicFields(...args),
    ...ordererFields(...args),
  };
};

const commentFields = () => {
  return {
    body: {
      type: 'text',
      label: 'コメント内容',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const supportContentsFields = ({ surveys = [] } = {}) => {
  return {
    supportMeans: {
      label: '対応方法',
      type: 'multiSelect',
      options: entries(supportMeans).map(([key, value]) => ({ label: value, value: key })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    supportSummary: {
      label: '対応内容',
      type: 'text',
      validations: {
        required: (_) => _,
      },
    },
    supportSurvey: {
      label: '対応完了後アンケート',
      type: 'select',
      options: surveys.map(_ => ({ label: _.title, value: _.id })),
      initialValue: '',
    },
    supportSurveySentDate: {
      label: '送信日時',
      type: 'datetime',
      validations: {
        laterThanNow: v => v > new Date()
      },
      hidden: _ => !_.supportSurvey,
    }
  };
};

const emailFields = () => {
  return {
    body: {
      label: 'メール本文',
      type: 'text',
    },
    files: {
      label: '添付ファイル',
      type: 'files',
    },
  };
};

const confirmMailBody = (methodInquiry, products, productTypes, questions, host) => {
  const {
    id,
    items,
    createdBy: { email, displayName },
    createdAt,
  } = methodInquiry;
  const productsById = keyBy(products, 'id');
  return dedent`
    下記の乗り方・使い方問合せを受付しました。

    [問合せ番号] ${id}

    [日時] ${formatDate(utcToZonedTime(createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}

    ---------- 問合せ情報 ----------

    ${entries(omit(basicFields({ productTypes, products }), ['files', 'destinationType']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(methodInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}
    ${questions.map((question) => {
      const { id, description, type, } = question;
      const value = methodInquiry.answers?.[id];
      return `[${description}] ${({
        text: _ => value,
        checkbox: _ => keys(value || {}).join(', '),
        radio: _ => value,
        select: _ => value,
        imageFile: _ => value?.map?.((_) => _.name).join(', '),
      })[type]() || null}`;
    }).join('\n')}

    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(methodInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}


    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

const shipmentMailBody = (methodInquiry, products, productTypes, host) => {
  const {
    id,
    items,
    createdBy: { email, displayName },
    createdAt,
    shipmentMessage = '',
    orderId = '',
  } = methodInquiry;
  const productsById = keyBy(products, 'id');
  const productOrPartText = items.some(({ productId }) => productsById[productId]?.isBody) ? '商品' : 'パーツ';

  return dedent`
    ${methodInquiry.name} 様

    新しい${productOrPartText}をお送りさせて頂きます。

    ${shipmentMessage}

    [問合せ番号] ${id}

    ---------- 発送${productOrPartText} ----------

    [注文番号] ${orderId}

    [${productOrPartText}]
    ${items
      .map(({ productId, quantity }) => {
        const { name: productName } = productsById[productId];
        return `${productName} ${quantity}個`;
      })
      .join('\n')}

    ---------- お届け先情報 ----------

    ${entries(destinationFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(shippingDestination(methodInquiry)[fieldName], fieldSettings)}`;
      })
      .join('\n')}
    
    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(methodInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}


    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

const reimbursementRequestMailBody = (methodInquiry, host) => {
  const {
    id,
    name,
    reimbursementRequest: { amount, description, requestedAt, requestedBy },
  } = methodInquiry;

  return dedent`
    ${name} 様

    立替工賃の申請を受付ました。

    [問合せ番号] ${id}

    ---------- 申請内容 ----------

    [申請金額] ${numeral(amount).format('0,0')}円
    [申請日時] ${formatDate(utcToZonedTime(requestedAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}
    [申請者] ${requestedBy.displayName} (${requestedBy.email})
    [連絡事項]
    ${description}
    
    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(methodInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

const supportSurveyMailBody = (methodInquiry, url) => {
  const { name, createdBy } = methodInquiry;
  return dedent`
    ${createdBy?.displayName || name} 様

    この度は不具合お問合せありがとうございました。

    宜しければ以下のURLからアンケートへのご回答をお願いします。

    ${url}
  `;
}

const replyEmailBody = (methodInquiry, body, products, productTypes, questions, host) => {
  const { id, name, items, createdAt, createdBy } = methodInquiry;
  const productsById = keyBy(products, 'id');
  return dedent`
    ${createdBy?.displayName || name} 様

    お問合せへのメッセージが届いています。
    以下のURLからご確認ください。

    ${host || '{host}'}/mypage/methodInquiries?messagesMethodInquiryId=${methodInquiry.id}



    [問合せ番号] ${id}

    [日時] ${formatDate(utcToZonedTime(createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}

    ---------- お問合せ情報 ----------

    ${entries(omit(basicFields({ productTypes, products }), ['files', 'destinationType']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(methodInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}
      ${questions.map((question) => {
        const { id, description, type, } = question;
        const value = methodInquiry.answers?.[id];
        return `[${description}] ${({
          text: _ => value,
          checkbox: _ => keys(value || {}).join(', '),
          radio: _ => value,
          select: _ => value,
          imageFile: _ => value?.map?.((_) => _.name).join(', '),
        })[type]() || null}`;
      }).join('\n')}

    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(methodInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

  `;
}

const shipmentFields = () => {
  return {
    shipmentMessage: {
      type: 'text',
      label: '発送メールでのユーザーへのメッセージ',
      rows: 6,
    },
    shipmentPackageNote: {
      type: 'text',
      label: '発送時の梱包指示備考',
      hint: '最大111文字までで指定してください',
      validations: {
        maxLength: v => (v || '').length <= 111,
      },
    },
  };
};

const shippingDestination = (inquiry) => {
  return keys(destinationFields()).reduce((x, y) => {
    return {
      ...x,
      [y]: inquiry[y] || inquiry[camelCase(y.replace(/^destination/, ''))] || null,
    };
  }, {});
};

module.exports = {
  basicFields,
  ordererFields,
  fields,
  itemFields,
  statuses,
  commentFields,
  activityTypes,
  confirmMailBody,
  supportContentsFields,
  supportSurveyMailBody,
  emailFields,
  replyEmailBody,
  shippingDestination,
  shipmentFields,
  shipmentMailBody,
};
