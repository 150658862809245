import React from 'react';
import classnames from 'classnames';

import firebase from '../firebase';
import { i18nField } from '../shared/i18n';
import LectureIcon from './LectureIcon';
import useDocumentFetch from './hooks/useDocumentFetch';

const db = firebase.firestore();

export default function LectureTypeDisplay(props) {
  const { event, className, } = props;
  const lectureType = useDocumentFetch(props.lectureType && db.collection('lectureTypes').doc(props.lectureType), [props.lectureType]);

  return (
    <span className={classnames(`d-flex align-items-center font-weight-bold lecture-type-display lecture-type-${lectureType}`, className)}>
      {lectureType?.[i18nField(event?.lang, 'name')]}
    </span>
  );
};

LectureTypeDisplay.disabledTranslation = true;
