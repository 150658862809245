import React, { useEffect, Fragment, useState, } from 'react';
import { useZxing } from 'react-zxing';

export default function QrReader (props) {
  const { onRead } = props;
  const [result, setResult] = useState('');
  const { ref } = useZxing({
    onResult(result) {
      setResult(result.getText());
    },
  });
  useEffect(() => {
    result && onRead(result);
  }, [result]);

  return (
    <div>
      <video ref={ref} width={300} height={300} />
    </div>
  );
};


