import React, { useEffect } from 'react';
import {
  format as formatDate,
  addDays,
  addMonths,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  differenceInSeconds,
} from 'date-fns';
import { sumBy, findKey, groupBy, orderBy, uniq, keyBy, isEmpty, get } from 'lodash';
import { Button } from 'reactstrap';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useQueryParams from '../hooks/useQueryParams';
import { fullPathWithParams, formatDuration } from '../../util';
import QueryDateSelector from '../QueryDateSelector';
import QueryBoolean from '../QueryBoolean';
import QuerySelector from '../QuerySelector';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts';
import TenantLink from '../TenantLink';
import SavedSearchParameterForm from '../forms/SavedSearchParameterForm';

const { entries } = Object;
const screenTypes = {
  admin: { label: '管理画面', paths: ['admin'], },
  products: { label: '商品', paths: ['products'], },
  parts: { label: 'パーツ', paths: ['parts'], },
  referral: { label: 'リファラ', paths: ['referralRedirect', 'referrerQrCode'], },
  orders: { label: '注文', paths: ['orders', 'orderThanks'], },
  agents: { label: 'ビジネスアカウント', paths: ['agents'], },
  events: { label: 'イベント', paths: ['events'], },
  surveys: { label: 'アンケートページ', paths: ['surveys'], },
  inquiries: { label: '問合せ', paths: ['inquiry', 'inquiries', 'contacts'], },
  troubleInquiries: { label: '不具合・組立問合せ', paths: ['troubleInquiry', 'troubleInquiries'], },
  methodInquiries: { label: '乗り方問合せ', paths: ['methodInquiry'], },
  contents: { label: 'コンテンツ', paths: ['contents'], },
  unknown: { label: '未分類', },
  other: { label: 'その他', paths: ['terms'], },
};
const screenTypeOptions = entries(screenTypes).map(([k, v]) => ({ label: v.label, value: k, }));
const db = firebase.firestore();
const accessLogsRef = db.collection('accessLogs');
const agentsRef = db.collection('agents');
const agentShopsRef = db.collectionGroup('agentShops');
const referrersRef = db.collectionGroup('referrers');
const qrUrlsRef = db.collection('qrUrls');

export default AdminPage(function AdminAccessLogs(props) {
  const { location, history } = props;
  const now = new Date();
  const params = useQueryParams();
  const {
    startOn: startOnString = formatDate(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(endOfMonth(new Date()), 'yyyy-MM-dd'),
    includeStaff: _includeStaff = '0',
    screenTypes: screenTypesForFilter,
    agents: agentsForFilter,
    agentShops: agentShopsForFilter,
    sortConditions: _sortConditions = ['accessCount'],
    groups: groupForFilter,
    displayCounts: _displayCounts = ['10'],
  } = params;
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const includeStaff = _includeStaff === '1';

  const { items: _accessLogs, isLoading } = useCollectionSubscriptionInTenant(
    accessLogsRef
      .where('createdAt', '>=', startOfDay(startOn))
      .where('createdAt', '<=', endOfDay(new Date(endOn)))
      .where('createdBy.role', 'in', includeStaff ? ['admin', 'staff', 'user', 'unauth'] : ['user', 'unauth'])
      .orderBy('createdAt', 'desc'),
    [startOnString, endOnString, includeStaff],
    { detail: true }
  );

  const referrers = useCollectionsFetchInTenant(
    uniq(_accessLogs.map((_) => _.referrerKey).filter((_) => _)).map((_) => referrersRef.where('key', '==', _)),
    [_accessLogs.length]
  );
  const referrersById = keyBy(referrers, 'id');
  const agents = useCollectionSubscriptionInTenant(agentsRef);
  const agentOptions = agents.map((_) => ({ label: _.name, value: _.id }));
  const agentShops = useCollectionSubscriptionInTenant(agentShopsRef);
  const agentShopOptions = agentShops.map((_) => ({ label: _.name, value: _.id }));
  const sortConditionOptions = [
    { label: 'アクセス回数', value: 'accessCount' },
    { label: '総滞在時間', value: 'allVisitSeconds' },
  ];
  const contentGroups = useCollectionSubscriptionInTenant(db.collection('contentGroups').orderBy('name'));
  const surveyGroups = useCollectionSubscriptionInTenant(db.collection('surveyGroups').orderBy('name'));
  const groupOptions = screenTypesForFilter?.includes('contents')
    ? contentGroups.map((_) => ({ label: _.name, value: _.id }))
    : screenTypesForFilter?.includes('surveys')
    ? surveyGroups.map((_) => ({ label: _.name, value: _.id }))
    : []
  const displayCountOptions = [
    { label: '10以上', value: '10' },
    { label: '100以上', value: '100' },
    { label: '全て', value: 'all' },
  ];

  let filteredAccessLogs = _accessLogs.map((_) => {
    const { path, groupId } = _;
    const referrer = referrersById[_.referrerKey];
    const agentShop = agentShops.find((_) => _.id === referrer?.ref.parent.parent.id);
    const agent = agents.find((_) => _.id === agentShop?.ref.parent.parent.id);
    const screenType = findKey(screenTypes, _ => _.paths?.includes(path.split(/[/?]/)?.[2])) || 'unknown';
    const group = screenType === 'contents'
      ? contentGroups.find(_ => _.id === groupId)?.name
      : screenType === 'surveys'
      ? surveyGroups.find(_ => _.id === groupId)?.name
      : null;
    return { ..._, referrer, agentShop, agent, screenType, group, };
  });
  if (!isEmpty(screenTypesForFilter)) {
    filteredAccessLogs = filteredAccessLogs.filter((_) => screenTypesForFilter.includes(_.screenType));
  }
  if (!isEmpty(agentsForFilter)) {
    filteredAccessLogs = filteredAccessLogs.filter((_) => agentsForFilter.includes(get(_, 'agent.id')));
  }
  if (!isEmpty(agentShopsForFilter)) {
    filteredAccessLogs = filteredAccessLogs.filter((_) => agentShopsForFilter.includes(get(_, 'agentShop.id')));
  }
  if (!isEmpty(groupForFilter)) {
    filteredAccessLogs = filteredAccessLogs.filter((_) => groupForFilter.includes(_.groupId));
  }

  const groupedAccessLogs = Object.values(groupBy(filteredAccessLogs, 'path')).map((accessLogs) => {
    const allVisitSeconds = sumBy(accessLogs, _ => _.duration ?? 0);
    return { accessLogs, allVisitSeconds, accessCount: accessLogs.length };
  }).filter(({ accessCount }) => {
    if (_displayCounts.includes('10')) return accessCount >= 10;
    if (_displayCounts.includes('100')) return accessCount >= 100;
    if (_displayCounts.includes('all')) return true;
    return true;
  });
  const qrUrls = useCollectionFetchInTenant(qrUrlsRef);
  const qrUrlsById = keyBy(qrUrls, 'id');

  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd') },
      location
    );
    history.replace(encodeURI(path));
  };

  return (
    <div>
      <div className="container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アクセスログ集計</h4>
        </div>
        <div className="d-flex align-items-end flex-wrap gap-2 mb-3">
          <Button onClick={() => onClickDateButton([addDays(now, -1), addDays(now, -1)])}>昨日</Button>
          <Button onClick={() => onClickDateButton([now, now])}>今日</Button>
          <Button onClick={() => onClickDateButton([startOfMonth(now), endOfMonth(now)])}>今月</Button>
          <Button onClick={() => onClickDateButton([startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}>
            先月
          </Button>
          <QueryDateSelector
            paramName="startOn"
            label="開始日"
            history={history}
            location={location}
            defaultValue={startOfMonth(new Date())}
            style={{ width: 150 }}
          />
          <QueryDateSelector
            paramName="endOn"
            label="終了日"
            history={history}
            location={location}
            defaultValue={endOfMonth(new Date())}
            invalid={startOn > endOn}
            style={{ width: 150 }}
          />
          <QueryBoolean paramName="includeStaff" label="スタッフを含める" defaultValue={'0'} />
          <SavedSearchParameterForm {...props} type="accessLog" />
        </div>
        <div className="mt-2 d-flex align-items-end flex-wrap gap-2">
          <QuerySelector paramName="screenTypes" width={250} isMulti options={screenTypeOptions} label="画面種別で絞込み" />
          <QuerySelector paramName="groups" width={250} isMulti options={groupOptions} label="グループで絞込み" />
          <QuerySelector paramName="agents" width={250} isMulti options={agentOptions} label="代理店で絞込み" />
          <QuerySelector paramName="agentShops" width={200} isMulti options={agentShopOptions} label="店舗で絞込み" />
          <QuerySelector paramName="sortConditions" width={200} options={sortConditionOptions} label="ソート条件" />
          <QuerySelector paramName="displayCounts" width={200} options={displayCountOptions} label="表示数" defaultValue="10" isClearable={false} />
        </div>
        <div className="mt-3">
          {isLoading ? (
            <div>
              <span className="fas fa-spin fa-spinner" />
            </div>
          ) : (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th>テナント</th>
                  <th style={{ width: 150 }}>画面種別</th>
                  <th style={{ width: 150 }}>グループ</th>
                  <th style={{ width: 150 }}>ページタイトル</th>
                  <th style={{ width: 200 }}>URL</th>
                  <th style={{ width: 150 }}>QRコード</th>
                  <th style={{ width: 150 }}>リファラ</th>
                  <th style={{ width: 150 }}>代理店</th>
                  <th style={{ width: 150 }}>店舗</th>
                  <th>アクセス回数</th>
                  <th>総滞在時間</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orderBy(groupedAccessLogs, _sortConditions, 'desc').map(
                  ({ accessLogs, accessCount, allVisitSeconds, }) => {
                    const { screenType, path, tenantId, qrUrlId, referrer, agentShop, agent, group } = accessLogs[0];
                    const documentTitle = accessLogs.find((_) => _.documentTitle)?.documentTitle;
                    const qrUrl = qrUrlsById[qrUrlId];
                    const groupedUserLogsByEmail = groupBy(accessLogs, 'createdBy.email');

                    return (
                      <tr key={path}>
                        <td>{tenantId}</td>
                        <td>{screenTypes[screenType]?.label}</td>
                        <td>{group}</td>
                        <td>{documentTitle}</td>
                        <td className="text-break">
                          <a href={path}>{path}</a>
                        </td>
                        <td>{qrUrl?.name}</td>
                        <td>{referrer?.name}</td>
                        <td>{agent?.name}</td>
                        <td>{agentShop?.name}</td>
                        <td>{accessCount} </td>
                        <td>{formatDuration(allVisitSeconds)}</td>
                        <td>
                          <ModalButton
                            label="詳細"
                            title="アクセス詳細"
                            outline
                            color="info"
                            className="ml-2"
                            size="sm"
                            modalProps={{ size: 'lg' }}
                            content={(_) => {
                              return (
                                <div>
                                  <table className="table">
                                    <thead className="thead-light text-center">
                                      <tr>
                                        <th>名前</th>
                                        <th>メールアドレス</th>
                                        <th>IPアドレス</th>
                                        <th>アクセス回数</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orderBy(Object.values(groupedUserLogsByEmail), [(_) => _.length], 'desc').map(
                                        (logs, i) => {
                                          const { createdBy } = logs[0];
                                          if (createdBy.email) {
                                            return (
                                              <tr key={i}>
                                                <td>
                                                  <TenantLink to={`/admin/users/${createdBy.uid}/accessLogs`}>
                                                    {createdBy.displayName}
                                                  </TenantLink>
                                                </td>
                                                <td>{createdBy.email}</td>
                                                <td>{createdBy.ipAddress}</td>
                                                <td>{logs.length}</td>
                                              </tr>
                                            );
                                          } else {
                                            return (
                                              <tr key={i}>
                                                <td>未ログインユーザー</td>
                                                <td></td>
                                                <td>{createdBy.ipAddress}</td>
                                                <td>{logs.length}</td>
                                              </tr>
                                            );
                                          }
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              );
                            }}
                          />
                        </td>
                        <td>
                          <ModalButton
                            label="グラフ"
                            hidesHeader
                            outline
                            color="info"
                            className="ml-2"
                            size="sm"
                            modalProps={{ size: 'lg' }}
                            content={(_) => {
                              const cardWidth = 700;
                              return (
                                <div className="card">
                                  <div className="card-body d-flex justify-content-center align-items-center">
                                    <div>
                                      <VisitTimeChart cardWidth={cardWidth} accessLogs={accessLogs} />
                                      <DailyChart cardWidth={cardWidth} accessLogs={accessLogs} />
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
});

const VisitTimeChart = ({ cardWidth, accessLogs }) => {
  const chartWidth = cardWidth * 0.9;
  const chartHeight = chartWidth * 0.7;
  const _data = groupBy(
    accessLogs
      .filter(_ => _.duration)
      .map(_ => _.duration)
  );
  const data = Object.entries(_data).map(([key, values]) => ({
    label: formatDuration(key),
    value: Number(key),
    count: values.length,
  }));
  return (
    <div>
      <div className="text-center">滞在時間グラフ</div>
      <LineChart width={chartWidth} height={chartHeight} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
      </LineChart>
    </div>
  );
};

const DailyChart = ({ cardWidth, accessLogs }) => {
  const chartWidth = cardWidth * 0.9;
  const chartHeight = chartWidth * 0.7;
  const _data = groupBy(
    accessLogs.filter((_) => _.lastVisitedAt).map((_) => formatDate(_.createdAt.toDate(), 'yyyy-MM-dd'))
  );
  const data = Object.entries(_data).map(([key, values]) => ({
    label: key,
    count: values.length,
  }));
  return (
    <div>
      <div className="text-center">日別グラフ</div>
      <LineChart width={chartWidth} height={chartHeight} data={orderBy(data, 'label')}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
      </LineChart>
    </div>
  );
};
