import React, { useEffect } from 'react';
import { Button, Progress } from 'reactstrap';
import { isEmpty, sortBy, uniq } from 'lodash';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { statuses, fields } from '../../shared/models/contact';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import DeleteButton from '../DeleteButton';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { useSurveyCollection, tenantSurveysQuery } from '../../models/survey';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';

const db = firebase.firestore();
const contactsRef = db.collection('contacts');
const { entries } = Object;

export default AdminPage(function AdminContact(props) {
  const {
    match: {
      params: { contactId },
    },
  } = props;
  const contact = useDocumentSubscription(contactsRef.doc(contactId), [contactId]);

  const tenant = useTenant();
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const { data: surveys = [] } = useSurveyCollection(tenantSurveysQuery(tenant?.id));
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map(_ => _.group));
  const contactCandidates = useCollectionSubscriptionInTenant(db.collection(`contacts/${contactId}/contactCandidates`));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [contact]);

  const { label: statusLabel, color } = statuses[contact?.status] || {};
  const pregressValue = contactCandidates.length ? contactCandidates.filter(_ => _.status === 'completed').length / contactCandidates.length * 100 : 0;

  return (
    contact != null && (
      <div className="admin-user container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>コンタクトリスト詳細</h4>
          </div>
          <div className="d-flex justify-content-end mb-3 gap-2 flex-wrap text-nowrap">
            {['completed'].includes(contact.status) && (
              <Button tag={TenantLink} to={`/admin/contacts/${contact.id}/contactTargets`} target="_blank">
                コンタクトリストを表示
              </Button>
            )}
            <DeleteButton itemRef={contact.ref} disabled={!['completed', 'error'].includes(contact.status)} />
          </div>
          <div>
            <table className="table table-bordered">
              <tbody className="thead-light">
                <tr>
                  <th style={{ width: 200 }}>ステータス</th>
                  <td style={{ whiteSpace: 'pre-line' }}>
                    <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                    {contact.status === 'creating' && (
                      <div className="w-100">
                        <Progress color="success" value={Math.min(pregressValue, 100)} />
                        <div className="text-center">{Math.min(Math.ceil(pregressValue), 100)}%</div>
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 200 }}>配信日時</th>
                  <td style={{ whiteSpace: 'pre-line' }}>
                    {contact.completedAt && formatDate(contact.completedAt.toDate(), 'yyyy/MM/dd HH:mm')}
                  </td>
                </tr>
                {entries({
                  ...fields({ products, productTypes, events, userTags, surveys, areaGroups }),
                }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {fieldDisplayValue(contact[fieldName], fieldSetting)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
});
