import React, { Fragment, useState } from 'react';
import { Button, Input } from 'reactstrap';
import { keyBy, groupBy, sortBy, omit, get, pick, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';
import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import retry from 'async-retry';

import { fieldDisplayValue } from '../../shared/util';
import { blockSmsFields, fields, childFields } from '../../shared/models/user';
import firebase, { functions } from '../../firebase';
import UserPage from '../hocs/UserPage';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import ProfileFormModal from '../modals/ProfileFormModal';
import ChildrenFormModal from '../modals/ChildrenFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';

const removeSubUser = functions.httpsCallable('removeSubUser');
const approveSubUser = functions.httpsCallable('approveSubUser');
const rejectSubUser = functions.httpsCallable('rejectSubUser');
const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const { entries } = Object;

export default UserPage(function MypageProfile(props) {
  const { user, myAccount } = props;
  const queryParams = useQueryParams();
  const subUsers = useCollectionSubscription(db.collection('users').where('mainUserId', '==', user.id), [user]);
  const followedTenants = useDocumentsFetch((user.tenantIds || []).map(_ => db.collection('tenants').doc(_)), [user]);
  const followedTenantMagazineGroups = useCollectionsFetch((user.tenantIds || []).map(_ => db.collection('magazineGroups').where('tenantId', '==', _)), [user]);
  const magazineGroupsGroupedByTenantId = groupBy(followedTenantMagazineGroups, 'tenantId');
  const magazineGroupsById = keyBy(followedTenantMagazineGroups, 'id');
  const pendingSubUsers = useCollectionSubscription(usersRef.where('pendingMainUserId', '==', user.id), [user]);
  const [showsProfileModal, toggleProfileModal] = useToggle();
  const magazineGroups = useCollectionSubscription(db.collection('magazineGroups').orderBy('createdAt'));
  const onSubmitProfileForm = async (values) => {
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(user.email, values.currentPassword);
      await auth.currentUser.reauthenticateWithCredential(credential);
      if (user.email !== values.email) {
        await auth.currentUser.verifyBeforeUpdateEmail(values.email);
      }
      if (user.displayName !== values.displayName) {
        await auth.currentUser.updateProfile({
          displayName: values.displayName,
        });
      }
      await usersRef
        .doc(user.uid)
        .update(
          pick(values, [
            'email',
            'displayName',
            'nameKana',
            'phone',
            'postalCode',
            'prefecture',
            'city',
            'address',
            'route',
            'blocksSms',
            'blocksMagazineGroupIds',
            'trs110User',
            'trs110Password',
          ])
        );
      toast.success('更新しました');
      toggleProfileModal();
    } catch (e) {
      const message = e.code === 'auth/wrong-password' ? 'パスワードが間違っています' : '失敗しました。';
      toast.error(message);
      console.error(e);
    }
  };

  return (
    user && (
      <div className="mypage-profile position-relative">
        <section className="container-fluid bg-light-grey">
          <h5 className="text-center mb-5 font-weight-bold p-4">プロフィール</h5>
        </section>
        <section className="container-fluid position-relative d-flex flex-wrap align-items-start justify-content-around gap-3">
          <div className="card" style={{ width: 300 }}>
            <div className="card-header">基本情報</div>
            <div className="card-body">
              <div>
                {entries(
                  pick(fields({ magazineGroups }), [
                    'email',
                    'displayName',
                    'nameKana',
                    'phone',
                    'postalCode',
                    'prefecture',
                    'city',
                    'address',
                    'route',
                    'blocksSms',
                    'blocksMagazineGroupIds',
                  ])
                ).map(([fieldName, { label, type }]) => {
                  const value = fieldDisplayValue(user[fieldName], fields({ magazineGroups })[fieldName]);
                  return (
                    <div className="mb-3" key={fieldName}>
                      <div className="text-muted small">{label}</div>
                      <div>{(Array.isArray(value) ? value.join(', ') : value) || '(未登録)'}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            {!myAccount?.isSubUser && (
              <Button block onClick={toggleProfileModal}>
                <span className="fas fa-edit mr-1" />
                編集
              </Button>
            )}
          </div>
          <div className="card" style={{ width: 300 }}>
            <div className="card-header">SMS/メール配信(お客様サポート)</div>
            <div className="card-body">
              <div>
                {followedTenants.map((tenant) => {
                  const { id, name, } = tenant;
                  const magazineGroups = magazineGroupsGroupedByTenantId[id] || [];
                  return (
                    <div key={id} className="mb-3 bg-grey rounded p-3 flex-fill">
                      <div className="large font-weight-bold mb-3">{name}</div>
                      <div>SMS/メール配信を停止: {user[`blocksSms__${id}`] ? 'はい' : 'いいえ'}</div>
                      {!isEmpty(user[`blocksMagazineGroupIds__${id}`]) && <div>停止中のSMS/メール配信種類: {user[`blocksMagazineGroupIds__${id}`].map(_ => magazineGroupsById[_]?.name).join(', ')}</div>}
                    </div>
                  );
                })}
              </div>
            </div>
            <EditButton
              block
              itemRef={user.ref}
              FormModal={ModelFormModal}
              formProps={{
                title: '編集',
                fields: blockSmsFields({ tenants: followedTenants, magazineGroupsGroupedByTenantId }),
                renderFormHeader: () => {
                  return <div className="text-muted mb-2"><span className="fas fa-info-circle mr-1"></span>利用者情報は、注文詳細から変更できます。</div>;
                },
              }}
            />
          </div>
          <div className="card" style={{ width: 300 }}>
            {isEmpty(user.children) ? (
              <EditButton label="お子様情報を登録する" block itemRef={user.ref} FormModal={ChildrenFormModal} />
            ) : (
              <Fragment>
                <div className="card-header">お子様情報</div>
                <div className="card-body">
                  <div>
                    {(user.children || []).map((child, i) => {
                      const { name, birthday, gender, vehicleExperiences = [] } = child;
                      return (
                        <div className="mb-3 bg-grey rounded p-3 flex-fill" key={i}>
                          <div className="large font-weight-bold mb-3">{name}</div>
                          {gender && <div>{fieldDisplayValue(gender, childFields()['gender'])}</div>}
                          <div>
                            {get(birthday, 'toDate') != null && formatDate(birthday.toDate(), 'yyyy年MM月dd日生まれ')}
                          </div>
                          <div>
                            {vehicleExperiences.map((_) => (
                              <div className="badge bg-white mr-1">{_}</div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <EditButton block itemRef={user.ref} FormModal={ChildrenFormModal} />
              </Fragment>
            )}
          </div>
          <div className='card' style={{ width: 300 }}>
            {(isEmpty(subUsers) && isEmpty(pendingSubUsers)) ? (
              <ModalButton title='サブアカウントを追加する' content={(_) => <SubUserInvitationContent user={user} />} isInitiallyOpen={queryParams.newSubAccount === '1'}>
                <span className='fas fa-plus mr-1' />
                サブアカウントを追加する
              </ModalButton>
            ) : (
              <Fragment>
                <div className="card-header">サブアカウント</div>
                <div className="card-body">
                  <div>
                    {sortBy(subUsers, 'displayName').map((subUser) => {
                      return (
                        <SubUserDisplay
                          subUser={subUser}
                          key={subUser.id}
                          isEditable={subUser.uid === myAccount.uid}
                          isRemovable={!myAccount.mainUserId}
                        />
                      );
                    })}
                    {sortBy(pendingSubUsers, 'displayName').map((pendingSubUser) => {
                      return (
                        <PendingSubUserDisplay
                          pendingSubUser={pendingSubUser}
                          key={pendingSubUser.id}
                        />
                      );
                    })}
                  </div>
                </div>
                <ModalButton
                  title="サブアカウントを追加する"
                  content={(_) => <SubUserInvitationContent user={user} />}
                  isInitiallyOpen={queryParams.newSubAccount === '1'}
                  disabled={myAccount.isSubUser}
                >
                  <span className="fas fa-plus mr-1" />
                  サブアカウントを追加する
                </ModalButton>
              </Fragment>
            )}
          </div>
        </section>
        {showsProfileModal && (
          <ProfileFormModal isOpen values={user} onClickClose={toggleProfileModal} onSubmit={onSubmitProfileForm} />
        )}
      </div>
    )
  );
});

function SubUserInvitationContent(props) {
  const { user } = props;
  const [token, setToken] = useState(null);
  const generateInvitationUrl = async () => {
    const token = uuid();
    const { exists, ref } = await user.ref.collection('subUserInvitations').doc(token).get();
    if (exists) return generateInvitationUrl();

    await ref.set({ token, createdAt: new Date() });
    setToken(token);
  };
  const invitationUrl = `${origin}/mypage?subUserInvitationToken=${token}`;
  const onClickCopy = () => {
    copy(invitationUrl);
    toast.success('コピーしました');
  };

  return (
    <div>
      {token ? (
        <div>
          <div className="d-flex">
            <Input className="flex-grow-1 mr-2" readOnly defaultValue={invitationUrl} />
            <Button color="primary" onClick={onClickCopy}>
              <span className="fas fa-copy" />
            </Button>
          </div>
          <div className="small text-muted mt-2 text-center">※一度しか表示されません</div>
        </div>
      ) : (
        <div>
          <div className="text-center mb-3">
            お孫さんへのプレゼントやママが購入してパパからの問合せなど
            <br />
            ご購入者とお使いになる方が異なる場合
            <br />
            サブアカウントを作成してください。
            <br />
            <br />
            注文履歴が紐づき
            <br />
            使い方や不具合などのお問合せがスムーズにできます。
            <br />
            <br />
            ≪作成方法≫
            <br />
            下記より招待URLをコピー
            <br />
            ↓
            <br />
            招待URLをご家族にLINEやメールで送信
            <br />
            ↓
            <br />
            ご家族がサブアカウントを作成
          </div>
          <div className="d-flex justify-content-center">
            <Button color="primary" onClick={generateInvitationUrl}>
              招待URLを取得する
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function SubUserDisplay(props) {
  const { subUser, isEditable, isRemovable } = props;
  const beforeSubmit = async (values) => {
    // NOTE: firestoreの活を確認
    await subUser.ref.get();

    const credential = firebase.auth.EmailAuthProvider.credential(subUser.email, values.currentPassword);
    await auth.currentUser.reauthenticateWithCredential(credential);
    if (subUser.email !== values.email) {
      await auth.currentUser.updateEmail(values.email);
      await auth.currentUser.sendEmailVerification();
    }
    if (subUser.displayName !== values.displayName) {
      await auth.currentUser.updateProfile({
        displayName: values.displayName,
      });
    }
    return omit(values, 'currentPassword');
  };
  const onClickRemove = async () => {
    if (!window.confirm('除外されたアカウントを再度サブアカウントにすることはできません。本当に除外しますか？')) return;

    try {
      await retry(removeSubUser.bind(null, { subUserUid: subUser.uid }), { retries: 3 });
      toast.success('除外しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    subUser != null && (
      <div className="mb-3 bg-grey rounded p-3 flex-fill">
        <div className="d-flex justify-content-between align-items-start">
          <div className="large font-weight-bold mb-3">{subUser.displayName}</div>
          {isEditable && (
            <EditButton
              color=""
              size="sm"
              itemRef={subUser.ref}
              FormModal={ModelFormModal}
              formProps={{ title: '編集', fields: pick(fields(), ['email', 'displayName', 'currentPassword']) }}
              beforeSubmit={beforeSubmit}
            />
          )}
          {isRemovable && (
            <ProgressButton color="danger" outline size="sm" process={onClickRemove}>
              除外
            </ProgressButton>
          )}
        </div>
        <div>{subUser.email}</div>
        <div>{subUser.phone}</div>
      </div>
    )
  );
}

function PendingSubUserDisplay(props) {
  const { pendingSubUser } = props;
  const onClickApprove = async () => {
    if (!window.confirm('このアカウントをサブアカウントとして承認しますか？')) return;

    try {
      await retry(approveSubUser.bind(null, { subUserUid: pendingSubUser.uid }), { retries: 3 });
      toast.success('承認しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  }

  const onClickReject = async () => {
    if (!window.confirm('除外されたアカウントを再度サブアカウントにすることはできません。本当に除外しますか？')) return;

    try {
      await retry(rejectSubUser.bind(null, { subUserUid: pendingSubUser.uid }), { retries: 3 });
      toast.success('除外しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    pendingSubUser != null && (
      <div className='mb-3 bg-grey rounded p-3 flex-fill'>
        <span className="badge badge-warning mb-1">未承認</span>
        <div className='d-flex justify-content-between align-items-start'>
          <div className='large font-weight-bold mb-3'>{pendingSubUser.displayName}</div>
          <ProgressButton color='success' size='sm' process={onClickApprove}>
            承認
          </ProgressButton>
          <ProgressButton color='danger' outline size='sm' process={onClickReject}>
            除外
          </ProgressButton>
        </div>
        <div>{pendingSubUser.email}</div>
        <div>{pendingSubUser.phone}</div>
      </div>
    )
  );
}
