import React from 'react';
import { Input, Button, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import TenantLink from './TenantLink';
import useTenant from './hooks/useTenant';

const tabs = [
  { name: 'shops', label: '店舗', path: '/agentShops' },
  { name: 'members', label: 'ビジネスアカウント', path: '/members' },
  { name: 'qrUrls', label: '紹介QRコード遷移先', path: '/qrUrls', shows: _ => _.agent.usesReferral, },
  { name: 'products', label: '商品', path: '/products', shows: _ => _.agent.usesReferral, },
  { name: 'comments', label: 'コメント', path: '/comments', },
  { name: 'contractPlans', label: '規約', path: '/contractPlans', shows: _ => _.agent.usesReferral, },
  { name: 'rentalItems', label: 'レンタル商品', path: '/rentalItems', shows: _ => _.agent.usesRental, },
  { name: 'contentProducts', label: 'コンテンツ商品', path: '/contentProducts', shows: _ => _.agent.usesReferral, },
  { name: 'settings', label: '設定', path: '/settings', },
];

export default function AdminAgentHeader (props) {
  const { activeTab, agent, } = props;
  const tenant = useTenant();
  const url = `${window.location.origin}/${tenant?.id}/agents/${agent.id}`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <a href={url} target="_blank" className="btn btn-secondary">
          ビジネスアカウント画面へ
          <span className="fas fa-external-link-alt ml-1" />
        </a>
        <div style={{ width: 300 }} className="ml-2 d-flex">
          <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
          <Button color="primary" onClick={onClickCopy}>
            <span className="fas fa-copy" />
          </Button>
        </div>
      </div>
      <Nav tabs>
        {
          tabs.filter(_ => _.shows == null || _.shows({ agent })).map(({ name, label, path }) => {
            return (
              <NavItem key={name}>
                <NavLink
                  tag={TenantLink}
                  className={classnames({ active: activeTab === name })}
                  to={`/admin/agents/${agent.id}${path}`}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })
        }
      </Nav>
    </div>
  );
};

