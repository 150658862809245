import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { PlaceDocumentData, Place } from '../types';
import type { PartiallyPartial } from 'types';

export const placeConverter = getConverter<PlaceDocumentData>();

export const placesRef = () => collection(getFirestore(), 'places').withConverter(placeConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Place> : null;
export const placeRef = <Id extends string | undefined>(placeId: Id) =>
  (placeId ? doc(placesRef(), placeId) : null) as RefOrNull<Id>;

export const newPlaceRef = () => doc(placesRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const placesQuery = (...queryConstraints: QueryConstraint[]) =>
  query(placesRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantPlacesQuery = (tenantId: string | undefined) =>
  tenantId ? placesQuery(...tenantQueryConstraints(tenantId)) : null;

export const addPlace = async (data: PartiallyPartial<Place, 'id' | 'createdAt'>) =>
  addDoc(placesRef(), { createdAt: serverTimestamp(), ...data });

export const updatePlace = async (placeId: string, data: UpdateData<Place>) => updateDoc(placeRef(placeId), data);

export const deletePlace = async (placeId: string) => deleteDoc(placeRef(placeId));

export const usePlaceCollection = useCollectionData;

export const usePlaceCollectionOnce = useCollectionDataOnce;

export const usePlaceDocument = useDocumentData;

export const usePlaceDocumentOnce = useDocumentDataOnce;
