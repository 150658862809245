const { isEmpty } = require('lodash');
const { isURL } = require('validator');

module.exports = {
  fields: ({ otherShortUrls = [], shortUrlGroups=[] }) => {
    return {
      shortUrlGroupId: {
        label: '短縮URLグループ',
        type: 'select',
        options: shortUrlGroups.map(({ id, name }) => ({ value: id, label: name })),
        validations: { required: (v) => !isEmpty(v) },
        initialValue: '',
      },
      name: {
        label: '名称',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
      key: {
        label: 'キー',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
          uniq: v => otherShortUrls.every(_ => _.key !== v),
        },
        hint: '短縮URLの一部になります',
      },
      textForQr: {
        label: 'QR内テキスト',
        type: 'string',
      },
      destination: {
        label: '転送先URL',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
          format: v => isEmpty(v) || v.includes('localhost') || isURL(v),
        },
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      memo: {
        label: '用途メモ',
        type: 'text',
        initialValue: '',
      },
    };
  },
};
