import { createContext, useContext, useState } from 'react';

const progressValueContext = createContext(0);
const setProgressValueContext = createContext(() => undefined);

export const ProgressValueProvider = ({ children }) => {
  const [progressValue, setProgressValue] = useState(null);

  return (
    <progressValueContext.Provider value={progressValue}>
      <setProgressValueContext.Provider value={setProgressValue}>{children}</setProgressValueContext.Provider>
    </progressValueContext.Provider>
  );
};

export const useProgressValue = () => useContext(progressValueContext);
export const useSetProgressValue = () => useContext(setProgressValueContext);
