import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import { TextEncoder } from 'text-encoding';
import { unparse as unparseCsv } from 'papaparse';
import { isFunction } from 'lodash';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { differenceInMinutes } from 'date-fns';
import firebase from '../firebase';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';

const db = firebase.firestore();
const exportRequestsRef = db.collection('exportRequests');

export default function ExportButton({ fileName, rows, label = 'エクスポート', hasPersonalInfo = false, user, ...extraProps }) {
  const [isExporting, setIsExporting] = useState(false);
  const [exportRequest] = useCollectionSubscriptionInTenant(
    exportRequestsRef
      .where('approvalStatus', '==', 'approved')
      .where('addedBy.uid', '==', user?.uid || '')
      .orderBy('approvedAt', 'desc'),
    [user]
  );
  const exportMinutes = differenceInMinutes(new Date(), exportRequest?.downloadStartAt?.toDate());
  const canExport = !hasPersonalInfo || (exportMinutes > 0 && exportMinutes < 60);

  const onClickExport = async () => {
    setIsExporting(true);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(isFunction(rows) ? await rows() : rows));
    fileDownload(fileContent, fileName);
    setIsExporting(false);
  };

  return (
    <Button color="secondary" onClick={onClickExport} disabled={isExporting || !canExport} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      {label}
    </Button>
  );
}
