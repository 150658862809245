import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  serverTimestamp,
  UpdateData,
  FieldPath,
  DocumentReference,
} from '../firebase';
import { tenantQueryConstraints, forwardMatchingQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { OrderDocumentData, Order } from '../types';
import type { PartiallyPartial } from 'types';

export const orderConverter = getConverter<OrderDocumentData>();

export const ordersRef = () => collection(getFirestore(), 'orders').withConverter(orderConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Order> : null;
export const orderRef = <Id extends string | undefined>(orderId: Id) =>
  (orderId ? doc(ordersRef(), orderId) : null) as RefOrNull<Id>;

export const newOrderRef = () => doc(ordersRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'desc')];

export const withinPeriodQueryConstraints = (start: Date, end: Date) => [
  where('createdAt', '>=', start),
  where('createdAt', '<=', end),
  orderBy('createdAt', 'desc'),
];

export const ordersQuery = (...queryConstraints: QueryConstraint[]) =>
  query(ordersRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const withinPeriodTenantOrdersQuery = (tenantId: string | undefined, start: Date, end: Date) =>
  tenantId ? ordersQuery(...tenantQueryConstraints(tenantId), ...withinPeriodQueryConstraints(start, end)) : null;

export const forwardMatchingTenantOrdersQuery = (
  tenantId: string | undefined,
  fieldPath: string | FieldPath,
  text: string
) =>
  tenantId
    ? ordersQuery(...tenantQueryConstraints(tenantId), ...forwardMatchingQueryConstraints(fieldPath, text))
    : null;

export const addOrder = async (data: PartiallyPartial<Order, 'id' | 'createdAt'>) =>
  addDoc(ordersRef(), { createdAt: serverTimestamp(), ...data });

export const updateOrder = async (orderId: string, data: UpdateData<Order>) => updateDoc(orderRef(orderId), data);

export const deleteOrder = async (orderId: string) => deleteDoc(orderRef(orderId));

export const getOrder = async (orderId: string) => getDoc(orderRef(orderId));

export const useOrderCollection = useCollectionData;

export const useOrderCollectionOnce = useCollectionDataOnce;

export const useOrderDocument = useDocumentData;

export const useOrderDocumentOnce = useDocumentDataOnce;
