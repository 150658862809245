import { ComponentProps } from 'react';
import useTenant from '../hooks/useTenant';
import { AddButton } from './AddButton';

export const AddInTenantButton = (props: ComponentProps<typeof AddButton>) => {
  const tenant = useTenant();
  const processValues = async (values) => {
    return { tenantId: tenant!.id, ...(await (props.processValues || ((_) => _))(values)) };
  };

  return <AddButton {...props} processValues={processValues} />;
};
