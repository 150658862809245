import React from 'react';
import classnames from 'classnames';

import { lectureAges } from '../shared/i18n';

export default function LectureAgeDisplay(props) {
  const { event, lectureAge, className, } = props;

  return lectureAge === '0-999' ? null : (
    <div className={classnames('bg-secondary rounded py-2 text-center', className)}>
      <div className="small font-weight-bold">対象年齢</div>
      <div className="mt-1 large font-weight-bold">
        {lectureAges[event?.lang || 'ja'][lectureAge]}
      </div>
    </div>
  );
};
