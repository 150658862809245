import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';

import useQueryParams from './hooks/useQueryParams';

export default function QuerySortLabel (props) {
  const { field, } = props;
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const sort = queryParams.sort ?? (props.defaultActive ? { field, direction: props.defaultDirection ?? 'asc' } : {});
  const onClick = () => {
    const values = {
      sort: {
        field,
        direction: field === sort.field && sort.direction === 'asc' ? 'desc' : 'asc',
      },
    };
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, ...values, }));
  };

  return (
    <span onClick={onClick} className="btn btn-link p-0">
      {props.children}
      <span className={classnames('fas', sort.field === field && { 'fa-sort-up': sort.direction === 'asc', 'fa-sort-down': sort.direction === 'desc' })} />
    </span>
  );
}
