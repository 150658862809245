import React, { useEffect } from 'react';
import { keyBy, omit, mapValues, } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';

const { entries, } = Object;

export default function ChildForm (props) {
  const { fields, values, onChange, shouldStarts = false, } = props;
  const statedFields = useFormState(values, fields, values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every(_ => _.isValid),
    });
  }, [...Object.values(statedFields).map(_ => _.value)]);
  useEffect(() => {
    if(shouldStarts) statedFields.startAll();
  }, [shouldStarts]);

  return (
    <div>
      <div>
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <div className="mr-2" style={{ minWidth: fieldName === 'name' && 250 }}>
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
