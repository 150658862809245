import React from 'react';
import { groupBy, sortBy, get, isEmpty, omit, omitBy, isUndefined, pick, orderBy } from 'lodash';
import { keyBy } from 'lodash';
import { toast } from 'react-toastify';
import { Button, Badge } from 'reactstrap';

import firebase from '../../firebase';
import { canUpdateCustomerJourney } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import CustomerJourneyFormModal from '../modals/CustomerJourneyFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { userConditionText } from '../../shared/util';
import { fields, orderTypes, entryTypes, customerJourneyRowFields } from '../../shared/models/customerJourney';
import TenantLink from '../TenantLink';
import QuerySelector from '../QuerySelector';
import useQueryParams from '../hooks/useQueryParams';
import { fields as groupFields } from '../../shared/models/customerJourneyGroup';
import { colors } from '../../shared/config';

const db = firebase.firestore();

const customerJourneysRef = db.collection('customerJourneys');
const customerJourneyGroupsRef = db.collection('customerJourneyGroups');

export default AdminPage(function AdminCustomerJourneys(props) {
  const { tenant, user } = props;
  const { groups: groupsForFilter } = useQueryParams();
  const customerJourneys = useCollectionSubscriptionInTenant(customerJourneysRef.orderBy('createdAt'));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts
    .filter((_) => _.isBody || _.isOption)
    .map((_) => ({
      ..._,
      label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`,
    }));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), (_) =>
    _.createdAt.toDate()
  );
  const productTypesById = keyBy(productTypes, 'id');
  const lectures = useCollectionSubscriptionInTenant(db.collectionGroup('lectures').orderBy('date'));
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));
  const userTagsById = keyBy(userTags, 'id');
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const sortedEvents = sortBy(events, (event) => {
    const date = get(lecturesGroupedByEventId, `${event.id}.0.date`);
    return date && date.toDate();
  });
  const customerJourneyGroups = sortBy(useCollectionSubscriptionInTenant(customerJourneyGroupsRef), (_) =>
    _.createdAt.toDate()
  );
  const customerJourneyGroupsById = keyBy(customerJourneyGroups, 'id');
  const groupOptions = customerJourneyGroups.map((_) => ({ label: _.name, value: _.id }));

  const onCreateCustomerJourneyGroup = async (value) => {
    const ref = await customerJourneyGroupsRef.add({ tenantId: user.tenantId, name: value, createdAt: new Date() });
    return ref.id;
  };

  let filteredCustomerJourneys = customerJourneys;
  if (!isEmpty(groupsForFilter)) {
    filteredCustomerJourneys = filteredCustomerJourneys.filter((_) =>
      groupsForFilter.some((group) => (get(_, 'customerJourneyGroupIds') || []).includes(group))
    );
  }

  const allRowsMap = customerJourneys.flatMap((_) => _.customerJourneyRows.map(({ key }) => key));
  const allRowsColorMap = orderBy(
    Object.entries(groupBy(allRowsMap)).map(([key, value]) => ({ key, count: value.length })),
    'count',
    'desc'
  )
    .map(({ key, count }, index) => {
      const colorIndex = index % colors.length;
      return { key, count, color: count > 1 ? colors[colorIndex] : null };
    })
    .reduce((x, y) => ({ ...x, [y.key]: y.color }), {});
  return (
    <div>
      <div className="admin-inquiry-types container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>カスタマージャーニー配信管理</h4>
              </div>
              <div className="d-flex justify-content-between flex-wrap gap-2 mb-3">
                <QuerySelector
                  paramName="groups"
                  className="ml-0"
                  width={400}
                  isMulti
                  options={groupOptions}
                  label="グループで絞込み"
                />
                <div className="d-flex justify-content-end align-items-end gap-2">
                  <ModalButton
                    title="グループ管理"
                    content={(_) => <CustomerJourneyGroups user={user} customerJourneyGroups={customerJourneyGroups} />}
                    modalProps={{ style: { minWidth: 1300 } }}
                  >
                    グループ管理
                  </ModalButton>
                  <ModalButton
                    Modal={ModelFormModal}
                    modalProps={{
                      title: 'グループによる一括配信停止',
                      submitLabel: '配信停止',
                      fields: {
                        customerJourneyGroupId: {
                          label: 'グループ',
                          type: 'select',
                          options: customerJourneyGroups.map((_) => ({ label: _.name, value: _.id })),
                          validations: {
                            required: (_) => !isEmpty(_),
                          },
                        },
                      },
                      onSubmit: async ({ customerJourneyGroupId }) => {
                        try {
                          await Promise.all(
                            customerJourneys
                              .filter(({ customerJourneyGroupIds }) =>
                                (customerJourneyGroupIds || []).includes(customerJourneyGroupId)
                              )
                              .map(({ ref }) => ref.update({ isSuspended: true }))
                          );
                          toast.success('一括配信停止しました');
                        } catch (e) {
                          console.error(e);
                          toast.error('失敗しました');
                        }
                      },
                    }}
                  >
                    一括配信停止
                  </ModalButton>
                  <AddInTenantButton
                    itemRef={customerJourneysRef.doc()}
                    FormModal={CustomerJourneyFormModal}
                    formProps={{
                      title: 'カスタマージャーニー追加',
                      tenant,
                      fields: fields({
                        products: selectableProducts,
                        productTypes,
                        events: sortedEvents,
                        userTags,
                        customerJourneyGroups,
                        onCreateCustomerJourneyGroup,
                      }),
                      rowFields: customerJourneyRowFields({
                        userTags,
                        useSms: tenant.useSms,
                        useMobile: tenant.useMobile,
                      }),
                    }}
                    disabled={!canUpdateCustomerJourney(user)}
                  />
                </div>
              </div>
              <div className="overflow-auto">
                {filteredCustomerJourneys.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center text-nowrap">
                      <tr>
                        <th>グループ</th>
                        <th>名称</th>
                        <th>注文条件</th>
                        <th>イベント参加条件</th>
                        <th>アカウント条件</th>
                        <th>明細</th>
                        <th>メモ</th>
                        <th style={{ minWidth: 120 }}></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCustomerJourneys.map((customerJourney) => {
                        const {
                          id,
                          ref,
                          name,
                          orderType = 'any',
                          conditionProductTypeIds,
                          conditionProductIds,
                          entryType = 'any',
                          conditionEventIds,
                          customerJourneyGroupIds = [],
                          customerJourneyRows,
                          note,
                        } = customerJourney;

                        const handleClickCopy = async () => {
                          if (!window.confirm('カスタマージャーニーをコピーしますか？')) return;
                          try {
                            await customerJourneysRef.add({
                              ...omit(customerJourney, ['id', 'ref', 'createdAt', 'addedBy']),
                              name: `${customerJourney.name} - コピー`,
                              status: 'copied',
                              createdAt: new Date(),
                              addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
                            });
                            toast.success('コピーしました。');
                          } catch (e) {
                            console.error(e);
                            toast.error('失敗しました');
                          }
                        };

                        return (
                          <tr key={id} id={id}>
                            <td>
                              <div className="d-flex flex-wrap gap-1">
                                {customerJourneyGroupIds.map((_) => (
                                  <div className="badge badge-secondary">{customerJourneyGroupsById[_]?.name}</div>
                                ))}
                              </div>
                            </td>
                            <td>
                              <TenantLink to={`/admin/customerJourneys/${id}`}>{name}</TenantLink>
                            </td>
                            <td style={{ whiteSpace: 'pre-line' }}>
                              {orderTypes[orderType]?.label}
                              {orderType === 'ordered' && (
                                <div style={{ maxWidth: 300, maxHeight: 200, overflow: 'hidden' }}>
                                  <div className="small text-muted">
                                    {conditionProductTypeIds?.map((_) => productTypesById[_]?.name).join(',')}
                                  </div>
                                  <div className="small text-muted">
                                    {conditionProductIds
                                      ?.map((_) => productsById[_])
                                      .map((_) => `[${_?.code}] ${_?.name}`)
                                      .join(',')}
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              {entryTypes[entryType]?.label}
                              {entryType === 'entried' && (
                                <div className="small text-muted">
                                  {conditionEventIds?.map((_) => eventsById[_]?.name).join(',')}
                                </div>
                              )}
                            </td>
                            <td className="text-muted small">{userConditionText(customerJourney, { userTagsById })}</td>
                            <td>
                              {customerJourneyRows.map((_) => (
                                <span
                                key={_.key}
                                  className="mr-1 px-2"
                                  title={_.key}
                                  style={{
                                    backgroundColor: allRowsColorMap[_.key],
                                    borderRadius: '0.5rem',
                                    border: "1px solid #d3d3d3",
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {_.name || '-'}
                                </span>
                              ))}
                            </td>
                            <td className="text-nowrap text-right">
                              <Button
                                className="ml-2"
                                onClick={handleClickCopy}
                                disabled={!canUpdateCustomerJourney(user)}
                              >
                                <span className="fas fa-copy mr-1" />
                                コピー
                              </Button>
                              <EditButton
                                itemRef={ref}
                                className="ml-2"
                                FormModal={CustomerJourneyFormModal}
                                formProps={{
                                  title: 'カスタマージャーニー編集',
                                  tenant,
                                  fields: fields({
                                    products: selectableProducts,
                                    productTypes,
                                    events: sortedEvents,
                                    userTags,
                                    customerJourneyGroups,
                                    onCreateCustomerJourneyGroup,
                                  }),
                                  rowFields: customerJourneyRowFields({
                                    userTags,
                                    useSms: tenant.useSms,
                                    useMobile: tenant.useMobile,
                                  }),
                                }}
                                disabled={!canUpdateCustomerJourney(user)}
                              />
                              <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateCustomerJourney(user)} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>カスタマージャーニーは未登録です</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function CustomerJourneyGroups(props) {
  const { user, customerJourneyGroups = [] } = props;
  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <AddInTenantButton
          itemRef={customerJourneyGroupsRef.doc()}
          FormModal={ModelFormModal}
          formProps={{ title: 'グループ追加', fields: groupFields() }}
          disabled={!canUpdateCustomerJourney(user)}
        />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>グループ名</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {customerJourneyGroups.map((customerJourneyGroup) => {
            const { id, ref, name } = customerJourneyGroup;
            return (
              <tr key={id}>
                <td>{name}</td>
                <td className="text-nowrap text-right">
                  <EditButton
                    itemRef={ref}
                    className="ml-1"
                    FormModal={ModelFormModal}
                    formProps={{ documentName: 'customerJourneyGroup', title: 'グループ編集', fields: groupFields() }}
                  />
                  <DeleteButton itemRef={ref} className="ml-1" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
