import React, { Fragment } from 'react';
import { Button, Form, } from 'reactstrap';
import { isEmpty, keyBy, pick, get, } from 'lodash';
import { useToggle, useMap, } from 'react-use';

import { basicFields } from '../../shared/models/methodInquiry';
import Field from '../Field';
import SettingText from '../SettingText';
import AppButton from '../AppButton';
import poNumberImageSrc from '../../images/po_number.png';
import QuestionForm from './QuestionForm';
import useQueryParams from '../hooks/useQueryParams';

const { entries, keys, } = Object;

export default function MethodInquiryBasicForm (props) {
  const { statedFields, products = [], questions = [], answers, onChange, } = props;
  const { orderId, productId, productTypeId, createdAt } = useQueryParams();
  const questionsById = keyBy(questions, 'id');
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const productsById = keyBy(products, 'id');
  const validatableStatedFields = pick(statedFields, keys(basicFields()));
  const validationErrorMessages = [
    ...Object.values(validatableStatedFields)
      .filter(_ => !_.isValid)
      .map(({ label, validationErrors }) => {
        return `${label}にエラーがあります`;
      }),
    ...entries(answers).filter(_ => !_[1].isValid).map(([questionId]) => {
      return `「${questionsById[questionId]?.description}」にエラーがあります`;
    }),
  ];
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    statedFields.setStarts(keys(validatableStatedFields));
    if(validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    await props.onSubmit();
  };

  return (
    <Form onSubmit={onSubmit}>
      <SettingText name="methodInquiryPageHeaderHtml" renderContainer={(html, children) => !isEmpty(html) && <div className="border border-info rounded p-3 mb-3">{children}</div>} />
      <div className="mb-5 text-muted">
        お買上の製品の使い方についてわからないことがあれば下記に記入して、お問合せください。
      </div>
      {
        keys(basicFields()).map((fieldName) => {
          const fieldSetting = statedFields[fieldName];
          const options = fieldName === 'productId' ? (() => {
            const { productTypeId: { value: productTypeId } } = statedFields;
            return products
              .filter(_ => (_.productTypeIds || []).includes(productTypeId))
              .filter(_ => !_.isPart)
              .map(_ => ({ label: _.name, value: _.id }));
          })() : fieldSetting.options;

          const isReadOnly = {
            productTypeId: !!productTypeId,
            productId: !!productTypeId && !!productId,
            purchaseYear: orderId || createdAt,
            purchaseMonth: orderId || createdAt,
          }
          return (
            <Fragment>
              <Field
                key={fieldName}
                name={fieldName}
                documentName="methodInquiry"
                {...fieldSetting}
                options={options}
                readOnly={() => isReadOnly[fieldName]}
              />
              {
                fieldName === 'productId' && (
                  <div className="d-flex justify-content-center">
                    <img src={get(productsById, [statedFields.productId.value, 'image'])} style={{ maxHeight: 200 }} />
                  </div>
                )
              }
            </Fragment>
          );
        })
      }
      {questions.map((question) => {
        const { id } = question;
        return (
          <div key={id}>
            <QuestionForm
              answer={answers[id]}
              {...question}
              onChange={onChange.bind(null, id)}
              shouldStarts={hasSubmitted}
            />
          </div>
        );
      })}
      <div className="d-flex mt-5">
        <AppButton size="lg" className="save flex-fill ml-2" type="submit" color="primary" onClick={onSubmit}>
          <span className="fas fa-arrow-right mr-1" />
          次へ
        </AppButton>
      </div>
    </Form>
  );
};
