import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { isEmpty, } from 'lodash';
import { toast } from 'react-toastify';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import firebase from '../../firebase';

const auth = firebase.auth();
const { entries } = Object;
const fields = {
  email: {
    type: 'string',
    label: 'メールアドレス',
    validations: {
      required: v => !isEmpty(v),
    },
  },
};

export default function PasswordResetFormModal(props) {
  const { isOpen, onClickClose } = props;
  const statedFields = useFormState(null, fields, isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await auth.sendPasswordResetEmail(statedFields.email.value)
      onClickClose();
      toast.success('パスワードリセットのメールをお送りしました。');
    } catch(e) {
      console.error(e);
      const message = ({
        'auth/invalid-email': 'メールアドレスの形式が正しくありません',
        'auth/user-not-found': 'アカウントが存在しません',
      })[e.code] || '不正なメールアドレスです';
      toast.error(message);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        パスワードリセット
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            ))
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>送信</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
