import { Fragment } from 'react';
import { keyBy, sum, isEmpty } from 'lodash';
import { Button, Navbar, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import UserPage from '../hocs/UserPage';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import ModalButton from '../ModalButton';
import SettingText from '../SettingText';

const db = firebase.firestore();

export default UserPage(function Mypage(props) {
  const { user } = props;
  return (
    <div className="mypage-profile position-relative">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">マイページ</h5>
      </section>
      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-6">
            <div className="d-flex flex-column gap-2">
              <div className="card p-2">
                <ModalButton color="link" className="p-0 text-left" title="問合せ" content={_ => <InquiryModalContent user={user} />}>
                  問合せ
                </ModalButton>
              </div>
              <div className="card p-2">
                <Link to="/mypage/entries">
                  イベント申込み履歴
                </Link>
              </div>
              <div className="card p-2">
                <Link to="/mypage/orders">
                  注文履歴
                </Link>
              </div>
              <div className="card p-2">
                <Link to="/mypage/contentOrders">
                  コンテンツ注文履歴
                </Link>
              </div>
              <div className="card p-2">
                <Link to="/mypage/troubleInquiries">
                  不具合・組立お問合せ履歴
                </Link>
              </div>
              <div className="card p-2">
                <Link to="/mypage/inquiries">
                  お問合せ履歴
                </Link>
              </div>
              <div className="card p-2">
                <Link to="/mypage/methodInquiries">
                  乗り方・使い方お問合せ履歴
                </Link>
              </div>
              <div className="card p-2">
                <Link to="/mypage/surveyAnswers">
                  アンケート回答履歴
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function InquiryModalContent (props) {
  const { user } = props;
  const tenants = useDocumentsFetch(user.tenantIds?.map(_ => db.collection('tenants').doc(_)), [user.tenantIds]);
  const tenantsById = keyBy(tenants, 'id');

  return (
    <div className="d-flex flex-column gap-3">
      <div>
        購入された製品の問合せは、
        <Link to="/mypage/orders">注文履歴</Link>
        からどうぞ
      </div>
      {
        user.tenantIds?.length > 0 && (
          user.tenantIds.length === 1 ? (
            <div>
              その他のお問合せは、
              <Link to={`/${user.tenantIds[0]}/inquiries/new`}>
                こちら
              </Link>
              からどうぞ
            </div>
          ) : (
            <div>
              <div>その他のお問合せは、各社の問合せからどうぞ</div>
              {
                user.tenantIds.map((tenantId) => {
                  return (
                    <div key={tenantId} className="pl-2">
                      <Link to={`/${tenantId}/inquiries/new`}>
                        {tenantsById[tenantId]?.name}
                      </Link>
                    </div>
                  );
                })
              }
            </div>
          )
        )
      }
    </div>
  );
}
