import React, { useState, useEffect } from 'react';
import { Badge } from 'reactstrap';
import { keyBy, isEmpty } from 'lodash';
import { format as formatDate, startOfDay, endOfDay, addMonths, addDays, } from 'date-fns';

import firebase, { getFirestore, collection, getCountFromServer, orderBy, startAt, endAt, where, query } from '../firebase';
import { roles, prefectures } from '../shared/config';
import ExportButton from './ExportButton';
import QueryText from './QueryText';
import QuerySelector from './QuerySelector';
import QueryBoolean from './QueryBoolean';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useQueryParams from './hooks/useQueryParams';
import TenantLink from './TenantLink';
import QueryDateRangeSelector from './QueryDateRangeSelector';

const db = firebase.firestore();

export default function UserListSection(props) {
  const { user, usersRef, usersRefV9, title, prefixColumns = [] } = props;
  const params = useQueryParams();
  const startOn = params.dateRange?.[0] ? new Date(params.dateRange[0]) : addDays(addMonths(new Date(), -6), 1);
  const endOn = params.dateRange?.[1] ? new Date(params.dateRange[1]) : new Date();
  const { field: [field] = ['email'], text, showsAllUsers: _showsAllUsers = '0', showsAllPeriod: _showsAllPeriod = '0' } = params;
  const showsAllUsers = _showsAllUsers === '1';
  const showsAllPeriod = _showsAllPeriod === '1';
  const usersQuery = showsAllPeriod
    ? usersRef
    : text
    ? usersRef.orderBy(field).startAt(text).endAt(`${text}\uf8ff`)
    : showsAllUsers
    ? usersRef
    : usersRef.where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)).orderBy('createdAt', 'desc');
  const users = useCollectionSubscription(usersQuery, [params]);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウント名', value: 'displayName' },
    { label: '電話番号', value: 'phone' },
  ];

  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    const _query = showsAllPeriod
      ? usersRefV9
      : text
      ? query(usersRefV9, orderBy(field), startAt(text), endAt(`${text}\uf8ff`))
      : showsAllUsers
      ? usersRefV9
      : query(usersRefV9, where('createdAt', '>=', startOfDay(startOn)), where('createdAt', '<=', endOfDay(endOn)), orderBy('createdAt', 'desc'));
    getCountFromServer(_query).then((snapshot) =>
      setTotalCount(snapshot.data().count)
    );
  }, [params]);

  return (
    <div>
      <div className="admin-users container-fluid py-5 position-relative d-flex flex-column gap-3">
        <div className="d-flex justify-content-center">
          <h4>{title}</h4>
        </div>
        <div className="d-flex flex-wrap gap-2 align-items-end">
          <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} disabled={!!text} />
          <QuerySelector
            paramName="field"
            options={fieldOptions}
            label="検索フィールド"
            defaultValue={[field]}
            isClearable={false}
          />
          <QueryText paramName="text" label="検索テキスト" />
          <QueryBoolean paramName="showsAllUsers" label="全アカウント表示" defaultValue={'0'} />
        </div>
        <div className="d-flex gap-4 align-items-end">
          <div className="ml-4" >{totalCount} 件</div>
          <QueryBoolean paramName="showsAllPeriod" label="全期間表示" defaultValue={'0'} />
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-end">
          <ExportButton
            fileName="users.csv"
            rows={users.map((u) => ({
              ...['id', 'email', 'displayName', 'role', 'ngDm', 'note', 'subUserIds', 'mainUserId'].reduce(
                (x, k) => ({ ...x, [k]: u[k] }),
                {}
              ),
              status: u.disabledAt ? '無効' : '',
            }))}
            hasPersonalInfo
            user={user}
          />
        </div>
        <div className="overflow-auto">
          {users.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  {prefixColumns.map(({ name, label }) => {
                    return <th key={name}>{label()}</th>;
                  })}
                  <th style={{ minWidth: 150 }}>アカウント名</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 100 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 100 }}>権限</th>
                  <th style={{ minWidth: 100 }}>DM発送不可</th>
                  <th style={{ minWidth: 150 }}>ユーザータグ</th>
                  <th style={{ minWidth: 200 }}>メモ</th>
                </tr>
              </thead>
              <tbody>
                {(users || []).map((user) => {
                  const {
                    id,
                    email,
                    displayName,
                    prefecture,
                    phone,
                    role = 'user',
                    ngDm = false,
                    note,
                    city,
                    userTagIds = [],
                    mainUserId,
                    subUserIds,
                    disabledAt,
                  } = user;
                  return (
                    <tr key={id} style={{ background: disabledAt ? 'lightgray' : '' }}>
                      {prefixColumns.map(({ name, body }) => {
                        return <td key={name}>{body(user)}</td>;
                      })}
                      <td>
                        <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
                        <div>
                          {!isEmpty(mainUserId) && <div className="badge badge-warning">サブアカウント</div>}
                          {!isEmpty(subUserIds) && (
                            <div className="badge badge-info">サブアカウントあり {subUserIds.length}件</div>
                          )}
                        </div>
                        {disabledAt && <Badge color="danger">無効</Badge>}
                      </td>
                      <td>{email}</td>
                      <td>{prefecture && prefectures[prefecture]}</td>
                      <td>{city}</td>
                      <td>{phone}</td>
                      <td>{roles[role]}</td>
                      <td>{ngDm && '発送不可'}</td>
                      <td>
                        <div className="d-flex flex-wrap gap-1">
                          {(userTagIds || []).map((_) => (
                            <div className="badge badge-secondary">{userTagsById[_]?.name}</div>
                          ))}
                        </div>
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>{note}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
}
