import React, { useEffect } from 'react';
import { Button, Form } from 'reactstrap';
import { addHours, endOfDay, parseISO, format as formatDate, isSameDay, } from 'date-fns';
import qs from 'qs';
import ja from 'date-fns/locale/ja';
import en from 'date-fns/locale/en-us';
import { useList } from 'react-use';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { getDocumentData } from '../../shared/firebase';
import { useHistory } from 'react-router';
import classnames from 'classnames';

import { i18nField } from '../../shared/i18n';
import LectureTypeDisplay from '../LectureTypeDisplay';
import LectureLevelDisplay from '../LectureLevelDisplay';
import LectureAgeDisplay from '../LectureAgeDisplay';
import AppButton from '../AppButton';

const MAX_USER_FRAMES_COUNT = 3;
const MAX_ENTRY_FRAMES_COUNT = 1;
const { min } = Math;
const langs = { ja, en };

export default function EntryForm(props) {
  const { event = {}, restoredFrames, lectures, activeEntryFrames, onClickLogin, showsLoginButton = false, eventProductTypesById, lectureTypesById, user, cancellationNotices, onClickNotifyCancell, userTmpEntries } = props;
  const { key: theDayKey } = qs.parse(window.location.search.slice(1));
  const [frames, { set: setFrames, push: pushFrame, removeAt: removeFrameAt }] = useList([]);
  const history = useHistory();
  const entryFrame = (lecture, lectureFrame, frameIndex) => {
    pushFrame({ lectureRef: lecture.ref, lectureFrame, lectureId: lecture.id, frameIndex });
  };
  const exitFrame = async (frame) => {
    removeFrameAt(frames.indexOf(frame));
    await Promise.all(frames.map(({ lectureId }) => {
      const tmpEntry = userTmpEntries.find(_ => _.isTemporary && _.frames.some((frame) => frame.lectureId === lectureId));
      return tmpEntry?.ref.delete();
    }));    
  };
  const isTheDayEvent = () => {
    return !!theDayKey && !!event.theDayKey && theDayKey === event.theDayKey;
  };
  const now = new Date();
  const leftCount = min(
    MAX_ENTRY_FRAMES_COUNT - frames.length,
    MAX_USER_FRAMES_COUNT - frames.length - activeEntryFrames.length
  );
  const onSubmit = async (e) => {
    e.preventDefault();
    for (const { frameIndex, lectureRef } of frames) {
      const lecture = await getDocumentData(lectureRef);
      const { capacity = 0, priorCapacity = 0, entriedCount = 0 } = lecture.frames[frameIndex];
      if ((isTheDayEvent() && isSameDay(lecture.date.toDate(), new Date) ? capacity : priorCapacity) - entriedCount <= 0) {
        toast.error('お申し込みのイベントはすでに満員になりました。\n恐れ入りますが最初からお申し込みください。');
        history.push(`/events/${event.id}/entries/new`);
        return;
      }
    }
    await props.onSubmit({ frames });
  };
  useEffect(() => {
    if (!isEmpty(restoredFrames)) {
      setFrames(restoredFrames.filter(({ lectureId }) => lectures.some(_ => _.id === lectureId)));
    }
  }, [restoredFrames]);

  return (
    <Form onSubmit={onSubmit}>
      <div>
        {lectures
          .filter((_) => _.abortedAt == null && endOfDay(_.date.toDate()) > now)
          .map((lecture) => {
            const {
              date,
              id: lectureId,
              frames: _lectureFrames = [],
              lectureType,
              lectureLevel,
              lectureAge,
              isUserFree,
            } = lecture;
            const lectureFrames = _lectureFrames
              .filter(
                (_) =>
                  addHours(
                    parseISO(formatDate(date.toDate(), 'yyyy-MM-dd') + formatDate(_.endAt.toDate(), ' HH:mm')),
                    -event.lectureFrameExpireHours || 0
                  ) >= now
              )
              .map((lectureFrame) => {
                const frameIndex = _lectureFrames.indexOf(lectureFrame);
                const { capacity = 0, priorCapacity = 0, entriedCount = 0 } = lectureFrame;
                const displayCapacity = isTheDayEvent() && isSameDay(date.toDate(), new Date()) ? capacity : priorCapacity;
                const leftCapacity = displayCapacity - entriedCount;
                const entriedFrames =
                  frames.filter((_) => _.lectureId === lectureId && _.frameIndex === frameIndex) || {};
                const conclusiveLeftCapacity = leftCapacity - entriedFrames.length;
                return { ...lectureFrame, frameIndex, entriedFrames, conclusiveLeftCapacity };
              });
            const isEntryLecture = frames.find(_ => _.lectureId === lectureId) || activeEntryFrames.find(_ => _.lectureId === lectureId);
            const isNotifyCancell = !!cancellationNotices.filter(_ => _.lectureId === lectureId).length;
            const isNotEntryStarted = lecture.entryStartDate?.toDate() > new Date();
            return (
              <div key={lectureId} className='mb-4'>
                <div className='mb-2'>
                  <div>
                    <LectureTypeDisplay className='large' event={event} lectureType={lectureType} />
                  </div>
                  <div className='mt-3 d-flex'>
                    <LectureLevelDisplay className='flex-fill' event={event} lectureLevel={lectureLevel} />
                    <LectureAgeDisplay className='ml-3 flex-fill' event={event} lectureAge={lectureAge} />
                  </div>
                  <div className='mt-3 d-flex justify-content-between'>
                    <div className="d-flex gap-2 align-items-end">
                      <div className='font-weight-bold'>
                        {formatDate(date.toDate(), 'yyyy/MM/dd (iii)', { locale: langs[event?.lang || 'ja'] })}
                      </div>
                      {
                        isNotEntryStarted && (
                          <div className="text-danger small">
                            ({formatDate(lecture.entryStartDate.toDate(), 'yyyy/MM/dd')}申込開始)
                          </div>
                        )
                      }
                    </div>
                    <div className='text-grey'>
                      {/* TODO: 将来的にはユーザーの場合のみ無料にする */}
                      {isUserFree ? 'ユーザー無料' : `${numeral(lecture.price).format('0,0')} 円`}
                    </div>
                  </div>
                </div>
                {lectureFrames
                  .map((lectureFrame) => {
                    const { startAt, lectureType, frameIndex, entriedFrames, conclusiveLeftCapacity } = lectureFrame;
                    return (
                      <div
                        key={frameIndex}
                        className='border-top py-2 px-3 d-flex justify-content-between align-items-center'
                      >
                        <div className='d-flex align-items-center gap-2'>
                          <div>{formatDate(startAt.toDate(), 'HH:mm')}</div>
                          {<span>{lectureTypesById[lectureType]?.[i18nField(event?.lang, 'name')]}</span>}
                          {entriedFrames.length > 0 && (
                            <div className='ml-2 badge badge-dark'>
                              選択
                              {entriedFrames.length > 1 && <span className='ml-1'>{entriedFrames.length}</span>}
                            </div>
                          )}
                        </div>
                        <div className='d-flex align-items-center'>
                          <div>{conclusiveLeftCapacity <= 0 && <div className='text-grey'>満員</div>}</div>
                          <div
                            className='ml-2 font-weight-bold large cursor-pointer' style={{ opacity: isNotEntryStarted && 0.2 }}
                            onClick={
                              showsLoginButton
                                ? onClickLogin
                                : (leftCount >= 1 && !isNotEntryStarted && entryFrame.bind(null, lecture, lectureFrame, frameIndex))
                            }
                          >
                            {conclusiveLeftCapacity > 0 ? (
                              <div>
                                <span className='far fa-circle text-danger' />
                              </div>
                            ) : (
                              <div>
                                <span className='fas fa-times text-grey' />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {
                    lectureFrames.every(_ => _.conclusiveLeftCapacity <= 0) && !isEntryLecture && user != null && <div className='d-flex justify-content-end'>
                      <Button color={isNotifyCancell ? "secondary" : "warning"} size="sm" onClick={() => onClickNotifyCancell(event, lectureId, user)}>
                        {
                          isNotifyCancell ? "通知を受け取らない" : "空きが出た場合通知を受け取る"
                        }
                      </Button>
                    </div>
                  }
              </div>
            );
          })}
      </div>
      <div className='mt-5 position-sticky' style={{ bottom: 10 }}>
        <div className='row'>
          <div className='col-lg-8 offset-lg-2'>
            {showsLoginButton ? (
              <Button color='primary' className='w-100' onClick={onClickLogin}>
                ログインまたは
                <br />
                新規会員登録（無料）してください
              </Button>
            ) : (
              <div className='card p-3 d-flex'>
                {frames.length === 0 ? (
                  activeEntryFrames.length < MAX_USER_FRAMES_COUNT ? (
                    <div className='text-center'>
                      <div>
                        <span className='large mr-2'>
                          <span className='far fa-circle text-danger' />
                        </span>
                        をタップして選択してください
                      </div>
                      <div className='text-grey small'>
                        <div>
                          同時に「
                          {min(
                            MAX_ENTRY_FRAMES_COUNT - frames.length,
                            MAX_USER_FRAMES_COUNT - activeEntryFrames.length
                          )}
                          」枠まで申込みできます
                        </div>
                        <div className='mt-2 small'>
                          ※ 過去にお申込みされたイベントで開催日が終了していないものも含めて合計3枠まで申込みできます。
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <div className='text-danger'>すでに「{MAX_USER_FRAMES_COUNT}」枠申込みされています。</div>
                      <div className='mt-2 small text-grey'>
                        ※ 過去にお申込みされたイベントで開催日が終了していないものを含む
                      </div>
                    </div>
                  )
                ) : (
                  <div className='d-flex justify-content-between align-items-start'>
                    <div className='flex-fill'>
                      {frames.map((frame) => {
                        const {
                          lectureFrame: { startAt, endAt },
                        } = frame;

                        return (
                          <div className='d-flex align-items-center'>
                            <div>
                              {formatDate(startAt.toDate(), 'HH:mm')}
                            </div>
                            <div
                              className='ml-2 small text-danger cursor-pointer font-weight-bold'
                              onClick={exitFrame.bind(null, frame)}
                            >
                              取消
                            </div>
                          </div>
                        );
                      })}
                      {leftCount > 0 && <div className='text-grey small'>あと「{leftCount}」枠申込みできます</div>}
                      {frames.length > 0 && (
                        <div className='mt-2 text-dark-grey small'>
                          ※ 複数人希望の場合、申込完了後に再度お申込み下さい。
                        </div>
                      )}
                    </div>
                    <AppButton size='lg' className='save text-nowrap' type='submit' color='primary' onClick={onSubmit}>
                      <span className='fas fa-arrow-right mr-1' />
                      次へ
                    </AppButton>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Form>
  );
}
