import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { InquiryTypeChildDocumentData, InquiryTypeChild } from '../types';
import type { PartiallyPartial } from 'types';

export const inquiryTypeChildConverter = getConverter<InquiryTypeChildDocumentData>();

export const inquiryTypeChildrenRef = () =>
  collection(getFirestore(), 'inquiryTypeChildren').withConverter(inquiryTypeChildConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<InquiryTypeChild> : null;
export const inquiryTypeChildRef = <Id extends string | undefined>(inquiryTypeChildId: Id) =>
  (inquiryTypeChildId ? doc(inquiryTypeChildrenRef(), inquiryTypeChildId) : null) as RefOrNull<Id>;

export const newInquiryTypeChildRef = () => doc(inquiryTypeChildrenRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const inquiryTypeChildrenQuery = (...queryConstraints: QueryConstraint[]) =>
  query(inquiryTypeChildrenRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantInquiryTypeChildrenQuery = (tenantId: string | undefined) =>
  tenantId ? inquiryTypeChildrenQuery(...tenantQueryConstraints(tenantId)) : null;

export const addInquiryTypeChild = async (data: PartiallyPartial<InquiryTypeChild, 'id' | 'createdAt'>) =>
  addDoc(inquiryTypeChildrenRef(), { createdAt: serverTimestamp(), ...data });

export const updateInquiryTypeChild = async (inquiryTypeChildId: string, data: UpdateData<InquiryTypeChild>) =>
  updateDoc(inquiryTypeChildRef(inquiryTypeChildId), data);

export const deleteInquiryTypeChild = async (inquiryTypeChildId: string) =>
  deleteDoc(inquiryTypeChildRef(inquiryTypeChildId));

export const useInquiryTypeChildCollection = useCollectionData;

export const useInquiryTypeChildCollectionOnce = useCollectionDataOnce;

export const useInquiryTypeChildDocument = useDocumentData;

export const useInquiryTypeChildDocumentOnce = useDocumentDataOnce;
