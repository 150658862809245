import React from 'react';
import { format as formatDate } from 'date-fns';
import ja from 'date-fns/locale/ja';
import en from 'date-fns/locale/en-us';

import { i18nField } from '../shared/i18n';
import PriceDisplay from './PriceDisplay';

import { lectureLevels, lectureAges, } from '../shared/i18n';

const langs = { ja, en };

export default function LectureTitle({ event, productType, lectureType, lectureLevel, lectureAge, date, entryStartDate, price, isUserFree = false, eventProductTypesById, lectureTypesById, }) {
  return (
    <span>
      {date && formatDate(date.toDate(), 'yyyy/MM/dd (iii)', { locale: langs[event?.lang || 'ja'], })} {eventProductTypesById[productType]?.[i18nField(event?.lang, 'name')]} {lectureTypesById[lectureType]?.[i18nField(event?.lang, 'name')]}{' '}
      {lectureLevel === 'none' ? '' : lectureLevels[event?.lang || 'ja'][lectureLevel]} {lectureAge === '0-999' ? '' : lectureAges[lectureAge]}{' '}
      <PriceDisplay event={event} price={price} isUserFree={isUserFree} />
    </span>
  );
}
