import React, { Fragment, useState, } from 'react';
import { format as formatDate, addHours, } from 'date-fns';
import { mapValues, isEmpty, get, omit, orderBy, keyBy } from 'lodash';
import { Button, Form, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import dedent from 'dedent';
import { useToggle } from 'react-use';
import { Link } from 'react-router-dom';
import { useZxing } from 'react-zxing';

import firebase, { functions } from '../../firebase';
import { fields as cancelRequestFields, reasons } from '../../shared/models/cancelRequest';
import { batch, getCollectionData, } from '../../shared/firebase';
import { fields, contactorFields } from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useFormState from '../hooks/useFormState';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import UserPage from '../hocs/UserPage';
import AddInTenantButton from '../AddInTenantButton';
import AppCard from '../AppCard';
import AppButton from '../AppButton';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import TenantLink from '../TenantLink';
import Field from '../Field';
import QrReader from '../QrReader';
import TenantContext from '../contexts/tenant';

const { entries } = Object;
const db = firebase.firestore();
const startRentalOrder = functions.httpsCallable('startRentalOrder');

export default UserPage(function MypageRentalOrder (props) {
  const { match: { params: { tenantId, agentId, rentalOrderId, } } } = props;
  const [rentalItemEntity, setRentalItemEntity] = useState(null);
  const tenant = useDocumentSubscription(db.collection('tenants').doc(tenantId), [tenantId]);
  const agent = useDocumentSubscription(db.collection('agents').doc(agentId), [agentId]);
  const rentalOrder = useDocumentSubscription(agent?.ref.collection('rentalOrders').doc(rentalOrderId), [agent, rentalOrderId]);
  const rentalPrice = useDocumentSubscription(rentalOrder != null && agent?.ref.collection('rentalPrices').doc(rentalOrder.rentalPriceId), [agent, rentalOrder]);

  return rentalOrder != null && rentalPrice != null && (
    <div className="mypage-rental-order container py-5 position-relative">
      {
        rentalOrder.startedAt == null ? (
          <div className="d-flex justify-content-center">
            <AppButton
              title="レンタル開始"
              color="primary"
              Component={ModalButton}
              content={({ toggleModal }) => {
                const onRead = async (result) => {
                  const [rentalItemEntity] = await getCollectionData(db.collection('agents').doc(agentId).collection('rentalItemEntities').where('code', '==', result).limit(1));
                  if(rentalItemEntity != null) {
                    if(rentalOrder.rentalItemId === rentalItemEntity.rentalItemId) {
                      setRentalItemEntity(rentalItemEntity);
                    } else {
                      toast.error(`読み込みましたが、商品が正しくありません。(${result})`);
                    }
                  } else {
                    toast.error(`読み込みましたが、見つかりません。(${result})`);
                  }
                };
                const onClickStart = async () => {
                  if(!window.confirm('レンタルを開始します。よろしいですか？')) return;

                  await startRentalOrder({
                    agentId: agent.id,
                    rentalOrderId: rentalOrder.id, 
                    hours: rentalPrice.hours,
                    rentalItemEntityId: rentalItemEntity.id,
                    rentalItemEntityCode: rentalItemEntity.code,
                  });
                };

                return (
                  <div>
                    {
                      rentalItemEntity == null ? (
                        <div className="d-flex justify-content-center">
                          <QrReader onRead={onRead} />
                        </div>
                      ) : (
                        <div>
                          <div className="alert alert-success mb-3">
                            レンタル商品を読み込みました。
                            <br />
                            レンタルを開始してください。
                          </div>
                          <div className="d-flex justify-content-center">
                            <ProgressButton process={onClickStart} color="primary">
                              レンタルを開始する
                            </ProgressButton>
                          </div>
                        </div>
                      )
                    }
                  </div>
                );
              }}
            >
              レンタル商品のQRを読み込む
            </AppButton>
          </div>
        ) : (
          <div>
            <div className="alert alert-info">
              {
                rentalOrder.status === 'renting' ? (
                  <div>現在レンタル中です。</div>
                ) : rentalOrder.status === 'returned' ? (
                  <div>レンタル終了しました。</div>
                ) : <div />
              }
              <div className="mt-2">
                <div>
                  開始: {formatDate(rentalOrder.startedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </div>
                <div>
                  終了: {rentalOrder.endedAt != null && formatDate(rentalOrder.endedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
});
