import React, { useEffect, useRef } from 'react';
import qrcode from 'qrcode';
import { Input, Button } from 'reactstrap';
import qs from 'qs';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { downloadCanvasImage } from '../../util';

import PublicPage from '../hocs/PublicPage';

export default PublicPage(function ReferrerQrCode (props) {
  const { location, } = props;
  const { key } = qs.parse(location.search.slice(1));
  const canvasEl = useRef(null);
  const url = `${window.location.origin}/products?referrerKey=${key}`;
  const onClickCopy = () => {
    copy(url);
    toast.success('クリップボードにコピーしました');
  }

  useEffect(() => {
    if(canvasEl.current) {
      qrcode.toCanvas(canvasEl.current, url, { width: 350, height: 350 });
    }
  }, [canvasEl, url]);

  const handleQrImageDownload = () => {
    downloadCanvasImage(canvasEl.current, "qrcode.jpg");
  }

  return (
    <div className="referrer-qr-code py-5 container">
      <div className="d-flex justify-content-center" title={url}>
        <canvas ref={canvasEl} />
      </div>
      <div className="d-flex w-100 justify-content-end mb-1">
          <Button color="secondary" onClick={handleQrImageDownload}><span className="fas fa-download mr-1" />画像ダウンロード</Button>
        </div>
      <div className="mt-4">
        <label className="small text-muted mb-1">QRコードのリンク先</label>
        <div className="d-flex">
          <Input className="flex-grow-1 mr-2" readOnly defaultValue={url} />
          <Button color="primary" onClick={onClickCopy}>
            <span className="fas fa-copy" />
          </Button>
        </div>
      </div>
    </div>
  );
});
