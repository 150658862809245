import React from 'react';
import { keyBy } from 'lodash';

import firebase from '../../firebase';
import UserPage from '../hocs/UserPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import SurveyAnswerItem from '../SurveyAnswerItem';

const db = firebase.firestore();

export default UserPage(function MypageSurveyAnswers(props) {
  const { user } = props;
  const surveys = useCollectionSubscription(db.collection('surveys').orderBy('createdAt', 'desc'), []);
  const surveysById = keyBy(surveys, 'id');
  const surveyAnswers = useCollectionSubscription(
    db.collection('surveyAnswers').where('createdBy.uid', '==', user.id).orderBy('createdAt', 'desc'),
    []
  );
  const questions = useCollectionSubscription(db.collection('questions').orderBy('createdAt'), []);
  const rows = surveyAnswers
    .filter(({ surveyId }) => surveysById[surveyId])
    .map((surveyAnswer) => {
      const survey = surveysById[surveyAnswer.surveyId];
      return {
        survey,
        ...surveyAnswer,
      };
    });

  return (
    <div className="mypage-survey-answers">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">アンケート回答履歴</h5>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="mt-4">
              {rows.map((surveyAnswer) => {
                return (
                  <SurveyAnswerItem
                    key={surveyAnswer.id}
                    surveyAnswer={surveyAnswer}
                    user={user}
                    questions={questions}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});
