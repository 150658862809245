import React, { useEffect } from 'react';
import { format as formatDate, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';
import { keyBy, isEmpty, sortBy, orderBy } from 'lodash';
import { Button } from 'reactstrap';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import AdminEventHeader from '../AdminEventHeader';
import { fullPathWithParams } from '../../util';
import TenantLink from '../TenantLink';
import QuerySelector from '../QuerySelector';
import useTenant from '../hooks/useTenant';
import QueryDateSelector from '../QueryDateSelector';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default AdminPage(function AdminEvent(props) {
  const {
    user,
    match: {
      params: { eventId },
    },
    addBreadNavValues,
    location,
    history,
  } = props;
  const now = new Date();
  const queryParams = useQueryParams();
  const {
    startOn: startOnString = formatDate(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(endOfMonth(new Date()), 'yyyy-MM-dd'),
  } = queryParams;
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const tenant = useTenant();
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const lectures = useCollectionFetch(eventsRef.doc(eventId).collection('lectures'), [eventId]);
  const cancellationNotices = useCollectionSubscriptionInTenant(
    eventsRef.doc(eventId).collection('cancellationNotices'),
    [eventId]
  );
  const lecturesById = keyBy(lectures, 'id');
  const lectureIdOptions = orderBy(
    lectures.map((_) => ({ label: formatDate(_.date.toDate(), 'yyyy/MM/dd'), value: _.id })),
    'label'
  );
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), (_) =>
    _.createdAt.toDate()
  );
  const lectureTypesById = keyBy(lectureTypes, 'id');

  let filteredCancellationNotices = cancellationNotices.filter(
    ({ createdAt }) => createdAt.toDate() >= startOfDay(startOn) && createdAt.toDate() <= endOfDay(endOn)
  );

  if (!isEmpty(queryParams.lectureIds) && !isEmpty(lecturesById)) {
    filteredCancellationNotices = filteredCancellationNotices.filter(({ lectureId }) =>
      queryParams.lectureIds.includes(lectureId)
    );
  }

  useEffect(() => {
    addBreadNavValues({ event });
  }, [event]);

  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd') },
      location
    );
    history.replace(encodeURI(path));
  };

  return (
    event != null && (
      <div>
        <div className="admin-event-cancellation-notices container-fluid py-5 position-relative">
          <AdminEventHeader activeTab="cancellationNotices" event={event} user={user} />
          <div className="bg-white p-4">
            <div className="row">
              <div className="col-12">
                <h5 className="text-center">空き待ち一覧</h5>
                <div className="d-flex align-items-end flex-wrap gap-2">
                  <Button onClick={() => onClickDateButton([addDays(now, -1), addDays(now, -1)])}>昨日</Button>
                  <Button onClick={() => onClickDateButton([now, now])}>今日</Button>
                  <Button onClick={() => onClickDateButton([startOfMonth(now), endOfMonth(now)])}>今月</Button>
                  <Button
                    onClick={() =>
                      onClickDateButton([startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])
                    }
                  >
                    先月
                  </Button>
                  <QueryDateSelector
                    paramName="startOn"
                    label="開始日"
                    history={history}
                    location={location}
                    defaultValue={startOfMonth(new Date())}
                    style={{ width: 150 }}
                  />
                  <QueryDateSelector
                    paramName="endOn"
                    label="終了日"
                    history={history}
                    location={location}
                    defaultValue={endOfMonth(new Date())}
                    invalid={startOn > endOn}
                    style={{ width: 150 }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-end mb-3">
                  <div className="d-flex align-items-end gap-1">
                    <QuerySelector
                      paramName="lectureIds"
                      width={250}
                      isMulti
                      options={lectureIdOptions}
                      label="レクチャーで絞込み"
                    />
                  </div>
                </div>
                {cancellationNotices.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th style={{ minWidth: 100 }}>レクチャー</th>
                        <th style={{ minWidth: 100 }}>レクチャー日</th>
                        <th style={{ minWidth: 100 }}>申込アカウント</th>
                        <th style={{ minWidth: 100 }}>メールアドレス</th>
                        <th style={{ minWidth: 100 }}>登録時間</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderBy(filteredCancellationNotices, ['lecture.date'], ['desc']).map((cancellationNotice, i) => {
                        const { createdBy, lectureId, createdAt } = cancellationNotice;
                        const lecture = lecturesById[lectureId];
                        return (
                          <tr key={i}>
                            <td>{lectureTypesById[lecture?.lectureType]?.name}</td>
                            <td>{lecture?.date ? formatDate(lecture.date.toDate(), 'yyyy/MM/dd') : ''}</td>
                            <td>
                              <TenantLink to={`/admin/users/${createdBy.uid}`}>{createdBy.displayName}</TenantLink>
                            </td>
                            <td>{createdBy.email}</td>
                            <td>{createdAt.toDate().toLocaleString()}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>空き待ちはまだいません</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
