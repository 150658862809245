import React, { useEffect, } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { useToggle } from 'react-use';
import qs from 'qs';
import { parseISO, format as formatDate, addHours, isSameDay, } from 'date-fns';
import sanitizeHtml from 'sanitize-html';
import { sortBy, keyBy, } from 'lodash';

import { activateRichTextHtml } from '../../util';
import { i18nField } from '../../shared/i18n';
import firebase from '../../firebase';
import useAppTitle from '../hooks/useAppTitle';
import useLocale from '../hooks/useLocale';
import PublicPage from '../hocs/PublicPage';
import LectureTitle from '../LectureTitle';
import AppButton from '../AppButton';
import AppCard from '../AppCard';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const { max } = Math;

export default PublicPage(function Event(props) {
  const {
    user,
    tenant,
    location,
    match: {
      params: { eventId },
    },
    setLang,
    translate,
  } = props;
  const { key: theDayKey } = qs.parse(window.location.search.slice(1));
  const event = useDocumentSubscription(eventsRef.doc(eventId), [eventId]);
  const isTheDayEvent = !!theDayKey && !!(event && event.theDayKey) && theDayKey === event.theDayKey;
  const lectures = useCollectionSubscription(eventsRef.doc(eventId).collection('lectures').orderBy('date'), [eventId]);
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const now = new Date();
  const lecturesWithInfo = lectures.map((lecture) => {
    const { date, frames: lectureFrames = [] } = lecture;
    const unendedLectureFrames = lectureFrames.filter(
      (_) =>
        addHours(
          parseISO(formatDate(date.toDate(), 'yyyy-MM-dd') + formatDate(_.endAt.toDate(), ' HH:mm')),
          -event?.lectureFrameExpireHours || 0
        ) >= now
    );
    const availableLectureFrames = unendedLectureFrames
      .map((lectureFrame, frameIndex) => {
        const { capacity = 0, priorCapacity = 0, entriedCount = 0 } = lectureFrame;
        const displayCapacity = isTheDayEvent && isSameDay(date.toDate(), new Date()) ? capacity : priorCapacity;
        const leftCapacity = displayCapacity - entriedCount;
        return {
          ...lectureFrame,
          displayCapacity,
          leftCapacity,
        };
      })
      .filter((_) => _.leftCapacity > 0);
    return {
      ...lecture,
      unendedLectureFrames,
      availableLectureFrames,
    };
  });
  const invitedUsers = useCollectionSubscription(
    user && eventsRef.doc(eventId).collection('invitedUsers').where('uid', '==', user.uid).limit(1),
    [eventId, user]
  );
  const isInvited = invitedUsers.length === 1;
  const isAllFull = lecturesWithInfo.every((_) => _.availableLectureFrames.length === 0);
  useAppTitle(event?.name + ' 申込トップ');
  useEffect(() => {
    event?.lang && setLang(event.lang);
  }, [event?.lang]);

  return (
    event != null && translate(
      <div>
        <div className="event container pt-5 position-relative">
          <div className="text-center mb-3">
            <h4>{event.name}</h4>
            {user != null && (['admin', 'staff'].includes(user.role) || isInvited) && (
              <div>
                <TenantLink
                  className="text-muted"
                  to={`/events/${eventId}/detail`}
                  style={{ textDecoration: 'underline' }}
                >
                  イベント詳細を見る
                </TenantLink>
              </div>
            )}
          </div>
          <div className="row mt-3">
            <div className="col-lg-8 offset-lg-2">
              {isAllFull && <div className="alert alert-warning mt-2">このイベントは満員です</div>}
              {event.description && (
                <div className="rounded border border-info p-3">
                  <RichTextContent html={event.description || ''} />
                </div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-8 offset-lg-2">
              {lecturesWithInfo.map((lecture) => {
                const { id } = lecture;
                return <LectureCard key={id} lecture={lecture} user={user} {...{ event, eventProductTypesById, lectureTypesById, }} {...props} />;
              })}
            </div>
          </div>
          <div className="mt-5 position-sticky" style={{ bottom: 10 }}>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <AppButton
                  block
                  color="primary"
                  size="lg"
                  tag={TenantLink}
                  to={`/events/${eventId}/entries/new${location.search}`}
                >
                  このイベントに申し込む
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

function LectureCard(props) {
  const { event, lecture, user, eventProductTypesById, lectureTypesById, location, match: { params: { eventId }}} = props;
  const { abortedAt, unendedLectureFrames, availableLectureFrames } = lecture;
  const [showsAvailability, toggleAvailability] = useToggle();
  if (unendedLectureFrames.length === 0) return null;

  return (
    <AppCard className="p-3 p-md-4 mb-3 d-block">
      <div>
        <LectureTitle {...lecture} {...{ event, eventProductTypesById, lectureTypesById, }} />
      </div>
      {
        lecture.entryStartDate?.toDate() > new Date() && (
          <div className="text-danger">
            ※ {formatDate(lecture.entryStartDate.toDate(), 'yyyy/MM/dd')}申込開始
          </div>
        )
      }
      {abortedAt != null ? (
        <div className="text-danger">中止しました</div>
      ) : availableLectureFrames.length > 0 ? (
        <Button color="link" onClick={toggleAvailability}>
          <span className={classnames('fas mr-1', { 'fa-plus': !showsAvailability, 'fa-minus': showsAvailability })} />
          {showsAvailability ? '空き状況を閉じる' : '空き状況を見る'}
        </Button>
      ) : (
        <div className="text-danger d-flex justify-content-between align-items-center mt-1">
          満員
          <Button color="warning" size="sm" tag={TenantLink} to={`/events/${eventId}/entries/new${location.search}`}>空き枠通知希望</Button>
        </div>
      )}
      {showsAvailability && (
        <div>
          {availableLectureFrames.map((lectureFrame, frameIndex) => {
            const { startAt, endAt, leftCapacity, lectureType } = lectureFrame;
            const leftCapacityDisplay = ['admin', 'staff'].includes(user?.role)
              ? max(leftCapacity, 0)
              : leftCapacity > 0
              ? '○'
              : '満席';
            return (
              <div key={frameIndex} className="d-flex flex-wrap gap-2">
                <span>{formatDate(startAt.toDate(), 'HH:mm')}</span>
                {<span style={{ minWidth: 220 }}>{lectureTypesById[lectureType]?.[i18nField(event?.lang, 'name')]}</span>}
                <span>{leftCapacityDisplay}</span>
              </div>
            );
          })}
        </div>
      )}
    </AppCard>
  );
}
