import React from 'react';

import PublicPage from '../hocs/PublicPage';
import OrderFlowSection from '../OrderFlowSection';
import PleaseConfirmEmail from '../PleaseConfirmEmail';
import useQueryParams from '../hooks/useQueryParams';

export default PublicPage(function OrderThanks(props) {

  return (
    <div className="order-thanks h-100">
      <OrderFlowSection activeIndex={3} />
      <section className="container mt-5">
        <div className="text-center" style={{ fontSize: 25 }}>
          ご注文ありがとうございました。
        </div>
        <div className="row">
          <div className="col-lg-8 offset-md-2 col-md-10 offset-md-1 p-3 my-4 border rounded border-info">
            <PleaseConfirmEmail />
          </div>
        </div>
      </section>
    </div>
  );
});
