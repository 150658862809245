import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js'
import sanitizeHtml from 'sanitize-html';

import './bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toggle/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Root from './components/Root';
import routes from './routes';
import './bugsnag';
import './firebase';
import './index.css';
import './hover.scss';
import './app-rte.scss';
import './delivery-slip.scss';
import './css/entry-form.css';
import './css/stepflow.css';
import './css/new-order-form.css';
import './css/tooltip.css';
import './stripe.css';

sanitizeHtml.defaults.allowedAttributes['*']= ['style'];
const ErrorBoundary = ['production', 'staging', 'multistaging'].includes(process.env.REACT_APP_ENV) ? Bugsnag.getPlugin('react') : Fragment;

ReactDOM.render(
  <ErrorBoundary>
    <Root routes={routes} />
  </ErrorBoundary>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
