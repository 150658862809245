import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { sortBy, isEmpty, get, keyBy, pickBy, orderBy } from 'lodash';
import { useMap, useTimeout, } from 'react-use';
import qs from 'qs';
import { addMinutes, differenceInMilliseconds, } from 'date-fns';

import firebase from '../../firebase';
import PublicPage from '../hocs/PublicPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import PartsSelectorForm from '../forms/PartsSelectorForm';
import Field from '../Field';
import CartInformation from '../CartInformation';

const { entries } = Object;
const db = firebase.firestore();
const productsRef = db.collection('products');
const productTagsRef = db.collection('productTags');
const productTypesRef = db.collection('productTypes');
const agentsRef = db.collection('agents');

const Parts = (props) => {
  const { user } = props;
  const history = useHistory();
  const {
    params: { tenantPath },
  } = useRouteMatch();
  const queryParams = useQueryParams();
  const { n, productId, productTypeId, orderItems, wholesale, agentId, agentShopId } = queryParams;
  const startAt = new Date(parseInt(queryParams.n, 10));
  const isWholesale = wholesale === '1';
  const [isWholesaleTimeout] = useTimeout(differenceInMilliseconds(addMinutes(startAt, 45), new Date()));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productTags = sortBy(useCollectionSubscriptionInTenant(productTagsRef), (_) => _.createdAt.toDate());
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const wholesaleAgent = useDocumentSubscription(isWholesale && agentsRef.doc(agentId), [isWholesale, agentId]);
  const productsById = keyBy(products, 'id');
  const partProducts = products
    .filter((_) => !_.isHidden)
    .filter((_) => _.isPart || _.isOption)
    .filter((_) => _.code !== 'HP99');
  const sortedPartProducts = orderBy(partProducts, [({ isOption }) => (isOption ? 1 : 0)], ['desc']);
  const [selectedItems, { set: setSelectedItems, remove: removeSelectedItems }] = useMap(
    orderItems?.reduce((acc, cur) => ({ ...acc, [cur.productId]: cur.quantity }), {}) || {}
  );
  const [selectedProductTypeId, setSelectedProductTypeId] = useState(productTypeId);
  const [selectedProductId, setSelectedProductId] = useState(productId);
  const bodySelectFields = {
    productTypeId: {
      label: '商品種別',
      type: 'select',
      options: productTypes.map((_) => ({ label: _.name, value: _.id })),
      value: selectedProductTypeId,
      setValue: setSelectedProductTypeId,
    },
    productId: {
      label: '商品',
      type: 'select',
      options: products
        .filter((_) => (_.productTypeIds || []).includes(selectedProductTypeId || ''))
        .filter((_) => !_.isPart)
        .map((_) => ({ label: _.name, value: _.id })),
      value: selectedProductId,
      setValue: setSelectedProductId,
    },
  };
  const showsWholesalePrice = !!wholesaleAgent?.members[user?.id];
  const handlePartsSelectorFormSubmit = () => {
    const queryParams = pickBy(
      { productId, productTypeId, orderItems, wholesale, agentId, agentShopId },
      (_) => !isEmpty(_)
    );
    const newOrderPath = `/${tenantPath}/orders/new?${qs.stringify(queryParams)}`;
    history.push(newOrderPath);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const productId = selectedProductId;
    const productTypeId = selectedProductTypeId;
    const orderItems = entries(selectedItems).map(([productId, quantity]) => ({
      productId,
      quantity,
    }));
    const queryParams = pickBy(
      { n, productId, productTypeId, orderItems, wholesale, agentId, agentShopId },
      (_) => !isEmpty(_)
    );
    const newPartsPath = `${history.location.pathname}?${qs.stringify(queryParams)}`;
    history.replace(newPartsPath);
  }, [selectedItems, selectedProductTypeId, selectedProductId]);

  return (
    <div className="products container pt-5 position-relative">
      {
        isWholesale && isWholesaleTimeout() && queryParams.n ? (
          <div className="d-flex justify-content-center">
            <div className="mt-4 alert alert-danger">
              時間切れになりました。<br />
            </div>
          </div>
        ) : (
          <div className="row mb-2">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div className="mb-5">
                <CartInformation />
              </div>
              <div className="mb-5">
                <h5 className="mb-4">お手持ちのへんしんバイクを選択してください</h5>
                {entries(bodySelectFields).map(([fieldName, fieldSetting]) => {
                  return (
                    <>
                      <Field key={fieldName} name={fieldName} documentName="parts" {...fieldSetting} />
                      {fieldName === 'productId' && (
                        <div className="d-flex justify-content-center">
                          <img src={get(productsById, [selectedProductId, 'image'])} style={{ maxHeight: 200 }} />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <PartsSelectorForm
                products={sortedPartProducts}
                productTags={productTags}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                removeSelectedItems={removeSelectedItems}
                showsPrice
                isWholesale={isWholesale}
                showsWholesalePrice={showsWholesalePrice}
                onSubmit={handlePartsSelectorFormSubmit}
                noInventoryLabel="在庫なし"
                {...(bodySelectFields.productId.value ? { statedFields: bodySelectFields } : {})}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};

export default PublicPage(Parts);
