import React from 'react';
import { Input, Button, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import TenantLink from './TenantLink';
import useTenant from './hooks/useTenant';

const tabs = [
  { name: 'general', label: '詳細', path: '/general' },
  { name: 'referrers', label: 'リファラ', path: '/referrers', shows: _ => _.agent.usesReferral, },
  { name: 'rentalPrices', label: 'レンタル料金', path: '/rentalPrices', shows: _ => _.agent.usesRental, },
  { name: 'rentalItemEntities', label: 'レンタル商品在庫', path: '/rentalItemEntities', shows: _ => _.agent.usesRental, },
  { name: 'rentalCalendar', label: 'レンタル状況', path: '/rentalCalendar', shows: _ => _.agent.usesRental, },
];

export default function AgentHeader (props) {
  const { activeTab, agent, agentShop, } = props;
  const tenant = useTenant();

  return (
    <div>
      <Nav tabs>
        {
          tabs.filter(_ => _.shows == null || _.shows({ agent, agentShop, })).map(({ name, label, path }) => {
            return (
              <NavItem key={name}>
                <NavLink
                  tag={TenantLink}
                  className={classnames({ active: activeTab === name })}
                  to={`/agents/${agent.id}/agentShops/${agentShop.id}${path}`}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })
        }
      </Nav>
    </div>
  );
};
