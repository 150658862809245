import React, { Fragment } from 'react';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { omitBy, isUndefined, pick } from 'lodash';

import { canUpdateEvent } from '../shared/abilities';
import firebase from '../firebase';
import useFirebaseUser from './hooks/useFirebaseUser';
import useTenant from './hooks/useTenant';
import TenantLink from './TenantLink';
import ModalButton from './ModalButton';
import EventQrcode from './EventQrcode';
import QrCode from './QrCode';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const tabs = [
  { name: 'general', label: '一般', path: '' },
  { name: 'entries', label: '申込み一覧', path: '/entries' },
  { name: 'entryFrames', label: '参加者一覧', path: '/entryFrames' },
  { name: 'cancellationNotices', label: '空き待ち一覧', path: '/cancellationNotices' }
];

export default function AdminEventHeader(props) {
  const { firebaseUser } = useFirebaseUser();
  const { activeTab, event, user } = props;
  const { isPublic = false } = event || {};
  const toggleIsPublic = async () => {
    if (!event) return;
    const { isPublic = false } = event;
    if (!window.confirm(`本当に${isPublic ? '非公開' : '公開'}にしますか？`)) return;
    try {
      await eventsRef.doc(event.id).update({
        isPublic: !isPublic,
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      });
      toast.success(`${isPublic ? '非公開' : '公開'}にしました`);
    } catch (e) {
      toast.error('失敗しました');
    }
  };

  return (
    <div>
      <div className='d-flex justify-content-center mb-3'>
        <h4>
          {event.name}
          {isPublic && <span className='fas fa-globe-asia text-muted' />}
        </h4>
      </div>
      <div className='d-flex justify-content-end mb-1 gap-1'>
        {event.isEnabledCheckIn && (
          <Fragment>
            <Button color='secondary' tag={TenantLink} to={`/events/${event.id}/checkIn`} target='_blank'>
              <span className='fas fa-external-link-alt mr-1' />
              チェックイン画面を開く
            </Button>
            <ModalButton title="チェックインQR" color='secondary' content={_ => <CheckInQrModalContent {...{ event }} />}>
              チェックインQR
            </ModalButton>
            <ModalButton title="事後アンケート回答QR" color='secondary' content={_ => <LaterSurveyQrModalContent {...{ event }} />}>
              事後アンケート回答QR
            </ModalButton>
          </Fragment>
        )}
        <Button color='secondary' tag={TenantLink} to={`/events/${event.id}/qrcode`} target='_blank'>
          <span className='fas fa-external-link-alt mr-1' />
          当日申込みQRコードを表示する
        </Button>
      </div>
      <div className='d-flex justify-content-end mb-1 gap-1'>
        <ModalButton title="公開ページQR" color='secondary' content={_ => <div className="d-flex justify-content-center"><EventQrcode forTheDay={false} event={event} /></div>}>
          公開ページQR
        </ModalButton>
        <Button color='secondary' tag={TenantLink} to={`/events/${event.id}`} target='_blank'>
          <span className='fas fa-external-link-alt mr-1' />
          公開ページを見る
        </Button>
        <Button color='warning' onClick={toggleIsPublic} disabled={!canUpdateEvent(user, event)}>
          {isPublic ? '非公開にする' : '公開する'}
        </Button>
      </div>
      <Nav tabs>
        {tabs.map(({ name, label, path }) => {
          return (
            <NavItem key={name}>
              <NavLink
                tag={TenantLink}
                className={classnames({ active: activeTab === name })}
                to={`/admin/events/${event.id}${path}`}
              >
                {label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
}

function CheckInQrModalContent(props) {
  const { event } = props;
  const tenant = useTenant();
  const url = `${window.origin}/${tenant.id}/events/${event.id}/checkInDirect`;
  return (
    <div className="d-flex justify-content-center" title={url}>
      <QrCode url={url} topText="チェックイン" centerText={event.textForQr} />
    </div>
  );
}

function LaterSurveyQrModalContent(props) {
  const { event } = props;
  const tenant = useTenant();
  const url = `${window.origin}/${tenant.id}/events/${event.id}/surveyWithCheckInCode`;
  return (
    <div className="d-flex justify-content-center" title={url}>
      <QrCode url={url} topText="事後アンケート回答" centerText={event.textForQr} />
    </div>
  );
}
