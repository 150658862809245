import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmpty, keyBy, pick } from 'lodash';
import { useAsync, } from 'react-use';
import retry from 'async-retry';
import dedent from 'dedent';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

import TenantUserPage from '../hocs/TenantUserPage';
import { batch, getDocumentData, getCollectionData } from '../../shared/firebase';
import firebase, { functions } from '../../firebase';
import useLocale from '../hooks/useLocale';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import ModalButton from '../ModalButton';
import AppButton from '../AppButton';
import ProgressButton from '../ProgressButton';
import LectureTypeDisplay from '../LectureTypeDisplay';
import LectureLevelDisplay from '../LectureLevelDisplay';
import LectureAgeDisplay from '../LectureAgeDisplay';

const { entries } = Object;
const db = firebase.firestore();
const findEntryByCheckInCode = functions.httpsCallable('findEntryByCheckInCode');
const findEntriesByUserName = functions.httpsCallable('findEntriesByUserName');
const generateSubUserInvitationToken = functions.httpsCallable('generateSubUserInvitationToken');

function SurveyWithCheckInCode(props) {
  const {
    user,
    history,
    match: {
      params: { tenantPath, eventId },
    },
  } = props;
  const { translate } = useLocale();
  const event = useDocumentSubscription(db.collection('events').doc(eventId), [eventId]);
  const [entries, setEntries] = useState([]);
  const [entry, setEntry] = useState();
  const lectures = useDocumentsFetch(entries.map(_ => _.lectureIds[0] && db.doc(`events/${eventId}/lectures/${_.lectureIds[0]}`)), [entries]);
  const lecturesById = keyBy(lectures, 'id');
  const [lecture, setLecture] = useState();
  const locale = useDocumentSubscription(event && db.collection('locales').doc([tenantPath, event.lang || 'ja'].join('__')), [event]);
  const onSubmit = async (findFn, values) => {
    try {
      const { data: { entries } } = await retry(_ => findFn({ eventId, ...values, }), { retries: 2 });
      if(isEmpty(entries)) {
        return toast.error('見つかりませんでした');
      }

      setEntries(entries);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return translate(
    <div className="survey-with-check-in-code py-5 px-3">
      <div className="d-flex justify-content-center">
        {
          isEmpty(entries) ? (
            <div className="d-flex flex-column gap-2">
              <ModalButton
                Component={AppButton}
                size="lg"
                color="primary"
                Modal={ModelFormModal}
                modalProps={{
                  title: 'チェックインコードでイベント申込を探す',
                  fields: {
                    checkInCode: {
                      type: 'string',
                      label: 'チェックインコード',
                      validations: { required: _ => !!_, },
                    },
                  },
                  onSubmit: onSubmit.bind(null, findEntryByCheckInCode),
                  submitLabel: '送信',
                }}
                >
                チェックインコードでイベント申込を探す
              </ModalButton>
              <ModalButton
                Component={AppButton}
                size="lg"
                color="primary"
                Modal={ModelFormModal}
                modalProps={{
                  title: '名前でイベント申込を探す',
                  fields: {
                    displayName: {
                      type: 'string',
                      label: '名前',
                      hint: 'スペースは省いて検索されます',
                      validations: { required: _ => !!_, },
                    },
                  },
                  onSubmit: onSubmit.bind(null, findEntriesByUserName),
                  submitLabel: '送信',
                }}
                >
                名前でイベント申込を探す
              </ModalButton>
            </div>
          ) : (
            <div>
              <div className="alert alert-info">以下のイベント申込みが見つかりました。</div>
              <div className="mt-4 d-flex flex-column gap-3">
                {
                  entries.map((entry) => {
                    const [frame] = entry.frames || [];
                    const lecture = lecturesById[entry.lectureIds[0]];
                    const { startAt, endAt } = lecture?.frames[frame?.frameIndex] || {};
                    const surveyAnswerUrl = `/${tenantPath}/surveys/${lecture?.laterSurveyId}/surveyAnswers/new?eventId=${event?.id}&entryId=${entry.id}&frameIndex=${entry.frames[0]?.frameIndex}`;
                    const onClickWithSubUser = async () => {
                      const { data: { token } } = await retry(_ => generateSubUserInvitationToken({ uid: entry.createdBy.uid }), { retries: 2 });
                      history.push(surveyAnswerUrl + `&subUserInvitationToken=${token}`);
                    };

                    return lecture && (
                      <div className="border rounded p-3">
                        <div className="d-flex align-items-center">
                          <LectureTypeDisplay lectureType={lecture.lectureType} />
                          <div className="ml-3 text-grey">{numeral(lecture.price).format('0,0')} 円(税込)</div>
                        </div>
                        <div className="mt-3 d-flex">
                          <LectureLevelDisplay className="flex-fill" lectureLevel={lecture.lectureLevel} />
                          <LectureAgeDisplay className="ml-3 flex-fill" lectureAge={lecture.lectureAge} />
                        </div>
                        <div className="mt-2">
                          {lecture.date && formatDate(lecture.date.toDate(), 'yyyy/MM/dd')} {formatDate(startAt.toDate(), 'HH:mm')}{' '}
                          - {formatDate(endAt.toDate(), 'HH:mm')}
                        </div>
                        <div className="mt-2">
                          <table className="table table-borderless table-sm">
                            <tbody>
                              <tr>
                                <td>アカウント名</td>
                                <td className="font-weight-bold">{entry.createdBy?.displayName}</td>
                              </tr>
                              <tr>
                                <td>参加者名</td>
                                <td className="font-weight-bold">{frame.name}</td>
                              </tr>
                              <tr>
                                <td>生年月日</td>
                                <td className="font-weight-bold">{formatDate(new Date(frame.birthday), 'yyyy / MM / dd')}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="mt-2">
                          {
                            user != null ? (
                              <AppButton color="primary" size="lg" block tag={Link} to={surveyAnswerUrl}>
                                アンケートに回答する
                              </AppButton>
                            ) : (
                              <div className="d-flex flex-column">
                                <AppButton color="primary" size="lg" block tag={Link} to={surveyAnswerUrl}>
                                  ログインしてアンケートに回答する
                                </AppButton>
                                <ProgressButton Component={AppButton} color="primary" size="lg" block process={onClickWithSubUser}>
                                  家族なのでサブアカウントを作成してアンケートに回答する
                                </ProgressButton>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  , locale);
}

SurveyWithCheckInCode.preview = true;
export default TenantUserPage(SurveyWithCheckInCode);
