import React, { useEffect, useRef, } from 'react';
import qrcode from 'qrcode';
import { Button, Input, } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import useTenant from './hooks/useTenant';
import QrCode from './QrCode';

export default function QrCodeCard(props) {
  const { qrUrl, agentShop, referrer, qrBottomDisplaysById, } = props;
  const tenant = useTenant();
  const urlWithKey = `${window.origin}/${tenant.id}/referralRedirect?qrUrlId=${qrUrl.id}&redirectUrl=${encodeURIComponent(qrUrl.url)}&referrerKey=${referrer.key}`;
  const onClickCopy = () => {
    copy(urlWithKey);
    toast.success('クリップボードにコピーしました');
  };

  return (
    <div className="card mb-3">
      <div className="card-header">
        {qrUrl.name}
      </div>
      <div className="card-body">
        <div className="mb-2">{qrUrl.description}</div>
        <div className="d-flex justify-content-center" title={urlWithKey}>
          <QrCode url={urlWithKey} topText={agentShop.name} centerText={[qrUrl, referrer].map(_ => _.textForQr || '').join('-')} bottomText={qrBottomDisplaysById[referrer.qrBottomDisplayId]?.display} />
        </div>
        <div className="mt-4">
          <div className="d-flex">
            <Input className="flex-grow-1 mr-2" readOnly defaultValue={urlWithKey} />
            <Button color="primary" onClick={onClickCopy}>
              <span className="fas fa-copy" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
