const { isEmpty, } = require('lodash');

const { entries } = Object;
const columnWidths = {
  full: { label: '全画面', container: 'container-fluid', },
  width12: { label: '広め', col: 'col-12', },
  width8: { label: '標準', col: 'col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8', },
  width6: { label: '狭め', col: 'col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6', },
};

module.exports = {
  fields: () => {
    return {
      code: {
        label: '商品コード',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      image: {
        label: '画像',
        type: 'file',
        inputProps: {
          accept: 'image/*',
        },
      },
      name: {
        label: '商品名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      price: {
        label: '税込価格',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
      description: {
        label: '商品説明',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      expirationDays: {
        label: '期限(日)',
        type: 'integer',
        validations: {
          greaterThan0: v => v != null && v > 0,
        },
      },
      columnWidth: {
        label: '横幅',
        type: 'select',
        options: entries(columnWidths).map(([k, v]) => ({ label: v.label, value: k, })),
        validations: { required: (v) => !isEmpty(v) },
      },
      body: {
        label: '内容',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      file: {
        label: 'ファイル',
        type: 'file',
        inputProps: {
          accept: 'image/*,video/mp4,audio/*,application/pdf',
        },
        hint: 'MOV形式はアップロード不可',
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      note: {
        label: 'メモ',
        type: 'text',
        initialValue: '',
      },
    };
  },
};
