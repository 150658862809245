const { isEmpty } = require('lodash');

const { vehicleExperiences } = require('./user');
const {
  userFiltering: { dateTypes },
  smsOrderTypes: orderTypes,
  smsEntryTypes: entryTypes,
} = require('../config');

const { entries } = Object;

const statuses = {
  create: { label: '作成前', color: 'secondary' },
  creating: { label: '作成中', color: 'secondary' },
  completed: { label: '作成完了', color: 'secondary' },
  error: { label: 'エラー', color: 'danger', },
};

module.exports = {
  fields: ({ products = [], productTypes = [], events = [], userTags = [], areaGroups = [] } = {}) => {
    return {
      dateType: {
        label: '日付範囲種別',
        type: 'select',
        options: entries(dateTypes).map(([k, v]) => ({ label: v.label, value: k })),
        initialValue: '',
        validations: {
          required: (_) => !isEmpty(_),
        },
      },
      startDate: {
        label: '開始日',
        type: 'date',
      },
      endDate: {
        label: '終了日',
        type: 'date',
      },
      orderType: {
        label: '注文種別',
        type: 'select',
        options: entries(orderTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: (_) => !isEmpty(_),
        },
      },
      conditionProductTypeIds: {
        label: '注文商品種別',
        type: 'multiSelect',
        options: productTypes.map((_) => ({ label: _.name, value: _.id })),
        hidden: (_) => _.orderType !== 'ordered',
      },
      conditionProductIds: {
        label: '注文商品',
        type: 'multiSelect',
        options: products.map((_) => ({ label: _.label || _.name, value: _.id })),
        hidden: (_) => _.orderType !== 'ordered',
      },
      isBodyOnly: {
        label: '本体のみ',
        type: 'boolean',
        initialValue: false,
        hidden: _ => _.orderType !== 'ordered',
      },
      entryType: {
        label: 'イベント参加種別',
        type: 'select',
        options: entries(entryTypes).map(([k, v]) => ({ label: v.label, value: k })),
        validations: {
          required: (_) => !isEmpty(_),
        },
      },
      conditionEventIds: {
        label: '参加イベント',
        type: 'multiSelect',
        options: events.map((_) => ({ label: _.name, value: _.id })),
        hidden: (_) => _.entryType !== 'entried',
      },
      userTagIds: {
        label: 'ユーザータグ',
        type: 'multiSelect',
        options: userTags.map((_) => ({ label: _.name, value: _.id })),
        hint: 'ORで判定します',
      },
      userChildAgeMin: {
        label: 'お子様の年齢下限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: (v) => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildAgeMax: {
        label: 'お子様の年齢上限',
        type: 'float',
        validations: {
          greaterThanOrEqualTo0: (v) => v == null || v >= 0,
        },
        showsTextInput: true,
      },
      userChildVehicleExperiences: {
        label: 'お子様乗り物経験',
        type: 'multiSelect',
        options: ['未選択', ...vehicleExperiences].map((_) => ({ label: _, value: _ })),
      },
      areaGroups: {
        label: 'エリアグループ',
        type: 'multiSelect',
        options: areaGroups.map((_) => ({ label: _, value: _ })),
        hint: 'ORで判定します'
      }
    };
  },
  adminFields: () => {
    return {
      adminNote: {
        label: '備考',
        type: 'text',
      },
    };
  },
  supportFields: ({ surveys = [] } = {}) => {
    return {
      supportSurvey: {
        label: '対応完了後アンケート',
        type: 'select',
        options: surveys.map(_ => ({ label: _.title, value: _.id })),
        initialValue: '',
      },
    }
  },
  orderTypes,
  entryTypes,
  dateTypes,
  statuses,
};
