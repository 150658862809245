import React, { useState } from 'react';
import { Badge } from 'reactstrap';
import { omitBy, isUndefined, pick, uniq, uniqBy, difference, keyBy, isEmpty, get } from 'lodash';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import { prefectures } from '../../shared/config';
import SuperAdminPage from '../hocs/SuperAdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import EditButton from '../EditButton';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import { fields } from '../../shared/models/user';

const db = firebase.firestore();

export default SuperAdminPage(function SuperAdminUsers(props) {
  const { user } = props;
  const usersRef = db.collection('users');
  const params = useQueryParams();
  const { field: [field] = ['email'], text, showsAllUsers: _showsAllUsers = '0', } = params;
  const showsAllUsers = _showsAllUsers === '1';
  const usersQuery = text
    ? usersRef.orderBy(field).startAt(text).endAt(`${text}\uf8ff`)
    : showsAllUsers
    ? usersRef
    : usersRef.orderBy('createdAt', 'desc').limit(100);
  const users = useCollectionSubscription(usersQuery, [params]);
  const tenants = useCollectionSubscription(db.collection('tenants'));
  const tenantsById = keyBy(tenants, 'id');
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウント名', value: 'displayName' },
    { label: '電話番号', value: 'phone' },
  ];

  return (
    <div>
      <div className="super-admin-users container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アカウント管理</h4>
        </div>
        <div>
          <div className="d-flex flex-wrap gap-2 align-items-end">
            <QuerySelector
              paramName="field"
              options={fieldOptions}
              label="検索フィールド"
              defaultValue={[field]}
              isClearable={false}
            />
            <QueryText paramName="text" label="検索テキスト" />
            <QueryBoolean paramName="showsAllUsers" label="全アカウント表示" defaultValue={'0'} />
          </div>
        </div>
        <div className="mt-1 overflow-auto">
          {users.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 150 }}>アカウント名</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 100 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 250 }}>関連するテナント</th>
                </tr>
              </thead>
              <tbody>
                {(users || []).map((user) => {
                  const {
                    id,
                    email,
                    displayName,
                    prefecture,
                    phone,
                    role = 'user',
                    city,
                    mainUserId,
                    subUserIds,
                    tenantIds,
                    disabledAt,
                  } = user;
                  return (
                    <tr key={id} style={{ background: disabledAt ? 'lightgray' : '' }}>
                      <td>
                        <div>{displayName}</div>
                        <div>
                          {!isEmpty(mainUserId) && <div className="badge badge-warning">サブアカウント</div>}
                          {!isEmpty(subUserIds) && (
                            <div className="badge badge-info">サブアカウントあり {subUserIds.length}件</div>
                          )}
                        </div>
                        {disabledAt && <Badge color="danger">無効</Badge>}
                      </td>
                      <td>{email}</td>
                      <td>{prefecture && prefectures[prefecture]}</td>
                      <td>{city}</td>
                      <td>{phone}</td>
                      <td>
                        <div className="d-flex flex-column align-items-start">
                          {
                            tenantIds?.map((tenantId) => {
                              const tenant = tenantsById[tenantId];
                              const { name, id } = tenant || {};
                              return (
                                <Link key={id} to={`/${id}/admin/users/${user.id}`} target="_blank">
                                  {name}
                                </Link>
                              );
                            })
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});
