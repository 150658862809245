import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
  FieldPath,
} from '../firebase';
import { tenantQueryConstraints, forwardMatchingQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { InquiryDocumentData, Inquiry } from '../types';
import type { PartiallyPartial } from 'types';

export const inquiryConverter = getConverter<InquiryDocumentData>();

export const inquiriesRef = () => collection(getFirestore(), 'inquiries').withConverter(inquiryConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Inquiry> : null;
export const inquiryRef = <Id extends string | undefined>(inquiryId: Id) =>
  (inquiryId ? doc(inquiriesRef(), inquiryId) : null) as RefOrNull<Id>;

export const newInquiryRef = () => doc(inquiriesRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'desc')];

export const inquiriesQuery = (...queryConstraints: QueryConstraint[]) =>
  query(inquiriesRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantInquiriesQuery = (tenantId: string | undefined) =>
  tenantId ? inquiriesQuery(...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

export const forwardMatchingTenantInquiriesQuery = (
  tenantId: string | undefined,
  fieldPath: string | FieldPath,
  text: string
) =>
  tenantId
    ? inquiriesQuery(...tenantQueryConstraints(tenantId), ...forwardMatchingQueryConstraints(fieldPath, text))
    : null;

export const addInquiry = async (data: PartiallyPartial<Inquiry, 'id' | 'createdAt'>) =>
  addDoc(inquiriesRef(), { createdAt: serverTimestamp(), ...data });

export const updateInquiry = async (inquiryId: string, data: UpdateData<Inquiry>) =>
  updateDoc(inquiryRef(inquiryId), data);

export const deleteInquiry = async (inquiryId: string) => deleteDoc(inquiryRef(inquiryId));

export const useInquiryCollection = useCollectionData;

export const useInquiryCollectionOnce = useCollectionDataOnce;

export const useInquiryDocument = useDocumentData;

export const useInquiryDocumentOnce = useDocumentDataOnce;
