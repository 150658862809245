import React, { useEffect, } from 'react';

import firebase from '../../firebase';
import PublicPage from '../hocs/PublicPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';

const db = firebase.firestore();

function ShortUrl (props) {
  const { history, match: { params: { shortUrlKey } } } = props;
  const [shortUrl] = useCollectionSubscription(db.collection('shortUrls').where('key', '==', shortUrlKey), [shortUrlKey]);
  useEffect(() => {
    if(shortUrl != null) {
      if(shortUrl.destination.match(/(localhost|vs2-staging2|vs2-multi-staging|vitamin-i.app)/)) {
        history.replace(shortUrl.destination.replace(new URL(shortUrl.destination).origin, ''));
      } else {
        window.location.href = shortUrl.destination;
      }
    }
  }, [shortUrl]);

  return null;
}

export default ShortUrl;
