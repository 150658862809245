import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { get, omitBy, isUndefined, pick, } from 'lodash';
import Toggle from 'react-toggle'
import { format as formatDate } from 'date-fns'

import { canUpdateAgentContractPlan, } from '../../shared/abilities';
import { contractPlanFields, } from '../../shared/models/agent';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { agentUserFields } from '../../shared/models/user';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const usersRef = db.collection('users');

export default AdminPage(function AdminAgentContractPlans (props) {
  const { user, match: { params: { agentId, tenantPath, } }, addBreadNavValues } = props;
  const { firebaseUser } = useFirebaseUser();
  const agent = useDocumentSubscription(agentsRef.doc(agentId), [agentId]);
  const onEdited = async (values) => {
    await agent.ref.collection('agentContractPlanLogs').doc().set({
      tenantId: tenantPath,
      notificates: true,
      text: values.agentContractPlanText,
      addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      createdAt: new Date(),
    });
  };
  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent-contract-plans container py-5 position-relative">
        <AdminAgentHeader activeTab="contractPlans" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="mt-4 d-flex justify-content-end mb-3 gap-1">
                <ModalButton title="履歴" content={_ => <AgentContractPlanTextLogs agent={agent} />} modalProps={{ style: { minWidth: '80vw', } }}>
                  <span className="fas fa-history mr-1" />
                  履歴
                </ModalButton>
                <EditButton
                  itemRef={agent.ref}
                  FormModal={ModelFormModal}
                  disabled={!canUpdateAgentContractPlan(user)}
                  formProps={{ title: '規約本文編集', fields: contractPlanFields(), }}
                  validateOnSubmit={_ => window.confirm('編集内容を保存し、代理店のビジネスアカウントにメール通知されます。よろしいですか？')}
                  onFinish={onEdited}
                />
              </div>
              <div>
                <div className="card p-2" style={{ whiteSpace: 'pre-line' }}>
                  {agent.agentContractPlanText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function AgentContractPlanTextLogs (props) {
  const { agent } = props;
  const agentContractPlanLogs = useCollectionSubscription(agent.ref.collection('agentContractPlanLogs').orderBy('createdAt', 'desc'), [agent]);
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th style={{ minWidth: 100 }}>保存者</th>
          <th style={{ minWidth: 100 }}>保存日時</th>
          <th style={{ minWidth: '50vw', }}>本文</th>
        </tr>
      </thead>
      <tbody>
        {
          agentContractPlanLogs.map((agentContractPlanLog) => {
            const { id, text, addedBy, createdAt } = agentContractPlanLog;
            return (
              <tr key={id}>
                <td>{addedBy?.displayName}</td>
                <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                <td>
                  <div className="overflow-auto" style={{ maxHeight: 200, whiteSpace: 'pre-line', }}>
                    {text}
                  </div>
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};
