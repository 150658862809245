import React, { Fragment, useEffect } from 'react';
import { toast } from 'react-toastify';
import { keyBy, sortBy, isEmpty, invert, pick, omitBy, isUndefined, } from 'lodash';
import { addMilliseconds, } from 'date-fns';
import { v4 as uuid } from 'uuid';
import { Button, } from 'reactstrap';
import qs from 'qs';

import { prefectures, } from '../../shared/config';
import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import { beforeDelete, fillAddress, } from '../../util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import useQueryParams from '../hooks/useQueryParams';
import EditButton from '../EditButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';
import QrPdfsButton from '../QrPdfsButton';
import { fields } from '../../shared/models/agentShop';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const agentsRef = db.collection('agents');
const qrBottomDisplaysRef = db.collection('qrBottomDisplays');

export default AdminPage(function AdminAgentShops (props) {
  const { user, history, match: { params: { tenantPath, agentId } }, addBreadNavValues } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const agentRef = agentsRef.doc(agentId);
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const agentShops = useCollectionSubscription(agentRef.collection('agentShops').orderBy('createdAt'), [agentId]);
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(qrBottomDisplaysRef), _ => _.createdAt.toDate());
  const qrBottomDisplaysByName = keyBy(qrBottomDisplays, 'name');

  let filteredAgentShops = agentShops;
  if (showsHidden !== '1') {
    filteredAgentShops = filteredAgentShops.filter(_ => !_.isHidden);
  }

  const onFormStateChanged = (prevStatedFields, statedFields) => {
    fillAddress(prevStatedFields, statedFields);
  };
  const rowsForExport = () => {
    return filteredAgentShops.map(_ => ({ ..._, prefectureLabel: prefectures[_.prefecture], })).map((agentShop) => {
      return {
        ...pick(agentShop, [
          'id',
          'name',
          'nameKana',
          'customerCode',
          'email',
          'phone',
          'hidesShopDestination',
          'postalCode',
          'prefectureLabel',
          'city',
          'address',
        ]),
        createsReferrer: null,
        referrerName: null,
        referrerQrBottomDisplayName: null,
        referrerTextForQr: null,
      };
    });
  };
  const processRow = (batch, item, i) => {
    if(isEmpty(item.name) && isEmpty(item.id)) return;

    const exists = agentShops.some(_ => _.id === item.id);
    const agentShopRef = agent.ref.collection('agentShops').doc(item.id || undefined);
    batch.set(agentShopRef, {
      ...pick(item, [
        'name',
        'nameKana',
        'customerCode',
        'email',
        'phone',
        'postalCode',
        'city',
        'address',
      ]),
      hidesShopDestination: item.hidesShopDestination?.toUpperCase() === 'TRUE',
      prefecture: invert(prefectures)[item.prefectureLabel] || null,
      tenantId: tenantPath,
      ...(
        !exists && {
          createdAt: addMilliseconds(new Date(), i), // NOTE: csvの順番にするため、ちょっとだけ時間をゴニョる
        }
      ),
      [exists ? 'updatedBy' : 'addedBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
    }, { merge: true });
    if(item.createsReferrer?.toUpperCase() === 'TRUE') {
      const key = uuid();
      batch.set(agentShopRef.collection('referrers').doc(key), {
        key,
        name: item.referrerName,
        qrBottomDisplayId: qrBottomDisplaysByName[item.referrerQrBottomDisplayName]?.id || null,
        textForQr: item.referrerTextForQr,
        addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
        isHidden: false,
        createdAt: new Date(),
      });
    }
  };

  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent container-fluid py-5 position-relative">
        <AdminAgentHeader activeTab="shops" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                店舗
              </h5>
              <div className="d-flex align-items-end justify-content-end mb-3 gap-1">
                <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
                <ImportButton processRow={processRow} batchSize={250} />
                <ExportButton fileName={`${agent.name}_店舗.csv`} rows={rowsForExport} />
                <AddInTenantButton
                  itemRef={agentRef.collection('agentShops').doc()}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '店舗追加',
                    fields,
                    onChange: onFormStateChanged,
                    renderFooterContent: (
                      base,
                      { onSubmit, isUnsubmittable, toggleSubmitting, statedFields, isSubmitting }
                    ) => {
                      const onSubmitNext = async (...args) => {
                        await onSubmit(...args);
                        history.push(`/${tenantPath}/admin/agents/${agent.id}/members?${qs.stringify({ creates: 1, email: statedFields.email.value })}`);
                      };
                      return (
                        <Fragment>
                          {base}
                          <Button className="save" type="submit" color="primary" onClick={onSubmitNext} disabled={isUnsubmittable || isSubmitting}>
                            {isSubmitting && <span className="fas mr-1 fa-spin fa-spinner" />}
                            保存してビジネスアカウント作成へ
                          </Button>
                        </Fragment>
                      );
                    },
                  }}
                  disabled={!canUpdateAgent(user)}
                />
              </div>
              {
                agentShops.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>店舗名</th>
                        <th>得意先CD</th>
                        <th>メールアドレス</th>
                        <th>電話番号</th>
                        <th>所在地</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (filteredAgentShops || []).map((agentShop) => {
                          const { id, ref, name, nameKana, customerCode, email, phone, postalCode, prefecture, city, address, isHidden = false, } = agentShop;

                          return (
                            <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                              <td>
                                <TenantLink to={`/admin/agents/${agentId}/agentShops/${id}`}>
                                  {name}({nameKana})
                                </TenantLink>
                              </td>
                              <td>
                                {customerCode}
                              </td>
                              <td>
                                {email}
                              </td>
                              <td>
                                {phone}
                              </td>
                              <td>
                                {postalCode} {prefectures[prefecture]}{city}{address}
                              </td>
                              <td className="text-right">
                                <QrPdfsButton {...{ agent, agentShop, }} />
                                <EditButton itemRef={ref} className="ml-1" FormModal={ModelFormModal} disabled={!canUpdateAgent(user)} formProps={{ title: '店舗編集', fields, onChange: onFormStateChanged, }} />
                                <DeleteButton itemRef={ref} className="ml-1" disabled={!canUpdateAgent(user)} beforeDelete={beforeDelete([ref.collection('referrers'), ref.parent.parent.collection('rentalOrders').where('agentShopId', '==', id)])} />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div>
                    店舗は未登録です
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
