import React, { useEffect, } from 'react';
import { toast } from 'react-toastify';
import qs from 'qs';
import { isEmpty } from 'lodash';

import firebase, { functions } from '../../firebase';
import Page from '../hocs/Page';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useLocale from '../hooks/useLocale';

const db = firebase.firestore();
const verifyEmail = functions.httpsCallable('verifyEmail');

export default Page(function EmailVerification(props) {
  const queryParams = useQueryParams();
  const { emailVerificationToken, pathname, search = '', } = queryParams;
  const localeId = queryParams.localeId === 'null' ? null : queryParams.localeId;
  const locale = useDocumentSubscription(localeId && db.collection('locales').doc(localeId), [localeId]);
  const { translate } = useLocale();
  useEffect(() => {
    if(!isEmpty(localeId) && locale == null) return;

    (async () => {
      const { data: { status, errorMessage } } = await verifyEmail({ emailVerificationToken });
      if(status === 'ok') {
        toast.success(translate('メールアドレスが確認できました', locale));
        const extendsSearch = '?' + qs.stringify({ ...qs.parse(search.slice(1)), verifiedEmail: 1 })
        setTimeout(() => {
          window.location.href = pathname ? decodeURIComponent(pathname) + decodeURIComponent(extendsSearch): '/';
        }, 1000);
      } else if (status === 'failed') {
        toast.error(errorMessage);
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);
      }
    })();
  }, [locale]);

  return (isEmpty(localeId) || locale != null) && translate(
    <div className="email-verification h-100 container">
      <div className="py-5 d-flex justify-content-center">
        <div className="py-3">
          <span className="fas fa-spin fa-spinner mr-1" />
          確認中...
        </div>
      </div>
    </div>
  , locale);
});
