import React, { useState, useEffect } from 'react';
import { format as formatDate, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';
import classnames from 'classnames';
import { uniq, keyBy, isEmpty, uniqBy, orderBy } from 'lodash';
import { useToggle } from 'react-use';
import { Alert, Button } from 'reactstrap';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import AdminEventHeader from '../AdminEventHeader';
import ExportButton from '../ExportButton';
import { answerText, fullPathWithParams } from '../../util';
import { ageDisplay } from '../../shared/util';
import TenantLink from '../TenantLink';
import QuerySelector from '../QuerySelector';
import EntryFrameNoteModal from '../modals/EntryFrameNoteModal';
import EditButton from '../EditButton';
import DeleteEventAnswersButton from '../DeleteEventAnswersButton';
import useTenant from '../hooks/useTenant';
import QueryDateSelector from '../QueryDateSelector';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import AnswerStatModal from '../modals/AnswerStatModal';

const db = firebase.firestore();
const usersRef = db.collection('users');
const eventsRef = db.collection('events');
const questionsRef = db.collection('questions');
const productsRef = db.collection('products');
const ordersRef = db.collection('orders');
const surveysRef = db.collection('surveys');

const getBeforeQuestionIds = (lectures = [], entries = [], surveys = []) => {
  const beforeQuestionIds = lectures.map((_) => [...(surveys.find(({ id }) => id === _.surveyId)?.questionRows?.map(_ => _.questionId) || []), ...(_.questions || [])]).flat();
  const beforeAnsweredIds = entries.map(({ answers }) => Object.keys(answers || {})).flat();
  return uniq([...beforeQuestionIds, ...beforeAnsweredIds]);
}

const getLaterQuestionIds = (lectures = [], entries = [], surveys = []) => {
  const laterQuestionIds = lectures.map((_) => [...(surveys.find(({ id }) => id === _.laterSurveyId)?.questionRows?.map(_ => _.questionId) || []), ...(_.laterQuestions || [])]).flat();
  const laterAnsweredIds = entries.map(({ frames }) => frames.map(_ => Object.keys(_.laterAnswer | {})).flat()).flat();
  return uniq([...laterQuestionIds, ...laterAnsweredIds])
}

export default AdminPage(function AdminEvent(props) {
  const {
    user,
    match: {
      params: { eventId },
    },
    addBreadNavValues,
    location,
    history,
  } = props;
  const now = new Date();
  const queryParams = useQueryParams();
  const {
    startOn: startOnString = formatDate(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endOn: endOnString = formatDate(endOfMonth(new Date()), 'yyyy-MM-dd'),
  } = queryParams;
  const [answerStatQuestionId, setAnswerStatQuestionId] = useState();
  const [showsAnswerStat, toggleAnswerStat] = useToggle();
  const startOn = new Date(startOnString);
  const endOn = new Date(endOnString);
  const tenant = useTenant();
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const entries = useCollectionFetch(eventsRef.doc(eventId).collection('entries'), [eventId]);
  const lectures = useCollectionFetch(eventsRef.doc(eventId).collection('lectures'), [eventId]);
  const lecturesById = keyBy(lectures, 'id');
  const lectureIdOptions = orderBy(
    lectures.map((_) => ({ label: formatDate(_.date.toDate(), 'yyyy/MM/dd'), value: _.id })),
    'label'
  );
  const lectureFrameOptions = orderBy(
    uniqBy(
      lectures
        .map(({ frames }) =>
          frames.map((_) => {
            const label = `${formatDate(_.startAt.toDate(), 'H:mm')} - ${formatDate(_.endAt.toDate(), 'H:mm')}`;
            const value = `${_.startAt.seconds}_${_.endAt.seconds}`;
            return { label, value, sortKey: _.startAt.seconds };
          })
        )
        .flat(),
      'value'
    ),
    'sortKey'
  );
  const surveys = useCollectionFetchInTenant(surveysRef);
  const beforeQuestions = useDocumentsFetch(
    getBeforeQuestionIds(lectures, entries, surveys).map((_) => questionsRef.doc(_)),
    [lectures, surveys]
  )
  const laterQuestions = useDocumentsFetch(
    getLaterQuestionIds(lectures, entries, surveys).map((_) => questionsRef.doc(_)),
    [lectures, surveys]
  );
  const products = useCollectionFetchInTenant(productsRef);
  const productsById = keyBy(products, 'id');
  const ordersRefs = uniq(entries.map(({ createdBy }) => createdBy.uid)).map((uid) =>
    ordersRef.where('tenantId', '==', tenant.id).where('createdBy.uid', '==', uid)
  );
  const orders = useCollectionsFetch(ordersRefs, [entries]);
  const [checkedEntries, setCheckedEntries] = useState([]);
  const staffs = useCollectionsFetchInTenant(['admin', 'staff'].map(_ => usersRef.where('role', '==', _)));

  const getfilteredQuestionIds = (entries) => {
    const filteredLectures = uniq(entries.map(_ => _.lectureIds).flat()).map(_ => lecturesById[_]).filter(_ => _);
    const filteredBeforeQuestionIds = getBeforeQuestionIds(filteredLectures, entries, surveys);
    const filteredLaterQuestionIds = getLaterQuestionIds(filteredLectures, entries, surveys);
    return { filteredBeforeQuestionIds, filteredLaterQuestionIds };
  };
  
  let filteredEntries = entries.filter((entry) => {
    const frames = entry.frames.map((_) => ({ ..._, entry, lecture: lecturesById[_.lectureId] }))
    return frames.filter(_ => _.lecture?.date.toDate() >= startOfDay(startOn) && _.lecture?.date.toDate() <= endOfDay(endOn)).length;
  });
  const { filteredBeforeQuestionIds, filteredLaterQuestionIds } = getfilteredQuestionIds(filteredEntries);
  let filteredBeforeQuestions = beforeQuestions.filter((_) => filteredBeforeQuestionIds.includes(_.id));
  let filteredLaterQuestions = laterQuestions.filter((_) => filteredLaterQuestionIds.includes(_.id));
  
  if (!isEmpty(queryParams.lectureIds) && !isEmpty(lecturesById)) {
    filteredEntries = filteredEntries.filter(({ frames }) =>
      frames.some((_) => queryParams.lectureIds.includes(_.lectureId))
    );
    const { filteredBeforeQuestionIds, filteredLaterQuestionIds } = getfilteredQuestionIds(filteredEntries);
    filteredBeforeQuestions = filteredBeforeQuestions.filter((_) => filteredBeforeQuestionIds.includes(_.id));
    filteredLaterQuestions = filteredLaterQuestions.filter((_) => filteredLaterQuestionIds.includes(_.id));
  }

  if (!isEmpty(queryParams.lectureFrames) && !isEmpty(lecturesById)) {
    filteredEntries = filteredEntries.filter(({ frames }) =>
      frames.some((_) =>
        queryParams.lectureFrames.includes(`${_.lectureFrame.startAt.seconds}_${_.lectureFrame.endAt.seconds}`)
      )
    );
    const { filteredBeforeQuestionIds, filteredLaterQuestionIds } = getfilteredQuestionIds(filteredEntries);
    filteredBeforeQuestions = filteredBeforeQuestions.filter((_) => filteredBeforeQuestionIds.includes(_.id));
    filteredLaterQuestions = filteredLaterQuestions.filter((_) => filteredLaterQuestionIds.includes(_.id));
  }

  const entryFrames = filteredEntries
    .map((entry) =>
      entry.frames.map((_) => {
        const { lectureId, birthday } = _;
        const lecture = lecturesById[lectureId];
        const { date } = lecture || {};
        const formattedBirthday = birthday && formatDate(birthday.toDate(), 'yyyy/MM/dd');
        const todayAge = birthday && ageDisplay(birthday.toDate(), new Date());
        const lectureAge = birthday && date && ageDisplay(birthday.toDate(), date.toDate());

        return { ..._, entry, lecture, formattedBirthday, todayAge, lectureAge };
      })
    )
    .flat();
  const entryFramesForExport = entryFrames.map((entryFrame) => {
    const { entry, lecture, name, formattedBirthday, todayAge, lectureAge, laterAnswers = {}, lectureFrame, note, noteUpdatedAt, noteUpdatedBy } = entryFrame;
    const { answers = {} } = entry;
    const isCancelled = entry.cancelledAt != null;
    const hasBodyOrder = orders
      .filter(({ createdBy }) => createdBy.uid === entry.createdBy.uid)
      .some(({ orderItems }) => orderItems.some((_) => productsById[_.productId].isBody));
    return {
      entryId: entry.id,
      isCancelled,
      entryDate: lecture ? formatDate(lecture.date.toDate(), 'yyyy/MM/dd') : '',
      entryTime: `${formatDate(lectureFrame.startAt.toDate(), 'H:mm')} - ${formatDate(
        lectureFrame.endAt.toDate(),
        'H:mm'
      )}`,
      entryUserName: entry.createdBy.displayName,
      entyUserEmail: entry.createdBy.email,
      name,
      birthday: formattedBirthday,
      todayAge,
      lectureAge,
      hasBodyOrder,
      ...filteredBeforeQuestions.reduce((x, { id, type, name }) => {
        return {
          ...x,
          [name]: answerText(answers[id], type),
        };
      }, {}),
      ...filteredLaterQuestions.reduce((x, { id, type, name }) => {
        return {
          ...x,
          [name]: answerText(laterAnswers[id], type),
        };
      }, {}),
      note,
      noteUpdatedAt: noteUpdatedAt?.toDate().toLocaleString(),
      noteUpdatedBy: noteUpdatedBy?.displayName,
    };
  });
  useEffect(() => {
    addBreadNavValues({ event });
  }, [event]);

  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd') },
      location
    );
    history.replace(encodeURI(path));
  };
  const openAnswerStatModal = (questionId) => {
    setAnswerStatQuestionId(questionId);
    toggleAnswerStat(true);
  };

  return (
    event != null && (
      <div>
        <div className="admin-event-entry-frames container-fluid py-5 position-relative">
          <AdminEventHeader activeTab="entryFrames" event={event} user={user} />
          <div className="bg-white p-4">
            <div className="row">
              <div className="col-12">
                <h5 className="text-center">参加者一覧</h5>
                <div className='d-flex align-items-end flex-wrap gap-2'>
                  <Button onClick={() => onClickDateButton([addDays(now, -1), addDays(now, -1)])}>昨日</Button>
                  <Button onClick={() => onClickDateButton([now, now])}>今日</Button>
                  <Button onClick={() => onClickDateButton([startOfMonth(now), endOfMonth(now)])}>今月</Button>
                  <Button
                    onClick={() => onClickDateButton([startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}
                  >
                    先月
                  </Button>
                  <QueryDateSelector
                    paramName='startOn'
                    label='開始日'
                    history={history}
                    location={location}
                    defaultValue={startOfMonth(new Date())}
                    style={{ width: 150 }}
                  />
                  <QueryDateSelector
                    paramName='endOn'
                    label='終了日'
                    history={history}
                    location={location}
                    defaultValue={endOfMonth(new Date())}
                    invalid={startOn > endOn}
                    style={{ width: 150 }}
                  />  
                </div>
                <div className="d-flex justify-content-between align-items-end mb-3">
                  <div className="d-flex align-items-end gap-1">
                    <QuerySelector
                      paramName="lectureIds"
                      width={250}
                      isMulti
                      options={lectureIdOptions}
                      label="レクチャーで絞込み"
                    />
                    <QuerySelector
                      paramName="lectureFrames"
                      width={250}
                      isMulti
                      options={lectureFrameOptions}
                      label="時間で絞込み"
                    />
                  </div>
                  <div>
                    <DeleteEventAnswersButton className="mr-1" targetEntries={checkedEntries} onFinish={() => setCheckedEntries([])} />
                    <ExportButton fileName={`${event.name}_参加者.csv`} rows={entryFramesForExport} />
                  </div>
                </div>
                {entryFrames.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th></th>
                        <th></th>
                        <th style={{ minWidth: 100 }}>参加日</th>
                        <th style={{ minWidth: 100 }}>参加時間</th>
                        <th style={{ minWidth: 100 }}>申込アカウント</th>
                        <th style={{ minWidth: 100 }}>名前</th>
                        <th style={{ minWidth: 100 }}>生年月日</th>
                        <th style={{ minWidth: 100 }}>現在の年齢</th>
                        <th style={{ minWidth: 100 }}>当日の年齢</th>
                        <th>本体購入</th>
                        <th>事後アンケート</th>
                        {filteredBeforeQuestions.map(({ id, name }) => {
                          return (
                            <th key={id} className="position-relative" style={{ minWidth: 150, maxWidth: 300 }}>
                              {name}
                              <Button color="link" size="sm" className="position-absolute" style={{ top: -2, right: -2 }} onClick={openAnswerStatModal.bind(null, id)}>
                                <span className="fas fa-search" />
                              </Button>
                            </th>
                          );
                        })}
                        {filteredLaterQuestions.map(({ id, name }) => {
                          return (
                            <th key={id} className="position-relative" style={{ minWidth: 150, maxWidth: 300 }}>
                              {name}
                              <Button color="link" size="sm" className="position-absolute" style={{ top: -2, right: -2 }} onClick={openAnswerStatModal.bind(null, id)}>
                                <span className="fas fa-search" />
                              </Button>
                            </th>
                          );
                        })}
                        <th style={{ minWidth: 200 }}>担当者</th>
                        <th style={{ minWidth: 200 }}>備考</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderBy(entryFrames, ['lecture.date', 'lectureFrame.startAt'], ['desc', 'asc']).map((entryFrame, i) => {
                        const { entry, lecture, name, formattedBirthday, todayAge, lectureAge, laterAnswers = {}, lectureFrame, note, noteUpdatedAt, noteUpdatedBy, laterQuestionInstructionMailSentAt, } = entryFrame;
                        const { answers = {} } = entry;
                        const isCheckedIn = entry.checkedInAt != null;
                        const isCancelled = entry.cancelledAt != null;
                        const hasBodyOrder = orders.filter(({ createdBy }) => createdBy.uid === entry.createdBy.uid).some(({ orderItems }) => orderItems.some(_ => productsById[_.productId].isBody));
                        const handleCheck = ({ target: { checked }}) => {
                          if (checked) {
                            setCheckedEntries(array => [...array, entry]);
                          } else {
                            setCheckedEntries(array => array.filter(_ => _.id !== entry.id));
                          }
                        }

                        return (
                          <tr
                            key={i}
                            className={classnames({ 'table-secondary': isCancelled || entry.abortedAt != null })}
                          >
                            <td>{['staff', 'admin'].includes(entry.createdBy.role) && <input type="checkbox" onChange={handleCheck} />}</td>
                            <td>
                              <div className="d-flex flex-column gap-1">
                                {isCheckedIn && <span className='badge badge-info'>チェックイン済み</span>}
                                {entry.abortedAt != null && <span className='badge badge-secondary'>中止済み</span>}
                                {isCancelled && <span className='badge badge-secondary'>キャンセル</span>}
                                {entry.refund != null && <span className='badge badge-success'>返金済み</span>}
                              </div>
                            </td>
                            <td>{lecture?.date ? formatDate(lecture.date.toDate(), 'yyyy/MM/dd') : ''}</td>
                            <td>
                              {formatDate(lectureFrame.startAt.toDate(), 'H:mm')} -{' '}
                              {formatDate(lectureFrame.endAt.toDate(), 'H:mm')}
                            </td>
                            <td>
                              <TenantLink to={`/admin/users/${entry.createdBy.uid}`}>
                                {entry.createdBy.displayName}
                              </TenantLink>
                            </td>
                            <td>{name}</td>
                            <td>{formattedBirthday}</td>
                            <td>{todayAge}</td>
                            <td>{lectureAge}</td>
                            <td>{hasBodyOrder ? '有' : ''}</td>
                            <td>
                              {
                                !!laterQuestionInstructionMailSentAt && (
                                  <div className="d-flex flex-column align-items-start">
                                    <span className='badge badge-info'>送信済み</span>
                                    <span className='text-muted small'>{formatDate(laterQuestionInstructionMailSentAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</span>
                                  </div>
                                )
                              }
                            </td>
                            {filteredBeforeQuestions.map(({ id, type, }) => {
                              const value = answerText(answers[id], type);
                              return <td key={id}>{value}</td>;
                            })}
                            {filteredLaterQuestions.map(({ id, type }) => {
                              const value = answerText(laterAnswers[id], type);
                              return <td key={id}>{value}</td>;
                            })}
                            <td>{(lectureFrame.pics || []).map(({ picId, staffRole}) => staffs.find(_ => _.id === picId)?.displayName + (staffRole ? `(${staffRole})` : '')).join(',')}</td>
                            <td>
                              <EditButton itemRef={entry.ref} FormModal={EntryFrameNoteModal} label='' icon={<span className='fas fa-edit' />} formProps={{ user, entry, lectureId: lecture?.id }} />
                              {note}<br />
                              {noteUpdatedAt && noteUpdatedBy && <small className='text-muted'>{noteUpdatedAt.toDate().toLocaleString()} {noteUpdatedBy.displayName}</small>}
                            </td>
                            <td>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>参加者はまだいません</div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showsAnswerStat && <AnswerStatModal isOpen onClickClose={toggleAnswerStat.bind(null, false)} defaultQuestionId={answerStatQuestionId} questions={[...filteredBeforeQuestions, ...filteredLaterQuestions]} rows={entryFrames.map(_ => _.entry)} />}
      </div>
    )
  );
});
