import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker'
import { Button } from 'reactstrap'
import qs from 'qs';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import { useLocation, useHistory } from 'react-router';

import { fullPathWithParams, } from '../util';

export default function QueryDateRangeSelector(props) {
  const { paramName, label, defaultValue, disabled = false, pickerProps, invalid = false, buttons = [], ...extraProps } = props;
  const location = useLocation();
  const history = useHistory();
  const { [paramName]: value = [defaultValue[0] && formatDate(defaultValue[0], 'yyyy-MM-dd'), defaultValue[1] && formatDate(defaultValue[1], 'yyyy-MM-dd')], } = qs.parse(decodeURI(location.search.slice(1)));
  const onSelect = (dates) => {
    const path = fullPathWithParams({ [paramName]: [dates[0] ? formatDate(dates[0], 'yyyy-MM-dd') : '', dates[1] ? formatDate(dates[1], 'yyyy-MM-dd') : ''], }, location);
    history.replace(encodeURI(path));
  };

  return (
    <div {...extraProps}>
      <div className="text-muted small">{label}</div>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        onChange={onSelect}
        className={classnames('form-control', ({ 'is-invalid': invalid }))}
        disabled={disabled}
        locale="ja"
        startDate={value[0] && new Date(value[0])}
        endDate={value[1] && new Date(value[1])}
        selectsRange
        customInput={<CustomInput />}
        {...pickerProps}
      >
        <div>
          {
            buttons.map((button, i) => {
              const dates = button.dates();
              return (
                <Button key={i} size="sm" color="link" onClick={onSelect.bind(null, dates)}>
                  {button.label}
                </Button>
              );
            })
          }
        </div>
      </DatePicker>
    </div>
  );
};



const CustomInput = forwardRef(({ ...props }, ref) => {
  return (
    <input {...props} ref={ref} style={{ minWidth: 220 }} />
  );
});
