import React, { useEffect } from 'react';
import qs from 'qs';
import { orderBy, maxBy, omit, isEmpty, sumBy, get, keyBy } from 'lodash';
import { format as formatDate, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import classnames from 'classnames';

import { fullPathWithParams } from '../../util';
import { canUpdateOrder } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import { prefectures } from '../../shared/config';
import { fields, destinationFields, conclusiveReferralFee } from '../../shared/models/order';
import { statuses, fieldsToApprove } from '../../shared/models/tmpOrder';
import { fields as userFields, adminFields as adminUserFields } from '../../shared/models/user';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentHeader from '../AgentHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';
import ModalButton from '../ModalButton';
import ModelFormModal from '../modals/ModelFormModal';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';

const { entries } = Object;
const db = firebase.firestore();
const productsRef = db.collection('products');
const productTypesRef = db.collection('productTypes');
const getAgentOrders = functions.httpsCallable('getAgentOrders');

export default AgentPage(function AgentTmpOrders(props) {
  const { user, agent, location, history, setBreadNavValues, canEditMembers, } = props;
  const { onlyInitial = '1' } = useQueryParams();
  const now = new Date();
  const agentShops = useCollectionSubscription(agent.ref.collection('agentShops').orderBy('createdAt'), [agent]);
  const agentShopsById = keyBy(agentShops, 'id');
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const tmpOrders = useCollectionSubscription(agent.ref.collection('tmpOrders').orderBy('createdAt', 'desc'), [agent]);
  const rows = tmpOrders.map((tmpOrder) => {
    const { orderItems = [], wholesaleAgentShopId } = tmpOrder;
    return {
      ...tmpOrder,
      agentShop: agentShopsById[wholesaleAgentShopId],
      orderItems: orderItems.map((orderItem) => {
        const { productId } = orderItem;
        const product = productsById[productId];
        return { ...orderItem, product, tmpOrder };
      }),
    };
  });

  // NOTE: filter
  let filteredRows = rows;
  if (onlyInitial === '1') {
    filteredRows = filteredRows.filter((_) => _.status === 'initial');
  }

  useEffect(() => {
    setBreadNavValues({ agent });
  }, [agent]);

  return (
    <div>
      <div className="agent-tmp-orders container-fluid py-5 position-relative">
        <AgentHeader activeTab="tmpOrders" agent={agent} canEditMembers={canEditMembers} />
        <div className="bg-white p-4">
          <div>
            <QueryBoolean paramName="onlyInitial" label="未対応のみ表示" defaultValue={'1'} />
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-end">
            <div>
              <span>注文件数: {filteredRows.length}</span>
              <span className="ml-3">
                注文商品数: {sumBy(filteredRows.map((_) => _.orderItems || []).flat(), 'quantity')}
              </span>
            </div>
          </div>
          <div className="overflow-auto mt-2">
            {filteredRows.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center text-nowrap">
                  <tr>
                    <th></th>
                    <th>状態</th>
                    <th>仮注文ID</th>
                    <th>発注番号</th>
                    <th>店舗</th>
                    <th style={{ minWidth: 300 }}>商品</th>
                    <th>上代合計(税込)</th>
                    <th>下代合計(税込)</th>
                    <th>注文日時</th>
                    <th>注文者お名前</th>
                    <th>注文者都道府県</th>
                    <th>注文者市区町村</th>
                    {entries(destinationFields()).map(([fieldName, { label }]) => {
                      return <th key={fieldName}>配送先{label}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {filteredRows.map((tmpOrder) => {
                    const {
                      id,
                      ref,
                      orderId,
                      orderItems,
                      agentShop,
                      amount,
                      wholesaleAmount,
                      discountAmount = 0,
                      createdAt,
                      shippedDate,
                      name,
                      prefecture,
                      city,
                      cancelledAt,
                      status = 'initial',
                      updatedBy,
                    } = tmpOrder;
                    const onClickApprovement = async (status, values = {}) => {
                      const message = {
                        approved: () =>
                          `${statuses.approved.label}にし、注文番号「${
                            values.orderId ? [agentShop.customerCode || '', values.orderId].join('-') : id
                          }」で本受注します。よろしいですか？`,
                        rejected: () => `${statuses.rejected.label}にします。よろしいですか？`,
                      }[status]();
                      if (!window.confirm(message)) return;

                      try {
                        if (values.orderId) {
                          const [sameOrderIdTmpOrder] = await getCollectionData(
                            agent.ref.collection('tmpOrders').where('orderId', '==', values.orderId).limit(1)
                          );
                          if (sameOrderIdTmpOrder) {
                            toast.error('同一の発注番号が存在します。');
                            return false;
                          }
                        }
                        await ref.update({ ...values, status, statusUpdatedAt: new Date() });
                        toast.success(`${statuses[status].label}にしました`);
                        return true;
                      } catch (e) {
                        console.error(e);
                        toast.error('失敗しました');
                      }
                    };

                    return (
                      <tr key={id}>
                        <td className="text-nowrap">
                          <div className="d-flex gap-1">
                            <ModalButton
                              label="承認"
                              color="success"
                              Modal={ModelFormModal}
                              modalProps={({ toggleModal }) => ({
                                title: '承認',
                                fields: fieldsToApprove,
                                submitLabel: '承認する',
                                onSubmit: async (values) => {
                                  if (await onClickApprovement('approved', values)) {
                                    toggleModal(false);
                                  }
                                },
                              })}
                              disabled={status !== 'initial' || isEmpty(agentShop?.customerCode)}
                            />
                            <ProgressButton
                              process={ () => onClickApprovement('rejected')}
                              color="secondary"
                              disabled={status !== 'initial'}
                            >
                              キャンセル
                            </ProgressButton>
                          </div>
                        </td>
                        <td>
                          <span className={classnames('badge', `badge-${statuses[status]?.color}`)}>
                            {statuses[status]?.label}
                          </span>
                          {status === 'rejected' && updatedBy?.uid === '' && (
                            <span className="small text-danger text-nowrap">7日経過キャンセル</span>
                          )}
                        </td>
                        <td>{id}</td>
                        <td>{orderId}</td>
                        <td>{agentShop?.name}</td>
                        <td>
                          {orderItems.map((orderItem) => {
                            const { product, quantity } = orderItem;
                            return (
                              product != null && (
                                <div>
                                  <span>{product.code}</span>
                                  <span className="ml-2">{product.name}</span>
                                  <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
                                </div>
                              )
                            );
                          })}
                        </td>
                        <td className="text-right">{numeral(amount - discountAmount).format('0,0')}</td>
                        <td className="text-right">{numeral(wholesaleAmount).format('0,0')}</td>
                        <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}</td>
                        <td>{name}</td>
                        <td>{prefectures[prefecture]}</td>
                        <td>{city}</td>
                        {entries(destinationFields()).map(([fieldName, fieldSettings]) => {
                          return <td key={fieldName}>{fieldDisplayValue(tmpOrder[fieldName], fieldSettings)}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div>No Data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
