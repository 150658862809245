import { useState } from 'react';
import { TextDecoder } from 'text-encoding';
import { parse as parseCsv } from 'papaparse';
import { chunk } from 'lodash';
import classnames from 'classnames';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import { writeBatch, WriteBatch } from '../../firebase';
import { readFile } from '../../util';

export const ImportButton = ({
  label = 'インポート',
  successMessage = 'インポートしました',
  encoding = 'Shift_JIS',
  onComplete = (_) => _,
  processRows = (_) => _,
  processRow = (_) => _,
  beforeSave = (_) => _,
  disabled = false,
  ...extraProps
}: {
  label?: string;
  successMessage?: string;
  encoding?: string;
  onComplete?: (rows: any[]) => any;
  processRows?: (rows: any[]) => any;
  processRow?: (batch: WriteBatch, row: any, i: number) => any;
  beforeSave?: (rows: any[]) => any;
  disabled?: boolean;
}) => {
  const [isImporting, setIsImporting] = useState(false);

  const onSelectFiles = async ({ target }: { target: HTMLInputElement }) => {
    const { files } = target;
    const [file] = files || [];
    if (!file) return;
    setIsImporting(true);
    try {
      const decoder = new TextDecoder(encoding);
      const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
      const { data: rows } = parseCsv(fileContent, { header: true });
      const processedRows = await processRows(rows);
      await beforeSave(processedRows);
      await chunk(processedRows, 500).reduce(async (x, rows, i) => {
        await x;
        const batch = writeBatch();
        await rows.reduce(async (x, row, i2) => {
          await x;
          await processRow(batch, row, i * 500 + i2);
        }, Promise.resolve());
        await batch.commit();
      }, Promise.resolve());
      await onComplete(processedRows);
      toast.success(successMessage);
    } catch (e) {
      console.error(e);
      toast.error('インポート失敗しました');
    }
    setIsImporting(false);
    target.value = '';
  };

  return (
    <Button color="secondary" disabled={disabled || isImporting} {...extraProps}>
      <label className="m-0 cursor-pointer">
        <span className={classnames('fas mr-1', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting })} />
        {label}
        {!disabled && <Input type="file" className="d-none" onChange={onSelectFiles} accept=".csv" />}
      </label>
    </Button>
  );
};
