import React from 'react';
import { omitBy, isUndefined, pick } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';
import classnames from 'classnames';

import firebase from '../firebase';
import useFirebaseUser from './hooks/useFirebaseUser';

const db = firebase.firestore();

export default function DeleteButton({
  item = null,
  label = '削除',
  itemRef,
  beforeDelete = async (_) => true,
  onFinish = null,
  disabled,
  ...extraProps
}) {
  const { firebaseUser } = useFirebaseUser();
  const [isDeleting, toggleDeleting] = useToggle();
  const onClickDelete = async (props) => {
    if (!window.confirm('本当に削除しますか？')) return;

    toggleDeleting(true);
    if ((await beforeDelete(item)) === false) {
      return toggleDeleting(false);
    }

    // NOTE: batchにすると、順番が担保されなかった
    await itemRef.update({ deletedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined) });
    await itemRef.delete();
    onFinish && (await onFinish());
    toast.success('削除しました');
    toggleDeleting(false);
  };

  return (
    <Button color="danger" onClick={onClickDelete} disabled={disabled || isDeleting} {...extraProps}>
      <span className={classnames('fas fa-trash', { 'mr-1': label })} />
      {label}
    </Button>
  );
}
