import React, { useState } from 'react';
import { omit, keyBy } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import SuperAdminPage from '../hocs/SuperAdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import { fields } from '../../shared/models/tenant';

const db = firebase.firestore();

export default SuperAdminPage(function SuperAdminTenants(props) {
  const { user } = props;
  const tenants = useCollectionSubscription(db.collection('tenants').orderBy('createdAt'));

  return (
    <div>
      <div className="super-admin-tenants container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>テナント管理</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddButton
            itemRef={_ => db.collection('tenants').doc(_.path)}
            FormModal={ModelFormModal}
            formProps={{ documentName: 'tenant', title: 'テナント追加', fields: fields() }}
          />
        </div>
        <div>
          {tenants.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 150 }}>名称</th>
                  <th style={{ minWidth: 200 }}>URLの固有文字列</th>
                  <th style={{ minWidth: 200 }}></th>
                </tr>
              </thead>
              <tbody>
                {tenants.map((tenant) => {
                  const { id, ref, name, path } = tenant;
                  return (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>{path}</td>
                      <td className="text-nowrap text-right">
                        <EditButton
                          itemRef={ref}
                          className="ml-2"
                          FormModal={ModelFormModal}
                          formProps={{ documentName: 'survey', title: 'テナント編集', fields: omit(fields(), 'path'), }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});
