import React, { useState, } from 'react';
import { sum, sortBy, keyBy, groupBy } from 'lodash';
import Select from 'react-select';
import { startOfDay, } from 'date-fns';
import { useAsync, } from 'react-use';
import { collection, collectionGroup, startAt, endAt, query, where, orderBy as dbOrderBy, limit, getCountFromServer, } from 'firebase/firestore';
import classnames from 'classnames';
import numeral from 'numeral';

import './index.css';
import eyecatchImage from '../../../images/img_eyecatch.jpg';
import bikeImage from '../../../images/img_henshin_bike.png';
import { prefectures, } from '../../../shared/config';
import firebase from '../../../firebase';
import { getCollectionData, } from '../../../shared/firebase';
import PublicPage from '../../hocs/PublicPage';
import EventCard from '../../EventCard';
import LectureIcon from '../../LectureIcon';
import useCollectionSubscriptionInTenant from '../../hooks/useCollectionSubscriptionInTenant';

const db = firebase.firestore();
const INITIAL_EVENTS_NUM = 1200;
const eventsRef = db.collection('events');
const placesRef = db.collection('places');
const lecturesRef = db.collectionGroup('lectures');
const { entries } = Object;
const prefectureOptions = entries(prefectures).map(([k, v]) => ({ label: v, value: k }));

export default PublicPage(function Events(props) {
  const { match: { params: { tenantPath, } } } = props;
  const [prefecture, setPrefecture] = useState();
  const places = useCollectionSubscriptionInTenant(placesRef);
  const placesById = keyBy(places, 'id');
  const placesGroupedByPrefecture = groupBy(places, 'prefecture');
  const events = useCollectionSubscriptionInTenant(eventsRef.where('isPublic', '==', true));
  const { value: lecturesCount, error, } = useAsync(async () => {
    const lecturesCount = (await getCountFromServer(query(collectionGroup(db, 'lectures'), where('tenantId', '==', tenantPath)))).data()?.count;
    const ignoredEvents = await getCollectionData(db.collection('events').where('tenantId', '==', tenantPath).where('isCountIgnored', '==', true));
    const ignoredLecturesCount = sum(await Promise.all(ignoredEvents.map(async (event) => {
      return (await getCountFromServer(collection(event.ref, 'lectures'))).data()?.count;
    })));
    return lecturesCount - ignoredLecturesCount;
  }, []);
  const fetchedLectures = useCollectionSubscriptionInTenant(lecturesRef);
  const lectures = (fetchedLectures || []).map(_ => ({ eventId: _.ref.parent.parent.id, ..._ }));
  const lecturesGroupedByEventId = groupBy(lectures, 'eventId');
  const eventsWithInfo = events
    .map((event) => {
      const lectures = sortBy(lecturesGroupedByEventId[event.id] || [], _ => _.date.toDate());
      return {
        ...event,
        lectures,
      };
    })
  const availableEvents = eventsWithInfo
    .filter(_ => _.lectures?.some(_ => _.date.toDate() >= startOfDay(new Date())));
  const filteredEvents = availableEvents
    .filter(_ => _.lectures.length > 0)
    .filter(_ => !prefecture || (placesGroupedByPrefecture[prefecture] || []).map(_ => _.id).includes(_.placeId));
  const sortedEvents = sortBy(filteredEvents, _ => _.lectures[0].date.toDate()).reverse();

  return (
    <div className={classnames('events h-100 py-0', { fetched: events != null })}>
      <section className="mt-5 container">
        <h4 className="h5 font-weight-bold text-center mb-3">イベント</h4>
        <div>
          <div className="text-center font-weight-bold">
            申込み受付中のイベント
            <span className="mx-1" style={{ fontSize: 40 }}>{numeral(availableEvents.length).format('0,0')}</span>
            <span>件</span>
          </div>
          <div className="text-center small text-grey">
            今までに
            <span className="ml-1" style={{ color: 'red' }}>{numeral(INITIAL_EVENTS_NUM + lecturesCount).format('0,0')}</span>
            件のイベントを開催してきました
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="font-weight-bold">
            申込み受付中のイベント
            <span className="mx-1" style={{ fontSize: 30 }}>{numeral(availableEvents.length).format('0,0')}</span>
            <span>件</span>
          </div>
          <div className="mt-3">
            <Select
              value={prefectureOptions.find(_ => _.value === prefecture) || null}
              options={prefectureOptions}
              placeholder="全国"
              onChange={_ => setPrefecture(_ && _.value)}
              isClearable
              components={{
                Control: (props) => {
                  return (
                    <div className="bg-secondary rounded-pill d-flex align-items-center justify-content-between px-3 py-2" {...props.innerProps} ref={props.innerRef}>
                      {props.children}
                    </div>
                  );
                },
                IndicatorSeparator: null,
              }}
            />
          </div>
          <div className="mt-3">
            <span className="mr-1 font-weight-bold" style={{ color: 'red' }}>
              {prefecture != null ? prefectures[prefecture] : '全国'}
            </span>
            の
            <span className="mx-1 font-weight-bold" style={{ color: 'red' }}>
              {numeral(filteredEvents.length).format('0,0')}
            </span>
            件を表示中
          </div>
        </div>
        <div className="mt-4 container">
          {
            sortedEvents.length > 0 ? (
              sortedEvents.map((event) => {
                const { id, lectures, placeId, } = event;
                const place = placesById[placeId];
                return (
                  <EventCard className="mb-4" key={id} to={`/events/${id}`} event={event} lectures={lectures} place={place} linkLabel="詳細" />
                );
              })
            ) : events != null && (
              <div className="text-grey p-3">
                該当の条件では見つかりませんでした。
                <br />
                イベント開催が決定次第、順次こちらに掲載していきます。
              </div>
            )
          }
        </div>
      </section>
    </div>
  );
});
