import {
  getConverter,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  limit as _limit,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
  CollectionReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import { useCollectionsDataOnce } from '../components/hooks/v9/useCollectionsDataOnce';
import type { InquiryCommentDocumentData, InquiryComment } from '../types';
import type { PartiallyPartial } from 'types';

export const inquiryCommentConverter = getConverter<InquiryCommentDocumentData>();

export const inquiryCommentsRef = (parent: DocumentReference) =>
  collection(parent, 'inquiryComments').withConverter(inquiryCommentConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<InquiryComment> : null;
export const inquiryCommentRef = <Id extends string | undefined>(parent: DocumentReference, inquiryCommentId: Id) =>
  (inquiryCommentId ? doc(inquiryCommentsRef(parent), inquiryCommentId) : null) as RefOrNull<Id>;

export const newInquiryCommentRef = (parent: DocumentReference) => doc(inquiryCommentsRef(parent));

export const defaultQueryConstraints = [orderBy('createdAt', 'desc')];

export const limitToLastQueryConstraints = (limit: number) => [orderBy('createdAt', 'desc'), _limit(limit)];

export const inquiryCommentsQuery = (parent: DocumentReference, ...queryConstraints: QueryConstraint[]) =>
  query(inquiryCommentsRef(parent), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantInquiryCommentsQuery = (parent: DocumentReference, tenantId: string | undefined) =>
  tenantId ? inquiryCommentsQuery(parent, ...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

type QueryOrNull<Id extends string | undefined> = Id extends string ? CollectionReference<InquiryComment> : null;
export const tenantLimitToLastInquiryCommentsQuery = <Id extends string | undefined>(
  parent: DocumentReference,
  tenantId: Id,
  limit: number
) =>
  (tenantId
    ? inquiryCommentsQuery(parent, ...tenantQueryConstraints(tenantId), ...limitToLastQueryConstraints(limit))
    : null) as QueryOrNull<Id>;

export const addInquiryComment = async (
  parent: DocumentReference,
  data: PartiallyPartial<InquiryComment, 'id' | 'createdAt'>
) => addDoc(inquiryCommentsRef(parent), { createdAt: serverTimestamp(), ...data });

export const updateInquiryComment = async (
  parent: DocumentReference,
  inquiryCommentId: string,
  data: UpdateData<InquiryComment>
) => updateDoc(inquiryCommentRef(parent, inquiryCommentId), data);

export const deleteInquiryComment = async (parent: DocumentReference, inquiryCommentId: string) =>
  deleteDoc(inquiryCommentRef(parent, inquiryCommentId));

export const useInquiryCommentCollection = useCollectionData;

export const useInquiryCommentCollectionOnce = useCollectionDataOnce;

export const useInquiryCommentCollectionsOnce = useCollectionsDataOnce;

export const useInquiryCommentDocument = useDocumentData;

export const useInquiryCommentDocumentOnce = useDocumentDataOnce;
