const { omit, keyBy, range, isEmpty } = require('lodash');
const { getYear } = require('date-fns');
const { format: formatDate, addMinutes } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');
const numeral = require('numeral');
const dedent = require('dedent');
const qs = require('qs');

const { ordererFields, destinationFields } = require('./order');
const { fieldDisplayValue } = require('../util');
const { supportMeans } = require('../config');

const { keys, entries } = Object;

const troublePoints = [
  'フレーム',
  'ハンドル',
  'グリップ',
  'ベル',
  'ブレーキ握り',
  'フロントフォーク',
  'ボルトナット',
  'ペダル',
  'クランク',
  'チェーンカバー',
  '前輪',
  '後輪',
  '前ブレーキ',
  '後ブレーキ',
  'スプロケット',
  '前後輪ナット',
  '爪付ワッシャー',
  'リムテープ',
  'リム',
  'ホイール',
  'サドル',
  'サドルクランプ',
  'ペダルシステムキャップ',
  'リア保護カバー',
  '空気入れ',
  '保護キャップ',
  'チェーン',
  '工具',
  '反射板',
  '箱',
  '説明書',
  'スタンド',
  'その他',
];
const troubleSituations = [
  '組立方法',
  '傷・剥がれ',
  '歪み',
  '塗装不良',
  '汚れ',
  '錆',
  '破損',
  '欠品',
  'サイズ違い',
  '固定不可',
  '穴ズレ',
  '入らない・取付不可',
  'チェーン脱落',
  '回転悪い',
  'チェーンカバー割れ',
  'ペダル左右同じ',
  'パンク',
  'ブレーキ片効き',
  'ブレーキきつめ',
  'レバー不良',
  '色違い',
  '異音',
  '空転',
  'リムずれ',
  'ネジ山潰れ',
  'チェーン固定してない',
  'チェーン切れ',
  '紛失',
  'その他',
];
const statuses = {
  initial: { label: '未対応', color: 'warning' },
  supporting: { label: '対応中', color: 'info' },
  awaiting: { label: '待ち', color: 'success' },
  supported: { label: '対応完了', color: 'secondary' },
};
const basicFields = ({ productTypes = [], products = [] } = {}) => {
  return {
    productTypeId: {
      label: '商品種別',
      type: 'select',
      options: productTypes.map((_) => ({ label: _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        isSearchable: false,
      },
    },
    productId: {
      label: '商品',
      type: 'select',
      options: products.filter((_) => !_.isPart).map((_) => ({ label: _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        isSearchable: false,
      },
    },
    purchaseYear: {
      label: '購入時期(年)',
      type: 'select',
      options: range(getYear(new Date()), 1999).map((_) => ({ label: `${_}年`, value: _ })),
      validations: {
        required: (_) => _ != null,
      },
      inputProps: {
        isSearchable: false,
      },
    },
    purchaseMonth: {
      label: '購入時期(月)',
      type: 'select',
      options: range(1, 13).map((_) => ({ label: `${_}月頃`, value: _ })),
      validations: {
        required: (_) => _ != null,
      },
      inputProps: {
        isSearchable: false,
      },
    },
    /*
    purchaseShop: {
      label: '購入店',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
      hint: 'へんしんバイク公式サイト・店舗　都道府県　〇〇自転車店　チェーン名　空白・アルペン・ゼビオ、etc',
    },
    serialNumber: {
      label: 'シリアルナンバー',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    poNumber: {
      label: 'バックフォークナンバー',
      type: 'string',
      validations: {
        required: (_) => !isEmpty(_),
      },
      hint: 'リアフォーク内側にある番号',
    },
    troublePoints: {
      label: '問合せ箇所',
      type: 'multiSelect',
      options: troublePoints.map((_) => ({ label: _, value: _ })),
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        isSearchable: false,
      },
      hint: '不具合のある場所を教えて下さい（複数選択可）',
    },
    troubleSituations: {
      label: '症状',
      type: 'multiSelect',
      options: troubleSituations.map((_) => ({ label: _, value: _ })),
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        isSearchable: false,
      },
      hint: '不具合の内容について教えて下さい（複数選択可）',
    },
    files: {
      label: 'ファイル',
      type: 'files',
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        accept: 'image/*,video/*',
      },
      hint: '症状のわかる画像や動画をアップロードして下さい。複数選択可\n動きや音に関しては、動画のアップロードをお願いします。',
    },
    description: {
      label: '詳細情報',
      type: 'text',
      hint: '症状について、教えて下さい。いつから、どのような症状が起きていますか？',
    },
    */
  };
};

const passwordFields = () => {
  return {
    password: {
      type: 'password',
      label: 'パスワード',
      validations: {
        required: v => !isEmpty(v),
        minLength: v => (v || '').length >= 6,
      },
      placeholder: '6文字以上'
    }
  }
}

const activityTypes = {
  startSupportOfTroubleInquiry: {
    text: (_) => '対応開始',
  },
  editTroubleInquiry: {
    text: (_) => 'パーツ編集',
  },
  finishSupportOfTroubleInquiry: {
    text: (_) => '対応完了',
  },
  unfinishSupportOfTroubleInquiry: {
    text: (_) => '対応再開',
  },
  createOrderOfTroubleInquiry: {
    text: (_) => '発送',
  },
  startReimbursementOfTroubleInquiry: {
    text: (_) => '立替受付開始',
  },
  finishReimbursementOfTroubleInquiry: {
    text: (_) => '立替完了',
  },
  cancelReimbursementOfTroubleInquiry: {
    text: (_) => '立替受付停止',
  },
  requestDiscountSourceOrderOfTroubleInquiry: {
    text: (_) => '割引申請',
  },
  rejectDiscountSourceOrderOfTroubleInquiry: {
    text: (_) => '割引否認',
  },
  failedDiscountSourceOrderOfTroubleInquiry: {
    text: (_) => '割引失敗',
  },
  discountSourceOrderOfTroubleInquiry: {
    text: (_) => '割引返金',
  },
  updatePicOfTroubleInquiry: {
    text: (_) => '担当変更',
  },
  requestedApprovalOfTroubleInquiry: {
    text: (_) => '承認申請',
  },
  approvedTroubleInquiry: {
    text: (_) => '承認',
  },
  rejectedTroubleInquiry: {
    text: (_) => '否認',
  },
  editDestinationOfTroubleInquiry: {
    text: (_) => '送り先変更',
  },
  sendTroubleInquiryEmail: {
    text: (_) => 'メール送信',
  },
};

const itemFields = ({ products = [] } = {}) => {
  const productsById = keyBy(products, 'id');
  return {
    productId: {
      label: '商品',
      type: 'select',
      options: products.map((_) => ({ label: _.label || _.name, value: _.id })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    quantity: {
      label: '数量',
      type: 'integer',
      validations: {
        greaterThan0: (_) => _ > 0,
      },
    },
  };
};

const approvalRequestFields = () => {
  return {
    approvalOrRejectionComment: {
      label: '承認/否認コメント',
      type: 'text',
      readOnly: () => true,
    },
    approvalRequestComment: {
      label: '承認申請コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const approvalFields = () => {
  return {
    approvalRequestComment: {
      label: '承認申請コメント',
      type: 'text',
      readOnly: () => true,
    },
    approvalOrRejectionComment: {
      label: '承認/否認コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const fields = (...args) => {
  return {
    ...basicFields(...args),
    ...ordererFields(...args),
    ...destinationFields(...args),
    ...passwordFields(),
    destinationType: {
      type: 'string',
      initialValue: 'same',
    },
  };
};

const shipmentFields = () => {
  return {
    shipmentMessage: {
      type: 'text',
      label: '発送メールでのユーザーへのメッセージ',
      rows: 6,
    },
    shipmentPackageNote: {
      type: 'text',
      label: '発送時の梱包指示備考',
      hint: '最大111文字までで指定してください',
      validations: {
        maxLength: v => (v || '').length <= 111,
      },
    },
  };
};

const commentFields = () => {
  return {
    body: {
      type: 'text',
      label: 'コメント内容',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const reimbursementRequestFields = () => {
  return {
    amount: {
      label: '立替金額',
      type: 'integer',
      validations: {
        required: (_) => _,
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
    },
    receiptFiles: {
      label: '領収書',
      type: 'files',
      validations: {
        required: (_) => !isEmpty(_),
      },
      inputProps: {
        accept: 'image/*',
      },
      hint: '領収書の画像をアップロードして下さい。複数アップロード可\n',
    },
    description: {
      label: '連絡事項',
      type: 'text',
    },
  };
};

const reimbursementResultFields = () => {
  return {
    date: {
      label: '立替工賃 - 支払日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
    },
    amount: {
      label: '立替工賃 - 支払金額',
      type: 'integer',
      validations: {
        required: (_) => _,
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
      showsTextInput: true,
    },
    note: {
      label: '立替工賃 - 備考',
      type: 'text',
    },
  };
};

const discountRequestFields = () => {
  return {
    amount: {
      label: '割引 - 返金金額',
      type: 'integer',
      validations: {
        required: (_) => _,
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
      showsTextInput: true,
    },
    note: {
      label: '割引 - 備考',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    approvalOrRejectionComment: {
      label: '割引 - 承認/否認コメント',
      type: 'text',
      readOnly: () => true,
    },
  };
};

const discountApprovalFields = () => {
  return {
    amount: {
      label: '割引 - 返金金額',
      type: 'integer',
      showsTextInput: true,
      readOnly: () => true,
    },
    note: {
      label: '割引 - 備考',
      type: 'text',
      readOnly: () => true,
    },
    approvalOrRejectionComment: {
      label: '割引 - 承認/否認コメント',
      type: 'text',
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
  };
};

const discountFields = () => {
  return {
    date: {
      label: '割引 - 返金日',
      type: 'date',
      validations: {
        required: (_) => _,
      },
    },
    amount: {
      label: '割引 - 返金金額',
      type: 'integer',
      validations: {
        required: (_) => _,
        greaterThanOrEqualTo0: (v) => v != null && v >= 0,
      },
      showsTextInput: true,
    },
    note: {
      label: '割引 - 備考',
      type: 'text',
    },
  };
};

const supportContentsFields = ({ surveys = [] } = {}) => {
  return {
    supportMeans: {
      label: '対応方法',
      type: 'multiSelect',
      options: entries(supportMeans).map(([key, value]) => ({ label: value, value: key })),
      validations: {
        required: (_) => !isEmpty(_),
      },
    },
    supportSummary: {
      label: '対応内容',
      type: 'text',
      validations: {
        required: (_) => _,
      },
    },
    supportSurvey: {
      label: '対応完了後アンケート',
      type: 'select',
      options: surveys.map(_ => ({ label: _.title, value: _.id })),
      initialValue: '',
    },
    supportSurveySentDate: {
      label: '送信日時',
      type: 'datetime',
      validations: {
        laterThanNow: v => v > new Date()
      },
      hidden: _ => !_.supportSurvey,
    }
  };
};

const emailFields = () => {
  return {
    body: {
      label: 'メール本文',
      type: 'text',
    },
    files: {
      label: '添付ファイル',
      type: 'files',
    },
  };
};

const confirmMailBody = (troubleInquiry, products, productTypes, questions, host) => {
  const {
    id,
    items,
    createdBy: { email, displayName },
    createdAt,
  } = troubleInquiry;
  const productsById = keyBy(products, 'id');
  return dedent`
    ご不便をおかけしまして、大変申し訳ございません。
    下記の不具合・組立お問合せを受付しました。

    [問合せ番号] ${id}

    [パーツ]
    ${items
      .map(({ productId, quantity }) => {
        const { name: productName } = productsById[productId];
        return `${productName} ${quantity}個`;
      })
      .join('\n')}
    [日時] ${formatDate(utcToZonedTime(createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}

    ---------- 不具合情報 ----------

    ${entries(omit(basicFields({ productTypes, products }), ['files', 'destinationType']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}
      ${questions.map((question) => {
        const { id, description, type, } = question;
        const value = troubleInquiry.answers?.[id];
        return `[${description}] ${({
          text: _ => value,
          checkbox: _ => keys(value || {}).join(', '),
          radio: _ => value,
          select: _ => value,
          imageFile: _ => value?.map?.((_) => _.name).join(', '),
        })[type]() || ''}`;
      }).join('\n')}

    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    ---------- お届け先情報 ----------

    ${entries(destinationFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

const shipmentMailBody = (troubleInquiry, products, productTypes, host) => {
  const {
    id,
    items,
    createdBy: { email, displayName },
    createdAt,
    shipmentMessage = '',
    orderId = '',
  } = troubleInquiry;
  const productsById = keyBy(products, 'id');
  const productOrPartText = items.some(({ productId }) => productsById[productId]?.isBody) ? '商品' : 'パーツ';

  return dedent`
    ${troubleInquiry.name} 様

    商品不具合のお問合せ内容が確認できました。
    新しい${productOrPartText}をお送りさせて頂きます。

    ${shipmentMessage}

    [問合せ番号] ${id}

    ---------- 発送${productOrPartText} ----------

    [注文番号] ${orderId}

    [${productOrPartText}]
    ${items
      .map(({ productId, quantity }) => {
        const { name: productName } = productsById[productId];
        return `${productName} ${quantity}個`;
      })
      .join('\n')}

    ---------- お届け先情報 ----------

    ${entries(destinationFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    ---------- 不具合情報 ----------

    ${entries(omit(basicFields({ productTypes, products }), ['files', 'destinationType']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

const reimbursementRequestMailBody = (troubleInquiry, host) => {
  const {
    id,
    name,
    reimbursementRequest: { amount, description, requestedAt, requestedBy },
  } = troubleInquiry;

  return dedent`
    ${name} 様

    立替工賃の申請を受付ました。

    [問合せ番号] ${id}

    ---------- 申請内容 ----------

    [申請金額] ${numeral(amount).format('0,0')}円
    [申請日時] ${formatDate(utcToZonedTime(requestedAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}
    [申請者] ${requestedBy.displayName} (${requestedBy.email})
    [連絡事項]
    ${description}
    
    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    お問合せはこちらのマイページよりお願いします。
    ${host || '{host}'}/mypage/orders
  `;
};

const supportSurveyMailBody = (troubleInquiry, url) => {
  const { name, createdBy } = troubleInquiry;
  return dedent`
    ${createdBy?.displayName || name} 様

    この度は不具合お問合せありがとうございました。

    宜しければ以下のURLからアンケートへのご回答をお願いします。

    ${url}
  `;
}

const replyEmailBody = (troubleInquiry, body, products, productTypes, questions, host) => {
  const { id, name, items, createdAt, createdBy } = troubleInquiry;
  const productsById = keyBy(products, 'id');
  return dedent`
    ${createdBy?.displayName || name} 様

    お問合せへのメッセージが届いています。
    以下のURLからご確認ください。

    ${host || '{host}'}/mypage/troubleInquiries?messagesTroubleInquiryId=${troubleInquiry.id}



    [問合せ番号] ${id}

    [パーツ]
    ${items
      .map(({ productId, quantity }) => {
        const { name: productName } = productsById[productId];
        return `${productName} ${quantity}個`;
      })
      .join('\n')}
    [日時] ${formatDate(utcToZonedTime(createdAt.toDate(), 'Asia/Tokyo'), 'yyyy/MM/dd HH:mm:ss')}

    ---------- 不具合情報 ----------

    ${entries(omit(basicFields({ productTypes, products }), ['files', 'destinationType']))
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}
      ${questions.map((question) => {
        const { id, description, type, } = question;
        const value = troubleInquiry.answers?.[id];
        return `[${description}] ${({
          text: _ => value,
          checkbox: _ => keys(value || {}).join(', '),
          radio: _ => value,
          select: _ => value,
          imageFile: _ => value?.map?.((_) => _.name).join(', '),
        })[type]() || null}`;
      }).join('\n')}

    ---------- お客様情報 ----------

    ${entries(ordererFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}

    ---------- お届け先情報 ----------

    ${entries(destinationFields())
      .map(([fieldName, fieldSettings]) => {
        const { label } = fieldSettings;
        return `[${label}] ${fieldDisplayValue(troubleInquiry[fieldName], fieldSettings)}`;
      })
      .join('\n')}


    154-0013 東京都世田谷区駒沢公園１－１ Tote駒沢公園２階
    株式会社ビタミンiファクトリー
  `;
}

module.exports = {
  basicFields,
  ordererFields,
  destinationFields,
  passwordFields,
  fields,
  itemFields,
  approvalRequestFields,
  approvalFields,
  shipmentFields,
  statuses,
  commentFields,
  activityTypes,
  confirmMailBody,
  shipmentMailBody,
  reimbursementRequestFields,
  reimbursementResultFields,
  reimbursementRequestMailBody,
  discountRequestFields,
  discountApprovalFields,
  discountFields,
  supportContentsFields,
  supportSurveyMailBody,
  emailFields,
  replyEmailBody,
};
