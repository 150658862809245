import React, { useEffect, Fragment, } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { useToggle } from 'react-use';
import { sortBy, isEmpty, keyBy, get, omitBy, isUndefined, pick, } from 'lodash';
import numeral from 'numeral';
import classnames from 'classnames';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import { batch, } from '../../shared/firebase';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import AdminPage from '../hocs/AdminPage';
import QueryBoolean from '../QueryBoolean';

const { keys } = Object;
const db = firebase.firestore();
const COLUMN_WIDTH = 120;

export default AdminPage(function AdminContentProductsAgents (props) {
  const { user, } = props;
  const queryParams = useQueryParams();
  const contentProducts = sortBy(useCollectionSubscriptionInTenant(db.collection('contentProducts')), 'code');
  const agents = sortBy(useCollectionSubscriptionInTenant(db.collection('agents')), _ => _.createdAt.toDate());
  const filteredAgents = queryParams.showsHidden === '1' ? agents : agents.filter(_ => !_.isHidden);
  const agentContentProductSettings = useCollectionSubscriptionInTenant(db.collectionGroup('agentContentProductSettings'));
  const agentContentProductSettingsByAgentIdAndContentProductId = keyBy(agentContentProductSettings, _ => [_.ref.parent.parent.id, _.id].join('--'));
  const filteredContentProducts = contentProducts;
  const importRows = async (rows) => {
    const items = rows.filter(_ => _.contentProductId).flatMap((row) => {
      return agents.map((agent) => {
        return {
          agent,
          contentProductId: row.contentProductId,
          isShownForRental: !!row[agent.name]?.split(',').includes('レンタル表示'),
        };
      });
    });
    await batch(db, items, (batch, item) => {
      const { agent, contentProductId, isShownForRental, } = item;
      const agentContentProductSetting = agentContentProductSettingsByAgentIdAndContentProductId[[agent.id, contentProductId].join('--')];
      batch.set(agent.ref.collection('agentContentProductSettings').doc(contentProductId), {
        tenantId: user.tenantId,
        isShownForRental,
        [agentContentProductSetting ? 'updatedBy' : 'createdBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      }, { merge: true });
    });
  };
  const rowsForExport = () => {
    return filteredContentProducts.map((contentProduct) => {
      const { id, ref, name, code, } = contentProduct;
      return {
        contentProductId: id,
        contentProductCode: code,
        contentProductName: name,
        ...(
          filteredAgents.reduce((x, agent) => {
            const { id, name } = agent;
            const agentContentProductSetting = agentContentProductSettingsByAgentIdAndContentProductId[[id, contentProduct.id].join('--')];
            return {
              ...x,
              [agent.name]: [agentContentProductSetting?.isShownForRental && 'レンタル表示'].filter(_ => _).join(','),
            };
          }, {})
        ),
      };
    });
  };

  return (
    <div className="small">
      <div className="admin-content-products-agents container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center mb-3">
                コンテンツ商品 × 代理店管理
              </h5>
              <div className="d-flex justify-content-end mb-3 gap-1 align-items-end">
                <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
                <ImportButton processRows={importRows} />
                <ExportButton fileName={`コンテンツ商品_代理店.csv`} rows={rowsForExport} />
              </div>
              <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
                <table className="table table-bordered sticky-table m-0">
                  <thead className="thead-light text-center">
                    <tr>
                      <th style={{ minWidth: 230 }} className="sticky">商品</th>
                      {
                        filteredAgents.map((agent) => {
                          const { id, name } = agent;
                          return (
                            <th colSpan={1} key={id} style={{ minWidth: COLUMN_WIDTH * 2 }}>
                              {name}
                            </th>
                          );
                        })
                      }
                    </tr>
                  </thead>
                  <tbody className="thead-light">
                    {
                      filteredContentProducts.map((contentProduct) => {
                        const { id, ref, name, code, } = contentProduct;
                        return (
                          <tr key={id}>
                            <th className="sticky">
                              <div>
                                [{code}] {name}
                              </div>
                              <div>
                                {contentProduct.isHidden && <div className="badge badge-secondary">非表示</div>}
                              </div>
                            </th>
                            {
                              filteredAgents.map((agent) => {
                                const { id, name } = agent;
                                const agentContentProductSetting = agentContentProductSettingsByAgentIdAndContentProductId[[id, contentProduct.id].join('--')];
                                const ref = agent.ref.collection('agentContentProductSettings').doc(contentProduct.id);
                                const isConclusivelyShownForRental = agentContentProductSetting?.isShownForRental;
                                const onChange = (fieldName, value) => {
                                  ref.set({
                                    tenantId: user.tenantId,
                                    [fieldName]: !value,
                                    [agentContentProductSetting ? 'updatedBy' : 'createdBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
                                  }, { merge: true });
                                };
                                return (
                                  <Fragment>
                                    <td key={id} className={classnames({ 'bg-grey': !isConclusivelyShownForRental })}>
                                      {
                                        [['isShownForRental', 'レンタル表示']].map(([fieldName, label]) => {
                                          const value = !!agentContentProductSetting?.[fieldName];

                                          return (
                                            <FormGroup check key={fieldName}>
                                              <Label check>
                                                <Input type="checkbox" checked={value} onChange={onChange.bind(null, fieldName, value)} />
                                                {label}
                                              </Label>
                                            </FormGroup>
                                          );
                                        })
                                      }
                                    </td>
                                  </Fragment>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
