const { isObject, isString, isArray, uniqBy, orderBy, invert, omit, pickBy, flatten, isDate, isEmpty, get, } = require('lodash');
const numeral = require('numeral');

const isNumeric = value => !Number.isNaN(parseFloat(value, 10));

const { entries, keys,  } = Object;

const ruleTypes = {
  surveyAnswer: {
    label: 'アンケート回答',
    hasRuleRows: true,
  },
  entry: {
    label: 'イベント参加',
  },
};
const valueTypes = {
  multiSelect: {
    formatForCsv: _ => _.join(','),
    parseForCsv: _ => _.split(',').map(_ => isNumeric(_) ? numeral(_).value() : _),
  },
  integer: {
    formatForCsv: _ => _,
    parseForCsv: _ => numeral(_).value(),
  },
  float: {
    formatForCsv: _ => _,
    parseForCsv: _ => numeral(_).value(),
  },
  string: {
    formatForCsv: _ => _.join(','),
    parseForCsv: _ => _.split(',').map(_ => isNumeric(_) ? numeral(_).value() : _),
  },
};
const equalityOperators = {
  someEquals: {
    label: '一致する',
    operator: (s, v) => v.map(_ => _.toString()).includes(s?.toString()),
    type: 'some',
  },
  everyUnequals: {
    label: '一致しない',
    operator: (s, v) => !v.map(_ => _.toString()).includes(s?.toString()),
    type: 'every',
  },
};
const inclusionOperators = {
  includes: {
    label: '含む',
    operator: (s, v) => s.includes(v),
    type: 'some',
  },
  excludes: {
    label: '含まない',
    operator: (s, v) => !s.includes(v),
    type: 'every',
  },
};
const numberOperators = {
  greaterThan: {
    label: 'より大きい',
    operator: (s, v) => s > v,
    type: 'some',
  },
  greaterThanOrEquals: {
    label: '以上',
    operator: (s, v) => s >= v,
    type: 'some',
  },
  lessThan: {
    label: 'より小さい',
    operator: (s, v) => s < v,
    type: 'some',
  },
  lessThanOrEquals: {
    label: '以下',
    operator: (s, v) => s <= v,
    type: 'some',
  },
  equals: {
    label: '同じ',
    operator: (s, v) => s === v,
    type: 'some',
  },
  otherThan: {
    label: '以外',
    operator: (s, v) => s !== v,
    type: 'some',
  },
};
const operators = {
  ...equalityOperators,
  ...inclusionOperators,
  ...numberOperators,
};

module.exports = {
  fields: ({ events, userTags, onCreateUserTag, } = {}) => {
    return {
      ruleType: {
        type: 'select',
        label: 'ルール種別',
        options: entries(ruleTypes).map(([k, { label }]) => ({ label, value: k })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      conditionEventIds: {
        label: '申込イベント',
        type: 'multiSelect',
        options: events.map(_ => ({ label: _.name, value: _.id })),
        hidden: _ => _.ruleType !== 'entry',
        hint: '指定しない場合はすべてのイベントが対象になります',
      },
      name: {
        type: 'string',
        label: 'ルール名',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      userTagIds: {
        label: '追加ユーザータグ',
        type: 'creatableMultiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
        onCreateMultiSelectValue: onCreateUserTag,
      },
      userTagIdsToRemove: {
        label: '除去ユーザータグ',
        type: 'multiSelect',
        options: userTags.map(_ => ({ label: _.name, value: _.id })),
      },
    };
  },
  rowFields: ({ subjects, } = {}) => {
    return {
      subject: {
        type: 'select',
        options: subjects,
        validations: {
          required: v => !isEmpty(v),
        },
      },
      operator: {
        type: 'select',
        options: [],
        validations: {
          required: v => !isEmpty(v),
        },
      },
      value: {
        validations: {
          required: v => v != null && (v.length != null ? !isEmpty(v) : true),
        },
      },
    };
  },
  ruleTypes,
  equalityOperators,
  inclusionOperators,
  numberOperators,
  operators,
  valueTypes,
};
