import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { sortBy, keyBy, omit } from 'lodash';
import { endOfDay } from 'date-fns';
import qs from 'qs';

import firebase from '../../firebase';
import { fullPathWithParams } from '../../util';
import useAppTitle from '../hooks/useAppTitle';
import useLocale from '../hooks/useLocale';
import TmpEntryForm from '../forms/TmpEntryForm';
import TenantUserPage from '../hocs/TenantUserPage';
import EventCard from '../EventCard';
import EntryflowSection from '../EntryflowSection';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import useTenant from '../hooks/useTenant';
import { auditData } from '../../shared/models/user';
import { getCollectionData } from '../../shared/firebase';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const placesRef = db.collection('places');
const entriesRef = db.collectionGroup('entries');

function TmpEntry(props) {
  const {
    firebaseUser,
    user,
    history,
    location,
    match: {
      params: { eventId },
    },
    toggleLoginForm,
    setLang,
    translate,
  } = props;
  const queryParams = useQueryParams();
  const tenant = useTenant();
  const event = useDocumentSubscription(eventsRef.doc(eventId), [eventId]);
  const openLoginForm = _ => {
    toggleLoginForm(true);
  };
  const [restoredFrames, setRestoredFrames] = useState();
  const place = useDocumentSubscription(event && placesRef.doc(event.placeId), [event]);
  const lectures = useCollectionSubscriptionInTenant(eventsRef.doc(eventId).collection('lectures').orderBy('date'), [
    eventId,
  ]);
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');
  const initialUserEntries = useCollectionFetchInTenant(user && entriesRef.where('createdBy.uid', '==', user.id), [
    user,
  ]);
  const userTmpEntries = initialUserEntries.filter((_) => _.isTemporary);
  const userEntries = useCollectionSubscriptionInTenant(user && entriesRef.where('createdBy.uid', '==', user.id), [
    user,
  ]);
  const entryFrames = userEntries
    .filter((_) => _.abortedAt == null && _.cancelledAt == null)
    .map((_) => _.frames)
    .flat();
  const userRelatedLectures = useDocumentsFetch(
    entryFrames.map((_) => _.lectureRef),
    [userEntries]
  );
  const userRelatedLecturesById = keyBy(userRelatedLectures, 'id');
  const activeEntryFrames = entryFrames.filter(({ lectureId }) => {
    const lecture = userRelatedLecturesById[lectureId];
    return lecture ? endOfDay(lecture.date.toDate()) >= new Date() : false;
  });
  const cancellationNotices = useCollectionSubscriptionInTenant(user && eventsRef.doc(eventId).collection('cancellationNotices').where("createdBy.uid", "==", user.id), [eventId, user]);
  const onSubmit = async (values) => {
    try {
      const tmpEntries = await getCollectionData(entriesRef.where('createdBy.uid', '==', user.id));
      if (tmpEntries.some(_ => _.isTemporary && _.ref.parent.parent.id !== eventId)) {
        toast.error(translate('異なるイベントが申し込み途中です。'));
        return;
      }
      const [userTmpEntry] = userTmpEntries;
      const ref = userTmpEntry?.ref || eventsRef.doc(eventId).collection('entries').doc();
      await ref.set({
        tenantId: event.tenantId,
        ...omit(values, ['id', 'ref']),
        createdBy: omit(user, 'id'),
        createdAt: new Date(),
        isTemporary: true,
        cancelledAt: null,
      });
      history.push(`/${event.tenantId}/events/${eventId}/entries/${ref.id}?${qs.stringify(queryParams)}`);
    } catch (e) {
      toast.error(translate('失敗しました'));
      console.error(e);
    }
  };
  useEffect(() => {
    (async () => {
      const [entryToRestore] = userTmpEntries;
      if (entryToRestore != null) {
        setRestoredFrames(entryToRestore.frames);
      }
    })();
  }, [initialUserEntries]);
  useEffect(() => {
    // NOTE: 未ログインユーザーの判定
    if (firebaseUser === null) {
      history.replace(fullPathWithParams({ isNewUser: 1 }, location));
    }
    if (firebaseUser === null && queryParams.verifiedEmail === '1') {
      openLoginForm();
    }
  }, [firebaseUser]);
  useEffect(() => {
    event?.lang && setLang(event.lang);
  }, [event?.lang]);
  useAppTitle(event?.name + ' 参加の申込');
  const isSignedUp = queryParams.isSignedUp === '1'

  const onClickNotifyCancell = async (event, lectureId, user) => {
    const ref = event.ref.collection('cancellationNotices');
    const notice = cancellationNotices.find(_ => _.lectureId === lectureId)
    if (notice) {
      if (!window.confirm(translate(`通知の受け取りをキャンセルしますか？`))) return;
      await notice.ref.delete();
    } else {
      if (!window.confirm(translate(`イベントに空きが出た場合通知を受け取りますか？`))) return;
      await ref.add({ tenantId: tenant.id, lectureId, createdBy: auditData(user), createdAt: new Date() });
    }
  }

  return (
    event != null && translate(
      <div className="entry position-relative">
        <EntryflowSection activeIndex={0} />
        <section className="container mt-5">
          {queryParams.isNewUser === '1' && firebaseUser != null && (
            <div className="card border-danger text-danger p-3 text-center font-weight-bold">
              まだイベントの申込みは完了していません。
              <br />
              時間枠を選択し、申込みを続けてください。
            </div>
          )}
          <div className="my-5">
            <EventCard event={event} lectures={lectures} place={place} {...{ eventProductTypesById, lectureTypesById, }} />
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 offset-lg-2">
              <TmpEntryForm
                eventId={eventId}
                event={event}
                lectures={lectures}
                eventProductTypesById={eventProductTypesById}
                lectureTypesById={lectureTypesById}
                onSubmit={onSubmit}
                activeEntryFrames={activeEntryFrames}
                showsLoginButton={user == null}
                onClickLogin={openLoginForm}
                restoredFrames={restoredFrames}
                user={user}
                cancellationNotices={cancellationNotices}
                onClickNotifyCancell={onClickNotifyCancell}
                userTmpEntries={userTmpEntries}
              />
            </div>
          </div>
        </section>
        {
          isSignedUp && <ModalButton title="アカウントが作成されました" content={() => (
            <div className='text-center mb-3'>
              メールを確認してください。
              <br />
              リンクを押すと、申込画面に進むことができます。
            </div>
            )} isInitiallyOpen hidden />
        }
      </div>
    )
  );
}

TmpEntry.preview = true;

export default TenantUserPage(TmpEntry);
