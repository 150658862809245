import React from 'react';
import { isFunction } from 'lodash';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { useToggle, } from 'react-use';

export default function ModalButton (props) {
  const { Component = Button, label = '開く', content, children, modalProps, isInitiallyOpen = false, hidesHeader = false, hidesFooter = false, ...extraProps } = props;
  const title = props.title || label || children;
  const [showsModal, toggleModal] = useToggle(isInitiallyOpen);
  const _modalProps = isFunction(modalProps) ? modalProps({ toggleModal }) : modalProps;

  return (
    <Component onClick={toggleModal.bind(null, true)} {...extraProps}>
      {children || label}
      {
        showsModal && (
          props.Modal ? (
            <props.Modal isOpen onClickClose={toggleModal.bind(null, false)} {..._modalProps} />
          ) : (
            <Modal isOpen {..._modalProps}>
              {
                !hidesHeader && (
                  <ModalHeader>
                    {title}
                  </ModalHeader>
                )
              }
              <ModalBody>
                {content != null && content({ showsModal, toggleModal })}
              </ModalBody>
              {
                !hidesFooter && (
                  <ModalFooter>
                    <Button className="cancel" onClick={toggleModal.bind(null, false)}>閉じる</Button>
                  </ModalFooter>
                )
              }
            </Modal>
          )
        )
      }
    </Component>
  );
};

