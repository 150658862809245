const { isEmpty } = require('lodash');

const referralFeeTypes = {
  rate: {
    label: '紹介料(率)',
  },
  amount: {
    label: '紹介料(税込金額)',
  },
};

module.exports = {
  fields: () => {
    return {
      isShownForRental: {
        label: 'レンタルカートで表示',
        type: 'boolean',
        initialValue: false,
      },
      price: {
        label: '価格',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
        initialValue: null,
        showsTextInput: true,
        hint: '空ならコンテンツ商品マスタの価格',
      },
    };
  },
};

