import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';

import firebase, { signInWithGoogle, onAuthStateChanged, signOut, signInAnonymously, onSnapshot } from '../../firebase';
import { userRef, setUser } from '../../models/user';
import HeaderNav from '../HeaderNav';
import env from '../../env';
import TenantPage from './TenantPage';
import BreadNav from '../BreadNav';
import AdminHelp from '../AdminHelp';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();

export default function AdminPageHOC(WrappedComponent) {
  return TenantPage(class AdminPage extends Component {
    constructor() {
      super();
      this.state = {};
    }
    checkUser() {
      const { user } = this.state;
      if(!['admin', 'staff'].includes(user.role)) toast.error('Not authorized');
    }
    componentWillMount() {
      if(process.env.REACT_APP_ENV === 'test') signInAnonymously();
      onAuthStateChanged((firebaseUser) => {
        if (!firebaseUser || (
          process.env.REACT_APP_ENV === 'staging' && !(env('EMAIL_WHITE_LIST') || '').split(',').some(_ => firebaseUser.email.match(_))
        )) {
          signOut();
          this.setState({ uid: null, email: null, displayName: null });
          this.openLoginModal();
          return;
        }
        if(process.env.REACT_APP_ENV === 'test') window.firebaseUser = firebaseUser;
        const { email, uid, displayName } = firebaseUser;
        this.setState({ uid, email, displayName });
      });
    }
    openLoginModal() {
      this.setState({ shouldShowLoginForm: true });
    }
    async onSetUid() {
      await this.updateUser();
      this.listenUser();
    }
    listenUser() {
      const { uid } = this.state;
      onSnapshot(userRef(uid), _ => this.setState({ user: _.data() }));
    }
    async updateUser() {
      const { uid, email, displayName } = this.state;
      await setUser(uid, { uid, email, displayName }, { merge: true });
    }
    componentDidUpdate(prevProps, prevState) {
      if (this.state.uid && !prevState.uid) {
        this.onSetUid();
      }
      if(!prevState.user && this.state.user) {
        this.checkUser();
      }
    }
    addBreadNavValues = (values) => this.setState({ breadNavValues: values })
    render() {
      const { match: { path, params }, showsHeader = true } = this.props;
      const { user, breadNavValues = {}, shouldShowLoginForm = false } = this.state;
      const helpKey = [params.tenantPath, encodeURIComponent(path)].join('__');
      return user ? (
        ['admin', 'staff'].includes(user.role) && (
          <div className="admin-page d-flex flex-column pb-5" style={{ backgroundColor: '#f3f3f3', minHeight: '100vh' }}>
            {showsHeader && <HeaderNav admin {...this.props} {...{ user }} />}
            {showsHeader && <BreadNav user={user} currentPath={path} currentParams={params} values={{ ...breadNavValues }} prefixContent={_ => <AdminHelp helpKey={helpKey} user={user} />} />}
            {showsHeader && <HelpPreview helpKey={helpKey} />}
            <WrappedComponent {...{ ...this.props, user, addBreadNavValues: this.addBreadNavValues  }} />
          </div>
        )
      ) : (
        shouldShowLoginForm && (
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div style={{ fontSize: 80 }}>
              VSM Admin
            </div>
            <div>
              <Button onClick={signInWithGoogle} block>
                Googleでログイン
              </Button>
              <Button tag={TenantLink} to="/login" block>
                メールアドレスでログイン
              </Button>
            </div>
          </div>
        )
      );
    }
  });
};

function HelpPreview (props) {
  const { helpKey } = props;
  const help = useDocumentSubscription(db.collection('helps').doc(helpKey), [helpKey]);
  return !isEmpty(help?.body) && (
    ({
      tip: _ => (
        <div className="d-flex justify-content-end position-relative">
          <div className="position-absolute border alert alert-info p-2 overflow-hidden" style={{ right: 30, top: -15, width: 300, height: 36, opacity: 0.5, zIndex: 1, }}>
            <RichTextContent html={help?.body} />
          </div>
        </div>
      ),
      alert: _ => (
        <div className="alert alert-info overflow-auto" style={{ maxHeight: 130, }}>
          <RichTextContent html={help?.body} />
        </div>
      ),
    })[help.displayType ?? 'tip']?.()
  );
}
