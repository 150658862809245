import { omitBy, isUndefined, pick } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useToggle } from 'react-use';

import useFirebaseUser from '../hooks/useFirebaseUser';
import { updateDoc, deleteDoc, DocumentData, DocumentReference } from '../../firebase';
import type { WithId } from '../../types';

export const DeleteButton = ({
  item,
  itemRef,
  beforeDelete = async (_) => true,
  onFinish = null,
  disabled,
  ...extraProps
}: {
  item?: WithId<DocumentData>;
  itemRef: DocumentReference;
  beforeDelete?: (values: any) => Promise<boolean>;
  onFinish?: (() => Promise<void>) | null;
  disabled?: boolean;
  className?: string;
}) => {
  const { firebaseUser } = useFirebaseUser();
  const [isDeleting, toggleDeleting] = useToggle(false);
  const onClickDelete = async (props) => {
    if (!window.confirm('本当に削除しますか？')) return;

    toggleDeleting(true);
    if ((await beforeDelete(item)) === false) {
      return toggleDeleting(false);
    }

    // NOTE: batchにすると、順番が担保されなかった
    await updateDoc(itemRef, { deletedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined) });
    await deleteDoc(itemRef);
    onFinish && (await onFinish());
    toast.success('削除しました');
    toggleDeleting(false);
  };

  return (
    <Button
      color="danger"
      aria-label="delete-button"
      onClick={onClickDelete}
      disabled={disabled || isDeleting}
      {...extraProps}
    >
      <span className="fas fa-trash mr-1" />
      削除
    </Button>
  );
};
