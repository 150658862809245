import React from 'react';
import { toast } from 'react-toastify';
import { orderBy, mapValues, sumBy, get, groupBy, pick } from 'lodash';
import numeral from 'numeral';

import firebase from '../../firebase';
import { canUpdateUser } from '../../shared/abilities';
import { roles } from '../../shared/config';
import { adminFields } from '../../shared/models/user';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const orderStatsRef = db.collection('orderStats');
const productsRef = db.collection('products');
const { entries } = Object;

export default AdminPage(function AdminMultiOrdersUsers(props) {
  const orderStats = useCollectionSubscriptionInTenant(orderStatsRef);
  const products = useCollectionSubscriptionInTenant(productsRef);
  const rows = Object.values(
    groupBy(
      orderStats.flatMap((_) => _.data),
      'user.uid'
    )
  )
    .map((_) => ({
      user: _[0].user,
      data: _,
      count: _.length,
      quantity: sumBy(_, (_) =>
        sumBy(
          _.orderItems.filter(({ productId }) => products.filter((_) => _.isBody).some((_) => _.id === productId)),
          'quantity'
        )
      ),
    }))
    .filter((_) => _.quantity >= 2);
  const sortedRows = orderBy(rows, 'quantity', 'desc');

  return (
    <div>
      <div className='admin-multi-orders-users container py-5 position-relative'>
        <div className='d-flex justify-content-center mb-3'>
          <h4>複数注文アカウント管理</h4>
        </div>
        <div className='d-flex justify-content-end mb-3'></div>
        <div>
          {sortedRows.length > 0 ? (
            <table className='table'>
              <thead className='thead-light text-center'>
                <tr>
                  <th>アカウント名</th>
                  <th>注文回数</th>
                  <th>注文個数</th>
                </tr>
              </thead>
              <tbody>
                {sortedRows.map((row) => {
                  const { user, count, quantity } = row;
                  return (
                    <tr key={user.uid}>
                      <td>
                        <TenantLink to={`/admin/users/${user.uid}`}>{user.displayName}</TenantLink>
                      </td>
                      <td className='text-right'>{numeral(count).format()}</td>
                      <td className='text-right'>{numeral(quantity).format()}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});
