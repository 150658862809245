import { omitBy, isUndefined, pick, keyBy, isEmpty, groupBy, uniqBy } from 'lodash';
import { format as formatDate, parseISO, startOfDay } from 'date-fns';
import numeral from 'numeral';

import { statuses } from '../../shared/models/receivingPlan';
import AdminPage from '../hocs/AdminPage';
import { ImportButton } from '../v9/ImportButton';
import useTenant from '../hooks/useTenant';
import { useReceivingPlanCollection, tenantReceivingPlansQuery, receivingPlanRef } from 'src/models/receivingPlan';
import { useProductCollection, tenantProductsQuery } from 'src/models/product';

const { max, round } = Math;
const { values } = Object;

export default AdminPage(function AdminReceivingPlans(props) {
  const { user } = props;
  const tenant = useTenant();
  const { data: receivingPlans = [] } = useReceivingPlanCollection(tenantReceivingPlansQuery(tenant?.id));
  const { data: products = [] } = useProductCollection(tenantProductsQuery(tenant?.id));
  const productsById = keyBy(products, 'id');
  const processRows = (rows) => {
    return values(groupBy(rows, '調達先入荷番号')).map((_) => uniqBy(_, '品目コード'));
  };
  const processRow = (batch, items) => {
    const [{ 調達先入荷番号: id, 入荷予定日: date, 入荷実績日: finishedDate }] = items;
    if (isEmpty(id)) return;

    const status = isEmpty(finishedDate) ? 'initial' : 'received';
    const ref = receivingPlanRef(id);
    batch.set(
      ref,
      {
        tenantId: user.tenantId,
        date: startOfDay(parseISO(date)),
        items: items.map((item) => {
          const planQuantity = parseInt(item['予定数量'], 10);
          return {
            productId: item['品目コード'],
            planQuantity,
            quantity: planQuantity - max(round(planQuantity * 0.01), 3),
          };
        }),
        status,
        addedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
        updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      },
      { merge: true }
    );
  };

  return (
    <div>
      <div className="admin-receiving-plans container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>入荷予定管理</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <ImportButton processRows={processRows} processRow={processRow} />
              </div>
              <div>
                {receivingPlans.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>入荷予定ID</th>
                        <th>入荷予定日</th>
                        <th>状態</th>
                        <th>商品/予想数量(予定数量)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receivingPlans.map((receivingPlan) => {
                        const { id, items, date, status } = receivingPlan;
                        return (
                          <tr key={id}>
                            <td>{id}</td>
                            <td>{date && formatDate(date.toDate(), 'yyyy/MM/dd')}</td>
                            <td>{statuses[status].label}</td>
                            <td>
                              <table className="table table-borderless table-sm m-0" style={{ width: 'auto' }}>
                                <tbody>
                                  {items.map((item, i) => {
                                    const { productId, quantity, planQuantity = 0 } = item;
                                    const product = productsById[productId];
                                    return (
                                      <tr key={i}>
                                        <td style={{ width: 200 }}>
                                          {product && product.name ? product.name : productId}
                                        </td>
                                        <td style={{ width: 200 }} className="text-right">
                                          {numeral(quantity).format('0,0')} ({numeral(planQuantity).format('0,0')})
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>入荷予定はまだありません</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
