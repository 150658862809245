import React, { Fragment } from 'react';
import { Button, Form, } from 'reactstrap';
import { isArray, isEmpty, keyBy, pick, get, } from 'lodash';
import { useToggle, useMap, } from 'react-use';
import numeral from 'numeral';
import qs from 'qs';

import { basicFields } from '../../shared/models/troubleInquiry';
import Field from '../Field';
import SettingText from '../SettingText';
import { fieldDisplayValue, } from '../../shared/util';
import AppButton from '../AppButton';
import TenantLink from '../TenantLink';
import poNumberImageSrc from '../../images/po_number.png';
import ProgressButton from '../ProgressButton';
import RichTextContent from '../RichTextContent';

const { entries, keys, } = Object;

export default function AgentRegisterConfirmForm (props) {
  const { nonPartProducts, qrUrls, coupons, productRows, showingQrUrlRows, statedFields, onCancel, } = props;
  const productsById = keyBy(nonPartProducts, 'id');
  const qrUrlsById = keyBy(qrUrls, 'id');
  const couponsById = keyBy(coupons, 'id');
  const onSubmit = async (event) => {
    event.preventDefault();
    await props.onSubmit();
  };
  const agentProductPublicSettings = nonPartProducts.map((product) => {
    const productRow = productRows.find(_ => _.productId === product.id);
    return [product.id, productRow == null ? '1' : '', productRow != null ? '1' : ''].join(',');
  });

  return (
    <Form onSubmit={onSubmit}>
      <h4 className="mt-4 h5 text-center font-weight-bold">
        内容を確認してください
      </h4>
      <div className="mt-5">
        <table className="table table-bordered bg-white">
          <tbody>
            <tr>
              <th style={{ minWidth: 200 }}>取扱商品</th>
              <td>
                <div className="d-flex flex-column gap-2">
                  {
                    productRows.map((productRow, i) => {
                      const { productId, referralFeeType, referralFeeAmount, referralFeeRate } = productRow;
                      const product = productsById[productId];
                      return (
                        <div key={i} className="d-flex gap-1 align-items-center gap-5">
                          <div>
                            {product.isHidden ? '[非表示] ' : ''}[{product.code}] {product.name}
                          </div>
                          <div>
                            {
                              {
                                amount: referralFeeAmount != null ? (numeral(referralFeeAmount).format() + ' 円') : '(商品マスタに従う)',
                                rate: referralFeeRate != null ? (numeral(referralFeeRate).format() + ' %') : '(商品マスタに従う)',
                              }[referralFeeType]
                            }
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th style={{ minWidth: 200 }}>紹介QRコード遷移先</th>
              <td>
                <div className="d-flex flex-column gap-2">
                  {
                    showingQrUrlRows.map((showingQrUrlRow, i) => {
                      const { qrUrlId, couponIds, } = showingQrUrlRow;
                      const qrUrl = qrUrlsById[qrUrlId];
                      return (
                        <div key={i} className="d-flex gap-1 align-items-center gap-5">
                          <div>
                            {qrUrl?.name}
                          </div>
                          <div>
                            {
                              couponIds?.map((couponId) => {
                                return (
                                  <div key={couponId} className="card p-1 mb-1">
                                    {couponsById[couponId]?.name}
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>取扱条件</th>
              <td style={{ whiteSpace: 'pre-line', }}>
                {
                  fieldDisplayValue(statedFields.conditionNote.value, statedFields.conditionNote)
                }
              </td>
            </tr>
            <tr>
              <th>規約</th>
              <td>
                {fieldDisplayValue(statedFields.agentContractPlanId.value, statedFields.agentContractPlanId)}
              </td>
            </tr>
            <tr>
              <th>商品ページ冒頭説明文</th>
              <td>
                <RichTextContent html={statedFields.productsPageHeaderHtml.value} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end mt-5 gap-1">
        <Button className="cancel" color="secondary" onClick={onCancel}>
          <span className="fas fa-arrow-left mr-1" />
          戻る
        </Button>
        <Button tag={TenantLink} to={`/products?` + qs.stringify({ aps: agentProductPublicSettings.join('\n'), agentProductsPageHeaderHtml: statedFields.productsPageHeaderHtml.value, })} target="_blank">
          カート確認
          <span className="fas fa-external-link-alt ml-1" />
        </Button>
        <ProgressButton className="save" type="submit" color="primary" process={onSubmit}>
          <span className="fas fa-arrow-right mr-1" />
          確定する
        </ProgressButton>
      </div>
    </Form>
  );
};
