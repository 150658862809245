import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get, keyBy, pick, omit } from 'lodash';
import { useToggle } from 'react-use';
import qs from 'qs';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import useLocale from '../hooks/useLocale';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import texts from '../../shared/texts';
import TenantUserPage from '../hocs/TenantUserPage';
import RichTextContent from '../RichTextContent';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const inquiriesRef = db.collection('inquiries');
const inquiryTypesRef = db.collection('inquiryTypes');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');

function SurveyAnswerThanks(props) {
  const { user, history, firebaseUser, toggleLoginForm, match: { params: { tenantPath, surveyId } } } = props;
  const { translate } = useLocale();
  const survey = useDocumentSubscription(db.collection('surveys').doc(surveyId), [surveyId]);
  const locale = useDocumentSubscription(survey && db.collection('locales').doc([tenantPath, survey.lang || 'ja'].join('__')), [survey]);

  return survey != null && translate(
    <div className="survey-answer-thanks container py-5 position-relative">
      <div className="row mb-5">
        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="bg-light-grey rounded-3 p-3">
            {
              survey.thanksContent ? (
                <RichTextContent html={survey?.thanksContent} />
              ) : (
                <h5 className="font-weight-normal text-center">アンケートへのご協力、ありがとうございました。</h5>
              )
            }
          </div>
        </div>
      </div>
    </div>
  , locale);
}

export default TenantUserPage(SurveyAnswerThanks);
