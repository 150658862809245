import { useState } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { isToday } from 'date-fns';
import EventCheckInModal from './modals/EventCheckInModal';
import { Event } from '../types/event';
import { Lecture } from 'src/types/lecture';
import { Entry } from '../types/entry';
import { getCollectionData, getDocumentData } from 'src/shared/firebase';

export default function EventCheckInButton({
  event,
  checkInCode,
  disabled,
  onCheckedIn,
}: {
  event: Event;
  checkInCode: string;
  disabled?: boolean;
  onCheckedIn?: () => Promise<void>;
}) {
  const [entry, setEntry] = useState<Entry>();
  const [lecture, setLecture] = useState<Lecture>();
  const [showsModal, toggleModal] = useToggle(false);
  const handleClick = async () => {
    try {
      const entriesRef = event.ref!.collection('entries');
      if (!navigator.onLine) {
        toast.error('ネットワークがオフラインです。通信環境の良い場所で再度チェックインを行ってください。');
        return;
      }

      const entryQuery = entriesRef
        .where('cancelledAt', '==', null)
        .where('checkInCode', '==', checkInCode);
      const entries = (await getCollectionData(entryQuery)) as Entry[];
      if (entries.some(_ => _.checkedInAt)) {
        toast.error('既にチェックイン済みです。');
        return;
      }
      const entriesWithLecture = await Promise.all(
        entries.map(async (_) => {
          const [frame] = _.frames;
          const lectureRef = event.ref!.collection('lectures').doc(frame.lectureId);
          const _lecture = (await getDocumentData(lectureRef)) as Lecture;

          return { ..._, lecture: _lecture };
        })
      );
      if (!entriesWithLecture.length) {
        toast.error('チェックインコードに誤りがあります。正しいコードを入力して、再度チェックインを行ってください。');
        return;
      }
      const entryWithLecture = entriesWithLecture.find(({ lecture }) => {
        const { date } = lecture;
        return !!date && isToday(date.toDate());
      });
      if (!entryWithLecture) {
        toast.error('イベント開催日ではありません。イベント当日に再度チェックインを行ってください。');
        return;
      }
      setEntry(entryWithLecture);
      setLecture(entryWithLecture.lecture);
      toggleModal(true);
    } catch (e) {
      console.error(e);
      toast.error('チェックイン中にエラーが発生しました。申し訳ありませんが再度チェックインを行ってください。');
    }
  };

  return (
    <>
      <Button color="primary" outline disabled={disabled} onClick={handleClick}>
        チェックイン
      </Button>
      {entry && lecture && (
        <EventCheckInModal
          event={event}
          lecture={lecture}
          entry={entry}
          isOpen={showsModal}
          onClickClose={() => toggleModal(false)}
          onCheckedIn={onCheckedIn}
        />
      )}
    </>
  );
}
