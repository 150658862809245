const { isEmpty } = require('lodash');
const { startOfDay, endOfDay, } = require('date-fns');

const isInValidDateRange = (referrer) => {
  if(referrer == null) return false;

  const { startDate, endDate, } = referrer;
  return (
    new Date() >= startOfDay(startDate?.toDate() || new Date(1900, 0, 1)) &&
    new Date() <= endOfDay(endDate?.toDate() || new Date(9999, 0, 1))
  );
};

module.exports = {
  fields: ({ qrBottomDisplays = [], forAgent = false } = {}) => {
    return {
      name: {
        label: 'リファラ名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), },
      },
      textForQr: {
        label: 'QR内テキスト',
        type: 'string',
        hidden: _ => forAgent,
      },
      qrBottomDisplayId: {
        label: 'QR下部表記',
        type: 'select',
        options: qrBottomDisplays.map(_ => ({ label: _.name, value: _.id })),
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      startDate: {
        label: '有効期限開始',
        type: 'date',
      },
      endDate: {
        label: '有効期限終了',
        type: 'date',
      },
    };
  },
  isInValidDateRange,
};
