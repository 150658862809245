import React from 'react';
import { Button, } from 'reactstrap';
import classnames from 'classnames';

export default function AppCard (props) {
  const { className, style, ...extraProps } = props;
  return (
    <div className={classnames('card border-secondary border-3', className)} style={{ borderWidth: '0.3vw', borderRadius: '2vw', ...style }} {...extraProps}>
    </div>
  );
}
