import React, { useRef, useMemo } from 'react';
import { Button, Input } from 'reactstrap';
import { sumBy, pick, sortBy, difference, get, omit, isString, keyBy, omitBy, isUndefined, isEmpty } from 'lodash';
import dedent from 'dedent';
import { addDays } from 'date-fns';
import numeral from 'numeral';
import { set } from 'lodash/fp';
import { useList } from 'react-use';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
import qs from 'qs';

import firebase from '../../firebase';
import { activateRichTextHtml, withConfirm } from '../../util';
import { colors } from '../../shared/config';
import { canUpdateProduct } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import ModalButton from '../ModalButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import FilesUploadButton from '../FilesUploadButton';
import QueryBoolean from '../QueryBoolean';
import QuerySelector from '../QuerySelector';
import RichTextContent from '../RichTextContent';
import { fields as _fields, getProductsWithInventory, getProductId, } from '../../shared/models/product';

const storageRef = firebase.storage().ref();
const db = firebase.firestore();
const surveysRef = db.collection('surveys');
const ordersRef = db.collection('orders');
const productsRef = db.collection('products');
const productTagsRef = db.collection('productTags');
const productTypesRef = db.collection('productTypes');
const tmpOrdersRef = db.collectionGroup('tmpOrders');
const receivingPlansRef = db.collection('receivingPlans');

export default AdminPage(function AdminProducts(props) {
  const { tenant, user } = props;
  const {
    showsAllProducts: _showsAllProducts = '0',
    bodyOnly: _bodyOnly = '0',
    partOnly: _partOnly = '0',
    alertOnly: _alertOnly = '0',
    productTypes: productTypesForFilter,
    productTags: productTagsForFilter,
  } = useQueryParams();
  const showsAllProducts = useMemo(() => _showsAllProducts === '1', [_showsAllProducts]);
  const bodyOnly = useMemo(() => _bodyOnly === '1', [_bodyOnly]);
  const partOnly = useMemo(() => _partOnly === '1', [_partOnly]);
  const alertOnly = useMemo(() => _alertOnly === '1', [_alertOnly]);
  const [selectedIds, { set: setSelectedItems, push: selectItem, removeAt: removeSelectedItemAt }] = useList([]);
  const unselectItem = (_) => removeSelectedItemAt(selectedIds.indexOf(_));
  const isSelecting = selectedIds.length > 0;
  const productTypesHooksRef = useRef([]);
  const productTagsHooksRef = useRef([]);
  const receivings = useCollectionSubscriptionInTenant(db.collection('receivings'));
  const shippings = useCollectionSubscriptionInTenant(db.collection('shippings'));
  const surveys = sortBy(useCollectionSubscriptionInTenant(surveysRef.where('surveyGroupId', 'in', ['troubleInquiry', 'troubleReinquiry', 'methodInquiry', 'methodReinquiry'])), (_) => _.createdAt.toDate());
  const surveysById = keyBy(surveys, 'id');
  const initialTmpOrders = useCollectionSubscriptionInTenant(tmpOrdersRef.where('status', '==', 'initial'));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTags = sortBy(useCollectionSubscriptionInTenant(productTagsRef), _ => _.createdAt.toDate());
  const productTagsById = keyBy(productTags, 'id');
  const productTagOptions = productTags.map(({ id, name }) => ({ label: name, value: id }));
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const productTypesById = keyBy(productTypes, 'id');
  const productTypeOptions = productTypes.map(({ id, name }) => ({ label: name, value: id }));
  const filteredProducts = useMemo(() => {
    let filtered = products;
    if (!showsAllProducts) filtered = filtered.filter(({ isHidden }) => !isHidden);
    if (bodyOnly) filtered = filtered.filter(({ isBody }) => isBody);
    if (partOnly) filtered = filtered.filter(({ isPart }) => isPart);
    if (!isEmpty(productTypesForFilter))
      filtered = filtered.filter(({ productTypeIds }) =>
        productTypeIds?.some((productTypeId) => productTypesForFilter.includes(productTypeId))
      );
    if (!isEmpty(productTagsForFilter))
      filtered = filtered.filter(({ productTagIds }) =>
        productTagIds?.some((productTagId) => productTagsForFilter.includes(productTagId))
      );
    return filtered;
  }, [
    products,
    showsAllProducts,
    bodyOnly,
    partOnly,
    productTypes,
    productTypesForFilter,
    productTags,
    productTagsForFilter,
  ]);
  const preOrders = useCollectionSubscriptionInTenant(
    ordersRef.where('isPreOrder', '==', true).where('cammacsStatus', 'in', ['initial', 'imported'])
  );
  const receivingPlans = useCollectionSubscriptionInTenant(receivingPlansRef.where('status', '==', 'initial'));
  const filteredReceivingPlans = sortBy(receivingPlans, (_) => _.date.toDate()).filter(
    (_) => addDays(_.date.toDate(), 1) > new Date()
  );
  const productItems = useMemo(() => {
    let productsWithInventory = getProductsWithInventory(tenant, filteredProducts, receivings, shippings, preOrders, filteredReceivingPlans, initialTmpOrders);
    if (alertOnly) productsWithInventory = productsWithInventory.filter((_) => _.normalInventory - _.tmpOrderQuantity < _.alertInventory);
    return productsWithInventory;
  }, [filteredProducts, preOrders, receivingPlans, alertOnly]);
  productTypesHooksRef.current = productTypes;
  productTagsHooksRef.current = productTags;
  const fields = () => {
    const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
    const selectableProducts = sortedProducts.map((product) => ({
      ...product,
      ...(product.isHidden && { label: `[非表示] ${product.name}` }),
    }));
    const fields = _fields({
      tenant,
      surveys,
      productTags,
      productTypes,
      products: selectableProducts,
    });
    return set(
      'productTagIds.options',
      fields.productTagIds.options.map((_) => ({
        ..._,
        label: (
          <div>
            <span className='fas fa-square mr-1' style={{ color: colors[_.color] }} />
            {_.label}
          </div>
        ),
      })),
      fields
    );
  };

  const processRow = async (batch, row) => {
    const {
      code,
      name,
      productTypeIds,
      productTagIds,
      recommendedProductIds,
      isBody,
      isPart,
      isOption,
      approvalRequired,
      price,
      referralFeeRate,
      wholesalePriceRate,
      alertInventory,
      abroadInventoryLeadDays,
      isHidden,
      packageNote,
      shippingName,
      description,
      thanksContent,
      troubleInquirySurveyId,
      troubleReinquirySurveyId,
      note,
    } = row;
    if (!code) return;
    const product = productsById[getProductId(tenant.id, code)];
    const toBoolean = (value) => value?.toUpperCase() === 'TRUE';
    const toNumber = (value) => (value ? parseInt(value, 10) : 0);
    const toArray = (value) => (value ? value.split(',').filter((_) => _) : null);

    batch.set(
      productsRef.doc(getProductId(tenant.id, code)),
      {
        ...omit(
          {
            ...product,
            code,
            name,
            productTypeIds: toArray(productTypeIds),
            productTagIds: toArray(productTagIds),
            isBody: toBoolean(isBody),
            isPart: toBoolean(isPart),
            isOption: toBoolean(isOption),
            approvalRequired: toBoolean(approvalRequired),
            price: toNumber(price),
            referralFeeRate: toNumber(referralFeeRate),
            wholesalePriceRate: toNumber(wholesalePriceRate),
            alertInventory: toNumber(alertInventory),
            abroadInventoryLeadDays: toNumber(abroadInventoryLeadDays),
            isHidden: toBoolean(isHidden),
            packageNote,
            shippingName,
            description,
            thanksContent,
            note,
            recommendedProductIds: toArray(recommendedProductIds),
            troubleInquirySurveyId,
            troubleReinquirySurveyId,
          },
          ['ref']
        ),
        ...(product == null ? { tenantId: tenant.id, createdAt: new Date() } : {}),
        [product ? 'updatedBy' : 'createdBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      },
      { merge: true }
    );
  };

  const processInventoryRow = async (batch, row) => {
    const code = row['品目コード'];
    const product = productsById[getProductId(tenant.id, code)];
    if(product == null || row['良品・不良品区分'] !== '0') return;

    batch.update(product.ref, {
      normalInventory: parseInt(row['論理在庫'], 10),
      updatedBy: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
    });
  };

  const processProductValues = async (values) => {
    const { id, image } = values;
    if (image == null) return values;

    const imageRef = storageRef.child(`products/${id}/image`);
    let imageUrl;
    if (isString(image)) {
      imageUrl = image;
    } else {
      await imageRef.put(image, { contentType: image.type });
      imageUrl = await imageRef.getDownloadURL();
    }
    return { ...values, image: imageUrl };
  };
  const importSuccessMessage = dedent`
    インポートしました。
    必要に応じて、編集ボタンから画像登録してください。
    また、パーツについては種別やタグも適宜設定してください。
  `;
  const withClear = (f) => {
    return (...args) => {
      setSelectedItems([]);
      return f(...args);
    };
  };
  const rowsForExport = () => {
    return productItems.map((productItem) => {
      const {
        code,
        name,
        isPart = false,
        isBody = false,
        isOption = false,
        approvalRequired = false,
        productTypeIds,
        productTagIds,
        recommendedProductIds,
        troubleInquirySurveyId,
        troubleReinquirySurveyId,
        price,
        referralFeeRate = 0,
        wholesalePriceRate = 0,
        normalInventory = 0,
        tmpOrderQuantity = 0,
        normalOrderableQuantity = 0,
        receivingQuantity = 0,
        preOrderQuantity = 0,
        preTmpOrderQuantity = 0,
        preOrderableQuantity = 0,
        alertInventory = 0,
        abroadInventoryLeadDays = 0,
        isHidden = false,
        packageNote = '',
        shippingName = '',
        description,
        thanksContent,
        note,
      } = productItem;

      return {
        code,
        name,
        productTypeIds: (productTypeIds || []).join(','),
        productTypeNames: (productTypeIds || []).map((_) => get(productTypesById, [_, 'name'])).join(','),
        isBody,
        isPart,
        isOption,
        approvalRequired,
        productTagIds: (productTagIds || []).join(','),
        productTagNames: (productTagIds || []).map((_) => get(productTagsById, [_, 'name'])).join(','),
        price,
        referralFeeRate,
        wholesalePriceRate,
        normalInventory,
        tmpOrderQuantity,
        normalOrderableQuantity,
        receivingQuantity,
        preOrderQuantity,
        preTmpOrderQuantity,
        preOrderableQuantity,
        alertInventory,
        abroadInventoryLeadDays,
        isHidden,
        packageNote,
        shippingName,
        description,
        thanksContent,
        recommendedProductIds: (recommendedProductIds || []).join(','),
        recommendedProductNames: (recommendedProductIds || []).map((_) => get(productsById, [_, 'name'])).join(','),
        troubleInquirySurveyId: troubleInquirySurveyId,
        troubleInquirySurveyTitle: surveysById[troubleInquirySurveyId]?.title,
        troubleReinquirySurveyId: troubleReinquirySurveyId,
        troubleReinquirySurveyTitle: surveysById[troubleReinquirySurveyId]?.title,
        note,
      };
    });
  };

  return (
    <div>
      <div className='admin-products container-fluid py-5 position-relative'>
        <div className='bg-white p-4'>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-center mb-3'>
                <h4>商品一覧</h4>
              </div>
              {!isSelecting ? (
                <div className='d-flex justify-content-end mb-3 gap-2 align-items-end flex-wrap'>
                  <QuerySelector
                    paramName='productTypes'
                    width={250}
                    isMulti
                    options={productTypeOptions}
                    label='種別で絞込み'
                  />
                  <QuerySelector
                    paramName='productTags'
                    width={250}
                    isMulti
                    options={productTagOptions}
                    label='タグで絞込み'
                  />
                  <QueryBoolean paramName='showsAllProducts' label='非表示も表示' defaultValue={'0'} />
                  <QueryBoolean paramName='bodyOnly' label='本体のみ' defaultValue={'0'} />
                  <QueryBoolean paramName='partOnly' label='パーツのみ' defaultValue={'0'} />
                  <QueryBoolean paramName='alertOnly' label='アラートのみ' defaultValue={'0'} />
                  {
                    !tenant.useWms && (
                      <ImportButton
                        label="在庫"
                        processRow={processInventoryRow}
                        disabled={!canUpdateProduct(user)}
                      />
                    )
                  }
                  <ImportButton
                    processRow={processRow}
                    successMessage={importSuccessMessage}
                    disabled={!canUpdateProduct(user)}
                  />
                  <ExportButton fileName='商品.csv' rows={rowsForExport} />
                  <AddInTenantButton
                    itemRef={(_) => productsRef.doc(getProductId(tenant.id, _.code))}
                    processValues={processProductValues}
                    FormModal={ModelFormModal}
                    formProps={{ documentName: 'product', title: '商品追加', fields }}
                    disabled={!canUpdateProduct(user)}
                  />
                </div>
              ) : (
                <div className='d-flex align-items-end justify-content-end mb-3'>
                  <div>{selectedIds.length} 件を選択中</div>
                  <Button
                    className='ml-2'
                    onClick={withClear(
                      withConfirm('本当に非表示にしますか？', (_) =>
                        selectedIds.map((_) => productsRef.doc(_).update({ isHidden: true }))
                      )
                    )}
                  >
                    非表示にする
                  </Button>
                  <Button
                    className='ml-2'
                    onClick={withClear(
                      withConfirm('本当に非表示を解除しますか？', (_) =>
                        selectedIds.map((_) => productsRef.doc(_).update({ isHidden: false }))
                      )
                    )}
                  >
                    非表示を解除する
                  </Button>
                </div>
              )}
              <div className="d-flex justify-content-start">
                総通常在庫数: {numeral(sumBy(productItems, 'normalInventory')).format()}
              </div>
              <div className='overflow-auto' style={{ maxHeight: 'calc(100vh - 200px)' }}>
                <table className='table'>
                  <thead className='thead-light text-center text-nowrap position-sticky' style={{ top: 0 }}>
                    <tr>
                      <th>
                        <Input
                          type='checkbox'
                          className='position-relative m-0'
                          checked={difference(productItems.map(_ => _.id), selectedIds).length === 0}
                          onChange={(_) => (_.target.checked ? setSelectedItems(productItems.map(_ => _.id)) : setSelectedItems([]))}
                        />
                      </th>
                      <th>商品コード</th>
                      <th style={{ minWidth: 180 }}>商品名</th>
                      <th>種別</th>
                      <th>本体</th>
                      <th>パーツ</th>
                      <th>オプション</th>
                      <th>要承認</th>
                      <th>タグ</th>
                      <th>おすすめ商品</th>
                      <th>画像</th>
                      <th>税込価格</th>
                      <th>紹介料率</th>
                      <th>下代率</th>
                      <th>アラート在庫</th>
                      <th>
                        <div>通常在庫</div>
                        <div>仮注文個数</div>
                        <div>理論在庫</div>
                      </th>
                      <th>
                        <div>入荷予定数</div>
                        <div>予約注文数</div>
                        <div>仮注文個数</div>
                        <div>理論在庫</div>
                      </th>
                      <th>非表示</th>
                      <th>
                        梱包指示備考
                        <br />
                        追加テキスト
                      </th>
                      <th>商品説明</th>
                      <th>メモ</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {productItems.map((productItem) => {
                      const {
                        id,
                        ref,
                        code,
                        name,
                        isPart = false,
                        isBody = false,
                        isOption = false,
                        approvalRequired = false,
                        productTypeIds,
                        productTagIds,
                        recommendedProductIds,
                        image,
                        price,
                        referralFeeRate = 0,
                        wholesalePriceRate = 0,
                        normalInventory = 0,
                        tmpOrderQuantity = 0,
                        normalOrderableQuantity = 0,
                        receivingQuantity = 0,
                        preTmpOrderQuantity = 0,
                        preOrderQuantity = 0,
                        preOrderableQuantity = 0,
                        alertInventory = 0,
                        isHidden = false,
                        subImages = [],
                        packageNote = '',
                        memo = '',
                        description,
                      } = productItem;
                      const shouldAlert = normalInventory - tmpOrderQuantity < alertInventory;
                      const hasEnoughReceiving = normalInventory - tmpOrderQuantity + receivingQuantity >= alertInventory;
                      const beforeDelete = async () => {
                        if (
                          (await ordersRef.where('tenantId', '==', tenant.id).where('productIds', 'array-contains', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };
                      const renderImagesModalContent = () => {
                        const storagePath = `products/${id}/subImages`;
                        const validateFiles = (files) => {
                          if (Array.from(files).every((_) => _.type.match(/^image/))) {
                            return true;
                          } else {
                            return toast.error('画像以外が選択されています。');
                          }
                        };
                        const onUploaded = async (uploads) => {
                          await Promise.all(
                            uploads.map(async ({ file, path, downloadUrl }) => {
                              await ref.set(
                                {
                                  subImages: firebase.firestore.FieldValue.arrayUnion({
                                    uploadedAt: new Date(),
                                    downloadUrl,
                                    storagePath: path,
                                    ...pick(file, ['name', 'type']),
                                  }),
                                },
                                { merge: true }
                              );
                            })
                          );
                        };

                        return (
                          <div>
                            <FilesUploadButton
                              storagePath={storagePath}
                              onUploaded={onUploaded}
                              accept='image/*'
                              validate={validateFiles}
                            />
                            <div className='position-relative d-flex flex-wrap justify-content-around gap-2'>
                              {subImages.map((image) => {
                                const { downloadUrl } = image;
                                const onClickDelete = async () => {
                                  if (!window.confirm('本当に削除しますか？')) return;

                                  await ref.set(
                                    {
                                      subImages: subImages.filter((_) => _ !== image),
                                    },
                                    { merge: true }
                                  );
                                };

                                return (
                                  <div
                                    key={downloadUrl}
                                    className='card p-3 d-flex flex-column align-items-center'
                                    style={{ width: 300 }}
                                  >
                                    <img src={downloadUrl} style={{ maxHeight: 200, maxWidth: '100%' }} />
                                    <Button className='mt-2' color='danger' size='sm' onClick={onClickDelete}>
                                      削除
                                    </Button>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      };

                      return (
                        <tr key={id} className={classnames({ 'table-danger': shouldAlert && !hasEnoughReceiving, 'table-warning': shouldAlert && hasEnoughReceiving, })}>
                          <td>
                            <Input
                              type='checkbox'
                              className='position-relative m-0'
                              checked={selectedIds.includes(productItem.id)}
                              onChange={(_) =>
                                selectedIds.includes(productItem.id)
                                  ? unselectItem(productItem.id)
                                  : selectItem(productItem.id)
                              }
                            />
                          </td>
                          <td>{code}</td>
                          <td>{name}</td>
                          <td>
                            {(productTypeIds || []).map((productTypeId) => {
                              const productType = productTypesById[productTypeId];
                              return (
                                productType != null && (
                                  <div key={productTypeId} className='mr-1 text-nowrap'>
                                    {productType.name}
                                  </div>
                                )
                              );
                            })}
                          </td>
                          <td>{isBody && '本体'}</td>
                          <td>{isPart && 'パーツ'}</td>
                          <td>{isOption && 'オプション'}</td>
                          <td>{approvalRequired && '要承認'}</td>
                          <td>
                            {(productTagIds || []).map((productTagId) => {
                              const productTag = productTagsById[productTagId];
                              return (
                                productTag != null && (
                                  <span
                                    key={productTagId}
                                    className='badge mr-1'
                                    style={{ backgroundColor: colors[productTag.color] }}
                                  >
                                    {productTag.name}
                                  </span>
                                )
                              );
                            })}
                          </td>
                          <td>
                            {(recommendedProductIds || []).map((productId) => {
                              const product = productsById[productId];
                              return (
                                product != null && (
                                  <div key={productId} className='mr-1 text-nowrap'>
                                    {product.name}
                                  </div>
                                )
                              );
                            })}
                          </td>
                          <td>
                            <img src={image} style={{ maxHeight: 50 }} />
                          </td>
                          <td className='text-right'>{numeral(price).format('0,0')}</td>
                          <td className='text-right'>{numeral(referralFeeRate).format('0,0')}%</td>
                          <td className='text-right'>{numeral(wholesalePriceRate).format('0,0')}%</td>
                          <td className='text-right'>{numeral(alertInventory).format('0,0')}</td>
                          <td className='text-right'>
                            <div>{numeral(normalInventory).format('0,0')}</div>
                            <div>{numeral(tmpOrderQuantity).format('0,0')}</div>
                            <div>{numeral(normalOrderableQuantity).format('0,0')}</div>
                          </td>
                          <td className='text-right'>
                            <div>{numeral(receivingQuantity).format('0,0')}</div>
                            <div>{numeral(preOrderQuantity).format('0,0')}</div>
                            <div>{numeral(preTmpOrderQuantity).format('0,0')}</div>
                            <div>{numeral(preOrderableQuantity).format('0,0')}</div>
                          </td>
                          <td>{isHidden && '非表示'}</td>
                          <td style={{ minWidth: 200 }}>{packageNote}</td>
                          <td style={{ minWidth: 200 }}>{
                            description && <RichTextContent html={description} />
                          }</td>
                          <td style={{ minWidth: 200 }}>{memo}</td>
                          <td className='text-nowrap text-right'>
                            <div className="d-flex justify-content-end gap-1">
                              <EditButton
                                itemRef={ref}
                                FormModal={ModelFormModal}
                                formProps={{ documentName: 'product', title: '商品編集', fields: omit(fields(), 'code') }}
                                disabled={isSelecting || !canUpdateProduct(user)}
                                beforeSubmit={processProductValues}
                              />
                              <DeleteButton
                                itemRef={ref}
                                disabled={isSelecting || !canUpdateProduct(user)}
                                beforeDelete={beforeDelete}
                              />
                            </div>
                            <div className="mt-1 d-flex justify-content-end gap-1">
                              <ModalButton
                                title='サブ画像登録（メイン画像は編集ボタンから追加）'
                                label='画像'
                                content={renderImagesModalContent}
                                modalProps={{ style: { minWidth: '80vw' } }}
                              />
                              <Button
                                tag={Link}
                                to={`/mypage/orders?${qs.stringify({ fromNewOrder: 1, productIds: [id], })}`}
                                target="_blank"
                              >
                                サンクス表示テスト
                                <span className="ml-1 fas fa-external-link-alt" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
