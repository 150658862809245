import {
  Timestamp,
  DocumentReference,
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  serverTimestamp as _serverTimestamp,
  getFirestore,
  collection,
  collectionGroup,
  doc,
  query,
  where,
  orderBy,
  limit,
  limitToLast,
  startAt,
  endAt,
  Query,
  QueryConstraint,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
  UpdateData,
  CollectionReference,
  onSnapshot,
  SetOptions,
  getDocs,
  FieldPath,
  documentId,
  writeBatch as _writeBatch,
  refEqual,
  queryEqual,
  WriteBatch,
  getCountFromServer,
} from 'firebase/firestore';
import { firebase } from './compat';
import { omit } from 'lodash';
import { WithId } from '../types/firebase';

const serverTimestamp = _serverTimestamp as unknown as () => Timestamp;

const getConverter = <T extends DocumentData>(): FirestoreDataConverter<WithId<T>> => ({
  toFirestore: (data: PartialWithFieldValue<WithId<T> | T>): DocumentData => {
    return omit(data, ['id', 'ref']);
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<T>, options: SnapshotOptions): WithId<T> => {
    // TODO: HOCでドキュメントデータ（userとかtenant）を渡すのやめたらv9のref入れるようにする
    return { id: snapshot.id, ...snapshot.data(options), ref: firebase.firestore().doc(snapshot.ref.path) } as any;
  },
});

const getCollectionData = async <T>(query: Query<T>) => {
  const snapshot = await getDocs(query);
  return snapshot.docs.map((doc) => doc.data());
};

const writeBatch = () => _writeBatch(getFirestore());

export type {
  Timestamp,
  DocumentData,
  DocumentReference,
  Query,
  QueryConstraint,
  UpdateData,
  CollectionReference,
  SetOptions,
  WriteBatch,
};
export {
  getFirestore,
  collection,
  collectionGroup,
  doc,
  query,
  where,
  orderBy,
  limit,
  limitToLast,
  startAt,
  endAt,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
  serverTimestamp,
  getConverter,
  onSnapshot,
  getCollectionData,
  FieldPath,
  documentId,
  writeBatch,
  refEqual,
  queryEqual,
  getCountFromServer,
};
