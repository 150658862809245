import React from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

export default function ProgressButton ({ Component = Button, label = '', children, process = _ => _, disabled, ...extraProps }) {
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async (event) => {
    toggleProcessing(true);
    await process(event);
    toggleProcessing(false);
  };

  return (
    <Component onClick={onClick} disabled={isProcessing || disabled} {...extraProps}>
      {isProcessing && <span className="fas fa-spin fa-spinner mr-1" />}
      {children || label}
    </Component>
  );
};
