import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { set } from 'lodash/fp';
import { useToggle } from 'react-use';

import firebase from '../../firebase';
import LaterQuestionsAnswerForm from '../forms/LaterQuestionsAnswerForm';
import TenantUserPage from '../hocs/TenantUserPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';

const db = firebase.firestore();
const eventsRef = db.collection('events');
const questionsRef = db.collection('questions');
const laterQuestionsRef = db.collection('laterQuestions'); // TODO: 利用が無くなった段階で削除する

export default TenantUserPage(function EntryLaterQuestion(props) {
  const {
    history,
    match: {
      params: { eventId, entryId, frameIndex },
    },
  } = props;
  const [hasJustAnswered, toggleHasJustAnswered] = useToggle();
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const lectures = useCollectionSubscriptionInTenant(eventRef.collection('lectures'), [eventId], {
    initialItems: null,
  });
  const entry = useDocumentSubscription(entryId && eventRef.collection('entries').doc(entryId), [eventId, entryId]);
  const { frames: { [frameIndex]: entryFrame } = [], createdBy } = entry || {};
  const questions = useCollectionSubscriptionInTenant(questionsRef.orderBy('createdAt'), [eventId], {
    initialItems: null,
  });
  const laterQuestions = useCollectionSubscriptionInTenant(laterQuestionsRef.orderBy('createdAt'), [eventId], {
    initialItems: null,
  });
  const userName = createdBy?.displayName && `${createdBy.displayName}さま`;
  const childName = entryFrame?.name && `（${entryFrame.name}さま）`;
  const onSubmit = async (values) => {
    try {
      const entryFrames = set(`${frameIndex}.laterAnswers`, values.laterAnswers, entry.frames);
      toggleHasJustAnswered();
      await entry.ref.update({ frames: entryFrames });
      history.push(`/mypage/entries`);
      toast.success('アンケートのご回答ありがとうございました。');
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  useEffect(() => {
    if (!hasJustAnswered && entryFrame && entryFrame.laterAnswers != null) {
      history.push(`/mypage/entries`);
      toast.success('アンケートは回答済みです');
    }
  }, [entry]);

  return (
    event != null &&
    entryFrame != null && (
      <div>
        <div className="entry-later-questions container py-5 position-relative">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 alert alert-info">
              {event.name}へのご参加ありがとうございました。
              <br />
              今後の改善のために、{userName}
              {childName}のお声を聞かせてください。
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {entry && lectures && questions && laterQuestions && (
                <LaterQuestionsAnswerForm
                  eventId={eventId}
                  lectures={lectures}
                  entry={entry}
                  questions={[...questions, ...laterQuestions]}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
});
