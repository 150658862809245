import React from 'react';
import { uniqBy, sortBy, orderBy, keyBy, groupBy } from 'lodash';
import { useToggle } from 'react-use';
import { Link } from 'react-router-dom';

import EventCard from '../EventCard';
import firebase from '../../firebase';
import UserPage from '../hocs/UserPage';
import useLocale from '../hooks/useLocale';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import EntryDetailModal from '../modals/EntryDetailModal';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionFetch from '../hooks/useCollectionFetch';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionsFetch from '../hooks/useCollectionsFetch';

const db = firebase.firestore();
const entriesRef = db.collectionGroup('entries');
const placesRef = db.collection('places');

export default UserPage(function MypageEntries(props) {
  const { user = {} } = props;
  const tenants = useCollectionSubscription(db.collection('tenants'));
  const locales = useCollectionFetch(db.collection('locales'));
  const localesById = keyBy(locales, 'id');
  const tenantsById = keyBy(tenants, 'id');
  const entries = useCollectionSubscription(entriesRef.where('createdBy.uid', '==', user.uid), [user.uid]);
  const places = useCollectionSubscription(placesRef);
  const placesById = keyBy(places, 'id');
  const filteredEntries = entries.filter((_) => !_.isTemporary);
  const sortedEntries = orderBy(filteredEntries, (_) => _.createdAt.toDate(), 'desc');
  const events = useDocumentsFetch(
    filteredEntries.map((_) => _.ref.parent.parent),
    [entries]
  );
  const eventsById = keyBy(events, 'id');
  const fetchedLectures = useCollectionsFetch(
    events.map((_) => _.ref.collection('lectures')),
    [events]
  );
  const lectures = uniqBy(
    (fetchedLectures || []).map((_) => ({ eventId: _.ref.parent.parent.id, ..._ })),
    'id'
  );
  const lecturesGroupedByEventId = groupBy(lectures, 'eventId');

  return (
    <div className="mypage-entries">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">イベント申込み履歴</h5>
      </section>
      <section className="container mt-4">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-end">
              <Link to="/events">
                <span className="fas fa-angle-double-right mr-1" />
                イベント、セミナーを探す
              </Link>
            </div>
            <div className="mt-4">
              {sortedEntries.map((entry) => {
                const {
                  ref: {
                    parent: {
                      parent: { id: eventId },
                    },
                  },
                } = entry;
                const event = eventsById[eventId] || {};
                const tenant = tenantsById[event?.tenantId];
                const lectures = sortBy(lecturesGroupedByEventId[event.id] || [], (_) => _.date.toDate());
                const place = placesById[event.placeId];

                return <EntryItem {...{ entry, event, tenant, place, lectures, localesById, }} />;
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

function EntryItem(props) {
  const { localesById, entry, event, tenant, place, lectures } = props;
  const { abortedAt, cancelledAt, isTestEntry } = entry;
  const [showsEntryDetailModal, toggleEntryDetailModal] = useToggle();
  const { translate } = useLocale();
  const locale = localesById[[event?.tenantId, event?.lang || 'ja'].join('__')];

  return (
    <div className="mb-4">
      <EventCard
        tenant={tenant}
        event={event}
        lectures={lectures}
        place={place}
        linkLabel={
          <span>
            <span className="mr-1 fas fa-search" />
            {translate('詳細', locale)}
          </span>
        }
        isAvailable={abortedAt == null && cancelledAt == null}
        unavailableLabel={translate(cancelledAt != null ? 'キャンセル済みです' : '中止されました', locale)}
        isTestEntry={isTestEntry}
        onClickLink={toggleEntryDetailModal}
      />
      <EntryDetailModal
        translate={_ => translate(_, locale)}
        isOpen={showsEntryDetailModal}
        onClickClose={toggleEntryDetailModal}
        {...{ entry, event, tenant, place, lectures }}
      />
    </div>
  );
}
