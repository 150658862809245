import React, { useEffect, useRef, useState, } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import { Calendar, dateFnsLocalizer, Views, Event as CalendarEvent, stringOrDate } from 'react-big-calendar';
import { useWindowSize } from 'react-use';
import { shuffle, range, isString, sortBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, parse as parseDate, min as minDate, differenceInMinutes, addMinutes, startOfWeek, getDay, startOfDay, endOfDay, addHours, } from 'date-fns';
import { Input, Button } from 'reactstrap';
import numeral from 'numeral';
import { ja } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useAsync, } from 'react-use';
import { v4 as uuid } from 'uuid';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import { prefectures } from '../../shared/config';
import { beforeDelete, } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentShopHeader from '../AgentShopHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import { fields } from '../../shared/models/rentalItem';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';
import TenantLink from '../TenantLink';
import QrReader from '../QrReader';

const returnRentalOrder = functions.httpsCallable('returnRentalOrder');
const locales = {
  ja: ja,
};

const localizer = dateFnsLocalizer({
  format: formatDate,
  parse: parseDate,
  startOfWeek,
  getDay,
  locales,
});

const formatOfYear = 'yyyy年';
const formatOfMonth = 'MM月';
const formatOfDay = 'dd日';
const formatOfDate = `${formatOfYear}${formatOfMonth}${formatOfDay}`;

const db = firebase.firestore();

export default AgentPage(function AgentShopRentalCalendar (props) {
  const { tenant, user, agent, match: { params: { agentShopId } }, setBreadNavValues, canReadAllShops, memberData, } = props;
  const { height } = useWindowSize();
  const [range, setRange] = useState({ startAt: startOfDay(new Date()), endAt: endOfDay(new Date()) });
  const queryParams = useQueryParams();
  const agentShop = useDocumentSubscription((canReadAllShops || memberData.shopIds.includes(agentShopId)) && agent.ref.collection('agentShops').doc(agentShopId), [agent, agentShopId]);
  const rentalItems = sortBy(useCollectionSubscription(agent.ref.collection('rentalItems').where('agentShopId', '==', agentShopId).where('isHidden', '==', false), [agent, agentShopId]), 'name');
  const rentalItemsById = keyBy(rentalItems, 'id');
  const rentalItemEntities = sortBy(useCollectionSubscription(agent.ref.collection('rentalItemEntities').where('agentShopId', '==', agentShopId).where('isHidden', '==', false), [agent, agentShopId]), 'code');
  const rentalItemGroups = sortBy(useCollectionSubscriptionInTenant(agent.ref.collection('rentalItemGroups'), [agent]), _ => _.createdAt.toDate());
  const rentalItemGroupsById = keyBy(rentalItemGroups, 'id');
  const rentalPrices = sortBy(useCollectionSubscriptionInTenant(agent.ref.collection('rentalPrices').where('agentShopId', '==', agentShopId), [agent, agentShopId]), _ => _.createdAt.toDate());
  const rentalPricesById = keyBy(rentalPrices, 'id');
  const rentalOrders = useCollectionSubscription(agent.ref.collection('rentalOrders').where('startedAt', '>=', range.startAt).where('startedAt', '<=', range.endAt), [range]);
  const groupOptions = rentalItemGroups.map((_) => ({ label: _.name, value: _.id }));
  const rentalItemOptions = rentalItems.map((_) => ({ label: _.name, value: _.id }));

  let filteredRentalItemEntities = rentalItemEntities;
  if (!isEmpty(queryParams.groupIds)) {
    filteredRentalItemEntities = filteredRentalItemEntities.filter((_) => queryParams.groupIds.includes(rentalItemsById[_.rentalItemId]?.rentalGroupId));
  }
  if (!isEmpty(queryParams.rentalItemIds)) {
    filteredRentalItemEntities = filteredRentalItemEntities.filter((_) => queryParams.rentalItemIds.includes(_.rentalItemId));
  }

  useEffect(() => {
    setBreadNavValues({ agent, agentShop, });
  }, [agent, agentShop]);

  const handleRangeChange = (_range) => {
    if (Array.isArray(_range)) {
      const [start] = _range;
      const [end] = _range.slice(-1);
      setRange({ startAt: startOfDay(start), endAt: endOfDay(end) });
    } else {
      const { start, end } = _range;
      setRange({ startAt: startOfDay(start), endAt: endOfDay(end) });
    }
  };
  const calendarResources = filteredRentalItemEntities.map((rentalItemEntity) => {
    const rentalItem = rentalItemsById[rentalItemEntity.rentalItemId];
    return {
      id: rentalItemEntity.id,
      title: (
        <div>
          <div>{rentalItem?.name}</div>
          <div className="text-muted small">{rentalItemEntity.code}</div>
        </div>
      ),
    };
  });
  const calendarEvents = rentalOrders.flatMap((rentalOrder) => {
    const { startedAt, endedAt, willEndAt, name, rentalPriceId, } = rentalOrder;
    const rentalPrice = rentalPricesById[rentalPriceId];
    return {
      resourceId: rentalOrder.rentalItemEntityId,
      start: startedAt.toDate(),
      end: (endedAt || willEndAt).toDate(),
      title: (
        <div>
          {name}
        </div>
      ),
    };
  });
  const resourceTitleAccessor = ({ title }) => {
    return (
      <div className='text-wrap' style={{ minHeight: '84px' }}>
        {title}
      </div>
    );
  };
  const eventStyleGetter = (event) => {
    const { isAborted, } = event;
    return {
      style: {
        opacity: isAborted && 0.3,
      },
    };
  };

  return agentShop != null && (
    <div>
      <div className="agent-shop container-fluid py-5 position-relative">
        <AgentShopHeader activeTab="rentalCalendar" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className='position-relative mb-2 d-flex align-items-end justify-content-end gap-2' style={{ zIndex: 11 }}>
            <ReturnButton {...{ agent, }} />
          </div>
          <div className='position-relative mb-2 d-flex align-items-end flex-wrap gap-2' style={{ zIndex: 11 }}>
            <QuerySelector
              paramName='groupIds'
              className='ml-0'
              width={300}
              isMulti
              options={groupOptions}
              label='グループで絞込み'
            />
            <QuerySelector
              paramName='rentalItemIds'
              className='ml-0'
              width={300}
              isMulti
              options={rentalItemOptions}
              label='レンタル商品で絞込み'
            />
          </div>
          <div>
            <Calendar
              localizer={localizer}
              events={calendarEvents}
              resources={calendarResources}
              defaultView={Views.DAY}
              style={{ height: height * 0.6 }}
              step={15}
              timeslots={1}
              culture='ja'
              formats={{
                monthHeaderFormat: `${formatOfYear}${formatOfMonth}`,
                dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
                  localizer?.format(start, formatOfDate, culture || 'ja') +
                  ' - ' +
                  localizer?.format(end, formatOfDate, culture || 'ja'),
                dayHeaderFormat: `${formatOfDate}(E)`,
                agendaDateFormat: `${formatOfDate}(E)`,
              }}
              scrollToTime={new Date()}
              resourceTitleAccessor={resourceTitleAccessor}
              eventPropGetter={eventStyleGetter}
              onRangeChange={handleRangeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

function ReturnButton (props) {
  const { agent } = props;
  const [rentalOrder, setRentalOrder] = useState(null);
  const [minRentalPrice] = useCollectionSubscription(agent.ref.collection('rentalPrices').orderBy('hours').limit(1), [agent]);

  return (
    <ModalButton
      title="レンタル返却"
      content={({ toggleModal }) => {
        const onRead = async (result) => {
          const [rentalOrder] = await getCollectionData(agent.ref.collection('rentalOrders').where('status', '==', 'renting').where('rentalItemEntityCode', '==', result).limit(1));
          if(rentalOrder != null) {
            setRentalOrder(rentalOrder);
          } else {
            toast.error(`読み込みましたが、見つかりません。(${result})`);
          }
        };

        return (
          <div>
            {
              rentalOrder == null ? (
                <div className="d-flex justify-content-center">
                  <QrReader onRead={onRead} />
                </div>
              ) : (() => {
                const overMinutes = differenceInMinutes(new Date(), rentalOrder.willEndAt.toDate());
                const shouldCharge = overMinutes > 15;
                const overHours = Math.ceil((overMinutes - 15) / 60);
                const amount = (overHours / minRentalPrice.hours) * minRentalPrice[`price__${rentalOrder.rentalItemId}`];
                const onClickReturn = async () => {
                  if(!window.confirm((shouldCharge ? (
                    '返却処理し、延滞料金を追加決済します。'
                  ) : (
                    '返却処理します。'
                  )) + 'よろしいですか？')) return;

                  await returnRentalOrder({
                    agentId: agent.id,
                    rentalOrderId: rentalOrder.id, 
                    shouldCharge,
                    overMinutes,
                    amount,
                  });
                  toggleModal(false);
                  toast.success('返却処理しました。');
                };

                return (
                  <div>
                    <div className="alert alert-success mb-3">
                      レンタル商品を読み込みました。
                    </div>
                    {
                      shouldCharge && (
                        <div className="alert alert-danger">
                          <div>延滞時間が15分を超えています。</div>
                          <div className="mt-2">
                            <div>延滞時間: {overMinutes}分</div>
                            <div>延滞料金: {numeral(amount).format()}円</div>
                          </div>
                        </div>
                      )
                    }
                    <div className="d-flex justify-content-center">
                      <ProgressButton process={onClickReturn}>
                        返却処理
                      </ProgressButton>
                    </div>
                  </div>
                );
              })()
            }
          </div>
        );
      }}
    >
      レンタル返却
    </ModalButton>
  );
}
