import { useState, useEffect } from 'react';

export default function useDocumentsFetch (ref, dependencies = []) {
  const [item, setItem] = useState();
  useEffect(() => {
    if(!ref) return;

    (async () => {
      setItem((_ => ({ id: _.id, ref: _.ref, ..._.data() }))(await ref.get()));
    })();
  }, dependencies);
  return item;
};
