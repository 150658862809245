import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../util';

export default function RichTextContent (props) {
  const { Component = 'div', html = '', ...extraProps } = props;

  return (
    <Component
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(activateRichTextHtml(html), {
          allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img', 'video', 'h2'],
          allowedAttributes: {
            ...sanitizeHtml.defaults.allowedAttributes,
            img: [...sanitizeHtml.defaults.allowedAttributes.img, 'style'],
            video: ['src', 'controls', 'poster', 'style'],
          },
        }),
      }}
      className="app-rte"
      {...extraProps}
    />
  );
}
