import { firebase, functions, DocumentReferenceV8 } from './compat';
// TODO: compatなくなったらこっちでinitializeAppする形にする
// import './app';

export * from './auth';
export * from './firestore';
export * from './functions';
export * from './storage';

export type { DocumentReferenceV8 };
export { functions };
export default firebase;
