import React, { useEffect, useState } from 'react';
import { Label, Button, Form, FormGroup, Input } from 'reactstrap';
import { omitBy, upperFirst, uniqWith, isEqual, get, omit, camelCase, pick, isEmpty, mapValues, keyBy } from 'lodash';
import { set } from 'lodash/fp';
import { useList } from 'react-use';
import { useToggle, useAsync } from 'react-use';
import classnames from 'classnames';
import dedent from 'dedent';
import sanitizeHtml from 'sanitize-html';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { stripHtml } from 'string-strip-html';
import Rte, { getTextAlignClassName } from 'react-rte';
import numeral from 'numeral';

import firebase, { functions } from '../../firebase';
import useFormState from '../hooks/useFormState';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import Field from '../Field';
import QrCode from '../QrCode';
import { prefectures } from '../../shared/config';
import { newFields as fields, acceptFields, newAgentFields, newUserFields, bankFields, } from '../../shared/models/agent';
import { fields as agentShopFields } from '../../shared/models/agentShop';
import { fields as referrerFields } from '../../shared/models/referrer';
import AppButton from '../AppButton';
import SettingText from '../SettingText';

const db = firebase.firestore();
const { entries, keys } = Object;
const prefecturesByPref = mapValues(keyBy(entries(prefectures), '1'), '0');
const checkEmailExistence = functions.httpsCallable('checkEmailExistence');

export default function NewAgentForm(props) {
  const { user, agentRegistration, productsById, statedFields, agentShopStatedFields, referrerStatedFields, agentContractPlan, qrBottomDisplaysById, } = props;
  const userValues = user != null ? { ...user } : {};
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const validationErrorMessages = [
    ...Object.values({ ...statedFields, ...agentShopStatedFields, ...referrerStatedFields, })
      .filter((_) => !_.isValid)
      .map(({ label, validationErrors }) => {
        return `${label}にエラーがあります`;
      }),
  ].filter((_) => _);
  const onClickLogin = (event) => {
    event.stopPropagation();
    props.onClickLogin();
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    statedFields.startAll();
    agentShopStatedFields.startAll();
    referrerStatedFields.startAll();
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    toggleSubmitting(true);
    await props.onSubmit({
      ...mapValues(statedFields, 'value'),
      agentShop: mapValues(agentShopStatedFields, 'value'),
      referrer: mapValues(referrerStatedFields, 'value'),
    });
  };
  const onClickSearchAddress = async (postalCode, statedFields, { fieldNames = ['prefecture', 'city'] } = {}) => {
    try {
      if (!postalCode) return;

      const { results } = await (
        await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`)
      ).json();
      if (isEmpty(results)) return;

      const [
        { address1, address2, address3 },
      ] = results;
      statedFields.setValues({
        [fieldNames[0]]: prefecturesByPref[address1],
        [fieldNames[1]]: address2 + address3,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const { value: isExistingEmail } = useAsync(async () => {
    if (!statedFields.email.isValid) return;

    const { data } = await checkEmailExistence({ email: statedFields.email.value });
    return data.exists;
  }, [statedFields.email.value]);
  useEffect(() => {
    if (user != null) {
      statedFields.setValues({
        ...omitBy(pick(user, ['displayName', 'nameKana', 'phone', 'email', 'postalCode', 'prefecture', 'city', 'address']), isEmpty),
        password: '********',
        passwordConfirmation: '********',
      });
    }
  }, [user]);

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <h4 className="h5 text-center font-weight-bold">ビジネスアカウントお申し込み</h4>
            {user == null && (
              <div className="mt-4 card p-3">
                <div className="mb-2">すでにアカウントをお持ちの方はログインしてください</div>
                <Button color="primary" size="lg" block onClick={onClickLogin}>
                  ログインする
                </Button>
              </div>
            )}
            <div className="mt-5 d-flex flex-column gap-5">
              <div className="border-bottom pb-5">
                <h5 className="font-weight-bold">取扱商品</h5>
                <div className="card p-2">
                  <div className="d-flex flex-column gap-2">
                    {
                      agentRegistration.productRows.map((productRow, i) => {
                        const { productId, } = productRow;
                        const product = productsById[productId];
                        const usesProductMaster = ['referralFeeAmount', 'referralFeeRate'].every(_ => productRow[_] == null);
                        return (
                          <div key={i} className="d-flex gap-1 align-items-center gap-5">
                            <div>
                              {product?.name}
                            </div>
                            <div className="d-flex justify-content-between" style={{ width: 130 }}>
                              <div>紹介料: </div>
                              <div>
                                {
                                  usesProductMaster ? (
                                    product.referralFeeRate != null ? (numeral(product.referralFeeRate).format() + ' %') : ''
                                  ) : {
                                    amount: productRow.referralFeeAmount != null ? (numeral(productRow.referralFeeAmount).format() + ' 円') : '',
                                    rate: productRow.referralFeeRate != null ? (numeral(productRow.referralFeeRate).format() + ' %') : '',
                                  }[productRow.referralFeeType]
                                }
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="border-bottom pb-5">
                <h5 className="font-weight-bold">取扱条件</h5>
                <div className="card p-2" style={{ whiteSpace: 'pre-line', }}>
                  {agentRegistration.conditionNote}
                </div>
              </div>
              {
                [
                  ['規約', keys(acceptFields()), 'agent'],
                  ['代理店情報', keys(newAgentFields()), 'agent'],
                  ['アカウント情報', keys(newUserFields()), 'agent'],
                  ['経理担当・振込口座情報', keys(bankFields()), 'agent'],
                  ['店舗情報', keys(omit(agentShopFields({ forAgent: true }), ['customerCode', 'hidesShopDestination', 'isHidden'])), 'agentShop'],
                  ['リファラ', keys(omit(referrerFields({ forAgent: true }), ['isHidden'])), 'referrer'],
                ].flatMap(([title, fieldNames, type]) => {
                  const _statedFields = ({ agent: statedFields, agentShop: agentShopStatedFields, referrer: referrerStatedFields, })[type];
                  return (
                    <div key={title} className="border-bottom pb-5">
                      <h5 className="font-weight-bold">{title}</h5>
                      {
                        fieldNames.map((fieldName) => {
                          const fieldSetting = _statedFields[fieldName];
                          const fieldContent = (
                            <Field
                              key={fieldName}
                              name={fieldName}
                              documentName="agent"
                              {...fieldSetting}
                              readOnly={type === 'agent' && (!isEmpty(userValues[fieldName]) || (user != null && ['password', 'passwordConfirmation'].includes(fieldName))) ? (_) => true : fieldSetting.readOnly}
                            />
                          );
                          if (fieldName === 'postalCode') {
                            return (
                              <div key={fieldName} className="d-flex align-items-start">
                                <div className="flex-fill">{fieldContent}</div>
                                <div className="ml-2 mt-3 pt-3">
                                  <Button onClick={onClickSearchAddress.bind(null, fieldSetting.value, _statedFields)}>
                                    <span className="fas fa-search mr-1" />
                                    郵便番号で住所検索
                                  </Button>
                                </div>
                              </div>
                            );
                          } else if (fieldName === 'accept') {
                            return (
                              <div key={fieldName}>
                                <div className="overflow-auto border rounded p-3" style={{ height: 150 }}>
                                  <div style={{ whiteSpace: 'pre-line', }}>
                                    {agentContractPlan.text}
                                  </div>
                                </div>
                                {fieldContent}
                              </div>
                            );
                          } else if (type === 'referrer' && fieldName === 'qrBottomDisplayId') {
                            return (
                              <div key={fieldName}>
                                {fieldContent}
                                <div>
                                  <div>※ 以下のようなQRコードが作成されます。(イメージです)</div>
                                  <div className="d-flex justify-content-center mt-3">
                                    <QrCode url={'https://example.com?q=' + Array(300).fill().map(_ => Math.round(Math.random() * 10)).join('')} topText={agentShopStatedFields.name.value} bottomText={qrBottomDisplaysById[referrerStatedFields.qrBottomDisplayId.value]?.display} />
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return fieldContent;
                          }
                        })
                      }
                    </div>
                  );
                })
              }
            </div>
            {user == null &&
              (isExistingEmail && (
                <div className="mt-4 card p-3">
                  <div className="alert alert-warning mb-3">
                    お客様のメールアドレスはすでにアカウント登録されています。
                    <br />
                    ログインしてください。
                  </div>
                  <Button color="primary" size="lg" block onClick={onClickLogin}>
                    ログインする
                  </Button>
                </div>
              ))
            }
            {!(user == null && isExistingEmail) && (
              <div className="d-flex mt-5">
                <AppButton
                  size="lg"
                  color="primary"
                  className="save flex-fill ml-2"
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={isSubmitting}
                >
                  <span
                    className={classnames('fas mr-1', {
                      'fa-arrow-right': !isSubmitting,
                      'fa-spin fa-spinner': isSubmitting,
                    })}
                  />
                  確認画面
                </AppButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </Form>
  );
}
