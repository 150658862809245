import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { keyBy, sortBy, get, omitBy, isUndefined, pick, } from 'lodash';
import Toggle from 'react-toggle'

import { canUpdateAgent, } from '../../shared/abilities';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import AgentQrUrlsFormModal from '../modals/AgentQrUrlsFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import { agentUserFields } from '../../shared/models/user';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const usersRef = db.collection('users');
const qrUrlsRef = db.collection('qrUrls');

export default AdminPage(function AdminAgentQrUrls (props) {
  const { user, match: { params: { agentId } }, addBreadNavValues } = props;
  const [showsFormModal, toggleFormModal] = useToggle(false);
  const agentRef = agentsRef.doc(agentId);
  const { firebaseUser } = useFirebaseUser();
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const qrUrls = sortBy(useCollectionSubscriptionInTenant(qrUrlsRef), _ => _.createdAt.toDate());
  const qrUrlsById = keyBy(qrUrls, 'id');
  const coupons = useCollectionSubscriptionInTenant(db.collection('coupons').orderBy('createdAt'));
  const couponsById = keyBy(coupons, 'id');
  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent-qr-urls container py-5 position-relative">
        <AdminAgentHeader activeTab="qrUrls" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end mb-1">
                <EditButton
                  itemRef={agent.ref}
                  FormModal={AgentQrUrlsFormModal}
                  disabled={!canUpdateAgent(user)}
                  formProps={{ qrUrls, coupons, }}
                />
              </div>
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ minWidth: 300 }}>紹介QRコード遷移先 名称</th>
                    <th style={{ minWidth: 300 }}>紹介QRコード遷移先 説明</th>
                    <th style={{ minWidth: 300 }}>優待</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (agent.showingQrUrls || []).map(({ qrUrlId, couponIds, emailRequestScreenDescription, }) => {
                      const qrUrl = qrUrlsById[qrUrlId];
                      return (
                        <tr key={qrUrlId}>
                          <td>
                            {qrUrl?.name}
                          </td>
                          <td>
                            {qrUrl?.description}
                          </td>
                          <td>
                            {
                              couponIds?.map((couponId) => {
                                return (
                                  <div key={couponId} className="card p-1 mb-1">
                                    {couponsById[couponId]?.name}
                                  </div>
                                );
                              })
                            }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
