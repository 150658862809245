import React from 'react';

export default function InquirySubAccountAlert(props) {
  const { inquiry } = props;

  return (inquiry?.myAccount?.isSubUser || false) && (
    <div className="alert alert-info">
      <div className="font-weight-bold">サブアカウントからの問い合わせです。</div>
      <div className="small mt-2">
        <div>{inquiry.myAccount.displayName}</div>
        <div>{inquiry.myAccount.email}</div>
        <div>{inquiry.myAccount.phone}</div>
      </div>
    </div>
  );
}
