import React from 'react';
import { Button, } from 'reactstrap';
import classnames from 'classnames';

export default function AppButton (props) {
  const { className, Component = Button, ...extraProps } = props;
  return (
    <Component className={classnames('rounded-pill px-4', className)} {...extraProps} />
  );
}
