const { isEmpty, } = require('lodash');
const { colors, } = require('../config');

module.exports = {
  fields: () => {
    return {
      name: {
        label: '名称',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      color: {
        label: '色',
        type: 'select',
        options: colors.map((color, i) => ({ color, value: i })),
        validations: {
          required: v => v != null,
        },
      },
    };
  },
};
