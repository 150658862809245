import React, { Fragment, useEffect, } from 'react';
import { useLocation, useRouteMatch, } from 'react-router-dom';
import { Label, Button, Form, FormGroup, Input, Tooltip } from 'reactstrap';
import { useToggle, useAsync } from 'react-use';
import { pick, } from 'lodash';
import { format as formatDate, } from 'date-fns';
import classnames from 'classnames';

import { fields, } from '../shared/models/help';
import firebase from '../firebase';
import ModalButton from './ModalButton';
import EditButton from './EditButton';
import ModelFormModal from './modals/ModelFormModal';
import RichTextContent from './RichTextContent';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useTenant from './hooks/useTenant';

const db = firebase.firestore();

export default function AdminReaction (props) {
  const { user, reactionKey, ...extraProps }  = props;
  const tenant = useTenant();
  const reactionReportRef = db.collection('reactionReports').doc(reactionKey);
  const reactionReport = useDocumentSubscription(reactionReportRef);
  const [showTooltip, toggleTooltip] = useToggle(false);
  const isOwnReacted = reactionReport?.reactions.some(_ => _.uid === user.id);
  const toggleRead = async () => {
    await reactionReportRef.set({
      tenantId: tenant.id,
      reactions: [
        ...(reactionReport?.reactions || []).filter(_ => _.uid !== user.id),
        ...(isOwnReacted ? [] : [{ code: 'eye', ...pick(user, ['uid', 'id', 'email', 'displayName']), reactedAt: new Date(), }]),
      ],
    }, { merge: true });
  };

  return (
    <div {...extraProps}>
      <span className={classnames('fas fa-eye cursor-pointer', { 'text-info': isOwnReacted, 'text-lightgray': !isOwnReacted })} onClick={toggleRead} />
      {
        reactionReport?.reactions.length > 0 && (
          <Fragment>
            <span className="px-2 small" id="reaction-report-toggler">{reactionReport.reactions.length}</span>
            <Tooltip placement="bottom" isOpen={showTooltip} target="reaction-report-toggler" toggle={toggleTooltip} style={{ maxWidth: '340px' }}>
              <div className="d-flex flex-column gap-1">
                {
                  reactionReport.reactions.map((reaction, i) => {
                    const { displayName, reactedAt, } = reaction;
                    return (
                      <div key={i} className="d-flex justify-content-between gap-3">
                        <div>
                          {displayName}
                        </div>
                        <div>
                          {formatDate(reactedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </Tooltip>
          </Fragment>
        )
      }
    </div>
  );
};
