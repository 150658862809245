import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { ReceivingPlanDocumentData, ReceivingPlan } from '../types';
import type { PartiallyPartial } from 'types';

export const receivingPlanConverter = getConverter<ReceivingPlanDocumentData>();

export const receivingPlansRef = () =>
  collection(getFirestore(), 'receivingPlans').withConverter(receivingPlanConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<ReceivingPlan> : null;
export const receivingPlanRef = <Id extends string | undefined>(receivingPlanId: Id) =>
  (receivingPlanId ? doc(receivingPlansRef(), receivingPlanId) : null) as RefOrNull<Id>;

export const newReceivingPlanRef = () => doc(receivingPlansRef());

export const defaultQueryConstraints = [orderBy('date', 'desc')];

export const receivingPlansQuery = (...queryConstraints: QueryConstraint[]) =>
  query(receivingPlansRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantReceivingPlansQuery = (tenantId: string | undefined) =>
  tenantId ? receivingPlansQuery(...tenantQueryConstraints(tenantId), ...defaultQueryConstraints) : null;

// NOTE: なぜかidの型が違うと怒られるので一旦anyで逃げる
export const addReceivingPlan = async (data: PartiallyPartial<ReceivingPlan, 'id' | 'createdAt'>) =>
  addDoc(receivingPlansRef(), { createdAt: serverTimestamp(), ...data } as any);

export const updateReceivingPlan = async (receivingPlanId: string, data: UpdateData<ReceivingPlan>) =>
  updateDoc(receivingPlanRef(receivingPlanId), data);

export const deleteReceivingPlan = async (receivingPlanId: string) => deleteDoc(receivingPlanRef(receivingPlanId));

export const useReceivingPlanCollection = useCollectionData;

export const useReceivingPlanCollectionOnce = useCollectionDataOnce;

export const useReceivingPlanDocument = useDocumentData;

export const useReceivingPlanDocumentOnce = useDocumentDataOnce;
