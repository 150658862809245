import React from 'react';

import TenantLink from './TenantLink';

export default function InquiryReinquiryAlert(props) {
  const { inquiry } = props;

  return (
    <>
      {(inquiry.sourceInquiryId != null || inquiry.sourceTroubleInquiryId != null) && (
        <div className="alert alert-info">
          <div className="font-weight-bold">再問合せです。</div>
          <div>
            元の問合せは
            {inquiry.sourceInquiryId != null && (
              <TenantLink to={`/admin/inquiries/${inquiry.sourceInquiryId}`}>こちら</TenantLink>
            )}
            {inquiry.sourceTroubleInquiryId != null && (
              <TenantLink to={`/admin/troubleInquiries/${inquiry.sourceTroubleInquiryId}`}>こちら</TenantLink>
            )}
          </div>
        </div>
      )}
      {inquiry.sourceSurveyAnswerId != null && (
        <div className="alert alert-info">
          <div className="font-weight-bold">再問合せです。</div>
          <div>
            元のアンケートは
            {inquiry.sourceSurveyAnswerId != null && (
              <TenantLink to={`/admin/surveyAnswers/${inquiry.sourceSurveyAnswerId}`}>こちら</TenantLink>
            )}
          </div>
        </div>
      )}
    </>
  );
}
