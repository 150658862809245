import React, { useEffect } from 'react';
import { orderBy, maxBy, isEmpty, sumBy, get, keyBy } from 'lodash';
import { format as formatDate, addMonths, startOfMonth, endOfMonth } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { useAsync } from 'react-use';

import { fullPathWithParams } from '../../util';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { destinationFields, conclusiveReferralFee } from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentHeader from '../AgentHeader';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import ExportButton from '../ExportButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';

const { entries } = Object;
const db = firebase.firestore();
const productsRef = db.collection('products');
const productTypesRef = db.collection('productTypes');
const couponsRef = db.collection('coupons');
const getAgentOrders = functions.httpsCallable('getAgentOrders');

export default AgentPage(function AgentReferralOrders(props) {
  const { user, agent, location, history, setBreadNavValues, canEditMembers, } = props;
  const now = new Date();
  const {
    startOn: startOnString,
    endOn: endOnString,
    agentShops: agentShopsForFilter,
    productTypes: productTypesForFilter,
  } = useQueryParams();
  const agentShops = useCollectionSubscription(agent.ref.collection('agentShops').orderBy('createdAt'), [agent]);
  const agentShopOptions = agentShops.map((_) => ({ label: _.name, value: _.id }));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const productTypeOptions = productTypes.map((_) => ({ label: _.name, value: _.id }));
  const { loading: isLoading = false, value: orders } = useAsync(async () => {
    const { data: orders } = await getAgentOrders({
      type: 'referral',
      agentId: agent.id,
      startOn: startOnString,
      endOn: endOnString,
    });
    return orders;
  }, [startOnString, endOnString]);
  const coupons = useDocumentsFetch((orders || []).map(_ => _.couponId).filter(_ => _).map(_ => couponsRef.doc(_)), [orders]);
  const couponsById = keyBy(coupons, 'id');
  const rows = orderBy(
    (orders || [])
      .filter((_) => _.shippedDate && conclusiveReferralFee(productsById, _))
      .map((order) => {
        const { orderItems = [], referrerKey, couponId } = order;
        const orderItemsWithProduct = orderItems.map((orderItem) => {
          const { productId } = orderItem;
          const product = productsById[productId];
          return { ...orderItem, product, order };
        });
        const coupon = couponsById[couponId];
        return {
          order: {
            ...order,
            createdAt: new Date(JSON.parse(order.createdAt)),
            cancelledAt: order.cancelledAt && new Date(JSON.parse(order.cancelledAt)),
          },
          coupon,
          orderItemsWithProduct,
        };
      }),
    'order.createdAt',
    'desc'
  );

  let filteredRows = rows;
  if (!isEmpty(agentShopsForFilter)) {
    filteredRows = filteredRows.filter((_) => agentShopsForFilter.includes(get(_, 'order.agentShop.id')));
  }
  if (!isEmpty(productTypesForFilter)) {
    filteredRows = filteredRows.filter((_) =>
      _.orderItemsWithProduct.some((_) =>
        get(_, 'product.productTypeIds', []).some((_) => productTypesForFilter.includes(_))
      )
    );
  }

  const rowsForExport = async () => {
    const maxLengthOrderItemsRow = maxBy(filteredRows, (_) => _.orderItemsWithProduct.length);
    return filteredRows.map(({ order, orderItemsWithProduct, coupon }) => {
      const {
        id,
        cammacsStatus,
        createdBy,
        createdAt,
        shippedDate,
        cancelledAt,
        amount,
        discountAmount = 0,
        shipmentFee = 0,
        isPreOrder = false,
        name,
        prefecture,
        city,
        agentShop,
        referrer,
      } = order;
      return {
        id,
        agentShopName: agentShop && agentShop.name,
        referrerName: referrer && referrer.name,
        couponId: coupon && coupon.id,
        couponName: coupon && coupon.name,
        totalAmount: amount - discountAmount,
        discountAmount,
        fee: conclusiveReferralFee(productsById, order),
        createdAt: formatDate(createdAt, 'yyyy/MM/dd HH:mm:ss'),
        shippedDate,
        cancelledAt: cancelledAt && formatDate(cancelledAt, 'yyyy/MM/dd HH:mm:ss'),
        name,
        prefecture: prefectures[prefecture],
        city,
        ...entries(destinationFields()).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            [fieldName]: fieldDisplayValue(order[fieldName], fieldSettings),
          };
        }, {}),
        ...maxLengthOrderItemsRow.orderItemsWithProduct.reduce((x, _, i) => {
          const orderItem = orderItemsWithProduct[i];
          return {
            ...x,
            [`orderItem_${i}_productId`]: orderItem && orderItem.product.id,
            [`orderItem_${i}_productCode`]: orderItem && orderItem.product.code,
            [`orderItem_${i}_productName`]: orderItem && orderItem.product.name,
            [`orderItem_${i}_quantity`]: orderItem && orderItem.quantity,
          };
        }, {}),
      };
    });
  };
  const uncancelledRows = filteredRows.filter((_) => _.order.cancelledAt == null);
  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd') },
      location
    );
    history.replace(encodeURI(path));
  };
  useEffect(() => {
    setBreadNavValues({ agent });
  }, [agent]);

  return (
    <div>
      <div className="agent-orders container-fluid py-5 position-relative">
        <AgentHeader activeTab="referralOrders" agent={agent} canEditMembers={canEditMembers} />
        <div className="bg-white p-4">
          <div className="d-flex align-items-end">
            <Button className="ml-0" onClick={onClickDateButton.bind(null, [startOfMonth(now), endOfMonth(now)])}>
              今月
            </Button>
            <Button
              className="ml-2"
              onClick={onClickDateButton.bind(null, [startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}
            >
              先月
            </Button>
            <QueryDateSelector
              className="ml-2"
              paramName="startOn"
              label="開始日"
              history={history}
              location={location}
            />
            <QueryDateSelector
              className="ml-2"
              paramName="endOn"
              label="終了日"
              history={history}
              location={location}
            />
            <QuerySelector
              paramName="agentShops"
              className="ml-2"
              width={250}
              isMulti
              options={agentShopOptions}
              label="店舗で絞込み"
            />
            <QuerySelector
              paramName="productTypes"
              className="ml-2"
              width={250}
              isMulti
              options={productTypeOptions}
              label="商品種別で絞込み"
            />
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-end">
            <div>
              <span>注文件数: {filteredRows.length}</span>
              <span className="ml-3">
                注文商品数: {sumBy(filteredRows.map((_) => _.order.orderItems || []).flat(), 'quantity')}
              </span>
              <span className="ml-3">
                注文合計(キャンセル除く): &yen;{' '}
                {numeral(sumBy(uncancelledRows, (_) => _.order.amount - (_.order.discountAmount || 0))).format()}
              </span>
              <span className="ml-3">
                紹介料合計(キャンセル除く): &yen;{' '}
                {numeral(sumBy(uncancelledRows, (_) => conclusiveReferralFee(productsById, _.order))).format()}
              </span>
            </div>
            <div>
              <ExportButton className="ml-2" fileName="注文.csv" rows={rowsForExport} user={user} />
            </div>
          </div>
          <div className="overflow-auto mt-2">
            {filteredRows.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center text-nowrap">
                  <tr>
                    <th>注文ID</th>
                    <th>店舗</th>
                    <th>リファラ</th>
                    <th>優待</th>
                    <th style={{ minWidth: 300 }}>商品</th>
                    <th>合計金額(税込)</th>
                    <th>割引額(税込)</th>
                    <th>紹介料(税込)</th>
                    <th>注文日時</th>
                    <th>発送日</th>
                    <th>キャンセル日時</th>
                    <th>注文者お名前</th>
                    <th>注文者都道府県</th>
                    <th>注文者市区町村</th>
                    {entries(destinationFields()).map(([fieldName, { label }]) => {
                      return <th key={fieldName}>配送先{label}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {filteredRows.map(({ order, coupon, orderItemsWithProduct }) => {
                    const {
                      id,
                      referrer,
                      agentShop,
                      amount,
                      discountAmount = 0,
                      createdAt,
                      shippedDate,
                      name,
                      prefecture,
                      city,
                      cancelledAt,
                    } = order;

                    return (
                      <tr key={id}>
                        <td>{id}</td>
                        <td>{agentShop && agentShop.name}</td>
                        <td>{referrer && referrer.name}</td>
                        <td>{coupon && coupon.name}</td>
                        <td>
                          {orderItemsWithProduct.map((orderItem) => {
                            const { product, quantity } = orderItem;
                            return (
                              product != null && (
                                <div key={product.code}>
                                  <span>{product.code}</span>
                                  <span className="ml-2">{product.name}</span>
                                  <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
                                </div>
                              )
                            );
                          })}
                        </td>
                        <td className="text-right">{numeral(amount - discountAmount).format('0,0')}</td>
                        <td className="text-right">{numeral(discountAmount).format('0,0')}</td>
                        <td className="text-right">
                          {numeral(conclusiveReferralFee(productsById, order)).format('0,0')}
                        </td>
                        <td>{formatDate(createdAt, 'yyyy/MM/dd HH:mm')}</td>
                        <td>{shippedDate}</td>
                        <td>{cancelledAt != null && formatDate(cancelledAt, 'yyyy/MM/dd HH:mm')}</td>
                        <td>{name}</td>
                        <td>{prefectures[prefecture]}</td>
                        <td>{city}</td>
                        {entries(destinationFields()).map(([fieldName, fieldSettings]) => {
                          return <td key={fieldName}>{fieldDisplayValue(order[fieldName], fieldSettings)}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : isLoading ? (
              <div>
                <span className="fas fa-spin fa-spinner" />
              </div>
            ) : (
              <div>No Data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
