const { isEmpty } = require('lodash');
const dedent = require('dedent');
const numeral = require('numeral');

const { lectureTypes, productTypes, lectureLevels, lectureAges, staffRoles } = require('../config');
const { formatAsTokyoTime } = require('../../lib/date');

const abortReasons = [
  '雨天',
  '延期',
];

function replaceAbortedMail(text, event, lecture, eventProductType, lectureType) {
  return (
    text
      .replace('{{ABORT_REASON}}', lecture.abortReason)
      .replace('{{EVENT_NAME}}', event.name)
      .replace('{{LECTURE_DATE}}', formatAsTokyoTime(lecture.date.toDate(), 'yyyy/MM/dd'))
      .replace('{{LECTURE_PRODUCT_TYPE}}', eventProductType?.name)
      .replace('{{LECTURE_TYPE}}', lectureType?.name)
      .replace('{{LECTURE_LEVEL}}', lectureLevels[lecture.lectureLevel])
      .replace('{{LECTURE_AGE}}', lectureAges[lecture.lectureAge])
      .replace(
        '{{LECTURE_PRICE}}',
        lecture.isUserFree || lecture.price === 0 ? '無料' : `${numeral(lecture.price).format('0,0')}円(税込)`
      )
  );
}

module.exports = {
  abortFields: () => {
    return {
      abortedAt: {
        type: 'datetime',
        label: '中止日時',
        validations: { required:  v => v != null, }
      },
      abortReason: {
        label: '理由',
        type: 'select',
        options: abortReasons.map(_ => ({ label: _, value: _ })),
        validations: { required:  v => !isEmpty(v), }
      },
      abortMailBody: {
        label: '中止メール本文',
        type: 'text',
        rows: 10,
        initialValue: dedent`
          以下を中止しました。

          中止理由: {{ABORT_REASON}}
          イベント名: {{EVENT_NAME}}
          開催日: {{LECTURE_DATE}}
          商品種別: {{LECTURE_PRODUCT_TYPE}}
          レクチャー種別: {{LECTURE_TYPE}}
          対象レベル: {{LECTURE_LEVEL}}
          対象年齢: {{LECTURE_AGE}}
          決済金額: {{LECTURE_PRICE}}
        `
      },
    };
  },
  picFields: ({ staffs = [] }) => {
    return {
      picId: {
        label: '担当者',
        type: 'select',
        options: staffs.map(({ id, displayName, email }) => ({ label: `${displayName} (${email})`, value: id })),
        width: 250
      },
      staffRole: {
        label: '役割',
        type: 'select',
        options: Object.entries(staffRoles).map(([k, v]) => ({ label: v.label, value: k })),
        width: 150
      }
    };
  },
  replaceAbortedMail,
};
