import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { isEmpty, get, isString, sortBy, keyBy, } from 'lodash';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import { beforeDelete, } from '../../util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import AdminAgentHeader from '../AdminAgentHeader';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ModalButton from '../ModalButton';
import QueryBoolean from '../QueryBoolean';
import QuerySelector from '../QuerySelector';
import { fields } from '../../shared/models/rentalItem';
import TenantLink from '../TenantLink';
import AgentRentalItemGroups from '../AgentRentalItemGroups';

const db = firebase.firestore();
const agentsRef = db.collection('agents');
const ordersRef = db.collection('orders');
const storageRef = firebase.storage().ref();

export default AdminPage(function AdminAgentRentalItems (props) {
  const { tenant, user, match: { params: { agentId, } }, addBreadNavValues } = props;
  const queryParams = useQueryParams();
  const agentRef = agentsRef.doc(agentId);
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const rentalItems = sortBy(useCollectionSubscription(agentRef.collection('rentalItems'), [agentId]), 'name');
  const rentalItemGroups = sortBy(useCollectionSubscriptionInTenant(db.collection('agents').doc(agentId).collection('rentalItemGroups'), [agentId]), _ => _.createdAt.toDate());
  const rentalItemGroupsById = keyBy(rentalItemGroups, 'id');
  const groupOptions = rentalItemGroups.map((_) => ({ label: _.name, value: _.id }));

  let filteredRentalItems = rentalItems;
  if (!isEmpty(queryParams.groupIds)) {
    filteredRentalItems = filteredRentalItems.filter((_) => queryParams.groupIds.includes(_.rentalItemGroupId));
  }
  if (queryParams.showsHidden !== '1') {
    filteredRentalItems = filteredRentalItems.filter(_ => !_.isHidden);
  }

  const processValues = async (values) => {
    return (async values => {
      const { id, image } = values;
      if (image == null) return values;

      const imageRef = storageRef.child(`agents/${agent.id}/rentalItems/${id}/image`);
      let imageUrl;
      if (isString(image)) {
        imageUrl = image;
      } else {
        await imageRef.put(image, { contentType: image.type });
        imageUrl = await imageRef.getDownloadURL();
      }
      return { ...values, image: imageUrl };
    })({ ...values, });
  };

  useEffect(() => {
    addBreadNavValues({ agent, })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent-shop container py-5 position-relative">
        <AdminAgentHeader activeTab="rentalItems" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
                <QuerySelector
                  paramName='groupIds'
                  className='ml-0'
                  width={400}
                  isMulti
                  options={groupOptions}
                  label='グループで絞込み'
                />
                <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
              </div>
              <div className="d-flex align-items-end justify-content-end mb-3 gap-1">
                <ModalButton title="グループ" content={_ => <AgentRentalItemGroups agent={agent} />} modalProps={{ style: { minWidth: 400 } }} disabled={!canUpdateAgent(user)}>
                  グループ
                </ModalButton>
                <AddInTenantButton itemRef={agent.ref.collection('rentalItems').doc()} processValues={processValues} FormModal={ModelFormModal} formProps={{ title: 'レンタル商品追加', fields: fields({ rentalItemGroups, }), }} disabled={!canUpdateAgent(user)} />
              </div>
              {
                rentalItems.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>グループ</th>
                        <th>画像</th>
                        <th>名称</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (filteredRentalItems || []).map((rentalItem) => {
                          const { id, ref, rentalItemGroupId, name, image, isHidden = false, } = rentalItem;
                          const rentalItemGroup = rentalItemGroupsById[rentalItemGroupId];
                          const beforeDelete = async () => {
                            if(
                              (await agent.ref.collection('rentalItemEntities').where('rentalItemId', '==', id).limit(1).get()).docs.length > 0
                              || (await agent.ref.collection('rentalOrders').where('rentalItemId', '==', id).limit(1).get()).docs.length > 0
                            ) {
                              toast.error('使用されているため削除できません');
                              return false;
                            }
                          };

                          return (
                            <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                              <td>
                                {rentalItemGroup?.name}
                              </td>
                              <td>
                                <img src={image} style={{ maxHeight: 50 }} />
                              </td>
                              <td>
                                {name}
                              </td>
                              <td className="text-right">
                                <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'レンタル商品編集', fields: fields({ rentalItemGroups, }), }} disabled={!canUpdateAgent(user)} />
                                <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateAgent(user)} beforeDelete={beforeDelete} />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div>
                    レンタル商品は未登録です
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


