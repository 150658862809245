const { isEmpty } = require('lodash');
const dedent = require('dedent');
const { isURL } = require('validator');

const embedKeywords = (text, agent, agentShop, referrer) => {
  return text
    .replace('{{AGENT_NAME}}', agent?.name)
    .replace('{{AGENT_SHOP_NAME}}', agentShop?.name)
    .replace('{{REFERRER_NAME}}', referrer?.name)
};

module.exports = {
  fields: ({ coupons = [] } = {}) => {
    return {
      name: {
        label: '名称',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      url: {
        label: 'URL',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
          format: v => isEmpty(v) || isURL(v),
        },
      },
      description: {
        label: '説明',
        type: 'text',
      },
      couponIds: {
        label: '優待',
        type: 'multiSelect',
        options: coupons.map(_ => ({ ..._, label: _.name, value: _.id })),
      },
      textForQr: {
        label: 'QRコード表示テキスト',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
        showsHelp: true,
      },
      requestsEmail: {
        label: 'メールアドレス入力を求める',
        type: 'boolean',
        initialValue: true,
      },
      emailSkipEnabled: {
        label: 'メールアドレス入力をスキップできる',
        type: 'boolean',
        hidden: _ => !_.requestsEmail,
        initialValue: false,
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      emailRequestScreenTitle: {
        label: 'メールアドレス入力画面タイトル',
        type: 'string',
        hidden: _ => !_.requestsEmail,
        initialValue: '{{AGENT_NAME}}の紹介',
        hint: `
          代理店名: {{AGENT_NAME}}
          店舗名: {{AGENT_SHOP_NAME}}
          リファラ名: {{REFERRER_NAME}}
        `,
      },
      emailRequestScreenDescription: {
        label: 'メールアドレス入力画面説明文',
        type: 'richText',
        rows: 10,
        hidden: _ => !_.requestsEmail,
        initialValue: dedent`
          {{AGENT_NAME}}の紹介での購入となります。
        `
      },
    };
  },
  embedKeywords,
};
