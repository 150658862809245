const { isEmpty, } = require('lodash');

module.exports = {
  fields: () => {
    return {
      name: {
        label: '名称',
        type: 'string',
        validations: {
          required: v => !isEmpty(v),
        },
      },
    };
  },
};

