import React, { useState, useEffect, useMemo, } from 'react';
import { toast } from 'react-toastify';
import { pickBy, isEmpty, keyBy, sortBy, get, pick, omit, mapValues, } from 'lodash';
import { useMap, useList, } from 'react-use';
import { getYear, getMonth, } from 'date-fns';
import retry from 'async-retry';

import firebase, { functions } from '../../firebase';
import { fields, } from '../../shared/models/rentalOrder';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { generateRentalOrderId, generateContentOrderId, } from '../../shared/util';
import useFormState from '../hooks/useFormState';
import RentalOrderBasicForm from '../forms/RentalOrderBasicForm';
import RentalOrderContentProductsForm from '../forms/RentalOrderContentProductsForm';
import RentalOrderConfirmForm from '../forms/RentalOrderConfirmForm';
import TenantUserPage from '../hocs/TenantUserPage';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import RentalOrderFlowSection from '../RentalOrderFlowSection';

const storageRef = firebase.storage().ref();
const { entries, keys, } = Object;
const auth = firebase.auth();
const db = firebase.firestore();
const ordersRef = db.collection('orders');
const createRentalOrder = functions.httpsCallable('createRentalOrder');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');

function NewRentalOrder(props) {
  const { tenant, user, myAccount, history, toggleLoginForm } = props;
  const { agentId, agentShopId, rentalItemId, rentalItemTypeId, createdAt } = useQueryParams();
  const contentProducts = useCollectionSubscriptionInTenant(db.collection('contentProducts'));
  const agentContentProductSettings = useCollectionSubscriptionInTenant(db.collection('agents').doc(agentId).collection('agentContentProductSettings'));
  const agentContentProductSettingsById = useMemo(_ => keyBy(agentContentProductSettings, 'id'), [agentContentProductSettings]);
  const displayContentProducts = useMemo(_ => {
    return contentProducts
      .filter(_ => agentContentProductSettingsById[_.id]?.isShownForRental)
      .map(_ => ({ ..._, price: agentContentProductSettingsById[_.id].price ?? _.price, }));
  }, [contentProducts, agentContentProductSettingsById]);
  const rentalItems = useCollectionSubscription(db.collection('agents').doc(agentId).collection('rentalItems'));
  const rentalItemsById = keyBy(rentalItems, 'id');
  const rentalPrices = sortBy(useCollectionSubscription(db.collection('agents').doc(agentId).collection('rentalPrices').where('agentShopId', '==', agentShopId)), 'hours');
  const statedFields = useFormState({ rentalItemId, }, fields({ rentalItems, rentalPrices, }));
  const [children, { set: setChildren, updateAt: updateChildAt }] = useList([]);
  const [contentProductIds, setContentProductIds] = useState([]);
  const [password, setPassword] = useState('');
  const [currentScreen, setScreen] = useState('basic');

  const createOrUpdateUser = async (values) => {
    if (user != null) {
      // NOTE: 更新
      await user.ref.update({
        ...pick(values, ['phone', 'prefecture', 'nameKana', 'postalCode', 'city', 'address', 'route']),
        ...(!isEmpty(children) && { children }),
      });
      return user;
    } else {
      // NOTE: アカウント登録
      try {
        const { email, name: displayName, } = values;
        const userValues = {
          displayName,
          ...pick(values, [
            'email',
            'phone',
            'prefecture',
            'route',
            'nameKana',
            'postalCode',
            'city',
            'address',
          ]),
          children: children.map((_) => ({ ..._, birthday: JSON.stringify(_.birthday) })),
        };
        const {
          data: { uid },
        } = await retry(
          (_) =>
            createUserAndSendEmailVerification({
              userValues,
              password,
              pathname: encodeURIComponent('/mypage/rentalOrders'),
              skipsEmailVerification: true,
            }),
          { maxTimeout: 1000 }
        );
        try {
          await auth.signInWithEmailAndPassword(email, password);
        } catch (e) {
          // NOTE: 最悪ログインは失敗しても問題ないのでスルー
          console.error(e);
        }
        return { uid, ...userValues };
      } catch (e) {
        console.error(e);
        const code = get(e, 'details.code') || e.code;
        const message = userErrorMessages[code] || '登録に失敗しました';
        toast.error(message);
        throw e;
      }
    }
  };

  const onSubmit = async ({ amount, contentAmount, stripeToken }) => {
    const values = mapValues(statedFields, 'value');
    const id = await generateRentalOrderId();
    const contentOrderId = await generateContentOrderId();
    const userValues = await createOrUpdateUser(values);
    try {
      const { data } = await createRentalOrder({
        rentalOrderId: id,
        rentalOrderValues: {
          tenantId: tenant.id,
          ...values,
          amount,
          createdBy: omit(userValues, 'ref'),
          agentShopId,
        },
        ...(
          !isEmpty(contentProductIds) && {
            contentOrderId,
            contentOrderValues: {
              contentProductIds,
              amount: contentAmount,
              tenantId: tenant.id,
              ...values,
              createdBy: omit(userValues, 'ref'),
              agentId,
              agentShopId,
            },
          }
        ),
        email: values.email,
        stripeToken,
        amount,
        agentId,
      });
      if (get(data, 'error') != null) throw new Error(data.error.message);

      // NOTE: 遷移、トースト
      toast.success('注文完了しました。ありがとうございます。');
      history.push(`/mypage/${tenant.id}/agents/${agentId}/rentalOrders/${id}?fromNewOrder=1`);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    if (user != null) {
      statedFields.setValues({
        name: user.displayName,
        ...pick(user, ['nameKana', 'phone', 'email', 'postalCode', 'prefecture', 'city', 'address', 'route']),
      });
    }
  }, [user != null]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentScreen]);
  const screens = ['basic', 'contents', 'confirm'];

  return (
    <div className="rental-order position-relative">
      <RentalOrderFlowSection activeIndex={screens.indexOf(currentScreen) + 1} />
      <section className="container mt-5 py-3">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            {
              ({
                basic: () => (
                  <RentalOrderBasicForm
                    onSubmit={_ => setScreen('contents')}
                    {...{
                      user,
                      statedFields,
                      rentalItems,
                      rentalPrices,
                      children,
                      password,
                      setChildren,
                      updateChildAt,
                      setPassword,
                      onClickLogin: toggleLoginForm,
                    }}
                  />
                ),
                contents: () => (
                  <RentalOrderContentProductsForm
                    onSubmit={_ => setScreen('confirm')}
                    onClickBack={_ => setScreen('basic')}
                    {...{
                      user,
                      statedFields,
                      contentProducts: displayContentProducts,
                      contentProductIds,
                      setContentProductIds,
                      children,
                    }}
                  />
                ),
                confirm: () => (
                  <RentalOrderConfirmForm
                    onSubmit={onSubmit}
                    onClickBack={_ => setScreen('contents')}
                    {...{
                      user,
                      statedFields,
                      rentalItems,
                      rentalPrices,
                      contentProducts: displayContentProducts,
                      contentProductIds,
                      children,
                    }}
                  />
                ),
              })[currentScreen]()
            }
          </div>
        </div>
      </section>
    </div>
  );
};

NewRentalOrder.preview = true;

export default TenantUserPage(NewRentalOrder);
