import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import { TextEncoder } from 'text-encoding';
import { unparse as unparseCsv } from 'papaparse';
import { uniq, sumBy, get, keyBy, isFunction, isEmpty } from 'lodash';
import { startOfDay, endOfDay } from 'date-fns';
import classnames from 'classnames';
import { differenceInMinutes } from 'date-fns';
import firebase from '../firebase';
import { getCollectionData } from '../shared/firebase';
import { areaFromPostalCode } from '../shared/models/setting';
import ModalButton from './ModalButton';
import OrderExportModal from './modals/OrderExportModal';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import useTenant from './hooks/useTenant';

const db = firebase.firestore();
const ordersRef = db.collection('orders');
const referrersRef = db.collectionGroup('referrers');
const exportRequestsRef = db.collection('exportRequests');

const PROGRESS_MAX = 3;

export default function ExportButton({
  fileName,
  rowsForExport,
  areaSetting,
  qrUrlsById,
  couponsById,
  agentShopsById,
  agentsById,
  productsById,
  user,
  fieldOptions,
  staffOptions,
  prefectureOptions,
  agentOptions,
  productTypeOptions,
  preOrderTypeOptions,
  hasPersonalInfo
}) {
  const [isExporting, setIsExporting] = useState(false);
  const [exportRequest] = useCollectionSubscriptionInTenant(
    exportRequestsRef
      .where('approvalStatus', '==', 'approved')
      .where('addedBy.uid', '==', user?.uid || '')
      .orderBy('approvedAt', 'desc'),
    [user]
  );
  const exportMinutes = differenceInMinutes(new Date(), exportRequest?.downloadStartAt?.toDate());
  const canExport = !hasPersonalInfo || (exportMinutes > 0 && exportMinutes < 60);
  const tenant = useTenant();
  
  const getRows = (orders, referrersById) =>
    orders
      .filter((_) => !_.isEnvelopeOrder)
      .map((order) => {
        const {
          orderItems,
          referrerKey,
          qrUrlId,
          destinationPostalCode,
          contactorPostalCode,
          wholesaleAgentId,
          wholesaleAgentShopId,
          coupon,
          otherCoupons = [],
        } = order;
        const allCoupons = [coupon, ...otherCoupons].filter(_ => _);
        const area = areaFromPostalCode(contactorPostalCode || destinationPostalCode, areaSetting);
        const referrer = referrersById[referrerKey];
        const qrUrl = qrUrlsById[qrUrlId];
        const agentShop = agentShopsById[wholesaleAgentShopId || get(referrer, 'ref.parent.parent.id')];
        const agent = agentsById[wholesaleAgentId || get(agentShop, 'ref.parent.parent.id')];
        const referralLogs = (order.referralLogs || []).map((referralLog) => {
          const { referrerKey, referrerKeySavedAt } = referralLog;
          const referrer = referrersById[referrerKey];
          const agentShop = agentShopsById[referrer?.ref.parent.parent.id];
          const agent = agentsById[agentShop?.ref.parent.parent.id];
          return {
            savedAt: new Date(referrerKeySavedAt),
            agent,
            agentShop,
            referrer,
          };
        });
        const orderItemsWithProduct = orderItems.map((orderItem) => {
          const { productId } = orderItem;
          const product = productsById[productId];
          return { ...orderItem, product, order };
        });
        const bodyQuantity = sumBy(
          orderItemsWithProduct.filter(({ product }) => product?.isBody),
          'quantity'
        );
        const bodyAmount = sumBy(
          orderItemsWithProduct
            .filter(({ product }) => product?.isBody)
            .map((_) => (_.price ?? _.product.price) * _.quantity)
        );
        return {
          order,
          area,
          referrer,
          agentShop,
          agent,
          qrUrl,
          orderItems,
          orderItemsWithProduct,
          bodyQuantity,
          bodyAmount,
          referralLogs,
          allCoupons,
        };
      });

  const onClickExport = async ({ field, text, beginDate, endDate, staffs, prefectures, agents, productTypes, preOrderTypes, withoutCancel, withPartsOrder, referrerIncludes, onProgress = () => null }) => {
    setIsExporting(true);
    if (referrerIncludes) {
      if (!window.confirm('リファラ情報を含むと処理に時間がかかります。よろしいですか？')) return;
    }

    const fieldPath = field === 'id' ? firebase.firestore.FieldPath.documentId() : field;
    const ordersQuery = text
      ? ordersRef
          .where('tenantId', '==', tenant?.id)
          .orderBy(fieldPath)
          .startAt(text)
          .endAt(`${text}\uf8ff`)
      : ordersRef
          .where('tenantId', '==', tenant?.id)
          .where('createdAt', '>=', startOfDay(beginDate))
          .where('createdAt', '<=', endOfDay(endDate))
          .orderBy('createdAt', 'desc');
    const orders = await getCollectionData(ordersQuery);
    onProgress(referrerIncludes ? 1 : 2, PROGRESS_MAX)

    let referrersById = {};
    if (referrerIncludes) {
      const refs = uniq(
        orders.flatMap((_) => [_.referrerKey, ...(_.referralLogs || []).map((_) => _.referrerKey)].filter((_) => _))
      ).map((_) => referrersRef.where('key', '==', _));
      const referrers = await refs.reduce(async (x, ref, index) => {
        const _x = await x;
        const _referrers = await getCollectionData(ref);
        onProgress(1 + ((index + 1) / refs.length), PROGRESS_MAX);
        return [..._x, ..._referrers];
      }, Promise.resolve([]))
      referrersById = keyBy(referrers, 'id');
    }

    let filteredRows = getRows(orders, referrersById)
    if (!text) {
      if (!isEmpty(staffs)) {
        filteredRows = filteredRows.filter((_) => staffs.includes(get(_, 'area.user.id')));
      }
      if (!isEmpty(prefectures)) {
        filteredRows = filteredRows.filter((_) => prefectures.includes(get(_, 'area.prefecture')));
      }
      if (!isEmpty(productTypes)) {
        filteredRows = filteredRows.filter((_) =>
          _.orderItemsWithProduct.some((_) =>
            get(_, 'product.productTypeIds', []).some((_) => productTypes.includes(_)) && _.product.isBody
          )
        );
      }
      if (!isEmpty(agents)) {
        filteredRows = filteredRows.filter((_) => agents.includes(get(_, 'agent.id')));
      }
      if (!isEmpty(preOrderTypes)) {
        filteredRows = filteredRows.filter((_) =>
        preOrderTypes.includes((_.order.isPreOrder || false).toString())
        );
      }
      if (withoutCancel) {
        filteredRows = filteredRows.filter((_) => _.order.cancelledAt == null);
      }
      if (!withPartsOrder) {
        filteredRows = filteredRows.filter((_) => _.orderItemsWithProduct.some((_) => _.product?.isBody));
      }
    }

    const rows = await rowsForExport(filteredRows);
    onProgress(3, PROGRESS_MAX)

    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const fileContent = encoder.encode(unparseCsv(isFunction(rows) ? await rows() : rows));
    fileDownload(fileContent, fileName);
    setIsExporting(false);
  };

  return (
    <ModalButton Modal={OrderExportModal} modalProps={{ onSubmit: onClickExport, fieldOptions, staffOptions, prefectureOptions, agentOptions, productTypeOptions, preOrderTypeOptions }} disabled={isExporting || !canExport}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      期間指定エクスポート
    </ModalButton>
  );
}
