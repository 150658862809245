import React from 'react';
import { sortBy, keyBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import numeral from 'numeral';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdateEnvelopeProduct } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/envelopeProduct';

const db = firebase.firestore();
const envelopeProductsRef = db.collection('envelopeProducts');

export default AdminPage(function AdminEnvelopeProducts (props) {
  const { tenant, user } = props;
  const envelopeProducts = useCollectionSubscriptionInTenant(envelopeProductsRef);
  const sortedEnvelopeProducts = sortBy(envelopeProducts, 'code');
  const products = useDocumentsFetch(envelopeProducts.map(_ => db.collection('products').doc(_.id)), [envelopeProducts]);
  const productsById = keyBy(products, 'id');

  return (
    <div>
      <div className="admin-envelopeProducts container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>郵送物一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton
            itemRef={(_) => envelopeProductsRef.doc(_.code)}
            FormModal={ModelFormModal}
            formProps={{ title: '郵送物追加', fields, }}
            disabled={!canUpdateEnvelopeProduct(user)}
          />
        </div>
        <div>
          {
            sortedEnvelopeProducts.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>商品コード</th>
                    <th>商品名</th>
                    <th>アラート在庫</th>
                    <th>在庫</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sortedEnvelopeProducts.map((envelopeProduct) => {
                      const { id, code, name, alertInventory = 0, ref, } = envelopeProduct;
                      const product = productsById[id];
                      const normalInventory = product?.normalInventory ?? 0;
                      const shouldAlert = normalInventory < alertInventory;
                      const beforeDelete = async () => {
                        if(
                          (await db.collection('envelopeSchedules').where('tenantId', '==', tenant.id).where('envelopeProductIds', 'array-contains', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id} className={classnames({ 'table-danger': shouldAlert })}>
                          <td>
                            {code}
                          </td>
                          <td>
                            {name}
                          </td>
                          <td className='text-right'>{numeral(alertInventory).format('0,0')}</td>
                          <td className='text-right'>{numeral(normalInventory).format('0,0')}</td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '郵送物編集', fields: omit(fields(), ['code']), }} disabled={!canUpdateEnvelopeProduct(user)} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateEnvelopeProduct(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                郵送物は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
