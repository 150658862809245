import React from 'react';
import { keyBy, isFunction, omit, } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle, } from 'react-use';
import classnames from 'classnames';
import { PDFDocument, } from 'pdf-lib';

import firebase from '../firebase';
import { drawQr } from '../qr';
import { getCollectionData } from '../shared/firebase';
import useTenant from './hooks/useTenant';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import ModalButton from './ModalButton';
import ModelFormModal from './modals/ModelFormModal';

const { entries } = Object;
const db = firebase.firestore();
const qrUrlsRef = db.collection('qrUrls');

const positions = {
  topLeft: { label: '左上', vertical: 'top', horizontal: 'left', },
  topRight: { label: '右上', vertical: 'top', horizontal: 'right', },
  bottomLeft: { label: '左下', vertical: 'bottom', horizontal: 'left', },
  bottomRight: { label: '右下', vertical: 'bottom', horizontal: 'right', },
};

const fields = ({ referrers, qrUrls }) => {
  return {
    file: {
      label: 'PDFファイル',
      type: 'file',
      validations: {
        required: v => v != null,
      },
    },
    qrUrlIds: {
      label: '紹介QRコード遷移先',
      type: 'multiSelect',
      options: qrUrls.map(_ => ({ label: _.name, value: _.id, })),
    },
    referrerIds: {
      label: 'リファラ',
      type: 'multiSelect',
      options: referrers.map(_ => ({ label: _.name, value: _.id, })),
    },
    size: {
      label: 'サイズ',
      type: 'integer',
      initialValue: 150,
      validations: {
        greaterThan0: v => v > 0,
      },
    },
    position: {
      label: '位置',
      type: 'select',
      options: entries(positions).map(([k, v]) => ({ label: v.label, value: k, })),
      validations: {
        required: v => v != null,
      },
    },
    marginX: {
      label: '横余白',
      type: 'integer',
      initialValue: 30,
      validations: {
        required: v => v != null,
      },
    },
    marginY: {
      label: '縦余白',
      type: 'integer',
      initialValue: 20,
      validations: {
        required: v => v != null,
      },
    },
  };
};

export default function QrPdfsButton ({ agent, agentShop, }) {

  return (
    <ModalButton Modal={Modal} modalProps={{ agent, agentShop, }}>
      QR PDF
    </ModalButton>
  );
};

const Modal = (props) => {
  const { agentShop, agent, isOpen, onClickClose, } = props;
  const tenant = useTenant();
  const qrUrls = useCollectionSubscriptionInTenant(isOpen && qrUrlsRef, []);
  const filteredQrUrls = qrUrls
    .filter(_ => !_.isHidden)
    .filter(_ => agent?.showingQrUrls?.map(_ => _.qrUrlId).includes(_.id));
  const referrers = useCollectionSubscription(isOpen && agentShop.ref.collection('referrers'), []);
  const qrUrlsById = keyBy(qrUrls, 'id');
  const referrersById = keyBy(referrers, 'id');
  const onSubmit = async (values) => {
    const { file, qrUrlIds, referrerIds, } = values;
    const { vertical, horizontal } = positions[values.position];
    try {
      const qrBottomDisplays = await getCollectionData(db.collection('qrBottomDisplays'));
      const qrBottomDisplaysById = keyBy(qrBottomDisplays, 'id');
      const reader = new FileReader();
      const buffer = await new Promise(((resolve) => {
        reader.addEventListener('load', () => {
          resolve(reader.result);
        });
        reader.readAsArrayBuffer(file);
      }));
      const pdfDoc = await PDFDocument.create();
      const loadedPdfDoc = await PDFDocument.load(buffer);
      await referrerIds.reduce(async (x, referrerId) => {
        await x;

        const referrer = referrersById[referrerId];
        await qrUrlIds.reduce(async (x, qrUrlId) => {
          await x;

          const qrUrl = qrUrlsById[qrUrlId];
          const [page] = await pdfDoc.copyPages(loadedPdfDoc, [0]);
          const { width, height } = page.getSize();
          const text = 'hoge';
          const urlWithKey = `${window.origin}/${tenant.id}/referralRedirect?qrUrlId=${qrUrl.id}&redirectUrl=${encodeURIComponent(qrUrl.url)}&referrerKey=${referrer.key}`;
          const canvas = document.createElement('canvas');
          drawQr(canvas, urlWithKey, agentShop.name, [qrUrl, referrer].map(_ => _.textForQr || '').join('-'), qrBottomDisplaysById[referrer.qrBottomDisplayId]?.display);
          const imageData = canvas.toDataURL('image/jpeg', 0.8);
          const jpgImage = await pdfDoc.embedJpg(imageData)
          const jpgDims = jpgImage.scale(values.size / 350)

          const positionX = ({ left: 0, right: width, })[horizontal] + values.marginX * ({ left: 1, right: -1 })[horizontal] + jpgDims.width * ({ left: 0, right: -1 })[horizontal];
          const positionY = ({ top: height, bottom: 0, })[vertical] + values.marginY * ({ top: -1, bottom: 1 })[vertical] + jpgDims.height * ({ top: -1, bottom: 0 })[vertical];
          page.drawImage(jpgImage, {
            x: positionX,
            y: positionY,
            width: jpgDims.width,
            height: jpgDims.height,
          })
          await pdfDoc.addPage(page);
        }, Promise.resolve());
      }, Promise.resolve());
      const content = await pdfDoc.save()
      const blob = new Blob([content], { type: 'application/pdf' });
      window.open(URL.createObjectURL(blob));
    } catch(e) {
      console.error(e);
    }
  };
  return (
    <ModelFormModal {...{ isOpen, onClickClose, title: 'QR PDF', fields: fields({ referrers, qrUrls: filteredQrUrls, }), submitLabel: 'PDF作成', onSubmit, }} />
  );
};
