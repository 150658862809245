import React from 'react';
import { mapValues, isEmpty, keys, keyBy } from 'lodash';

import firebase from '../../firebase';
import useFormState from '../hooks/useFormState';
import useQueryParams from '../hooks/useQueryParams';
import Field from '../Field';
import AppButton from '../AppButton';
import useCollectionSubscription from '../hooks/useCollectionSubscription';

const fields = ({ products }) => ({
  orderId: {
    label: '注文番号',
    type: 'string',
    validations: {
      required: (_) => !isEmpty(_),
    },
    readOnly: () => true,
  },
  product: {
    label: '商品',
    type: 'select',
    options: (_) => products.map((_) => ({ label: _.label || _.name, value: _.id })),
    validations: { required: (v) => !isEmpty(v) },
  },
});
const db = firebase.firestore();
const productsRef = db.collection('products');

export default function PublicTroubleInquirySelectProductForm(props) {
  const { onSubmit, onBack } = props;
  const { orderId, createdAt, productIds } = useQueryParams();
  const products = useCollectionSubscription(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const filteredProducts = products.filter((_) => productIds.includes(_.id) && _.isBody);
  const statedFields = useFormState({ orderId }, fields({ products: filteredProducts }));
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);

  const handleClick = async () => {
    if (isUnsubmittable) return;

    const values = mapValues(statedFields, 'value');
    const { orderId, product: productId } = values;
    const product = productsById[productId];
    const productTypeId = product?.productTypeIds[0];
    onSubmit({ orderId, createdAt, productId, productTypeId });
  };

  return (
    <>
      <div className="mb-5 text-muted">
        お買上の製品の初期不良やお使いになっての故障、お困りのことがありましたら下記に記入して、お問合せください。
      </div>
      {keys(fields({ products: filteredProducts })).map((fieldName) => {
        const fieldSetting = statedFields[fieldName];
        return (
          <Field
            key={fieldName}
            name={fieldName}
            documentName="troubleInquiry"
            {...fieldSetting}
            options={fieldSetting.options}
            hint={fieldSetting.hint}
          />
        );
      })}
      <p className="mb-5">
        ※ 旧システムの注文番号をお持ちのお客様は<a href="/inquiries/new">こちら</a>よりお問合せください。
      </p>
      <div className="d-flex mt-5">
        <AppButton size="lg" className="cancel flex-fill" color="secondary" onClick={onBack}>
          <span className="fas fa-arrow-left mr-1" />
          戻る
        </AppButton>
        <AppButton
          size="lg"
          className="save flex-fill ml-2"
          color="primary"
          onClick={handleClick}
          disabled={isUnsubmittable}
        >
          <span className="fas fa-arrow-right mr-1" />
          次へ
        </AppButton>
      </div>
    </>
  );
}
