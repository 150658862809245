import React, { Fragment, useState } from 'react';
import { Input } from 'reactstrap';
import { startOfDay, endOfDay } from 'date-fns';
import { isTablet } from 'react-device-detect';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useAppTitle from '../hooks/useAppTitle';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useLocale from '../hooks/useLocale';
import LoadingScreen from '../LoadingScreen';
import EventCheckInButton from '../EventCheckInButton';
import ModalButton from '../ModalButton';
import EventQrcode from '../EventQrcode';
import logoImage from '../../images/logo.png';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default AdminPage(function CheckIn(props) {
  const {
    tenant,
    match: {
      params: { eventId },
    },
  } = props;
  const [checkInCode, setCheckInCode] = useState('');
  const eventRef = eventsRef.doc(eventId);
  const { translate } = useLocale();
  const event = useDocumentSubscription(eventRef);
  const locale = useDocumentSubscription(event && db.collection('locales').doc([tenant.id, event.lang || 'ja'].join('__')), [event]);
  useAppTitle(event?.name + ' チェックイン');
  const lecturesRef = eventRef.collection('lectures');
  const now = new Date();
  const lecturesQuery = lecturesRef.where('date', '>=', startOfDay(now)).where('date', '<=', endOfDay(now));
  const lectures = useCollectionSubscriptionInTenant(lecturesQuery);
  const isSoldOut = lectures.every(({ frames }) =>
    frames.every(({ capacity, entriedCount = 0 }) => capacity <= entriedCount)
  );
  const handleInputChange = (event) => {
    setCheckInCode(event.target.value);
  };
  const handleCheckedIn = async () => {
    setCheckInCode('');
  };

  if (!event) return <LoadingScreen />;

  const description = () => {
    return event.checkInDescription ? (
      <div className="rounded border border-info p-3">
        <RichTextContent html={event.checkInDescription} />
      </div>
    ) : null;
  };

  const inputForm = () => {
    return (
      <Fragment>
        <div className="d-flex flex-column gap-2">
          <p>こちらにチェックインコードを入力してください</p>
          <div className="d-flex gap-2 text-nowrap">
            <Input value={checkInCode} onChange={handleInputChange} />
            <EventCheckInButton
              event={event}
              checkInCode={checkInCode}
              disabled={!checkInCode}
              onCheckedIn={handleCheckedIn}
            />
          </div>
          {event.isEnabledTheDayEntry && !isSoldOut && (
            <div>
              <ModalButton
                color="link"
                className="p-0"
                title="当日申込みQRコード"
                content={() => (
                  <div className="d-flex flex-column justify-content-center align-items-center py-3">
                    <h5>{event.name}</h5>
                    <EventQrcode event={event} />
                    <p>スマホで読込んで申込みをしてください</p>
                  </div>
                )}
              >
                当日申込みはこちらから
              </ModalButton>
            </div>
          )}
        </div>
        {event.checkInNote && <RichTextContent html={event.checkInNote} />}
      </Fragment>
    );
  };

  return translate(
    <div className="container d-flex flex-column pt-5 gap-3">
      <div className="text-center d-flex flex-column gap-4">
        <div className="w-100">
          <img src={logoImage} style={{ maxWidth: '100%' }} />
        </div>
        <h4>{event.name}</h4>
      </div>
      <div className="row">
        {isTablet ? (
          <Fragment>
            <div className="col-lg-8 d-flex flex-column gap-4">{description()}</div>
            <div className="col-lg-4">{inputForm()}</div>
          </Fragment>
        ) : (
          <div className="col-lg-8 offset-lg-2 d-flex flex-column gap-4">
            {description()}
            {inputForm()}
          </div>
        )}
      </div>
    </div>
  , locale);
});
