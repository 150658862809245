import React, { useEffect } from 'react';
import { sortBy, } from 'lodash';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';

import firebase from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { fields } from '../../shared/models/envelopeDelivery';
import AdminPage from '../hocs/AdminPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import DeleteButton from '../DeleteButton';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const envelopeDeliveriesRef = db.collection('envelopeDeliveries');
const { entries } = Object;

export default AdminPage(function AdminEnvelopeDelivery(props) {
  const {
    match: {
      params: { envelopeDeliveryId },
    },
  } = props;
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts').orderBy('code'));
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), _ => _.createdAt.toDate());
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));
  const envelopeDelivery = useDocumentSubscription(envelopeDeliveriesRef.doc(envelopeDeliveryId), [envelopeDeliveryId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [envelopeDelivery]);

  return (
    envelopeDelivery != null && (
      <div className="admin-user container">
        <div className="p-4 bg-white my-4">
          <div className="d-flex justify-content-center mb-3">
            <h4>一括郵便発送詳細</h4>
          </div>
          <div className="d-flex justify-content-end mb-3 gap-2 flex-wrap text-nowrap">
            {['create', 'creating', 'copied'].includes(envelopeDelivery.status) || (
              <Button tag={TenantLink} to={`/admin/envelopeDeliveries/${envelopeDelivery.id}/deliveryTargets`} target="_blank">
                発送対象
              </Button>
            )}
            <DeleteButton itemRef={envelopeDelivery.ref} disabled={!['preview', 'error', 'failed', 'copied'].includes(envelopeDelivery.status)}  />
          </div>
          <div>
            <table className="table table-bordered">
              <tbody className="thead-light">
                {entries({
                  ...fields({ products, envelopeProducts, productTypes, events, userTags, }),
                }).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>{label}</th>
                      <td style={{ whiteSpace: 'pre-line' }}>{fieldDisplayValue(envelopeDelivery[fieldName], fieldSetting)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
});
