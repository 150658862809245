import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { keyBy, get, omitBy, isUndefined, pick } from 'lodash';

import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { roles, agentMemberFields, } from '../../shared/models/agent';
import { canUpdateAgentMember, } from '../../shared/abilities';
import { getCollectionData, } from '../../shared/firebase';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import ModalButton from '../ModalButton';
import ProgressButton from '../ProgressButton';
import AdminAgentHeader from '../AdminAgentHeader';
import AgentMembersPageContent from '../AgentMembersPageContent';
import { agentUserFields } from '../../shared/models/user';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const getUserByEmail = functions.httpsCallable('getUserByEmail');
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const usersRef = db.collection('users');

export default AdminPage(function AdminAgentMembers(props) {
  const { user, match: { params: { agentId }, }, addBreadNavValues, } = props;
  const agentRef = agentsRef.doc(agentId);
  const agent = useDocumentSubscription(agentRef, [agentId]);
  useEffect(() => {
    addBreadNavValues({ agent });
  }, [agent]);

  return (
    agent != null && (
      <div>
        <div className='admin-agent container py-5 position-relative'>
          <AdminAgentHeader activeTab='members' user={user} agent={agent} />
          <AgentMembersPageContent {...{ ...props, agent }} canEditMembers={canUpdateAgentMember(user)} />
        </div>
      </div>
    )
  );
});
