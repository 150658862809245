const { isEmpty } = require('lodash');

const defaultGroups = [
  {
    id: 'eventEarlierQuestion',
    name: 'イベント事前アンケート',
    type: 'eventEarlierQuestion',
  },
  {
    id: 'eventLaterQuestion',
    name: 'イベント事後アンケート',
    type: 'eventLaterQuestion',
  },
  {
    id: 'troubleInquiry',
    name: '不具合問合せアンケート',
    type: 'troubleInquiry',
  },
  {
    id: 'troubleReinquiry',
    name: '不具合再問合せアンケート',
    type: 'troubleReinquiry',
  },
  {
    id: 'methodInquiry',
    name: '乗り方・使い方問合せアンケート',
    type: 'methodInquiry',
  },
  {
    id: 'methodReinquiry',
    name: '乗り方・使い方再問合せアンケート',
    type: 'methodReinquiry',
  },
];

module.exports = {
  defaultGroups,
  fields: () => {
    return {
      name: {
        label: 'グループ名称',
        type: 'string',
        validations: { required: (v) => !isEmpty(v) },
      },
    };
  },
};
