import { getFirestore, collection, doc, QueryConstraint, query, DocumentReference } from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { AreaSettingDocumentData } from '../types';

export const settingsRef = () => collection(getFirestore(), 'settings');

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<AreaSettingDocumentData> : null;
export const settingRef = <Id extends string | undefined>(settingId: Id) =>
  (settingId ? doc(settingsRef(), settingId) : null) as RefOrNull<Id>;

export const newSettingRef = () => doc(settingsRef());

export const tenantAreaSettingRef = (tenantId: string | undefined) =>
  tenantId ? settingRef(`${tenantId}__area`) : null;

export const defaultQueryConstraints = [];

export const settingsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(settingsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const useSettingCollection = useCollectionData;

export const useSettingCollectionOnce = useCollectionDataOnce;

export const useSettingDocument = useDocumentData;

export const useSettingDocumentOnce = useDocumentDataOnce;
