import { useMemo } from 'react';
import { Query } from 'firebase/firestore';
import { useCollectionData as _useCollectionData } from 'react-firebase-hooks/firestore';
import { getCollectionData } from 'src/firebase';

export const useCollectionData = <T>(query?: Query<T> | null) => {
  // TODO: マルチテナント＆v9対応のindexをすべて作成したら削除する
  query && getCollectionData(query)
  const [data, loading, error, snapshot] = _useCollectionData(query, { snapshotOptions: { serverTimestamps: 'estimate' } });
  return useMemo(() => ({ data, loading, error, snapshot }), [data, loading, error, snapshot]);
};
