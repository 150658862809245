import React from 'react';
import { Link } from 'react-router-dom';
import { format as formatDate } from 'date-fns';

import firebase, { getFirestore, collection } from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import UserListSection from '../UserListSection';

const db = firebase.firestore();

export default AdminPage(function AdminMagazineDeliveryHistories(props) {
  const {
    user,
    match: {
      params: { magazineId },
    },
  } = props;
  const collectionPath = `magazines/${magazineId}/deliveryHistories`;
  const usersRef = db.collection(collectionPath);
  const prefixColumns = [
    {
      name: 'createdAt',
      label: (_) => '配信日時',
      body: ({ orderId, createdAt }) => {
        const Component = orderId != null ? Link : 'div';
        return (
          <Component to={`/admin/orders/${orderId}`}>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</Component>
        );
      },
    },
  ];

  return (
    <UserListSection
      user={user}
      usersRef={usersRef}
      title="配信履歴"
      prefixColumns={prefixColumns}
      usersRefV9={collection(getFirestore(), collectionPath)}
    />
  );
});
