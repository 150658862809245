import ModalButton from './ModalButton';
import { InquiryApprovalFormModal } from './modals/InquiryApprovalFormModal';
import { DocumentReference } from '../firebase';
import type { User, Inquiry, TroubleInquiry } from '../types';
import { Fields } from 'types/field';

export const InquiryApprovalButton = ({
  inquiryRef,
  inquiry,
  user,
  fields,
  onApprove,
  onReject,
  disabled,
}: {
  inquiryRef: DocumentReference<TroubleInquiry | Inquiry>;
  inquiry: TroubleInquiry | Inquiry;
  user: User;
  fields: () => Fields;
  onApprove?: (values: Partial<TroubleInquiry | Inquiry>) => Promise<void>;
  onReject?: (values: Partial<TroubleInquiry | Inquiry>) => Promise<void>;
  disabled?: boolean;
}) => {
  return (
    <ModalButton
      color="primary"
      Modal={InquiryApprovalFormModal}
      modalProps={{
        inquiryRef,
        inquiry,
        user,
        fields,
        onSubmit: onApprove,
        onCancel: onReject,
      }}
      disabled={disabled}
    >
      承認
    </ModalButton>
  );
};
