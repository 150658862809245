import React, { useEffect } from 'react';
import qs from 'qs';
import { orderBy, maxBy, omit, isEmpty, sumBy, get, keyBy } from 'lodash';
import { format as formatDate, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';

import { fullPathWithParams } from '../../util';
import { canUpdateOrder } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { cammacsStatuses, fields, destinationFields, contactorFields } from '../../shared/models/order';
import { fields as userFields, adminFields as adminUserFields } from '../../shared/models/user';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import AgentHeader from '../AgentHeader';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ExportButton from '../ExportButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import TenantLink from '../TenantLink';

const { entries } = Object;
const db = firebase.firestore();
const productsRef = db.collection('products');
const productTypesRef = db.collection('productTypes');
const getAgentOrders = functions.httpsCallable('getAgentOrders');

export default AgentPage(function AgentWholesaleOrders(props) {
  const { user, agent, location, history, setBreadNavValues, canEditMembers, } = props;
  const now = new Date();
  const {
    startOn: startOnString,
    endOn: endOnString,
    agentShops: agentShopsForFilter,
    productTypes: productTypesForFilter,
  } = useQueryParams();
  const agentShops = useCollectionSubscription(agent.ref.collection('agentShops').orderBy('createdAt'), [agent]);
  const agentShopsById = keyBy(agentShops, 'id');
  const agentShopOptions = agentShops.map((_) => ({ label: _.name, value: _.id }));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const productTypeOptions = productTypes.map((_) => ({ label: _.name, value: _.id }));
  const { loading: isLoading = false, value: orders = [] } = useAsync(async () => {
    const { data: orders = [] } = await getAgentOrders({
      type: 'wholesale',
      agentId: agent.id,
      startOn: startOnString,
      endOn: endOnString,
    });
    return orders;
  }, [startOnString, endOnString]);
  const tmpOrders = useDocumentsFetch(
    orders.filter((_) => _.tmpOrderId).map((_) => agent.ref.collection('tmpOrders').doc(_.tmpOrderId)),
    [isLoading]
  );
  const tmpOrdersById = keyBy(tmpOrders, 'id');
  const rows = orderBy(
    orders.map((order) => {
      const { orderItems = [], tmpOrderId } = order;
      const tmpOrder = tmpOrdersById[tmpOrderId];
      const orderItemsWithProduct = orderItems.map((orderItem) => {
        const { productId } = orderItem;
        const product = productsById[productId];
        return { ...orderItem, product, order };
      });
      return {
        order: {
          ...order,
          createdAt: new Date(JSON.parse(order.createdAt)),
          cancelledAt: order.cancelledAt && new Date(JSON.parse(order.cancelledAt)),
        },
        tmpOrder,
        agentShop: agentShopsById[order.wholesaleAgentShopId],
        orderItemsWithProduct,
      };
    }),
    'order.createdAt',
    'desc'
  );

  let filteredRows = rows;
  if (!isEmpty(agentShopsForFilter)) {
    filteredRows = filteredRows.filter((_) => agentShopsForFilter.includes(get(_, 'order.wholesaleAgentShopId')));
  }
  if (!isEmpty(productTypesForFilter)) {
    filteredRows = filteredRows.filter((_) =>
      _.orderItemsWithProduct.some((_) =>
        get(_, 'product.productTypeIds', []).some((_) => productTypesForFilter.includes(_))
      )
    );
  }

  const rowsForExport = async () => {
    const maxLengthOrderItemsRow = maxBy(filteredRows, (_) => _.orderItemsWithProduct.length);
    return filteredRows.map(({ order, agentShop, tmpOrder, orderItemsWithProduct }) => {
      const {
        id,
        tmpOrderId,
        cammacsStatus,
        createdBy,
        createdAt,
        shippedDate,
        cancelledAt,
        amount,
        discountAmount = 0,
        shipmentFee = 0,
        wholesaleAmount,
        isPreOrder = false,
        name,
        prefecture,
        city,
      } = order;
      return {
        id,
        tmpOrderId,
        tmpOrderCreatedAt: tmpOrder && formatDate(tmpOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        status: cammacsStatuses[cammacsStatus]?.labelForAgent || cammacsStatuses[cammacsStatus]?.label,
        agentShopName: agentShop && agentShop.name,
        totalAmount: amount - discountAmount,
        wholesaleAmount,
        shipmentFee,
        approvedAt: formatDate(createdAt, 'yyyy/MM/dd HH:mm:ss'),
        shippedDate,
        cancelledAt: cancelledAt && formatDate(cancelledAt, 'yyyy/MM/dd HH:mm:ss'),
        name,
        prefecture: prefectures[prefecture],
        city,
        ...entries({ ...destinationFields(), ...contactorFields() }).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            [fieldName]: fieldDisplayValue(order[fieldName], fieldSettings),
          };
        }, {}),
        ...maxLengthOrderItemsRow.orderItemsWithProduct.reduce((x, _, i) => {
          const orderItem = orderItemsWithProduct[i];
          return {
            ...x,
            [`orderItem_${i}_productId`]: orderItem && orderItem.product.id,
            [`orderItem_${i}_productCode`]: orderItem && orderItem.product.code,
            [`orderItem_${i}_productName`]: orderItem && orderItem.product.name,
            [`orderItem_${i}_quantity`]: orderItem && orderItem.quantity,
          };
        }, {}),
      };
    });
  };
  const uncancelledRows = filteredRows.filter((_) => _.order.cancelledAt == null);
  const onClickDateButton = ([startOn, endOn]) => {
    const path = fullPathWithParams(
      { startOn: formatDate(startOn, 'yyyy-MM-dd'), endOn: formatDate(endOn, 'yyyy-MM-dd') },
      location
    );
    history.replace(encodeURI(path));
  };
  useEffect(() => {
    setBreadNavValues({ agent });
  }, [agent]);

  return (
    <div>
      <div className="agent-orders container-fluid py-5 position-relative">
        <AgentHeader activeTab="wholesaleOrders" agent={agent} canEditMembers={canEditMembers} />
        <div className="bg-white p-4">
          <div className="d-flex align-items-end">
            <Button className="ml-0" onClick={onClickDateButton.bind(null, [startOfMonth(now), endOfMonth(now)])}>
              今月
            </Button>
            <Button
              className="ml-2"
              onClick={onClickDateButton.bind(null, [startOfMonth(addMonths(now, -1)), endOfMonth(addMonths(now, -1))])}
            >
              先月
            </Button>
            <QueryDateSelector
              className="ml-2"
              paramName="startOn"
              label="開始日"
              history={history}
              location={location}
            />
            <QueryDateSelector
              className="ml-2"
              paramName="endOn"
              label="終了日"
              history={history}
              location={location}
            />
            <QuerySelector
              paramName="agentShops"
              className="ml-2"
              width={250}
              isMulti
              options={agentShopOptions}
              label="店舗で絞込み"
            />
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-end">
            <div className="d-flex gap-4">
              <span>注文件数: {numeral(uncancelledRows.length).format()}</span>
              <span>
                注文商品数:{' '}
                {numeral(sumBy(uncancelledRows.map((_) => _.order.orderItems || []).flat(), 'quantity')).format()}
              </span>
              <span>
                上代合計:{' '}
                {numeral(
                  sumBy(
                    uncancelledRows.map((_) => _.order),
                    (_) => _.amount - _.discountAmount
                  )
                ).format()}
              </span>
              <span>
                下代合計:{' '}
                {numeral(
                  sumBy(
                    uncancelledRows.map((_) => _.order),
                    'wholesaleAmount'
                  )
                ).format()}
              </span>
              <span>
                送料合計:{' '}
                {numeral(
                  sumBy(
                    uncancelledRows.map((_) => _.order),
                    'shipmentFee'
                  )
                ).format()}
              </span>
              <span>
                請求金額(下代 + 送料):{' '}
                {numeral(
                  sumBy(
                    uncancelledRows.map((_) => _.order),
                    (_) => (_.wholesaleAmount || 0) + (_.shipmentFee || 0)
                  )
                ).format()}
              </span>
            </div>
            <div>
              <ExportButton className="ml-2" fileName="注文.csv" rows={rowsForExport} user={user} />
            </div>
          </div>
          <div className="overflow-auto mt-2">
            {filteredRows.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center text-nowrap">
                  <tr>
                    <th>注文ID</th>
                    <th>仮注文ID</th>
                    <th>状態</th>
                    <th>店舗</th>
                    <th style={{ minWidth: 300 }}>商品</th>
                    <th>上代(税込)</th>
                    <th>下代(税込)</th>
                    <th>送料(税込)</th>
                    <th>仮注文日時</th>
                    <th>承認日時</th>
                    <th>発送日</th>
                    <th>キャンセル日時</th>
                    <th>注文者お名前</th>
                    <th>注文者都道府県</th>
                    <th>注文者市区町村</th>
                    {entries({ ...destinationFields(), ...contactorFields() }).map(([fieldName, { label }]) => {
                      const prefix = fieldName.startsWith('destination')
                        ? '配送先'
                        : fieldName.startsWith('contactor')
                        ? '利用者情報'
                        : '';
                      return (
                        <th key={fieldName}>
                          {prefix}
                          {label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {filteredRows.map(({ order, tmpOrder, agentShop, orderItemsWithProduct }) => {
                    const {
                      id,
                      tmpOrderId,
                      cammacsStatus = 'initial',
                      amount,
                      discountAmount = 0,
                      shipmentFee,
                      wholesaleAmount,
                      createdAt,
                      shippedDate,
                      name,
                      prefecture,
                      city,
                      cancelledAt,
                      wholesaleAgentShopId,
                    } = order;

                    return (
                      <tr key={id}>
                        <td>{id}</td>
                        <td>{tmpOrderId}</td>
                        <td>
                          {cammacsStatuses[cammacsStatus]?.labelForAgent || cammacsStatuses[cammacsStatus]?.label}
                        </td>
                        <td>{agentShop && agentShop.name}</td>
                        <td style={{ minWidth: 350 }}>
                          {orderItemsWithProduct.map((orderItem, i) => {
                            const { product, quantity } = orderItem;
                            return (
                              product != null && (
                                <div key={i} className="bg-white border rounded p-2">
                                  <div className="mb-1">
                                    <span>{product.code}</span>
                                    <span className="ml-2">{product.name}</span>
                                    <span className="ml-2">{numeral(quantity).format('0,0')}個</span>
                                  </div>
                                  {product.isBody && (
                                    <div className="d-flex gap-1 justify-content-around">
                                      <Button
                                        outline
                                        color="primary"
                                        size="sm"
                                        tag={TenantLink}
                                        target="_blank"
                                        to={`/parts?productId=${product.id}&productTypeId=${product.productTypeIds[0]}&wholesale=1&agentId=${agent.id}&agentShopId=${wholesaleAgentShopId}`}
                                      >
                                        メンテパーツ購入
                                      </Button>
                                      <Button
                                        color="secondary"
                                        size="sm"
                                        tag={TenantLink}
                                        target="_blank"
                                        to={`/troubleInquiry?orderId=${id}&productId=${product.id}&productTypeId=${product.productTypeIds[0]}&wholesale=1&agentId=${agent.id}&agentShopId=${wholesaleAgentShopId}`}
                                      >
                                        不具合・組立を問合せ
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              )
                            );
                          })}
                        </td>
                        <td className="text-right">{numeral(amount - discountAmount).format('0,0')}</td>
                        <td className="text-right">{numeral(wholesaleAmount).format('0,0')}</td>
                        <td className="text-right">{numeral(shipmentFee).format('0,0')}</td>
                        <td>{tmpOrder && formatDate(tmpOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm')}</td>
                        <td>{formatDate(createdAt, 'yyyy/MM/dd HH:mm')}</td>
                        <td>{shippedDate}</td>
                        <td>{cancelledAt != null && formatDate(cancelledAt, 'yyyy/MM/dd HH:mm')}</td>
                        <td>{name}</td>
                        <td>{prefectures[prefecture]}</td>
                        <td>{city}</td>
                        {entries({ ...destinationFields(), ...contactorFields() }).map(([fieldName, fieldSettings]) => {
                          return <td key={fieldName}>{fieldDisplayValue(order[fieldName], fieldSettings)}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : isLoading ? (
              <div>
                <span className="fas fa-spin fa-spinner" />
              </div>
            ) : (
              <div>No Data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
