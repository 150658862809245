const { isMultibyte, isEmail, } = require('validator');
const { isEmpty } = require('lodash');

module.exports = {
  fields: () => {
    return {
      email: {
        type: 'string',
        label: 'メールアドレス',
        validations: {
          required: v => !isEmpty(v),
          format: v => !v || isEmail(v),
          notMultibyte: v => !v || !isMultibyte(v),
        },
        hint: `
          携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
          詳しくは各携帯電話会社にご確認下さい。
        `,
      },
    };
  },
};
