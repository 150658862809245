import React from 'react';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { orderBy, isEmpty, get } from 'lodash';

import { activateRichTextHtml } from '../../util';
import firebase from '../../firebase';
import { canUpdateQuestions } from '../../shared/abilities';
import { questionTypes } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/question';
import { defaultGroups } from '../../shared/models/surveyGroup';
import QuerySelector from '../QuerySelector';
import RichTextContent from '../RichTextContent';
import useQueryParams from '../hooks/useQueryParams';

const db = firebase.firestore();
const questionsRef = db.collection('questions');
const lecturesRef = db.collectionGroup('lectures');
const surveyGroupsRef = db.collection('surveyGroups');

export default AdminPage(function AdminQuestions(props) {
  const { user } = props;
  const { groups: groupsForFilter } = useQueryParams();
  const questions = sortBy(useCollectionSubscriptionInTenant(questionsRef), (_) => _.createdAt.toDate());
  const _surveyGroups = useCollectionSubscriptionInTenant(surveyGroupsRef);
  const surveyGroups = [...defaultGroups, ..._surveyGroups];
  const groupOptions = surveyGroups.map((_) => ({ label: _.name, value: _.id }));

  let filteredQuestions = questions;
  if (!isEmpty(groupsForFilter)) {
    filteredQuestions = filteredQuestions.filter((_) => groupsForFilter.includes(get(_, 'surveyGroupId')));
  }


  return (
    <div>
      <div className="admin-questions container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アンケート一覧</h4>
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QuerySelector
            paramName='groups'
            className='ml-0'
            width={400}
            isMulti
            options={groupOptions}
            label='グループで絞込み'
          />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton
            itemRef={questionsRef.doc()}
            FormModal={ModelFormModal}
            formProps={{ documentName: 'question', title: 'アンケート追加', fields: fields({ surveyGroups }) }}
            disabled={!canUpdateQuestions(user)}
          />
        </div>
        <div>
          {
            questions.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ minWidth: 150, }}>グループ</th>
                    <th style={{ minWidth: 150, }}>名称</th>
                    <th style={{ minWidth: 200, }}>質問文</th>
                    <th style={{ minWidth: 200, }}>ヒント</th>
                    <th style={{ minWidth: 100, }}>タイプ</th>
                    <th style={{ minWidth: 200, }}>選択肢</th>
                    <th style={{ minWidth: 200, }}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    orderBy(filteredQuestions, 'surveyGroupId').map((question) => {
                      const { id, ref, name, description, type, optionsString, isOptional = false, hint = '', isHidden = false, surveyGroupId } = question;
                      const beforeDelete = async () => {
                        if((await lecturesRef.where('questions', 'array-contains', id).limit(1).get()).docs.length > 0) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                  return (
                    <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                      <td>{surveyGroups.find((_) => _.id === surveyGroupId)?.name || ''}</td>
                      <td>
                        {isOptional && <div className="badge badge-info mr-1">任意</div>}
                        {name}
                      </td>
                      <td>{description}</td>
                      <td className="small text-muted">
                        <RichTextContent html={hint} />
                      </td>
                      <td>{questionTypes[type]}</td>
                      <td>{(optionsString || '').split(',').join(' ')}</td>
                      <td className="text-right">
                        <EditButton
                          itemRef={ref}
                          FormModal={ModelFormModal}
                          formProps={{
                            documentName: 'question',
                            title: '申込時アンケート編集',
                            fields: fields({ surveyGroups }),
                          }}
                          disabled={!canUpdateQuestions(user)}
                        />
                        <DeleteButton
                          itemRef={ref}
                          className="ml-2"
                          disabled={!canUpdateQuestions(user)}
                          beforeDelete={beforeDelete}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>アンケートは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});
