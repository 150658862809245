import React from 'react';
import Select from 'react-select';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router';

import { fullPathWithParams, } from '../util';

export default function QuerySelector(props) {
  const { paramName = 'filter', defaultValue = [], label = '絞込み', isMulti = false, width = 200, options = [], disabled = false, isClearable = true, max, selectorProps, isSimple = false, ...extraProps } = props;
  const history = useHistory();
  const location = useLocation();
  const { [paramName]: items = defaultValue } = qs.parse(decodeURI(location.search.slice(1)));
  const onSelect = (selected) => {
    const value = isMulti ? ((max ? selected.slice(0, max) : selected) || []).map(_ => _.value) : (selected ? [selected.value] : []);
    const path = fullPathWithParams({ [paramName]: value, }, location);
    history.replace(encodeURI(path));
  };

  return (
    <div style={{ width, }} {...extraProps}>
      <div className="text-muted small">{label}</div>
      <Select
        isMulti={isMulti}
        value={options.filter(_ => items.includes(_.value))}
        options={options}
        onChange={onSelect}
        className="w-100"
        isDisabled={disabled}
        isClearable={isClearable}
        {...(isSimple && { components: { IndicatorsContainer: _ => null }, })}
        {...selectorProps}
      />
    </div>
  );
};
