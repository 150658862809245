import { sortBy, last, } from 'lodash';
import { addDays, } from 'date-fns';
import { useLocalStorage, } from 'react-use';

import useTenant from './useTenant';

export default function useAvailableReferrerKey () {
  const tenant = useTenant();
  const [referrerKeyInStorage] = useLocalStorage(['referrerKey', tenant.id].join('__'));
  const [referrerKeySavedAtString] = useLocalStorage(['referrerKeySavedAt', tenant.id].join('__'));
  const [referrers] = useLocalStorage(['referrers', tenant.id].join('__'), []);
  const oldReferrerData = referrerKeyInStorage != null ? { referrerKey: referrerKeyInStorage, referrerKeySavedAt: JSON.parse(referrerKeySavedAtString) } : null;
  const availableReferrers = sortBy(
    [...referrers, oldReferrerData]
      .filter(_ => _)
      .map(_ => ({ ..._, referrerKeySavedAt: new Date(_.referrerKeySavedAt), }))
      .filter(_ => _.referrerKeySavedAt > addDays(new Date(), -30))
    ,
    'referrerKeySavedAt'
  );
  const availableReferrer = last(availableReferrers.filter(_ => _.enabledLastReferrerWin)) ?? availableReferrers[0];
  return availableReferrer?.referrerKey ?? null;
};
