import { serverTimestamp } from '../../firebase';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { mapValues } from 'lodash';
import { toast } from 'react-toastify';
import Field from '../Field';
import { auditData } from '../../shared/models/user';
import { TroubleInquiry } from '../../types/troubleInquiry';
import { User } from '../../types/user';
import { discountApprovalFields as fields } from '../../shared/models/troubleInquiry';
import useFormState from '../hooks/useFormState';
import { functions, DocumentReference, updateDoc } from '../../firebase';

const refundOrder = functions.httpsCallable('refundOrder');

export default function InquiryDiscountFormModal({
  inquiryRef,
  inquiry,
  user,
  isOpen,
  onClickClose,
  onSubmit,
  onCancel,
  onSubmitError,
}: {
  inquiryRef: DocumentReference<TroubleInquiry>;
  inquiry: TroubleInquiry;
  user: User;
  isOpen: boolean;
  onClickClose: () => void;
  onSubmit?: (inquiry: TroubleInquiry) => Promise<void>;
  onCancel?: (inquiry: TroubleInquiry) => Promise<void>;
  onSubmitError?: () => Promise<void>;
}) {
  const { discountRequest, sourceOrder } = inquiry;
  const statedFields = useFormState(discountRequest, fields(), isOpen);
  const isUnsubmittable = Object.values(statedFields).some((_: any) => !_?.isValid);
  const handleSubmit = async () => {
    const { amount, note, approvalOrRejectionComment } = mapValues(statedFields, 'value');
    if (!discountRequest || !amount) return;
    if (!window.confirm('承認すると割引処理が行われます。本当に承認しますか？')) return;

    try {
      const {
        data: { error },
      } = await refundOrder({ orderId: sourceOrder?.id, refundOptions: { amount, metadata: { note } } });
      if (error) throw error;

      const updatedData = {
        discount: {
          amount,
          note,
          date: serverTimestamp(),
          registeredAt: serverTimestamp(),
        },
        discountRequest: {
          ...discountRequest,
          status: 'approved',
          approvalOrRejectionComment,
          approvedBy: auditData(user),
          approvedAt: serverTimestamp(),
        },
        updatedBy: auditData(user),
      };
      await updateDoc(inquiry.ref, updatedData);

      // NOTE: inquiryのupdateとstripeへのrefundが完了した時点で割引は成功したとみなすのでここでのエラーはキャッチする
      onSubmit && (await onSubmit({ ...inquiry, ...updatedData }).catch((e) => console.error(e)));
      toast.success('割引しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      await updateDoc(inquiry.ref, {
        discount: null,
        discountRequest: { ...discountRequest, status: 'requested', approvedBy: null, approvedAt: null },
      }).catch((e) => console.error(e));
      onSubmitError && (await onSubmitError().catch((e) => console.error(e)));
      toast.error('失敗しました');
    }
  };
  const handleCancel = async () => {
    const { approvalOrRejectionComment } = mapValues(statedFields, 'value');
    if (!discountRequest) return;
    if (!window.confirm('本当に否認しますか？')) return;

    try {
      const updatedData = {
        discountRequest: {
          ...discountRequest,
          status: 'rejected',
          approvalOrRejectionComment,
          rejectedBy: auditData(user),
          rejectedAt: serverTimestamp(),
        },
        updatedBy: auditData(user),
      };
      await updateDoc(inquiry.ref, updatedData);
      onCancel && (await onCancel({ ...inquiry, ...updatedData }));
      toast.success('否認しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>割引</ModalHeader>
        <ModalBody>
          {Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
            return <Field key={fieldName} name={fieldName} documentName="inquiry" {...(fieldSetting as Object)} />;
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={isUnsubmittable}>
            承認する
          </Button>
          <Button color="primary" outline onClick={handleCancel} disabled={isUnsubmittable}>
            否認する
          </Button>
          <Button onClick={onClickClose}>閉じる</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
