import { addDays, } from 'date-fns';
import { useLocalStorage, } from 'react-use';

import useTenant from './useTenant';

export default function useAvailableQrUrlId () {
  const tenant = useTenant();
  const [qrUrlIdInStorage] = useLocalStorage(['qrUrlId', tenant.id].join('__'));
  const [qrUrlIdSavedAtString] = useLocalStorage(['qrUrlIdSavedAt', tenant.id].join('__'));
  const qrUrlIdSavedAt = qrUrlIdSavedAtString && new Date(JSON.parse(qrUrlIdSavedAtString));
  const availableQrUrlId = qrUrlIdSavedAt > addDays(new Date(), -30) ? qrUrlIdInStorage : null;
  return availableQrUrlId;
};
