import React from 'react';
import { format as formatDate } from 'date-fns';
import { orderBy, keyBy, pick } from 'lodash';
import numeral from 'numeral';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import { basicFields, reimbursementRequestFields } from '../../shared/models/troubleInquiry';
import { fieldDisplayValue } from '../../shared/util';
import UserPage from '../hocs/UserPage';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import AppCard from '../AppCard';
import MypageMessagesButton from '../MypageMessagesButton';
import InquiryReimbursementRequestButton from '../InquiryReimbursementRequestButton';

const { entries } = Object;
const db = firebase.firestore();
const troubleInquiriesRef = db.collection('troubleInquiries');
const productsRef = db.collection('products');

export default UserPage(function MypageTroubleInquiries(props) {
  const { user = {} } = props;
  const troubleInquiries = useCollectionSubscription(
    user.uid && troubleInquiriesRef.where('createdBy.uid', '==', user.uid),
    [user.uid]
  );
  const products = useCollectionSubscription(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const filteredTroubleInquiries = troubleInquiries;
  const sortedTroubleInquiries = orderBy(filteredTroubleInquiries, (_) => _.createdAt.toDate(), 'desc');

  return (
    <div className="mypage-troubleInquiries">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">不具合・組立お問合せ履歴</h5>
      </section>
      <section className="container mt-4">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="d-flex justify-content-center">
              <div>
                お買上げの商品毎に、サポートしています。<br />
                <Link to="/mypage/orders">
                  注文履歴
                </Link>
                から、問合せ、パーツ購入できます。
              </div>
            </div>
            <div className="mt-4">
              {sortedTroubleInquiries.map((troubleInquiry) => {
                return (
                  <TroubleInquiryItem
                    key={troubleInquiry.id}
                    troubleInquiry={troubleInquiry}
                    products={products}
                    productsById={productsById}
                    user={user}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

function TroubleInquiryItem(props) {
  const { troubleInquiry, products, productsById, user } = props;
  const { createdAt, items = [], cancelledAt, hasReimbursement, reimbursementRequest } = troubleInquiry;
  const isCancelled = cancelledAt != null;

  return (
    <div className="mb-4">
      <div className="position-relative" style={{ textDecoration: 'none' }}>
        <AppCard className="card text-dark p-3 p-sm-4" style={{ opacity: isCancelled && 0.5 }}>
          <div className="flex-grow-1">
            <div className="mb-3">
              <div className="small text-muted">お問合せ日時</div>
              {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
            </div>
            {entries(pick(basicFields({ products }), ['productId', 'description'])).map(([fieldName, fieldSetting]) => {
              return (
                <div key={fieldName} className="mb-3 text-truncate">
                  <div className="small text-muted">{fieldSetting.label}</div>
                  {fieldDisplayValue(troubleInquiry[fieldName], fieldSetting)}
                </div>
              );
            })}
            <div>
              <div className="small text-muted">パーツ</div>
              {items.map((item) => {
                const { productId, quantity } = item;
                const product = productsById[productId];

                return (
                  product != null && (
                    <div>
                      <div className="d-flex">
                        <div>
                          <img src={product.image} style={{ maxHeight: 50 }} />
                        </div>
                        <div className="flex-fill ml-2">
                          <div className="d-flex">
                            <div className="flex-fill mx-2">{product.name}</div>
                            <div className="text-nowrap">{numeral(quantity).format('0,0')}個</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-end pt-3">
            {hasReimbursement && (
              <InquiryReimbursementRequestButton
                inquiry={troubleInquiry}
                formFields={reimbursementRequestFields()}
                buttonProps={{
                  color: 'secondary',
                  className: 'rounded-pill px-4',
                  size: 'sm',
                  disabled: !!reimbursementRequest?.requestedAt,
                }}
                user={user}
              />
            )}
            {troubleInquiry.messages?.length > 0 && <MypageMessagesButton color="secondary" className="rounded-pill px-4" size="sm" type="troubleInquiry" subject={troubleInquiry} reinquiryPath="troubleInquiry" />}
          </div>
        </AppCard>
      </div>
    </div>
  );
}
