import React from 'react';
import { sortBy, keyBy, groupBy, range, } from 'lodash';
import { format as formatDate, } from 'date-fns';
import classnames from 'classnames';

import firebase from '../../firebase';
import InvitedUserPage from '../hocs/InvitedUserPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import LectureTitle from '../LectureTitle';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default InvitedUserPage(function Lecture (props) {
  const { match: { params: { eventId, lectureId } } } = props;
  const eventRef = eventsRef.doc(eventId);
  const lecture = useDocumentSubscription(eventRef.collection('lectures').doc(lectureId), [eventId, lectureId]);
  const _entries = useCollectionSubscriptionInTenant(eventRef.collection('entries'), [eventId]);
  const entries = _entries.filter(_ => !_.cancelledAt);
  const entryFrames = entries.map(e => e.frames.map(_ => ({ ..._, entry: e }))).reduce((x, y) => [...x, ...y], []);
  const entryFramesGroupedByFrameIndex = groupBy(entryFrames.filter(_ => _.lectureId === lectureId), 'frameIndex');
  const { frames: lectureFrames = [] } = lecture || {};
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');

  return (
    <div>
      <div className="admin-event container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <LectureTitle {...lecture} {...{ eventProductTypesById, lectureTypesById, }} />
        </div>
        <div>
          {
            lectureFrames.map((lectureFrame, frameIndex) => {
              const { startAt, endAt, capacity = 0, priorCapacity = 0, lectureType, } = lectureFrame;
              const entryFrames = entryFramesGroupedByFrameIndex[frameIndex] || [];
              return (
                <div key={frameIndex} className="card p-3 my-2">
                <div className='d-flex align-items-center mb-2 gap-2'>
                  <div>
                    {formatDate(startAt.toDate(), 'HH:mm')} 〜 {formatDate(endAt.toDate(), 'HH:mm')}
                  </div>
                  <div>
                    {lectureTypesById[lectureType]?.name}
                  </div>
                </div>
                  <div className="d-flex align-items-center flex-wrap">
                    {
                      range(0, capacity).map((seatIndex) => {
                        const { name, entry = {} } = entryFrames[seatIndex] || {};
                        const isPrior = seatIndex < priorCapacity;
                        return (
                          <div key={seatIndex} className={classnames('card p-2 m-1 text-center', { 'border-info': isPrior })} style={{ width: 140, height: 65 }}>
                            <div>
                              {name}
                            </div>
                            <div className="text-muted small">
                              {entry.name}
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
});
