import { Button } from 'reactstrap';
import { keyBy, omit, omitBy, isUndefined, pick } from 'lodash';
import sanitizeHtml from 'sanitize-html';
import { sortBy, isEmpty, get } from 'lodash';
import numeral from 'numeral';
import { format as formatDate } from 'date-fns';

import { activateRichTextHtml } from '../../util';
import firebase from '../../firebase';
import { canUpdateContent } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import { AddInTenantButton } from '../v9/AddInTenantButton';
import { EditButton } from '../v9/EditButton';
import { DeleteButton } from '../v9/DeleteButton';
import { fields } from '../../shared/models/content';
import {
  contentRef,
  newContentRef,
  uploadContentFile,
  deleteContentFile,
  addContent,
  copyContentFile,
  updateContent,
} from '../../models/content';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import RichTextContent from '../RichTextContent';
import { fields as groupFields } from '../../shared/models/contentGroup';
import { orderBy } from 'src/firebase';
import { toast } from 'react-toastify';
import useFirebaseUser from '../hooks/useFirebaseUser';

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export default AdminPage(function AdminContents(props) {
  const { user } = props;
  const tenant = useTenant();
  const { firebaseUser } = useFirebaseUser();
  const { groups: groupsForFilter, showsAllContents: _showsAllContents = '0' } = useQueryParams();
  const showsAllContents = _showsAllContents === '1';
  const contents = sortBy(useCollectionSubscriptionInTenant(db.collection('contents')), _ => _.createdAt.toDate());
  const contentGroups = useCollectionSubscriptionInTenant(db.collection('contentGroups').orderBy('name'));
  const contentGroupsById = keyBy(contentGroups, 'id');
  const groupOptions = contentGroups.map((_) => ({ label: _.name, value: _.id }));
  const convertValues = async (values) => {
    const { uploadFile, ...restValues } = values;
    if (!uploadFile) return restValues;

    const file = await uploadContentFile(restValues.id, uploadFile);
    return { ...restValues, file };
  };
  const beforeDelete = async ({ file }) => {
    if (!file) return true;
    const exists = await storageRef.child(file.path).getDownloadURL().then(() => true).catch(() => false);
    if (exists) {
      await deleteContentFile(file.path);
    }
    return true;
  };

  const handleCopy = async (contentId) => {
    if (!window.confirm('このコンテンツをコピーしますか？')) return;
    try {
      const content = contents?.find((_) => _.id === contentId);
      if (!content) return;
      const ref = await addContent({
        ...omit(content, ['id', 'ref', 'createdAt', 'createdBy', 'updatedBy']),
        title: `${content.title} のコピー`,
        addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      });
      if (content.file) {
        const copiedFile = await copyContentFile(ref.id, content.file);
        await updateContent(ref.id, { file: copiedFile });
      }
      toast.success('コピーしました。');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  if (!contents || !contentGroups) return <></>;

  let filteredContents = sortBy(contents, _ => contentGroupsById[_.contentGroupId]?.name, 'name');
  if (!isEmpty(groupsForFilter)) {
    filteredContents = filteredContents.filter((_) => groupsForFilter.includes(get(_, 'contentGroupId')));
  }
  if (!showsAllContents) {
    filteredContents = filteredContents.filter(({ isHidden }) => !isHidden);
  }

  return (
    <div>
      <div className="admin-contents container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>コンテンツ一覧</h4>
        </div>
        <div className='d-flex justify-content-between flex-wrap gap-2 mb-3'>
          <QuerySelector
            paramName='groups'
            className='ml-0'
            width={400}
            isMulti
            options={groupOptions}
            label='グループで絞込み'
          />
          <div className="d-flex justify-content-end gap-2 align-items-end flex-wrap">
            <QueryBoolean paramName="showsAllContents" label="非表示も表示" defaultValue={'0'} />
            <ModalButton
              title="グループ管理"
              content={(_) => <ContentGroups user={user} contentGroups={contentGroups} />}
              modalProps={{ style: { minWidth: 1300 } }}
            >
              グループ管理
            </ModalButton>
            <AddInTenantButton
              itemRef={newContentRef()}
              processValues={convertValues}
              FormModal={ModelFormModal}
              formProps={{ documentName: 'content', title: 'コンテンツ追加', fields: fields({ contentGroups }) }}
              disabled={!canUpdateContent(user)}
            />
          </div>
        </div>
        <div>
          {contents && contents.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 150 }}>グループ</th>
                  <th style={{ minWidth: 150 }}>名称</th>
                  <th style={{ minWidth: 150 }}>タイトル</th>
                  <th style={{ minWidth: 150 }}>説明</th>
                  <th style={{ minWidth: 150 }}>コンテンツファイル</th>
                  <th style={{ minWidth: 150 }}>メモ</th>
                  <th style={{ minWidth: 150 }}></th>
                </tr>
              </thead>
              <tbody>
                {filteredContents.map((content) => {
                  const { id, name, description, title, isHidden, contentGroupId, file, note, } = content;
                  const group = contentGroups.find((_) => _.id === contentGroupId);

                  return (
                    <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                      <td>{group?.name}</td>
                      <td>{name}</td>
                      <td>{title}</td>
                      <td>
                        <RichTextContent
                          style={{ maxWidth: 400, maxHeight: 200, overflow: 'hidden' }}
                          html={description}
                        />
                      </td>
                      <td>
                        {
                          file != null && (
                            file.name ? (
                              <div>
                                <div className="font-weight-bold">
                                  {file?.name}
                                </div>
                                <div className="small">
                                  {file?.size && numeral(file?.size).format('0.0 b')}
                                </div>
                                <div className="small">
                                  {file?.uploadedAt && formatDate(file?.uploadedAt.toDate(), 'yyyy/MM:dd HH:mm:ss')}
                                </div>
                              </div>
                            ) : (
                              <div className="text-muted">ファイル名等不明</div>
                            )
                          )
                        }
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>
                        {note}
                      </td>
                      <td className="d-flex justify-content-end gap-2 text-nowrap flex-wrap">
                        <Button
                          as={TenantLink}
                          href={`/${tenant?.id}/contents/${id}?preview=true`}
                          target="_blank"
                          aria-label="preview-button"
                        >
                          プレビュー
                        </Button>
                        <EditButton
                          itemRef={contentRef(id)}
                          beforeSubmit={convertValues}
                          FormModal={ModelFormModal}
                          formProps={{
                            documentName: 'content',
                            title: 'コンテンツ編集',
                            fields: fields({ contentGroups }),
                          }}
                          disabled={!canUpdateContent(user)}
                        />
                        <DeleteButton
                          item={content}
                          itemRef={contentRef(id)}
                          beforeDelete={beforeDelete}
                          disabled={!canUpdateContent(user)}
                        />
                        <Button onClick={() => handleCopy(id)} disabled={!canUpdateContent(user)}>
                          <span className="fas fa-copy mr-1" />
                          コピー
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>コンテンツは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});

function ContentGroups(props) {
  const { user, contentGroups, } = props;
  const tenant = useTenant();

  return (
    <div>
      <div className="d-flex justify-content-end mb-3">
        <AddInTenantButton
          itemRef={db.collection('contentGroups').doc()}
          FormModal={ModelFormModal}
          formProps={{ title: 'コンテンツグループ追加', fields: groupFields() }}
          disabled={!canUpdateContent(user)}
        />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>グループ名</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contentGroups.map((contentGroup) => {
            const { id, ref, name } = contentGroup;
            return (
              <tr key={id}>
                <td>{name}</td>
                <td className="text-nowrap text-right">
                  <EditButton
                    itemRef={ref}
                    className="ml-1"
                    FormModal={ModelFormModal}
                    formProps={{ documentName: 'contentGroup', title: 'コンテンツグループ編集', fields: groupFields() }}
                  />
                  <DeleteButton itemRef={ref} className="ml-1" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
