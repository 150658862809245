import React, { useEffect } from 'react';
import { sortBy, mapValues } from 'lodash';

import { canUpdateCartSettings, } from '../../shared/abilities';
import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import RichTextContent from '../RichTextContent';
import { cartSettingsFields } from '../../shared/models/setting';
import { fieldDisplayValue } from '../../shared/util';

const db = firebase.firestore();
const { entries } = Object;

export default AdminPage(function AdminCartSettings (props) {
  const { tenant, user, match: { params: { tenantPath } } } = props;
  const cartSettingsRef = db.collection('settings').doc([tenantPath, 'cart'].join('__'));
  const cartSettings = useDocumentSubscription(cartSettingsRef);
  const userTags = sortBy(useCollectionSubscriptionInTenant(db.collection('userTags')), (_) => _.createdAt.toDate());
  useEffect(() => {
    if(cartSettings === null) {
      cartSettingsRef.set({ tenantId: tenant.id, ...mapValues(cartSettingsFields({ userTags, }), _ => '') });
    }
  }, [cartSettings])

  return cartSettings != null && (
    <div>
      <div className="admin-cart-settings container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="d-flex justify-content-end mb-3">
            <EditButton color="primary" itemRef={cartSettingsRef} FormModal={ModelFormModal} formProps={{ title: 'カート設定', fields: cartSettingsFields({ userTags }), }} disabled={!canUpdateCartSettings(user)} />
          </div>
          <table className="table table-bordered align-middle thead-light">
            <tbody>
              {
                entries(cartSettingsFields({ userTags })).map(([fieldName, fieldSettings]) => {
                  const { label } = fieldSettings;
                  return (
                    <tr key={fieldName}>
                      <th>
                        {label}
                      </th>
                      <td>
                        {
                          fieldSettings.type === 'richText' ? (
                            <RichTextContent html={cartSettings[fieldName] || ''} />
                          ) : (
                            <div style={{ whiteSpace: 'pre-line', }}>
                              {fieldDisplayValue(cartSettings[fieldName], fieldSettings)}
                            </div>
                          )
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
