import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { DocumentReference } from '../firebase';
import InquiryDiscountFormModal from './modals/InquiryDiscountFormModal';
import { TroubleInquiry } from '../types/troubleInquiry';
import { User } from '../types/user';

export const InquiryDiscountButton = ({
  inquiryRef,
  inquiry,
  user,
  disabled,
  onSubmit,
  onCancel,
  onSubmitError,
}: {
  inquiryRef: DocumentReference<TroubleInquiry>;
  inquiry: TroubleInquiry;
  user: User;
  disabled?: boolean;
  onSubmit?: (inquiry: TroubleInquiry) => Promise<void>;
  onCancel?: (inquiry: TroubleInquiry) => Promise<void>;
  onSubmitError?: () => Promise<void>;
}) => {
  const { discount } = inquiry;
  const [showsModal, toggleModal] = useToggle(false);

  return (
    <>
      <Button color='primary' disabled={disabled || !!discount} onClick={() => toggleModal(true)}>
        割引承認
      </Button>
      <InquiryDiscountFormModal
        inquiryRef={inquiryRef}
        inquiry={inquiry}
        user={user}
        isOpen={showsModal}
        onClickClose={() => toggleModal(false)}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onSubmitError={onSubmitError}
      />
    </>
  );
}
