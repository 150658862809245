import React from 'react';
import { format as formatDate } from 'date-fns';
import { uniq, keyBy, last } from 'lodash';
import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';

const db = firebase.firestore();
const accessLogsRef = db.collection('accessLogs');
const referrersRef = db.collectionGroup('referrers');
const qrUrlsRef = db.collection('qrUrls');

export default AdminPage(function AdminUserAccessLogs(props) {
  const {
    match: {
      params: { userId },
    },
  } = props;
  // NOTE: とりあえず3000件。ページネーションを別途。
  const accessLogs = useCollectionSubscriptionInTenant(
    accessLogsRef.where('createdBy.uid', '==', userId).orderBy('createdAt', 'desc').limit(3000),
    [userId]
  );
  const referrers = useCollectionsFetchInTenant(
    uniq(accessLogs.map((_) => _.referrerKey).filter((_) => _)).map((_) => referrersRef.where('key', '==', _)),
    [accessLogs.length]
  );
  const referrersById = keyBy(referrers, 'id');
  const qrUrls = useCollectionFetchInTenant(qrUrlsRef);
  const qrUrlsById = keyBy(qrUrls, 'id');

  return (
    <div>
      <div className="admin-agents container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アクセスログ</h4>
        </div>
        <div>
          <table className="table">
            <thead className="thead-light text-center">
              <tr>
                <th>日時</th>
                <th>テナント</th>
                <th style={{ width: 200 }}>ページタイトル</th>
                <th style={{ width: 300 }}>URL</th>
                <th style={{ width: 300 }}>遷移元</th>
                <th style={{ width: 150 }}>QRコード</th>
                <th style={{ width: 150 }}>リファラ</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accessLogs.map((accessLog, i) => {
                const { id, path, tenantId, documentTitle, before, qrUrlId, referrerKey, createdAt } = accessLog;
                const next = accessLogs[i + 1];
                const qrUrl = qrUrlsById[qrUrlId];
                const referrer = referrersById[referrerKey];
                return (
                  <tr key={id}>
                    <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                    <td>{tenantId || ''}</td>
                    <td>{documentTitle}</td>
                    <td className="text-break">
                      <a href={path}>{path}</a>
                    </td>
                    <td className="text-break">{before !== next?.before && before}</td>
                    <td>{qrUrl?.name}</td>
                    <td>{referrer?.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
