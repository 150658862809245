import React from 'react';
import { groupBy, sortBy, get } from 'lodash';
import { keyBy, } from 'lodash';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { canUpdateEnvelopeSchedule } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ModalButton from '../ModalButton';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { userConditionText, } from '../../shared/util';
import { fields, orderTypes, entryTypes, scheduleTypes, } from '../../shared/models/envelopeSchedule';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const sendSms = functions.httpsCallable('sendSms');

export default AdminPage(function AdminEnvelopeSchedules (props) {
  const { user } = props;
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts').orderBy('code'));
  const envelopeProductsById = keyBy(envelopeProducts, 'id');
  const envelopeSchedules = useCollectionSubscriptionInTenant(db.collection('envelopeSchedules').orderBy('createdAt'));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts
    .filter((_) => _.isBody || _.isOption)
    .map((_) => ({
      ..._,
      label: `${_.isHidden ? '[非表示] ' : ''}[${_.code}] ${_.name}`,
    }));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), _ => _.createdAt.toDate());
  const productTypesById = keyBy(productTypes, 'id');
  const lectures = useCollectionSubscriptionInTenant(db.collectionGroup('lectures').orderBy('date'));
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags').orderBy('createdAt'));
  const userTagsById = keyBy(userTags, 'id');
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const sortedEvents = sortBy(events, (event) => {
    const date = get(lecturesGroupedByEventId, `${event.id}.0.date`);
    return date && date.toDate();
  });

  return (
    <div>
      <div className="admin-inquiry-types container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center mb-3">
                <h4>郵便スケジュール管理</h4>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <AddInTenantButton itemRef={db.collection('envelopeSchedules').doc()} FormModal={ModelFormModal} formProps={{ title: '郵便スケジュール追加', fields: fields({ products: selectableProducts, envelopeProducts, productTypes, events: sortedEvents, userTags, }), }} disabled={!canUpdateEnvelopeSchedule(user)} />
              </div>
              <div className="overflow-auto">
                {
                  envelopeSchedules.length > 0 ? (
                    <table className="table">
                      <thead className="thead-light text-center text-nowrap">
                        <tr>
                          <th>名称</th>
                          <th>注文条件</th>
                          <th>イベント参加条件</th>
                          <th>アカウント条件</th>
                          <th>スケジュール</th>
                          <th>郵送物</th>
                          <th style={{ minWidth: 120 }}></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          envelopeSchedules.map((envelopeSchedule) => {
                            const { id, ref, name, orderType = 'any', conditionProductTypeIds, conditionProductIds, entryType = 'any', conditionEventIds, scheduleType, daysAfter, envelopeProductIds, } = envelopeSchedule;

                            return (
                              <tr key={id} id={id}>
                                <td>
                                  <TenantLink to={`/admin/envelopeSchedules/${id}`}>{name}</TenantLink>                                  
                                </td>
                                <td>
                                  {orderTypes[orderType]?.label}
                                  {
                                    orderType === 'ordered' && (
                                      <div>
                                        <div className="small text-muted">
                                          {conditionProductTypeIds?.map(_ => productTypesById[_]?.name).join(',')}
                                        </div>
                                        <div className="small text-muted">
                                          {conditionProductIds?.map(_ => productsById[_]).map(_ => `[${_?.code}] ${_?.name}`).join(',')}
                                        </div>
                                      </div>
                                    )
                                  }
                                </td>
                                <td>
                                  {entryTypes[entryType]?.label}
                                  {
                                    entryType === 'entried' && (
                                      <div className="small text-muted">
                                        {conditionEventIds?.map(_ => eventsById[_]?.name).join(',')}
                                      </div>
                                    )
                                  }
                                </td>
                                <td className="text-muted small">
                                  {userConditionText(envelopeSchedule, { userTagsById, })}
                                </td>
                                <td>
                                  <span>{scheduleTypes[scheduleType]?.label}</span>
                                  <span>{daysAfter}日後</span>
                                </td>
                                <td>
                                  {
                                    (envelopeProductIds || []).map((envelopeProductId) => {
                                      const envelopeProduct = envelopeProductsById[envelopeProductId];
                                      return (
                                        <div key={envelopeProductId}>
                                          [{envelopeProduct?.code}] {envelopeProduct?.name}
                                        </div>
                                      );
                                    })
                                  }
                                </td>
                                <td>
                                  <TenantLink to={`/admin/envelopeSchedules/${envelopeSchedule.id}/deliveryHistories`} target="_blank">履歴</TenantLink>
                                </td>
                                <td className="text-nowrap text-right">
                                  <EditButton itemRef={ref} className="ml-2" FormModal={ModelFormModal} formProps={{ title: '郵便スケジュール編集', fields: fields({ products: selectableProducts, envelopeProducts, productTypes, events: sortedEvents, userTags, }), }} disabled={!canUpdateEnvelopeSchedule(user)} />
                                  <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateEnvelopeSchedule(user)} />
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      郵便スケジュールは未登録です
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});


