import React, { useEffect, } from 'react';
import { toast } from 'react-toastify';
import qs from 'qs';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import TenantUserPage from './TenantUserPage';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default function InvitedUserPageHOC(WrappedComponent) {
  return TenantUserPage(function InvitedUserPage (props) {
    const { history, location, user, match: { params: { eventId } } } = props;
    const eventRef = eventsRef.doc(eventId);
    const invitedUser = useDocumentSubscription(eventRef.collection('invitedUsers').doc(user.uid), [eventId, user.uid]);
    const canShow = ['admin', 'staff'].includes(user.role) || !!invitedUser;
    const processInvitation = async () => {
      const { eventInvitationToken } = qs.parse(location.search.slice(1));
      if(!eventInvitationToken) return;
      const { exists, ref } = await eventsRef.doc(eventId).collection('invitations').doc(eventInvitationToken).get();
      if(!exists) {
        toast.error('Invalid invitation token.');
        return history.replace(location.pathname);
      }
      await ref.update({ uid: user.uid });
      toast.success('招待を承認しました');
      history.replace(location.pathname);
    };
    useEffect(() => {
      if(user) {
        processInvitation();
      }
    }, [user]);

    return (
      <div className="invited-user-page h-100 mb-5">
        {canShow && <WrappedComponent {...props} />}
      </div>
    );
  });
};
