import { useState, useEffect } from 'react';

export default function useDocumentsFetch (refs, dependencies = [], { initialItems = [] } = {}) {
  const [items, setItems] = useState(initialItems);
  useEffect(() => {
    if(refs == null) return;

    (async () => {
      setItems(
        (await Promise.all(refs.map(_ => _?.get())))
          .map(_ => _ && ({ id: _.id, ref: _.ref, ..._.data() }))
      );
    })();
  }, dependencies);
  return items;
};
