import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isEmpty, keyBy, pick } from 'lodash';
import { startOfDay, endOfDay, } from 'date-fns';
import { useAsync, } from 'react-use';
import dedent from 'dedent';

import TenantUserPage from '../hocs/TenantUserPage';
import { batch, getDocumentData, getCollectionData } from '../../shared/firebase';
import firebase from '../../firebase';
import useLocale from '../hooks/useLocale';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';

const { entries } = Object;
const db = firebase.firestore();

function CheckInDirect(props) {
  const {
    user,
    history,
    match: {
      params: { tenantPath, eventId },
    },
  } = props;
  const { translate } = useLocale();
  const event = useDocumentSubscription(db.collection('events').doc(eventId), [eventId]);
  const locale = useDocumentSubscription(event && db.collection('locales').doc([tenantPath, event.lang || 'ja'].join('__')), [event]);
  useAsync(async () => {
    try {
      const todayLectures = await getCollectionData(db.collection(`events/${eventId}/lectures`).where('date', '>=', startOfDay(new Date())).where('date', '<=', endOfDay(new Date())));
      if(todayLectures.length === 0) return;

      const event = await getDocumentData(db.doc(`events/${eventId}`));
      const entries = await getCollectionData(db.collection(`events/${eventId}/entries`).where('cancelledAt', '==', null).where('checkedInAt', '==', null).where('createdBy.uid', '==', user.id).where('lectureIds', 'array-contains-any', todayLectures.map(_ => _.id)));
      await batch(db, entries, (b, _) => b.update(_.ref, { checkedInAt: new Date() }));
      const entryIndexes = await Promise.all(entries.map(async (entry) => {
        const entries = await getCollectionData(db.collection(`events/${eventId}/entries`).where('lectureIds', 'array-contains', entry.frames[0].lectureId).orderBy('createdAt'));
        return entries.findIndex(_ => _.id === entry.id) + 1;
      }));
      toast.success(translate(dedent`
        チェックインが完了しました。
        番号は${entryIndexes.map(_ => `${event?.entryIndexPrefix}${_}`).join(', ')}です。
      `, locale), { autoClose: false });
      history.push('/mypage/entries');
    } catch(e) {
      toast.error(translate('チェックインできませんでした', locale));
    }
  }, []);

  return translate(
    <div className="check-in-direct py-5">
      <div className="d-flex justify-content-center">
        <div>
          <span className="fas fa-spin fa-spinner mr-1" />
          チェックイン中...
        </div>
      </div>
    </div>
  , locale);
}

export default TenantUserPage(CheckInDirect);
