import { isEmpty, uniq, omit, orderBy, keyBy } from 'lodash';
import { format as formatDate, startOfDay, endOfDay, addMonths, addDays, } from 'date-fns';
import ellipsis from 'text-ellipsis';

import firebase from '../../firebase';
import { statuses, fields as _fields, reimbursementResultFields } from '../../shared/models/inquiry';
import { areaFromPostalCode } from '../../shared/models/setting';
import { fieldDisplayValue } from '../../shared/util';
import { supportMeans as supportMeanOptions } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import useQueryParams from '../hooks/useQueryParams';
import ExportButton from '../ExportButton';
import QueryText from '../QueryText';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import TenantLink from '../TenantLink';
import useTenant from '../hooks/useTenant';
import {
  inquiryRef,
} from '../../models/inquiry';
import { useInquiryCommentCollectionsOnce, tenantLimitToLastInquiryCommentsQuery } from '../../models/inquiryComment';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import { useInquiryTypeCollection, tenantInquiryTypesQuery } from '../../models/inquiryType';
import { useInquiryTypeChildCollection, tenantInquiryTypeChildrenQuery } from '../../models/inquiryTypeChild';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { entries } = Object;
const db = firebase.firestore();
const ordersRef = db.collection('orders');

export default AdminPage(function AdminInquiries(props) {
  const tenant = useTenant();
  const { user } = props;
  const queryParams = useQueryParams();
  const {
    text = '',
    field: [field] = ['email'],
    picNames: picNamesForFilter,
    statuses: statusesForFilter,
    reimbursementOnly: _reimbursementOnly = '0',
    approvalRequiredOnly: _approvalRequiredOnly = '0',
    dateRange,
  } = queryParams;
  const startOn = queryParams.dateRange?.[0] ? new Date(queryParams.dateRange[0]) : addDays(addMonths(new Date(), -6), 1);
  const endOn = queryParams.dateRange?.[1] ? new Date(queryParams.dateRange[1]) : new Date();
  const reimbursementOnly = _reimbursementOnly === '1';
  const approvalRequiredOnly = _approvalRequiredOnly === '1';
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const { data: inquiryTypes = [] } = useInquiryTypeCollection(tenantInquiryTypesQuery(tenant?.id));
  const { data: inquiryTypeChildren = [] } = useInquiryTypeChildCollection(tenantInquiryTypeChildrenQuery(tenant?.id));
  const fields = omit(_fields({ inquiryTypes, inquiryTypeChildren }), ['files']);
  const inquiriesRef = text ? (
    db.collection('inquiries')
      .where(field, '>=', text)
      .where(field, '<=', text + '\uf8ff')
      .orderBy(field, 'asc')
  ) : (
    db.collection('inquiries').where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)).orderBy('createdAt', 'desc')
  );
  const inquiries = useCollectionSubscriptionInTenant(inquiriesRef, [text, ...[startOn, endOn].map(_ => formatDate(_, 'yyyy/MM/dd'))]);
  const lastCommentQueries = tenant
    ? inquiries.map(({ id }) => tenantLimitToLastInquiryCommentsQuery(inquiryRef(id), tenant.id, 1))
    : [];
  const { data: lastComments = [] } = useInquiryCommentCollectionsOnce(lastCommentQueries);
  const lastCommentsByInquiryId = keyBy(lastComments, ({ ref }) => ref.parent.parent.id);
  const rows = inquiries.map((inquiry) => {
    const { postalCode, respondedBy } = inquiry;
    const area = areaFromPostalCode(postalCode, areaSetting);
    const picName = respondedBy?.displayName || area?.user.displayName;
    const { body: lastComment = '' } = lastCommentsByInquiryId[inquiry.id] || {};
    return { inquiry, area, picName, lastComment };
  });
  const picNameOptions = uniq(rows.map((_) => _.picName))
    .filter((_) => _)
    .map((_) => ({ label: _, value: _ }));
  const statusOptions = entries(statuses).map(([value, { label }]) => ({
    label,
    value,
  }));
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウントID', value: 'createdBy.uid' },
  ];

  // NOTE: filter
  let filteredRows = rows;
  if (!isEmpty(picNamesForFilter)) {
    filteredRows = filteredRows.filter((_) => picNamesForFilter?.includes(_.picName));
  }
  if (!isEmpty(statusesForFilter)) {
    filteredRows = filteredRows.filter((_) => statusesForFilter?.includes(_.inquiry.status));
  }
  if (reimbursementOnly) {
    filteredRows = filteredRows.filter((_) => _.inquiry.hasReimbursement);
    filteredRows = orderBy(
      filteredRows,
      [({ inquiry: { reimbursementResult } }) => reimbursementResult?.date.toDate() || 0],
      ['desc']
    );
  }
  if (approvalRequiredOnly) {
    filteredRows = filteredRows.filter((_) => _.inquiry.approvalRequired);
    filteredRows = orderBy(filteredRows, [({ inquiry: { approvedAt } }) => approvedAt?.toDate()], ['desc']);
  }

  const rowsForExport = () => {
    return filteredRows.map(({ inquiry, area, lastComment }) => {
      const {
        id,
        status,
        createdAt,
        respondedBy,
        reimbursementResult,
        approvedAt,
        approvalRequestComment,
        approvalOrRejectionComment,
        supportMeans,
        supportSummary,
      } = inquiry;
      return {
        id,
        status,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        areaStaff: area?.user.displayName,
        respondedBy: respondedBy?.displayName,
        ...entries(fields).reduce((x, [fieldName, fieldSettings]) => {
          return {
            ...x,
            // TODO: fieldDisplayValueの型を修正する（sharedのままだとtsにはできない）
            [fieldName]: fieldDisplayValue(inquiry[fieldName], {
              ...fieldSettings,
              values: inquiry,
            }),
          };
        }, {}),
        reimbursementDate: reimbursementResult?.date && formatDate(reimbursementResult.date.toDate(), 'yyyy/MM/dd'),
        reimbursementAmount: reimbursementResult?.amount,
        reimbursementNote: reimbursementResult?.note,
        approvedAt: approvedAt && formatDate(approvedAt.toDate(), 'yyyy/MM/dd'),
        approvalRequestComment,
        approvalOrRejectionComment,
        lastComment,
        supportMeans: supportMeans?.map((_) => supportMeanOptions[_]),
        supportSummary,
      };
    });
  };

  return (
    <div>
      <div className="admin-trouble-inquiries container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>お問合せ一覧</h4>
        </div>
        <div className="d-flex flex-wrap gap-3 mb-2">
          <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} disabled={!!text} />
          <div className="d-flex flex-wrap align-items-end gap-1">
            <QuerySelector
              paramName="field"
              options={fieldOptions}
              label="検索フィールド"
              defaultValue={[field]}
              isClearable={false}
            />
            <QueryText paramName="text" label="検索テキスト" />
          </div>
        </div>
        <div className="d-flex flex-wrap gap-1 mb-3 align-items-end">
          <QuerySelector paramName="picNames" width={250} isMulti options={picNameOptions} label="担当で絞込み" />
          <QuerySelector paramName="statuses" width={250} isMulti options={statusOptions} label="状態で絞込み" />
          <QueryBoolean paramName="reimbursementOnly" label="立替有のみ表示" defaultValue={'0'} />
          <QueryBoolean paramName="approvalRequiredOnly" label="要承認のみ表示" defaultValue={'0'} />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <ExportButton className="ml-2" fileName="お問合せ.csv" rows={rowsForExport} hasPersonalInfo user={user} />
        </div>
        <div className="overflow-auto">
          {rows.length > 0 ? (
            <table className="table table-bordered">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 150 }}>ID</th>
                  <th style={{ minWidth: 80 }}>状態</th>
                  <th style={{ minWidth: 150 }}>お問合せ日時</th>
                  <th style={{ minWidth: 150 }}>担当</th>
                  <th style={{ minWidth: 150 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>お名前</th>
                  <th style={{ minWidth: 150 }}>カテゴリ</th>
                  <th style={{ minWidth: 150 }}>子カテゴリ</th>
                  <th style={{ minWidth: 400 }}>内容</th>
                  <th style={{ minWidth: 200 }}>電話可能時間</th>
                  <th style={{ minWidth: 150 }}>ファイル</th>
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 150 }}>郵便番号</th>
                  <th style={{ minWidth: 150 }}>番地・建物名</th>
                  {entries(reimbursementResultFields()).map(([fieldName, { label }]) => {
                    const minWidth = fieldName === 'note' ? 400 : 150;

                    return (
                      <th key={fieldName} style={{ minWidth }}>
                        {label}
                      </th>
                    );
                  })}
                  <th style={{ minWidth: 150 }}>承認日</th>
                  <th style={{ minWidth: 400 }}>承認申請コメント</th>
                  <th style={{ minWidth: 400 }}>承認/否認コメント</th>
                  <th style={{ minWidth: 400 }}>対応コメント</th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((_) => <InquiryRow key={_.inquiry.id} {..._} fields={fields} />)}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});

const InquiryRow = ({ inquiry, picName, lastComment, fields }) => {
  const {
    id,
    createdAt,
    files,
    status,
    createdBy,
    approvalStatus,
    approvedAt,
    approvalRequestComment,
    approvalOrRejectionComment,
    isAdminCreated,
    orderId
  } = inquiry;
  const { label: statusLabel, color } = statuses[status] || {};
  const shipmentOrder = useDocumentSubscription(orderId && ordersRef.doc(orderId), [orderId]);
  return (
    <tr key={id}>
      <td>
        <TenantLink to={`/admin/inquiries/${id}`}>{id}</TenantLink>
        {isAdminCreated && <span className="badge badge-info ml-1">管理者作成</span>}
      </td>
      <td>
        <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
        {shipmentOrder
          ? (shipmentOrder?.cammacsStatus === 'shipped'
            ? <span className="badge badge-success">発送完了</span>
            : <span className="badge badge-info">発送依頼中</span>)
          : ''
        }
        {approvalStatus === 'requested' && <span className="badge badge-danger">承認待ち</span>}
      </td>
      <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
      <td>{picName}</td>
      {[
        'prefecture',
        'city',
        'name',
        'inquiryTypeId',
        'inquiryTypeChildId',
        'description',
        'callableTime',
      ].map((fieldName) => {
        // TODO: fieldDisplayValueの型を修正する（sharedのままだとtsにはできない）
        const displayValue = fieldDisplayValue(inquiry[fieldName], {
          ...fields[fieldName],
          values: inquiry,
        });
        return (
          <td key={fieldName}>
            {fieldName === 'name' && createdBy ? (
              <TenantLink to={`/admin/users/${createdBy.uid}`}>{displayValue}</TenantLink>
            ) : (
              ellipsis(displayValue, 80)
            )}
          </td>
        );
      })}
      <td>
        {files.map(({ name, url }, i) => {
          return (
            <div key={i}>
              <a href={url} target="_blank">
                {name}
              </a>
            </div>
          );
        })}
      </td>
      {['phone', 'email', 'postalCode', 'address'].map((fieldName) => {
        return (
          <td key={fieldName}>
            {/* TODO: fieldDisplayValueの型を修正する（sharedのままだとtsにはできない） */}
            {ellipsis(fieldDisplayValue(inquiry[fieldName], fields[fieldName]), 80)}
          </td>
        );
      })}
      {entries(reimbursementResultFields()).map(([fieldName, fieldSetting]) => {
        return (
          <td key={fieldName}>
            {/* TODO: fieldDisplayValueの型を修正する（sharedのままだとtsにはできない） */}
            {fieldDisplayValue(inquiry.reimbursementResult?.[fieldName], fieldSetting)}
          </td>
        );
      })}
      <td>{approvedAt && formatDate(approvedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
      <td>{approvalRequestComment}</td>
      <td>{approvalOrRejectionComment}</td>
      <td>{lastComment}</td>
    </tr>
  );
}
