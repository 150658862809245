import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
  UpdateData,
  SetOptions,
  getCollectionData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import { useDocumentsDataOnce } from '../components/hooks/v9/useDocumentsDataOnce';
import type { UserDocumentData, User } from '../types';
import type { PartiallyPartial } from 'types';

export const userConverter = getConverter<UserDocumentData>();

export const usersRef = () => collection(getFirestore(), 'users').withConverter(userConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<User> : null;
export const userRef = <Id extends string | undefined>(userId: Id) =>
  (userId ? doc(usersRef(), userId) : null) as RefOrNull<Id>;

export const newUserRef = () => doc(usersRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'desc')];

export const usersQuery = (...queryConstraints: QueryConstraint[]) =>
  query(usersRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const usersByRolesQuery = (roles: string[]) => usersQuery(where('role', 'in', roles));

// NOTE: なぜかidの型が違うと怒られるので一旦anyで逃げる
export const addUser = async (data: PartiallyPartial<User, 'id' | 'createdAt'>) =>
  addDoc(usersRef(), { createdAt: serverTimestamp(), ...data } as any);

export const updateUser = async (uid: string, data: UpdateData<User>) => updateDoc(userRef(uid), data);

export const deleteUser = async (uid: string) => deleteDoc(userRef(uid));

export const setUser = async (uid: string, data: Partial<User>, options: SetOptions = {}) =>
  setDoc(userRef(uid), data, options);

export const getUserCollection = () => getCollectionData(usersQuery());

export const useUserCollection = useCollectionData;

export const useUserCollectionOnce = useCollectionDataOnce;

export const useUserDocument = useDocumentData;

export const useUserDocumentOnce = useDocumentDataOnce;

export const useUserDocumentsOnce = useDocumentsDataOnce;
