const { partition, omit, isEmpty, sumBy, flatten, sortBy } = require('lodash');

const { max } = Math;

module.exports = {
  fields: ({ rentalItems }) => {
    return {
      name: {
        label: '名称',
        type: 'string',
      },
      hours: {
        label: '時間',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
      isHidden: {
        label: '非表示',
        type: 'boolean',
        initialValue: false,
      },
      ...rentalItems.reduce((x, rentalItem) => {
        return {
          ...x,
          [`price__${rentalItem.id}`]: {
            label: `料金 ${rentalItem.name}`,
            type: 'integer',
            validations: {
              greaterThanOrEqualTo0: v => v != null && v >= 0,
            },
            showsTextInput: true,
          },
        };
      }, {}),
    };
  },
};
