import React, { useState } from 'react';
import { keyBy, get, pick, sortBy } from 'lodash';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import randomstring from 'randomstring';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import { colors } from '../../shared/config';
import { fieldDisplayValue } from '../../shared/util';
import { canUpdateCoupon } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields, statuses } from '../../shared/models/coupon';
import QueryBoolean from '../QueryBoolean';
import useQueryParams from '../hooks/useQueryParams';
import RichTextContent from '../RichTextContent';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const productsRef = db.collection('products');
const couponsRef = db.collection('coupons');
const couponTemplatesRef = db.collection('couponTemplates');

export default AdminPage(function AdminCoupons(props) {
  const { user } = props;
  const {
    showsAllCoupons: _showsAllCoupons = '0'
  } = useQueryParams();
  const showsAllCoupons = _showsAllCoupons === '1';
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts.map((product) => ({
    ...product,
    ...(product.isHidden && { label: `[非表示] ${product.name}` }),
  }));
  const coupons = useCollectionSubscriptionInTenant(couponsRef.orderBy('createdAt'));
  const couponTemplates = sortBy(useCollectionSubscriptionInTenant(couponTemplatesRef), _ => _.createdAt.toDate())
  const couponTemplatesById = keyBy(couponTemplates, 'id');
  let filteredCoupons = coupons;
  if (!showsAllCoupons) {
    filteredCoupons = filteredCoupons.filter(({ status }) => status !== 'disabled' && status !== 'used');
  }

  const newRef = async () => {
    const generateId = async () => {
      const id = randomstring.generate({ length: 10 }).toUpperCase();
      return !(await couponsRef.doc(id).get()).exists ? id : await generateId();
    };
    return couponsRef.doc(await generateId());
  };
  const renderFormHeader = (statedFields) => {
    const onFixed = (value) => {
      const couponTemplate = couponTemplatesById[value];
      statedFields.setValues({
        ...pick(couponTemplate, [
          'type',
          'productIds',
          'valueType',
          'discountAmount',
          'discountRate',
          'giftProductIds',
          'isShipmentFree',
        ]),
      });
    };

    return (
      <div className='card p-2 mb-3'>
        <div className='text-muted small'>優待テンプレート</div>
        <TemplateSelector onFixed={onFixed} couponTemplates={couponTemplates} />
      </div>
    );
  };

  return (
    <div>
      <div className='admin-coupons container-fluid py-5 position-relative'>
        <div className='d-flex justify-content-center mb-3'>
          <h4>優待一覧</h4>
        </div>
        <div className='d-flex justify-content-end mb-3 gap-2 align-items-end flex-wrap'>
          <QueryBoolean paramName='showsAllCoupons' label='非表示も表示' defaultValue={'0'} />
          <AddInTenantButton
            itemRef={newRef}
            processValues={(_) => ({ ..._, status: 'initial', createdBy: pick(user, ['id', 'email', 'displayName']) })}
            FormModal={ModelFormModal}
            formProps={{ title: '優待追加', fields: fields({ products: selectableProducts }), renderFormHeader }}
            disabled={!canUpdateCoupon(user)}
          />
        </div>
        <div>
          {filteredCoupons.length > 0 ? (
            <table className='table'>
              <thead className='thead-light text-center text-nowrap'>
                <tr>
                  <th style={{ minWidth: 100 }}>コード</th>
                  <th style={{ minWidth: 100 }}>クーポン種別</th>
                  <th style={{ minWidth: 100 }}>クーポン名</th>
                  <th style={{ minWidth: 250 }}>商品</th>
                  <th style={{ minWidth: 100 }}>割引内容</th>
                  <th style={{ minWidth: 100 }}>開始/終了日</th>
                  <th style={{ minWidth: 100 }}>状態</th>
                  <th style={{ minWidth: 150 }}>説明</th>
                  <th style={{ minWidth: 150 }}>メモ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredCoupons.map((coupon) => {
                  const {
                    id,
                    ref,
                    type,
                    name,
                    productIds,
                    valueType,
                    discountAmount,
                    discountRate,
                    isShipmentFree,
                    createdBy,
                    startDate,
                    deadlineDate,
                    status,
                    disabilityMessage,
                    description,
                    note,
                  } = coupon;
                  const discountDisplay = {
                    amount: `${numeral(discountAmount).format()} 円`,
                    rate: `${numeral(discountRate).format()} %`,
                  }[valueType];

                  return (
                    <tr key={id} style={{ background: ['disabled', 'used'].includes(status) ? 'lightgray' : '' }}>
                      <td>
                        <div>{id}</div>
                        <div className="small text-muted">created by {get(createdBy, 'displayName', '')}</div>
                      </td>
                      <td>{fieldDisplayValue(type, fields().type)}</td>
                      <td>{name}</td>
                      <td>
                        {fieldDisplayValue(productIds, fields({ products }).productIds).map((_) => (
                          <div>{_}</div>
                        ))}
                      </td>
                      <td>
                        {discountDisplay}
                        <div>{isShipmentFree && <div className="badge badge-info">送料無料</div>}</div>
                      </td>
                      <td>
                        <div>{startDate && formatDate(startDate.toDate(), 'yyyy/MM/dd')}</div>
                        {[startDate, deadlineDate].some(_ => _) && <div>〜</div>}
                        <div>{deadlineDate && formatDate(deadlineDate.toDate(), 'yyyy/MM/dd')}</div>
                      </td>
                      <td>
                        {fieldDisplayValue(status, { ...fields({ products, coupon }).status, values: coupon })}
                        {status === 'disabled' && (
                          <div className='text-danger small' style={{ whiteSpace: 'pre-line' }}>
                            {disabilityMessage}
                          </div>
                        )}
                      </td>
                      <td>
                        <RichTextContent html={description} />
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>{note}</td>
                      <td className='text-nowrap text-right'>
                        {!['disabled', 'used'].includes(status) &&
                            <Button tag={TenantLink} to={`/products?coupon=1&couponId=${id}`} target="_blank" className="mr-1" onClick={_ => window.alert('優待付きのカートに入ります')}>
                            優待付きカート
                            <span className="fas fa-external-link-alt ml-1" />
                          </Button>
                        }
                        <EditButton
                          itemRef={ref}
                          FormModal={ModelFormModal}
                          formProps={{ title: '優待編集', fields: fields({ products: selectableProducts }) }}
                          disabled={!canUpdateCoupon(user)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>優待は未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});

function TemplateSelector(props) {
  const { couponTemplates } = props;
  const [value, setValue] = useState(null);
  const couponTemplateOptions = couponTemplates.map((_) => ({ label: _.name, value: _.id }));
  const onFixed = async () => {
    if (!window.confirm('適用しますか？')) return;

    await props.onFixed(value);
    setValue(null);
  };

  return (
    <div className='d-flex'>
      <Select
        value={couponTemplateOptions.find((_) => _.value === value)}
        options={couponTemplateOptions}
        onChange={(_) => setValue(_ && _.value)}
        isClearable
        className='flex-fill'
      />
      <Button className='ml-2' onClick={onFixed} disabled={value == null}>
        適用する
      </Button>
    </div>
  );
}
