import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { keyBy, omit, get, mapValues } from 'lodash';
import { useList, useAsync } from 'react-use';

import firebase from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import {
  fields,
  rowFields,
  equalityOperators,
  inclusionOperators,
  numberOperators,
  ruleTypes,
} from '../../shared/models/userTagRule';
import useFormState from '../hooks/useFormState';
import useTenant from '../hooks/useTenant';
import Field from '../Field';
import ListForm from '../ListForm';

const { entries } = Object;
const db = firebase.firestore();

export default function UserTagRuleFormModal(props) {
  const { events, userTags, isOpen, values, onClickClose } = props;
  const isNew = !values;
  const tenant = useTenant();
  const onCreateUserTag = async (value) => {
    const ref = await db.collection('userTags').add({ tenantId: tenant.id, name: value, createdAt: new Date() });
    return ref.id;
  };
  const statedFields = useFormState(values, fields({ events, userTags, onCreateUserTag }), isOpen);
  const [rows, { set: setRows, updateAt: updateRowAt }] = useList(get(values, 'rows') || []);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid) || rows.some((_) => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit({
      ...mapValues(statedFields, 'value'),
      rows: rows.map((_) => omit(_, 'isValid')),
    });
  };
  const { value: subjects = [] } = useAsync(async () => {
    if (statedFields.ruleType.value == null) return;

    const subjects = await {
      surveyAnswer: async () => {
        const questions = await getCollectionData(db.collection('questions').orderBy('createdAt', 'desc'));
        return questions
          .filter((_) => _.type !== 'imageFile')
          .map((_) => ({ label: `${_.name} (${_.description})`, value: _.id, data: _ }));
      },
    }[statedFields.ruleType.value]();
    return subjects;
  }, [statedFields.ruleType.value]);

  return (
    <Modal isOpen={isOpen} style={{ minWidth: 800 }}>
      <ModalHeader>ユーザータグルール{isNew ? '追加' : '編集'}</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <Field name="ruleType" {...statedFields.ruleType} />
          <Field name="name" {...statedFields.name} />
          <Field name="conditionEventIds" {...statedFields.conditionEventIds} />
          {
            ruleTypes[statedFields.ruleType.value]?.hasRuleRows && (
              <div className="my-3">
                <label>条件</label>
                <ListForm
                  items={rows}
                  renderItem={(item, itemIndex) => {
                    return (
                      <div>
                        <div className="card p-3">
                          <RowForm
                            rows={rows}
                            index={itemIndex}
                            values={item}
                            subjects={subjects}
                            ruleType={statedFields.ruleType.value}
                            onChange={(_) => updateRowAt(itemIndex, { ...item, ..._ })}
                          />
                        </div>
                        {itemIndex < rows.length - 1 && <div className="mt-2 font-weight-bold">AND</div>}
                      </div>
                    );
                  }}
                  onChange={(_) => setRows(_)}
                  minItems={1}
                />
              </div>
            )
          }
          <Field name="userTagIds" {...statedFields.userTagIds} />
          <Field name="userTagIdsToRemove" {...statedFields.userTagIdsToRemove} />
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

function RowForm(props) {
  const { subjects, ruleType, values, onChange } = props;
  const subjectsByValue = keyBy(subjects, 'value');
  const statedFields = useFormState(values, rowFields({ subjects }), values);
  const subject = subjectsByValue[statedFields.subject.value];
  const operatorOptions = entries(
    {
      surveyAnswer: (() => {
        return {
          text: inclusionOperators,
          radio: equalityOperators,
          checkbox: equalityOperators,
        }[subject?.data.type];
      })(),
    }[ruleType] || {}
  ).map(([k, v]) => ({ label: v.label, value: k }));
  const valueField = {
    surveyAnswer: (() => {
      return (
        subject != null && (
          <Field
            {...statedFields.value}
            name="value"
            type={{ text: 'string', radio: 'multiSelect', select: 'multiSelect', checkbox: 'multiSelect' }[subject?.data.type]}
            options={(
              {
                text: [],
                radio: subject?.data.optionsString?.split(','),
                select: subject?.data.optionsString?.split(','),
                checkbox: subject?.data.optionsString?.split(','),
              }[subject?.data.type] || []
            ).map((_) => ({ label: _, value: _ }))}
            hint={
              ['radio', 'select', 'checkbox'].includes(subject?.data.type)
                ? statedFields.operator.value === 'someEquals'
                  ? 'OR'
                  : 'AND'
                : ''
            }
          />
        )
      );
    })(),
  }[ruleType];
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every((_) => _.isValid),
    });
  }, [...Object.values(statedFields).map((_) => _.value)]);

  return (
    <div className="d-flex">
      <div style={{ width: 300 }}>
        <Field
          name="subject"
          {...statedFields.subject}
          inputProps={{ components: { IndicatorsContainer: (_) => null } }}
        />
      </div>
      <div className="ml-2" style={{ width: 170 }}>
        <Field
          name="operator"
          {...statedFields.operator}
          options={operatorOptions}
          inputProps={{ components: { IndicatorsContainer: (_) => null } }}
        />
      </div>
      <div className="ml-2" style={{ width: 200 }}>
        {valueField}
      </div>
    </div>
  );
}
