import { toast } from 'react-toastify';
import ModalButton from './ModalButton';
import ModelFormModal from './modals/ModelFormModal';
import { updateDoc, DocumentReference } from '../firebase';
import type { Inquiry, TroubleInquiry } from '../types';
import type { Fields } from 'types';

export const InquiryApprovalRequestButton = ({
  inquiryRef,
  inquiry,
  fields,
  onRequest,
  disabled,
}: {
  inquiryRef: DocumentReference<Inquiry | TroubleInquiry>;
  inquiry: Inquiry | TroubleInquiry;
  fields: () => Fields;
  onRequest?: (values: Partial<Inquiry | TroubleInquiry>) => Promise<void>;
  disabled?: boolean;
}) => {
  const { approvalStatus } = inquiry;
  const handleRequest = async ({ approvalRequestComment }) => {
    if (!window.confirm('本当に承認申請しますか？')) return;

    try {
      const updatedData = { approvalStatus: 'requested', approvalRequestComment };
      await updateDoc(inquiry.ref, updatedData);
      onRequest && (await onRequest({ ...inquiry, ...updatedData }));
      toast.success('申請しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <ModalButton
      color="primary"
      outline={approvalStatus === 'approved'}
      disabled={disabled || approvalStatus === 'approved'}
      Modal={ModelFormModal}
      modalProps={{
        onSubmit: handleRequest,
        title: '承認申請',
        values: inquiry,
        fields: fields,
        submitLabel: '申請する',
      }}
    >
      {{ approved: '承認済' }[approvalStatus || ''] || '承認申請'}
    </ModalButton>
  );
};
