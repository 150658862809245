import { useState, useEffect, useMemo, useRef } from 'react';
import { getDoc, DocumentReference, refEqual } from 'src/firebase';

const refsEqual = <T>(prevRefs: DocumentReference<T>[], refs: DocumentReference<T>[]) => {
  if (prevRefs.length !== refs.length) return false;
  const sortedPrevRefs = prevRefs.slice().sort();
  const sortedRefs = refs.slice().sort();

  return sortedPrevRefs.every((prev, i) => refEqual(prev, sortedRefs[i]));
};

export const useDocumentsDataOnce = <T>(refs: DocumentReference<T>[] | undefined, { initialData = [] } = {}) => {
  const [data, setData] = useState<T[]>(initialData);
  const prevRefs = useRef<DocumentReference<T>[] | undefined>();

  useEffect(() => {
    if (refs == null) return;
    if (prevRefs.current != null && refsEqual(prevRefs.current, refs)) return;

    prevRefs.current = refs;
    (async () => {
      setData(await Promise.all(refs.map(async (ref) => (await getDoc<T>(ref)).data() as T)));
    })();
  }, [refs]);

  return useMemo(() => ({ data }), [data]);
};
