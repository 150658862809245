const { omit, pick, isEmpty } = require('lodash');

const { prefectures, } = require('../config');

const { entries } = Object;

const fields = () => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: { required: (v) => !isEmpty(v) },
    },
    enName: {
      label: '名称(英語)',
      type: 'string',
    },
    path: {
      label: 'URLの固有文字列',
      type: 'string',
      validations: { required: (v) => !isEmpty(v) },
    },
    useSms: {
      label: 'SMSを使う',
      type: 'boolean',
    },
    useMobile: {
      label: 'モバイルアプリを使う',
      type: 'boolean',
    },
    useWms: {
      label: 'WMS機能を使う',
      type: 'boolean',
    },
    phone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        format: v => !v || v.match(/^(0{1}\d{9,10})$/),
      },
      placeholder: '08012345678 （ハイフンなし）'
    },
    postalCode: {
      type: 'string',
      label: '郵便番号',
      validations: {
        format: v => (v || '').match(/^[0-9]{7}$/g),
      },
      inputProps: {
        type: 'tel',
      },
    },
    prefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
    },
    city: {
      type: 'string',
      label: '市区町村',
    },
    address1: {
      type: 'string',
      label: '町・番地',
    },
    address2: {
      type: 'string',
      label: 'マンション・ビル名',
    },
  };
};

module.exports = {
  fields,
  generalSettingsFields: () => {
    return {
      ...omit(fields(), ['path']),
    };
  },
};
