import React, { useEffect, useMemo, } from 'react';
import qs from 'qs';
import { sortBy, orderBy, groupBy, uniq, maxBy, omit, isEmpty, sum, sumBy, get, keyBy, omitBy, pick, isUndefined, max } from 'lodash';
import { format as formatDate, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { Button, Badge, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ellipsis from 'text-ellipsis';

import firebase, { functions } from '../firebase';
import { ordererFields, cancelFields, } from '../shared/models/rentalOrder';
import { fieldDisplayValue } from '../shared/util';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';

const { entries } = Object;
const db = firebase.firestore();

export default function ContentOrdersTable (props) {
  const { 
    agentsById,
    agentShopsById,
    contentProductsById,
    startOn,
    endOn,
    agentIds,
    agentShopIds,
    contentProductIds,
  } = props;
  const contentOrders = orderBy(useCollectionSubscriptionInTenant(db.collection('contentOrders').where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)), [startOn, endOn]), 'createdAt', 'desc');

  let filteredContentOrders = contentOrders;
  if(!isEmpty(agentIds)) {
    filteredContentOrders = filteredContentOrders.filter(_ => agentIds.includes(_.agentId));
  }
  if(!isEmpty(agentShopIds)) {
    filteredContentOrders = filteredContentOrders.filter(_ => agentShopIds.includes(_.agentShopId));
  }
  if(!isEmpty(contentProductIds)) {
    filteredContentOrders = filteredContentOrders.filter(_ => _.contentProductIds.some(_ => contentProductIds.includes(_)));
  }

  const rows = filteredContentOrders.map((contentOrder) => {
    const agent = agentsById[contentOrder.agentId];
    const agentShop = agentShopsById[contentOrder.agentShopId];
    const contentProducts = contentOrder.contentProductIds.map(_ => contentProductsById[_]);
    return {
      ...contentOrder,
      agent,
      agentShop,
      contentProducts,
    };
  });
  const rowsForExport = () => {
    return rows.map((row) => {
      const { id, status, createdAt, } = row;
      return {
        id,
        status,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        ...(
          entries(ordererFields()).reduce((x, [fieldName, fieldSettings]) => {
            return {
              ...x,
              [fieldName]: fieldDisplayValue(row[fieldName], fieldSettings),
            };
          }, {})
        ),
      };
    });
  };

  return (
    <div>
      <div className='position-relative mb-2 d-flex align-items-end justify-content-start gap-2' style={{ zIndex: 11 }}>
      </div>
      <table className="table">
        <thead className="thead-light text-center text-nowrap">
          <tr>
            <th>コンテンツ注文ID</th>
            <th>注文日時</th>
            <th>ステータス</th>
            <th>代理店</th>
            <th>代理店店舗</th>
            <th>コンテンツ商品</th>
            <th>金額</th>
            {entries(ordererFields()).map(([fieldName, { label }]) => {
              return <th key={fieldName}>{label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {
            rows.map((contentOrder) => {
              const { id, ref, createdAt, status, agent, agentShop, contentProducts, amount, } = contentOrder;

              return (
                <tr key={id}>
                  <td>
                    {id}
                  </td>
                  <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                  <td>
                    {status}
                  </td>
                  <td>
                    {agent?.name}
                  </td>
                  <td>
                    {agentShop?.name}
                  </td>
                  <td>
                    {
                      contentProducts.map((contentProduct) => {
                        return (
                          <div>
                            <span>{contentProduct?.code}</span>
                            <span className='ml-2'>{contentProduct?.name}</span>
                          </div>
                        );
                      })
                    }
                  </td>
                  <td className="text-right">{numeral(amount).format('0,0')}</td>
                  {entries(ordererFields()).map(([fieldName, fieldSettings]) => {
                    return (
                      <td>
                        {fieldDisplayValue(contentOrder[fieldName], fieldSettings)}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

