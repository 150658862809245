import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { isEmpty, pick, mapValues, omit } from 'lodash';
import { useToggle } from 'react-use';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields } from '../../shared/models/user';
import { getAddressByPostalCode } from '../../util';
import { toHankaku } from '../../shared/util';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import TenantLink from '../TenantLink';

const { entries } = Object;
const auth = firebase.auth();
const db = firebase.firestore();

export default function ProfileFormModal(props) {
  const { isOpen, values, onClickClose } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const [extraValidationErrors, setExtraValidationErrors] = useState({});
  const magazineGroups = useCollectionSubscription(db.collection('magazineGroups').orderBy('createdAt'));
  const statedFields = useFormState(
    values,
    pick(fields({ magazineGroups, role: values.role }), [
      'email',
      'displayName',
      'nameKana',
      'phone',
      'postalCode',
      'prefecture',
      'city',
      'address',
      'route',
      'blocksSms',
      'blocksMagazineGroupIds',
      'trs110User',
      'trs110Password',
      'currentPassword',
    ]),
    isOpen
  );
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid) && isEmpty(extraValidationErrors);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    toggleSubmitting(true);
    await props.onSubmit({ ...mapValues(statedFields, 'value') });
    toggleSubmitting(false);
  };
  const handleChangePostalCode = async ({ fieldNames }, { target: { name, value } }) => {
    if (value?.length !== 7) return;

    try {
      const { prefecture, city, address1 } = await getAddressByPostalCode(value);
      setExtraValidationErrors(omit(extraValidationErrors, [name]));
      statedFields.setValues({
        [name]: value,
        [fieldNames.prefecture]: prefecture,
        [fieldNames.city]: city + address1,
      });
    } catch (e) {
      console.error(e);
      setExtraValidationErrors({ ...extraValidationErrors, [name]: ['existingPostalCode'] });
    }
  };
  const onClickPasswordReset = async () => {
    if (!window.confirm('パスワード変更のためのメールをお送りします。よろしいですか？')) return;

    await auth.sendPasswordResetEmail(values.email);
    toast.success('パスワードリセットのメールをお送りしました。');
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>プロフィール編集</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {entries(statedFields).map(([fieldName, fieldSetting]) => {
            if (fieldName === 'postalCode') {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  documentName="user"
                  {...fieldSetting}
                  {...(extraValidationErrors[fieldName] && {
                    validationErrors: [...fieldSetting.validationErrors, ...extraValidationErrors[fieldName]],
                  })}
                  onChange={handleChangePostalCode.bind(null, {
                    fieldNames: { prefecture: 'prefecture', city: 'city' },
                  })}
                />
              );
            } else {
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  documentName="user"
                  {...(['city', 'address'].includes(fieldName) && {
                    inputProps: { onBlur: (_) => fieldSetting.setValue(toHankaku(fieldSetting.value)) },
                  })}
                  {...fieldSetting}
                />
              );
            }
          })}
          <div className="text-muted mt-2">
            パスワードをお忘れの方は
            <TenantLink onClick={onClickPasswordReset}>こちら</TenantLink>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button
            className="save"
            type="submit"
            color="primary"
            onClick={onSubmit}
            disabled={isUnsubmittable || isSubmitting}
          >
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
