import React from 'react';
import { format as formatDate, startOfDay, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { isEmpty, omit, uniq, omitBy, pick, isUndefined } from 'lodash';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import firebase, { serverTimestamp, functions } from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import { canUpdateExportRequests } from '../../shared/abilities';
import useTenant from '../hooks/useTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import { fields, paceMakerTypes } from '../../shared/models/paceMakerSetting';
import QuerySelector from '../QuerySelector';
import useQueryParams from '../hooks/useQueryParams';
import { useSettingDocument, tenantAreaSettingRef } from '../../models/setting';
import { canUpdatePaceMakerSetting } from '../../shared/abilities';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import useFirebaseUser from '../hooks/useFirebaseUser';
import DeleteButton from '../DeleteButton';
import PaceMakerFormModal from '../modals/PaceMakerFormModal';
import ModalButton from '../ModalButton';
import ModelFormModal from '../modals/ModelFormModal';

const db = firebase.firestore();
const paceMakerSettingsRef = db.collection('paceMakerSettings');
const createPaceMakerResults = functions.httpsCallable('createPaceMakerResults');

const createDataFields = {
  from: {
    type: 'date',
    label: '開始日',
    validations: {
      required: (v) => v != null,
    },
  },
  to: {
    type: 'date',
    label: '終了日',
    validations: {
      required: (v) => v != null,
    },
  },
};

export default AdminPage(function AdminPaceMakerSettings(props) {
  const { user } = props;
  const { firebaseUser } = useFirebaseUser();
  const params = useQueryParams();
  const startOn = params.dateRange?.[0] ? new Date(`${params.dateRange[0]}T00:00:00`) : startOfMonth(new Date());
  const endOn = params.dateRange?.[1] ? new Date(`${params.dateRange[1]}T00:00:00`) : endOfMonth(new Date());
  const { destinations: destinationsForFilter, areaGroups: areaGroupsFilter } = params;

  const tenant = useTenant();
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map((_) => _.group));
  const areaGroupOptions = areaGroups.map((_) => ({ label: _, value: _ }));
  const { items: fixedPeriodRows, isLoadingFixedPeriod } = useCollectionSubscriptionInTenant(
    paceMakerSettingsRef.where('startDate', '>=', startOfDay(startOn)).orderBy('startDate', 'desc'),
    [formatDate(startOn, 'yyyy/MM/dd')],
    { detail: true }
  );
  const { items: monthlyRecurringRows, isLoadingMonthlyRecurring } = useCollectionSubscriptionInTenant(
    paceMakerSettingsRef.where('scheduleType', '==', 'monthlyRecurring').orderBy('createdAt', 'desc'),
    [],
    { detail: true }
  );
  const rows = [...fixedPeriodRows, ...monthlyRecurringRows];
  const destinationOptions = [...new Set(rows.map((_) => _.destinations?.split(',')).flat())].map((_) => ({
    label: _,
    value: _,
  }));

  let filteredRows = rows;
  if (startOn) {
    filteredRows = filteredRows.filter((_) => _.scheduleType !== 'fixedPeriod' || _.startDate?.toDate() >= startOn);
  }
  if (endOn) {
    filteredRows = filteredRows.filter((_) => _.scheduleType !== 'fixedPeriod' || _.endDate?.toDate() <= endOn);
  }
  if (!isEmpty(destinationsForFilter)) {
    filteredRows = filteredRows.filter((_) => destinationsForFilter.some((email) => _.destinations.includes(email)));
  }
  if (!isEmpty(areaGroupsFilter)) {
    filteredRows = filteredRows.filter((_) => areaGroupsFilter.some((areaGroup) => _.areaGroups.includes(areaGroup)));
  }

  const onSubmitCreateData = async ({ paceMakerSetting, from, to }) => {
    if (from > to) {
      toast.error('開始日を終了日より後にできません');
      return;
    }
    if (differenceInDays(to, from) > 365) {
      toast.error('期間は1年以内で指定してください');
      return;
    }
    try {
      await createPaceMakerResults({
        paceMakerSettingId: paceMakerSetting.id,
        from: from.toISOString(),
        to: to.toISOString(),
      });
      toast.success('データ作成しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div>
      <div className="admin-pace-maker-settings container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>ペースメーカー設定</h4>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-2">
          <div className="d-flex gap-1 align-items-end">
            <QueryDateRangeSelector
              label="期間"
              defaultValue={[startOn, endOn]}
              paramName="dateRange"
              pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }}
            />
            <QuerySelector
              paramName="destinations"
              className="ml-0"
              width={250}
              isMulti
              options={destinationOptions}
              label="送信先で絞込み"
            />
            <QuerySelector
              paramName="areaGroups"
              width={250}
              max={10}
              isMulti
              options={areaGroupOptions}
              label="エリアグループで絞込み"
            />
          </div>
          <div>
            <AddInTenantButton
              itemRef={paceMakerSettingsRef.doc()}
              FormModal={PaceMakerFormModal}
              formProps={{
                documentName: 'paceMakerSetting',
                title: '新規登録',
                fields: fields({ areaGroups }),
              }}
              disabled={!canUpdateExportRequests(user)}
            />
          </div>
        </div>
        <div>
          {isLoadingFixedPeriod || isLoadingMonthlyRecurring ? (
            <div>
              <span className="fas fa-spin fa-spinner" />
            </div>
          ) : (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th>期間</th>
                  <th>種別</th>
                  <th>名前</th>
                  <th>送信先</th>
                  <th>目標売上台数</th>
                  <th>目標売上金額</th>
                  <th>目標イベント開催数</th>
                  <th>目標イベント事後アンケート回答数</th>
                  <th>目標イベント後販売数</th>
                  <th>エリアグループ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((paceMakerSetting) => {
                  const {
                    id,
                    name,
                    type,
                    destinations,
                    targetAgentSalesVolume,
                    targetAgentSalesAmount,
                    targetDirectSalesVolume,
                    targetDirectSalesAmount,
                    targetHostingEventsCount,
                    targetSurveyAnswersCount,
                    targetSalesVolumeAfterEvent,
                    areaGroups: _areaGroups,
                    startDate,
                    endDate,
                    scheduleType,
                  } = paceMakerSetting;

                  const handleClickCopy = async () => {
                    if (!window.confirm('この設定をコピーしますか？')) return;
                    try {
                      if (!paceMakerSetting) return;
                      await paceMakerSettingsRef.add({
                        ...omit(paceMakerSetting, ['id', 'ref', 'createdAt', 'createdBy', 'updatedBy']),
                        addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
                        createdAt: serverTimestamp(),
                      });
                      toast.success('コピーしました。');
                    } catch (e) {
                      console.error(e);
                      toast.error('失敗しました');
                    }
                  };

                  return (
                    <tr key={id}>
                      <td>
                        {scheduleType === 'monthlyRecurring'
                          ? '月間'
                          : `${formatDate(startDate.toDate(), 'yyyy/MM/dd')} - ${formatDate(
                              endDate.toDate(),
                              'yyyy/MM/dd'
                            )}`}
                      </td>
                      <td>{paceMakerTypes[type]?.label}</td>
                      <td>{name}</td>
                      <td>
                        {destinations.split(',').map((email) => (
                          <span key={email} className="badge badge-secondary mr-1">
                            {email}
                          </span>
                        ))}
                      </td>
                      <td>{(targetAgentSalesVolume + targetDirectSalesVolume).toLocaleString()}</td>
                      <td>{(targetAgentSalesAmount + targetDirectSalesAmount).toLocaleString()}</td>
                      <td>{targetHostingEventsCount?.toLocaleString()}</td>
                      <td>{targetSurveyAnswersCount?.toLocaleString()}</td>
                      <td>{targetSalesVolumeAfterEvent?.toLocaleString()}</td>
                      <td>
                        {_areaGroups.map((_) => (
                          <span key={_} className="badge badge-secondary mr-1">
                            {_}
                          </span>
                        ))}
                      </td>
                      <td className="text-nowrap text-right">
                        <EditButton
                          color="primary"
                          className="mr-1"
                          itemRef={paceMakerSetting.ref}
                          FormModal={PaceMakerFormModal}
                          formProps={{
                            documentName: 'paceMakerSetting',
                            title: '設定',
                            fields: fields({ areaGroups }),
                          }}
                          disabled={!canUpdatePaceMakerSetting(user)}
                        />
                        <Button className="mr-1" onClick={handleClickCopy} disabled={!canUpdatePaceMakerSetting(user)}>
                          <span className="fas fa-copy mr-1" />
                          コピー
                        </Button>
                        <ModalButton
                          className="mr-1"
                          Modal={ModelFormModal}
                          modalProps={{
                            title: 'データ作成',
                            fields: createDataFields,
                            submitLabel: '送信する',
                            onSubmit: (data) => onSubmitCreateData({ paceMakerSetting, ...data }),
                          }}
                        >
                          <span className="fas fa-paper-plane mr-1" /> データ作成
                        </ModalButton>
                        <DeleteButton
                          label="削除"
                          itemRef={paceMakerSetting.ref}
                          disabled={!canUpdatePaceMakerSetting(user)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
});
