import React, { Fragment, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useToggle, useAsync, useLocalStorage } from 'react-use';
import qs from 'qs';
import { uniq, get, mapValues, isEmpty, omit } from 'lodash';
import { Button, Form } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../../util';
import firebase from '../../firebase';
import { fields } from '../../shared/models/referredEmail';
import { isInValidDateRange } from '../../shared/models/referrer';
import { embedKeywords, fields as qrUrlFields } from '../../shared/models/qrUrl';
import { fieldDisplayValue } from '../../shared/util';
import useTenant from '../hooks/useTenant';
import useFormState from '../hooks/useFormState';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import PublicPage from '../hocs/PublicPage';
import Field from '../Field';
import AppButton from '../AppButton';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const qrUrlsRef = db.collection('qrUrls');
const referrersRef = db.collectionGroup('referrers');
const referredEmailsRef = db.collection('referredEmails');

export default PublicPage(function ReferralRedirect(props) {
  const { user } = props;
  const isStaff = ['admin', 'staff'].includes(user?.role);
  const [showStaffPage, toggleShowStaffPage] = useToggle();
  const { qrUrlId, referrerKey, redirectUrl } = useQueryParams();
  const tenant = useTenant();
  const [, setQrUrlId] = useLocalStorage(['qrUrlId', tenant.id].join('__'));
  const [, setQrUrlIdSavedAt] = useLocalStorage(['qrUrlIdSavedAt', tenant.id].join('__'));
  const [oldReferrerKey, ,removeOldReferrerKey] = useLocalStorage(['referrerKey', tenant.id].join('__'));
  const [oldReferrerKeySavedAt, ,removeOldReferrerKeySavedAt] = useLocalStorage(['referrerKeySavedAt', tenant.id].join('__'));
  const [referrers = [], setReferrers] = useLocalStorage(['referrers', tenant.id].join('__'));
  const [referralLogs = [], setReferralLogs] = useLocalStorage(['referralLogs', tenant.id].join('__'), []);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const statedFields = useFormState({}, fields(), false);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);
  const qrUrl = useDocumentSubscription(qrUrlsRef.doc(qrUrlId));
  const [referrer] = useCollectionSubscriptionInTenant(referrersRef.where('key', '==', referrerKey).limit(1), [
    referrerKey,
  ]);
  const agentShop = useDocumentSubscription(referrer && referrer.ref.parent.parent, [referrer]);
  const agent = useDocumentSubscription(agentShop && agentShop.ref.parent.parent, [agentShop]);
  const couponIds = uniq([qrUrl?.couponIds, agent?.showingQrUrls?.find(_ => _.qrUrlId === qrUrlId)?.couponIds].flatMap(_ => _ || []));
  const coupons = useDocumentsFetch(couponIds.map(_ => db.collection('coupons').doc(_)), [qrUrl, agent]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    toggleSubmitting(true);
    try {
      // TODO: マルチテナントへの対応。
      await referredEmailsRef.doc(statedFields.email.value).set({
        qrUrlId,
        referrerKey,
        referrerId: referrer.id,
        redirectUrl,
        agentShopId: agentShop.id,
        agentId: agent.id,
        email: statedFields.email.value,
        createdAt: new Date(),
      });
      window.location = decodeURIComponent(redirectUrl);
    } catch (e) {
      console.error(e);
      toast.error('失敗しました。もう一度お試しください。');
    }
    toggleSubmitting(false);
  };
  useEffect(() => {
    toggleShowStaffPage(isStaff);
  }, [isStaff]);
  useEffect(() => {
    if (showStaffPage == null || agent == null) return;

    if (!isEmpty(referrerKey)) {
      const isRecentSameReferrer = referralLogs?.[0]?.referrerKey === referrerKey;
      const newData = { referrerKey, referrerKeySavedAt: new Date(), enabledLastReferrerWin: !!agent.enabledLastReferrerWin };
      if (oldReferrerKey) {
        setReferrers([newData, { referrerKey: oldReferrerKey, referrerKeySavedAt: JSON.parse(oldReferrerKeySavedAt) }]);
        removeOldReferrerKey();
        removeOldReferrerKeySavedAt();
      } else {
        setReferrers([newData, ...(isRecentSameReferrer ? referrers.slice(1) : referrers)]);
      }
      setReferralLogs([newData, ...(isRecentSameReferrer ? referralLogs.slice(1) : referralLogs)]);
    }
    if (!isEmpty(qrUrlId)) {
      setQrUrlId(qrUrlId);
      setQrUrlIdSavedAt(JSON.stringify(new Date()));
    }
    if (qrUrl != null && !qrUrl.requestsEmail && !showStaffPage) {
      window.location = decodeURIComponent(redirectUrl);
    }
  }, [agent, referrerKey, qrUrlId, qrUrl, showStaffPage]);

  return referrer != null && (
    isInValidDateRange(referrer) ? (
      showStaffPage ? (
        <Fragment>
          {qrUrl != null && agent != null && (
            <div className="referral-redirect h-100 container">
              <div className="mt-4 row">
                <div className="col-sm-8 offset-sm-2">
                  <h4 className="text-center">紹介QRコード遷移先情報</h4>
                  <table className="table table-bordered mt-4">
                    <tbody className="thead-light">
                      <tr>
                        <th style={{ width: 200 }}>名称</th>
                        <td style={{ whiteSpace: 'pre-line' }}>{qrUrl.name}</td>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }}>遷移先URL</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          <a href={qrUrl.url} target="_blank" className="text-break">
                            {qrUrl.url}
                            <span className="ml-1 fas fa-external-link-alt" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }}>優待</th>
                        <td>
                          {
                            coupons?.map((coupon) => {
                              return (
                                <div key={coupon.id} className="card p-1 mb-1">
                                  {coupon.name}
                                </div>
                              );
                            })
                          }
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }}>メールアドレス入力</th>
                        <td>
                          {qrUrl.requestsEmail && '求める'}
                          {qrUrl.emailSkipEnabled && '(スキップ可)'}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }}>リファラー</th>
                        <td>{referrer && referrer.name}</td>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }}>代理店</th>
                        <td>{agent && agent.name}</td>
                      </tr>
                      <tr>
                        <th style={{ width: 200 }}>店舗</th>
                        <td>{agentShop && agentShop.name}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="mt-4">
                    <AppButton block color="primary" onClick={toggleShowStaffPage}>
                      遷移先URLに進む
                    </AppButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {qrUrl != null && get(qrUrl, 'requestsEmail', false) && agent != null && (
            <div className="referral-redirect h-100 container">
              <div className="mt-4 row">
                <div className="col-sm-6 offset-sm-3">
                  <h4 className="text-center">
                    {embedKeywords(qrUrl.emailRequestScreenTitle, agent, agentShop, referrer)}
                  </h4>
                  <div className="mt-4 d-flex flex-column gap-2">
                    <RichTextContent html={embedKeywords(qrUrl.emailRequestScreenDescription, agent, agentShop, referrer)} />
                    {
                      (_ => _ && (
                        <RichTextContent html={embedKeywords(_, agent, agentShop, referrer)} />
                      ))(agent.showingQrUrls?.find(_ => _.qrUrlId === qrUrlId)?.emailRequestScreenDescription)
                    }
                  </div>
                  <div className="mt-5 d-flex flex-column gap-2">
                    {
                      coupons.map((coupon, i) => {
                        return (
                          <div key={coupon.id} className="bg-light-grey rounded-3 p-3">
                            <h6 className="font-weight-bold">優待内容{coupons.length > 1 ? i + 1 : ''}</h6>
                            <RichTextContent html={coupon.description} />
                          </div>
                        );
                      })
                    }
                  </div>
                  <Form className="mt-5" onSubmit={onSubmit}>
                    {
                      qrUrl.emailSkipEnabled && (
                        <div className="d-flex justify-content-end">
                          <Button tag="a" className="m-0 p-0 text-dark-grey" size="sm" color="link" href={decodeURIComponent(redirectUrl)}>
                            <span className="fas fa-angle-right mr-1" />
                            スキップする
                          </Button>
                        </div>
                      )
                    }
                    <div>
                      <Field name="email" {...statedFields.email} />
                    </div>
                    <div className="mt-4">
                      <AppButton block color="primary" disabled={isUnsubmittable} onClick={onSubmit}>
                        送信して次へ
                      </AppButton>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )
    ) : (
      <div className="container">
        <div className="mt-5 alert alert-danger">
          有効期限外です
        </div>
      </div>
    )
  );
});
