import React, { useEffect } from 'react';
import qs from 'qs';
import { sortBy, maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useAsync, } from 'react-use';

import { canUpdateOrder, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { prefectures } from '../../shared/config';
import { fields } from '../../shared/models/order';
import { fields as userFields, } from '../../shared/models/user';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionsFetchInTenant from '../hooks/useCollectionsFetchInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import ExportButton from '../ExportButton';
import QueryDateSelector from '../QueryDateSelector';
import QrCodeCard from '../QrCodeCard';

const db = firebase.firestore();
const productsRef = db.collection('products');
const qrUrlsRef = db.collection('qrUrls');

export default AgentPage(function AgentOrders (props) {
  const { user, agent, match: { params: { agentShopId, referrerId } }, setBreadNavValues, canReadAllShops, memberData, } = props;
  const agentShop = useDocumentSubscription((canReadAllShops || memberData.shopIds.includes(agentShopId)) && agent.ref.collection('agentShops').doc(agentShopId), [agent, agentShopId]);
  const referrer = useDocumentSubscription(agentShop && agentShop.ref.collection('referrers').doc(referrerId), [agentShop, referrerId]);
  const qrUrls = sortBy(useCollectionSubscriptionInTenant(qrUrlsRef), _ => _.createdAt.toDate());
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(db.collection('qrBottomDisplays')), _ => _.createdAt.toDate());
  const qrBottomDisplaysById = keyBy(qrBottomDisplays, 'id');
  const filteredQrUrls = qrUrls
    .filter(_ => !_.isHidden)
    .filter(_ => agent?.showingQrUrls?.map(_ => _.qrUrlId).includes(_.id));
  useEffect(() => {
    setBreadNavValues({ agent, agentShop, referrer, });
  }, [agent, agentShop, referrer, ]);

  return referrer != null && (
    <div>
      <div className="agent-shop-referrer container py-5 position-relative">
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className="row">
            <div className="col-12">
              <h5 className="text-center mb-4">
                {referrer.name}
              </h5>
              <div className="d-flex flex-wrap justify-content-around">
                {
                  filteredQrUrls.map((qrUrl) => {
                    const { id, name, url } = qrUrl;
                    return (
                      <QrCodeCard key={id} {...{ qrUrl, agentShop, referrer, qrBottomDisplaysById, }} />
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
