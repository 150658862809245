import useTenant from './hooks/useTenant';
import AddButton from './AddButton';

export default function AddInTenantButton (props) {
  const tenant = useTenant();
  const processValues = async (values) => {
    return { tenantId: tenant.id, ...(await (props.processValues || (_ => _))(values)) };
  };

  return (
    <AddButton {...props} processValues={processValues} />
  );
};
