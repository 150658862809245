import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Input, Button } from 'reactstrap';
import qs from 'qs';

import useQueryParams from './hooks/useQueryParams';
import { fullPathWithParams } from '../util';

export default function QueryText({
  paramName,
  label,
  defaultValue = '',
  width = 200,
  isImmediate = false,
  submitLabel = '検索',
  ...extraProps
}: {
  paramName: string;
  label?: string;
  defaultValue?: string;
  width?: number;
  isImmediate?: boolean;
  submitLabel?: string;
  extraProps?: any;
}) {
  const history = useHistory();
  const location = useLocation();
  const { [paramName]: _value = defaultValue } = qs.parse(decodeURI(location.search.slice(1)));
  const [value, setValue] = useState(_value);
  const replaceHistory = (newValue) => {
    const path = fullPathWithParams({ ...queryParams, [paramName]: newValue }, location);
    history.replace(encodeURI(path));
  };
  const queryParams = useQueryParams();
  const onChange = ({ target: { value: newValue } }) => {
    setValue(newValue);
    isImmediate && replaceHistory(newValue);
  };
  const onSubmit = () => {
    replaceHistory(value);
  };

  return (
    <div className='d-flex flex-column' {...extraProps}>
      {label && <div className='text-muted small'>{label}</div>}
      <div className='d-flex gap-2'>
        <div style={{ width }}>
          <Input value={value as string} onChange={onChange} />
        </div>
        {isImmediate || (
          <Button onClick={onSubmit}>
            {submitLabel}
          </Button>
        )}
      </div>
    </div>
  );
}
