import React from 'react';
import qs from 'qs';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import AdminPage from '../hocs/AdminPage';
import AdminMypageInquiries from '../AdminMypageInquiries';
import AdminMypagePaceMaker from '../AdminMypagePaceMaker';

const tabs = {
  inquiries: { label: '問合せ・アンケート管理' },
  paceMaker: { label: 'ペースメーカー' },
};

export default AdminPage(function AdminMypage(props) {
  const { tenant, location, user } = props;
  const queryParams = qs.parse(decodeURI(location.search.slice(1)));
  const { tab = 'inquiries' } = queryParams;  

  return (
    <div>
      <div className="admin-events container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>管理マイページ</h4>
        </div>
        <Nav tabs className="mt-3">
          {Object.entries(tabs).map(([name, { label }]) => {
            return (
              <NavItem key={name}>
                <NavLink
                  tag={Link}
                  className={classnames({ active: tab === name })}
                  to={`${location.pathname}?${qs.stringify({ ...queryParams, tab: name })}`}
                >
                  {label}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        {{
          inquiries: () => (
            <div className="mt-3">
              <AdminMypageInquiries {...props} />
            </div>
          ),
          paceMaker: () => (
            <div className="mt-3">
              <AdminMypagePaceMaker {...props} />
            </div>
          ),
        }[tab]()}
      </div>
    </div>
  );
});
