import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import { sortBy, keyBy, } from 'lodash';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import { beforeDelete, } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import AdminAgentShopHeader from '../AdminAgentShopHeader';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';
import { fields } from '../../shared/models/agentShop';
import TenantLink from '../TenantLink';

const { entries } = Object;
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const ordersRef = db.collection('orders');
const qrBottomDisplaysRef = db.collection('qrBottomDisplays');

export default AdminPage(function AdminAgent (props) {
  const { tenant, user, match: { params: { agentId, agentShopId, } }, addBreadNavValues } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const agentRef = agentsRef.doc(agentId);
  const agentShopRef = agentRef.collection('agentShops').doc(agentShopId);
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const agentShop = useDocumentSubscription(agentShopRef, [agentId, agentShopId]);

  useEffect(() => {
    addBreadNavValues({ agent, agentShop, })
  }, [agent, agentShop]);

  return agentShop != null && (
    <div>
      <div className="admin-agent-shop container py-5 position-relative">
        <AdminAgentShopHeader activeTab="general" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end mb-3">
                <EditButton color="primary" itemRef={agentShop.ref} FormModal={ModelFormModal} formProps={{ documentName: 'agentShop', title: '編集', fields, }} disabled={!canUpdateAgent(user)} />
              </div>
              <table className="table table-bordered align-middle thead-light">
                <tbody className="thead-light">
                  {
                    entries(fields()).map(([fieldName, fieldSettings]) => {
                      const { label, type } = fieldSettings;
                      const value = fieldDisplayValue(agentShop[fieldName], fieldSettings);
                      return (
                        <tr>
                          <th style={{ width: 250 }}>
                            {label}
                          </th>
                          <td>
                            {value}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
