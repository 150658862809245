import React from 'react';
import { Link } from 'react-router-dom';
import { keyBy, countBy } from 'lodash';
import numeral from 'numeral';

import firebase from '../../firebase';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import SuperAdminPage from '../hocs/SuperAdminPage';

const db = firebase.firestore();

export default SuperAdminPage(function SuperAdminRoot(props) {
  return (
    <div>
      <div className="admin-events container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>Super Admin</h4>
        </div>
        <div className="card mb-4">
          <div className="card-header">管理メニュー</div>
          <div className="card-body">
            <ul className="nav flex-column pb-2">
              <li className="nav-item">
                <Link className="nav-link" to="/superAdmin/tenants">
                  テナント管理
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/superAdmin/users">
                  アカウント管理
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
