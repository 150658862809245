import React, { useEffect } from 'react';
import { pick, sortBy, keyBy, isEmpty } from 'lodash';
import { useAsync, useLocalStorage } from 'react-use';
import qs from 'qs';
import numeral from 'numeral';
import Slider from 'react-slick';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../../util';
import firebase, { functions } from '../../firebase';
import { getDocumentData, getCollectionData, } from '../../shared/firebase';
import PublicPage from '../hocs/PublicPage';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AppButton from '../AppButton';
import AppCard from '../AppCard';
import CartInformation from '../CartInformation';
import useAvailableReferrerKey from '../hooks/useAvailableReferrerKey';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const agentsRef = db.collection('agents');

function RentalItems(props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const { agentId, agentShopId, } = queryParams;

  const agent = useDocumentSubscription(db.collection('agents').doc(agentId), [agentId]);
  const rentalItems = useCollectionSubscriptionInTenant(agent?.ref.collection('rentalItems'), [agent]);
  const rentalPrices = sortBy(useCollectionSubscriptionInTenant(agent?.ref.collection('rentalPrices').where('agentShopId', '==', agentShopId), [agent, agentShopId]), 'hours');

  return (
    <div className="rental-items container py-5 position-relative">
      <div className="position-relative d-flex flex-wrap justify-content-around gap-4">
        {rentalItems.map((rentalItem) => {
          const { id, name, image, description } = rentalItem;
          const imageUrls = [image].filter((_) => _);

          return (
            <AppCard
              key={id}
              className="p-3 d-flex flex-column align-items-stretch justify-content-between"
              style={{ width: 300 }}
            >
              <div className="w-100">
                <Slider dots infinite arrows={false} className="mb-4">
                  {imageUrls.map((imageUrl) => (
                    <div key={imageUrl} className="d-flex justify-content-center">
                      <img src={imageUrl} style={{ maxHeight: 200, maxWidth: "100%" }} />
                    </div>
                  ))}
                </Slider>
                <div className="h5 text-center">{name}</div>
              </div>
              <div className="d-flex flex-column align-items-stretch">
                {
                  rentalPrices.map((rentalPrice) => {
                    return (
                      <div key={rentalPrice.id} className="d-flex justify-content-between">
                        <div>{rentalPrice.name}</div>
                        <div>&yen; {numeral(rentalPrice[`price__${id}`]).format('0,0')} (税込)</div>
                      </div>
                    );
                  })
                }
                <div className="mt-3 d-flex justify-content-center">
                  <AppButton
                    color="primary"
                    tag={TenantLink}
                    to={`/rentalOrders/new?${qs.stringify({ rentalItemId: id, ...pick(queryParams, ['agentId', 'agentShopId']), })}`}
                  >
                    <span className="fas fa-arrow-right mr-1" />
                    注文に進む
                  </AppButton>
                </div>
              </div>
            </AppCard>
          );
        })}
      </div>
    </div>
  );
}

RentalItems.preview = true;

export default PublicPage(RentalItems);
