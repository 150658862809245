import React, { Fragment, useState, } from 'react';
import { isEmpty } from 'lodash';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useToggle, } from 'react-use';

import TenantLink from './TenantLink';

export default function PopoverButton(props) {
  const { id, header, body, popoverProps, ...extraProps } = props;
  const [isOpen, toggle] = useToggle(false);

  return (
    <Fragment>
      <Button id={`id-${id}`} {...extraProps}>
        {props.children}
      </Button>
      <Popover {...popoverProps} isOpen={isOpen} target={`#id-${id}`} toggle={toggle}>
        {isOpen && header && <PopoverHeader>{header()}</PopoverHeader>}
        {isOpen && body && <PopoverBody>{body({ togglePopover: toggle, })}</PopoverBody>}
      </Popover>
    </Fragment>
  );
}
