import React, { useState } from 'react';
import { format as formatDate, startOfDay, startOfMonth, endOfMonth } from 'date-fns';
import { isEmpty, uniq, groupBy, orderBy } from 'lodash';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import firebase from '../firebase';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import QuerySelector from './QuerySelector';
import QueryDateRangeSelector from './QueryDateRangeSelector';
import useQueryParams from './hooks/useQueryParams';
import { useSettingDocument, tenantAreaSettingRef } from './../models/setting';
import { colors } from '../shared/config';
import { paceMakerTypes } from '../shared/models/paceMakerSetting';

const db = firebase.firestore();
const paceMakerResultsRef = db.collection('paceMakerResults');
const paceMakerSettingsRef = db.collection('paceMakerSettings');

export default function AdminMypagePaceMaker(props) {
  const { tenant, user } = props;
  const params = useQueryParams();
  const startOn = params.dateRange?.[0] ? new Date(`${params.dateRange[0]}T00:00:00`) : startOfMonth(new Date());
  const endOn = params.dateRange?.[1] ? new Date(`${params.dateRange[1]}T00:00:00`) : endOfMonth(new Date());
  const {
    destinations: destinationsForFilter,
    areaGroups: areaGroupsFilter,
    paceMakerTypes: paceMakerTypesFilter = ['order'],
  } = params;
  const { items: rows, isLoading } = useCollectionSubscriptionInTenant(
    paceMakerResultsRef.where('createdAt', '>=', startOfDay(startOn)).orderBy('createdAt'),
    [formatDate(startOn, 'yyyy/MM/dd')],
    { detail: true }
  );
  const { items: fixedPeriodRows, isLoadingFixedPeriod } = useCollectionSubscriptionInTenant(
    paceMakerSettingsRef.where('startDate', '>=', startOfDay(startOn)).orderBy('startDate', 'desc'),
    [formatDate(startOn, 'yyyy/MM/dd')],
    { detail: true }
  );
  const { items: monthlyRecurringRows, isLoadingMonthlyRecurring } = useCollectionSubscriptionInTenant(
    paceMakerSettingsRef.where('scheduleType', '==', 'monthlyRecurring').orderBy('createdAt', 'desc'),
    [],
    { detail: true }
  );
  const paceMakerSettings = [...fixedPeriodRows, ...monthlyRecurringRows];
  const destinationOptions = [...new Set(rows.map((_) => _.destinations?.split(',')).flat())].map((_) => ({
    label: _,
    value: _,
  }));
  const { data: areaSetting } = useSettingDocument(tenantAreaSettingRef(tenant?.id));
  const areaGroups = uniq(Object.values(areaSetting?.data || {}).map((_) => _.group));
  const areaGroupOptions = areaGroups.map((_) => ({ label: _, value: _ }));
  const paceMakerTypeOptions = Object.entries(paceMakerTypes).map(([k, v]) => ({ label: v.label, value: k }));
  const [activeTabId, setActiveTabId] = useState(null);

  let filteredRows = rows;
  let filteredSettings = paceMakerSettings;
  if (!isEmpty(paceMakerTypesFilter)) {
    filteredRows = filteredRows.filter((_) => paceMakerTypesFilter.includes(_.type));
    filteredSettings = filteredSettings.filter((_) => paceMakerTypesFilter.includes(_.type));
  }
  if (!isEmpty(destinationsForFilter)) {
    filteredRows = filteredRows.filter((_) => destinationsForFilter.some((email) => _.destinations.includes(email)));
    filteredSettings = filteredSettings.filter((_) =>
      destinationsForFilter.some((email) => _.destinations.includes(email))
    );
  }
  if (!isEmpty(areaGroupsFilter)) {
    filteredRows = filteredRows.filter((_) => areaGroupsFilter.some((areaGroup) => _.areaGroups.includes(areaGroup)));
    filteredSettings = filteredSettings.filter((_) =>
      areaGroupsFilter.some((areaGroup) => _.areaGroups.includes(areaGroup))
    );
  }

  const _data = orderBy(
    filteredRows
      .filter((row) => row.paceMakerSettingId === activeTabId)
      .map((row) => {
        return Object.entries(row.data)
          .map(([section, values]) => values.map((_) => ({ section, createdAt: row.createdAt, ..._ })))
          .flat();
      })
      .flat()
      .filter((_) => _.section !== '目標'),
    'section'
  );
  if (isLoading || isLoadingFixedPeriod || isLoadingMonthlyRecurring) return null;

  return (
    <div>
      <div className="d-flex flex-wrap justify-content-around align-items-start">
        <div className="d-flex gap-1 align-items-end">
          <QuerySelector
            paramName="paceMakerTypes"
            className="ml-0"
            width={250}
            options={paceMakerTypeOptions}
            defaultValue="order"
            label="種別で絞込み"
          />
          <QueryDateRangeSelector
            label="期間"
            defaultValue={[startOn, endOn]}
            paramName="dateRange"
            pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }}
          />
          <QuerySelector
            paramName="destinations"
            className="ml-0"
            width={250}
            isMulti
            options={destinationOptions}
            label="送信先で絞込み"
          />
          <QuerySelector
            paramName="areaGroups"
            width={250}
            max={10}
            isMulti
            options={areaGroupOptions}
            label="エリアグループで絞込み"
          />
        </div>
      </div>
      <div className="mt-3">
        <Nav tabs>
          {filteredSettings.map(({ id, name }) => {
            return (
              <NavItem key={id}>
                <NavLink className={classnames({ active: activeTabId === id })} onClick={() => setActiveTabId(id)}>
                  {name || id}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <Charts data={_data} />
      </div>
    </div>
  );
}

const Charts = ({ data }) => {
  const cardWidth = 600;
  const cardHeight = cardWidth * 0.8;
  const chartWidth = cardWidth * 0.9;
  const chartHeight = chartWidth * 0.7;

  return Object.entries(groupBy(data, (_) => _.section)).map(([section, sectionData]) => {
    return (
      <div key={section} className='m-3'>
        {section}
        <div className="mt-3 d-flex flex-wrap justify-content-around gap-3">
          {Object.entries(groupBy(sectionData, (_) => _.title)).map(([title, values]) => {
            const data = values.map((_) => ({
              date: formatDate(_.createdAt.toDate(), 'M/d'),
              '実績(日)': _.type === 'amount' ? _.dailyAmount : _.type === 'volume' ? _.dailyVolume : _.dailyCount,
              '実績(月)':
                _.type === 'amount' ? _.monthlyAmount : _.type === 'volume' ? _.monthlyVolume : _.monthlyCount,
              '目標(日)':
                _.type === 'amount'
                  ? _.targetAmountPerDay
                  : _.type === 'volume'
                  ? _.targetVolumePerDay
                  : _.targetCountPerDay,
              '目標(月)': _.type === 'amount' ? _.targetAmount : _.type === 'volume' ? _.targetVolume : _.targetCount,
            }));
            return (
              <div key={title} className="card" style={{ width: cardWidth, height: cardHeight }}>
                <div className="card-header d-flex justify-content-between">
                  <div className="flex-fill">{title}</div>
                </div>
                <div className="card-body d-flex justify-content-center align-items-center">
                  <div>
                    <LineChart width={chartWidth} height={chartHeight} data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis tickFormatter={(_) => _.toLocaleString()} />
                      <Tooltip />
                      <Line type="monotone" dataKey="実績(日)" stroke={colors[0]} strokeWidth={2} />
                      <Line type="monotone" dataKey="目標(日)" stroke={colors[5]} strokeWidth={2} />
                      <Line type="monotone" dataKey="実績(月)" stroke={colors[4]} strokeWidth={2} />
                      <Line type="monotone" dataKey="目標(月)" stroke={colors[2]} strokeWidth={2} />
                    </LineChart>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  });
};
