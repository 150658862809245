const { isEmpty } = require('lodash');
const { addHours, } = require('date-fns');

module.exports = {
  approvalRequestFields: () => {
    return {
      approvalOrRejectionComment: {
        label: '承認/否認コメント',
        type: 'text',
        readOnly: () => true,
      },
      downloadStartAt: {
        label: 'ダウンロード可能開始日時',
        type: 'datetime',
        validations: {
          laterThanNow: (v) => v > addHours(new Date(), -1),
        },
      },
      approvalRequestComment: {
        label: '承認申請コメント',
        type: 'text',
        validations: {
          required: (_) => !isEmpty(_),
        },
        placeholder: '目的、ダウンロード内容、期間を記入',
        hint: '承認後、「ダウンロード可能開始日時」から１時間ダウンロード可能',
      },
    };
  },
  approvalFields: () => {
    return {
      approvalRequestComment: {
        label: '承認申請コメント',
        type: 'text',
        readOnly: () => true,
      },
      downloadStartAt: {
        label: 'ダウンロード可能開始日時',
        type: 'datetime',
        readOnly: () => true,
      },
      approvalOrRejectionComment: {
        label: '承認/否認コメント',
        type: 'text',
        validations: {
          required: (_) => !isEmpty(_),
        },
        hint: '承認後、「ダウンロード可能開始日時」から１時間ダウンロード可能',
      },
    };
  },
};
