import React, { useEffect, useState, useMemo, } from 'react';
import Bugsnag from '@bugsnag/js'
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { pick, omitBy, isUndefined } from 'lodash';

import logo from '../images/logo.png';
import firebase from '../firebase';
import useLocale from './hooks/useLocale';
import useCollectionFetch from './hooks/useCollectionFetch';
import Footer from './Footer';
import { ProgressValueProvider } from './contexts/ProgressValueProvider';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');

export default function Root (props) {
  const initialTags = useCollectionFetch(db.collection('initialTags'), []);
  const [lang = 'ja', setLang] = useState();
  const [locale, setLocale] = useState();
  const { translate: _translate } = useLocale();
  const translate = useMemo(_ => _ => _translate(_, locale), [locale]);
  useEffect(() => {
    return auth.onAuthStateChanged(async (firebaseUser) => {
      if(firebaseUser != null) {
        try {
          const doc = await usersRef.doc(firebaseUser.uid).get();
          const { displayName } = await doc.data();
          Bugsnag.setUser(firebaseUser.uid, firebaseUser.email, displayName);
          // NOTE: メールアドレス変更時に届くメールにて、メールアドレスが戻されるケースがあるため、firebaseUserのemailを正とする
          await doc.ref.update({ email: firebaseUser.email });
          if(displayName != null) return;

          await doc.ref.update(omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined));
        } catch(e) {
          console.error(e);
        }
      }
    });
  }, []);
  useEffect(() => {
    initialTags.map((initialTag) => {
      const fragment = document.createRange().createContextualFragment(initialTag.body);
      document.body.appendChild(fragment);
    });
  }, [initialTags]);

  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh', }}>
      <BrowserRouter>
        <ProgressValueProvider>
          <div className="flex-fill">
            {props.routes({ lang, setLang, locale, setLocale, translate, })}
          </div>
        </ProgressValueProvider>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};
