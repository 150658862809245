import { useState } from 'react';
import { omitBy, isUndefined, pick } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

import useFirebaseUser from '../hooks/useFirebaseUser';
import { useDocumentData } from '../hooks/useDocumentData';
import { DocumentReference, updateDoc } from '../../firebase';

export const EditButton = ({
  label = '編集',
  icon = <span className='fas fa-edit mr-1' />,
  validateOnSubmit = async (_) => true,
  itemRef,
  FormModal,
  formProps,
  beforeSubmit = (_) => _,
  onFinish = (_) => _,
  ...extraProps
}: {
  label?: string;
  icon?: JSX.Element | null;
  validateOnSubmit?: (values: any) => Promise<boolean>;
  itemRef: DocumentReference;
  FormModal: any;
  formProps: any;
  beforeSubmit?: any;
  onFinish?: any;
  disabled?: boolean;
  className?: string;
  color?: string;
}) => {
  const { firebaseUser } = useFirebaseUser();
  const [showsModal, setShowsModal] = useState(false);
  // TODO: 中でデータとるのやめたい
  const { data: item } = useDocumentData(showsModal ? itemRef : null);
  const onSubmit = async (values) => {
    const { id } = itemRef;
    if (!(await validateOnSubmit({ id, ...values }))) return;

    try {
      await updateDoc(itemRef, {
        ...(await beforeSubmit({ id, ...values })),
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      });
      toast.success('保存しました');
      setShowsModal(false);
      onFinish(values);
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color='secondary' aria-label='edit-button' onClick={(_) => setShowsModal(true)} {...extraProps}>
      {icon}
      {label}
      {item && (
        <FormModal
          isOpen={showsModal}
          values={item}
          onClickClose={(_) => setShowsModal(false)}
          onSubmit={onSubmit}
          {...formProps}
        />
      )}
    </Button>
  );
};
