import React from 'react';
import classnames from 'classnames';

const steps = [
  {
    label: '商品の選択',
    title: (
      <span>
        <span className="small bg-black text-white py-1 px-2 mr-2">
          STEP 1
        </span>
        <span>
          商品の選択
        </span>
      </span>
    ),
  },
  {
    label: '注文情報の入力',
    title: (
      <span>
        <span className="small bg-black text-white py-1 px-2 mr-2">
          STEP 2
        </span>
        <span>
          注文情報の入力
        </span>
      </span>
    ),
  },
  {
    label: '確認',
    title: '注文内容の確認',
  },
  {
    label: '完了',
    title: '注文完了',
  },
];

export default function EntryflowSection(props) {
  const { activeIndex } = props;
  const activeStep = steps[activeIndex];

  return (
    <section className="container-fluid bg-light-grey p-3">
      <h5 className="text-center font-weight-bold p-3">
        {activeStep.title}
      </h5>
      <div>
        <ul className="stepflow m-0">
          {
            steps.map((step, i) => {
              return (
                <li className={classnames({ completed: i < activeIndex, active: i === activeIndex, })}>
                  <span className="bubble" />
                  {step.label}
                </li>
              );
            })
          }
        </ul>
      </div>
    </section>
  )
};

