import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form } from 'reactstrap';
import { keyBy, omit, mapValues } from 'lodash';
import { useList } from 'react-use';

import ListForm from '../ListForm';
import { picFields } from '../../shared/models/lecture';
import useFormState from '../hooks/useFormState';
import Field from '../Field';

export default function PicsFormModal(props) {
  const { isOpen, pics, staffs, onClickClose } = props;
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(pics || []);
  const onSubmit = async (event) => {
    event.preventDefault();

    props.onSubmit({ pics: items });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">担当者編集</h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <div>
            <ListForm
              items={items}
              initialItem={{ picId: null, staffRole: '' }}
              hideAddButton={items.length >= 2}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <PicForm
                      values={item}
                      staffs={staffs}
                      onChange={(_) => updateItemAt(itemIndex, { ...item, ..._ })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

function PicForm(props) {
  const { values, staffs, onChange } = props;
  const statedFields = useFormState(values, picFields({ staffs }), values);
  useEffect(
    () => {
      onChange(mapValues(statedFields, 'value'));
    },
    Object.values(statedFields).map((_) => _.value)
  );

  return (
    <div className="d-flex flex-wrap gap-1">
      {Object.entries(statedFields).map(([fieldName, fieldSetting]) => (
        <div key={fieldName} style={{ width: fieldSetting.width || 100 }}>
          <Field name={fieldName} {...fieldSetting} />
        </div>
      ))}
    </div>
  );
}
