import React, { useEffect, useMemo, Fragment, } from 'react';
import { Button, } from 'reactstrap';
import { uniqBy, trim, pick, omit, isEmpty, orderBy, sortBy, keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, startOfDay, endOfDay, } from 'date-fns';
import numeral from 'numeral';
import moji from 'moji';

import firebase from '../../firebase';
import { colors, prefectures, deliveryTimes, } from '../../shared/config';
import { fieldDisplayValue, postalCodeWithHyphen, } from '../../shared/util';
import { canUpdateShipping } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import TenantLink from '../TenantLink';
import ModelFormModal from '../modals/ModelFormModal';
import ShippingFormModal from '../modals/ShippingFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import { fields, types, statuses, } from '../../shared/models/shipping';
import QuerySelector from '../QuerySelector';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { keys, entries } = Object;
const db = firebase.firestore();
const typeOptions = entries(types).map(([k, v]) => ({ label: v.label, value: k }));
const statusOptions = entries(statuses).map(([k, v]) => ({ label: v.label, value: k }));
const replaceSpaceAndChar = _ => (_ || '').replace(/[ 　]([a-zA-Z])/g, 'ー$1');
const trimAndReplaceDashAndEtc = _ => trim(_ || '').replace(/[-ー―−‐—]/g, '-').replace(/\t/, '');

export default AdminPage(function AdminShippings (props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const shippedOnStartOn = useMemo(_ => queryParams.shippedOnRange?.[0] ? startOfDay(new Date(queryParams.shippedOnRange[0])) : null, [queryParams.shippedOnRange]);
  const shippedOnEndOn = useMemo(_ => queryParams.shippedOnRange?.[1] ? endOfDay(new Date(queryParams.shippedOnRange[1])) : null, [queryParams.shippedOnRange]);
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes').orderBy('index'));
  const productTypesById = keyBy(productTypes, 'id');
  const productTypeOptions = productTypes.map(_ => ({ value: _.id, label: _.name }));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productOptions = products.map(_ => ({ value: _.id, label: _.code }));
  const productsById = keyBy(products, 'id');
  const _shippings = useCollectionSubscriptionInTenant(db.collection('shippings'));
  const shippings = useMemo(_ => orderBy(_shippings, _ => _.createdAt?.toDate(), 'desc'), [_shippings]);

  let filteredRowGroups = shippings;
  if(shippedOnStartOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.shippedOn?.toDate() >= shippedOnStartOn);
  }
  if(shippedOnEndOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.shippedOn?.toDate() <= shippedOnEndOn);
  }
  if(!isEmpty(queryParams.types)) {
    filteredRowGroups = filteredRowGroups.filter(_ => queryParams.types.includes(_.type));
  }
  if(!isEmpty(queryParams.statuses)) {
    filteredRowGroups = filteredRowGroups.filter(_ => queryParams.statuses.includes(_.status));
  }
  filteredRowGroups = filteredRowGroups.map((rowGroup) => {
    let filteredItems = rowGroup.items;
    if(!isEmpty(queryParams.productTypeIds)) {
      filteredItems = filteredItems.filter(_ => productsById[_.productId]?.productTypeIds?.some(_ => queryParams.productTypeIds.includes(_)));
    }
    if(!isEmpty(queryParams.productIds)) {
      filteredItems = filteredItems.filter(_ => queryParams.productIds.includes(_.productId));
    }
    return {
      ...rowGroup,
      filteredItems,
    };
  }).filter(_ => _.filteredItems.length > 0);

  const rowsByDeliveryCarrier = (deliveryCarrier) => {
    const targetShippings = filteredRowGroups
      .filter(_ => _.type === 'ship')
      .filter(_ => _.status === 'initial');
    return targetShippings.map((shipping) => {
      const { id, ref, type, shippedOn, items, sourceOrderId, cancelledAt, destinationName, destinationNameKana, destinationPhone, destinationPostalCode, destinationPrefecture, destinationCity, destinationAddress, } = shipping;
      const deliveryDate = { toDate: _ => new Date(), };
      const deliveryTime = 'at19';
      const firstProduct = productsById[items[0]?.productId];

      return {
        出荷ID: id,
        ...({
          sagawa: { 元着区分: 1, },
          yamato: { 送り状種類: 0, },
        })[deliveryCarrier],
        品名: deliveryCarrier === 'sagawa' ? moji(firstProduct?.shippingName).convert('HE', 'ZE').convert('HS', 'ZS').toString() : firstProduct?.shippingName,
        出荷数量: items.map(_ => _.quantity).join(','),
        配送先郵便番号: postalCodeWithHyphen(destinationPostalCode),
        配送先都道府県: prefectures[destinationPrefecture],
        配送先市区郡町村: destinationCity,
        ['配送先住所1']: replaceSpaceAndChar(trimAndReplaceDashAndEtc(destinationAddress)).slice(0, 16),
        ['配送先住所2']: replaceSpaceAndChar(trimAndReplaceDashAndEtc(destinationAddress)).slice(16),
        配送先宛名: replaceSpaceAndChar(trimAndReplaceDashAndEtc(destinationName)),
        配送先電話番号: replaceSpaceAndChar(trimAndReplaceDashAndEtc(destinationPhone)),
        配送日: deliveryDate && ({
          yamato: formatDate(deliveryDate.toDate(), 'yyyy/MM/dd'),
          sagawa: formatDate(deliveryDate.toDate(), 'yyyyMMdd'),
        })[deliveryCarrier],
        配送時間: deliveryTimes[deliveryTime || 'anytime'][deliveryCarrier],
        注文番号: trimAndReplaceDashAndEtc(sourceOrderId),
        配送元郵便番号: postalCodeWithHyphen(tenant.postalCode),
        配送元都道府県: prefectures[tenant.prefecture],
        配送元市区郡町村: tenant.city,
        ['配送元町・番地']: tenant.address1,
        ['配送元マンション・ビル名']: tenant.address2,
        配送元名: tenant.name,
        配送元電話番号: tenant.phone,
      };
    });
  };
  const processRows = (rows) => {
    return uniqBy(rows.slice().reverse(), _ => _['お客様管理ナンバー'] || _['お客様管理番号'] || _['顧客管理番号']);
  };
  const processRow = (batch, row) => {
    const _id = row['お客様管理ナンバー'] || row['お客様管理番号'] || row['顧客管理番号'] || row['お客様側管理番号'];
    const _trackingNumber = row[keys(row).find(_ => _.match(/お問い?合せ送り状/) || _.match('お問合せNO') || _.match('お問い合わせ番号') || _.match('送り状番号') || _ === '伝票番号')];
    if(!_id || !_trackingNumber) return;

    const trackingNumber = _trackingNumber.replace(/[ 　]/g, '');
    const id = _id.replace(/[ 　]/g, '');
    id && trackingNumber && batch.update(db.collection('shippings').doc(id), { trackingNumber, shippedOn: startOfDay(new Date()), });
  };

  return (
    <div>
      <div className="admin-shippings container-fluid py-5 position-relative bg-white">
        <div className="d-flex justify-content-center mb-3">
          <h4>出荷管理</h4>
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QueryDateRangeSelector label="出荷日" defaultValue={[shippedOnStartOn, shippedOnEndOn]} paramName="shippedOnRange" pickerProps={{ isClearable: true, showYearDropdown: true, dropdownMode: 'select' }} />
          <QuerySelector paramName="types" width={200} isMulti options={typeOptions} label="種別で絞込み" />
          <QuerySelector paramName="statuses" width={200} isMulti options={statusOptions} label="ステータスで絞込み" />
          <QuerySelector paramName="productTypeIds" width={400} isMulti options={productTypeOptions} label="商品種別で絞込み" />
          <QuerySelector paramName="productIds" width={300} isMulti options={productOptions} label="商品で絞込み" />
        </div>
        <div className="mt-4 d-flex justify-content-end mb-3 gap-1">
          <ImportButton label="伝票番号登録CSV" processRows={processRows} processRow={processRow} />
          <Button tag={TenantLink} target="_blank" to={`/admin/shippingPickingList`}>
            ピッキングリスト
          </Button>
          <ExportButton fileName={`yamato.csv`} label={`ヤマトCSV`} rows={rowsByDeliveryCarrier.bind(null, 'yamato')} />
          <ExportButton fileName={`sagawa.csv`} label={`佐川CSV`} rows={rowsByDeliveryCarrier.bind(null, 'sagawa')} />
          <AddInTenantButton itemRef={db.collection('shippings').doc()} FormModal={ShippingFormModal} formProps={{ products, }} disabled={!canUpdateShipping(user)} />
        </div>
        <div>
          {
            shippings.length > 0 ? (
              <table className="table table-bordered table-sm">
                <thead className="thead-light text-center text-nowrap">
                  <tr>
                    <th style={{ width: 200 }}></th>
                    {
                      entries(pick(fields(), ['shippedOn'])).map(([k, v]) => {
                        return (
                          <th key={k}>
                            {v.label}
                          </th>
                        );
                      })
                    }
                    <th>元受注ID</th>
                    <th>商品コード</th>
                    <th style={{ minWidth: 100, }}>数量</th>
                    {
                      entries(omit(fields(), ['shippedOn'])).map(([k, v]) => {
                        return (
                          <th key={k} style={{ minWidth: 100, }}>
                            {v.label}
                          </th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRowGroups.map((shipping) => {
                      const { id, ref, type, shippedOn, items, sourceOrderId, cancelledAt, } = shipping;

                      return (
                        <Fragment key={id}>
                          {
                            items.map((item, i) => {
                              const { productId, quantity, } = item;
                              const product = productsById[productId];
                              return (
                                <tr key={id} style={{ opacity: cancelledAt != null && 0.5, }}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={items.length} className="text-nowrap">
                                          {cancelledAt != null && <div className="mb-1"><span className="badge badge-secondary">キャンセル済み</span></div>}
                                          <EditButton itemRef={ref} FormModal={ShippingFormModal} formProps={{ products, }} disabled={!canUpdateShipping(user) || cancelledAt != null} />
                                          <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateShipping(user) || sourceOrderId != null} />
                                        </td>
                                        {
                                          entries(pick(fields(), ['shippedOn'])).map(([fieldName, fieldSetting]) => {
                                            return (
                                              <td key={fieldName} rowSpan={items.length}>
                                                {fieldDisplayValue(shipping[fieldName], fieldSetting)}
                                              </td>
                                            );
                                          })
                                        }
                                        <td rowSpan={items.length}>
                                          {
                                            sourceOrderId != null && (
                                              <TenantLink to={`/admin/orders/${sourceOrderId}`}>
                                                {sourceOrderId}
                                              </TenantLink>
                                            )
                                          }
                                        </td>
                                      </Fragment>
                                    )
                                  }
                                  <td>
                                    {product?.code}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format()}
                                  </td>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        {
                                          entries(omit(fields(), ['shippedOn'])).map(([fieldName, fieldSetting]) => {
                                            return (
                                              <td key={fieldName} rowSpan={items.length} style={{ whiteSpace: 'pre-line', }}>
                                                {fieldDisplayValue(shipping[fieldName], fieldSetting)}
                                              </td>
                                            );
                                          })
                                        }
                                      </Fragment>
                                    )
                                  }
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                出荷は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
