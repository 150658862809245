import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import InquiryReimbursementResultFormModal from './modals/InquiryReimbursementResultFormModal';
import { auditData } from '../shared/models/user';
import { updateDoc, DocumentReference } from '../firebase';
import { Inquiry } from '../types/inquiry';
import { TroubleInquiry } from '../types/troubleInquiry';
import { User } from '../types/user';

export const InquiryReimbursementButton = ({
  inquiryRef,
  inquiry,
  user,
  disabled,
  onStart,
  onFinish,
  onCancel,
}: {
  inquiryRef: DocumentReference<TroubleInquiry | Inquiry>;
  inquiry: TroubleInquiry | Inquiry;
  user: User;
  disabled?: boolean;
  onStart?: () => Promise<void>;
  onFinish?: () => Promise<void>;
  onCancel?: () => Promise<void>;
}) => {
  const { hasReimbursement, reimbursementRequest } = inquiry;
  const [showsModal, toggleModal] = useToggle(false);
  const onClickStart = async () => {
    if (!window.confirm('本当に立替申請の受付を開始しますか？')) return;

    try {
      await updateDoc(inquiry.ref, { hasReimbursement: true, status: 'awaiting', updatedBy: auditData(user) });
      await onStart?.();
      toast.success('立替申請の受付を開始しました');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <>
      {!hasReimbursement ? (
        <Button color="info" outline onClick={onClickStart} disabled={disabled}>
          立替受付
        </Button>
      ) : (
        <>
          <Button color="info" disabled={disabled} onClick={() => toggleModal(true)} outline={!reimbursementRequest}>
            立替精算
          </Button>
          <InquiryReimbursementResultFormModal
            inquiry={inquiry}
            user={user}
            isOpen={showsModal}
            onClickClose={() => toggleModal(false)}
            onSubmit={onFinish}
            onCancel={onCancel}
          />
        </>
      )}
    </>
  );
};
