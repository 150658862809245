import React, { useEffect, Fragment, } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { useToggle } from 'react-use';
import { sortBy, isEmpty, keyBy, get, omitBy, isUndefined, pick, } from 'lodash';
import numeral from 'numeral';
import classnames from 'classnames';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import { batch, } from '../../shared/firebase';
import useQueryParams from '../hooks/useQueryParams';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';
import AdminPage from '../hocs/AdminPage';
import QueryBoolean from '../QueryBoolean';

const { keys } = Object;
const db = firebase.firestore();
const COLUMN_WIDTH = 120;

export default AdminPage(function AdminProductsAgents (props) {
  const { user, } = props;
  const queryParams = useQueryParams();
  const products = sortBy(useCollectionSubscriptionInTenant(db.collection('products')), 'code');
  const agents = sortBy(useCollectionSubscriptionInTenant(db.collection('agents')), _ => _.createdAt.toDate());
  const filteredAgents = queryParams.showsHidden === '1' ? agents : agents.filter(_ => !_.isHidden);
  const agentProductSettings = useCollectionSubscriptionInTenant(db.collectionGroup('agentProductSettings'));
  const agentProductSettingsByAgentIdAndProductId = keyBy(agentProductSettings, _ => [_.ref.parent.parent.id, _.id].join('--'));
  const filteredProducts = products.filter(_ => !_.isPart);
  const importRows = async (rows) => {
    const items = rows.filter(_ => _.productId).flatMap((row) => {
      return agents.map((agent) => {
        return {
          agent,
          productId: row.productId,
          isHiddenForReferral: row[agent.name]?.split(',').includes('紹介非表示'),
          isShownForReferral: row[agent.name]?.split(',').includes('紹介強制表示'),
          isHiddenForWholesale: row[agent.name]?.split(',').includes('卸非表示'),
          isShownForWholesale: row[agent.name]?.split(',').includes('卸強制表示'),
        };
      });
    });
    await batch(db, items, (batch, item) => {
      const { agent, productId, isHiddenForReferral, isShownForReferral, isHiddenForWholesale, isShownForWholesale, } = item;
      const agentProductSetting = agentProductSettingsByAgentIdAndProductId[[agent.id, productId].join('--')];
      batch.set(agent.ref.collection('agentProductSettings').doc(productId), {
        tenantId: user.tenantId,
        isHiddenForReferral,
        isShownForReferral,
        isHiddenForWholesale,
        isShownForWholesale,
        [agentProductSetting ? 'updatedBy' : 'createdBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
      }, { merge: true });
    });
  };
  const rowsForExport = () => {
    return filteredProducts.map((product) => {
      const { id, ref, name, code, } = product;
      return {
        productId: id,
        productCode: code,
        productName: name,
        ...(
          filteredAgents.reduce((x, agent) => {
            const { id, name } = agent;
            const agentProductSetting = agentProductSettingsByAgentIdAndProductId[[id, product.id].join('--')];
            return {
              ...x,
              [agent.name]: [agentProductSetting?.isHiddenForReferral && '紹介非表示', agentProductSetting?.isShownForReferral && '紹介強制表示', agentProductSetting?.isHiddenForWholesale && '卸非表示', agentProductSetting?.isShownForWholesale && '卸強制表示'].filter(_ => _).join(','),
            };
          }, {})
        ),
      };
    });
  };

  return (
    <div className="small">
      <div className="admin-products-agents container-fluid py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center mb-3">
                商品 × 代理店管理
              </h5>
              <div className="d-flex justify-content-end mb-3 gap-1 align-items-end">
                <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
                <ImportButton processRows={importRows} />
                <ExportButton fileName={`商品_代理店.csv`} rows={rowsForExport} />
              </div>
              <div className="overflow-auto" style={{ maxHeight: '100vh', }}>
                <table className="table table-bordered sticky-table m-0">
                  <thead className="thead-light text-center">
                    <tr>
                      <th style={{ minWidth: 230 }} className="sticky">商品</th>
                      {
                        filteredAgents.map((agent) => {
                          const { id, name } = agent;
                          return (
                            <th colSpan={2} key={id} style={{ minWidth: COLUMN_WIDTH * 2 }}>
                              {name}
                            </th>
                          );
                        })
                      }
                    </tr>
                  </thead>
                  <tbody className="thead-light">
                    {
                      filteredProducts.map((product) => {
                        const { id, ref, name, code, } = product;
                        return (
                          <tr key={id}>
                            <th className="sticky">
                              <div>
                                [{code}] {name}
                              </div>
                              <div>
                                {product.isHidden && <div className="badge badge-secondary">非表示</div>}
                              </div>
                            </th>
                            {
                              filteredAgents.map((agent) => {
                                const { id, name } = agent;
                                const agentProductSetting = agentProductSettingsByAgentIdAndProductId[[id, product.id].join('--')];
                                const ref = agent.ref.collection('agentProductSettings').doc(product.id);
                                const isConclusivelyShownForReferral = agentProductSetting?.isShownForReferral || !product.isHidden && !agentProductSetting?.isHiddenForReferral;
                                const isConclusivelyShownForWholesale = agentProductSetting?.isShownForWholesale || !product.isHidden && !agentProductSetting?.isHiddenForWholesale;
                                const onChange = (fieldName, value) => {
                                  ref.set({
                                    tenantId: user.tenantId,
                                    [fieldName]: !value,
                                    [agentProductSetting ? 'updatedBy' : 'createdBy']: omitBy(pick(user, ['uid', 'email', 'displayName']), isUndefined),
                                  }, { merge: true });
                                };
                                return (
                                  <Fragment>
                                    <td key={id} className={classnames({ 'bg-grey': !isConclusivelyShownForReferral })}>
                                      {
                                        [['isHiddenForReferral', '紹介非表示'], ['isShownForReferral', '紹介強制表示']].map(([fieldName, label]) => {
                                          const value = !!agentProductSetting?.[fieldName];

                                          return (
                                            <FormGroup check key={fieldName}>
                                              <Label check>
                                                <Input type="checkbox" checked={value} onChange={onChange.bind(null, fieldName, value)} />
                                                {label}
                                              </Label>
                                            </FormGroup>
                                          );
                                        })
                                      }
                                    </td>
                                    <td key={id} className={classnames({ 'bg-grey': !isConclusivelyShownForWholesale })}>
                                      {
                                        [['isHiddenForWholesale', '卸非表示'], ['isShownForWholesale', '卸強制表示']].map(([fieldName, label]) => {
                                          const value = !!agentProductSetting?.[fieldName];

                                          return (
                                            <FormGroup check key={fieldName}>
                                              <Label check>
                                                <Input type="checkbox" checked={value} onChange={onChange.bind(null, fieldName, value)} />
                                                {label}
                                              </Label>
                                            </FormGroup>
                                          );
                                        })
                                      }
                                    </td>
                                  </Fragment>
                                );
                              })
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
