import React from 'react';
import { last, } from 'lodash';
import ellipsis from 'text-ellipsis';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import AdminHelp from './AdminHelp';
import AdminReaction from './AdminReaction';
import breadcrumbs from '../breadcrumbs';
import TenantLink from './TenantLink';

export default function BreadNav (props) {
  const { user, currentPath, currentParams, prefixContent = _ => null, values = {} } = props;
  const currentPathWithoutTenantPath = currentPath?.replace(/^\/:tenantPath/, '');
  const helpKey = [encodeURIComponent(currentPath?.replace(/:([^/]+)/g, (_, paramName) => currentParams[paramName]))].join('__');
  const reactionKey = helpKey;

  const computeBreadcrumbs = (path) => {
    const breadcrumb = breadcrumbs[path];
    if(!breadcrumb) return [];
    return [...computeBreadcrumbs(breadcrumb.parent), { ...breadcrumb, path }];
  };
  const computedBreadcrumbs = computeBreadcrumbs(currentPathWithoutTenantPath);

  return (
    <Breadcrumb listClassName="mb-0 d-flex justify-content-between align-items-start">
      <div className="d-flex gap-1">
        {
          computedBreadcrumbs.map(({ title, path, isNotLink = false, }) => {
            const isActive = path === currentPathWithoutTenantPath;
            const linkPath = path.replace(/:([^/]+)/g, (_, paramName) => currentParams[paramName]);
            const displayTitle = ellipsis(title(values, currentParams) || '', 20);
            return (
              <BreadcrumbItem key={path} active={isActive}>
                {
                  (isNotLink || isActive) ? (
                    <span>{displayTitle}</span>
                  ) : (
                    <TenantLink to={linkPath}>{displayTitle}</TenantLink>
                  )
                }
              </BreadcrumbItem>
            );
          })
        }
        {last(computedBreadcrumbs)?.isDetailScreen && <AdminHelp helpKey={helpKey} user={user} title="メモ" label={false} buttonProps={{ className: 'p-0', }} icon="sticky-note" />}
        {last(computedBreadcrumbs)?.reactionEnabled && <AdminReaction user={user} reactionKey={reactionKey} className="ml-2" />}
      </div>
      {prefixContent()}
    </Breadcrumb>
  );
};
