import React, { useEffect, useMemo, useState, } from 'react';
import ellipsis from 'text-ellipsis';
import { isEmpty, countBy, keyBy, orderBy, } from 'lodash';
import { Button, Modal, ModalBody, ModalFooter, Form, } from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import { format as formatDate, } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { batch } from '../../shared/firebase';
import { canUpdateProductType } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields } from '../../shared/models/textTemplate';

const db = firebase.firestore();
const { entries, keys, } = Object;

const numberCell = (num, format = '') => {
  return (
    <td className="text-right">
      {numeral(num).format(format)}
    </td>
  );
};

export default function AnswerStatModal(props) {
  const { isOpen, onClickClose, defaultQuestionId, questions, rows, } = props;
  const [questionId, setQuestionId] = useState(defaultQuestionId);
  const questionsById = keyBy(questions, 'id');
  const questionOptions = questions.map(_ => ({ label: _.name, value: _.id }));
  const question = questionsById[questionId];
  const data = (() => {
    const answers = rows.map(_ => _.answers?.[questionId]).filter(_ => !isEmpty(_)).map(_ => ({
      text: _,
      checkbox: keys(_ || {}),
      radio: _,
      select: _,
      imageFile: [_].flat().some(_ => _?.url) ? 'あり' : 'なし',
    }[question?.type]));
    const flatAnswers = answers.flat();
    const counts = countBy(flatAnswers, _ => _);
    return {
      rows,
      answers,
      flatAnswers,
      counts,
    };
  })();

  return question != null && (
    <Modal isOpen={isOpen} style={{ width: '90vw', maxWidth: 900 }}>
      <div className='modal-header'>
        <Select
          value={questionOptions.find(_ => _.value === questionId) || null}
          options={questionOptions}
          onChange={_ => setQuestionId(_.value)}
          className="w-100"
        />
      </div>
      <ModalBody>
        <div>
          <table className="table table-bordered table-stripe">
            <tbody>
              <tr>
                <th>
                  総数
                </th>
                {numberCell(data.rows.length)}
              </tr>
              <tr>
                <th>
                  回答者数
                </th>
                {numberCell(data.answers.length)}
              </tr>
              <tr>
                <th>
                  回答者率
                </th>
                {numberCell(data.answers.length / data.rows.length, '0.00%')}
              </tr>
              <tr>
                <th>
                  総回答数(複数回答をそれぞれカウント)
                </th>
                {numberCell(data.flatAnswers.length)}
              </tr>
            </tbody>
          </table>
          <table className="mt-5 table table-bordered table-stripe">
            <thead className="thead-light">
              <tr>
                <th style={{ minWidth: 80 }}>回答内容</th>
                <th style={{ minWidth: 200 }}>件数</th>
                <th style={{ minWidth: 200 }}>回答率(対総回答数)</th>
              </tr>
            </thead>
            <tbody>
              {
                orderBy(entries(data.counts), '[1]', 'desc').map(([label, count]) => {
                  return (
                    <tr key={label}>
                      <th>
                        {ellipsis(label, 15)}
                      </th>
                      {numberCell(count)}
                      {numberCell(count / data.flatAnswers.length, '0.00%')}
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className='cancel' color='secondary' onClick={onClickClose}>閉じる</Button>
      </ModalFooter>
    </Modal>
  );
};
