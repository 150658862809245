import Avatar from 'react-avatar';
import { format as formatDate } from 'date-fns';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { UserAuditData } from '../types/user';

// NOTE: messageがhtmlに変換されたときに余計なマージンつかないようにしている
const CustomComponent: any = ({ className, children, ...props }) => {
  const {
    node: { tagName: Tag },
  } = props;

  return (
    <Tag {...props} className={`${className} m-0`}>
      {children}
    </Tag>
  );
};

export default function ChatMessage({
  message,
  owner,
  createdAt,
  isOwnMessageView = false,
  ownerAlias,
}: {
  message: string;
  owner: UserAuditData;
  createdAt: Date;
  isOwnMessageView?: boolean;
  ownerAlias?: string;
}) {
  const components: Components = {
    p: CustomComponent,
    pre: CustomComponent,
  };
  const messageBox = (message: string, isOwn: boolean) => {
    const backgroundColor = isOwn ? 'bg-grey' : 'bg-light-grey';

    return (
      <div className={`${backgroundColor} rounded-3 p-2`} style={{ whiteSpace: 'pre-line' }}>
        <ReactMarkdown children={message} remarkPlugins={[remarkGfm]} linkTarget='_blank' components={components} />
      </div>
    );
  };

  return isOwnMessageView ? (
    <div className='d-flex align-items-end justify-content-end gap-1 w-100'>
      <div className='d-flex flex-column align-items-end w-75'>
        <div className='small text-muted'>{formatDate(createdAt, 'yyyy/MM/dd HH:mm')}</div>
        {messageBox(message, isOwnMessageView)}
      </div>
    </div>
  ) : (
    <div className='d-flex align-items-end gap-1 w-100'>
      <Avatar name={ownerAlias || owner.displayName} size='30' round />
      <div className='d-flex flex-column align-items-start w-75'>
        <div className='small text-muted'>
          {ownerAlias || owner.displayName} {formatDate(createdAt, 'yyyy/MM/dd HH:mm')}
        </div>
        {messageBox(message, isOwnMessageView)}
      </div>
    </div>
  );
}
