import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import { isString, sortBy, keyBy, } from 'lodash';

import { canUpdateAgent, } from '../shared/abilities';
import firebase from '../firebase';
import { beforeDelete, } from '../util';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from './hooks/useCollectionSubscriptionInTenant';
import useQueryParams from './hooks/useQueryParams';
import AdminPage from './hocs/AdminPage';
import AdminAgentShopHeader from './AdminAgentShopHeader';
import ModelFormModal from './modals/ModelFormModal';
import AddInTenantButton from './AddInTenantButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import ModalButton from './ModalButton';
import QueryBoolean from './QueryBoolean';
import { fields } from '../shared/models/rentalItemGroup';
import TenantLink from './TenantLink';

const db = firebase.firestore();

export default function AgentRentalItemGroups (props) {
  const { agent } = props;
  const rentalItemGroups = sortBy(useCollectionSubscriptionInTenant(agent.ref.collection('rentalItemGroups'), []), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <AddInTenantButton itemRef={agent.ref.collection('rentalItemGroups').doc()} FormModal={ModelFormModal} formProps={{ documentName: 'rentalItemGroup', title: 'グループ追加', fields: fields }} />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            rentalItemGroups.map((rentalItemGroup) => {
              const { id, ref, name, } = rentalItemGroup;
              const beforeDelete = async () => {
                if(
                  (await agent.ref.collection('rentalItems').where('rentalItemGroupId', '==', id).limit(1).get()).docs.length > 0
                ) {
                  toast.error('使用されているため削除できません');
                  return false;
                }
              };

              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td className="text-nowrap text-right">
                    <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'rentalItemGroup', title: 'グループ', fields: fields(), }} />
                    <DeleteButton itemRef={ref} className="ml-1" beforeDelete={beforeDelete} />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
