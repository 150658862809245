import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signOut as _signOut,
  onAuthStateChanged as _onAuthStateChanged,
  NextOrObserver,
  User,
  signInAnonymously as _signInAnonymously,
} from '@firebase/auth';

const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithRedirect(getAuth(), provider);
};

const onAuthStateChanged = (nextOrObserver: NextOrObserver<User>) => _onAuthStateChanged(getAuth(), nextOrObserver);

const signOut = () => _signOut(getAuth());

const sendPasswordResetEmail = (email: string) => _sendPasswordResetEmail(getAuth(), email);

const signInAnonymously = () => _signInAnonymously(getAuth());

export {
  getAuth,
  signInWithGoogle,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  signInAnonymously,
};
