import React from 'react';
import { Button } from 'reactstrap';
import { format as formatDate } from 'date-fns';
import { isEmpty, sortBy, keyBy, } from 'lodash';

import firebase from '../../firebase';
import useAppTitle from '../hooks/useAppTitle';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import InvitedUserPage from '../hocs/InvitedUserPage';
import PriceDisplay from '../PriceDisplay';
import { lectureLevels, lectureAges } from '../../shared/config';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const db = firebase.firestore();
const eventsRef = db.collection('events');

export default InvitedUserPage(function AdminEvent(props) {
  const {
    match: {
      params: { eventId },
    },
  } = props;
  const eventRef = eventsRef.doc(eventId);
  const event = useDocumentSubscription(eventRef, [eventId]);
  const lectures = useCollectionSubscription(eventRef.collection('lectures'), [eventId]);
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('eventProductTypes')), _ => _.createdAt.toDate());
  const lectureTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('lectureTypes')), _ => _.createdAt.toDate());
  const eventProductTypesById = keyBy(eventProductTypes, 'id');
  const lectureTypesById = keyBy(lectureTypes, 'id');
  useAppTitle(event?.name);

  return (
    (event || null) && (
      <div>
        <div className="admin-event container py-5 position-relative">
          <div className="d-flex justify-content-center mb-3">
            <h4>{event.name}</h4>
          </div>
          {
            !isEmpty(event.noteForInvitedUser) && (
              <div className="bg-light-grey rounded p-3 mb-3">
                <RichTextContent html={event.noteForInvitedUser} />
              </div>
            )
          }
          <div className="d-flex justify-content-end mb-3">
            <Button
              color="secondary"
              className="ml-2"
              tag={TenantLink}
              to={`/events/${eventId}/qrcode`}
              target="_blank"
            >
              <span className="fas fa-external-link-alt mr-1" />
              当日申込みQRコードを表示する
            </Button>
          </div>
          <div className="row">
            <div className="col-12">
              {lectures.length > 0 ? (
                <table className="table">
                  <thead className="thead-light text-center">
                    <tr>
                      <th>開催日</th>
                      <th>商品</th>
                      <th>レクチャー種別</th>
                      <th>対象レベル</th>
                      <th>対象年齢</th>
                      <th>税込価格</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(lectures || []).map((lecture) => {
                      const { id, date, productType, lectureType, lectureLevel, lectureAge, price, isUserFree } =
                        lecture;
                      return (
                        <tr key={id}>
                          <td>
                            <TenantLink to={`/events/${eventId}/lectures/${id}`}>
                              {date && formatDate(date.toDate(), 'yyyy/MM/dd')}
                            </TenantLink>
                          </td>
                          <td>{eventProductTypesById[productType]?.name}</td>
                          <td>{lectureTypesById[lectureType]?.name}</td>
                          <td>{lectureLevels[lectureLevel]}</td>
                          <td>{lectureAges[lectureAge]}</td>
                          <td className="text-right">
                            <PriceDisplay price={price} isUserFree={isUserFree} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div>レクチャーは未登録です</div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
});
