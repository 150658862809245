import React from 'react';

import PublicPage from '../hocs/PublicPage';

export default PublicPage(function Terms(props) {
  return (
    <div className="terms h-100 py-5">
      <section className="container">
        <div className="text-center">
          (利用規約)
        </div>
      </section>
    </div>
  );
});
