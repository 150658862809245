import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { sortBy, get, omitBy, isUndefined, pick, } from 'lodash';
import Toggle from 'react-toggle'

import { canUpdateAgent, } from '../../shared/abilities';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import firebase, { functions } from '../../firebase';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AdminAgentHeader from '../AdminAgentHeader';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { agentSettingsFields } from '../../shared/models/agent';
import { fieldDisplayValue } from '../../shared/util';
import TenantLink from '../TenantLink';
import RichTextContent from '../RichTextContent';

const { keys } = Object;
const createUser = functions.httpsCallable('createUser');
const db = firebase.firestore();
const agentsRef = db.collection('agents');
const usersRef = db.collection('users');
const { entries } = Object;

export default AdminPage(function AdminAgentSettings (props) {
  const { user, match: { params: { agentId } }, addBreadNavValues } = props;
  const agentRef = agentsRef.doc(agentId);
  const { firebaseUser } = useFirebaseUser();
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const agentContractPlans = sortBy(useCollectionSubscriptionInTenant(db.collection('agentContractPlans'), []), _ => _.createdAt.toDate());
  useEffect(() => {
    addBreadNavValues({ agent })
  }, [agent]);

  return agent != null && (
    <div>
      <div className="admin-agent-settings container py-5 position-relative">
        <AdminAgentHeader activeTab="settings" user={user} agent={agent} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-end mb-3">
                <EditButton color="primary" itemRef={agent.ref} FormModal={ModelFormModal} formProps={{ documentName: 'agent', title: '設定', fields: agentSettingsFields({ agentContractPlans }), }} disabled={!canUpdateAgent(user)} />
              </div>
              <table className="table table-bordered align-middle thead-light">
                <tbody className="thead-light">
                  {
                    entries(agentSettingsFields({ agentContractPlans })).map(([fieldName, fieldSettings]) => {
                      const { label, type } = fieldSettings;
                      const value = fieldDisplayValue(agent[fieldName], fieldSettings);
                      return (
                        <tr>
                          <th style={{ width: 250 }}>
                            {label}
                          </th>
                          <td>
                            <RichTextContent html={value} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
