import React from 'react';
import { Badge, FormGroup, Label, Input, Button } from 'reactstrap';
import { keyBy, isEmpty, sortBy } from 'lodash';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';

import AdminPage from '../hocs/AdminPage';
import firebase, { getFirestore, collection, query, where, getCountFromServer } from '../../firebase';
import { roles, prefectures } from '../../shared/config';
import { userConditionText } from '../../shared/util';
import ExportButton from './../ExportButton';
import QueryText from './../QueryText';
import QuerySelector from './../QuerySelector';
import QueryBoolean from './../QueryBoolean';
import useCollectionSubscriptionInTenant from './../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from './../hooks/useCollectionSubscription';
import useQueryParams from './../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import { statuses, fields, orderTypes, entryTypes, dateTypes } from '../../shared/models/envelopeDelivery';
import TenantLink from '../TenantLink';
import { statuses as deliveryTargetStatuses } from '../../shared/models/deliveryTarget';

const db = firebase.firestore();

export default AdminPage(function AdminEnvelopeDeliveryDeliveryTargets(props) {
  const {
    user,
    match: {
      params: { envelopeDeliveryId },
    },
  } = props;
  const envelopeDeliveryRef = db.collection('envelopeDeliveries').doc(envelopeDeliveryId);
  const envelopeDelivery = useDocumentSubscription(envelopeDeliveryRef);
  const usersRef = db.collection(`envelopeDeliveries/${envelopeDeliveryId}/deliveryTargets`);
  const params = useQueryParams();
  const { field: [field] = ['email'], text, showsAllUsers: _showsAllUsers = '0' } = params;
  const showsAllUsers = _showsAllUsers === '1';
  const usersQuery = text
    ? usersRef.orderBy(field).startAt(text).endAt(`${text}\uf8ff`)
    : showsAllUsers
    ? usersRef
    : usersRef.orderBy('createdAt', 'desc').limit(100);
  const users = useCollectionSubscription(usersQuery, [params]);
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const fieldOptions = [
    { label: 'メールアドレス', value: 'email' },
    { label: 'アカウント名', value: 'displayName' },
    { label: '電話番号', value: 'phone' },
  ];

  const updateNotDelivery = async (id, value) => {
    await usersRef.doc(id).update({ notDelivery: value });
  };

  const onClickDelivery = async () => {
    const snapshot = await getCountFromServer(
      query(
        collection(getFirestore(), `envelopeDeliveries/${envelopeDeliveryId}/deliveryTargets`),
        where('notDelivery', '==', false),
        where('status', '==', 'initial')
      )
    );
    if (!window.confirm(`${snapshot.data().count}件が処理対象です。処理しますか？`)) return;

    await envelopeDeliveryRef.update({ status: 'initial' });
  };

  const isDeliveryPreview = envelopeDelivery?.status === 'preview';
  const isDeliveryCompleted = envelopeDelivery?.status === 'completed';

  return (
    <div>
      <div className="admin-users container-fluid py-5 position-relative d-flex flex-column gap-3">
        <div className="d-flex justify-content-center">
          <h4>一括郵便発送処理詳細</h4>
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-end">
          <Button color={isDeliveryPreview ? 'primary' : 'secondary'} onClick={onClickDelivery} disabled={!isDeliveryPreview}>
            {isDeliveryPreview ? '処理実行' : isDeliveryCompleted ? '処理済' : <><span className="fas fa-spin fa-spinner mr-1" />処理中</>}
          </Button>
        </div>
        <EnvelopeDeliveryTable envelopeDeliveryId={envelopeDeliveryId} envelopeDelivery={envelopeDelivery} />
        <div className="d-flex justify-content-center">
          <h4>処理対象</h4>
        </div>
        <div className="d-flex flex-wrap gap-2 align-items-end">
          <QuerySelector
            paramName="field"
            options={fieldOptions}
            label="検索フィールド"
            defaultValue={[field]}
            isClearable={false}
          />
          <QueryText paramName="text" label="検索テキスト" />
          <QueryBoolean paramName="showsAllUsers" label="全アカウント表示" defaultValue={'0'} />
        </div>
        <div className="d-flex justify-content-end gap-1 align-items-end">
          <ExportButton
            fileName="users.csv"
            rows={users.map((u) => ({
              ...['id', 'email', 'displayName', 'role', 'ngDm', 'note', 'subUserIds', 'mainUserId', 'notDelivery'].reduce(
                (x, k) => ({ ...x, [k]: u[k] }),
                {}
              ),
              conditionOrderId: u.conditions?.order ? u.conditions.order.id : '',
              conditionEntryPath: u.conditions?.entry ? u.conditions.entry.ref.path : '',
              conditionChildren: u.conditions?.children ? u.conditions.children[0].name : '',
              status: u.disabledAt ? '無効' : '',
            }))}
            hasPersonalInfo
            user={user}
          />
        </div>
        <div className="overflow-auto">
          {users.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th style={{ minWidth: 50 }}>ステータス</th>
                  <th style={{ minWidth: 150 }}>判定条件</th>
                  <th style={{ minWidth: 150 }}>アカウント名</th>
                  <th style={{ minWidth: 150 }}>メールアドレス</th>
                  <th style={{ minWidth: 100 }}>都道府県</th>
                  <th style={{ minWidth: 150 }}>市区町村</th>
                  <th style={{ minWidth: 150 }}>電話番号</th>
                  <th style={{ minWidth: 100 }}>権限</th>
                  <th style={{ minWidth: 100 }}>DM発送不可</th>
                  <th style={{ minWidth: 150 }}>ユーザータグ</th>
                  <th style={{ minWidth: 200 }}>メモ</th>
                  <th>処理対象</th>
                </tr>
              </thead>
              <tbody>
                {(users || []).map((user) => {
                  const {
                    id,
                    email,
                    displayName,
                    prefecture,
                    phone,
                    role = 'user',
                    ngDm = false,
                    note,
                    city,
                    userTagIds = [],
                    mainUserId,
                    subUserIds,
                    disabledAt,
                    conditions,
                    notDelivery,
                    status
                  } = user;
                  const { label: statusLabel, color } = deliveryTargetStatuses[status] || {};
                  return (
                    <tr key={id} style={{ background: notDelivery ? 'lightgray' : '' }}>
                      <td>
                        <Badge color={color}>{statusLabel}</Badge>
                      </td>
                      <td>
                        {conditions?.order && (
                          <div>
                            <Badge className="mr-1">注文条件</Badge>
                            <TenantLink to={`/admin/orders/${conditions.order.id}`}>{conditions.order.id}</TenantLink>
                          </div>
                        )}
                        {conditions?.entry && (
                          <div>
                            <Badge className="mr-1">イベント参加条件</Badge>
                            <TenantLink to={`/admin/${conditions.entry.ref.path}`}>{conditions.entry.id}</TenantLink>
                          </div>
                        )}
                        {conditions?.children && (
                          <div>
                            <Badge className="mr-1">年齢・乗り物経験</Badge>
                            <TenantLink to={`/admin/users/${id}`}>{conditions.children[0].name}</TenantLink>
                          </div>
                        )}
                      </td>
                      <td>
                        <TenantLink to={`/admin/users/${id}`}>{displayName}</TenantLink>
                        <div>
                          {!isEmpty(mainUserId) && <div className="badge badge-warning">サブアカウント</div>}
                          {!isEmpty(subUserIds) && (
                            <div className="badge badge-info">サブアカウントあり {subUserIds.length}件</div>
                          )}
                        </div>
                        {disabledAt && <Badge color="danger">無効</Badge>}
                      </td>
                      <td>{email}</td>
                      <td>{prefecture && prefectures[prefecture]}</td>
                      <td>{city}</td>
                      <td>{phone}</td>
                      <td>{roles[role]}</td>
                      <td>{ngDm && '発送不可'}</td>
                      <td>
                        <div className="d-flex flex-wrap gap-1">
                          {(userTagIds || []).map((_) => (
                            <div key={_.id} className="badge badge-secondary">
                              {userTagsById[_]?.name}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td style={{ whiteSpace: 'pre-line' }}>{note}</td>
                      <td>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={notDelivery}
                              onChange={(_) => updateNotDelivery(id, _.target.checked)}
                              disabled={isDeliveryCompleted}
                            />
                            除外する
                          </Label>
                        </FormGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>No Data</div>
          )}
        </div>
      </div>
    </div>
  );
});

const EnvelopeDeliveryTable = (props) => {
  const { envelopeDeliveryId, envelopeDelivery } = props;
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productsById = keyBy(products, 'id');
  const productTypes = sortBy(useCollectionSubscriptionInTenant(db.collection('productTypes')), _ => _.createdAt.toDate());
  const productTypesById = keyBy(productTypes, 'id');
  const events = useCollectionSubscriptionInTenant(db.collection('events'));
  const eventsById = keyBy(events, 'id');
  const envelopeProducts = useCollectionSubscriptionInTenant(db.collection('envelopeProducts').orderBy('code'));
  const envelopeProductsById = keyBy(envelopeProducts, 'id');
  const deliveryCandidates = useCollectionSubscriptionInTenant(
    db.collection(`envelopeDeliveries/${envelopeDeliveryId}/deliveryCandidates`)
  );
  if (!envelopeDelivery) return <></>;
  const {
    id,
    status,
    completedAt,
    orderType = 'any',
    conditionProductTypeIds,
    conditionProductIds,
    entryType = 'any',
    conditionEventIds,
    dateType,
    envelopeProductIds,
    deliveryCount,
  } = envelopeDelivery;
  const { label: statusLabel, color } = statuses[status] || {};
  return (
    <table className="table">
      <thead className="thead-light text-center text-nowrap">
        <tr>
          <th>処理日時</th>
          <th>ステータス</th>
          <th>注文条件</th>
          <th>イベント参加条件</th>
          <th>アカウント条件</th>
          <th>日付範囲</th>
          <th>対象件数</th>
          <th>郵送物</th>
        </tr>
      </thead>
      <tbody>
        <tr key={id}>
          <td style={{ whiteSpace: 'pre-line' }}>{completedAt ? formatDate(completedAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : '未処理'}</td>
          <td>
            <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
          </td>
          <td>
            {orderTypes[orderType]?.label}
            {orderType === 'ordered' && (
              <div>
                <div className="small text-muted">
                  {conditionProductTypeIds?.map((_) => productTypesById[_]?.name).join(',')}
                </div>
                <div className="small text-muted">
                  {conditionProductIds
                    ?.map((_) => productsById[_])
                    .map((_) => `[${_?.code}] ${_?.name}`)
                    .join(',')}
                </div>
              </div>
            )}
          </td>
          <td>
            {entryTypes[entryType]?.label}
            {entryType === 'entried' && (
              <div className="small text-muted">{conditionEventIds?.map((_) => eventsById[_]?.name).join(',')}</div>
            )}
          </td>
          <td className="text-muted small">{userConditionText(envelopeDelivery)}</td>
          <td>
            <span>{dateTypes[dateType]?.text({ data: envelopeDelivery })}</span>
          </td>
          <td className='text-right'>{numeral(deliveryCandidates.reduce((x, y) => x + y.deliveryCount, 0)).format('0,0')}</td>
          <td>
            {
              (envelopeProductIds || []).map((envelopeProductId) => {
                const envelopeProduct = envelopeProductsById[envelopeProductId];
                return (
                  <div key={envelopeProductId}>
                    [{envelopeProduct?.code}] {envelopeProduct?.name}
                  </div>
                );
              })
            }
          </td>
        </tr>
      </tbody>
    </table>
  );
};
