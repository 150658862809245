import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { tenantQueryConstraints } from './utils';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { SurveyDocumentData, Survey } from '../types';
import type { PartiallyPartial } from 'types';

export const surveyConverter = getConverter<SurveyDocumentData>();

export const surveysRef = () => collection(getFirestore(), 'surveys').withConverter(surveyConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Survey> : null;
export const surveyRef = <Id extends string | undefined>(surveyId: Id) =>
  (surveyId ? doc(surveysRef(), surveyId) : null) as RefOrNull<Id>;

export const newSurveyRef = () => doc(surveysRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const surveysQuery = (...queryConstraints: QueryConstraint[]) =>
  query(surveysRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantSurveysQuery = (tenantId: string | undefined) =>
  tenantId ? surveysQuery(...tenantQueryConstraints(tenantId)) : null;

export const addSurvey = async (data: PartiallyPartial<Survey, 'id' | 'createdAt'>) =>
  addDoc(surveysRef(), { createdAt: serverTimestamp(), ...data });

export const updateSurvey = async (surveyId: string, data: UpdateData<Survey>) => updateDoc(surveyRef(surveyId), data);

export const deleteSurvey = async (surveyId: string) => deleteDoc(surveyRef(surveyId));

export const useSurveyCollection = useCollectionData;

export const useSurveyCollectionOnce = useCollectionDataOnce;

export const useSurveyDocument = useDocumentData;

export const useSurveyDocumentOnce = useDocumentDataOnce;
