import React from 'react';
import { format as formatDate } from 'date-fns';
import { isEmpty, get } from 'lodash';
import { Badge } from 'reactstrap';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import { canUpdateExportRequests, canApproveExportRequests } from '../../shared/abilities';
import ModalButton from '../ModalButton';
import ModelFormModal from '../modals/ModelFormModal';
import ExportApprovalFormModal from '../modals/ExportApprovalFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import { approvalRequestFields, approvalFields } from '../../shared/models/exportRequest';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import useQueryParams from '../hooks/useQueryParams';

const db = firebase.firestore();
const usersRef = db.collection('users');
const exportRequestsRef = db.collection('exportRequests');

export default AdminPage(function AdminExportRequests(props) {
  const { user } = props;
  const params = useQueryParams();
  const { staffs: staffsForFilter, onlyRequested: _onlyRequested = '0' } = params;
  const onlyRequested = _onlyRequested === '1';

  const staffs = useCollectionSubscriptionInTenant(usersRef.where('role', 'in', ['admin', 'staff']));
  const staffOptions = staffs.map((_) => ({ label: _.displayName, value: _.id }));
  // NOTE: とりあえず3000件。ページネーションを別途。
  const { items: rows, isLoading } = useCollectionSubscriptionInTenant(
    exportRequestsRef.orderBy('createdAt', 'desc').limit(3000),
    [],
    { detail: true }
  );

  let filteredRows = rows;
  if (!isEmpty(staffsForFilter)) {
    filteredRows = filteredRows.filter((_) => staffsForFilter.includes(get(_, 'addedBy.uid')));
  }
  if (onlyRequested) {
    filteredRows = filteredRows.filter((_) => _.approvalStatus === 'requested');
  }

  return (
    <div>
      <div className="admin-export-requests container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>エクスポート履歴</h4>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-2">
          <div className="d-flex gap-1 align-items-end">
            <QuerySelector
              paramName="staffs"
              className="ml-0"
              width={400}
              isMulti
              options={staffOptions}
              label="担当で絞込み"
            />
            <QueryBoolean paramName="onlyRequested" label="申請中のみ" defaultValue={'0'} />
          </div>
          <div>
            <AddInTenantButton
              itemRef={exportRequestsRef.doc()}
              processValues={(_) => ({ ..._, approvalStatus: 'requested' })}
              FormModal={ModelFormModal}
              formProps={{ documentName: 'exportRequest', title: 'エクスポート申請', fields: approvalRequestFields() }}
              disabled={!canUpdateExportRequests(user)}
            />
          </div>
        </div>
        <div>
          {isLoading ? (
            <div>
              <span className="fas fa-spin fa-spinner" />
            </div>
          ) : (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th>申請日時</th>
                  <th>申請者</th>
                  <th>承認申請コメント</th>
                  <th>ステータス</th>
                  <th>承認/否認日時</th>
                  <th>承認/否認コメント</th>
                  <th>ダウンロード可能開始日時</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((exportRequest) => {
                  const {
                    id,
                    addedBy,
                    createdAt,
                    approvalRequestComment,
                    approvalStatus,
                    approvedAt,
                    rejectedAt,
                    approvalOrRejectionComment,
                    downloadStartAt,
                  } = exportRequest;

                  return (
                    <tr key={id}>
                      <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                      <td>{get(addedBy, 'displayName')}</td>
                      <td>{approvalRequestComment}</td>
                      <td>
                        {
                          {
                            requested: <span className="badge badge-secondary">申請中</span>,
                            approved: <span className="badge badge-success">承認済</span>,
                            rejected: <span className="badge badge-warning">否認</span>,
                          }[approvalStatus]
                        }
                      </td>
                      <td>
                        {approvedAt && formatDate(approvedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        {rejectedAt && formatDate(rejectedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                      </td>
                      <td>{approvalOrRejectionComment}</td>
                      <td>
                        {downloadStartAt && formatDate(downloadStartAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                      </td>
                      <td className="text-nowrap text-right">
                        {approvalStatus === 'requested' && canApproveExportRequests(user) && (
                          <ModalButton
                            color="primary"
                            Modal={ExportApprovalFormModal}
                            modalProps={{
                              exportRequest,
                              user,
                              fields: approvalFields,
                            }}
                            disabled={!canApproveExportRequests(user)}
                          >
                            承認
                          </ModalButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
});
