import React from 'react';
import { format as formatDate, endOfDay } from 'date-fns';
import { uniq } from 'lodash';
import classnames from 'classnames';

import { i18nField } from '../shared/i18n';
import { prefectures } from '../shared/config';
import LectureTypeDisplay from './LectureTypeDisplay';
import AppCard from './AppCard';
import TenantLink from './TenantLink';

export default function EventCard({
  event,
  tenant,
  lectures = [],
  place,
  isAvailable = true,
  to,
  className,
  linkLabel,
  onClickLink,
  unavailableLabel = '終了しました',
  isTestEntry = false,
  ...extraProps
}) {
  const { id, name, prefecture } = event;
  const _lectureTypes = uniq(lectures.map((_) => _.lectureType));

  return (
    <div
      className={classnames('position-relative', className)}
      key={id}
      style={{ textDecoration: 'none' }}
      {...extraProps}
    >
      <AppCard className="card text-dark p-3" style={{ opacity: isAvailable ? 1 : 0.5 }}>
        <div className="flex-grow-1">
          <div>{name}</div>
          <div className="text-grey">
            <div style={{ wordBreak: 'break-all' }}>
              <div>{place && place.name}</div>
            </div>
          </div>
          <div className="mt-3">
            {_lectureTypes.map((lectureType) => {
              return (
                <div className="py-1">
                  <LectureTypeDisplay event={event} lectureType={lectureType} />
                </div>
              );
            })}
          </div>
          {tenant != null && (
            <div className="mt-1 text-muted" style={{ fontSize: 10 }}>
              {tenant[i18nField(event.lang, 'name')]}
            </div>
          )}
        </div>
        {linkLabel && (
          <TenantLink to={to} className="cursor-pointer position-absolute" style={{ bottom: -15, right: 20 }} onClick={onClickLink}>
            <div className="bg-primary text-white py-2 px-3 rounded-pill d-flex align-items-center">
              {linkLabel}
              {to && <span className="ml-2 fas fa-angle-right large text-right" />}
            </div>
          </TenantLink>
        )}
        <div className="position-absolute" style={{ top: -13, left: 15 }}>
          <span className="bg-secondary px-2 py-1 rounded">{prefectures[prefecture]}</span>
        </div>
      </AppCard>
      {!isAvailable && (
        <div className="position-absolute fit d-flex justify-content-center align-items-center">
          <div className="text-danger border border-danger font-weight-bold px-2 py-1 bg-white">{unavailableLabel}</div>
        </div>
      )}
      {isTestEntry && (
        <div className="position-absolute fit d-flex justify-content-center align-items-center">
          <div className="text-warning border border-warning font-weight-bold px-2 py-1 bg-white">テスト申込</div>
        </div>
      )}
    </div>
  );
}
