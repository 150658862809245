import { useState, useEffect } from 'react';

export default function useCollectionsFetch (refs, dependencies = []) {
  const [items, setItems] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setItems(
          (await Promise.all(refs.map(_ => _.get())))
          .reduce((x, y) => [...x, ...y.docs], [])
          .map(_ => ({ id: _.id, ref: _.ref, ..._.data() }))
        );
      } catch(e) {
        console.log(e, refs);
      }
    })();
  }, dependencies);
  return items;
};
