import React from 'react';
import { set } from 'lodash/fp';
import { sortBy, } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { canUpdateEventProductType } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields, } from '../../shared/models/eventProductType';

const db = firebase.firestore();
const productsRef = db.collection('products');
const eventProductTypesRef = db.collection('eventProductTypes');

export default AdminPage(function AdminEventProductTypes (props) {
  const { user } = props;
  const eventProductTypes = sortBy(useCollectionSubscriptionInTenant(eventProductTypesRef), _ => _.createdAt.toDate());

  return (
    <div>
      <div className="admin-eventProductTypes container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>イベント商品種別一覧</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={eventProductTypesRef.doc()} FormModal={ModelFormModal} formProps={{ title: 'イベント商品種別追加', fields, }} disabled={!canUpdateEventProductType(user)} />
        </div>
        <div>
          {
            eventProductTypes.length > 0 ? (
              <table className="table">
                <thead className="thead-light text-center">
                  <tr>
                    <th>名称</th>
                    <th>英語</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    eventProductTypes.map((eventProductType) => {
                      const { id, name, enName, ref, } = eventProductType;
                      const beforeDelete = async () => {
                        if(
                          (await db.collectionGroup('lectures').where('productType', '==', id).limit(1).get()).docs.length > 0
                        ) {
                          toast.error('使用されているため削除できません');
                          return false;
                        }
                      };

                      return (
                        <tr key={id}>
                          <td>
                            {name}
                          </td>
                          <td>
                            {enName}
                          </td>
                          <td className="text-nowrap text-right">
                            <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'イベント商品種別編集', fields }} disabled={!canUpdateEventProductType(user)} />
                            <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateEventProductType(user)} beforeDelete={beforeDelete} />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                イベント商品種別は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});

