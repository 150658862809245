import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Label, } from 'reactstrap';
import { omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';

import { computeDiscountAmount, isShipmentFree, } from '../../shared/models/coupon';
import { fieldDisplayValue, } from '../../shared/util';
import { fields, } from '../../shared/models/inquiry';
import AppButton from '../AppButton';
import SettingText from '../SettingText';
import AppCardElement from '../AppCardElement';
import env from '../../env';

const { entries, } = Object;

export default function NewInquiryConfirmForm (props) {
  const { values, onClickBack, user, inquiryTypes, inquiryTypeChildren, } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isSubmitting) return;

    toggleSubmitting(true);
    try {
      await props.onSubmit({
        ...values,
      });
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleSubmitting(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container mt-5">
        <div>
          <div>
            <div className="card border-danger text-danger p-3 text-center font-weight-bold">
              お問合せはまだ完了していません
            </div>
            <h4 className="mt-4 h5 text-center font-weight-bold">
              お問合せ内容をご確認ください
            </h4>
          </div>
          <div className="mt-5">
            <h4>お問合せ内容</h4>
            <table className="table table-bordered">
              <tbody>
                {
                  entries(fields({ inquiryTypes, inquiryTypeChildren, })).map(([fieldName, fieldSettings]) => {
                    const { label, } = fieldSettings;
                    const value = fieldDisplayValue(values[fieldName], { ...fieldSettings, values });
                    return (
                      <tr>
                        <th>{label}</th>
                        <td>{value}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex mt-5">
          <AppButton size="lg" className="cancel flex-fill" color="secondary" onClick={onClickBack} disabled={isSubmitting}>
            <span className="fas fa-arrow-left mr-1" />
            戻る
          </AppButton>
          <AppButton size="lg" color="primary" className="save flex-fill ml-2" type="submit" color="primary" onClick={onSubmit} disabled={isSubmitting}>
            <span className={classnames('fas mr-1', { 'fa-arrow-right': !isSubmitting, 'fa-spin fa-spinner': isSubmitting, })} />
            お問合せを送信する
          </AppButton>
        </div>
      </section>
    </Form>
  );
};
