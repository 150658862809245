import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

import firebase from '../../firebase';
import env from '../../env';
import Page from './Page';
import useFirebaseUser from '../hooks/useFirebaseUser';
import useDocumentSubscription from '../hooks/useDocumentSubscription';

const db = firebase.firestore();

export default function SuperAdminPageHOC(WrappedComponent) {
  return Page(function SuperAdminPage(props) {
    const { firebaseUser } = useFirebaseUser();
    const user = useDocumentSubscription(firebaseUser != null && db.collection('users').doc(firebaseUser.uid), [
      firebaseUser,
    ]);

    return (
      !!user?.isSuperAdmin && (
        <div className="admin-page d-flex flex-column pb-5" style={{ backgroundColor: '#f3f3f3', minHeight: '100vh' }}>
          <WrappedComponent {...{ ...props, user }} />
        </div>
      )
    );
  });
}
