import React, { useEffect, useMemo, } from 'react';
import qs from 'qs';
import { sortBy, orderBy, groupBy, uniq, maxBy, omit, isEmpty, sum, sumBy, get, keyBy, omitBy, pick, isUndefined, max } from 'lodash';
import { format as formatDate, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval, addDays, addMonths, startOfDay, endOfDay, startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { Button, Badge, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ellipsis from 'text-ellipsis';
import ReactPaginate from 'react-paginate';

import { fullPathWithParams } from '../../util';
import { prefectures, routes, colors, } from '../../shared/config';
import { canUpdateOrder } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { ordererFields, cancelFields, } from '../../shared/models/rentalOrder';
import { areaFromPostalCode } from '../../shared/models/setting';
import { fields as userFields, adminFields as adminUserFields } from '../../shared/models/user';
import { fieldDisplayValue } from '../../shared/util';
import AgentPage from '../hocs/AgentPage';
import useFirebaseUser from '../hooks/useFirebaseUser';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import TenantLink from '../TenantLink';
import AgentHeader from '../AgentHeader';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import EditButton from '../EditButton';
import QueryDateSelector from '../QueryDateSelector';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import QueryText from '../QueryText';
import QueryInput from '../QueryInput';
import OrderExportButton from '../OrderExportButton';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import useTenant from '../hooks/useTenant';

const db = firebase.firestore();
const cancelRentalOrder = functions.httpsCallable('cancelRentalOrder');
const { entries } = Object;

export default AgentPage(function AgentRentalOrders (props) {
  const { tenant, user, agent, setBreadNavValues, canReadAllShops, memberData, } = props;
  const queryParams = useQueryParams();
  const {
    agentShopIds,
    dateRange,
  } = queryParams;
  const startOn = useMemo(_ => queryParams.dateRange?.[0] ? new Date(queryParams.dateRange[0]) : addDays(addMonths(new Date(), -1), 1), [queryParams.dateRange]);
  const endOn = useMemo(_ => queryParams.dateRange?.[1] ? new Date(queryParams.dateRange[1]) : new Date(), [queryParams.dateRange]);
  const agentShops = useCollectionSubscription(agent.ref.collection('agentShops').orderBy('createdAt'), [agent]);
  const agentShopsById = keyBy(agentShops, 'id');
  const agentShopOptions = agentShops.map(_ => ({ label: _.name, value: _.id, }));
  const rentalItems = sortBy(useCollectionSubscription(agent.ref.collection('rentalItems').where('isHidden', '==', false), [agent]), 'name');
  const rentalItemsById = keyBy(rentalItems, 'id');
  const rentalItemGroups = sortBy(useCollectionSubscription(agent.ref.collection('rentalItemGroups'), [agent]), _ => _.createdAt.toDate());
  const rentalItemGroupsById = keyBy(rentalItemGroups, 'id');
  const rentalPrices = sortBy(useCollectionSubscription(agent.ref.collection('rentalPrices').where('isHidden', '==', false), [agent]), _ => _.createdAt.toDate());
  const rentalPricesById = keyBy(rentalPrices, 'id');

  useEffect(() => {
    setBreadNavValues({ agent, });
  }, [agent]);

  return (
    <div>
      <div className="agent-shop-rental-orders container-fluid py-5 position-relative">
        <AgentHeader activeTab="rentalOrders" user={user} agent={agent} />
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className='position-relative mb-2 d-flex align-items-end justify-content-start gap-2' style={{ zIndex: 11 }}>
            <QueryDateRangeSelector label="期間" defaultValue={[startOn, endOn]} paramName="dateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select' }} />
            <QuerySelector paramName="agentShopIds" width={250} isMulti options={agentShopOptions} label="店舗で絞込み" />
          </div>
          <div className='overflow-auto mt-2'>
            <RentalOrdersTable
              {...{
                agent,
                agentShopsById,
                rentalItemsById,
                rentalItemGroupsById,
                rentalPricesById,
                startOn,
                endOn,
                agentShopIds,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

function RentalOrdersTable (props) {
  const { 
    agent,
    agentShopsById,
    rentalItemsById,
    rentalItemGroupsById,
    rentalPricesById,
    startOn,
    endOn,
    agentShopIds,
  } = props;
  const rentalOrders = orderBy(useCollectionSubscription(agent.ref.collection('rentalOrders').where('createdAt', '>=', startOfDay(startOn)).where('createdAt', '<=', endOfDay(endOn)), [agent, startOn, endOn]), 'createdAt', 'desc');

  let filteredRentalOrders = rentalOrders;
  if(!isEmpty(agentShopIds)) {
    filteredRentalOrders = filteredRentalOrders.filter(_ => agentShopIds.includes(_.agentShopId));
  }

  const rows = filteredRentalOrders.map((rentalOrder) => {
    const rentalItem = rentalItemsById[rentalOrder.rentalItemId];
    const rentalPrice = rentalPricesById[rentalOrder.rentalPriceId];

    return {
      ...rentalOrder,
      rentalItem,
      rentalPrice,
    };
  });
  const rowsForExport = () => {
    return rows.map((row) => {
      const { id, status, amount, returnAmount, createdAt, startedAt, endedAt, willEndAt, cancelledAt, cancellationNote, } = row;
      return {
        id,
        status,
        rentalItemId: row.rentalItemId,
        rentalItemName: row.rentalItem?.name,
        rentalPriceId: row.rentalPriceId,
        rentalPriceName: row.rentalPrice?.name,
        amount,
        returnAmount,
        createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        startedAt: startedAt && formatDate(startedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        willEndAt: willEndAt && formatDate(willEndAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        endedAt: endedAt && formatDate(endedAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        cancelledAt: cancelledAt && formatDate(cancelledAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
        cancellationNote,
        ...(
          entries(ordererFields()).reduce((x, [fieldName, fieldSettings]) => {
            return {
              ...x,
              [fieldName]: fieldDisplayValue(row[fieldName], fieldSettings),
            };
          }, {})
        ),
      };
    });
  };

  return (
    <div>
      <div className='position-relative mb-2 d-flex align-items-end justify-content-start gap-2' style={{ zIndex: 11 }}>
        <ExportButton
          fileName="レンタル注文.csv"
          rows={rowsForExport}
        />
      </div>
      <table className="table">
        <thead className="thead-light text-center text-nowrap">
          <tr>
            <th>レンタル注文ID</th>
            <th>ステータス</th>
            <th style={{ minWidth: 300 }}>商品</th>
            <th>レンタル金額(税込)</th>
            <th>延滞料金(税込)</th>
            <th>注文日時</th>
            <th>レンタル開始</th>
            <th>レンタル終了予定</th>
            <th>レンタル終了</th>
            <th>キャンセル日時</th>
            <th>キャンセル理由</th>
            {entries(ordererFields()).map(([fieldName, { label }]) => {
              return <th key={fieldName}>{label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {
            rows.map((rentalOrder) => {
              const { id, ref, status, amount, returnAmount, createdAt, startedAt, endedAt, willEndAt, cancelledAt, cancellationNote, } = rentalOrder;
              const onSubmitCancel = async (values, { toggleModal }) => {
                try {
                  await cancelRentalOrder({ agentId: agent.id, rentalOrderId: id, values, });
                  toast.success('キャンセルしました');
                  toggleModal(false);
                } catch(e) {
                  console.error(e);
                  toast.error('失敗しました');
                }
              };

              return (
                <tr key={id}>
                  <td>
                    {id}
                    {
                      startedAt == null && (
                        <DeleteButton size="sm" itemRef={ref} />
                      )
                    }
                    {
                      startedAt != null && (
                        <EditButton icon={false} label="キャンセル" size="sm" color="danger" itemRef={ref} onSubmit={onSubmitCancel} FormModal={ModelFormModal} formProps={{ title: 'キャンセル', fields: cancelFields }} disabled={cancelledAt != null } />
                      )
                    }
                  </td>
                  <td>
                    {status}
                  </td>
                  <td>
                    <div>
                      <div>
                        {rentalOrder.rentalItem?.name}
                      </div>
                      <div>
                        {rentalOrder.rentalPrice?.name}
                      </div>
                    </div>
                  </td>
                  <td className="text-right">
                    {numeral(amount).format()}
                  </td>
                  <td className="text-right">
                    {returnAmount != null && numeral(returnAmount).format()}
                  </td>
                  <td>
                    {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                  </td>
                  <td>
                    {startedAt != null && formatDate(startedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                  </td>
                  <td>
                    {willEndAt != null && formatDate(willEndAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                  </td>
                  <td>
                    {endedAt != null && formatDate(endedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                  </td>
                  <td>
                    {cancelledAt != null && formatDate(cancelledAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                  </td>
                  <td>
                    {cancellationNote}
                  </td>
                  {entries(ordererFields()).map(([fieldName, fieldSettings]) => {
                    return (
                      <td>
                        {fieldDisplayValue(rentalOrder[fieldName], fieldSettings)}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
