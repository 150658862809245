import React from 'react';
import { format as formatDate } from 'date-fns';
import { isFunction, omit, upperFirst, orderBy, keyBy, pick } from 'lodash';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { useLocation, useHistory, } from 'react-router';
import qs from 'qs';

import firebase from '../firebase';
import { autoLink, nl2br, } from '../util';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useQueryParams from './hooks/useQueryParams';
import ModalButton from './ModalButton';
import AppButton from './AppButton';

const { entries } = Object;
const db = firebase.firestore();

function MypageMessagesButton (props) {
  const { type, subject, reinquiryPath, ...extraProps } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const subjectIdName = `messages${upperFirst(type)}Id`;
  const { [subjectIdName]: subjectId } = queryParams;
  const isInitiallyOpen = subjectId === subject.id;

  return (
    <ModalButton
      title="メッセージ確認"
      isInitiallyOpen={isInitiallyOpen}
      content={({ toggleModal }) => {
        return (
          <div className="d-flex flex-column gap-5">
            {
              orderBy(subject.messages, _ => _.sentAt.toDate(), 'desc').map((message, i) => {
                const { body, attachedFiles = [], sentAt, } = message;
                const reinquiryUrl = `/${subject.tenantId}/${isFunction(reinquiryPath) ? reinquiryPath(message) : reinquiryPath}?${qs.stringify({
                  [`source${upperFirst(type)}Id`]: subject.id,
                  ...(subject.sourceOrder && { orderId: subject.sourceOrder.id }),
                })}`;
                return (
                  <div key={i} className="border rounded p-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: nl2br(autoLink(body, { linkAttr: { target: '_blank' } })) }}
                    />
                    {
                      attachedFiles.length > 0 && (
                        <div className="mt-3 d-flex flex-wrap gap-1">
                          {
                            attachedFiles.map((attachedFile) => {
                              const { url } = attachedFile;
                              return (
                                <a href={url} target="_blank">
                                  <img src={url} style={{ maxWidth: 100, maxHeight: 100, }} />
                                </a>
                              );
                            })
                          }
                        </div>
                      )
                    }
                    <div className="mt-1 small text-muted text-right">
                      {formatDate(sentAt.toDate(), 'yyyy/MM/dd HH:mm')}
                    </div>
                    <div className="mt-3">
                      <AppButton block color="primary" tag={Link} to={reinquiryUrl}>
                        追加のお問合せ
                      </AppButton>
                    </div>
                  </div>
                );
              })
            }
          </div>
        );
      }}
      {...extraProps}
    >
      メッセージ確認
    </ModalButton>
  );
};

export default MypageMessagesButton;
