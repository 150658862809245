const { isEmpty } = require('lodash');

module.exports = {
  fields: () => {
    return {
      memo: {
        label: 'メモ',
        type: 'text',
        validations: { required: (v) => !isEmpty(v) },
        initialValue: '',
      },
    };
  },
};
