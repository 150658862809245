import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { ActivityDocumentData, Activity } from '../types';
import type { PartiallyPartial } from 'types';

export const activityConverter = getConverter<ActivityDocumentData>();

export const activitiesRef = () => collection(getFirestore(), 'activities').withConverter(activityConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Activity> : null;
export const activityRef = <Id extends string | undefined>(activityId: Id) =>
  (activityId ? doc(activitiesRef(), activityId) : null) as RefOrNull<Id>;

export const newActivityRef = () => doc(activitiesRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const byPayloadQueryConstraints = (key: string, value: unknown) => [where(`payload.${key}`, '==', value)];

export const activitiesQuery = (...queryConstraints: QueryConstraint[]) =>
  query(activitiesRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantActivitiesByPayloadQuery = (tenantId: string | undefined, key: string, value: unknown) =>
  tenantId ? activitiesQuery(...tenantQueryConstraints(tenantId), ...byPayloadQueryConstraints(key, value)) : null;

export const addActivity = async (data: PartiallyPartial<Activity, 'id' | 'createdAt'>) =>
  addDoc(activitiesRef(), { createdAt: serverTimestamp(), ...data });

export const updateActivity = async (activityId: string, data: UpdateData<Activity>) =>
  updateDoc(activityRef(activityId), data);

export const deleteActivity = async (activityId: string) => deleteDoc(activityRef(activityId));

export const useActivityCollection = useCollectionData;

export const useActivityCollectionOnce = useCollectionDataOnce;

export const useActivityDocument = useDocumentData;

export const useActivityDocumentOnce = useDocumentDataOnce;
