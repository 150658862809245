import { DocumentReference, serverTimestamp, updateDoc } from '../firebase';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { omit } from 'lodash';

import ModelFormModal from './modals/ModelFormModal';
import { auditData } from '../shared/models/user';
import { TroubleInquiry } from '../types/troubleInquiry';
import { User } from '../types/user';
import { discountRequestFields } from '../shared/models/troubleInquiry';

export const InquiryDiscountRequestButton = ({
  inquiryRef,
  inquiry,
  user,
  disabled,
  onFinish,
  ...extraProps
}: {
  inquiryRef: DocumentReference<TroubleInquiry>;
  inquiry: TroubleInquiry;
  user: User;
  disabled?: boolean;
  onFinish?: (inquiry: TroubleInquiry) => Promise<void>;
}) => {
  const { discount, discountRequest } = inquiry;
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    const { amount, note } = values;
    if (!window.confirm('本当に割引申請しますか？')) return;

    try {
      const updatedData = {
        discountRequest: {
          ...discountRequest,
          amount,
          note,
          status: 'requested',
          requestedBy: auditData(user),
          requestedAt: serverTimestamp(),
        },
        updatedBy: auditData(user),
      };
      await updateDoc(inquiry.ref, updatedData);
      onFinish && (await onFinish({ ...inquiry, ...updatedData }));
      toast.success('申請しました');
      toggleModal(false);
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const fields =
    discountRequest?.status === 'rejected'
      ? discountRequestFields()
      : omit(discountRequestFields(), ['approvalOrRejectionComment']);

  return (
    <Button
      color="primary"
      outline
      onClick={(_) => toggleModal(true)}
      disabled={disabled || !!discount}
      {...extraProps}
    >
      {discount ? '割引済' : '割引申請'}
      <ModelFormModal
        isOpen={showsModal}
        onClickClose={(_) => toggleModal(false)}
        onSubmit={onSubmit}
        title="割引申請"
        values={discountRequest}
        fields={fields}
        submitLabel="申請する"
      />
    </Button>
  );
};
