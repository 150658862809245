import {
  getFirestore,
  getConverter,
  collection,
  collectionGroup,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import { useCollectionsDataOnce } from '../components/hooks/v9/useCollectionsDataOnce';
import type { ReferrerDocumentData, Referrer } from '../types';
import type { PartiallyPartial } from 'types';

export const referrerConverter = getConverter<ReferrerDocumentData>();

export const referrersGroupRef = () => collectionGroup(getFirestore(), 'referrers').withConverter(referrerConverter);

export const referrersRef = (parent: DocumentReference) =>
  collection(parent, 'referrers').withConverter(referrerConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Referrer> : null;
export const referrerRef = <Id extends string | undefined>(parent: DocumentReference, referrerId: Id) =>
  (referrerId ? doc(referrersRef(parent), referrerId) : null) as RefOrNull<Id>;

export const newReferrerRef = (parent: DocumentReference) => doc(referrersRef(parent));

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const byKeyQueryConstraints = (key: string) => [where('key', '==', key)];

export const referrersGroupQuery = (...queryConstraints: QueryConstraint[]) =>
  query(referrersGroupRef(), ...queryConstraints);

export const tenantReferrersByKeyGroupQuery = (tenantId: string | undefined, key: string | undefined | null) =>
  tenantId && key ? referrersGroupQuery(...tenantQueryConstraints(tenantId), ...byKeyQueryConstraints(key)) : null;

export const referrersQuery = (parent: DocumentReference, ...queryConstraints: QueryConstraint[]) =>
  query(referrersRef(parent), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const addReferrer = async (parent: DocumentReference, data: PartiallyPartial<Referrer, 'id' | 'createdAt'>) =>
  addDoc(referrersRef(parent), { createdAt: serverTimestamp(), ...data });

export const updateReferrer = async (parent: DocumentReference, referrerId: string, data: UpdateData<Referrer>) =>
  updateDoc(referrerRef(parent, referrerId), data);

export const deleteReferrer = async (parent: DocumentReference, referrerId: string) =>
  deleteDoc(referrerRef(parent, referrerId));

export const useReferrerCollection = useCollectionData;

export const useReferrerCollectionOnce = useCollectionDataOnce;

export const useReferrerCollectionsOnce = useCollectionsDataOnce;

export const useReferrerDocument = useDocumentData;

export const useReferrerDocumentOnce = useDocumentDataOnce;
