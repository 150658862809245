import React, { Fragment } from 'react';
import { isEmpty, orderBy, sortBy, pick, omit, get, keyBy } from 'lodash';
import { format as formatDate } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import dedent from 'dedent';

import { canUpdateTroubleInquiry, canApproveTroubleInquiry } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import {
  statuses,
  fields as _fields,
  basicFields,
  itemFields,
  approvalRequestFields,
  approvalFields,
  shipmentFields,
  commentFields,
  activityTypes,
  shipmentMailBody,
  replyEmailBody,
  reimbursementResultFields,
  discountFields,
  supportContentsFields,
  emailFields,
} from '../../shared/models/troubleInquiry';
import { paymentMethod, destinationFields } from '../../shared/models/order';
import { createActivity } from '../../util';
import { fieldDisplayValue } from '../../shared/util';
import { supportMeans } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCreateActivity from '../hooks/useCreateActivity';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import InquiryItemsFormModal from '../modals/InquiryItemsFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import { InquiryShipmentButton } from '../InquiryShipmentButton';
import { InquiryReimbursementButton } from '../InquiryReimbursementButton';
import { InquiryDiscountRequestButton } from '../InquiryDiscountRequestButton';
import { InquiryDiscountButton } from '../InquiryDiscountButton';
import { InquiryUpdatePicButton } from '../InquiryUpdatePicButton';
import { InquiryApprovalRequestButton } from '../InquiryApprovalRequestButton';
import { InquiryApprovalButton } from '../InquiryApprovalButton';
import InquirySubAccountAlert from '../InquirySubAccountAlert';
import InquiryReinquiryAlert from '../InquiryReinquiryAlert';
import ModalButton from '../ModalButton';
import Activity from '../Activity';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const troubleInquiriesRef = db.collection('troubleInquiries');
const productTypesRef = db.collection('productTypes');
const productsRef = db.collection('products');
const activitiesRef = db.collection('activities');
const ordersRef = db.collection('orders');
const surveysRef = db.collection('surveys');
const usersRef = db.collection('users');
const questionsRef = db.collection('questions');
const storageRef = firebase.storage().ref();
const sendTroubleInquiryEmail = functions.httpsCallable('sendTroubleInquiryEmail');
const troubleInquiryShipment = functions.httpsCallable('troubleInquiryShipment');
const { entries, keys } = Object;

export default AdminPage(function AdminTroubleInquiry(props) {
  const {
    user,
    match: {
      params: { troubleInquiryId },
    },
  } = props;
  const createActivity = useCreateActivity();
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('createdAt'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts.map((product) => ({
    ...product,
    ...(product.isHidden && { label: `[非表示] ${product.name}` }),
  }));
  const productsById = keyBy(products, 'id');
  const fields = { ...omit(_fields({ productTypes, products }), ['destinationType']), ...shipmentFields() };
  const troubleInquiry = useDocumentSubscription(troubleInquiriesRef.doc(troubleInquiryId), [troubleInquiryId]);
  const childTroubleInquiries = useCollectionSubscriptionInTenant(troubleInquiriesRef.where('sourceTroubleInquiryId', '==', troubleInquiryId), [troubleInquiryId]);
  const agent = useDocumentSubscription(
    troubleInquiry?.wholesaleAgentId && db.collection('agents').doc(troubleInquiry.wholesaleAgentId),
    [troubleInquiry]
  );
  const agentShop = useDocumentSubscription(
    agent?.ref.collection('agentShops').doc(troubleInquiry?.wholesaleAgentShopId),
    [agent]
  );
  const commentsRef = troubleInquiriesRef.doc(troubleInquiryId).collection('troubleInquiryComments');
  const comments = useCollectionSubscriptionInTenant(commentsRef.orderBy('createdAt', 'desc'), [troubleInquiryId]);
  const activities = useCollectionSubscriptionInTenant(
    activitiesRef.where('payload.troubleInquiryId', '==', troubleInquiryId),
    [troubleInquiryId]
  );
  const sourceOrderId = troubleInquiry?.sourceOrder?.id;
  const sourceOrder = useDocumentSubscription(sourceOrderId && ordersRef.doc(sourceOrderId), [sourceOrderId]);
  const shipmentOrderId = troubleInquiry?.orderId;
  const shipmentOrder = useDocumentSubscription(shipmentOrderId && ordersRef.doc(shipmentOrderId), [shipmentOrderId]);
  const discountable = sourceOrder && paymentMethod(sourceOrder) === 'credit' && sourceOrder.charge;
  const { label: statusLabel, color } = (troubleInquiry && statuses[troubleInquiry.status]) || {};
  const shippable =
    troubleInquiry &&
    !troubleInquiry.shipmentRequested &&
    (!troubleInquiry.approvalRequired || troubleInquiry.approvedAt) &&
    ['name', 'nameKana', 'phone', 'postalCode', 'prefecture', 'city', 'address', 'email'].every(_ => troubleInquiry[_]) &&
    troubleInquiry.items.every((_) => _.quantity <= get(productsById, [_.productId, 'normalInventory'], 0));
  const thWidth = 220;
  const surveys = useCollectionSubscriptionInTenant(surveysRef);
  const surveysById = keyBy(surveys, 'id');
  const isReinquiry = troubleInquiry?.sourceTroubleInquiryId != null;
  const troubleInquirySurvey = surveysById[productsById[troubleInquiry?.productId]?.[isReinquiry ? 'troubleReinquirySurveyId' : 'troubleInquirySurveyId']];
  const relatedQuestions = useDocumentsFetch((troubleInquirySurvey?.questionRows?.map(_ => _.questionId) || []).map(_ => questionsRef.doc(_)), [troubleInquirySurvey]);
  const onClickStart = async () => {
    if (!window.confirm('本当に対応開始しますか？')) return;

    await troubleInquiry.ref.update({ status: 'supporting', respondedBy: user });
    await createActivity('startSupportOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onClickUnfinish = async () => {
    if (!window.confirm('本当に対応中に戻しますか？')) return;

    const status =
      troubleInquiry.hasReimbursement && !troubleInquiry.reimbursementRequest?.requestedAt ? 'awaiting' : 'supporting';
    await troubleInquiry.ref.update({ status });
    await createActivity('unfinishSupportOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const validateOnDone = async () => {
    if (!window.confirm('本当に対応完了しますか？')) return false;
    return true;
  };
  const onDoneFinished = async (values) => {
    const note = `${values.supportSummary} (${values.supportMeans.map((_) => supportMeans[_]).join(',')})`;
    await troubleInquiry.ref.update({ status: 'supported' });
    await createActivity('finishSupportOfTroubleInquiry', user, { troubleInquiryId, note, uid: troubleInquiry.createdBy.uid });
  };
  const validateeOnEdit = async ({ items }) => {
    const addedItems = items.filter(({ productId, quantity }) =>
      (troubleInquiry.items || []).every((_) => _.productId !== productId || quantity > _.quantity)
    );
    const approvalRequired = items.some(({ productId }) => productsById[productId].approvalRequired);
    const addedApprovalRequired = addedItems.some(({ productId }) => productsById[productId].approvalRequired);
    addedApprovalRequired && window.alert('要承認の商品が追加されました。承認申請ボタンにより承認申請をしてください');

    // NOTE: 要承認の商品が追加されたら承認ステータスを初期値に戻す
    await troubleInquiry.ref.update({
      approvalRequired,
      ...(addedApprovalRequired ? { approvalStatus: 'initial', approvedAt: null } : {}),
    });
    return true;
  };
  const onEdited = async ({ items }) => {
    await createActivity('editTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onShipmentFinished = async () => {
    await createActivity('createOrderOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onReimbursementStarted = async () => {
    await createActivity('startReimbursementOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onReimbursementFinished = async () => {
    await createActivity('finishReimbursementOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onReimbursementCancelled = async () => {
    await createActivity('cancelReimbursementOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onDiscountRequestFinished = async ({ discountRequest: { note } }) => {
    await createActivity('requestDiscountSourceOrderOfTroubleInquiry', user, { troubleInquiryId, note, uid: troubleInquiry.createdBy.uid });
  };
  const onDiscountApproved = async ({ discountRequest: { approvalOrRejectionComment } }) => {
    await createActivity('discountSourceOrderOfTroubleInquiry', user, {
      troubleInquiryId,
      note: approvalOrRejectionComment,
      uid: troubleInquiry.createdBy.uid
    });
  };
  const onDiscountRejected = async ({ discountRequest: { approvalOrRejectionComment } }) => {
    await createActivity('rejectDiscountSourceOrderOfTroubleInquiry', user, {
      troubleInquiryId,
      note: approvalOrRejectionComment,
      uid: troubleInquiry.createdBy.uid
    });
  };
  const onDiscountFailed = async () => {
    await createActivity('failedDiscountSourceOrderOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onSubmitUpdatePic = async () => {
    await createActivity('updatePicOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onRequestApproval = async ({ approvalRequestComment }) => {
    await createActivity('requestedApprovalOfTroubleInquiry', user, { troubleInquiryId, note: approvalRequestComment, uid: troubleInquiry.createdBy.uid });
  };
  const onApprove = async ({ approvalOrRejectionComment }) => {
    await createActivity('approvedTroubleInquiry', user, { troubleInquiryId, note: approvalOrRejectionComment, uid: troubleInquiry.createdBy.uid });
  };
  const onReject = async ({ approvalOrRejectionComment }) => {
    await createActivity('rejectedTroubleInquiry', user, { troubleInquiryId, note: approvalOrRejectionComment, uid: troubleInquiry.createdBy.uid });
  };
  const onEditedDestination = async () => {
    await createActivity('editDestinationOfTroubleInquiry', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid });
  };
  const onSubmitEmail = async (values, { onClickClose, }) => {
    try {
      const attachedFiles = await Promise.all(
        Array.from(values.files || []).map(async (file) => {
          const storagePath = `troubleInquiries/${troubleInquiryId}/attachedFiles/${new Date().toISOString()}/${file.name}`;
          const fileRef = storageRef.child(storagePath);
          await fileRef.put(file, { contentType: file.type });
          return {
            ...pick(file, ['name', 'type']),
            storagePath,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      await sendTroubleInquiryEmail({ troubleInquiryId: troubleInquiry.id, values, });
      await troubleInquiry.ref.update({
        messages: firebase.firestore.FieldValue.arrayUnion({ ...omit(values, 'files'), attachedFiles, sentAt: new Date(), sentBy: pick(user, ['email', 'uid', 'displayName']), }),
      });
      const body = replyEmailBody(troubleInquiry, values.body, products, productTypes, relatedQuestions, window.location.origin);
      await createActivity('sendTroubleInquiryEmail', user, { troubleInquiryId, uid: troubleInquiry.createdBy.uid, body, messageBody: values.body, attachedFiles });
      toast.success('送信しました');
      onClickClose();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  const onRetryRefund = async () => {
    try {
      if (!window.confirm('再度、割引申請を行いますか？')) return;
      await troubleInquiry.ref.update({
        discount: null,
        discountRequest: { ...troubleInquiry.discountRequest, status: 'requested', approvedBy: null, approvedAt: null },
      });
      toast.success('割引申請を再度実行できます。');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  }
  const mailConfirmBody = ({ values }) => {
    const body = replyEmailBody(troubleInquiry, values.body, products, productTypes, relatedQuestions, window.location.origin);
    return (
      <div>
        <div>以下のようなメールを送ります。よろしいですか？</div>
        <div className="card p-3 mt-3" style={{ whiteSpace: 'pre-line' }}>
          {body}
        </div>
        <div className="mt-4">
          <h5>メッセージ内容</h5>
          <div className="card p-3" style={{ whiteSpace: 'pre-line', }}>
            {values.body}
          </div>
        </div>
      </div>
    );
  };

  return (
    troubleInquiry != null && (
      <div className='admin-trouble-inquiry container-fluid'>
        <div className='p-4 bg-white my-4'>
          <div className='d-flex justify-content-center mb-3'>
            <h4>不具合組立問合せ詳細</h4>
          </div>
          <InquirySubAccountAlert inquiry={troubleInquiry} />
          <InquiryReinquiryAlert inquiry={troubleInquiry} />
          {troubleInquiry?.discount && sourceOrder && !sourceOrder?.refund && (
            <div className="alert alert-danger d-flex justify-content-between align-items-center">
              <div>返金処理が正常に完了していません。Stripeの確認をして下さい。</div>
              <Button className="btn btn-danger my-0" onClick={onRetryRefund}>再実行</Button>
            </div>
          )}
          <div className='d-flex flex-wrap text-nowrap justify-content-end mb-3 gap-2'>
            {troubleInquiry.status !== 'supported' && (
              <InquiryUpdatePicButton inquiry={troubleInquiry} onSubmit={onSubmitUpdatePic} />
            )}
            <ModalButton Modal={ModelFormModal} modalProps={{ title: 'メール送信', fields: emailFields(), submitLabel: '送信する', onSubmit: onSubmitEmail, confirms: true, confirmBody: mailConfirmBody, }} >
              <span className="fas fa-paper-plane mr-1" /> メール送信
            </ModalButton>
            {['supporting', 'awaiting'].includes(troubleInquiry.status) && (
              <Fragment>
                {troubleInquiry.approvalRequired && (
                  <>
                    {troubleInquiry.approvalStatus === 'requested' ? (
                      <InquiryApprovalButton
                        inquiryRef={troubleInquiry.ref}
                        inquiry={troubleInquiry}
                        user={user}
                        fields={approvalFields}
                        onApprove={onApprove}
                        onReject={onReject}
                        disabled={!canApproveTroubleInquiry(user)}
                      />
                    ) : (
                      <InquiryApprovalRequestButton
                        inquiryRef={troubleInquiry.ref}
                        inquiry={troubleInquiry}
                        fields={approvalRequestFields}
                        onRequest={onRequestApproval}
                        disabled={!canUpdateTroubleInquiry(user)}
                      />
                    )}
                  </>
                )}
                {discountable && (
                  <>
                    {troubleInquiry?.discountRequest?.status === 'requested' ? (
                      <InquiryDiscountButton
                        inquiry={troubleInquiry}
                        user={user}
                        disabled={!canUpdateTroubleInquiry(user)}
                        onSubmit={onDiscountApproved}
                        onCancel={onDiscountRejected}
                        onSubmitError={onDiscountFailed}
                      />
                    ) : (
                      <InquiryDiscountRequestButton
                        inquiry={troubleInquiry}
                        user={user}
                        disabled={!canUpdateTroubleInquiry(user)}
                        onFinish={onDiscountRequestFinished}
                      />
                    )}
                  </>
                )}
                <InquiryReimbursementButton
                  inquiry={troubleInquiry}
                  user={user}
                  disabled={!canUpdateTroubleInquiry(user)}
                  onStart={onReimbursementStarted}
                  onFinish={onReimbursementFinished}
                  onCancel={onReimbursementCancelled}
                />
                <EditButton
                  label='発送先変更'
                  icon={null}
                  itemRef={troubleInquiry.ref}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '発送先を変更する',
                    fields: destinationFields,
                    submitLabel: '変更する',
                  }}
                  disabled={!canUpdateTroubleInquiry(user)}
                  onFinish={onEditedDestination}
                />
                <EditButton
                  itemRef={troubleInquiry.ref}
                  FormModal={InquiryItemsFormModal}
                  disabled={!canUpdateTroubleInquiry(user)}
                  formProps={{ products: selectableProducts, itemFields }}
                  onFinish={onEdited}
                  validateOnSubmit={validateeOnEdit}
                />
                <InquiryShipmentButton
                  inquiry={troubleInquiry}
                  user={user}
                  disabled={!shippable}
                  products={products}
                  productTypes={productTypes}
                  shipmentFields={shipmentFields}
                  shipmentMailBody={shipmentMailBody}
                  onFinish={onShipmentFinished}
                  inquiryShipment={troubleInquiryShipment}
                  shipmentOrder={shipmentOrder}
                />
                <EditButton
                  label='対応完了'
                  icon={null}
                  color='primary'
                  itemRef={troubleInquiry.ref}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: 'サポート内容を登録する',
                    fields: supportContentsFields({ surveys }),
                    submitLabel: '登録する',
                  }}
                  validateOnSubmit={validateOnDone}
                  onFinish={onDoneFinished}
                />
              </Fragment>
            )}
            {troubleInquiry.status === 'initial' && (
              <Button color='primary' onClick={onClickStart}>
                対応開始
              </Button>
            )}
            {troubleInquiry.status === 'supported' && (
              <Button color='primary' outline onClick={onClickUnfinish}>
                対応中に戻す
              </Button>
            )}
          </div>
          <div className="d-sm-flex justify-content-around mt-5 gap-4">
            <div className="flex-fill">
              <table className='table table-bordered'>
                <tbody className='thead-light'>
                  <tr>
                    <th style={{ width: thWidth }}>ID</th>
                    <td>{troubleInquiry.id}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>状態</th>
                    <td>
                      <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>パーツ</th>
                    <td>
                      {troubleInquiry.items.map((item, i) => {
                        const { productId, quantity } = item;
                        const product = productsById[productId];
                        return (
                          product != null && (
                            <div key={i}>
                              {troubleInquiry.status !== 'supported' &&
                                !troubleInquiry.shipmentRequested &&
                                quantity > product.normalInventory && (
                                  <span className='mr-1 text-danger small'>[在庫不足]</span>
                                )}
                              <span>{product.code}</span>
                              <span className='ml-2'>{product.name}</span>
                              <span className='ml-2'>{numeral(quantity).format('0,0')}個</span>
                            </div>
                          )
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>合計金額(税込)</th>
                    <td className='text-right'>{numeral(troubleInquiry.amount).format('0,0')}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>お問合せ日時</th>
                    <td>{formatDate(troubleInquiry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>元受注ID</th>
                    <td>{troubleInquiry.sourceOrder?.id}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>元受注日時</th>
                    <td>
                      {troubleInquiry.sourceOrder != null &&
                        formatDate(troubleInquiry.sourceOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>代理店</th>
                    <td>{agent?.name}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>店舗</th>
                    <td>{agentShop?.name}</td>
                  </tr>
                  {entries(basicFields({ productTypes, products })).map(([fieldName, fieldSetting]) => {
                    const displayValue = fieldDisplayValue(troubleInquiry[fieldName], fieldSetting);
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{fieldSetting.label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {displayValue}
                        </td>
                      </tr>
                    );
                  })}
                  {relatedQuestions.map((question) => {
                    const { id, type, name } = question;
                    const answer = troubleInquiry.answers[id];
                    const displayValue = {
                      text: answer,
                      checkbox: keys(answer || {}).join(', '),
                      radio: answer,
                      select: answer,
                      imageFile: [answer].flat().map((answer, i) => (
                        <div key={i}>
                          <a href={answer?.url} target="_blank">
                            {answer?.name}
                          </a>
                        </div>
                      )),
                    }[type];
                    return (
                      <tr key={id}>
                        <th key={id} style={{ width: thWidth }}>
                          {name}
                        </th>
                        <td>{displayValue}</td>
                      </tr>
                    );
                  })}
                  {entries(omit({ ...fields }, keys(basicFields()))).map(([fieldName, fieldSetting]) => {
                    const label = keys(destinationFields()).includes(fieldName)
                      ? `発送先 - ${fieldSetting.label}`
                      : fieldSetting.label;
                    const displayValue = fieldDisplayValue(troubleInquiry[fieldName], fieldSetting);
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {fieldName === 'name' ? (
                            <TenantLink to={`/admin/users/${troubleInquiry.createdBy.uid}`}>{displayValue}</TenantLink>
                          ) : (
                            displayValue
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {entries(reimbursementResultFields()).map(([fieldName, fieldSetting]) => {
                    const { label } = fieldSetting;
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {fieldDisplayValue(troubleInquiry.reimbursementResult?.[fieldName], fieldSetting)}
                        </td>
                      </tr>
                    );
                  })}
                  {entries(discountFields()).map(([fieldName, fieldSetting]) => {
                    const { label } = fieldSetting;
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {fieldDisplayValue(troubleInquiry.discount?.[fieldName], fieldSetting)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-5 mt-sm-0" style={{ minWidth: '40vw' }}>
              {
                !isEmpty(childTroubleInquiries) && (
                  <div>
                    <h5>再問合せ</h5>
                    <div className="overflow-auto" style={{ maxHeight: 360, }}>
                      {
                        orderBy(childTroubleInquiries, _ => _.createdAt.toDate(), 'desc').map((childTroubleInquiry) => {
                          const { id, createdAt, } = childTroubleInquiry;
                          return (
                            <div key={id} className="d-flex justify-content-between">
                              <TenantLink to={`/admin/troubleInquiries/${id}`}>{id}</TenantLink>
                              <div>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                )
              }
              <hr className='my-5' />
              <div>
                <div className='d-flex justify-content-end mb-3'>
                  <AddInTenantButton
                    label='新規コメント'
                    itemRef={commentsRef.doc()}
                    processValues={(_) => ({ ..._, createdBy: pick(user, ['uid', 'email', 'displayName']) })}
                    FormModal={ModelFormModal}
                    formProps={{ title: '新規コメント', fields: commentFields }}
                  />
                </div>
                <div className="overflow-auto" style={{ maxHeight: 360, }}>
                  {comments.length > 0 ? (
                    <div>
                      {comments.map(({ id, body, createdAt, createdBy }) => {
                        return (
                          <div key={id} className='card p-3 mb-3'>
                            <div className='small text-muted mb-1'>
                              {createdBy.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                            </div>
                            <div style={{ whiteSpace: 'pre-line' }}>{body}</div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>コメントはまだありません</div>
                  )}
                </div>
              </div>
              <hr className='my-5' />
              <div>
                <h5>アクティビティ</h5>
                <div className="overflow-auto" style={{ maxHeight: 360, }}>
                  {activities.length > 0 ? (
                    <div className='d-flex flex-column gap-1'>
                      {orderBy(activities, (_) => _.createdAt.toDate(), 'desc').map((_) => (
                        <Activity activity={_} activityTypes={activityTypes} />
                      ))}
                    </div>
                  ) : (
                    <div>アクティビティはまだありません</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
