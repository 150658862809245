import { useEffect } from 'react';
import { useWindowSize } from 'react-use';

import TenantUserPage from '../hocs/TenantUserPage';
import PublicPage from '../hocs/PublicPage';
import LoadingScreen from '../LoadingScreen';
import RichTextContent from '../RichTextContent';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import firebase from '../../firebase';
import { columnWidths } from '../../shared/models/content';
import useAppTitle from '../hooks/useAppTitle';

const db = firebase.firestore();
const contentsRef = db.collection('contents');

function Page(props) {
  const { content } = props;
  const { width, height } = useWindowSize();
  const mainContent = (content) => {
    const { title, file: { type, url } = {} } = content;

    if (!url) return;
    // TODO: iframeのレスポンシブ悩ましい（PDFコンテンツ実際使うことになったら必要に応じて考える）
    return [
      {
        regexp: /^video\/.*$/,
        content: <video title={title} src={url} width="100%" controls controlsList="nodownload" />,
      },
      {
        regexp: /^audio\/.*$/,
        content: <audio title={title} src={url} controls controlsList="nodownload" />,
      },
      {
        regexp: /^image\/.*$/,
        content: <img title={title} src={url} width="100%" />,
      },
      {
        regexp: /^application\/pdf$/,
        content: (
          <iframe
            title={title}
            src={`/pdfjs-4.0.269-dist/web/viewer.html?file=${encodeURIComponent(url)}`}
            width="100%"
            height={height * 0.6}
          />
        ),
      },
    ].find(({ regexp }) => type?.match(regexp))?.content;
  };
  useAppTitle(content?.title);
  const { container = 'container', col = '' } = columnWidths[content?.columnWidth || 'full'] || {};
  useEffect(() => {
    if(content?.isNoindex && ![...(document.head.childNodes || [])].some(_ => _.getAttribute?.('name') === 'robots')) {
      const metaElm = document.createElement('meta');
      metaElm.setAttribute('name', 'robots');
      metaElm.setAttribute('content', 'noindex');
      document.head.appendChild(metaElm);
    }
  }, [content?.isNoindex]);

  return content ? (
    <div className={`${container} p-3 p-md-5`}>
      <div className="row">
        <div className={`col ${col} d-flex flex-column gap-5`}>
          <h5 className="font-weight-bold">{content.title}</h5>
          <RichTextContent html={content.description} />
          <div>{mainContent(content)}</div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
}

export default function Content(props) {
  const {
    match: {
      params: { contentId },
    },
  } = props;
  const contentRef = contentsRef.doc(contentId);
  const content = useDocumentSubscription(contentRef);
  const Component = (content?.isPublic ? PublicPage : TenantUserPage)(Page);
  return <Component {...props} content={content} />;
}
