import React, { useState } from 'react';
import { omitBy, isUndefined, pick } from 'lodash';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import classnames from 'classnames';

import useFirebaseUser from './hooks/useFirebaseUser';
import useDocumentSubscription from './hooks/useDocumentSubscription';

export default function EditButton(props) {
  const {
    type = 'update',
    label = '編集',
    icon = <span className={classnames('fas fa-edit', { 'mr-1': label })} />,
    validateOnSubmit = (_) => true,
    itemRef,
    FormModal,
    formProps,
    beforeSubmit = (_) => _,
    onFinish = (_) => _,
    beforeEdit = _ => _,
    ...extraProps
  } = props;
  const { firebaseUser } = useFirebaseUser();
  const [showsModal, setShowsModal] = useState(false);
  const item = useDocumentSubscription(showsModal && itemRef, [showsModal]);
  const onSubmit = async (values, { showsModal } = { showsModal: false }) => {
    const { id } = itemRef;
    if (!(await validateOnSubmit({ id, ...values }))) return;

    const _values = await beforeSubmit({ id, ...values });
    if(props.onSubmit) {
      await props.onSubmit(_values, { toggleModal: setShowsModal });
    } else {
      try {
        await itemRef[type]({
          ..._values,
          updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
        }, ...(type === 'set' ? [{ merge: true }] : []));
        toast.success('保存しました');
        setShowsModal(showsModal);
        onFinish(_values);
      } catch (e) {
        console.error(e);
        toast.error('失敗しました');
      }
    }
  };

  return (
    <Button color='secondary' onClick={(_) => setShowsModal(true)} {...extraProps}>
      {icon}
      {label}
      {(type === 'set' || item) && (
        <FormModal
          isOpen={showsModal}
          values={beforeEdit(item)}
          onClickClose={(_) => setShowsModal(false)}
          onSubmit={onSubmit}
          {...formProps}
        />
      )}
    </Button>
  );
}
