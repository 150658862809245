import { useRouteMatch } from 'react-router';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import useTenant from './hooks/useTenant';

export default function TenantLink(props) {
  const { to, ...extraProps } = props;
  const match = useRouteMatch();
  const tenant = useTenant();
  const tenantPath = match.params.tenantPath || tenant?.id || props.tenantId;

  return isEmpty(tenantPath) ? <Link to={to} {...extraProps} /> : <Link to={to && '/' + tenantPath + to} {...extraProps} />;
}
