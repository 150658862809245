import React from 'react';
import qs from 'qs';

import PublicPage from '../hocs/PublicPage';
import useQueryParams from '../hooks/useQueryParams';
import PublicTroubleInquiryInputOrderForm from '../forms/PublicTroubleInquiryInputOrderForm';
import PublicTroubleInquirySelectProductForm from '../forms/PublicTroubleInquirySelectProductForm';

export default PublicPage(function PublicTroubleInquiry(props) {
  const { history } = props;
  const { orderId } = useQueryParams();

  const onSubmitInputOrder = ({ orderId, createdAt, productIds }) => {
    history.push(`/troubleInquiries/new?${qs.stringify({ orderId, createdAt, productIds })}`);
  };

  const onSubmitSelectProduct = ({ orderId, createdAt, productId, productTypeId }) => {
    history.push(`/troubleInquiry?${qs.stringify({ orderId, createdAt, productId, productTypeId })}`);
  };

  const onBack = () => {
    history.goBack();
  }

  return (
    <div className="trouble-inquiry position-relative">
      <section className="container mt-5 py-3">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            {orderId ? (
              <PublicTroubleInquirySelectProductForm onSubmit={onSubmitSelectProduct} onBack={onBack} />
            ) : (
              <PublicTroubleInquiryInputOrderForm onSubmit={onSubmitInputOrder} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
});
