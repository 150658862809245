import React, { Fragment } from 'react';
import { Button, Form } from 'reactstrap';
import { mapValues, pick } from 'lodash';
import { useToggle } from 'react-use';
import { useLocation, useHistory } from 'react-router';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { fields } from '../../shared/models/user';
import { getAddressByPostalCode, fullPathWithParams } from '../../util';
import ModalButton from '../ModalButton';

const { entries } = Object;

export default function SignUpForm(props) {
  const { isOpen, values, isForSubUser = false, translate, abroad, full = false, } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const statedFields = useFormState(
    values,
    (isForSubUser || abroad) ? pick(fields({ abroad }), [
      'email',
      'displayName',
      'phone',
      'password',
      'passwordConfirmation',
    ]) : pick(fields({ abroad, }), [
          'email',
          'displayName',
          'phone',
          'postalCode',
          'prefecture',
          'city',
          ...(full ? ['address'] : []),
          'route',
          'password',
          'passwordConfirmation',
          'accept',
        ]),
    isOpen
  );
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid);
  const handleChangePostalCode = async ({ target: { value } }) => {
    if (value?.length !== 7) return;

    try {
      const { prefecture, city, address1 } = await getAddressByPostalCode(value);
      statedFields.setValues({
        postalCode: value,
        prefecture: prefecture,
        city: city + address1,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const location = useLocation();
  const history = useHistory();
  const fromEvent = /\/events\/\S+\/entries\/new/.test(location.pathname);

  const onSubmit = async (event) => {
    toggleSubmitting(true);
    event.preventDefault();
    if (isUnsubmittable) return;
    await props.onSubmit({ ...mapValues(statedFields, 'value') });
    toggleSubmitting(false);

    if (fromEvent) {
      history.replace(fullPathWithParams({ isSignedUp: 1 }, location));
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {!isForSubUser && <div className="alert alert-info">
        {fromEvent ? <span>アカウントをお持ちでない方は、新規アカウントを作成してください。<br />アカウント作成後に、申込ができるようになります。</span> : <span>アカウントをお持ちでない場合、新規登録をして下さい。</span>}
      </div>}
      <div>
        {entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Fragment>
            {fieldName === 'accept' && (
              <div className="d-flex flex-column align-items-start">
                {/*
                    <TenantLink to="/terms" target="_blank">
                      利用規約
                      <span className="fas fa-external-link-alt ml-1" />
                    </TenantLink>
                    */}
                <a href="http://www.vitamin-i.jp/privacy.html" target="_blank" rel="noopener noreferrer">
                  個人情報の取扱いについて
                  <span className="fas fa-external-link-alt ml-1" />
                </a>
              </div>
            )}
            <Field
              translate={translate}
              documentName="user"
              className={fieldName === 'city' && !full && 'd-none'}
              key={fieldName}
              name={fieldName}
              {...fieldSetting}
              onChange={fieldName === 'postalCode' ? handleChangePostalCode : _ => _}
            />
          </Fragment>
        ))}
      </div>
      <div>
        <Button block type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
          {fromEvent ? 'メール送信で本人認証へ進む' : '登録'}
        </Button>
      </div>
      <ModalButton title="サブアカウントの作成" content={() => (
        <div className='text-center mb-3'>
          お孫さんへのプレゼントやママが購入してパパからの問合せなど
          <br />
          ご購入者とお使いになる方が異なる場合は
          <br />
          こちらから、サブアカウントを作成できます。
          <br />
          <br />
          「閉じる」を押して新規登録にお進みください。
        </div>
        )} isInitiallyOpen={isForSubUser} hidden />
    </Form>
  );
}
