import React from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

import firebase from '../firebase';

const db = firebase.firestore();
const surveyAnswersRef = db.collection('surveyAnswers');

export default function DeleteSurveyAnswersButton ({ targetAnswers = [], onFinish = null, disabled, ...extraProps }) {
  const [isDeleting, toggleDeleting] = useToggle();
  const onClickDelete = async (props) => {
    if (!window.confirm(`${targetAnswers.length}件の回答を削除しますか？`)) return;

    toggleDeleting(true);
    await Promise.all(targetAnswers.map(id => surveyAnswersRef.doc(id).delete()));
    onFinish && await onFinish();
    toast.success('削除しました');
    toggleDeleting(false);
  }

  return targetAnswers.length !== 0 && (
    <Button color="danger" onClick={onClickDelete} disabled={disabled || isDeleting} {...extraProps}>
      <span className="fas fa-trash mr-1" />
      選択した回答を削除
    </Button>
  );
};
