import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import numeral from 'numeral';
import { mapValues } from 'lodash';
import { toast } from 'react-toastify';
import Field from '../Field';
import { auditData } from '../../shared/models/user';
import { Inquiry } from '../../types/inquiry';
import { TroubleInquiry } from '../../types/troubleInquiry';
import { User } from '../../types/user';
import { reimbursementResultFields as fields } from '../../shared/models/troubleInquiry';
import useFormState from '../hooks/useFormState';

export default function InquiryReimbursementResultFormModal({
  inquiry,
  user,
  isOpen,
  onClickClose,
  onSubmit,
  onCancel,
}: {
  inquiry: TroubleInquiry | Inquiry;
  user: User;
  isOpen: boolean;
  onClickClose: () => void;
  onSubmit?: () => Promise<void>;
  onCancel?: () => Promise<void>;
}) {
  const { ref, reimbursementRequest, reimbursementResult } = inquiry;
  const statedFields = useFormState(reimbursementResult, fields(), isOpen) as { [key: string]: {} };
  const isUnsubmittable = Object.values(statedFields).some((_: any) => !_.isValid);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    if (reimbursementResult && !window.confirm('既に精算結果を登録済みです。上書き登録しますか？')) return;

    try {
      await ref?.update({
        reimbursementResult: { ...mapValues(statedFields, 'value'), registeredAt: new Date() },
        updatedBy: auditData(user),
      });
      onSubmit && (await onSubmit());
      toast.success('登録しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const handleCancelReimbursement = async () => {
    if (!window.confirm('本当に立替申請の受付をキャンセルしますか？')) return;

    try {
      await ref?.update({
        hasReimbursement: false,
        reimbursementRequest: null,
        status: 'supporting',
        updatedBy: auditData(user),
      });
      onCancel && (await onCancel());
      toast.success('立替申請の受付をキャンセルしました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader>立替精算</ModalHeader>
        <ModalBody>
          <div>
            <h5 className='mb-4'>申請内容</h5>
            <table className='table table-bordered'>
              <tbody>
                <tr>
                <th style={{ width: '100px' }}>申請者</th>
                  <td>
                    <div>
                      {reimbursementRequest?.requestedBy?.displayName}
                    </div>
                    <div>
                      {reimbursementRequest?.requestedBy?.email}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>申請金額</th>
                  <td>{reimbursementRequest && numeral(reimbursementRequest.amount).format('0,0')}</td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>領収書</th>
                  <td>
                    {reimbursementRequest?.receiptFiles.map(({ name, url }, index) => {
                      return (
                        <div key={index}>
                          <a href={url} target='_blank'>
                            {name}
                          </a>
                        </div>
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '100px' }}>連絡事項</th>
                  <td>{reimbursementRequest?.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr className='my-3' />
          <div>
            <h5 className='mb-4'>精算結果を入力してください</h5>
            {Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
              return <Field key={fieldName} name={fieldName} documentName='inquiry' {...fieldSetting} />;
            })}
          </div>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-between'>
          <Button color='primary' onClick={handleCancelReimbursement}>
            受付をキャンセルする
          </Button>
          <div className='d-flex gap-2'>
            <Button color='primary' type='submit' disabled={!reimbursementRequest}>
              登録
            </Button>
            <Button onClick={onClickClose}>閉じる</Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
