import useTenant from './useTenant';
import { addActivity } from 'src/models/activity';

export default function useCreateActivity() {
  const tenant = useTenant();

  const createActivity = async (type, user, payload) => {
    await addActivity({
      ...(tenant && { tenantId: tenant.id }),
      type,
      payload,
      createdBy: user,
    });
  };

  return createActivity;
}
