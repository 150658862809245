import React, { useEffect, useRef, } from 'react';
import qrcode from 'qrcode';
import { Button, Input, } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import { keyBy, sortBy, get } from 'lodash';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QrCodeCard from '../QrCodeCard';
import { fields } from '../../shared/models/referrer';

const db = firebase.firestore();
const agentsRef = db.collection('agents');
const qrUrlsRef = db.collection('qrUrls');

export default AdminPage(function AdminAgentShopReferrer (props) {
  const { user, match: { params: { agentId, agentShopId, referrerId, } }, addBreadNavValues } = props;
  const agentRef = agentsRef.doc(agentId);
  const agentShopRef = agentRef.collection('agentShops').doc(agentShopId);
  const referrerRef = agentShopRef.collection('referrers').doc(referrerId);
  const qrUrls = sortBy(useCollectionSubscriptionInTenant(qrUrlsRef), _ => _.createdAt.toDate());
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const agentShop = useDocumentSubscription(agentShopRef, [agentId, agentShopId]);
  const referrer = useDocumentSubscription(referrerRef, [agentId, agentShopId, referrerId]);
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(db.collection('qrBottomDisplays')), _ => _.createdAt.toDate());
  const qrBottomDisplaysById = keyBy(qrBottomDisplays, 'id');
  const filteredQrUrls = qrUrls
    .filter(_ => !_.isHidden)
    .filter(_ => agent?.showingQrUrls?.map(_ => _.qrUrlId).includes(_.id));
  useEffect(() => {
    addBreadNavValues({ agent, agentShop, referrer, })
  }, [agent, agentShop, referrer]);

  return referrer != null && (
    <div>
      <div className="admin-agent-shop-referrer container py-5 position-relative">
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              <h5 className="text-center mb-4">
                {referrer.name}
              </h5>
              <div className="d-flex flex-wrap justify-content-around">
                {
                  filteredQrUrls.map((qrUrl) => {
                    const { id, name, url } = qrUrl;
                    return (
                      <QrCodeCard key={id} {...{ qrUrl, agentShop, referrer, qrBottomDisplaysById, }} />
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
