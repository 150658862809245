import React from 'react';
import { sortBy, } from 'lodash';
import firebase from '../../firebase';
import { canUpdateSurveys } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { defaultGroups, fields } from '../../shared/models/surveyGroup';
import { SurveyGroup } from '../../types/surveyGroup';

const db = firebase.firestore();
const surveyGroupsRef = db.collection('surveyGroups');

export default AdminPage(function AdminSurveyGroups(props) {
  const { user } = props;
  const surveyGroups = sortBy(useCollectionSubscriptionInTenant(surveyGroupsRef), _ => _.createdAt.toDate()) as unknown as SurveyGroup[];

  return (
    <div>
      <div className="admin-coupon-templates container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>アンケートグループ管理</h4>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <AddInTenantButton
            itemRef={surveyGroupsRef.doc()}
            FormModal={ModelFormModal}
            formProps={{ title: 'アンケートグループ追加', fields: fields() }}
            disabled={!canUpdateSurveys(user)}
          />
        </div>
        <div>
          {surveyGroups.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th>グループ名</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {defaultGroups.map((defaultGroup) => {
                  const { id, name } = defaultGroup;
                  return (
                    <tr key={id}>
                      <td>{name}</td>
                      <td></td>
                    </tr>
                  );
                })}
                {surveyGroups.map((surveyGroup) => {
                  const { id, ref, name } = surveyGroup;
                  return (
                    <tr key={id}>
                      <td>{name}</td>
                      <td className="text-nowrap text-right">
                        <EditButton
                          itemRef={ref}
                          FormModal={ModelFormModal}
                          formProps={{ title: 'アンケートグループ編集', fields: fields() }}
                          disabled={!canUpdateSurveys(user)}
                        />
                        <DeleteButton className="ml-2" itemRef={ref} disabled={!canUpdateSurveys(user)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>アンケートグループは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});
