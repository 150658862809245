const { isHiragana } = require('wanakana');
const { keyBy, pick, isNaN, orderBy, sumBy, get, isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');
const { format: formatDate, addDays, setMonth, eachDayOfInterval, startOfMonth, endOfMonth, } = require('date-fns');
const { utcToZonedTime } = require('date-fns-tz');
const numeral = require('numeral');

const { prefectures, routes, } = require('../config');

const { entries } = Object;

const basicFields = ({ rentalItems, rentalPrices, }) => {
  return {
    rentalItemId: {
      label: '商品',
      type: 'select',
      options: rentalItems.map((rentalItem) => ({ value: rentalItem.id, rentalItem, })),
      validations: {
        required: v => !isEmpty(v),
      },
      inputProps: {
        isSearchable: false,
      },
    },
    rentalPriceId: {
      label: '時間',
      type: 'select',
      options: s => rentalPrices.map(_ => ({ label: `${_.name} (${numeral(_[`price__${s.rentalItemId}`]).format()}円)`, value: _.id, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const ordererFields = () => {
  return {
    name: {
      label: 'お名前',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    nameKana: {
      type: 'string',
      label: 'お名前ふりがな',
      validations: {
        required: v => !isEmpty(v),
        hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
      },
    },
    phone: {
      type: 'string',
      inputType: 'tel',
      label: '電話番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || v.match(/^(0{1}\d{9,10})$/),
      },
      warnings: {
        cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
      },
      placeholder: '08012345678 （ハイフンなし）'
    },
    email: {
      type: 'string',
      inputType: 'email',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
      hint: `
        携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
        詳しくは各携帯電話会社にご確認下さい。
      `,
    },
    postalCode: {
      type: 'string',
      label: '郵便番号',
      validations: {
        required: v => !isEmpty(v),
        format: v => (v || '').match(/^[0-9]{7}$/g),
      },
      inputProps: {
        type: 'tel',
      },
    },
    prefecture: {
      label: '都道府県',
      type: 'select',
      options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      validations: {
        required:  v => !isEmpty(v),
      },
    },
    city: {
      type: 'string',
      label: '市区町村',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    address: {
      type: 'string',
      label: '番地・建物名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    route: {
      type: 'select',
      label: 'どちらでお知りになりましたか？',
      options: routes.map(_ => ({ value: _, label: _ })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fields = (...args) => {
  return {
    ...basicFields(...args),
    ...ordererFields(...args),
  };
};

const cancelFields = () => {
  return {
    cancellationNote: {
      type: 'text',
      label: 'キャンセル理由',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const paymentMethods = {
  credit: { label: 'クレジットカード', },
};

const paymentMethod = (order) => {
  return 'credit';
};

module.exports = {
  basicFields,
  ordererFields,
  fields,
  paymentMethods,
  paymentMethod,
  cancelFields,
};
