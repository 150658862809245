import React, { Fragment, useEffect, useRef, } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import { maxBy, omit, isEmpty, sumBy, get, keyBy, } from 'lodash';
import { format as formatDate, addMinutes, addMonths, startOfDay, endOfDay, } from 'date-fns';
import { Button, Input, } from 'reactstrap';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useCounter, useAsync, useInterval, } from 'react-use';

import { canUpdateOrder, } from '../../shared/abilities';
import firebase, { functions } from '../../firebase';
import { canUpdateAgent, } from '../../shared/abilities';
import { beforeDelete, fillAddress, downloadCanvasImage } from '../../util';
import { prefectures } from '../../shared/config';
import { fieldDisplayValue } from '../../shared/util';
import { fields } from '../../shared/models/agentShop';
import AgentPage from '../hocs/AgentPage';
import AgentHeader from '../AgentHeader';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import useTenant from '../hooks/useTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';
import ExportButton from '../ExportButton';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QueryDateSelector from '../QueryDateSelector';
import QueryBoolean from '../QueryBoolean';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const productsRef = db.collection('products');

export default AgentPage(function AgentShops (props) {
  const { user, history, match: { params: { tenantPath } }, agent, setBreadNavValues, canReadAllShops, memberData, canEditMembers, } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const agentShops = useCollectionSubscription(agent.ref.collection('agentShops').orderBy('createdAt'), [agent]);
  const displayableAgentShops = canReadAllShops ? agentShops : agentShops.filter(_ => memberData.shopIds.includes(_.id));

  let filteredAgentShops = displayableAgentShops;
  if (showsHidden !== '1') {
    filteredAgentShops = filteredAgentShops.filter(_ => !_.isHidden);
  }

  const onFormStateChanged = (prevStatedFields, statedFields) => {
    fillAddress(prevStatedFields, statedFields);
  };
  useEffect(() => {
    setBreadNavValues({ agent });
  }, [agent]);

  return (
    <div>
      <div className="agent-shops container-fluid py-5 position-relative">
        <AgentHeader activeTab="shops" agent={agent} canEditMembers={canEditMembers} />
        <div className="bg-white p-4 py-5" style={{ minHeight: '50vh', }}>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-end justify-content-end mb-3 gap-1">
                <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
                <AddInTenantButton
                  itemRef={agent.ref.collection('agentShops').doc()}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '店舗追加',
                    fields: omit(fields(), ['customerCode']), onChange: onFormStateChanged,
                    renderFooterContent: (
                      base,
                      { onSubmit, isUnsubmittable, toggleSubmitting, statedFields, isSubmitting }
                    ) => {
                      const onSubmitNext = async (...args) => {
                        await onSubmit(...args);
                        history.push(`/${tenantPath}/agents/${agent.id}/members?${qs.stringify({ creates: 1, email: statedFields.email.value })}`);
                      };
                      return (
                        <Fragment>
                          {base}
                          <Button className="save" type="submit" color="primary" onClick={onSubmitNext} disabled={isUnsubmittable || isSubmitting}>
                            {isSubmitting && <span className="fas mr-1 fa-spin fa-spinner" />}
                            保存してビジネスアカウント作成へ
                          </Button>
                        </Fragment>
                      );
                    },
                  }}
                  disabled={!canUpdateAgent(user, agent)}
                />
              </div>
              {
                displayableAgentShops.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>店舗名</th>
                        <th>メールアドレス</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (filteredAgentShops || []).map((agentShop) => {
                          const { id, ref, name, email, isHidden = false, } = agentShop;

                          return (
                            <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                              <td>
                                <TenantLink to={`/agents/${agent.id}/agentShops/${id}`}>
                                  {name}
                                </TenantLink>
                              </td>
                              <td>
                                {email}
                              </td>
                              <td className="text-right">
                                <div className="d-flex gap-1 justify-content-end">
                                  {
                                    agent.usesWholesale && (
                                      <ModalButton title="お客様用卸QR" content={_ => <WholesaleQrModalContent agent={agent} agentShop={agentShop} />}>
                                        お客様用卸QR
                                      </ModalButton>
                                    )
                                  }
                                  {
                                    agent.usesWholesale && (
                                      <Button tag={TenantLink} to={`/products?${qs.stringify({ wholesale: '1', agentId: agent.id, agentShopId: id, })}`} target="_blank" onClick={_ => window.alert('一括請求用卸カートに入ります')}>
                                        卸カート
                                        <span className="fas fa-external-link-alt ml-1" />
                                      </Button>
                                    )
                                  }
                                  {
                                    agent.usesPartsWholesale && (
                                      <Button tag={TenantLink} to={`/parts?${qs.stringify({ wholesale: '1', agentId: agent.id, agentShopId: id, })}`} target="_blank" onClick={_ => window.alert('一括請求用卸カートに入ります')}>
                                        パーツ卸カート
                                        <span className="fas fa-external-link-alt ml-1" />
                                      </Button>
                                    )
                                  }
                                  {
                                    agent.usesRental && (
                                      <ModalButton title="レンタル購入QR" content={_ => <RentalQrModalContent agent={agent} agentShop={agentShop} />}>
                                        レンタル購入QR
                                      </ModalButton>
                                    )
                                  }
                                  <EditButton itemRef={ref} FormModal={ModelFormModal} disabled={!canUpdateAgent(user, agent)} formProps={{ title: '店舗編集', fields: omit(fields(), ['customerCode']), onChange: onFormStateChanged, }} />
                                  <DeleteButton itemRef={ref} disabled={!canUpdateAgent(user, agent)} beforeDelete={beforeDelete([ref.collection('referrers'), ref.parent.parent.collection('rentalOrders').where('agentShopId', '==', id)])} />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div>
                    No Data
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

function WholesaleQrModalContent (props) {
  const { agent, agentShop } = props;
  return (
    <div>
      <div className="alert alert-info">
        お客様のスマホで読み取り、注文者、配送先を入力してください。
        <br />
        店舗受取を選ぶこともできます。
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-center">
          どちらを注文しますか？
        </div>
        <div className="mt-2 d-flex justify-content-around gap-2">
          <ModalButton title="本体" className="flex-grow-1" content={_ => (
            <div>
              <WholesaleQrCodeModalContent agent={agent} agentShop={agentShop} target="products" />
            </div>
          )}>
            本体
          </ModalButton>
          <ModalButton title="パーツ" className="flex-grow-1" content={_ => (
            <div>
              <WholesaleQrCodeModalContent agent={agent} agentShop={agentShop} target="parts" />
            </div>
          )}>
            パーツ
          </ModalButton>
        </div>
      </div>
    </div>
  );
}

function WholesaleQrCodeModalContent (props) {
  const { agent, agentShop, target = 'products' } = props;
  const canvasEl = useRef(null);
  const [count, { inc: incCount }] = useCounter();
  const now = new Date();
  const url = `${window.location.origin}/${target}?${qs.stringify({ wholesale: '1', n: now.getTime(), agentId: agent.id, agentShopId: agentShop?.id, })}`;

  useInterval(incCount, 60 * 1000);
  useEffect(() => {
    if(canvasEl.current) {
      qrcode.toCanvas(canvasEl.current, url, { width: 350, height: 350 });
    }
  }, [canvasEl, url]);

  return (
    <div className="py-5">
      <div className="d-flex flex-column align-items-center justify-content-center" title={url}>
        <div>
          このカートは{formatDate(addMinutes(now, 45), 'yyyy/MM/dd HH:mm')}まで利用可能
        </div>
        <canvas ref={canvasEl} />
        <Input value={url} readOnly />
      </div>
    </div>
  );
}

function RentalQrModalContent (props) {
  const { agent, agentShop, } = props;
  const canvasEl = useRef(null);
  const tenant = useTenant();
  const url = `${window.location.origin}/${tenant.id}/rentalItems?${qs.stringify({ agentId: agent.id, agentShopId: agentShop?.id, })}`;
  useEffect(() => {
    if(canvasEl.current) {
      qrcode.toCanvas(canvasEl.current, url, { width: 350, height: 350 });
    }
  }, [canvasEl, url]);

  return (
    <div className="py-5">
      <div className="d-flex flex-column align-items-center justify-content-center" title={url}>
        <canvas ref={canvasEl} />
        <Input value={url} readOnly />
      </div>
    </div>
  );
}
