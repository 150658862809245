import { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Progress } from 'reactstrap';
import { startOfMonth, endOfMonth } from 'date-fns';
import classnames from 'classnames';
import { mapValues } from 'lodash';
import { toast } from 'react-toastify';
import Field from '../Field';
import useFormState from '../hooks/useFormState';

const fields = ({ fieldOptions, staffOptions, prefectureOptions, agentOptions, productTypeOptions, preOrderTypeOptions }) => ({
  beginDate: {
    label: '開始日',
    type: 'date',
    initialValue: startOfMonth(new Date()),
    inputProps: { isClearable: false }
  },
  endDate: {
    label: '終了日',
    type: 'date',
    initialValue: endOfMonth(new Date()),
    inputProps: { isClearable: false }
  },
  field: { 
    label: '検索フィールド',
    type: 'select',
    options: fieldOptions
  },
  text: { 
    label: '検索テキスト',
    type: 'string',
  },
  staffs: {
    label: '担当で絞込み',
    type: 'multiSelect',
    options: staffOptions,
  },
  prefectures: {
    label: 'エリア都道府県で絞込み',
    type: 'multiSelect',
    options: prefectureOptions,
  },
  agents: {
    label: '代理店で絞込み',
    type: 'multiSelect',
    options: agentOptions,
  },
  productTypes: {
    label: '商品種別で絞込み',
    type: 'multiSelect',
    options: productTypeOptions
  },
  preOrderTypes: {
    label: '予約注文かどうかで絞込み',
    type: 'multiSelect',
    options: preOrderTypeOptions
  },
  withoutCancel: {
    label: 'キャンセルを除く',
    type: 'boolean',
  },
  withPartsOrder: {
    label: 'パーツ注文も表示する',
    type: 'boolean',
  },
  referrerIncludes: {
    label: 'リファラ情報を含む',
    type: 'boolean',
    initialValue: false,
  },
});

export default function OrderExportModal({ isOpen, onClickClose, onSubmit, fieldOptions, staffOptions, prefectureOptions, agentOptions, productTypeOptions, preOrderTypeOptions }) {
  const [isExporting, setIsExporting] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const statedFields = useFormState(null, fields({ fieldOptions, staffOptions, prefectureOptions, agentOptions, productTypeOptions, preOrderTypeOptions }), isOpen);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_?.isValid);
  const onProgress = (value, max) => setProgressValue(((value) / max) * 100)
  
  const handleSubmit = async () => {
    const { beginDate, endDate, field, text, staffs, prefectures, agents, productTypes, preOrderTypes, withoutCancel, withPartsOrder, referrerIncludes } = mapValues(statedFields, 'value');
    try {
      setIsExporting(true);
      onSubmit && (await onSubmit({ beginDate, endDate, field, text, staffs, prefectures, agents, productTypes, preOrderTypes, withoutCancel, withPartsOrder, referrerIncludes, onProgress }));
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <Form>
        <ModalHeader>期間指定エクスポート</ModalHeader>
        <ModalBody>
          {Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
            return <Field key={fieldName} name={fieldName} {...fieldSetting} />;
          })}
        </ModalBody>
        <ModalFooter>
          <ProgressBar isExporting={isExporting} progressValue={progressValue} />
          <Button color="primary" onClick={handleSubmit} disabled={isUnsubmittable || isExporting}>
            <span
              className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })}
            />
            エクスポート
          </Button>
          <Button onClick={onClickClose}>閉じる</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

const ProgressBar = (props) => {
  const { isExporting, progressValue } = props;
  return (
    isExporting &&
    progressValue != null && (
      <div className="w-100">
        <Progress color="success" value={Math.min(progressValue, 100)} />
        <div className="text-center">{Math.min(Math.ceil(progressValue), 100)}%</div>
      </div>
    )
  );
};
