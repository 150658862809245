import React from 'react';
import { keyBy } from 'lodash';

import firebase, { Timestamp } from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import { Survey } from 'types/survey';
import { SurveyAnswer } from 'types/surveyAnswer';
import { Question } from 'types/question';
import SurveyAnswerItem from '../SurveyAnswerItem';

const db = firebase.firestore();

export default AdminPage(function SurveyAnswers(props) {
  const {
    user,
    match: {
      params: { userId },
    },
  } = props;
  const surveys = useCollectionSubscriptionInTenant(
    db.collection('surveys').orderBy('createdAt', 'desc'),
    []
  ) as unknown as Survey<Timestamp>[];
  const surveysById = keyBy(surveys, 'id');
  const surveyAnswers = useCollectionSubscriptionInTenant(
    db.collection('surveyAnswers').where('createdBy.uid', '==', userId).orderBy('createdAt', 'desc'),
    []
  ) as unknown as SurveyAnswer<Timestamp>[];
  const questions = useCollectionSubscriptionInTenant(
    db.collection('questions').orderBy('createdAt'),
    []
  ) as unknown as Question<Timestamp>[];
  const rows = surveyAnswers
    .filter(({ surveyId }) => surveysById[surveyId])
    .map((surveyAnswer) => {
      const survey = surveysById[surveyAnswer.surveyId];
      return {
        survey,
        ...surveyAnswer,
      };
    });

  return (
    <div className="mypage-survey-answers">
      <section className="container-fluid bg-light-grey">
        <h5 className="text-center mb-5 font-weight-bold p-4">アンケート回答一覧</h5>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="mt-4">
              {rows.map((surveyAnswer) => {
                return (
                  <SurveyAnswerItem
                    key={surveyAnswer.id}
                    surveyAnswer={surveyAnswer}
                    user={user}
                    questions={questions}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});
