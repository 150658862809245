import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { pick } from 'lodash';

import ModelFormModal from './modals/ModelFormModal';
import { TroubleInquiry } from '../types/troubleInquiry';
import { Inquiry } from '../types/inquiry';
import firebase from '../firebase';
import { auditData } from '../shared/models/user';
import { User } from '../types/user';

const storageRef = firebase.storage().ref();

export default function InquiryReimbursementRequestButton({
  inquiry,
  formFields,
  buttonProps,
  user,
}: {
  inquiry: Inquiry | TroubleInquiry;
  formFields: {};
  buttonProps?: any;
  user: User;
}) {
  const { id, ref, reimbursementRequest } = inquiry;
  const [showsModal, toggleModal] = useToggle(false);
  const onSubmit = async (values) => {
    try {
      const receiptFiles = await Promise.all(
        Array.from(values.receiptFiles).map(async (file: any) => {
          const fileRef = storageRef.child(`troubleInquiries/${id}/reimbursementReceiptFiles/${new Date().toISOString()}/${file.name}`);
          await fileRef.put(file, { contentType: file.type });
          return {
            ...pick(file, ['name', 'type']),
            url: await fileRef.getDownloadURL(),
          };
        })
      );

      await ref?.update({
        status: 'supporting',
        reimbursementRequest: { ...values, receiptFiles, requestedBy: auditData(user), requestedAt: new Date() },
      });
      toast.success('立替工賃申請を受け付けました');
      toggleModal(false);
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button onClick={(_) => toggleModal(true)} {...buttonProps}>
      {reimbursementRequest?.requestedAt ? '立替工賃を申請済み' : '立替工賃を申請する'}
      <ModelFormModal
        isOpen={showsModal}
        onClickClose={(_) => toggleModal(false)}
        onSubmit={onSubmit}
        title='立替工賃申請'
        fields={formFields}
        submitLabel='申請する'
      />
    </Button>
  );
}
