import React, { useEffect, } from 'react';
import { sortBy, get, pick, keyBy, omitBy, isUndefined } from 'lodash';
import numeral from 'numeral';

import firebase from '../../firebase';
import { prefectures } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useFirebaseUser from '../hooks/useFirebaseUser';
import ExportButton from '../ExportButton';
import ImportButton from '../ImportButton';

const { entries } = Object;
const db = firebase.firestore();
const usersRef = db.collection('users');
const prefecturesByName = entries(prefectures).reduce((x, [k, v]) => ({ ...x, [v]: k }), {});

export default AdminPage(function AdminArea(props) {
  const { tenant, match: { params: { tenantPath } } } = props;
  const { firebaseUser } = useFirebaseUser();
  const areaSettingsRef = db.collection('settings').doc([tenantPath, 'area'].join('__'));
  const staffs = useCollectionSubscriptionInTenant(usersRef.where('role', 'in', ['admin', 'staff']));
  const staffsByEmail = keyBy(staffs, 'email');
  const areaSetting = useDocumentSubscription(areaSettingsRef);
  const processRows = async (rows) => {
    areaSettingsRef.set(
      {
        data: rows
          .filter((_) => _?.postalCode3)
          .reduce((x, row) => {
            const { postalCode3, userEmail, prefectureName, city, group } = row;
            const staff = staffsByEmail[userEmail];
            return {
              ...x,
              [numeral(postalCode3).format('000')]: {
                user: pick(staff, ['id', 'displayName', 'email']),
                prefecture: prefecturesByName[prefectureName],
                city,
                group,
              },
            };
          }, {}),
        updatedAt: new Date(),
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      },
      { merge: true }
    );
  };
  const rowsForExport = (_) => {
    return sortBy(entries(areaSetting.data), '[0]').map(([postalCode3, item]) => {
      return {
        postalCode3: numeral(postalCode3).format('000'),
        userEmail: get(item, 'user.email'),
        prefectureName: prefectures[item.prefecture],
        city: item.city,
        group: item.group,
      };
    });
  };
  useEffect(() => {
    areaSettingsRef.set({ tenantId: tenant.id }, { merge: true });
  }, []);

  return (
    <div>
      <div className='admin-productTags container py-5 position-relative'>
        <div className='d-flex justify-content-center mb-3'>
          <h4>エリア管理</h4>
        </div>
        <div className='d-flex justify-content-end mb-3'>
          <ExportButton fileName='area.csv' rows={rowsForExport} />
          <ImportButton className='ml-2' processRows={processRows} />
        </div>
        <div>
          <table className='table'>
            <thead className='thead-light text-center'>
              <tr>
                <th>グループ</th>
                <th>郵便番号頭3桁</th>
                <th>担当者</th>
                <th>都道府県</th>
                <th>市区町村</th>
              </tr>
            </thead>
            <tbody>
              {sortBy(entries(get(areaSetting, 'data', {})), '[0]').map(([postalCode3, { user, prefecture, city, group }]) => {
                return (
                  <tr key={postalCode3}>
                    <td>{group}</td>
                    <td>{postalCode3}</td>
                    <td>
                      {get(user, 'displayName')}
                      <span className='ml-1 text-muted small'>({get(user, 'email')})</span>
                    </td>
                    <td>{prefectures[prefecture]}</td>
                    <td>{city}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
