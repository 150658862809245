import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form } from 'reactstrap';
import { keyBy, omit, mapValues, isFunction } from 'lodash';
import { useList } from 'react-use';
import useFormState from '../hooks/useFormState';

import { targetFields } from '../../shared/models/paceMakerSetting';
import PaceMakerTagetForm from '../forms/PaceMakerTargetForm';
import ListForm from '../ListForm';
import Field from '../Field';

const { entries } = Object;

export default function PaceMakerFormModal(props) {
  const { isOpen, values, onClickClose, fields, documentName } = props;
  const statedFields = useFormState(values, isFunction(fields) ? fields() : fields, isOpen);
  const [items, { set: setItems, updateAt: updateItemAt }] = useList(values.targets || []);
  const isUnsubmittable = items.some((_) => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    await props.onSubmit(
      { ...mapValues(statedFields, 'value'), targets: items.map((_) => omit(_, 'isValid')) },
      { onClickClose }
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="modal-header">
        <div className="w-100 d-flex justify-content-between">
          <h5 className="modal-title">ペースメーカー設定</h5>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              key={fieldName}
              name={fieldName}
              values={mapValues(statedFields, 'value')}
              documentName={documentName}
              {...fieldSetting}
            />
          ))}
          <div>
            <ListForm
              addButtonLabel="目標値を追加する"
              items={items}
              renderItem={(item, itemIndex) => {
                return (
                  <div className="card p-3">
                    <PaceMakerTagetForm
                      fields={targetFields()}
                      index={itemIndex}
                      values={item}
                      onChange={(_) => updateItemAt(itemIndex, { ...item, ..._ })}
                    />
                  </div>
                );
              }}
              onChange={setItems}
              minItems={0}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
