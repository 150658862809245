import React, { useState, useEffect, } from 'react';
import { isEmpty, } from 'lodash';
import { useInterval } from 'react-use';

import firebase from '../../firebase';
import HeaderNav from '../HeaderNav';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import TenantPage from './TenantPage';
import useAccessLog from '../hooks/useAccesslog';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const updateAccessLogIntervalSeconds = 10;

export default function PublicPageHOC(WrappedComponent) {
  return TenantPage(function PublicPage (props) {
    const { location, setLang, } = props;
    const [firebaseUser, setFirebaseUser] = useState();
    const myAccount = useDocumentSubscription(firebaseUser && usersRef.doc(firebaseUser.uid), [firebaseUser]);
    const mainAccount = useDocumentSubscription(!isEmpty(myAccount?.mainUserId) && usersRef.doc(myAccount.mainUserId), [myAccount]);
    const user = isEmpty(myAccount?.mainUserId) ? myAccount : mainAccount;

    useEffect(() => {
      return auth.onAuthStateChanged((firebaseUser) => {
        setFirebaseUser(firebaseUser);
      });
    }, []);
    useEffect(() => {
      user?.lang && setLang(user.lang);
    }, [user]);

    const [accessLogId, setAccessLogId] = useState();
    const { createAccessLog, updateAccessLog } = useAccessLog();
    useEffect(() => {
      new Promise((resolve) => auth.onAuthStateChanged(firebaseUser => resolve(!!firebaseUser)))
        .then(isLoggedIn => ((isLoggedIn && user) || !isLoggedIn) && createAccessLog(user, location))
        .then(setAccessLogId);
    }, [user, location]);
    useInterval(() => {
      if(accessLogId && document.hasFocus()) {
        updateAccessLog(accessLogId, updateAccessLogIntervalSeconds);
      }
    }, updateAccessLogIntervalSeconds * 1000);

    return (
      <div className="public-page h-100 d-flex flex-column mb-5">
        <HeaderNav {...props} {...{ user, myAccount, mainAccount, }}/>
        <WrappedComponent {...props} {...{ firebaseUser, user, myAccount, mainAccount, } } />
      </div>
    );
  });
};
