import { isEmpty } from 'lodash';
import numeral from 'numeral';

import firebase from '../firebase';
import { defaultShipmentFee, shipmentFeeThreshold } from '../shared/config';
import useTenant from './hooks/useTenant';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import SettingText from './SettingText';

const db = firebase.firestore();

export default function CartInformation() {
  const tenant = useTenant();
  const cartSettingsRef = db.collection('settings').doc([tenant.id, 'cart'].join('__'));
  const cartSettings = useDocumentSubscription(cartSettingsRef);
  return (
    <>
      <div className='bg-light-grey rounded-3 p-3'>
        <h5 className='font-weight-bold text-center mb-3'>配送について</h5>
        <div style={{ whiteSpace: 'pre-line' }}>
          {cartSettings?.deliveryDescription}
        </div>
      </div>
      <SettingText
        name='productsPageHeaderHtml'
        renderContainer={(html, children) =>
          !isEmpty(html) && <div className='text-break border border-info rounded p-3 mt-3'>{children}</div>
        }
      />
    </>
  );
}
