import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Form } from 'reactstrap';
import { mapValues } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { auditData } from '../../shared/models/user';

const { entries } = Object;

const fields = {
  note: {
    label: '備考',
    type: 'text',
    rows: 10,
    initialValue: '',
  },
};

export default function EntryFrameNoteModal(props) {
  const {
    user,
    documentName,
    isOpen,
    values,
    onClickClose,
    submitLabel = '保存',
    hasSpin = false,
    entry,
    lectureId,
    ...extraProps
  } = props;
  const statedFields = useFormState(
    values.frames.find((_) => _.lectureId === lectureId),
    fields,
    isOpen
  );
  const isUnsubmittable = Object.values(statedFields).some((_: any) => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    toggleSubmitting(true);
    const frameIndex = entry.frames.findIndex((_) => _.lectureId === lectureId);
    await props.onSubmit({
      frames: [
        ...entry.frames.slice(0, frameIndex),
        {
          ...entry.frames[frameIndex],
          ...mapValues(statedFields, 'value'),
          noteUpdatedAt: new Date(),
          noteUpdatedBy: auditData(user),
        },
        ...entry.frames.slice(frameIndex + 1),
      ],
    });
    toggleSubmitting(false);
  };

  return (
    <Modal isOpen={isOpen} {...extraProps}>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              key={fieldName}
              name={fieldName}
              values={mapValues(statedFields, 'value')}
              documentName={documentName}
              {...(fieldSetting as any)}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          {onClickClose && (
            <Button className="cancel" color="secondary" onClick={onClickClose}>
              閉じる
            </Button>
          )}
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
            {hasSpin && isSubmitting && <span className="fas mr-1 fa-spin fa-spinner" />}
            {submitLabel}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
