import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form } from 'reactstrap';
import { keyBy, omit, get, mapValues } from 'lodash';
import { useList, useAsync, useCounter, } from 'react-use';
import { arrayMoveImmutable } from 'array-move';

import firebase from '../../firebase';
import { getCollectionData } from '../../shared/firebase';
import { fields, questionRowFields, stepFields, } from '../../shared/models/survey';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';

const { entries } = Object;
const db = firebase.firestore();

export default function SurveyFormModal(props) {
  const { staffs, questions, surveyGroups, isOpen, values, onClickClose } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields({ staffs, questions, surveyGroups, }), isOpen);
  const [rows, { set: setRows, updateAt: updateRowAt }] = useList(get(values, 'questionRows') || []);
  const [steps, { set: setSteps, updateAt: updateStepAt }] = useList(values.steps ?? [{}]);
  const isUnsubmittable = Object.values(statedFields).some((_) => !_.isValid)
    || steps.some((_) => !_.isValid)
    || rows.some((_) => !_.isValid);
  const onClickMove = (index, direction) => {
    const newSteps = arrayMoveImmutable(steps, index, index + ({ up: -1, down: 1 })[direction]);
    setSteps(newSteps);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit({
      ...mapValues(statedFields, 'value'),
      steps: steps.map((_) => omit(_, 'isValid')),
      questionRows: rows.map((_) => omit(_, 'isValid')),
    });
  };

  return (
    <Modal isOpen={isOpen} size='xl'>
      <ModalHeader>アンケートページ{isNew ? '追加' : '編集'}</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                values={mapValues(statedFields, 'value')}
                documentName="survey"
                {...fieldSetting}
              />
            ))
          }
          <div className="my-3">
            <label>ステップ</label>
            <ListForm
              items={steps}
              onDelete={(index) => setRows(rows.filter(_ => (_.stepIndex ?? 0) !== index))}
              renderItem={(step, stepIndex) => {
                const lessStepIndexRows = rows.filter(_ => (_.stepIndex ?? 0) < stepIndex);
                const stepRows = rows.filter(_ => (_.stepIndex ?? 0) === stepIndex);
                const greaterStepIndexRows = rows.filter(_ => (_.stepIndex ?? 0) > stepIndex);
                const setStepRows = _ => setRows([...lessStepIndexRows, ..._, ...greaterStepIndexRows]);
                const updateStepRowAt = (index, item) => updateRowAt(index + lessStepIndexRows.length, item);
                return (
                  <div>
                    <div className="rounded p-3" style={{ border: '2px solid #aaa', }}>
                      <StepForm
                        index={stepIndex}
                        values={step}
                        questions={questions.filter(_ => _.surveyGroupId === statedFields.surveyGroupId.value)}
                        allRows={rows}
                        rows={stepRows}
                        setRows={setStepRows}
                        updateRowAt={updateStepRowAt}
                        onChange={(_) => updateStepAt(stepIndex, { ...step, ..._ })}
                        onClickMove={onClickMove.bind(null, stepIndex)}
                      />
                    </div>
                  </div>
                );
              }}
              onChange={(_) => setSteps(_)}
              minItems={1}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

function StepForm (props) {
  const { allRows, rows, setRows, index: stepIndex, questions, values, onChange, onClickMove, updateRowAt, } = props;
  const questionsById = keyBy(questions, 'id');
  const statedFields = useFormState(values, stepFields({ }), values);
  const onClickMoveRow = (index, direction) => {
    const newRows = arrayMoveImmutable(rows, index, index + ({ up: -1, down: 1 })[direction]);
    const isInvalidCondition = (row, i) => row.visibilityConditionQuestionId != null && !newRows.slice(0, i).map(_ => _.questionId).includes(row.visibilityConditionQuestionId);
    if((_ => _ != null && !window.confirm(`「${questionsById[_.questionId]?.name}」の表示条件がクリアされます。よろしいですか？`))(newRows.find(isInvalidCondition))) return;

    setRows(newRows.map((row, i) => isInvalidCondition(row, i) ? { ...row, visibilityConditionQuestionId: null, visibilityConditionAnswer: null } : row));
  };
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every((_) => _.isValid),
    });
  }, [...Object.values(statedFields).map((_) => _.value)]);

  return (
    <div>
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field
            key={fieldName}
            name={fieldName}
            values={mapValues(statedFields, 'value')}
            documentName="survey"
            {...fieldSetting}
          />
        ))
      }
      <div className="my-3">
        <label>アンケート項目</label>
        <ListForm
          showsInsert
          items={rows}
          initialItem={{ stepIndex }}
          renderItem={(item, itemIndex) => {
            return (
              <div>
                <div className="card p-3">
                  <RowForm
                    rows={rows}
                    allRows={allRows}
                    index={itemIndex}
                    indexInAll={allRows.indexOf(item)}
                    values={item}
                    questions={questions}
                    onChange={(_) => updateRowAt(itemIndex, { ...item, ..._ })}
                    onClickMove={onClickMoveRow.bind(null, itemIndex)}
                  />
                </div>
              </div>
            );
          }}
          onChange={(_) => setRows(_)}
          minItems={1}
        />
      </div>
    </div>
  );
}

function RowForm(props) {
  const { allRows, rows, index, indexInAll, questions, values, onChange, onClickMove, } = props;
  const prevRowQuestionIds = allRows.slice(0, indexInAll).map(_ => _.questionId);
  const otherRows = [...allRows.slice(0, indexInAll), ...allRows.slice(indexInAll + 1)]; 
  const statedFields = useFormState(values, questionRowFields({ questions, prevRowQuestionIds, otherRows, }), values);
  useEffect(() => {
    onChange({
      ...mapValues(statedFields, 'value'),
      isValid: Object.values(statedFields).every((_) => _.isValid),
    });
  }, [...Object.values(statedFields).map((_) => _.value)]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end align-items-start gap-1">
        <div className="flex-grow-1">
          <Field
            name="questionId"
            {...statedFields.questionId}
            label={false}
          />
        </div>
        <div className="d-flex gap-1">
          <Button size="sm" onClick={onClickMove.bind(null, 'up')} disabled={index === 0}>
            <span className="fas fa-caret-up" />
          </Button>
          <Button size="sm" onClick={onClickMove.bind(null, 'down')} disabled={index === rows.length - 1}>
            <span className="fas fa-caret-down" />
          </Button>
        </div>
      </div>
      <div className="d-flex gap-1">
        <div>
          <Field
            name="visibilityConditionEnabled"
            {...statedFields.visibilityConditionEnabled}
          />
        </div>
      </div>
      <div className="d-flex flex-wrap gap-1">
        <div className='flex-grow-1'>
          <Field
            name="visibilityConditionQuestionId"
            {...statedFields.visibilityConditionQuestionId}
          />
        </div>
        <div className='flex-grow-1'>
          <Field
            name="visibilityConditionAnswers"
            {...statedFields.visibilityConditionAnswers}
          />
        </div>
      </div>
      <div>
        <Field
          name="supportRequiredAnswer"
          {...statedFields.supportRequiredAnswer}
        />
      </div>
    </div>
  );
}
