
import React from 'react';
import { CardElement, } from 'react-stripe-elements';

export default function AppCardElement (props) {
  return (
    <CardElement
      hidePostalCode
      style={{
        base: {
          '::placeholder': {
            color: '#bbb',
          },
        },
      }}
      {...props}
    />
  );
};
