import {
  getStorage,
  ref,
  uploadBytes as _uploadBytes,
  getDownloadURL,
  deleteObject,
  StorageReference,
  getBytes,
} from 'firebase/storage';

const storageRef = (path: string) => ref(getStorage(), path);

type UploadBytesParameters = Parameters<typeof _uploadBytes>;
// NOTE: これだとrefの型が変わってしまったのでrefの型は個別に指定する形に
// const uploadBytes = (...[ref, data, metadata]: UploadBytesParameters) =>
const uploadBytes = (ref: StorageReference, data: UploadBytesParameters[1], metadata: UploadBytesParameters[2]) =>
  _uploadBytes(ref, data, { cacheControl: 'public,max-age=10800', ...metadata });

export { getStorage, storageRef, uploadBytes, getDownloadURL, deleteObject, getBytes };
